import { api } from '@ekenta/app/api/apiSlice'
import { RecentBlogResponseType, AllBlogPostResponseType, BlogPostBySlugResponseType } from './blogDataType'

export const blogApiSlice = api.injectEndpoints({
  endpoints: (builder) => ({
    getRecentBlogPost: builder.query<RecentBlogResponseType, void>({
      query: () => ({
        url: `/api/recent-blogs/qlason`,
        method: 'GET',
      }),
    }),
    getAllBlogPost: builder.query<AllBlogPostResponseType, void>({
      query: () => ({
        url: `/api/blogs/qlason`,
        method: 'GET',
      }),
    }),
    getBlogPostBySlug: builder.query<BlogPostBySlugResponseType, string>({
      query: (slug) => ({
        url: `/api/blog/${slug}`,
        method: 'GET',
      }),
    }),
  }),
})

export const {
  useGetRecentBlogPostQuery,
  useGetAllBlogPostQuery,
  useGetBlogPostBySlugQuery,
} = blogApiSlice
