import React from 'react'
import styled from 'styled-components'
import Rating from './Rating'
import ReviewList from './ReviewList'
import { device } from '@ekenta/utils/devices'

const Reviews = () => {
  return (
    <Container>
      <div className="top-header">
        <h5>Customer Review’s & Ratings</h5>
      </div>
      <Stack>
        <Rating />
        <ReviewList />
      </Stack>
    </Container>
  )
}

const Container = styled.div`
  flex: 1;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 16px 22px 19.5px 21px;
  border-radius: 12px;
  background: #fff;
  font-family: var(--eke-body-font-family);
  gap: 20px;
  //box-shadow: 0 2px 2px 0px rgba(0, 0, 0, 0.12);

  .top-header {
    width: 100%;
    height: 57px;
    border-radius: 12px;
    padding: 15px 16px 12px 16px;
    align-items: center;
    background: #f3faff;

    h5 {
      color: var(--secondary-500, #1d4057);
      font-size: 22px;
      font-weight: 700;
      margin: 0;
      padding: 0;
    }
  }
`

const Stack = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
  font-family: var(--eke-body-font-family);
  flex-direction: column;
  gap: 14px;

  @media ${device.md} {
    flex-direction: row;
  }
`
export default Reviews
