import React, { Fragment } from 'react'
import styled from 'styled-components'
import Post from './common/Post'
import { useGetAllBlogPostQuery } from '@ekenta/redux/store/features/blog/blogApiSlice'
import { BlogContentType } from '@ekenta/redux/store/features/blog/blogDataType'
import { Loader } from '@ekenta/components'
import { PostStyle } from './style'
import { device } from '@ekenta/utils/devices'

const AllPosts = () => {
  const { data, isLoading } = useGetAllBlogPostQuery()
  const allPost = data?.blogs?.data ?? []

  return (
    <Box>
      <PostStyle>
        <h3>All blog posts</h3>
        {isLoading ? <Loader /> : null}
        <Flex>
          {allPost?.length > 0 ? (
            <Fragment>
              {allPost?.map((item: BlogContentType) => (
                <div key={`all-${item.id}`} className="wrapper">
                  <Post
                    title={item.title}
                    summary={item.summary}
                    image_url={item.image_url}
                    date={item.updated_at}
                    written_by={item.written_by}
                    slug={item.slug}
                    direction="column"
                    imgH="282px"
                  />
                </div>
              ))}
            </Fragment>
          ) : (
            <>No Post yet</>
          )}
        </Flex>
      </PostStyle>
      <br />
    </Box>
  )
}
const Box = styled.div`
  width: 100%;
  display: block;
  max-width: 90%;
  height: auto;
  margin: 0 auto;
  background-color: var(--eke-color-white) !important;

  h3 {
    font-family: var(--eke-body-font-family);
    font-size: 32px;
    font-weight: 700 !important;
    line-height: 38.4px;
    color: #101828;
    margin-top: 14px;
    margin-bottom: 27px;
  }

  @media ${device.lg} {
    width: 100%;
    max-width: 100%;
  }
`
const Flex = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: stretch;
  justify-content: stretch;
  row-gap: 25px;
  width: 100%;
  height: auto;
  margin-top: -22px;
  margin-left: -22px;

  @media ${device.lg} {
    flex-direction: row;
  }

  > * {
    flex-basis: calc(33.33% - 22px);
    margin-top: 22px;
    margin-left: 22px;
  }

  h2 {
    height: 60px;
    margin-bottom: 5px;
  }

  .post--paragraph-box {
    width: 100% !important;
    display: block;
    display: -webkit-box;
    text-decoration: none;
    max-width: 100%;
    height: 44px;
    margin-bottom: 0rem;
    line-height: 1.5;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis !important;
    line-height: 140%;
    p {
    }
  }
`
export default AllPosts
