import React from "react"
//import { useNavigate } from "react-router-dom";
import NumberFormat from "react-number-format";
import classnames from "classnames";
import { Button } from "@ekenta/components";

import styles from "./PaymentHookButton.module.scss";


interface PayOnDeliveryHookButtonPropsType {
  text: string;
  className: string;
  textPrice: number;
  url: string;

}

const PayOnDeliveryHookButton: React.FC<PayOnDeliveryHookButtonPropsType> = (props) => {

  function navigate(url: string) {
    window.location.href = url;
  }
  return (
    <div className={styles.ButtonGroup}>
      <Button
        onClick={() => navigate(props.url)}
        className={
          classnames(
            styles.PaymentHook__Button,
            "d-flex algin-items-center justify-content-center"
            , props.className)}
        name="paymentBtn__hook"
        width="w-100">
        <span>{props.text}</span>
        <NumberFormat
          className="tot ms-2"
          thousandSeparator={true}
          displayType={"text"}
          decimalSeparator="."
          decimalScale={2}
          prefix="NGN"
          fixedDecimalScale
          value={props.textPrice}
        />
      </Button>
    </div>
  )
}

export default PayOnDeliveryHookButton;