import axios from 'axios'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { toast } from 'react-toastify'

import {
  setCartItems,
  addToCart,
  updateQuantity,
  removeFromCart,
  CartItem,
} from './cartSlice'
import { RootState } from '../../hook'
import { BASE_URL } from '@ekenta/constants'

interface ErrorResponse {
  message?: string;
}

interface CustomRejectionValue {
  status: string
  errorMessage: string
}

interface QuantityPayload {
  cartItemId: string
  type: 'increase' | 'decrease'
  quantity: number
}

let nextItemId = 1

// Fetch all CartItems
export const fetchCartItemsAsync = createAsyncThunk<
  CartItem[],
  void,
  { state: { auth: { authentication: { token: string } } } }
>('cart/fetchCartItems', async (_, { getState, dispatch }) => {
  const { token } = getState().auth.authentication
  try {
    if (token) {
      const response = await axios.get(`${BASE_URL}/api/get-cart-items`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      const rawResponse = response.data?.cart
      dispatch(setCartItems(rawResponse))
      return rawResponse
    } else {
      dispatch(setCartItems([]))
    }
  } catch (error) {
    if (axios.isAxiosError(error)) {
      const status = 'error'
      const errorMessage = 'An error occurred while fetching cart.'
      const rejectionValue: CustomRejectionValue = {
        status,
        errorMessage: errorMessage,
      }
      toast.error(errorMessage)

      return Promise.reject(rejectionValue)
    }
    throw error
  }

  return Promise.reject('Somethin went wrong')
})

///Add To Cart
export const addToCartAsync = createAsyncThunk<
  CartItem,
  {
    id: string
    name: string
    price: number
    stock: number
    is_variant: number
  },
  { state: RootState }
>('cart/addToCart', async (product, { getState, dispatch }) => {
  const { token } = getState().auth.authentication
  try {
    if (product.stock === 0) return toast.error('Product is out of stock')
    if (token) {
      const data = {
        product_id: product.id,
      }
      const response = await axios.post(`${BASE_URL}/api/add-to-cart`, data, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      })
      const result = response.data.cart

      const cartItem = {
        id: result.id,
        product: {
          id: result.product_id,
          name: product.name,
          price: product.price,
          stock: product.stock,
          is_variant: product.is_variant,
        },
        user_id: result.user_id,
        quantity: 1,
      }
      dispatch(addToCart(cartItem))
      toast.success(`Product added successfully`)
      return response.data
    } else {
      const newId = nextItemId++
      dispatch(
        addToCart({
          id: newId.toString(),
          product: product,
          quantity: 1,
          user_id: '',
        })
      )
      toast.success(`Product added successfully`)
    }
  } catch (error) {
    if (axios.isAxiosError(error)) {
      const status = 'error'
      const errorMessage = 'An error occurred while adding to cart.'
      const rejectionValue: CustomRejectionValue = {
        status,
        errorMessage: errorMessage,
      }
      toast.error(errorMessage)
      return Promise.reject(rejectionValue)
    }
    throw error
  }

  return Promise.reject('Something went wrong')
})

// Update CartItem Quantity
export const updateQuantityAsync = createAsyncThunk<
  void,
  QuantityPayload,
  { state: RootState }
>(
  'cart/updateQuantity',
  async ({ cartItemId, type }, { getState, dispatch }) => {
    const { token } = getState().auth.authentication
    try {
      if (token) {
        const response = await axios.put(
          `${BASE_URL}/api/update-cart/${type}/${cartItemId}`,
          {},
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        const result = response.data.cart
        const cartItem = {
          cartItemId: result.id.toString(),
          quantity: result.quantity,
        }
        dispatch(updateQuantity(cartItem))
        toast.success(`${response?.data?.message}`)
        return response.data
      } else {
        toast.info('Signin to add quantity')
      }
    } catch (error) {
      if (axios.isAxiosError(error)) {
       const status = 'error';
      const defaultErrorMessage = 'An error occurred while updating the cart.';
      
      // Type casting error.response?.data to ErrorResponse
      const responseData = error?.response?.data as ErrorResponse;
      
      const errorMessage = responseData?.message || defaultErrorMessage;

      const rejectionValue: CustomRejectionValue = {
        status,
        errorMessage,
      };
        toast.error(errorMessage)
        return Promise.reject(rejectionValue)
      }
      throw error
    }
    return Promise.reject('Something went wrong')
  }
)

export const removeFromCartAsync = createAsyncThunk<
  void,
  string,
  { state: RootState }
>('cart/removeFromCart', async (cartItemId, { getState, dispatch }) => {
  const { token } = getState().auth.authentication
  try {
    if (token) {
      const response = await axios.put(
        `${BASE_URL}/api/update-cart/remove/${cartItemId}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      dispatch(removeFromCart({ id: cartItemId }))
      toast.success(`${response?.data?.message}`)
    } else {
      dispatch(removeFromCart({ id: cartItemId }))
    }
  } catch (error) {
    if (axios.isAxiosError(error)) {
      const status = 'error'
      const errorMessage = 'An error occurred while removing from cart.'
      const rejectionValue: CustomRejectionValue = {
        status,
        errorMessage: errorMessage,
      }
      toast.error(errorMessage)
      return Promise.reject(rejectionValue)
    }
    throw error
  }
  return Promise.reject('Something went wrong')
})
