import React, { useState } from 'react';
import { toast } from "react-toastify";
import { Button, ButtonGroup, Field, Typography } from '@ekenta/components';
import changePassFormValidation from '@ekenta/validations/schemas/account-changepassword.schema';
import { useFormik } from 'formik';
import classnames from 'classnames';
import { useChangeUserPasswordMutation } from '@ekenta/redux/store/services/accountApi';
import { useAppSelector } from '@ekenta/redux/store/hook';
import { useEffect } from 'react';

import styles from "../Profile.module.scss";

const SectionPass = () => {
    const [isEdit, setIsEdit] = useState(false);
    const { token } = useAppSelector(state => state.auth.authentication);
    const [changeUserPassword, getResult] = useChangeUserPasswordMutation();

    const formik = useFormik({
        initialValues: {
            currentPassword: "",
            newPassword: "",
            confirmPassword: "",
        },
        validationSchema: changePassFormValidation(),
        onSubmit: values => {
            const data = {
                old_password: values.currentPassword,
                password: values.newPassword,
                password_confirmation: values.confirmPassword,
            };
            changeUserPassword({ data, token });
        }
    });

    useEffect(() => {
        if (getResult?.isSuccess) {
            toast.success(`${getResult?.data.message}`, {
                toastId: "updated-mess-toast-success",
            });

            handleHideForm();
        }
        if (getResult?.isError && getResult?.error.status === "FETCH_ERROR") {
            toast.error(`Something went wrong`, {
                toastId: "updated-fetch-toast-error",
            });
            setIsEdit(false);
        }

        if (getResult?.isError && getResult?.error.status === 401) {
            toast.error(`${getResult?.error.data.message}`, {
                toastId: "updated-message-toast-error",
            });
            setIsEdit(false);
            handleHideForm();
        }
    }, [
        getResult?.isSuccess,
        getResult?.isError,
        getResult?.error
    ]);

    const handleShowForm = () => setIsEdit(true);
    const handleHideForm = () => {
        setIsEdit(false);
        formik.setFieldValue("currentPassword", "");
        formik.setFieldValue("newPassword", "");
        formik.setFieldValue("confirmPassword", "");
    }


    return (
        <div className={styles.MarginTop}>

            {isEdit ? (
                <EditForm
                    title="Edit password"
                    formik={formik}
                    isLoading={getResult?.isLoading}
                    onCancel={handleHideForm}
                />
            ) : (
                <div className={classnames(styles.Section__Group)}>
                    <Typography
                        as="span"
                        size="smallest"
                        color="light"
                        uiText>Password</Typography>
                    <div className={styles.Row}>
                        <Typography
                            as="span"
                            size="medium"
                            color="dark"
                            className={styles.Row__Heading}
                            uiText>************</Typography>
                        <Button
                            aria-label="Edit Password"
                            name="Edit"
                            as="text"
                            textVariant="dark"
                            className={styles.Row__Button}
                            isLoadingText="Save"
                            isLoading={getResult?.isLoading}
                            onClick={handleShowForm}
                        />
                    </div>
                </div>
            )}
        </div>
    )
}


const EditForm = (props: { title: string; formik: any; isLoading: boolean; onCancel: () => void }) => {
    const { title, formik, isLoading, onCancel } = props;

    return (
        <div style={{ marginTop: "0.7rem" }}>
            <Typography
                as="span"
                size="small"
                color="secondary"
                margin="top"
                className={styles.EditTypograph_Heading}
                heading>
                {title}
            </Typography>
            <div className="mt-3">
                <Typography
                    as="span"
                    size="smallest"
                    className="mb-3"
                    color="light"
                    uiText>*Required fields</Typography>
                <Field
                    id="currentPassword"
                    name="currentPassword"
                    label=""
                    disabled={false}
                    type="password"
                    required
                    size="large"
                    childOutsideLabel={false}
                    placeholder="Current password*"
                    fieldClassName={styles.FieldControl__Input}
                    autoComplete="off"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.currentPassword}
                    isError={formik.touched.currentPassword && formik.errors.currentPassword ? true : false}
                    error={formik.errors.currentPassword}
                />
            </div>
            <div className="mt-3">
                <Field
                    id="newPassword"
                    name="newPassword"
                    label=""
                    disabled={false}
                    type="password"
                    required
                    size="large"
                    childOutsideLabel={false}
                    placeholder="New password*"
                    fieldClassName={styles.FieldControl__Input}
                    autoComplete="off"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.newPassword}
                    isError={formik.touched.newPassword && formik.errors.newPassword ? true : false}
                    error={formik.errors.newPassword}
                />
            </div>
            <div className="mt-3">
                <Field
                    id="confirmPassword"
                    name="confirmPassword"
                    label=""
                    disabled={false}
                    type="password"
                    required
                    size="large"
                    childOutsideLabel={false}
                    placeholder="Confirm new password*"
                    fieldClassName={styles.FieldControl__Input}
                    autoComplete="off"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.confirmPassword}
                    isError={formik.touched.confirmPassword && formik.errors.confirmPassword ? true : false}
                    error={formik.errors.confirmPassword}
                />
            </div>
            <ButtonGroup pos="right">
                <Button
                    as="text"
                    name="Cancel"
                    shadow={false}
                    type="button"
                    disabled={isLoading}
                    className={styles.Row__Button}
                    onClick={onCancel}
                />
                <Button
                    name="Save"
                    bgVariant="primary"
                    textVariant="light"
                    className="py-2"
                    disabled={isLoading}
                    isLoadingText="Save"
                    isLoading={isLoading}
                    shadow={false}
                    type="button"
                    onClick={formik.handleSubmit}
                />
            </ButtonGroup>
        </div>
    )
}

export default SectionPass