import React from 'react'
import styled from 'styled-components'
import SellerInfo from './SellerInfo'
import ChatSeller from './ChatSeller'
import { device } from '@ekenta/utils/devices'
import { ProductType } from '@ekenta/redux/store/features/product/productDataType'

interface PropsType {
  product: ProductType
}

const SellerDetails: React.FC<PropsType> = (props) => {
  const { product } = props
  return (
    <Container>
      <Stack>
        <SellerInfo
          merchantFollowers={product?.merchantFollowers}
          sellerInfo={product?.sellerInfo}
          followStatus={product?.followStatus}
        />
        <ChatSeller
          sellerUserId={product?.user_id}
          sellerName={product?.sellerInfo?.business_name}
          productId={product?.id}
        />
      </Stack>
    </Container>
  )
}

const Container = styled.div`
  width: 100%;
  height: auto;
`

export const Stack = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  align-items: stretch;
  flex-direction: column;
  justify-content: stretch;
  gap: 24px;

  @media ${device.md} {
    flex-direction: row;
  }
`

export default SellerDetails
