import React from 'react';
import { Form } from 'react-bootstrap';
import { getIn, useFormikContext } from 'formik';
import { VscArrowSmallRight } from 'react-icons/vsc';
import { Button, Field, Label } from '@ekenta/components';
import { OnboardingContentWidth } from '../../style';
import { MyFormValues } from '../OnboardingForm/types';

export default function BankDetails({ nextPage }: { nextPage: () => void }) {
    const {
        values,
        errors,
        touched,
        setFieldValue,
        handleChange,
        handleBlur,
    } = useFormikContext<MyFormValues>();

    return (
        <OnboardingContentWidth className="seller_account_details_form_col">
            <div className="_top_header">
                <h5>Seller Bank Details</h5>
                <p>Complete the form below to become a seller</p>
            </div>
            <div>
                <div className=" mt-4">
                    <Form.Group className="mb-3 position-relative">
                        <Label required type="text" text="Bank Name" />
                        <div>
                            <Form.Select
                                name="bank_detail.bank"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.bank_detail.bank}
                            //isInvalid={!!getIn(errors, 'bank_detail.bank')}
                            >
                                <option value="">Select Bank</option>
                                <option value="accessbank">Access Bank</option>
                                <option value="citibank">Citibank</option>
                                <option value="diamond">Diamond Bank</option>
                                <option value="ecobank">Ecobank</option>
                                <option value="fidelity">Fidelity Bank</option>
                                <option value="firstbank">First Bank</option>
                                <option value="fcmb">First City Monument Bank (FCMB)</option>
                                <option value="gtb">Guaranty Trust Bank (GTB)</option>
                                <option value="heritage">Heritage Bank</option>
                                <option value="keystone">Keystone Bank</option>
                                <option value="Kuda">Kuda Bank</option>
                                <option value="polaris">Polaris Bank</option>
                                <option value="Paycom">Paycom (OPAY) Bank</option>
                                <option value="providus">Providus Bank</option>
                                <option value="stanbic">Stanbic IBTC Bank</option>
                                <option value="standard">Standard Chartered Bank</option>
                                <option value="sterling">Sterling Bank</option>
                                <option value="suntrust">Suntrust Bank</option>
                                <option value="union">Union Bank</option>
                                <option value="uba">United Bank for Africa (UBA)</option>
                                <option value="unity">Unity Bank</option>
                                <option value="wema">Wema Bank</option>
                                <option value="zenith">Zenith Bank</option>
                            </Form.Select>
                            {getIn(touched, 'bank_detail.bank') &&
                                getIn(errors, 'bank_detail.bank') ? (
                                <div className="invalid-fbk">
                                    {getIn(errors, "business_detail.bank")}
                                </div>
                            ) : null}
                        </div>
                    </Form.Group>

                    <Form.Group className="mb-3 position-relative">
                        <Field
                            id="account_name"
                            name="bank_detail.account_name"
                            label="Account Name"
                            disabled={false}
                            required
                            type="text"
                            size="large"
                            childOutsideLabel={false}
                            placeholder="e.g. John Doe Jane"
                            fieldClassName=""
                            autoComplete="off"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.bank_detail.account_name}
                            isError={getIn(touched, 'bank_detail.account_name') && getIn(errors, 'bank_detail.account_name') ? true : false}
                            error={getIn(errors, 'bank_detail.account_name')}
                        />
                    </Form.Group>
                    <Form.Group className="mb-4 position-relative">
                        <Field
                            id="account_number"
                            name="bank_detail.account_number"
                            label="Account Number"
                            disabled={false}
                            type="number"
                            required
                            childOutsideLabel={false}
                            placeholder="e.g 128279729239"
                            autoComplete="off"
                            format="##########"
                            onChange={(e) => {
                                setFieldValue("bank_detail.account_number", e.formattedValue)
                            }}
                            value={values.bank_detail.account_number}
                            isError={values.bank_detail.account_number && getIn(errors, 'bank_detail.account_number') ? true : false}
                            error={getIn(errors, 'bank_detail.account_number')}
                        />
                    </Form.Group>
                    {/* <Button
                            name="Back"
                            shadow={false}
                            bgVariant="secondary"
                            width="w-100"
                            textVariant="light"
                            prefixIcon={<VscArrowSmallLeft />}
                            type="button"
                            onClick={prevPage}
                        /> */}
                    <Button
                        name="Proceed"
                        textVariant="light"
                        width="w-100"
                        className="PageStep__Btn"
                        disabled={
                            !values.bank_detail.bank ||
                            !values.bank_detail.account_name ||
                            !values.bank_detail.account_number ||
                            getIn(errors, 'bank_detail.bank') ||
                            getIn(errors, 'bank_detail.account_name') ||
                            getIn(errors, 'bank_detail.account_number')
                        }
                        shadow={false}
                        suffixIcon={<VscArrowSmallRight />}
                        type="button"
                        onClick={nextPage}
                    />
                </div>
            </div>
        </OnboardingContentWidth>

    );
}
