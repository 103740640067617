/* eslint-disable @typescript-eslint/no-explicit-any */
import classnames from 'classnames';
import React from 'react'
import { Link } from 'react-router-dom'
import styled, { css } from 'styled-components';

export const MenuItem = (props: { link: string; className?: string; active: boolean; icon: any; text: string; onClick?: () => void }) => {
  return (
    <MenuItemStyle className={classnames('menu-item', props.className)}>
      <LinkStyle active={props.active} to={props.link} onClick={props.onClick}>
        {props.icon}
        <span>{props.text}</span>
      </LinkStyle>
    </MenuItemStyle>
  )
}


const MenuItemStyle = styled.li`
   margin: 0.2rem 0;
  list-style-type: none;
                
`;

const LinkStyle = styled(Link) <{ active: boolean }>`
  padding: 10px 0 10px 0px !important;
  padding-left: 25.55px !important;

  width: 100%;
  height: auto;
  text-decoration: none;
  //color:  #adadad; // rgb(93, 98, 145);
  display: flex;
  align-items: center;
  gap: 0.5rem !important;
  color: var(--greyscale-700, #616161) !important;
  font-family: var(--eke-body-font-family);
  font-size: 13.5px;
  font-weight: var(--eke-font-weight-normal) !important;

  letter-spacing: 0.2px;
  border-radius: 45px;

  ${props => props.active && css`
    background:var(--eke-color-primary);
    color: var(--eke-color-white) !important;
    .fill-icon{
      fill: var(--eke-color-white) !important;
    }
    .stroke-icon{
      stroke: var(--eke-color-white) !important;
    }
  `}

  svg{
         display: inline-block;
          height: 15px !important;
          width: 15px;
          font-size: 14px;
          text-align: center;
  }

  .fill-icon{
      fill: #616161;
    }
    .stroke-icon{
      stroke: #616161;
    }



  span{
          display: inline-block;
          line-height: 20px;
          text-align: center;
      }
        transition: 0.3s ease-in-out;

  &:hover{
      background: var(--eke-color-primary);
    color: var(--eke-color-white) !important;

    .fill-icon{
      fill: var(--eke-color-white) !important;
    }
    .stroke-icon{
      stroke: var(--eke-color-white) !important;
    }
    svg{
      stroke: var(--eke-color-white) !important;
    }
  }
                
`;