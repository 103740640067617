/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { Fragment } from 'react';
import styled from 'styled-components';
import { FiCheckCircle, FiLink2, FiMoreVertical, FiSlash } from 'react-icons/fi';
import { CardBox, Dropdown, Loader, Typography } from "@ekenta/components";
import { device } from '@ekenta/utils/devices';
import { StatusIndicator } from '@ekenta/pages/Dashboard/common/Resuable';


const BannerCard = ({ name, link, bannerUrl, menus, tag, loading, isActive, onPreview }: { name: string; tag: string; link: string, isActive: number, bannerUrl: string; loading: boolean; menus: any[], onPreview: () => void }) => {
    return (
        <CardBoxStyle link={link}>
            <CardBox className="CardBox">
                {loading ? (
                    <Loader size="large" color="black" />
                ) : (
                    <Fragment>
                        <div className="Banner_File_Preview File_Group" title="Click to preview">
                            <img src={bannerUrl} alt={name} onClick={onPreview} />
                        </div>
                        <div className="Banner_Content">
                            <div className="Banner_Content-Left">
                                <Typography
                                    as="h3"
                                    color="default"
                                    size="medium">
                                    <span>{name}</span>
                                    {isActive === 1 ? <FiCheckCircle fontSize="0.5em" color="green" /> : <FiSlash fontSize="0.5em" color="grey" />}
                                </Typography>
                                {link ? (<a href={link} target="_blank" rel="noreferrer">
                                    <FiLink2 />
                                    <span>{link}</span>
                                </a>) : (
                                    <a>
                                        <FiLink2 />
                                        <span>no link</span>
                                    </a>
                                )}
                                {tag ? (
                                    <div className="tagIndicator">
                                        <StatusIndicator text={tag}
                                            status={tag === "NIGERIA" ? "COMPLETED" : tag === "GENERAL" ? "PROCESSING" : "BLOCKED"} />
                                    </div>
                                ) : null}
                            </div>
                            <div className="Banner_Content-Right">
                                <Dropdown
                                    name=""
                                    onlyIcon={true}
                                    suffixIcon={<FiMoreVertical color='#F8880A' />}
                                    placement="bottom"
                                    tooltipContent=""
                                    menus={menus}
                                />
                            </div>
                        </div>
                    </Fragment>
                )}
            </CardBox >
        </CardBoxStyle>
    )
}

const CardBoxStyle = styled.div<{ link?: string }>`
    width: 90%;

    @media ${device.md}{
        width: 31%;
    }

    @media ${device.lg}{
        width: 24%;
    }
    

    .CardBox{
        position: relative;
        width: 100% !important;
        height: 274px !important;
        display: flex;
        flex-direction: column;
        flex-shrink: 0;
        border-radius: 13px !important;
        padding: 12px 12px !important;
        font-family: var(--eke-body-font-family);




        .Banner_File_Preview{
            display: flex;
            width: 100%;
            max-width: 100%;
            height: 159px;
            justify-content: center;
            align-items: center;
            flex-shrink: 0;
            border-radius: 12px;
            background: #f2f2f2;
            margin-top: 5px;
            cursor: pointer;

            img{
                width: 100%;
                height: 100%;
                border-radius: 12px;
                object-fit: cover;
                

            }

            
            @media ${device.lg}{
            margin-top: 0px;
                max-width: 263px;
            }
        }

        .Banner_Content{
            width: 100%;
            display: flex;
            align-items: flex-start;
            justify-content: space-between;
            padding-left: 2px;
            padding-right: 2px;
            padding-top: 20px;

            @media ${device.xl}{
                padding-left: 2px;
                padding-right: 2px;
            }

            .Banner_Content-Left{
                flex: 1;
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                gap: 9px;

                h3{
                    display: flex;
                    align-items: center;
                    gap: 8px;

                    span{
                        color: var(--greyscale-900, #212121);
                        font-size: 20px;
                        font-style: normal;
                        font-weight: var(--eke-font-weight-semibold) !important;
                        line-height: 120%; /* 24px */
                        width: fit-content;
                        height: fit-content;
                        -webkit-box-orient: vertical;
                        -webkit-line-clamp: 1;
                        overflow: hidden;
                        white-space: nowrap;
                        text-overflow: ellipsis;
    
                        @media ${device.lg}{
                                width: 159px;
                            }
    
                            @media ${device.xl}{
                                width: 189px;
                            }
                    }
                }

                a{
                    display: flex;
                    align-items: center;
                    text-decoration: none;
                    gap: 11px;
                    color: ${props => props.link ? 'var(--eke-color-primary)' : '#d2cece'} !important;
    
                    svg{ margin-top: 3.5px !important;}
                    span{
                        flex: 1;
                        display: -webkit-box;
                        display: block;
                        width: 139px;
                        height: fit-content;
                        -webkit-box-orient: vertical;
                        -webkit-line-clamp: 1;
                        overflow: hidden;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                        font-family: var(--eke-body-font-family);
                        font-size: 16px;
                        font-weight: var(--eke-font-weight-medium);
                        line-height: 140%; /* 22.4px */
                        letter-spacing: 0.2px;


                        @media ${device.lg}{
                            width: 10rem;
                        }

                        @media ${device.xl}{
                            width: 10rem;

                        }
                    }
                }

                .tagIndicator{
                    position: absolute;
                    top: 11px;
                    right:6px;
                    .status_tag{
                        box-shadow: 0px 4px 8px 0px rgba(92, 107, 173, 0.03) !important;
                    }

                }
            }

            .Banner_Content-Right{
            }


        }

    }

   

`;


export default BannerCard;