/* eslint-disable @typescript-eslint/no-explicit-any */
import { CardBox, StarRating, Typography } from '@ekenta/components';
import React from 'react'
import classnames from 'classnames';
import styles from "./RatingItem.module.scss";
import { getIn } from 'formik';

const RatingItem = (props: any) => {
    const { title, images, orderNo, errors, rating, setFieldValue } = props;


    const RatingType = [
        {
            rating: 1,
            text: "I hate it!"
        },
        {
            rating: 2,
            text: "I dislike it!"
        },
        {
            rating: 3,
            text: "Fair!"
        },
        {
            rating: 4,
            text: "I like it!"
        },
        {
            rating: 5,
            text: "I love it!"
        }
    ]

    const getRatingVal = (options: any, rating: string) => {
        return options.find((option: any) => option.rating === parseInt(rating));
    };

    const ratingText = rating ? getRatingVal(RatingType, rating).text : "";

    return (
        <CardBox
            shadow={false}
            padding={false}
            className={styles.CardBox_Rating}>
            <div className={styles.CardBox_Image}>
                <img
                    src={images.length > 0 ? images[0].image_url : ""}
                    width="100"
                    height="100"
                    alt={title}
                />
            </div>
            <div className={styles.Item_Typography}>
                <div>
                    <Typography
                        as="h3"
                        size="medium"
                        color="dark"
                        className=""
                        uiText>
                        {title}
                    </Typography>
                    <div>
                        <div className={classnames(styles.FlexBox, styles.Typography__Group)}>
                            <Typography
                                as="span"
                                size="small"
                                color="secondary"
                                className="me-2"
                                uiText>
                                Order No:
                            </Typography>
                            <Typography
                                as="span"
                                size="small"
                                color="secondary"
                                className={styles.OrderNo}
                                uiText>
                                {orderNo}
                            </Typography>
                        </div>
                        <div className={styles.RatingDiv}>
                            <StarRating
                                name="rating"
                                value={rating}
                                starDimension="35px"
                                starSpacing="1px"
                                color="primary"
                                numberOfStars={5}
                                onChange={(value) => setFieldValue("rating", value)}
                            />
                            <Typography
                                as="span"
                                size="base"
                                color="secondary"
                                className=""
                                uiText>
                                {ratingText}
                            </Typography>
                            <div>
                                {getIn(errors, "rating") ? (
                                    <span className="invalid-fbk">
                                        {errors.rating}
                                    </span>
                                ) : null}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </CardBox>
    )
}

export default RatingItem