import React from 'react';
import { Col, Container } from 'react-bootstrap';
import { withDefaultLayout } from '@ekenta/hoc';
import { Link } from 'react-scroll';
import { HelmetHeader, PageTitle, Typography } from '@ekenta/components';

import styles from './index.module.scss';

const OnboardingPolicy = () => {
    return (
        <>
            <HelmetHeader
                title="Privacy Policy"
                description="Qlason Privacy Policy Page"
                keywords="qlason policy"
                url=""
            />
            <Container className="landingpage__container top__container pt-5">
                <div className={styles.Documentation__main}>
                    <div className={styles.Documentation__Container}>
                        <div className={styles.Documentation__Content}>
                            <PageTitle
                                title="Vendor Onboarding Policy"
                                className={styles.PageTitle}
                                margin={false}
                            />
                            <Typography
                                as="p"
                                size="base"
                                color="dark"
                                className={styles.Documentation_Para}
                                uiText
                            >
                                This document was last updated on May 31, 2020
                            </Typography>



                            <Col lg={12} className={'text-justify'}>
                                <div>
                                    You (“vendor”, “seller”) have elected to sign up for a vendor account (“Account”) on our Platform. This Policy explains Qlason’s (“Qlason”, “we”, “us”, “our” “Company”) rules pertaining to your relationship between you and other users on our Platform, and between you and us.
                                    This Policy MUST be read in conjunction with our Terms & Conditions of Use which are available on our Platform

                                </div>{' '}
                                <br />
                                <b>Preliminary Terms</b>
                                <br />
                                <p>You agree that:</p>
                                <div>
                                    <ol>
                                        <li>
                                            You are able to form legally binding contracts;
                                        </li>
                                        <li>
                                            Not a person barred from receiving and rendering services under the laws of Nigeria or other applicable jurisdictions;
                                        </li>
                                        <li>Not are suspended from using the Platform; and</li>
                                        <li>You have a valid email address.</li>
                                    </ol>
                                </div>{' '}
                                <br />
                                <p>You also agree to:</p>
                                <div>
                                    <ol>
                                        <li>
                                            Abide by and comply with this Policy and all applicable laws, including, without limitation, anti-corruption laws, privacy laws, intellectual property laws, anti-spam laws, and regulatory requirements; and
                                        </li>
                                        <li>
                                            Provide accurate, true, complete and non-misleading information to us and keep it updated. This shall include using your real name (or company name) on your account and providing a full and traceable address
                                        </li>
                                    </ol>
                                </div>{' '}
                                <br />

                                <p>You also agree not to:</p>
                                <div>
                                    <ol>
                                        <li>
                                            post content or items in inappropriate categories or areas on our Platform
                                        </li>
                                        <li>
                                            infringe any laws, third party rights or our policies,
                                        </li>
                                        <li>fail to deliver goods and or services purchased from you</li>
                                        <li>circumvent or manipulate our fee structure, the billing process, or fees owed to us;</li>
                                        <li>post false, inaccurate, misleading, deceptive, defamatory or offensive content (including personal information);</li>
                                        <li>transfer your account to another party without our consent;</li>
                                        <li>distribute or post spam, unsolicited, or bulk electronic communications, chain letters, or pyramid schemes</li>
                                        <li>distribute viruses or any other technologies that may harm our Platform, or the interests or property of our users (including their Intellectual Property Rights, privacy and publicity rights) or is unlawful, threatening, abusive, defamatory, invasive of privacy, vulgar, obscene, profane or which may harass or cause distress or inconvenience to, or incite hatred of, any person;</li>
                                        <li>attempt to modify, translate, adapt, edit, decompile, disassemble, or reverse engineer any software programs used by us in connection with the Platform;</li>
                                        <li>copy, modify or distribute rights or content from the Platform; or</li>
                                        <li>harvest or otherwise collect information about Users, including email addresses, without their consent;</li>
                                        <li>as a vendor, not upload any file or any text that contains your personal information such as phone number, email and website. This information will only be provided to us during registration. Any account that fails to comply with this will automatically be deactivated</li>
                                    </ol>
                                </div>{' '}
                                <br />

                                <b>Your Vendor Account</b>
                                <br />
                                <div>
                                    By creating this Account, you certify that you (including all of your employees, independent contractors or agents who are providing goods and services through this Platform) are, a licensed, certified, or experienced vendor, with the requisite experience and expertise and that the business information is correctly represented on our Platform.
                                    <br />
                                    We reserve the full and unencumbered right to remove or hide any incorrect, out of date, or illegal information from Account profiles, as well as remove or hide the entire Account’s profile itself.
                                </div>

                                <br /><br />

                                <b>Management of Transaction</b>
                                <br />
                                <div>

                                    Once a User has agreed to purchase any of the goods or services offered by you on our Platform, you shall be notified of a successful transaction. The notice shall contain all details necessary to complete the order.
                                    Your vendor account must contain your Nigerian bank account information. This is the ONLY account through which we will initiate payment disbursement to you.
                                    We shall facilitate the payment process from the Users on your behalf. You hereby authorize us to hold, receive, and disburse funds on your behalf. Funds we receive for any given transaction will not be disbursed to you until the transaction is deemed complete.
                                    Transactions will be deemed complete when we have confirmed that the service ordered has been fully rendered or the goods ordered have been dispatched for delivery.
                                    The disbursed funds shall be the total funds received for the transaction less our facilitation commission. This commission is Two and a half percent (2.5%) of the total transaction fee received from the User when the total transaction fee is greater than Fifty Thousand Naira (N50,000.00) and Five percent (5%) of the total transaction fee received from the User in all other circumstances.

                                </div>

                                <br />

                                <b>Customer Care</b>
                                <br />
                                <div>
                                    As a separate entity from Qlason, you hereby agree that Qlason shall be the sole arbiter and mediator of all customer service issues relating to such goods and services offered on our Platform to Users. You agree to be bound by Qlason’s resolution of conflicts between you and Users.
                                    In performing your services, you must present yourself as a separate entity from us.
                                    You hereby covenant that you will adopt our general Return, Cancellation, and Adjustment Policy, and not give cash refunds or refunds of any kind to a User, unless through our Platform.
                                    Qlason shall have the final say on all refunds to be processed. The amount of the refund/adjustment cannot exceed the amount shown as the total on the original sales.
                                    By accepting an order, you agree to process and provide refunds for, your goods or services through us in accordance with the Terms & Conditions of Use; the adopted Return, Cancellation, and Adjustment Policy; and this Policy. This Return, Cancellation, and Adjustment Policy shall be made available to the purchasing User before booking your services. This Return, Cancellation, and Adjustment Policy shall form a part of the legal relationship binding you to Qlason. Should there be any change to this policy, we shall notify you through any of the contact information you have provided to us in your Vendor account. For your record, a copy of the Return, Cancellation, and Adjustment Policy can be seen here.

                                </div>

                                <br />

                                <div>
                                    <b>We reserve the right to suspend or terminate your account for frequent negative reviews form Users as these may speak negatively of our Platform as a whole.</b>
                                </div>

                                <br />

                                <b>Disputes</b>
                                <br />
                                <div>
                                    All disputes between you and Qlason arising out of or relating to this Policy or breach thereof shall be settled by negotiation first.
                                    If the dispute has not been resolved by negotiation within a period of 7 (seven) days, the dispute shall be referred to and finally resolved by a sole mediator to be appointed by both Parties.
                                    Neither Vendor nor Qlasson are precluded from seeking an injunction or such order of the court against the defaulting Party, either before or upon commencement of the mediation process.

                                </div>

                                <br />

                                <b>Governing Law </b>
                                <br />
                                <div>
                                    The laws of the Federal Republic of Nigeria shall govern the validity, interpretation and performance of this Policy
                                </div>
                                <br />
                                <b>Miscellaneous</b>
                                <br />
                                <div>No failure or delay by either Qlason or Vendor in exercising any of its rights under this Agreement shall operate as a waiver thereof, nor shall any single or partial exercise preclude any further exercise of such rights unless the waiver has been reduced to writing and signed by the duly authorized representative(s) of the waiving entity.
                                    If any term or provision in this Policy shall be held to be illegal or unenforceable, in whole or in part, under any enactment or rule of law or otherwise, such term or provision (or any part thereof) shall to that extent be deemed not to form part of this Policy but the enforceability of the remainder of this Policy shall not be affected.
                                </div>

                                <br />
                                <b>Changes to this policy</b>
                                <br />
                                <div>We have the discretion to update this policy at any time. We encourage you to frequently check this policy every time you elect to accept an order. You acknowledge and agree that it is your responsibility to review this policy periodically and become aware of modifications.
                                    Changes to this policy invariably supersede any previous iterations or versions. When we change the terms of this policy, the terms will only apply to successful bookings that occur after the change has been posted.
                                </div>


                                <br />
                            </Col>
                        </div>
                    </div>
                    <br />
                    <br />
                </div>
            </Container>
        </>
    );
};

export default withDefaultLayout(OnboardingPolicy);
