/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState } from "react";
import * as queryString from "query-string";
import moment from "moment";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { DataTable, HelmetHeader, PageTitle, Pagination, Typography } from "@ekenta/components";
import { AppBoard } from "@ekenta/pages/Dashboard/common/styles";
import { VscArrowSmallLeft } from "react-icons/vsc";
import { withDashboardLayout } from "@ekenta/hoc";
import { CardGroupStyle, DataTableWrapper, RenderCard, TypographyWrapper } from "../../../common/common";
import { useAppSelector } from "@ekenta/redux/store/hook";
import { ReactComponent as OrderIcon } from "@ekenta/assets/icons/order-value-icon.svg";
import { ReactComponent as DeliveryIcon } from "@ekenta/assets/icons/delivery-fee-icon.svg";
import { ReactComponent as DocIcon } from "@ekenta/assets/icons/doc-icon.svg";
import { useFetchSinglePayoutHistoryQuery } from "@ekenta/redux/store/services/adminSellerApi";
import { StatusIndicator, TextTruncate } from "@ekenta/pages/Dashboard/common/Resuable";
import { formatCurrency } from "@ekenta/utils";
import { ReactComponent as PendingIcon } from "@ekenta/assets/icons/info-square-icon.svg";
import { ReactComponent as CompletedIcon } from "@ekenta/assets/icons/success-check-icon.svg";

const AdminSinglePayoutHistory = () => {
    const query = queryString.parse(window.location.search);
    const _page = query.page;
    const navigate = useNavigate();
    const { pathname } = useLocation();
    const params = useParams();
    const isParam = !params.id ? true : false
    const { token } = useAppSelector((state) => state.auth.authentication);
    const [pageno, setPageno] = useState(1);
    const [activeFilter, setActiveFilter] = useState('');
    const [status, setStatus] = useState('');
    const result = useFetchSinglePayoutHistoryQuery({
        pageno,
        id: params.id,
        status,
        token
    }, {
        skip: isParam
    });

    const columns = [
        {
            Header: 'Date',
            accessor: 'date_updated',
            disableSortBy: true,
        },
        {
            Header: "Name",
            accessor: "name",
            disableSortBy: true,
        },
        {
            Header: 'Email',
            accessor: 'email',
            disableSortBy: true,
        },
        {
            Header: 'Phone No',
            accessor: 'phone',
            sortType: 'basic',
        },
        {
            Header: 'Store',
            accessor: 'store',
            disableSortBy: true,
        },
        {
            Header: 'Amount',
            accessor: 'amount',
            disableSortBy: true,
        },
        {
            Header: 'status',
            accessor: 'status',
            sortType: 'basic',
        },
        {
            Header: 'Action',
            accessor: 'action',
            disableSortBy: true,
        },
    ];

    const mapData = (data: any) => {
        return data.length > 0 ? data.map((item: any) => ({
            ...item,
            date_updated: moment(item?.updated_at).format('MMM D, YYYY'),
            name: (
                <TextTruncate>{item.user.first_name + " " + item.user.last_name}</TextTruncate>
            ),
            email: item?.user?.email,
            phone: item.user ? item.user?.merchant_business?.business_phone_number : '',
            store: (
                <TextTruncate>{item.user?.merchant_business?.business_name}</TextTruncate>
            ),
            amount: formatCurrency(item?.amount, '₦'),
            status: (
                item.status ? (
                    <StatusIndicator status={item?.status} />
                ) : item?.status
            ),
        })) : [];
    };

    const handleFilter = (status: string) => {
        setActiveFilter(status.toLowerCase());
        setStatus(status);
    }

    const clearFilter = () => {
        setStatus('');
        setActiveFilter('');
    }


    return (
        <>
            <HelmetHeader
                title={result?.data ? `${result?.data.user?.merchant_business?.business_name} Payout History` : "Payout History"}
                description="Qlason Payout History Page"
            />
            <AppBoard className="_listing_product_">
                <div className="AppEvents_col mx-1 mx-lg-0">
                    <PageTitle
                        title="Payout History"
                        margin={false}
                        subTitle={
                            <div>
                                <span className="cursor-pointer d-flex w-lg-50  align-items-center text-muted"
                                    onClick={() => navigate(-1)}>
                                    <VscArrowSmallLeft />
                                    Back to {result?.data ? result?.data.user?.merchant_business?.business_name : "Loading..."}
                                </span>
                                <p className="mt-4">
                                    View analytics and seller data
                                </p>
                            </div>
                        }
                    />
                </div>
                <CardGroupStyle>
                    <RenderCard
                        icon={<OrderIcon />}
                        loading={result?.isLoading}
                        text="Total Orders Value"
                        amount={`₦${new Intl.NumberFormat().format(result?.data ? result?.data?.totalOrderValue : 0)}`}
                    />
                    <RenderCard
                        icon={<DeliveryIcon />}
                        loading={result?.isLoading}
                        text="Pending Payout Value"
                        amount={`₦${new Intl.NumberFormat().format(result?.data ? result?.data?.totalUnpaidPayout : 0)}`}
                    />
                    <RenderCard
                        icon={<DocIcon />}
                        text="Completed Payout value"
                        loading={result?.isLoading}
                        amount={`₦${new Intl.NumberFormat().format(result?.data ? result?.data?.totalPayout : 0)}`}
                    />
                </CardGroupStyle>
                <DataTableWrapper isCenter={false} className="mt-4 tab__ position-relative" >
                    <DataTable
                        theme="simple"
                        data={result?.data ? mapData(result?.data?.payouts?.data) : []}
                        loading={result?.isLoading || result?.isFetching}
                        isSelectable={true}
                        columns={columns}
                        showPagination={true}
                        hasLink={true}
                        thClassName=""
                        trClassName=""
                        theadClassName="DataTable_THead"
                        tdClassName={`Row__col payout_td`}
                        showSearch={false}
                        showFilter={true}
                        activeFilter={activeFilter}
                        clearFilter={clearFilter}
                        filterMenu={[
                            {
                                name: "Pending",
                                icon: <PendingIcon />,
                                active: false,
                                key: "pending",
                                handler: () => handleFilter("PENDING")
                            },
                            {
                                name: "Completed",
                                icon: <CompletedIcon />,
                                active: false,
                                key: "completed",
                                handler: () => handleFilter("COMPLETED")
                            },
                        ]}
                        renderTop={
                            <TypographyWrapper>
                                <Typography
                                    as="h3"
                                    color="default"
                                    size="xlarge"
                                    heading >
                                    Payouts
                                </Typography>
                            </TypographyWrapper>
                        }
                        renderPagnation={
                            <div className="mt-4">
                                {result?.data ? (
                                    <Pagination
                                        pathname={pathname}
                                        itemPerPage={result?.data?.payouts?.per_page}
                                        itemsLength={result?.data?.payouts?.total}
                                        currentPage={pageno}
                                        queryNo={_page}
                                        callback={(value: any) => setPageno(value)}
                                    />
                                )
                                    : null}
                            </div>
                        }
                    />
                    <br />
                </DataTableWrapper>
            </AppBoard>
        </>
    )
}

export default withDashboardLayout(AdminSinglePayoutHistory);