import Analytics from 'analytics';
//import googleAnalyticsPlugin from '@analytics/google-analytics'
import googleAnalytics from '@analytics/google-analytics';
import { GOOGLE_ANALYTICS_TRACKINGID } from '../constants';

const analyticsInstance = Analytics({
  app: 'qlason',
  plugins: [
    googleAnalytics({
      measurementIds: GOOGLE_ANALYTICS_TRACKINGID
        ? GOOGLE_ANALYTICS_TRACKINGID
        : [],
    }),
  ],
});

export default analyticsInstance;
