import React from 'react'
import styled from 'styled-components';
import { HelmetHeader } from '@ekenta/components';
import { withDashboardLayout } from '@ekenta/hoc';
import { device } from '@ekenta/utils/devices';
import ChatSystem from '@ekenta/components/ChatSystem';
import { useAppSelector } from '@ekenta/redux/store/hook';
import { DashboardSellerChatPathUrl } from '@ekenta/routes/constant';

const SellerChat = () => {
    const { account } = useAppSelector((state) => state.auth.authentication);

    return (
        <>
            <HelmetHeader
                title="Seller Chats"
                description="Qlason All Seller Chats Page" />
            <Box>
                <ContainerWrapper>
                    <Container>
                        <ChatSystem
                            isPopup={false}
                            userId={account.user ? account.user.id : null}
                            redirectUrl={DashboardSellerChatPathUrl}
                            from="seller"
                        />
                    </Container>
                </ContainerWrapper>

            </Box>
        </>
    )
}

const ContainerWrapper = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 90vh;
`;

const Container = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    align-items: flex-start;
    box-shadow: 0px 4px 60px 0px rgba(203, 216, 224, 0.50);


    .AllUsers_Style{
        flex-shrink: 0;
        width: 100%;
        
        @media ${device.lg}{
            width: 380px;
            
        }
    }

    .AllChat_Style{
        display: none;
        width: 100%;
        height: 100%;


        @media ${device.lg}{
           display: inline-flex;
            
        }

    }
`;

const Box = styled.div`
    margin-top: 1rem;
`;

export default withDashboardLayout(SellerChat);