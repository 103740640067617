/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  AccountAddresses,
  BuyerOrders,
  Chats,
  NewReturnRequest,
  PendingReviews,
  Profile,
  RatingAndReviews,
  Referrals,
  ReturnRequest,
  SavedItem,
  Voucher,
} from '@ekenta/pages/Account';

import {
  accountProfilePathUrl,
  addressesPathUrl, // inboxPathUrl,
  // WalletSystemPathUrl,
  returnRequestPathUrl,
  newreturnRequestPathUrl,
  checkoutOnePagePathUrl,
  checkoutCallbackPagePathUrl,
  cartPathUrl,
  savedItemPathUrl,
  ordersPathUrl,
  pendingReviewsPathUrl,
  ratingAndReviewsPathUrl,
  checkoutVerifyPagePathUrl,
  referralsPathUrl,
  ChatPathUrl,
  voucherPathUrl,
} from '../constant';
import { Cart, CheckoutCallbackPage, CheckoutVerifyPage } from '@ekenta/pages';
import CheckoutOnePage from '@ekenta/pages/Checkout';
import { Roles } from '../roleConfig';

export const AuthorizeUserRoutes: {
  path: string;
  component: (props?: any) => JSX.Element;
  displayName?: string;
  roles: string[];
}[] = [
  {
    path: accountProfilePathUrl,
    component: Profile,
    roles: [Roles.ROLE_USER, Roles.ROLE_SELLER, Roles.ROLE_ADMIN],
  },
  {
    path: cartPathUrl,
    component: Cart,
    roles: [Roles.ROLE_USER, Roles.ROLE_SELLER, Roles.ROLE_ADMIN],
  },
  {
    path: addressesPathUrl,
    component: AccountAddresses,
    roles: [Roles.ROLE_USER, Roles.ROLE_SELLER, Roles.ROLE_ADMIN],
  },
  // { path: inboxPathUrl, component: Inbox,roles: [Roles.ROLE_USER,]},
  // { path: WalletSystemPathUrl, component: WalletSystem,roles: [Roles.ROLE_USER,]},
  {
    path: referralsPathUrl,
    component: Referrals,
    roles: [Roles.ROLE_USER, Roles.ROLE_SELLER, Roles.ROLE_ADMIN],
    },
   {
    path: voucherPathUrl,
    component: Voucher,
    roles: [Roles.ROLE_USER, Roles.ROLE_SELLER, Roles.ROLE_ADMIN],
  },
  {
    path: returnRequestPathUrl,
    component: ReturnRequest,
    roles: [Roles.ROLE_USER, Roles.ROLE_SELLER, Roles.ROLE_ADMIN],
  },
  {
    path: newreturnRequestPathUrl,
    component: NewReturnRequest,
    roles: [Roles.ROLE_USER, Roles.ROLE_SELLER, Roles.ROLE_ADMIN],
  },
  {
    path: savedItemPathUrl,
    component: SavedItem,
    roles: [Roles.ROLE_USER, Roles.ROLE_SELLER, Roles.ROLE_ADMIN],
  },
  {
    path: ordersPathUrl,
    component: BuyerOrders,
    roles: [Roles.ROLE_USER, Roles.ROLE_SELLER, Roles.ROLE_ADMIN],
  },
  {
    path: checkoutOnePagePathUrl,
    component: CheckoutOnePage,
    roles: [Roles.ROLE_USER, Roles.ROLE_SELLER, Roles.ROLE_ADMIN],
  },
  {
    path: checkoutCallbackPagePathUrl,
    component: CheckoutCallbackPage,
    roles: [Roles.ROLE_USER, Roles.ROLE_SELLER, Roles.ROLE_ADMIN],
  },
  {
    path: checkoutVerifyPagePathUrl,
    component: CheckoutVerifyPage,
    roles: [Roles.ROLE_USER, Roles.ROLE_SELLER, Roles.ROLE_ADMIN],
  },

  {
    path: pendingReviewsPathUrl,
    component: PendingReviews,
    roles: [Roles.ROLE_USER, Roles.ROLE_SELLER, Roles.ROLE_ADMIN],
  },
  {
    path: ratingAndReviewsPathUrl,
    component: RatingAndReviews,
    roles: [Roles.ROLE_USER, Roles.ROLE_SELLER, Roles.ROLE_ADMIN],
  },
  {
    path: ChatPathUrl,
    component: Chats,
    roles: [Roles.ROLE_USER, Roles.ROLE_SELLER, Roles.ROLE_ADMIN],
  },

  //
];

export default AuthorizeUserRoutes;
