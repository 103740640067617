/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState } from 'react'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import toast from 'react-hot-toast'
import styled from '@emotion/styled'
import { REACT_APP_ENV } from '@ekenta/constants'
import { Button, ButtonGroup, CustomModal, Field } from '@ekenta/components'
import { useCreateVoucherMutation } from '@ekenta/redux/store/features/voucher/voucherApiSlice'
import { useAppSelector } from '@ekenta/redux/store/hook'
import FlutterwavePay from '@ekenta/components/FlutterwavePay/FlutterwavePay'

interface FormValue {
  recipientName: string
  recipientEmail: string
  cost: string
  description: string
}

interface createType {
  cost: number
  transaction_ref: string
  recipient_email: string
  recipient_name: string
  isTest: boolean
}

const CreateVoucher = (props: { isOpen: boolean; onClose: () => void }) => {
  const { isOpen, onClose } = props
  const { account } = useAppSelector((state) => state.auth.authentication)
  const [loading, setLoading] = useState(false)

  const [createVoucher, { isLoading }] = useCreateVoucherMutation()

  const validationSchema = Yup.object().shape({
    recipientName: Yup.string().trim().required('Recipient Name is required'),
    recipientEmail: Yup.string().trim().required('Recipient Email is required'),
    cost: Yup.string().required('Voucher Amount is required'),
    description: Yup.string().trim().required('Description is required'),
  })

  const isTestEnv = REACT_APP_ENV === 'PRODUCTION' ? false : true

  const formik = useFormik<FormValue>({
    initialValues: {
      cost: '',
      recipientName: '',
      recipientEmail: '',
      description: '',
    },
    validationSchema: validationSchema,
    onSubmit: () => {
      console.log('onSubmit')
    },
  })

  const initializeFlutterwave = async (values: FormValue, response: any) => {
    const data = {
      cost: response.charged_amount,
      transaction_ref: response.transaction_id,
      recipient_email: values.recipientEmail,
      recipient_name: values.recipientName,
      isTest: isTestEnv,
    }
    await onCreate(data)
  }

  const onCreate = async (data: createType) => {
    await createVoucher(data)
      .unwrap()
      .then(() => {
        setLoading(false)
        toast.success('Voucher created successfully', {
          position: 'bottom-center',
        })
        onClose()
      })
      .catch((error) => {
        setLoading(false)
        const message = error?.data?.detail || 'Something went wrong'
        toast.error(message, { position: 'bottom-center' })
      })
  }

  return (
    <CustomModal
      show={isOpen}
      size="sm"
      title="Create Voucher"
      subtitle=""
      centered={true}
      showFooter={false}
      isLoading={false}
      isDisabled={false}
      headerClass="app-modalheader event-modalhead"
      modalClass="app-modalbody"
      onHide={onClose}
      render={
        <ModalContent>
          <div className="modal--body">
            <form className="form-field">
              <Field
                id="cost"
                name="cost"
                label="Voucher Amount"
                disabled={loading || isLoading}
                type="number"
                required
                size="large"
                thousandSeparator={true}
                numberFieldPrefix="₦ "
                childOutsideLabel={false}
                placeholder=""
                fieldClassName=""
                autoComplete="off"
                onBlur={formik.handleBlur}
                value={formik.values.cost}
                isError={formik.errors.cost ? true : false}
                error={formik.errors.cost}
                onChange={(value) => {
                  formik.setFieldValue('cost', value.value)
                }}
              />
              <Field
                id="recipientName"
                name="recipientName"
                label="Recipient Name"
                disabled={loading || isLoading}
                type="text"
                required
                size="large"
                childOutsideLabel={false}
                placeholder=""
                fieldClassName=""
                autoComplete="off"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.recipientName}
                isError={
                  formik.touched.recipientName && formik.errors.recipientName
                    ? true
                    : false
                }
                error={formik.errors.recipientName}
              />
              <Field
                id="recipientEmail"
                name="recipientEmail"
                label="Recipient Email Address"
                disabled={loading || isLoading}
                type="text"
                required
                size="large"
                childOutsideLabel={false}
                placeholder=""
                fieldClassName=""
                autoComplete="off"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.recipientEmail}
                isError={
                  formik.touched.recipientEmail && formik.errors.recipientEmail
                    ? true
                    : false
                }
                error={formik.errors.recipientEmail}
              />
              <Field
                id="description"
                name="description"
                label="Description"
                disabled={loading || isLoading}
                type="textarea"
                required
                size="large"
                childOutsideLabel={false}
                placeholder=""
                fieldClassName=""
                autoComplete="off"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.description}
                isError={
                  formik.touched.description && formik.errors.description
                    ? true
                    : false
                }
                error={formik.errors.description}
              />
              <ButtonGroup className="cta-group">
                <Button
                  name="Continue Shopping"
                  type="button"
                  className="shopping-btn"
                  bgVariant=""
                  disabled={loading || isLoading}
                  defaultClass={false}
                  onClick={onClose}
                />
                <FlutterwavePay
                  amountToPay={parseFloat(formik.values.cost)}
                  customerEmail={account.user.email}
                  customerName={account.user.firstName}
                  customerPhoneNumber={account.user.phoneNumber}
                  descriptionOfPayment="Payment for new Qlason Voucher"
                  callback={(response) => {
                    initializeFlutterwave(formik.values, response)
                  }}
                  disabled={
                    !formik.values.cost ||
                    !formik.values.recipientEmail ||
                    !formik.values.recipientName ||
                    !formik.values.description
                  }
                  loading={loading}
                  setLoading={setLoading}
                />
              </ButtonGroup>
            </form>
          </div>
        </ModalContent>
      }
    />
  )
}

const ModalContent = styled.div`
  width: 100%;
  height: auto;
  border-top: 1px solid #eeeeee;
  padding: 5px 20px;

  .modal--body {
    padding-top: 10px;
    .form-field {
      display: flex;
      flex-direction: column;
      gap: 5px;
      width: 100%;
      height: auto;

      .cta-group {
        width: 100%;
        display: flex;
        justify-content: space-between;
        gap: 15px;

        button {
          width: 100% !important;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 36.128px;
          height: 50px !important;
          padding: 18px 16px 18px 16px !important;
          border: none !important;
          transition: all 0.3s ease-out;
          gap: 4px !important;

          span {
            font-size: 14px !important;
            font-family: var(--eke-body-font-family) !important;
            font-weight: var(--eke-font-weight-bold) !important;
            margin-top: 0px;
          }
        }

        .shopping-btn {
          color: var(--eke-bg-primary-color) !important;
          background-color: var(--eke-color-primary-100) !important;
          box-shadow: none !important;
        }

        .cta-btn {
          background-color: var(--eke-color-primary) !important;
          box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.1);
        }
      }
    }
  }
`
export default CreateVoucher
