import React from 'react'

const SvgSquarePlus = ({
    width = "1rem",
    height = "1rem",
    className
}: {
    className?: string,
    width?: string;
    height?: string
}) => (
    <svg
        width={width}
        height={height}
        className={className}
        role="img"
        viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g id="Plus">
            <path id="Fill 1" fillRule="evenodd" clipRule="evenodd" d="M10.75 15.1543C10.336 15.1543 10 14.8183 10 14.4043V7.0773C10 6.6633 10.336 6.3273 10.75 6.3273C11.164 6.3273 11.5 6.6633 11.5 7.0773V14.4043C11.5 14.8183 11.164 15.1543 10.75 15.1543Z" fill="" />
            <path id="Fill 3" fillRule="evenodd" clipRule="evenodd" d="M14.4165 11.4902H7.0835C6.6685 11.4902 6.3335 11.1542 6.3335 10.7402C6.3335 10.3262 6.6685 9.9902 7.0835 9.9902H14.4165C14.8305 9.9902 15.1665 10.3262 15.1665 10.7402C15.1665 11.1542 14.8305 11.4902 14.4165 11.4902Z" fill="" />
            <path id="Fill 5" fillRule="evenodd" clipRule="evenodd" d="M6.064 1.5C3.292 1.5 1.5 3.397 1.5 6.335V15.165C1.5 18.103 3.292 20 6.064 20H15.436C18.209 20 20 18.103 20 15.165V6.335C20 3.397 18.209 1.5 15.436 1.5H6.064ZM15.436 21.5H6.064C2.437 21.5 0 18.954 0 15.165V6.335C0 2.546 2.437 0 6.064 0H15.436C19.063 0 21.5 2.546 21.5 6.335V15.165C21.5 18.954 19.063 21.5 15.436 21.5Z" fill="" />
        </g>
    </svg>

)

export default SvgSquarePlus;