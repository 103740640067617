import React, { FC } from 'react'
import styled from 'styled-components'
import { ReactComponent as ArrowSquare } from '@ekenta/assets/icons/arrow-right-square.svg'
import { Link } from 'react-router-dom'
import moment from 'moment'
import { device } from '@ekenta/utils/devices'

type PropType = {
  title: string
  slug: string
  date: string
  summary: string
  image_url: string
  written_by: string
  direction?: string
  imgH?: string
  imgW?: string
  imgFlexGrow?: number
  paragraphWidth?: string
}
const Post: FC<PropType> = (props) => {
  const {
    title,
    slug,
    date,
    image_url,
    summary,
    written_by,
    direction = 'row',
    imgW = '100%',
    imgH = '282px',
    imgFlexGrow = '',
    paragraphWidth = '',
  } = props

  const url = `/blog/${slug}`

  return (
    <Flex direction={direction} paragraphWidth={paragraphWidth} gap="24px">
      <ImageBox
        flexGrow={imgFlexGrow}
        as={Link}
        to={url}
        width={imgW}
        height={imgH}
      >
        <img src={image_url} alt={title} />
      </ImageBox>
      <Content direction={direction}>
        <div>
          <span>
            {written_by} • {date && moment(date).format('DD MMM, YYYY')}
          </span>
        </div>
        <h2>
          <Link to={url}>{title}</Link>
        </h2>
        <div className="post--paragraph-box">
          <p>{summary}</p>
        </div>
        <MoreBtn as={Link} to={url}>
          <span>Read Blog</span>
          <ArrowSquare />
        </MoreBtn>
      </Content>
    </Flex>
  )
}

const Flex = styled.div<{
  direction: string
  paragraphWidth: string
  gap: string
}>`
  display: flex;
  flex-direction: column;
  gap: ${(props) => props.gap};

  p {
    width: 100%;
  }

  @media ${device.md} {
    flex-direction: row;
  }

  @media ${device.lg} {
    flex-direction: ${(props) => props.direction};

    p {
      width: ${(props) =>
        props.paragraphWidth
          ? props.paragraphWidth
          : props.direction === 'row'
          ? '248px'
          : 'auto'};
    }
  }
`

const ImageBox = styled.div<{
  width: string
  height: string
  flexGrow: number | string
}>`
  width: ${(props) => (props.width ? props.width : '100%')};
  flex-shrink: 1;
  flex: ${(props) => props.flexGrow};
  height: ${(props) => props.height};
  border-radius: 8px;

  img {
    width: 100% !important;
    height: 100% !important;
    object-fit: cover;
    border-radius: 8px;
  }

  @media ${device.md} {
    width: auto !important;
    height: auto !important;
  }
`
const Content = styled.div<{ direction: string }>`
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: 8px;
  font-family: var(--eke-body-font-family) !important;

  @media ${device.md} {
    flex: 1.8 !important;
  }

  @media ${device.lg} {
    width: ${(props) => (props.direction === 'row' ? '350px' : '100%')};
  }

  span {
    font-weight: 700;
    color: var(--eke-color-primary);
  }

  h2 {
    font-weight: 700;
    color: #101828;
    display: -webkit-box;
    text-decoration: none;
    font-size: 24px;
    margin: 0 !important;
    a {
      text-decoration: none;
      color: #101828;
    }
  }
  p {
    display: block;
    width: 100%;
    display: -webkit-box;
    text-decoration: none;
    color: #475467;
    font-weight: 400;
    font-size: 16px;
    padding-right: 15px;
    height: ${(props) => (props.direction === 'row' ? '68px' : '48px')};
    margin-bottom: 2px !important;
    text-overflow: ellipsis !important;
    line-height: 1.5;
    -webkit-line-clamp: ${(props) => (props.direction === 'row' ? 3 : 2)};
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-transform: capitalize;
    line-height: 140%; /* 27.404px */
    letter-spacing: 0.241px;
  }
`

const MoreBtn = styled.a`
  display: flex;
  align-items: center;
  font-weight: 600;
  color: var(--eke-color-primary);
  font-family: var(--eke-body-font-family);
  gap: 5.36px;
  text-decoration: none;

  span {
    font-size: 16px;
    letter-spacing: 0.2px;
    line-height: 22.4px;
  }
`

export default Post
