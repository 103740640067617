/* eslint-disable @typescript-eslint/no-explicit-any */
import Loader from '@ekenta/components/Loader/Loader'
import { isVideoUrl } from '@ekenta/utils'
import moment from 'moment'
import React from 'react'
import styled, { css } from 'styled-components'

const ChatBubbles = (props: {
  isDateCurrent: boolean
  from: 'admin' | 'seller' | 'buyer'
  loading: boolean | undefined
  currentUser: number
  messages: any
}) => {
  const { messages, currentUser, loading } = props

  return (
    <>
      {/* {!props.isDateCurrent && messages.length > 0 ? (
        <DateGroup>
          <DateText>July 31, 2023</DateText>
        </DateGroup>
      ) : null} */}

      {messages ? (
        <ChatBubbleWrapper>
          {messages.length > 0 ? (
            messages.map((item: any) => {
              const userNameMatch = parseInt(item.sender_id) === currentUser

              return (
                <ChatBubble
                  key={item.id}
                  currentuser={userNameMatch}
                  from={userNameMatch ? 'true' : 'false'}
                >
                  <h5>{item.sender_name}</h5>
                  <div className="message-container">
                    {item.type === 'TEXT' ? (
                      <p>{item.message}</p>
                    ) : item.type === 'FILE' && isVideoUrl(item.message) ? (
                      <div className="video-container">
                        <Video controls>
                          <source src={item.message} type="video/mp4" />
                          Your browser does not support the video tag.
                        </Video>
                      </div>
                    ) : item.type === 'FILE' && !isVideoUrl(item.message) ? (
                      <div className="image-container">
                        <img src={item.message} alt="photo" />
                      </div>
                    ) : null}
                    <Date>{moment(item.created_at).format('hh:mm a')}</Date>
                  </div>
                </ChatBubble>
              )
            })
          ) : (
            <HStack>
              <div>Start a conversation</div>
            </HStack>
          )}
          {loading ? <Loader size="large" /> : null}
        </ChatBubbleWrapper>
      ) : null}
    </>
  )
}

// const DateGroup = styled.div`
//   display: flex;
//   justify-content: center;
//   margin-top: 18px;
//   margin-bottom: 10px;
// `;

// const DateText = styled.p`
//   color: var(--neutral-body-text, #747a8b);
//   text-align: center;
//   font-family: Outfit;
//   font-size: 12px;
//   font-style: normal;
//   font-weight: 400;
//   line-height: 150%; /* 18px */
//   letter-spacing: -0.24px;
//   text-transform: uppercase;
// `;

const Date = styled.p`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 10px;
  margin-bottom: 0;
  color: #fff;
  font-family: var(--eke-body-font-family);
  font-weight: var(--eke-font-weight-normal);
  font-size: 10px;
  line-height: 150%; /* 18px */
  letter-spacing: -0.24px;
`

const ChatBubbleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0.5px 15px;
`

const ChatBubble = styled.div<{ currentuser: boolean; from: string }>`
  max-width: 80%;
  min-width: 40%;
  width: auto;
  padding: 8px 12px !important;
  margin: 10px 17px !important;
  background: ${(props) =>
    props.from === 'true'
      ? 'var(--gradients-gradient-green, linear-gradient(286deg, #22BB9C 0%, #35DEBC 100%))'
      : 'var(--primary-500, #F8880A)'};
  border-radius: 0px 16px 16px 16px;
  align-self: flex-start;

  h5 {
    font-size: 13.5px;
    font-weight: 700;
  }

  .image-container {
    width: 200px;

    img {
      width: 70%;
      height: auto;
      object-fit: cover;
    }
  }

  .video-container{
    width: 200px;
    height: auto;
  }

  ${(props) =>
    props.currentuser &&
    css`
      border-radius: 16px 16px 0px 16px !important;
      align-self: flex-end !important;
    `}

  color: #FFF;
  font-family: var(--eke-body-font-family);
  font-weight: var(--eke-font-weight-normal);
  font-size: 13px !important;
  line-height: 150%; /* 24px */
  letter-spacing: -0.32px;

  p {
    margin: 0 !important;
  }
`

const HStack = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;

  div {
    display: flex;
    width: 80%;
    margin: 0 auto;
    padding: 10px;
    height: 47px;
    padding: 8px;
    align-items: center;
    justify-content: center;
    gap: 4px;
    text-align: center;
    background: #f0f0f0;

    color: var(--neutral-body-text, #747a8b);
    text-align: center;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 19.5px */
    letter-spacing: -0.26px;
  }
`

const Video = styled.video`
  width: 100%; /* Adjust the width as needed */
  height: 150px;
`

export default ChatBubbles
