import React from 'react';
import { Col } from 'react-bootstrap';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

export const AllLiveCardLoader = () => {
  return (
    <>
      <SkeletonCard />
      <SkeletonCard />
      <SkeletonCard />
      <SkeletonCard />
      <SkeletonCard />
    </>
  );
};

const SkeletonCard = () => {
  return (
    <Col sm={12} md={6} lg={3} className="mt-1 mb-1">
      <div className="image mb-1">
        <Skeleton height={150} className="w-100" />
      </div>
      <div className="name">
        <Skeleton width={150} height={17} />
        <Skeleton width={50} height={11} />
      </div>
    </Col>
  );
};
