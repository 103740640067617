/* eslint-disable @typescript-eslint/no-explicit-any */
import { Button, CardBox, Typography } from '@ekenta/components';
import React from 'react'
import classnames from 'classnames';
import SVG from "react-inlinesvg";
import outlineCheck from "@ekenta/assets/icons/outline-check.svg";


import styles from "./ListItem.module.scss";
import moment from 'moment';
import { useNavigate } from 'react-router-dom';

const ListItem = (props: any) => {
    const { item, orderNo, deliveryDate } = props;
    const navigate = useNavigate();

    return (
        <CardBox
            className={styles.CardBox_Review}>
            <div className={styles.CardBox_Review__Image}>
                <img
                    src={item.product.images ? item.product.images[0].image_url : ""}
                    width="100"
                    height="100"
                    alt={item.product.name}
                />
            </div>
            <div className={styles.Item_Typography}>
                <div>
                    <Typography
                        as="h3"
                        size="medium"
                        color="dark"
                        className=""
                        uiText>
                        {item.product.name}
                    </Typography>
                    <div style={{ marginTop: "19px" }}>
                        <div className={classnames(styles.FlexBox, styles.Typography__Group)}>
                            <Typography
                                as="span"
                                size="small"
                                color="secondary"
                                className=""
                                uiText>
                                Order No :
                            </Typography>
                            <Typography
                                as="span"
                                size="small"
                                color="secondary"
                                className={styles.OrderNo}
                                uiText>
                                {orderNo}
                            </Typography>
                        </div>
                        <div className={styles.CompletedOrder}>
                            <SVG src={outlineCheck} />
                            <Typography
                                as="span"
                                size="smallest"
                                color="success"
                                uiText>
                                Delivered on {moment(deliveryDate).format("MM-DD-YYYY")}
                            </Typography>
                        </div>
                    </div>
                </div>
                <Button
                    name="Rate this product"
                    shadow={false}
                    type="button"
                    bgVariant="primary"
                    textVariant="light"
                    className={classnames(styles.FlexItem__Button, "p-y")}
                    disabled={false}
                    onClick={() => {
                        navigate(`/user/account/ratings-and-reviews/${item.product.id}?order=${orderNo}`);
                    }}
                />
            </div>

        </CardBox>
    )
}

export default ListItem;