import React from 'react'
import moment from 'moment'
import { CellProps } from 'react-table'
import styled from '@emotion/styled'

interface RowData {
  id: string
  cost: number
  status: string
  code: string
  recipient_name: string
  created_at: string
}

export const voucherColumn = [
  {
    Header: 'S/N',
    accessor: 's/n',
    sortType: 'basic',
  },
  {
    Header: 'Date Purchased',
    accessor: 'date',
    sortType: 'basic',
    Cell: ({ cell: { row } }: CellProps<RowData, string>) => (
      <span>{moment(row?.original?.created_at).format('DD/MM/YYYY')}</span>
    ),
  },
  {
    Header: 'Recipient Name',
    accessor: 'name',
    Cell: ({ cell: { row } }: CellProps<RowData, string>) => (
      <HStack>
        <span>{row?.original.recipient_name}</span>
      </HStack>
    ),
  },
  {
    Header: 'Voucher Code',
    accessor: 'voucher_code',
    sortType: 'basic',
    Cell: ({ cell: { row } }: CellProps<RowData, string>) => (
      <span>{row?.original.code}</span>
    ),
  },
  {
    Header: 'Voucher Amount',
    accessor: 'voucher_amount',
    sortType: 'basic',
    Cell: ({ cell: { row } }: CellProps<RowData, string>) => (
      <span>{row?.original.cost}</span>
    ),
  },
  {
    Header: 'Status',
    accessor: 'status',
    sortType: 'basic',
  },
]

const HStack = styled.div``
