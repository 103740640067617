/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { Fragment, useEffect, useState } from 'react';
import { getIn, useFormikContext } from 'formik';
import styled from 'styled-components';
import { NewProductListingFormType } from '@ekenta/interfaces/ProductListing';
import { AddCard, CancelBtn, StepContainer, StepContentStyle, StepFooter, StepFormGroup, StepHeading } from '../style';
import { ReactComponent as CancelIcon } from '@ekenta/assets/icons/cancel-icon.svg';
import { Button, Field, Label, Typography } from '@ekenta/components';
import { SquarePlus } from '@ekenta/components/icons';
import { useAppSelector } from '@ekenta/redux/store/hook';
import { useFetchFeaturesByColIdQuery } from '@ekenta/redux/store/services/collectionApi';
import CustomSelect from '@ekenta/components/Select/CustomSelect';
import { VscArrowSmallRight, VscTrash } from 'react-icons/vsc';


const mapVariantData = (data: any) => {
  return data && data.map((item: any) => (
    {
      value: item.id,
      label: item.name
    }
  ));

}


const ProductVariant = (props: { nextPage: () => void; isLoading: boolean }) => {
  const { nextPage } = props;
  const { values, errors, touched, setFieldValue, setFieldTouched, handleChange, handleBlur } = useFormikContext<NewProductListingFormType>();
  const { token } = useAppSelector(state => state.auth.authentication);

  const [featuresOptions, setFeaturesOptions] = useState<any>([]);
  const { data, isLoading, isError: feature_isError, isSuccess }
    = useFetchFeaturesByColIdQuery({
      token,
      id: values.collectionId
    }, {
      skip: !values.collectionId
    });

  const defaultValue = (options: any, val: string) => {
    return options ? options.find((option: any) => option.value === parseInt(val)) : "";
  }

  useEffect(() => {
    if (isSuccess) {
      const obj = mapVariantData(data.features);
      setFeaturesOptions(obj);
    }
  }, [isSuccess]);

  useEffect(() => {
    variantData();
  }, [])


  const handleAddVariant = () => setFieldValue('variants',
    [
      ...values.variants,
      {
        price: values.defaultprice,
        features: [
          {
            feature_id: "",
            feature_value: ""
          }
        ]
      },
    ]
  );

  const handleRemoveVariant = (index: number) => {
    const variants = [...values.variants];
    variants.splice(index, 1);
    setFieldValue(`variants`, variants);
  }

  const handleAddFeatureField = (index: number) => {
    setFieldValue(`variants.${index}.features`,
      [
        ...values.variants[index].features,
        {
          feature_id: '',
          feature_value: '',
        }
      ]
    );

  }

  const handleRemoveFeatureField = (index: number, singleFeatureIndex: number) => {
    const features = [...values.variants[index].features];
    features.splice(singleFeatureIndex, 1);
    setFieldValue(`variants.${index}.features`, features);
  }

  const variantData = () => {
    if (values.variants.length > 0) {
      const result = values.variants.map(item => ({ ...item, price: values.defaultprice }));
      setFieldValue("variants", result);
    }
  }

  return (
    <StepContainer>
      <Flex>
        <StepHeading>
          <Typography
            as="h3"
            color="default"
            size="xlarge"
            heading >
            Product Variants
          </Typography>
          <Typography
            as="p"
            color="default"
            size="small">
            Add variants if this product comes in multiple versions, like different sizes or colours
          </Typography>
        </StepHeading>

        <AddCard type="button" onClick={() => handleAddVariant()}>
          <SquarePlus />
          <span>Add Variant</span>
        </AddCard>
      </Flex>
      <StepContentStyle mb="30px">
        {values.variants.length > 0 && values.variants.map((item, index) => (
          <VariantGroup key={index}>
            {values.variants.length > 0 && (
              <CancelBtn className="cancelbtn" pos="relative" type="button"
                onClick={() => handleRemoveVariant(index)}>
                <CancelIcon />
              </CancelBtn>
            )}
            <Field
              id="variantprice"
              name={`variants.${index}.price`}
              label="Variant Price"
              disabled={false}
              type="number"
              required
              size="large"
              numberFieldPrefix="₦ "
              thousandSeparator={true}
              childOutsideLabel={false}
              placeholder="Enter Variant Price"
              fieldClassName=""
              autoComplete="off"
              onBlur={handleBlur}
              value={`${item.price}`}
              isError={
                getIn(touched, `variants.${index}.price`) &&
                  getIn(errors, `variants.${index}.price`) ? true : false
              }
              error={getIn(errors, `variants.${index}.price`)}
              onChange={(value: any) => {
                setFieldValue(`variants.${index}.price`, value.value);
              }}

            />
            {item.features && item.features.map((feature, i) => (
              <Fragment key={i}>
                <Stack>
                  <StepFormGroup>
                    <Label
                      type="text"
                      text="Features"
                      required={true}
                    />
                    <CustomSelect
                      name={`variants.${index}.features.${i}.feature_id`}
                      isDisabled={!values.collectionId ? true : false}
                      className="custom-select"
                      placeholder="Select Features"
                      noOptionsMessage={() => feature_isError ? 'Could not load, refresh' : 'No Features Found'}
                      isLoading={isLoading}
                      value={data ? defaultValue(featuresOptions, feature.feature_id) : ""}
                      options={featuresOptions}
                      onBlur={handleBlur}
                      isError={getIn(touched, `variants.${index}.features.${i}.feature_id`) && getIn(errors, `variants.${index}.features.${i}.feature_id`) ? true : false}
                      error={getIn(errors, `variants.${index}.features.${i}.feature_id`)}
                      onChanged={(value: any) => {
                        setFieldTouched(`variants.${index}.features.${i}.feature_id`, true)
                        value ? setFieldValue(`variants.${index}.features.${i}.feature_id`, value.value) :
                          setFieldValue(`variants.${index}.features.${i}.feature_id`, '')
                      }}
                    />
                  </StepFormGroup>
                  <StepFormGroup>
                    <Field
                      id="variant_feature_value"
                      name={`variants.${index}.features.${i}.feature_value`}
                      disabled={!values.collectionId ? true : false}
                      label="Feature Value"
                      type="text"
                      required
                      size="large"
                      childOutsideLabel={false}
                      placeholder="Enter Variant Value"
                      fieldClassName=""
                      autoComplete="off"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={feature.feature_value.toLowerCase()}
                      isError={
                        getIn(touched, `variants.${index}.features.${i}.feature_value`) &&
                          getIn(errors, `variants.${index}.features.${i}.feature_value`) ? true : false
                      }
                      error={getIn(errors, `variants.${index}.features.${i}.feature_value`)}
                    />
                  </StepFormGroup>
                  {item.features.length > 1 && (
                    <CancelBtn pos="relative" type="button" onClick={() => handleRemoveFeatureField(index, i)}>
                      <VscTrash color="#F75555" fontSize="1.2rem" />
                    </CancelBtn>
                  )}
                </Stack>
                {item.features.length - 1 === i && item.features.length < 3 && (
                  <AddCard type="button" mt="28px"
                    disabled={!values.collectionId ? true : false}
                    onClick={() => handleAddFeatureField(index)}>
                    <SquarePlus />
                    <span>Add More</span>
                  </AddCard>
                )}
              </Fragment>
            ))}
          </VariantGroup>
        ))}
      </StepContentStyle>
      <StepFooter>
        <Button
          name="Proceed"
          textVariant="light"
          width="w-100"
          bgVariant="primary"
          className="PageStep__Btn"
          disabled={
            props.isLoading ||
            !values.variants ||
            getIn(errors, 'variants')
          }
          shadow={false}
          suffixIcon={<VscArrowSmallRight />}
          type="button"
          onClick={nextPage}
        />

      </StepFooter>
    </StepContainer>
  )
}

const Flex = styled.div`
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
`;
const Stack = styled.div`
    display: flex;
    width: 100%;
    height: auto;
    margin-top: 13px;
    align-items: flex-start;
    gap:5px;

    button{
        align-self: center !important;
        height: 100%;
        //margin-bottom: 5px !important;
        flex: 0 !important;

        svg{
            width: fit-content !important;
            height: fit-content !important;
        }

    }
`;

const VariantGroup = styled.div`
 position: relative;
  width: 100%;
  height: auto;
  padding-top: 10px;
  padding-bottom: 20px;
  margin-bottom: 10px;
  border-bottom: 0.5px solid var(--greyscale-300, #E0E0E0);
  .cancelbtn{
    position: absolute !important;
    right: 0 !important;
    top: 0;
  }

  &:last-child {
  border-bottom: none;
  }

  &:nth-child(1){
    padding-top: 5px !important;
  }
`;

export default ProductVariant