/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react'
import styled from 'styled-components';
import { AddProductAsideStyle } from '../../style'
import { Checkbox } from '@ekenta/components';
import { VscChevronRight } from 'react-icons/vsc';

interface AsideStepperType {
    page: number;
    setPage: any;
    isLoading: boolean
}


const AsideStepper = ({ page, setPage, isLoading }: AsideStepperType) => {

    const handleStepClick = (step: number) => {
        if (step > page) {
            return; // Do not update the active step if the clicked step is greater
        }
        setPage(step);
    };

    return (
        <AddProductAsideStyle>
            <List>
                <ListItem active={page === 0} disabled={isLoading ? isLoading : 0 > page}>
                    <label title="Product collection details" htmlFor="collection"
                        onClick={() => handleStepClick(0)}>
                        <Checkbox
                            id="collection"
                            name="collection"
                            value={page >= 0}
                            className=""
                            readOnly
                        />
                        <div className="contentStyle_group">
                            <div className="content">
                                <h5>Product Collection</h5>
                                <p>Add a main, sub, sub-category</p>
                            </div>
                            <VscChevronRight className="icon-arrow" />
                        </div>
                    </label>
                </ListItem>
                <ListItem active={page === 1} disabled={isLoading ? isLoading : 1 > page}>
                    <label title="Product Details" htmlFor="details"
                        onClick={() => handleStepClick(1)}>
                        <Checkbox
                            id="details"
                            name="details"
                            value={page >= 1}
                            className=""
                        />
                        <div className="contentStyle_group">
                            <div className="content">
                                <h5>Product Details</h5>
                                <p>Add your product details</p>
                            </div>
                            <VscChevronRight className="icon-arrow" />
                        </div>

                    </label>
                </ListItem>
                <ListItem active={page === 2} disabled={isLoading ? isLoading : 2 > page}
                    onClick={() => handleStepClick(2)}>
                    <label title="Product Prices" htmlFor="prices">
                        <Checkbox
                            id="prices"
                            name="prices"
                            value={page >= 2}
                            className=""
                        />
                        <div className="contentStyle_group">
                            <div className="content">
                                <h5>Product Prices</h5>
                                <p>Add your product prices</p>
                            </div>
                            <VscChevronRight className="icon-arrow" />
                        </div>
                    </label>
                </ListItem>
                <ListItem active={page === 3} disabled={isLoading ? isLoading : 3 > page}
                    onClick={() => handleStepClick(3)}>
                    <label title="Product Variant" htmlFor="variants">
                        <Checkbox
                            id="variants"
                            name="variants"
                            value={page >= 3}
                            className=""
                        />
                        <div className="contentStyle_group">
                            <div className="content">
                                <h5>Product Variant</h5>
                                <p>Add variants</p>
                            </div>
                            <VscChevronRight className="icon-arrow" />
                        </div>
                    </label>
                </ListItem>
                <ListItem active={page === 4} disabled={isLoading ? isLoading : 4 > page}
                    onClick={() => handleStepClick(4)}>
                    <label title="Other Product" htmlFor="otherInfo">
                        <Checkbox
                            id="otherInfo"
                            name="otherInfo"
                            value={page >= 4}
                            className=""
                        />
                        <div className="contentStyle_group">
                            <div className="content">
                                <h5>Other Product Info.</h5>
                                <p>Add other product info</p>
                            </div>
                            <VscChevronRight className="icon-arrow" />
                        </div>
                    </label>
                </ListItem>
            </List>
        </AddProductAsideStyle>
    )
}

const List = styled.ul`
    display: flex;
    width: 90%;
    align-items: flex-start;
    flex-direction: column;
    gap: 0.1em;
    list-style: none;
    padding: 0 !important;
    margin: 0  auto !important;
`;

const ListItem = styled.li<{ active: boolean; disabled: boolean }>`
    display: block;
    width: 100%;
    font-family: var(--eke-body-font-family);
    padding-bottom: 5px;
    border-bottom: 1px solid var(--greyscale-200, #f2f2ff) !important;
    &:last-child{
        border-bottom: none !important;
    }
    //padding: 0px 0px 0px 0px;

    label{
        position: relative;
        width: 100%;
        display: flex;
        padding: 15px 11px;
        align-items: flex-start;
        justify-content: space-between;
        border-radius: 16px;
        gap: 1em;
        cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
        opacity: ${({ disabled }) => (disabled ? '0.65' : 1)};;
        ${({ active }) => active && 'background: #F5FBFF;'}
        pointer-events: ${({ disabled }) => (disabled ? 'none' : 'auto')};
      

        .Checkbox__Container{
            right: 0;
            margin-left: 0.3em;
            width: auto !important;
        }
    }


    .Image_IconStyle{
        width: 60px;
        height: 60px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;
        border-radius: 50%;
        background: var(--transparent-blue, rgba(51, 94, 247, 0.08));
    }

    .contentStyle_group{
        flex: 1 1 auto;
        display: flex;
        align-items: center;

        .content{
            flex-grow: 1 !important;
        }

        h5{
            color: var(--greyscale-900, #212121);
            font-weight: var(--eke-font-weight-semibold);
            font-size: 16px;
            line-height: 140%; /* 22.4px */
            letter-spacing: 0.2px;
            margin-bottom: 0.1em;
        }
        p{
            color: var(--greyscale-500, #9E9E9E);
            font-weight: var(--eke-font-weight-normal);
            font-size: 12px;
            line-height: normal;
            letter-spacing: 0.2px;
            margin: 0;
        }
    }

    .icon-arrow{
        flex-shrink: 1;
        font-size: 24px !important;
        color: var(--eke-color-primary) !important;
    }
`;

export default AsideStepper