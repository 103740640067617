/* eslint-disable @typescript-eslint/no-explicit-any */
import React from "react";
import { BsFillCaretDownFill } from "react-icons/bs";
import { FiChevronLeft, FiLogOut, FiMenu } from "react-icons/fi";
import { useLocation, useNavigate } from "react-router-dom";
import { googleLogout } from '@react-oauth/google';
import {
  DashboardSellerLiveCommerceBaseUrl,
  DashboardSellerLiveCommercePathUrl,
  homePathUrl,
  loginPathUrl,
} from "@ekenta/routes/constant";
import avatar from "@ekenta/assets/images/avatar_pro.png";
import liveicon from "@ekenta/assets/images/TB1live.gif";
import { useAppDispatch, useAppSelector } from "../../../redux/store/hook";
import {
  checkIfAdmin,
  checkIfAdminOrSellerRoleExist,
  logoutHandler,
  useMatchPath,
} from "@ekenta/utils";
import { removeUser } from "../../../redux/store/states/reducerSlice/authenticationSlice";
//import logo from "../../../assets/images/ekentalogo.png";

import Dropdown from "../../../components/Dropdown/Dropdown3";
import {
  removeLive,
  setLiveType,
} from "../../../redux/store/states/reducerSlice/livecomSlice";
import { toast } from "react-toastify";
// import { useFetchProductFilteredByCollectionQuery } from "../../../redux/store/services/livecommerceApi";
import { Logo } from "@ekenta/components";
import styled from "styled-components";
import { emptyCart } from "@ekenta/redux/store/features/cart/cartSlice";

export const DashboardCustomNavBar = ({
  setvisibilty,
}: {
  setvisibilty: React.Dispatch<React.SetStateAction<boolean>>;
}) => {


  const { pathname } = useLocation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { account, ek_isAuth, token } = useAppSelector(
    (state: any) => state.auth.authentication,
  );

  const isAdmin = account.user ? checkIfAdmin(token, account.user.role) : null;
  // const checkResult = useFetchProductFilteredByCollectionQuery(sellerId, { skip: !sellerId });

  const logoutHandlerFunc = () => {
    logoutHandler(
      navigate,
      dispatch,
      googleLogout,
      loginPathUrl,
      removeUser,
      emptyCart,
      removeLive,
    );
    toast.success(`You"ve successfully logout`, {
      toastId: "logout-seller-navbar-id-toast-success",
    });
  };

  const navigateToLivestream = (type: string) => {
    dispatch(
      setLiveType({
        liveType: type,
      }),
    );
    navigate(
      `${DashboardSellerLiveCommerceBaseUrl}/view-${account.user.id}?type=${type}`,
    );
  };

  // const checkIfEkemarketExist = useCallback(() => {

  //   if (checkResult?.data) {
  //     if (checkResult?.data.content.length > 0) {
  //       return [
  //         {
  //           name: "Eke Market Live",
  //           icon: "",
  //           active: false,
  //           key: "eke-market",
  //           handler: () => navigateToLivestream("isEkeLive"),
  //         }
  //       ];
  //     } else {
  //       return [];
  //     }
  //   }
  //   return [];
  // }, [checkResult?.data]);

  return (
    <Container className="dashboard-custom-nav">
      <div className="dashboard-custom-nav-menu d-flex align-items-center">
        <button
          className="navbar-toggler d-lg-none p-0 border-0"
          type="button"
          aria-controls=""
          aria-expanded="false"
          onClick={() => setvisibilty(true)}
        >
          <span className="navbar-mobile-icon">
            <FiMenu className="text-black" fontSize={22} />
          </span>
        </button>
        <div className="dashboard-brand d-lg-none ">
          <Logo
            width="140px"
            type="default"
          />
        </div>
      </div>
      <div className="dashboard-custom-nav-container">
        <ul className="ul__list">
          {pathname !==
            useMatchPath(DashboardSellerLiveCommercePathUrl, pathname) ? (
            <>
              {ek_isAuth && checkIfAdminOrSellerRoleExist(token, account.user.role) && !isAdmin ? (
                <li>
                  <Dropdown
                    name="Go Live"
                    className="go-live"
                    prefixIcon={
                      <>
                        <img
                          src={liveicon}
                          alt="live stream icon"
                          width="9"
                          height="9"
                        />
                      </>
                    }
                    placement="bottom"
                    menus={[
                      {
                        name: "Seller Livestream",
                        icon: "",
                        active: false,
                        key: "go-default-live",
                        handler: () => navigateToLivestream("isSellerLive"),
                      },
                      // ...checkIfEkemarketExist(),

                    ]}
                    tooltipContent={""}
                  />
                </li>
              ) : null}
            </>
          ) : null}

          <li>
            <Dropdown
              placement="bottom"
              menus={[
                {
                  name: "Switch to Buyer",
                  icon: <FiChevronLeft />,
                  active: false,
                  key: "switch-to-buyer",
                  handler: () => navigate(homePathUrl),
                },
                {
                  name: "Logout",
                  icon: <FiLogOut />,
                  active: false,
                  key: "logout",
                  handler: logoutHandlerFunc,
                },
              ]}
              tooltipContent={""}
              render={
                <div className="dashboard-custom-nav-profile shadow-sm">
                  <div className="dashboard-custom-nav-image">
                    <img src={avatar} alt="seller account" />
                  </div>
                  <div className="dashboard-profile_name">
                    <h5>{account && account.user.firstName}</h5>
                    <BsFillCaretDownFill className="dropDownIcon" />
                  </div>
                </div>
              }
            />

            {/* <div className="dashboard-custom-nav-profile">
                            <div className="dashboard-custom-nav-image">
                                    <img src={avatar} alt="seller account" />
                                </div>
                                <div className="dashboard-profile_name">
                                    <h5>{account && account.user.firstName}</h5>
                                    <BsFillCaretDownFill className="dropDownIcon" />
                                </div>
                         </div> */}
          </li>
        </ul>
      </div>
    </Container>
  );
};


const Container = styled.div`
 background-color:  var(--eke-color-white) !important;
 border-bottom: 1px solid #eee;
 margin:0;
`;