
import styled from "styled-components";

export const  AuthContainerStyle = styled.div`
	display: flex;
	width: 100%;
	height: 100vh;
	//padding: 5px 0px 4px 0px;
	justify-content: center;
	align-items: flex-start;
	gap: 20px;
	background-color: var(--eke-color-bg);
`;
export const AuthFormGroupStyle = styled.div`
	flex: 1;
	width: 100%;
	height: auto;
	margin: 0 auto;

	label{
		color: var(--greyscale-700, #616161) !important;
		font-family: var(--eke-body-font-family);
		line-height: 140%;
		letter-spacing: 0.2px;
		
		span{
			font-size: 14px;
			font-weight: var(--eke-font-weight-medium) !important;
		}

		svg{
			margin-top: 6px;
		}
	}

	input{
		border-radius: 8px !important;
		border: 1px solid var(--gray-300, #D0D5DD) !important;
		background: var(--eke-color-white) !important;
		box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05) !important;
		color: var(--gray-500, #667085);
		font-size: 14.5px !important;
		font-family: var(--eke-body-font-family);
		font-weight: var(--eke-font-weight-medium) !important;
		line-height: 140%;
		letter-spacing: 0.2px;
	}
`;



export const PinInputStyle = styled.div`
	padding-top: 10px;
	.pincode-input-container{
		display: flex;
		gap: 0.8em !important;
		margin-bottom: 0 !important;

		input{
			border-radius: 12px !important;
			border: 1px solid var(--greyscale-200, #ddd) !important;
			background: var(--eke-color-white) !important;
			color: var(--greyscale-900, #212121) !important;
			text-align: center;
			font-size: 24px !important;
			font-family: var(--eke-body-font-family);
			font-weight: var(--eke-font-weight-bold) !important;
			line-height: 120%;
		}
		
	}
`;

export const ErrorStyle = styled.div`
    width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
    margin-top: 0.9rem;
    font-size: 0.775em;
    color: #dc3545;
`;


export const PopupStyle = styled.div`
	display: flex;
	padding: 40px 22px 32px 22px;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	text-align: center;
	gap: 0.8em;
	font-family: var(--eke-body-font-family);

	.SuccessIcon__Style{
		svg{
			width: 110px !important;
			height: auto !important;
		}
	}

	h5{
		color: var(--greyscale-900, #212121);
		text-align: center;
		font-size: 22px;
		font-weight: var(--eke-font-weight-bold) !important;
		line-height: 120%;
		margin: 0;
	}

	p{
		color: var(--greyscale-900, #212121);
		font-size: 14px;
		font-weight: var(--eke-font-weight-normal) !important;
		line-height: 140%;
		letter-spacing: 0.2px;
		margin-bottom: 5px;
	}

	.Button-redirect-action{
		width: 100% !important;
		color: var(--eke-color-white);
		text-align: center;
		text-shadow: 4px 8px 24px 0px #EFDCC7 !important;
		font-family: var(--eke-body-font-family);
		padding: 18px 16px !important;
		line-height: 140%;
		letter-spacing: 0.2px;
		
		span{
			font-weight: var(--eke-font-weight-bold) !important;
			font-size: 16px !important;
		}
	}

`;