import React from "react";
import styles from "./Label.module.scss";
import classnames from "classnames";

interface LabelPropsType {
    htmlFor?: string;
    text?: string;
    type?: string;
    center?: boolean;
    uppercase?: boolean;
    className?: string;
    required?: boolean;
    children?: React.ReactNode;
    render?: () => void
}

const Label: React.FC<LabelPropsType> = (props) => {
    const { htmlFor, text, required, type, center, uppercase, className, children, render } = props;

    const Classes = classnames(styles.labelText, {
        [styles.UpperCaseLabel]: uppercase,
        [styles.TypeCheckbox]: type === "checkbox",
        [styles.TextMarginBottom]: type !== "checkbox",
    });

    const parentClass = classnames({
        [styles.Center_Label]: center
    });

    return (
        <label htmlFor={htmlFor} className={classnames(styles.label, styles.fullWidth, parentClass, className)}>
            {text && (
                <span className={Classes}>
                    {text} {required && <span className={styles.required}>*</span>}
                </span>
            )}
            {render && render()}
            {children}
        </label>
    )
}

export default Label;