import React from 'react'
import SVG from "react-inlinesvg";
import { AlertNoteStyle } from "../style";
import CautionSVG from "@ekenta/assets/icons/caution.svg";
import outlineCheck from "@ekenta/assets/icons/outline-check.svg";



export const AlertNote = ({ text, status }: { text: string; status?: "success" | "info" }) => (
    <AlertNoteStyle className="alertnote-div">
        <div className="IconStyle">
            {status === "success" ? (<SVG src={outlineCheck} />) : (<SVG src={CautionSVG} />)}</div>
        <span>{text}</span>
    </AlertNoteStyle>
)