/* eslint-disable @typescript-eslint/no-explicit-any */
import { createSlice } from "@reduxjs/toolkit";

export const feedBackSlice = createSlice({
    name: 'popUp',
    initialState: {
		showPopup: false,
		timer: null,
	},
    reducers: {
		togglePopup: (state, action) => {
			state.showPopup = action.payload;
		},
		setTimer: (state, action) => {
			state.timer = action.payload;
	},
    }
})

export const { togglePopup, setTimer } = feedBackSlice.actions;

export  default feedBackSlice.reducer;
