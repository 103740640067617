/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useCallback, useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { useNavigate, useParams } from 'react-router'
import { VscArrowSmallLeft } from 'react-icons/vsc'
import * as queryString from 'query-string'
import styled from 'styled-components'
import { withDashboardLayout } from '@ekenta/hoc'
import { AppBoard } from '../../common/styles'
import {
  Button,
  CustomModal,
  DataTable,
  HelmetHeader,
  PageTitle,
} from '@ekenta/components'
import { ButtonGroupStyle, DataTableWrapper } from '../../common/common'
import { PageSubTitle, TextTruncate } from '../../common/Resuable'
import { DashboardEventsPathUrl } from '@ekenta/routes/constant'
import {
  useFetchSingleEventQuery,
  useRemoveMultipleEventProductMutation,
  useRemoveSingleEventProductMutation,
} from '@ekenta/redux/store/services/adminSellerApi'
import { useAppSelector } from '@ekenta/redux/store/hook'
import { formatCurrency } from '@ekenta/utils'
import NoticeGif from '@ekenta/assets/images/notice-gif.gif'

const SingleEvent = () => {
  const query = queryString.parse(window.location.search)
  const collectionId = query.colId
  const discount = query.discount
  const navigate = useNavigate()
  const params = useParams()
  const { token } = useAppSelector((state) => state.auth.authentication)
  const [modal, setModal] = useState(false)
  const [productId, setProductId] = useState('')
  const [autoselectRow, setAutoSelectRow] = useState(false)

  const isParam = !params.id ? true : false
  const [singleDelete, singleResult] = useRemoveSingleEventProductMutation()
  const [multiDelete, multiResult] = useRemoveMultipleEventProductMutation()

  const result = useFetchSingleEventQuery(
    {
      id: params.id,
      token,
    },
    {
      skip: isParam,
      refetchOnMountOrArgChange: true,
      refetchOnReconnect: true,
      keepPreviousData: false,
    }
  )

  const selectedRowsRef = React.useRef<any>([])

  if (!params.id || !collectionId) {
    navigate(DashboardEventsPathUrl)
  }

  useEffect(() => {
    if (singleResult?.isSuccess) {
      handleOnClick(false, '')
      result?.refetch()
      toast.success(`${singleResult?.data.message}`, {
        toastId: 'event-prod-toast-success',
      })
    }
    if (singleResult?.isError) {
      toast.error('Event product failed to delete  ', {
        toastId: 'event-prod-toast-error',
      })
    }

    //Multiple Remove Event Product
    if (multiResult?.isSuccess) {
      handleOnClick(false, '')
      result?.refetch()
      setAutoSelectRow(true)
      toast.success(`${multiResult?.data.message}`, {
        toastId: 'event-multi-prod-toast-success',
      })
    }
    if (multiResult?.isError) {
      toast.error('Event products failed to delete  ', {
        toastId: 'event-muti-prod-toast-error',
      })
    }
  }, [
    singleResult?.isSuccess,
    singleResult?.isError,
    multiResult?.isSuccess,
    multiResult?.isError,
  ])

  const columns = [
    {
      Header: 'Image',
      accessor: 'image',
      sortType: 'basic',
      disableSortBy: true,
    },

    {
      Header: 'Name',
      accessor: 'name',
      sortType: 'basic',
    },
    {
      Header: 'Actual Price',
      accessor: 'actual_price',
      sortType: 'basic',
      disableSortBy: true,
    },
    {
      Header: 'Collection',
      accessor: 'collection',
      sortType: 'basic',
    },
    {
      Header: 'Discount %',
      accessor: 'discount',
      sortType: 'basic',
    },
    {
      Header: 'Discount Price',
      accessor: 'discount_price',
      sortType: 'basic',
    },
    {
      Header: 'Qty',
      accessor: 'stock',
      disableSortBy: true,
    },
    {
      Header: 'Action',
      accessor: 'action',
      disableSortBy: true,
    },
  ]

  const mapData = (data: any) => {
    return data.length > 0
      ? data.map((item: any) => ({
          ...item,
          image: (
            <div
              className="picture__thumb"
              style={{
                width: '50px',
                height: 'auto',
                maxHeight: '60px',
                overflow: 'hidden',
              }}
            >
              <img
                className="img-thumbnail rounded"
                width="100%"
                height="90%"
                style={{ maxHeight: '60px' }}
                src={
                  item?.product?.images.length > 0
                    ? item.product.images[0]?.image_url
                    : ''
                }
              />
            </div>
          ),
          name: <TextTruncate>{item?.product?.name}</TextTruncate>,
          actual_price: formatCurrency(item?.product?.actual_price),
          collection: item?.product.collection,
          discount: item?.discount,
          discount_price: formatCurrency(item?.product?.price),
          stock: item?.product?.stock,
          action: (
            <ButtonGroupStyle defaultStyle={false}>
              <Button
                name="Remove"
                bgVariant=""
                textColor="primary"
                className="text_role_button"
                textVariant="light"
                type="button"
                shadow={false}
                onClick={() => {
                  handleOnClick(true, item?.id)
                }}
              />
            </ButtonGroupStyle>
          ),
        }))
      : []
  }

  const handleOnClick = (open: boolean, id: string) => {
    setProductId(id)
    setAutoSelectRow(false)
    setModal(open)
  }

  const onSelectRow = (selectedRows: any) => {
    selectedRowsRef.current = selectedRows
  }

  const loopSelectedRow = (selectedRows: any) => {
    const result = selectedRows.map((row: any) => row.id)
    return result
  }

  const handleRemoveProduct = useCallback(
    async (productId) => {
      const eventId = params.id
      if (!eventId) {
        return toast.error('No Event Id', {
          toastId: '-event-id-400-toast-error',
        })
      }

      if (productId === 'all') {
        const selectedProducts = loopSelectedRow(selectedRowsRef.current)

        if (selectedProducts.length === 0) {
          toast.error('No Product was selected', {
            toastId: '-sele-error-400-id-toast-error',
          })
          handleOnClick(false, '')
          return
        }
        // If eventId exists and product Ids, you can perform further actions here
        const data = {
          event_product_id: selectedProducts,
        }
        multiDelete({
          data,
          eventId,
          token,
        })
      } else {
        const obj = {
          event_product_id: productId,
        }

        singleDelete({
          data: obj,
          eventId,
          token,
        })
      }
    },
    [selectedRowsRef.current]
  )

  const isRemoveAll = productId === 'all' ? 's' : ''

  return (
    <>
      <HelmetHeader
        title={result?.data ? `${result?.data.events?.name}` : 'Single Event'}
        description="Qlason Single Events Page"
      />
      <AppBoard className="_listing_product_">
        <div>
          <PageTitle
            title={result?.data ? `${result?.data.events?.name}` : 'Loading...'}
            subTitle={
              <PageSubTitle>
                <span onClick={() => navigate(DashboardEventsPathUrl)}>
                  <VscArrowSmallLeft /> Back to Event
                </span>
                <p>View all products in your event</p>
              </PageSubTitle>
            }
          >
            <ButtonGroupStyle defaultStyle>
              <Button
                name="Remove Products"
                bgVariant=""
                className="outline_role_button"
                type="button"
                shadow={false}
                disabled={singleResult?.isLoading || multiResult?.isLoading}
                onClick={() => handleOnClick(true, 'all')}
              />
              <Button
                name="Add Product"
                bgVariant="primary"
                textVariant="light"
                type="button"
                disabled={singleResult?.isLoading || multiResult?.isLoading}
                onClick={() =>
                  navigate(
                    `${DashboardEventsPathUrl}/${params.id}/add-product?eventId=${params.id}&colId=${collectionId}&discount=${discount}`
                  )
                }
              />
            </ButtonGroupStyle>
          </PageTitle>
        </div>
      </AppBoard>

      <DataTableWrapper isCenter={false} className="mt-3 position-relative">
        <div className="">
          <DataTable
            theme="simple"
            data={
              result?.data ? mapData(result?.data?.events?.eventProduct) : []
            }
            loading={result?.isLoading || result?.isFetching}
            isSelectable={true}
            columns={columns}
            showPagination={true}
            onSelectedRow={onSelectRow}
            autoResetSelectedRows={autoselectRow}
            thClassName=""
            trClassName=""
            tdClassName={`Row__col event_col`}
            theadClassName="DataTable_THead"
            renderPagnation={() => (
              <>
                <div className="mt-4">
                  {/* {allEvent?.data && allEvent?.data.events.length > 0 ? ( */}
                  {/* <Pagination
                        pathname={pathname}
                        itemPerPage={result?.data?.sellers?.per_page}
                        itemsLength={result?.data?.sellers?.total}
                        currentPage={pageno}
                        queryNo={_page}
                        callback={(value) => setPageno(value)}
                      /> */}
                  {/* ) : null} */}
                </div>
              </>
            )}
          />
        </div>
      </DataTableWrapper>

      <CustomModal
        show={modal}
        size="sm"
        title={`Remove Product${isRemoveAll}`}
        subtitle=""
        centered={false}
        showFooter={false}
        isLoading={false}
        isDisabled={false}
        headerClass="app-modalheader"
        modalClass="app-modalbody  app-infomodal"
        onHide={() => handleOnClick(false, '')}
        render={
          <FormPopupStyle>
            <div className="container">
              <div className="Notice_GifIcon__Style">
                <img src={NoticeGif} alt="" />
              </div>
              <div>
                <h5>Remove Product{isRemoveAll}?</h5>
                <p>Are you sure you want to remove product?</p>
              </div>
              <br />
              <ButtonGroupStyle defaultStyle>
                <Button
                  name="Cancel"
                  bgVariant=""
                  className="outline_role_button"
                  type="button"
                  shadow={false}
                  onClick={() => handleOnClick(false, '')}
                />
                <Button
                  name="Yes, Remove"
                  bgVariant="primary"
                  textVariant="light"
                  type="button"
                  isLoadingText=" "
                  isLoading={singleResult?.isLoading || multiResult?.isLoading}
                  disabled={singleResult?.isLoading || multiResult?.isLoading}
                  shadow={false}
                  onClick={() => handleRemoveProduct(productId)}
                />
              </ButtonGroupStyle>
            </div>
          </FormPopupStyle>
        }
      />
    </>
  )
}

const FormPopupStyle = styled.div`
  width: 90%;
  margin: 0 auto;
  text-align: center;
  padding-bottom: 20px;
  font-family: var(--eke-body-font-family);

  .container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    justify-content: center;

    .Notice_GifIcon__Style {
      width: 100%;
      height: auto;
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        width: 152px;
        height: 152px;
      }
    }
    h5 {
      color: var(--greyscale-900, #212121);
      text-align: center;
      font-size: 22px;
      font-weight: var(--eke-font-weight-bold);
      line-height: 120%; /* 28.8px */
    }

    p {
      color: var(--greyscale-700, #616161);
      margin: 15px;
      font-size: 14px;
      font-weight: var(--eke-font-weight-medium);
      line-height: 140%; /* 22.4px */
      letter-spacing: 0.2px;
    }

    .ButtonSubmit_Class {
      padding: 16px 16px !important;
      span {
        text-align: center;
        text-shadow: 4px 8px 24px #efdcc7;
        font-size: 15px !important;
        font-weight: var(--eke-font-weight-bold) !important;
        line-height: 140%; /* 22.4px */
        letter-spacing: 0.2px;
      }
    }
  }
`
export default withDashboardLayout(SingleEvent)
