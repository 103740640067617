import React from 'react'
import styled from '@emotion/styled'
import { Link } from 'react-router-dom'
import { Button, Loader } from '@ekenta/components'
import { blogPathUrl } from '@ekenta/routes/constant'
import { useGetRecentBlogPostQuery } from '@ekenta/redux/store/features/blog/blogApiSlice'
import { BlogContentType } from '@ekenta/redux/store/features/blog/blogDataType'
import Post from '../common/Post'

const LastestPost = () => {
  const { data, isLoading } = useGetRecentBlogPostQuery()
  const recentPosts = data?.blogs ?? []
  const post = recentPosts?.slice(0, 2) ?? []
  return (
    <Flex>
      <div className="left--section">
        <div>
          <span>Latest</span>
        </div>
        <h2>From the blog</h2>
        <p>
          Welcome to our Blog, your dedicated space for delving deeper into
          access to a wide range of products, and a personalized shopping
          experience, all from the comfort of your own home.
        </p>
        <div className="newsButton">
          <Link to={blogPathUrl} reloadDocument>
            <Button
              name="View all posts"
              bgVariant="primary"
              shadow={false}
              type="submit"
              isLoading={false}
              isLoadingText=" "
            />
          </Link>
        </div>
      </div>
      <div className="right--section">
        <div className="group--item">
          {isLoading ? <Loader size="xlarge" /> : null}
          {post?.map((item: BlogContentType) => (
            <div key={`lst-${item.id}`} className="wrapper">
              <Post
                title={item?.title}
                summary={item?.summary}
                image_url={item?.image_url}
                date={item?.updated_at}
                written_by={item?.written_by}
                slug={item?.slug}
                direction="column"
                imgH="250px"
              />
            </div>
          ))}
        </div>
      </div>
    </Flex>
  )
}

const Flex = styled.div`
  display: flex;
  align-items: flex-start;
  padding-top: 77px;
  gap: 40px;

  .left--section {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 8px;
    font-family: var(--eke-body-font-family) !important;

    span {
      font-weight: 700;
      color: var(--eke-color-primary);
    }

    h2 {
      font-weight: 700;
      color: #101828;
      font-size: 32px;
      margin: 0 !important;
    }
    p {
      color: #475467;
      font-weight: 400;
      font-size: 16px;
      padding-right: 15px;
      margin-bottom: 2px !important;
    }

    button {
      span {
        color: #fff !important;
      }
    }
  }

  .right--section {
    flex: 1.7;
    .group--item {
      display: flex;
      flex-wrap: wrap;
      align-items: flex-start;
      justify-content: space-between;
      width: 100%;
      height: auto;
      column-gap: 2px;
      row-gap: 20px;
      > * {
        flex-basis: 49%;
      }

      h1 {
        font-size: 22px !important;
      }

      .post--paragraph-box {
        width: 100% !important;
        display: block;
        display: -webkit-box;
        text-decoration: none;
        max-width: 100%;
        height: 44px; /* Fallback for non-webkit */
        margin-bottom: 0rem;
        line-height: 1.5;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis !important;
        line-height: 140%;
        p {
        }
      }
    }
  }
`

export default LastestPost
