import React from 'react'
import { useNavigate } from 'react-router-dom'
import { VscArrowSmallLeft } from 'react-icons/vsc'
import { AdminVoucherPathUrl } from '@ekenta/routes/constant'
import { PageTitle, DataTable, HelmetHeader } from '@ekenta/components'
import { useGetAllVoucherForAdminQuery } from '@ekenta/redux/store/features/admin/vouchersApiSlice'
import { withDashboardLayout } from '@ekenta/hoc'
import { voucherHistoryColumn } from './column'
import { VoucherStyle } from './style'

const AllVouchers = () => {
  const navigate = useNavigate()
  const { data, isLoading } = useGetAllVoucherForAdminQuery()
  const voucher = data?.data?.data ?? []

  return (
    <>
      <HelmetHeader
        title="Voucher History"
        description="Qlason Voucher History Page"
      />
      <VoucherStyle>
        <div className="h-stacked">
          <PageTitle
            title="All Vouchers History"
            subTitle={
              <span
                className="cursor-pointer d-flex w-lg-50 align-items-center 
						text-muted"
                onClick={() => navigate(AdminVoucherPathUrl)}
              >
                <VscArrowSmallLeft />
                Back to Voucher
              </span>
            }
          />
        </div>

        <div className="datatable-wrapper">
          <DataTable
            theme="simple"
            data={voucher}
            shadow={true}
            loading={isLoading}
            isSelectable={true}
            columns={voucherHistoryColumn}
            showPagination={true}
            hasLink={true}
            thClassName=""
            trClassName="w-25"
            theadClassName="DataTable_THead"
            tdClassName={`Row__col`}
            cardClassName="DataTable_Card"
            showSearch={false}
          />
        </div>
      </VoucherStyle>
    </>
  )
}

export default withDashboardLayout(AllVouchers)
