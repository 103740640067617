/* eslint-disable @typescript-eslint/no-explicit-any */
import { Roles } from '../routes/roleConfig'
//import { DecodeTokenType } from "../interfaces/decode";
//import { decodeToken } from "./decodeToken";
import slugify from 'slugify'
import { v4 as uuidv4 } from 'uuid'
import { matchPath, NavigateFunction } from 'react-router'
import { Dispatch } from 'react'
import { AnyAction } from '@reduxjs/toolkit'
import { toast } from 'react-toastify'
import { decodeToken } from './decodeToken'
import { DecodeTokenType } from '@ekenta/interfaces/decode'

export const checkTokenValidityAndExpireAndReturnRoles = (token: string) => {
  if (!token) return false
  const decoded_Token: DecodeTokenType | any = decodeToken(token)
  if (!decoded_Token || decoded_Token.exp * 1000 < Date.now()) return false
  return decoded_Token.auth
}

export const checkAuthorizationRoles = (
  actorRole: string[],
  permissions: string[]
) => {
  if (!permissions) return true
  return actorRole.some((item) => permissions.includes(item))
}

export const checkIsSellerOrAdminRoleOnboardingRoute = (role: string) => {
  const actorRole = [role]
  return actorRole.some((item) =>
    item == Roles.ROLE_SELLER || item == Roles.ROLE_ADMIN ? true : false
  )
}

// { change the sellanything nav item to dashboard for seller and admin}
export const checkIfAdminOrSellerRoleExist = (token: string, _role: string) => {
  if (!token) return false
  const role = _role
  if (!role) return false
  const actorRole = [role]
  return actorRole.some((item: string) =>
    item == Roles.ROLE_SELLER || item == Roles.ROLE_ADMIN ? true : false
  )
}

export const checkIfAdmin = (token: string, _role: string) => {
  if (!token) return false
  const role = _role
  if (!role) return false
  const actorRole = [role]
  return actorRole.some((item: string) =>
    item == Roles.ROLE_ADMIN ? true : false
  )
}

export const checkIfSeller = (token: string, _role?: string) => {
  if (!token) return false
  const role = _role
  if (!role) return false
  const actorRole = [role]
  return actorRole.some((item: string) =>
    item == Roles.ROLE_SELLER ? true : false
  )
}

export const getUserToken = () => {
  return ''
}

export const formatBytes = (bytes: number, decimals = 2) => {
  if (bytes === 0) return '0 Bytes'
  const k = 1024
  const dm = decimals < 0 ? 0 : decimals
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
  const i = Math.floor(Math.log(bytes) / Math.log(k))
  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i]
}

export const slugifyText = (text: string, id?: any) => {
  let result = text
  if (id) {
    result += ` ${id}`
  }
  return slugify(result)
}

export const getAppBaseUrl = (onlyHost = false) => {
  const { protocol, host } = window.location
  if (onlyHost) {
    return `${host}`
  }
  return `${protocol}//${host}`
}

export const formatDurationInSeconds = (seconds: any) => {
  if (isNaN(seconds)) return null
  const date = new Date(seconds * 1000)
  const hh = date.getUTCHours()
  const mm = date.getUTCMinutes()
  const ss = pad(date.getUTCSeconds())
  if (hh) {
    return `${hh}:${pad(mm)}:${ss}`
  }
  return `${mm}:${ss}`
}

function pad(string: any) {
  return ('00' + string).slice(-2)
}

export const useMatchPath = (path: string, pathname: string) => {
  const route = matchPath(path, pathname)
  if (!route) return 'undefined'
  return route.pathname
}

export const mapCartDataReturnQuantity = (cartData: any, productid: number) => {
  return cartData
    ? cartData.length > 0 &&
        cartData.cart.find((item: any) => {
          if (item.product.id === productid) {
            return item
          }
        })
    : null
}

export const getNameInitial = (nameString: string) => {
  return nameString[0].substring(0, 1).toUpperCase()
}

export function formatCurrency(amount: number, currency?: string) {
  if (!amount) return 0
  return `${currency ? currency : '₦'}${amount.toLocaleString()}`
}

export function formatNumber(amount: number) {
  if (!amount) return 0
  return amount.toLocaleString(undefined, {
    maximumFractionDigits: 2,
  })
}

export const logoutHandler = (
  navigate: NavigateFunction,
  dispatch: Dispatch<AnyAction>,
  googleLogout: () => void,
  loginPathUrl: string,
  removeUser: () => AnyAction,
  emptyCart: () => AnyAction,
  removeLive: () => AnyAction
) => {
  googleLogout()
  dispatch(removeUser())
  dispatch(emptyCart())
  dispatch(removeLive())
  navigate(loginPathUrl)
}

export const abbrevateNumber = (value: number) => {
  const suffixes = ['', 'k', 'm', 'b', 't']
  const suffixNum = Math.floor(('' + value).length / 3)
  let shortValue = parseFloat(
    (suffixNum != 0 ? value / Math.pow(1000, suffixNum) : value).toPrecision(2)
  )
  if (shortValue % 1 != 0) {
    shortValue = parseInt(shortValue.toFixed(1))
  }
  return [shortValue, suffixes[suffixNum]]
}

export const calculatePercentage = (maxPrice: number, minPrice: number) => {
  const percent = ((maxPrice - minPrice) / maxPrice) * 100
  return `-${parseInt(percent.toFixed(2))}`
}

export const setDiscountPercent = (discountPercentage: number) => {
  return `-${discountPercentage}`
}

export const calculateTotalPrice = (price: number, discountVal: number) => {
  return discountVal && discountVal > 0 ? Math.ceil(price - discountVal) : price
}

export const readFileAsDataURL = (file: File): Promise<string> => {
  return new Promise<string>((resolve, reject) => {
    const reader = new FileReader()

    reader.onloadend = () => {
      if (reader.readyState === FileReader.DONE) {
        // Resolve with the data URL as a string
        resolve(reader.result as string)
      } else {
        // Reject with an error message
        reject(new Error('Failed to read the image file.'))
      }
    }

    // Start reading the file as a data URL
    reader.readAsDataURL(file)
  })
}

export const copyToClipboard = (file: string): Promise<string> => {
  return new Promise((resolve) => {
    const input = document.createElement('input')
    input.value = file
    document.body.appendChild(input)
    input.select()
    document.execCommand('copy')
    document.body.removeChild(input)
    resolve('Copied!')
  })
}

export const showToastify = (message: string, key: string, isError = false) => {
  const toastFunction = isError ? toast.error : toast.success

  toastFunction(message, {
    toastId: `${isError ? 'error' : 'success'}-toast-${key}`,
  })
}

export const capitalize = (str: string) => {
  if (typeof str !== 'string' || str.length === 0) {
    return ''
  }
  return str
    .split(' ')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(' ')
}
export const isVideoUrl = (url: string) => {
  const videoFilePattern = /\.(mp4|avi|mkv|mov|wmv|flv|webm)$/i
  return videoFilePattern.test(url)
}

export const isMobileView = () => {
  const windowWidth = window.innerWidth
  return windowWidth <= 768
}

export const generateReference = (): string => {
  return 'ref_' + uuidv4().replace(/-/g, '').substring(0, 10)
}
