import { api } from '@ekenta/app/api/apiSlice'

export const searchApiSlice = api.injectEndpoints({
  endpoints: (builder) => ({
    searchProductSuggestion: builder.mutation({
      query: (data) => ({
        url: `/api/search-suggestion`,
        method: 'POST',
        body: data,
      }),
    }),
    searchProduct: builder.mutation({
      query: (data) => ({
        url: `/api/search-product-by-name`,
        method: 'POST',
        body: data,
      }),
    }),

    searchSellerProduct: builder.query({
      query: ({ sellerId, pageno }) => ({
        url: `/api/seller-product/${sellerId}?page=${pageno}`,
        method: 'GET',
      }),
    }),

    searchBrandProduct: builder.query({
      query: ({ brandId, pageno }) => ({
        url: `/api/brand-product/${brandId}?page=${pageno}`,
        method: 'GET',
      }),
    }),
  }),
})
export const {
  useSearchProductSuggestionMutation,
  useSearchProductMutation,
  useSearchBrandProductQuery,
  useSearchSellerProductQuery,
} = searchApiSlice
