import React from 'react'
import { withDefaultLayout } from '@ekenta/hoc'
import { HelmetHeader } from '@ekenta/components'
import Hero from './Hero'
import { BlogStyle } from './style'
import RecentPost from './RecentPost'
import AllPosts from './AllPosts'

const Blog = () => {
  const pageUrl = window.location.href

  return (
    <>
      <HelmetHeader
        title="Blog - Online Shopping Made Easy"
        description="Qlason Blog Page"
        url={pageUrl}
      />
      <BlogStyle>
        <Hero />
        <RecentPost />
        <AllPosts />
      </BlogStyle>
    </>
  )
}

export default withDefaultLayout(Blog)
