import React, { FC } from 'react';
import { Link } from "react-router-dom";

interface PropsType {
    children: React.ReactNode;
    className?: string;
    target?: string;
    to: string;
    replace?: boolean;
    state?: any | string;

}

const RefreshLink: FC<PropsType> = (props) => {
    const { children, className, to, target, replace, state, ...rest } = props;
    return (
        <Link
            reloadDocument={true}
            replace={replace}
            state={state}
            target={target}
            className={className}
            to={to}
            {...rest}
        >
            {children}
        </Link>
    )
}

export default RefreshLink;