/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react'
import styled, { css } from 'styled-components'
import { CardBox, Loader, Typography } from '@ekenta/components'
import { device } from '@ekenta/utils/devices'
import classnames from 'classnames'

export const ButtonGroupStyle = styled.div<{ defaultStyle?: boolean }>`
  ${(props) =>
    props.defaultStyle
      ? css`
          display: flex;
          width: 100%;
          align-items: center;
          justify-content: space-between;
          padding: 18px 0 10px 0;

          @media ${device.lg} {
            padding: 0 3em;
            padding-right: 0 !important;
            justify-content: flex-end;
          }
        `
      : null}

  button {
    min-width: 50% !important;
    padding: 12px 16px !important;
    @media ${device.lg} {
      min-width: 180px !important;
    }
    span {
      font-weight: var(--eke-font-weight-bold) !important;
      font-size: 14.5px !important;
    }
  }

  .outline_role_button {
    border: 1px solid var(--eke-color-primary) !important;
    background-color: var(--eke-color-white) !important;

    span {
      color: var(--eke-color-primary) !important;
      font-family: var(--eke-body-font-family);
    }
  }

  .text_role_button {
    min-width: auto !important;
    background-color: transparent !important;
    border: none !important;
    box-shadow: none !important;
    span {
      color: var(--eke-color-primary) !important;
      font-family: var(--eke-body-font-family);
    }
  }

  .outline_role {
    border-radius: 32px !important;
    display: flex;
    padding: 12px 16px !important;
    justify-content: center;
    align-items: center;

    .Button__icon {
      gap: 10px !important;
    }
  }

  .icon-primary {
    svg {
      fill: var(--eke-color-primary) !important;
      margin-right: 0.5rem !important;
    }
  }

  .icon-light {
    svg {
      fill: var(--eke-color-white) !important;
      margin-right: 0.5rem !important;
    }
  }
`

export const RenderCard = ({
  icon,
  text,
  amount,
  loading,
  className,
  onClick,
  isClickable = false,
}: {
  isClickable?: boolean
  icon: any
  text: string
  amount: string
  className?: string
  loading: boolean
  onClick?: () => void
}) => {
  return (
    <CardBox
      className={classnames(
        className ? className : 'CardBox',
        isClickable ? 'cursor-pointer' : ''
      )}
      onClick={onClick}
    >
      <div className="TopBox_Group">
        {icon}
        <Typography as="h5" color="default" size="medium">
          {text}
        </Typography>
      </div>
      <div className="Bottom_Amout_Div">
        {loading ? (
          <Loader size="large" color="black" />
        ) : (
          <Typography as="h3" color="default" size="medium">
            {amount}
          </Typography>
        )}
      </div>
    </CardBox>
  )
}

export const DataTableWrapper = styled.div<{ isCenter?: boolean }>`
  .DataTable_THead {
    background: #ecf6ff !important;
  }

  .event_tableWrapper {
    table {
      .event_col:nth-child(4),
      .event_col:nth-child(6) {
        text-align: center !important;
      }
    }
  }
  .tableWrapper {
    table {
      .payout_td:nth-child(6),
      .payout_td:nth-child(7) {
        text-align: center !important;
      }
    }
  }

  ${(props) =>
    props.isCenter &&
    css`
      table {
        .payout_td:last-child,
        .payout_td:nth-child(5),
        .payout_td:nth-child(6),
        .payout_td:nth-child(7) {
          text-align: center !important;
        }
      }
    `}
`

export const CardGroupStyle = styled.div<{ flexWrap?: boolean }>`
  display: flex;
  width: 100% !important;
  align-items: center;
  flex-wrap: wrap;
  /* overflow-y: hidden;
  overflow-x: auto; */
  gap: 15px;
  font-family: var(--eke-body-font-family);

  ${(props) =>
    props.flexWrap
      ? css`
          @media ${device.lg} {
            flex-wrap: wrap !important;
          }
        `
      : css`
          @media ${device.lg} {
            flex-wrap: nowrap !important;
          }
        `}

  .CardBox, .CardBox_Dash {
    width: 100%;
    padding-top: 30px !important;
    padding-bottom: 30px !important;
    border-radius: 13px !important;
    box-shadow: #adcfffb3 0px 1px 2px 0px, #3435364b 0px 1px 2px 0px !important;
    //box-shadow: 0px 4px 60px 0px rgba(92, 107, 173, 0.03) !important;
    transition: all 0.3s ease-in-out;
    &:hover {
      box-shadow: #adcfffb3 0px 1px 5px 0px, #3435364b 0px 1px 5px 0px !important;
    }
  }

  .TopBox_Group {
    display: flex;
    align-items: center;
    gap: 12px;
    margin-bottom: 20px;

    h5 {
      color: var(--greyscale-800, #424242) !important;
      font-size: 14px !important;
      font-weight: var(--eke-font-weight-semibold) !important;
      line-height: 140%; /* 19.6px */
      letter-spacing: 0.2px;
    }
  }

  .Bottom_Amout_Div {
    h3 {
      color: var(--greyscale-900, #212121) !important;
      font-size: 24px !important;
      font-weight: var(--eke-font-weight-bold) !important;
      line-height: 120%; /* 28.8px */
    }
  }

  @media ${device.md} {
    .CardBox {
      width: 31.833% !important;
    }
    .CardBox_Dash {
      width: 32% !important;
    }
  }

  @media ${device.lg} {
    gap: 10px;
    .CardBox {
      width: 32.333% !important;
    }
    .CardBox_Dash {
      width: 24% !important;
    }
  }
`

export const TopWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  button {
    margin-top: -10px;
    border: 1px solid var(--eke-color-primary) !important;
    background-color: transparent !important;
    min-width: 133px !important;
    padding-top: 10px !important;
    padding-bottom: 15px !important;
    height: 42px !important;
    span {
      color: var(--eke-color-primary) !important;
      font-family: var(--eke-body-font-family);
      font-size: 14px !important;
      line-height: 140%; /* 22.4px */
      letter-spacing: 0.2px;
    }
  }
`

export const TypographyWrapper = styled.div`
  width: 100%;
  padding-bottom: 20px;
  padding-top: 12px;

  h3 {
    color: var(--greyscale-900, #212121) !important;
    font-size: 18px !important;
    font-weight: var(--eke-font-weight-bold) !important;
    line-height: 120%; /* 21.6px */
  }
`
