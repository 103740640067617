import { device } from "@ekenta/utils/devices";
import styled from "styled-components";

export const  LoginContainerStyle = styled.div`
	display: flex;
	width: 100%;
	height: 100vh;
	//padding: 5px 0px 4px 0px;
	justify-content: center;
	align-items: flex-start;
	gap: 20px;
	background-color: var(--eke-color-bg);
`;

export const  LoginAsideStyle = styled.div`
	display: none;
	width: 746px;
	height: 100vh;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	flex-shrink: 1;
	gap: 1em;
	background-color: var(--eke-color-white);

		
	@media ${device.lg}{
		display: flex;
    }

	.authAImage__Style{
		display: flex;
		width: 100%;
		min-height: 150px;
		justify-content: center;
		margin-bottom: 0.8em;

		svg{
			width: 80%;
		   height: auto;
		   margin: 0 auto;
		}
	}

	h1{
		margin:0 auto;
		margin-top: 20px;
		color: var(--greyscale-900, #212121);
		text-align: center;
		font-size: 24px;
		font-family: var(--eke-body-font-family);
		font-weight: var(--eke-font-weight-bold);
		line-height: 120%;

	}

	p{
		margin:8px auto;
		width: 564px;
		text-align: center;
		color: var(--greyscale-800, #424242);
		font-size: 16px;
		font-family: var(--eke-body-font-family);
		font-weight: var(--eke-font-weight-normal);
		line-height: 140%;
		letter-spacing: 0.2px;
	}
`;

export const  LoginMainContent = styled.div`
    flex: 1;
	display: flex;
	height: 100vh;
	justify-content: center;
	align-items: center;
	padding: 110px 23px 168px 23px;

	@media ${device.lg}{
		padding: 169px 183px 168px 183px;
    }

	.ContentChildGroup__Style{
		width: 100%;
		height: auto;
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
		margin: 0 auto;
		zoom: 0.95;

		.logo{
			margin-right: 4rem;
		}

		.SignText_Style{
			margin-top: 25px;
			margin-bottom: 2px;
			text-align: center;
			margin-right: 2rem;

			h1{
				color: var(--greyscale-900, #212121);
				text-align: center;
				font-size: 23px;
				font-family: var(--eke-body-font-family);
		        font-weight: var(--eke-font-weight-bold);
				line-height: 120%;
			}
			p{
				color: var(--greyscale-700, #616161);
				text-align: center;
				font-size: 15px;
				font-family: var(--eke-body-font-family);
		        font-weight: var(--eke-font-weight-normal);
				line-height: 140%;
				letter-spacing: 0.2px;
				margin: 0;
			}
		}
	}

	.ContentForm__Style{
		width: 100%;
		height: auto;
		display: flex;
		flex-direction: column;
		align-items: center;
		gap: 0.7em;
		margin-top: 16px;

		form{
			width: 100%;
			display: flex;
			flex-direction: column;
			align-items: center;
			gap: 0.7em;
		}


		.ForgetPass__Style{
			display: flex;
			justify-content: flex-end;
			align-items: center;
			gap: 8px;
			align-self: stretch;
			margin-bottom: 10px;
			a{
				color: var(--greyscale-700, #616161);
				text-align: right;
				font-size: 14px;
				font-family: var(--eke-body-font-family);
		        font-weight: var(--eke-font-weight-normal);
				line-height: 140%;
				letter-spacing: 0.2px;
				text-decoration: none;

				&:hover{
					text-decoration: underline;
				}
			}
		}

		.Button-Call-to-action{
			width: 100% !important;
			color: var(--eke-color-white);
			text-align: center;
			text-shadow: 4px 8px 24px 0px #EFDCC7 !important;
			font-family: var(--eke-body-font-family);
			padding: 18px 16px !important;
			line-height: 140%;
			letter-spacing: 0.2px;
			
			span{
				font-weight: var(--eke-font-weight-bold) !important;
				font-size: 16px !important;
			}
		}

		.RegisterDiv__Style{
			display: flex;
			justify-content: center;
			align-items: center;
			gap: 8px;
			align-self: stretch;

			span{
				color: var(--greyscale-700, #616161);
				text-align: right;
				font-size: 14px;
				font-family: var(--eke-body-font-family);
				font-weight: var(--eke-font-weight-normal) !important;
				line-height: 140%;
				letter-spacing: 0.2px;
			}

			a{
				color: var(--eke-color-primary);
				font-size: 14px;
				font-family: var(--eke-body-font-family);
				font-weight: var(--eke-font-weight-bold) !important;
				line-height: 140%;
				letter-spacing: 0.2px;
				text-decoration: none;
			}
		}

		.OtherProcess_div__Style{
			position: relative;
			width: 100%;
			height: 15px;
			margin-top: 20px;


			.OtherDivider{
				width: 100%;
				height: 2px;
				border-bottom: 1px solid #eee;
			}

			span{
				position: absolute;
				top: -75%;
				left: 50%;
				transform: translateX(-50%);
				display: block;
				height: 25px;
				background-color: var(--eke-color-bg);
				width: 150px;
				color: var(--greyscale-700, #616161);
				text-align: center;
				font-size: 16px;
				font-family: var(--eke-body-font-family);
				font-weight: var(--eke-font-weight-semibold) !important;
				line-height: 140%;
				letter-spacing: 0.2px;
			}
		}


		.SocialAuth_method_style{
			width: 100%;
			display: flex;
			justify-content: center;
			margin-top: 5px;

			.Listauth_Style{
				width: 100%;

				.auth_Card{
					display: flex;
					width: 100% !important;
					height: 50px;
					padding: 18px 32px !important;
					justify-content: center;
					align-items: center;
					gap: 1em;
					border: 1px solid var(--greyscale-200, #EEE);
					background: var(--eke-color-white);
					color: var(--greyscale-700, #616161);
					text-align: center;
					font-size: 16px;
					font-family: var(--eke-body-font-family);
					font-weight: var(--eke-font-weight-semibold) !important;
					line-height: 140%;
					letter-spacing: 0.2px;
					border-radius: 100px;

					svg{
						width: 23.04px !important;
						height: 24px;
					}
				}

			}
		}



		.Terms_Conditions-Style{
			display: flex;
			margin-top: 4px;
			width: 320px;
			flex-direction: column;
			justify-content: center;
			color: #616161;
			text-align: center;
			font-size: 14px;
			font-family: var(--eke-body-font-family);
			font-weight: var(--eke-font-weight-normal) !important;
			line-height: 140%;
			letter-spacing: 0.2px;

			a{
				color: var(--eke-color-primary) !important;
				text-decoration: none;

				&:hover{
					text-decoration: underline;
				}
			}
		}


	}
`;


export const FormGroupStyle = styled.div`
	flex: 1;
	width: 100%;
	height: auto;
	margin: 0 auto;

	label{
		color: var(--greyscale-700, #616161) !important;
		font-family: var(--eke-body-font-family);
		line-height: 140%;
		letter-spacing: 0.2px;
		
		span{
			font-size: 14px;
			font-weight: var(--eke-font-weight-medium) !important;
		}

		svg{
			margin-top: 6px;
		}
	}

	input{
		border-radius: 8px !important;
		border: 1px solid var(--gray-300, #D0D5DD) !important;
		background: var(--eke-color-white) !important;
		box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05) !important;
	}
`;