import React from 'react'
import { SectionCardStyle } from './styles'
import { FiChevronRight } from 'react-icons/fi'
import { RefreshLink } from '@ekenta/components'

const SectionCard = (props: {
  title: string
  url?: string
  children: React.ReactNode
  size?: string
  render?: JSX.Element
}) => {
  return (
    <SectionCardStyle>
      <div className="TopHeader__Style">
        <h5>{props.title}</h5>
        {props.size === 'small' ? (
          <RefreshLink to={props.url ? props.url : ''}>
            <span>See All</span>
            <FiChevronRight />
          </RefreshLink>
        ) : (
          <>{props.render}</>
        )}
      </div>
      <div className="BottomContent__Style">{props.children}</div>
    </SectionCardStyle>
  )
}

export default SectionCard
