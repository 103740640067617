/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Col, Row } from 'react-bootstrap';
import * as queryString from 'query-string';
import { animateScroll } from 'react-scroll';
import styled from 'styled-components';

import CollectionSideBar from '../component/CollectionSideBar';
import { FilterIcon, SortIcon } from '../../../components/icons/icons';
import { useAppSelector } from '../../../redux/store/hook';
import { useFetchCartItemsQuery } from '../../../redux/store/services/cartApi';
import Price_Sort_Component from '../component/Filters/Sort/Sort';
import withDefaultLayout from '../../../hoc/Layout/withDefaultLayout';
import { HelmetHeader } from '@ekenta/components';

import { CollectionProvider } from '@ekenta/context/CollectionContext';
import SearchMainBar from './component/SearchMainBar';
import { PageTitle } from '@ekenta/components';
import PopOver from '../component/inner/PopoverBottom';
import MobileSort from '../component/Filters/Sort/MobileSort';
import MobileFilters from '../component/Filters/MobileFilters';
import { useFetchSingleSubCollectionCategoryQuery } from '@ekenta/redux/store/services/collectionApi';
import { useSearchBrandProductQuery, useSearchProductMutation, useSearchSellerProductQuery } from '@ekenta/redux/store/features/search/searchApiSlice';

const SearchResult = () => {
  const params = useParams();
  const { token } = useAppSelector((state) => state.auth.authentication);

  const urlParams = new URLSearchParams(window.location.search);

  const myParam = urlParams.get('q');
  const category = urlParams.get('category');
  const uId = urlParams.get('uid');

  // if (!category) {
  //   navigate("*");
  // }

  // get the query params from the url
  const queryParam = queryString.parse(window.location.search);
  const _page = queryParam.page;
  const [pageno, setPageno] = useState<any>(1);

  const subCollectionId = params['subslug']
    ? params.subslug!.slice(params.subslug!.lastIndexOf('-') + 1)
    : null;
  const categoryId = params['categoryslug']
    ? params.categoryslug!.slice(params.categoryslug!.lastIndexOf('-') + 1)
    : null;

  // Search by products
  const [searchProduct, getResult] = useSearchProductMutation();

  // Search result by seller
  const sellerId = category === 'seller' ? uId : null;
  const getSellerProduct = useSearchSellerProductQuery(
    { sellerId, pageno },
    { skip: !sellerId },
  );

  //Search result by brand
  const brandId = category === 'brands' ? uId : null;
  const getBrandProduct = useSearchBrandProductQuery(
    { brandId, pageno },
    { skip: !brandId },
  );

  const { data: allCartData } = useFetchCartItemsQuery(token, { skip: !token });
  const [productViewIcon, setProductViewIcon] = useState('grid');
  const [_loading, setLoading] = useState(false);

  const getCategory = useFetchSingleSubCollectionCategoryQuery(categoryId, {
    skip: !categoryId,
  });

  useEffect(() => {
    scrollToTop();
    const body = {
      keyword: myParam,
    };
    searchProduct(body);
  }, [myParam]);

  useEffect(() => {
    scrollToTop();
  }, [_page]);

  const switchToGridHandler = () => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
      setProductViewIcon('grid');
    }, 1000);
  };

  const switchToListHandListler = () => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
      setProductViewIcon('list');
    }, 1000);
  };

  const scrollToTop = () => {
    animateScroll.scrollToTop();
  };

  const [showPopover, setShowPopover] = useState({
    type: '',
    show: false,
  });

  const handleDisplay = (type: string, show: boolean) => {
    setShowPopover({
      type: type,
      show: show,
    });
  };

  const resultData = () => {
    if (category === 'seller' && getSellerProduct?.isSuccess) {
      return getSellerProduct?.data.products;
    } else if (category === 'brands' && getBrandProduct?.isSuccess) {
      return getBrandProduct?.data.products;
    } else if (getResult?.data) {
      return getResult?.data.result;
    } else {
      return { data: [] };
    }
  };

  const CustomButton = (props: {
    icon: React.ReactElement;
    type: string;
    onClick: () => void;
  }) => (
    <Button className="sub_filter_sorting_" onClick={props.onClick}>
      {props.icon}
      <span>{props.type}</span>
    </Button>
  );

  return (
    <>
      <HelmetHeader
        title={`Search: ${myParam} `}
        description="QlasonSearch Page"
      />
      <CollectionProvider>
        <div className="mb_padd_ d_collection_wrapper ">
          {/* <div
            className="pt-1 pb-3 px-0 bg-white"
            style={{ borderBottom: "1px solid#e8eaef" }}
          >
            <Container className="mt-3 d-none d-lg-block container_bread_crumb">
              <h4 className="mb-2">Result for: {myParam} </h4>
            </Container>
          </div> */}

          <div className="collection_grid_v">
            <>
              <Row>
                <CollectionSideBar
                  queryParam={queryParam}
                  subCollectionId={subCollectionId}
                  categoryId={categoryId}
                />

                <Col xs={12} lg={10} className="collection_mainBar">
                  <div
                    className=""
                    style={{
                      marginBottom: '5px',
                      marginTop: '8px',
                      borderBottom: '1px solid#e8eaef',
                    }}
                  >
                    <PageTitle
                      title={`Result for: ${myParam}`}
                      margin={false}
                    />
                  </div>
                  <div className="d-block d-lg-none my-3 mt-2 _filter_sorting_ d-flex align-items-center">
                    <Container>
                      <CustomButton
                        type="Sort"
                        icon={<SortIcon className="sort__fiIcon" />}
                        onClick={() => handleDisplay('sort', true)}
                      />
                      <CustomButton
                        type="Filter"
                        icon={<FilterIcon className="sort__fiIcon2" />}
                        onClick={() => handleDisplay('filter', true)}
                      />
                    </Container>
                  </div>
                  {/* <div className="mt-3 d-block d-lg-none section__m_div">
                    <div className="my-3 mt-2 _filter_sorting_ d-flex align-items-center">
                      <Link className="sub_filter_sorting_" to="?open=sort">
                        <SortIcon className="sort__fiIcon" />
                        <span>Sort</span>
                      </Link>
                      <Link className="sub_filter_sorting_" to="?open=filter">
                        <FilterIcon className="sort__fiIcon2" />
                        <span>Filter</span>
                      </Link>
                    </div>
                  </div> */}
                  <Col className=" d-none d-lg-block price_sort_wrapper">
                    <Price_Sort_Component
                      gridHandler={switchToGridHandler}
                      listHandler={switchToListHandListler}
                      productViewIcon={productViewIcon}
                    />
                  </Col>
                  <SearchMainBar
                    allCartData={allCartData}
                    isSuccess={
                      category === 'seller'
                        ? getSellerProduct?.isSuccess
                        : category === 'brands'
                          ? getBrandProduct?.isSuccess
                          : getResult?.isSuccess
                    }
                    loading={
                      category === 'seller'
                        ? getSellerProduct?.isLoading
                        : category === 'brands'
                          ? getBrandProduct?.isLoading
                          : getResult?.isLoading
                    }
                    productViewIcon={productViewIcon}
                    queryNo={_page}
                    pageno={pageno}
                    isError={
                      category === 'seller'
                        ? getSellerProduct?.isError
                        : category === 'brands'
                          ? getBrandProduct?.isError
                          : getResult?.isError
                    }
                    setPageno={setPageno}
                    data={resultData()}
                  />
                </Col>
              </Row>
            </>
          </div>
          <div className="popover_panel_container">
            <PopOver
              show={showPopover.show}
              type={showPopover.type}
              setOutside={() =>
                setShowPopover(() => ({ type: '', show: false }))
              }
            >
              {showPopover.type === 'sort' ? (
                <MobileSort setShowPopover={setShowPopover} />
              ) : (
                <MobileFilters
                  setShowPopover={setShowPopover}
                  queryParam={queryParam}
                  collectionId={
                    getCategory?.isSuccess
                      ? getCategory?.data.sub_collection_category?.collection_id
                      : null
                  }
                />
              )}
            </PopOver>
          </div>
        </div>
      </CollectionProvider>
    </>
  );
};

const Container = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  overflow-y: hidden;
  overflow-x: auto;
`;
const Button = styled.button`
  outline: none;
  border: none;
  padding-top: 12px;
  border-radius: 25px;
  padding-bottom: 12px;
  margin-left: 10px;
  display: flex;
  align-items: center;
  min-width: 150px;
  justify-content: center;
`;

export default withDefaultLayout(SearchResult);
