/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState } from 'react'
import { useFormik } from 'formik'
import toast from 'react-hot-toast'
import { REACT_APP_ENV } from '@ekenta/constants'
import CustomModal from '../Modal/CustomModal'
import Field from '../InputField/Field/Field'
import { useAppSelector } from '@ekenta/redux/store/hook'
import FlutterwavePay from '../FlutterwavePay/FlutterwavePay'
import { useCreateVoucherMutation } from '@ekenta/redux/store/features/voucher/voucherApiSlice'
import { ModalContent } from './style'
import Button from '../Button/Button'
import { formatCurrency } from '@ekenta/utils'

interface FormValue {
  recipientName: string
  recipientEmail: string
  cost: string
  description: string
}

interface createType {
  cost: number
  transaction_ref: string
  recipient_email: string
  recipient_name: string
  isTest: boolean
}

const BuyVoucherModal = (props: {
  cost: number
  voucher_id: number
  isOpen: boolean
  onClose: () => void
}) => {
  const { cost, voucher_id, isOpen, onClose } = props
  const { account } = useAppSelector((state) => state.auth.authentication)
  const [loading, setLoading] = useState(false)

  const isTestEnv = REACT_APP_ENV === 'PRODUCTION' ? false : true

  const [createVoucher, { isLoading }] = useCreateVoucherMutation()

  const formik = useFormik({
    initialValues: {
      recipientName: '',
      recipientEmail: '',
      cost: cost,
      quantity: 1,
    },
    enableReinitialize: true,
    validationSchema: '',
    onSubmit: (values) => {
      console.log(values)
    },
  })

  const initializeFlutterwave = async (values: FormValue, response: any) => {
    const data = {
      cost: response.charged_amount,
      transaction_ref: response.transaction_id,
      recipient_email: values.recipientEmail,
      recipient_name: values.recipientName,
      isTest: isTestEnv,
      voucher_id: voucher_id,
    }
    await onCreate(data)
  }

  const onCreate = async (data: createType) => {
    await createVoucher(data)
      .unwrap()
      .then(() => {
        setLoading(false)
        toast.success('Voucher successfully', {
          position: 'bottom-center',
        })
        onClose()
      })
      .catch((error) => {
        setLoading(false)
        const message = error?.data?.detail || 'Something went wrong'
        toast.error(message, { position: 'bottom-center' })
      })
  }

  const handleClose = () => {
    setLoading(false)
    onClose()
  }

  return (
    <CustomModal
      show={isOpen}
      size="sm"
      title="Purchase Voucher"
      subtitle=""
      centered={false}
      showFooter={false}
      isLoading={false}
      isDisabled={false}
      headerClass="app-modalheader event-modalhead"
      modalClass="app-modalbody"
      onHide={handleClose}
      render={
        <ModalContent>
          <form className="form-field">
            <div className="modal--body ">
              <Field
                id="recipientName"
                name="recipientName"
                label="Recipient Name"
                disabled={false}
                type="text"
                required
                size="large"
                childOutsideLabel={false}
                placeholder="Name"
                fieldClassName=""
                autoComplete="off"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.recipientName}
                isError={
                  formik.touched.recipientName && formik.errors.recipientName
                    ? true
                    : false
                }
                error={formik.errors.recipientName}
              />
              <Field
                id="recipientEmail"
                name="recipientEmail"
                label="Email Address or Username"
                disabled={false}
                type="email"
                required
                size="large"
                childOutsideLabel={false}
                placeholder="Email address"
                fieldClassName=""
                autoComplete="off"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.recipientEmail}
                isError={
                  formik.touched.recipientEmail && formik.errors.recipientEmail
                    ? true
                    : false
                }
                error={formik.errors.recipientEmail}
              />
              <div className="quantity-container">
                <div className="quantity-label">
                  <label>Amount</label>
                  <p>{formatCurrency(cost || 0)}</p>
                </div>
              </div>
            </div>
            <div className="modal--footer">
              <Button
                name="Continue Shopping"
                type="button"
                className="shopping-btn"
                bgVariant=""
                disabled={loading || isLoading}
                defaultClass={false}
                onClick={onClose}
              />
              <FlutterwavePay
                amountToPay={formik.values.cost || cost}
                customerEmail={account?.user?.email || ''}
                customerName={account?.user?.firstName || ''}
                customerPhoneNumber={account?.user?.phoneNumber || ''}
                descriptionOfPayment="Payment for new Qlason Voucher"
                callback={(response) => {
                  initializeFlutterwave(
                    {
                      ...formik.values,
                      cost: formik.values.cost?.toString(),
                      description: '',
                    },
                    response
                  )
                }}
                disabled={
                  !formik.values.cost ||
                  !formik.values.recipientEmail ||
                  !formik.values.recipientName
                }
                loading={loading}
                setLoading={setLoading}
              />
            </div>
          </form>
        </ModalContent>
      }
    />
  )
}

export default BuyVoucherModal
