import React, { useEffect } from 'react'
import { Col } from 'react-bootstrap';
import { animateScroll } from "react-scroll";
import AppContent from '../../components/AppContent';
import ErrorBoundaries from '../../components/ErrorBoundary/ErrorBoundaries';
import CheckoutNavBar from '../../components/NavigationBar/CheckoutNav/CheckoutNavBar'

export default function withCheckoutLayout(WrappedComponent: () => JSX.Element) {
  const CheckoutLayout = (props: any) => {

    useEffect(() => {
      scrollToTop();
    }, [])


    const scrollToTop = () => {
      animateScroll.scrollToTop();
    }

    return (
      <>
        <Col>
          <CheckoutNavBar />
          <ErrorBoundaries externalError={false} wrapContent={true}>
            <AppContent
              full={true}
              isPad={false}
            >
              <WrappedComponent {...props} />;
            </AppContent>
          </ErrorBoundaries>
        </Col>
      </>
    )
  }
  CheckoutLayout.displayName = 'CheckoutLayout'
  return CheckoutLayout;
}
