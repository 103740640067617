/* eslint-disable @typescript-eslint/no-explicit-any */
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { BASE_URL } from '@ekenta/constants'
import { SigninUserType } from '@ekenta/interfaces/Authentication'

// Headers
const authenticationApiHeaders = {
  'Content-Type': 'application/json',
}

// helper func for returning the headers and others
const createMutationRequest = ({
  url,
  method,
  body,
}: {
  url: string
  method: string
  body: any
}) => ({
  url: url,
  headers: authenticationApiHeaders,
  method: method,
  body: body,
})

export const authenticationApi: any = createApi({
  reducerPath: 'authenticationApi',
  baseQuery: fetchBaseQuery({ baseUrl: BASE_URL }),
  endpoints: (builder) => ({
    verifyUserEmailUsingCode: builder.mutation<
      void,
      { email: string; code: string }
    >({
      query: (data) =>
        createMutationRequest({
          url: `/api/verify`,
          method: 'POST',
          body: data,
        }),
    }),

    registerUser: builder.mutation({
      query: (data) =>
        createMutationRequest({
          url: '/api/register',
          method: 'POST',
          body: data,
        }),
    }),

    signinUser: builder.mutation({
      query: (data) =>
        createMutationRequest({
          url: '/api/login',
          method: 'POST',
          body: data,
        }),
    }),
    signInUserSocial: builder.mutation({
      query: (data) =>
        createMutationRequest({
          url: '/api/social-login',
          method: 'POST',
          body: data,
        }),
    }),
    resetUserPasswordWithEmail: builder.mutation<void, void>({
      query: (data) =>
        createMutationRequest({
          url: '/api/reset-password-init',
          method: 'POST',
          body: data,
        }),
    }),
    restoreUserPassword: builder.mutation<void, void>({
      query: (data) =>
        createMutationRequest({
          url: '/api/reset-password-finish',
          method: 'POST',
          body: data,
        }),
    }),
  }),
})

export const {
  useRegisterUserMutation,
  useSigninUserMutation,
  useVerifyUserEmailUsingCodeMutation,
  useResetUserPasswordWithEmailMutation,
  useResetPassVerifyTokenMutation,
  useRestoreUserPasswordMutation,
  useSignInUserSocialMutation,
} = authenticationApi
