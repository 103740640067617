/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react'
import { getIn } from 'formik'
import { Col, Form, ListGroup, Row } from 'react-bootstrap'
import NumberFormat from 'react-number-format'
import { DeliveryChannelItem } from './DeliveryChannelItem'
import Header from '../Header/Header'
import { CardBox, CustomAlert, Loader, Typography } from '@ekenta/components'
import { Fragment } from 'react'

interface DeliveryMethodPropsType {
  handleBlur: any
  setFieldValue: any
  errors: any
  values: any
  touched: any
  isSuccess: boolean
  shippingAddress: any | null
  //isLoading: boolean;
  loading: boolean
  getGIGResult: any
  data: any
}

function DeliveryMethod({
  handleBlur,
  setFieldValue,
  getGIGResult,
  shippingAddress,
  data,
  errors,
  values,
  touched,
  isSuccess,
  //isLoading,
  loading,
}: DeliveryMethodPropsType) {
  const deliveryEnums = {
    DELIVERY: {
      PICKUP: 'PICKUP_STATION',
      DOOR: 'DOOR_DELIVERY',
      DATE: '',
    },
  }

  const logisticsEnums = {
    EDS: 'EDS',
    GIG: 'GIG',
    REDSTAR: 'REDSTAR',
    PICKUP: 'PICKUP',
  }

  // Calulate to get percent off
  const getEdsPrice = (actualPrice: number, discount: number) => {
    const discoutBy = discount / 100
    const totalValue = actualPrice - actualPrice * discoutBy
    return parseInt(totalValue.toFixed(2))
  }

  return (
    <Col lg={12} className="checkout__delivery__option">
      <CardBox padding={false} className="card">
        <div className="card-body">
          {loading ? (
            <Loader isCenter />
          ) : (
            <>
              <Header
                title="Delivery Method"
                active={values.deliveryChannel}
                isBorder={shippingAddress ? true : false}
              />

              {shippingAddress ? (
                <Fragment>
                  {isSuccess && data ? (
                    <Col className="delivery__opt pt-0">
                      <div className="list__return__data">
                        <ListGroup>
                          <p className="mb-2">Selected Service - </p>
                          <ListGroup.Item className="d-flex align-items-center mb-1">
                            <span className="deliver__by_span">
                              {values.logistics === logisticsEnums.PICKUP ? (
                                <span className="pickup_from_span">
                                  PICKUP - Ikeja, Lagos <br />
                                  Eunice Orji
                                  <br />
                                  +234 706 808 2530
                                </span>
                              ) : (
                                'Delivered by'
                              )}{' '}
                            </span>
                            <span className="delivery__ag__name">
                              {values.logistics === logisticsEnums.EDS
                                ? 'QlasonDelivery Services'
                                : values.logistics === logisticsEnums.GIG
                                ? 'GIG Logistics'
                                : null}{' '}
                            </span>
                          </ListGroup.Item>
                          <ListGroup.Item>
                            <span
                              className="delivery__charges__"
                              style={{ textTransform: 'capitalize' }}
                            >
                              (Charge fee -
                              <NumberFormat
                                thousandSeparator
                                displayType="text"
                                decimalSeparator="."
                                decimalScale={2}
                                prefix="₦"
                                fixedDecimalScale
                                value={data.deliveryCharge}
                              />
                              )
                            </span>
                          </ListGroup.Item>
                        </ListGroup>
                      </div>
                    </Col>
                  ) : (
                    <Col className="delivery__opt mt-0 deliveryOrderBy pt-0">
                      <Typography
                        id="deliveryHeading"
                        className="title"
                        as="h5"
                      >
                        How do you want your order delivered?
                      </Typography>
                      <div className="list__delivery__agency">
                        <ListGroup className="p-0 m-0">
                          <ListGroup.Item className="px-0 py-0 mb-2">
                            <div className="d__checkbox_ mt-2">
                              <Form.Check
                                id="pickup"
                                type="radio"
                                name="deliveryChannel"
                                label="Pickup Station"
                                checked={
                                  values.deliveryChannel ===
                                  deliveryEnums.DELIVERY.PICKUP
                                }
                                onBlur={handleBlur}
                                onChange={(e: any) => {
                                  setFieldValue(
                                    'deliveryChannel',
                                    deliveryEnums.DELIVERY.PICKUP
                                  )
                                  setFieldValue('deliveryCharge', 0)
                                  setFieldValue(
                                    'logistics',
                                    logisticsEnums.PICKUP
                                  )
                                }}
                              />
                              <div className="notice__wrapper pt-1 ">
                                <CustomAlert show={true} variant="">
                                  <div>
                                    <span className="note__title">
                                      <b>Pickup Address</b>
                                    </span>
                                    <br />
                                    <div className="pickup-addresses">
                                      <Form.Check
                                        id="address1"
                                        type="radio"
                                        name="pickup_address"
                                        onBlur={handleBlur}
                                      />
                                      <label htmlFor="address1">
                                        11, bayode oluwole street, balogun bus
                                        stop, opposite lagoon hospital, Awolowo
                                        way, ikeja, Lagos
                                      </label>
                                    </div>
                                    <div className="pickup-addresses">
                                      <Form.Check
                                        id="address2"
                                        type="radio"
                                        name="pickup_address"
                                        onBlur={handleBlur}
                                      />
                                      <label htmlFor="address2">
                                        Reasons Court, No. 8 Lai Yusuf Crescent,
                                        (Back of De Place Restaurant) off
                                        Admiralty Way Lekki Phase 1, Lagos
                                        State.
                                      </label>
                                    </div>
                                    <div className="mb-2" />
                                    <span>
                                      <b>Contact Person</b>
                                      <br />
                                      <span>Eunice Orji</span>
                                      <br />
                                      <span>+234 706 808 2530</span>
                                    </span>
                                  </div>
                                </CustomAlert>
                              </div>
                            </div>
                          </ListGroup.Item>
                          <ListGroup.Item className="px-0 py-0 mb-2">
                            <div className="d__checkbox_ mt-2">
                              <Form.Check
                                id="door"
                                type="radio"
                                name="deliveryChannel"
                                label="Door Delivery"
                                checked={
                                  values.deliveryChannel ===
                                  deliveryEnums.DELIVERY.DOOR
                                }
                                onBlur={handleBlur}
                                onChange={(e: any) => {
                                  setFieldValue(
                                    'deliveryChannel',
                                    deliveryEnums.DELIVERY.DOOR
                                  )
                                  setFieldValue('deliveryCharge', null)
                                }}
                              />
                              {values.deliveryChannel ===
                              deliveryEnums.DELIVERY.DOOR ? (
                                <div className="ps-4 pt-3 pe-2">
                                  <div className="list__delivery__agency listDelivery">
                                    <Row>
                                      <Col lg={10}>
                                        <div className="mb-2">
                                          <p className="mb-0">Method</p>
                                          <span>
                                            Select your prefered delivery agency
                                            of your choice{' '}
                                          </span>
                                        </div>
                                      </Col>
                                      <Col lg={2}>
                                        <div className="mb-2">
                                          <p className="mb-0">Price</p>
                                          <span />
                                        </div>
                                      </Col>
                                    </Row>

                                    <ListGroup className="p-0 mt-2">
                                      <DeliveryChannelItem
                                        companyName="QlasonDelivery Service"
                                        logistics={logisticsEnums.EDS}
                                        isLoadingPrice={
                                          getGIGResult?.isLoading ||
                                          !getGIGResult?.isSuccess
                                        }
                                        deliveryPrice={
                                          getGIGResult?.isSuccess
                                            ? getEdsPrice(
                                                getGIGResult?.data.price,
                                                10
                                              )
                                            : null
                                        }
                                        values={values}
                                        handleBlur={handleBlur}
                                        deliveryEnums={deliveryEnums}
                                        setFieldValue={setFieldValue}
                                        touched={touched}
                                        errors={errors}
                                        id="eds"
                                      />
                                      <DeliveryChannelItem
                                        companyName="GIG Logistics Express Service"
                                        logistics={logisticsEnums.GIG}
                                        isLoadingPrice={
                                          getGIGResult?.isLoading ||
                                          !getGIGResult?.isSuccess
                                        }
                                        deliveryPrice={
                                          getGIGResult?.isSuccess
                                            ? getGIGResult?.data.price
                                            : null
                                        }
                                        values={values}
                                        handleBlur={handleBlur}
                                        deliveryEnums={deliveryEnums}
                                        setFieldValue={setFieldValue}
                                        touched={touched}
                                        errors={errors}
                                        id="gig"
                                      />
                                      {getIn(errors, 'deliveryChannel') ? (
                                        <Form.Control.Feedback
                                          type="invalid"
                                          className="invalid-fbk"
                                        >
                                          {getIn(errors, 'deliveryChannel')}
                                        </Form.Control.Feedback>
                                      ) : null}
                                    </ListGroup>
                                    <Col className="below--deliver--method mt-4">
                                      <div className="delivery--durations">
                                        <span>
                                          <b>Note:</b> Items will arrive within
                                          1-3 days
                                        </span>
                                        <br />
                                        <span>
                                          Payment on delivery option only
                                          available with{' '}
                                          <b>QlasonDelivery Service</b>
                                        </span>
                                      </div>
                                      <br />
                                    </Col>
                                  </div>
                                </div>
                              ) : null}
                            </div>
                          </ListGroup.Item>
                        </ListGroup>
                      </div>
                    </Col>
                  )}
                </Fragment>
              ) : null}
            </>
          )}
        </div>
      </CardBox>
    </Col>
  )
}

export default DeliveryMethod
