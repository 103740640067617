import React, { FC } from 'react'
import styled from '@emotion/styled'
import { ReactComponent as FeatureIcon } from '@ekenta/assets/icons/send-circle-icon.svg'
import { Button, Field } from '@ekenta/components'
import { device } from '@ekenta/utils/devices'

interface PropType {
  body: string
}

const Content: FC<PropType> = (props) => {
  const { body } = props
  return (
    <Flex>
      <div
        className="post--description"
        dangerouslySetInnerHTML={{ __html: body }}
      />
      <div className="news--letter">
        <div className="news--letter-card">
          <FeatureIcon />
          <div>
            <h5>Weekly newsletter</h5>
            <p>
              No spam. Just the latest releases and tips, interesting articles,
              and exclusive interviews in your inbox every week.
            </p>
          </div>
          <div className="news--letter--field">
            <form>
              <Field
                id="newsletter-field"
                name="newsletter"
                type="email"
                disabled={false}
                value={''}
                fieldClassName="newsletter__InputField"
                placeholder="Enter your email"
                autoComplete="off"
              />
              <div className="newsletter__Button">
                <Button
                  name="Subscribe"
                  bgVariant="primary"
                  shadow={false}
                  type="submit"
                  isLoading={false}
                  isLoadingText=" "
                />
              </div>
            </form>
          </div>
        </div>
      </div>
    </Flex>
  )
}

const Flex = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  gap: 96px;
  padding-top: 38px;

  @media ${device.lg} {
  flex-direction: row;
  }

  .post--description {
    flex: 1;
    min-height: 100px;
    color: #475467;
    letter-spacing: 0.2px;
    font-size: 18px;
  }

  .news--letter {
    flex: 0.6;
    flex-shrink: 0;
  }

  .news--letter-card {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    gap: 20px;
    width: 384px;
    height: auto;
    border-top: 4px solid var(--eke-color-primary);
    background-color: #f5fbff;
    border-top-right-radius: 0px;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 16px;
    border-bottom-right-radius: 16px;
    padding: 32px 32px 40px 32px;

    h5 {
      font-weight: 700;
      font-size: 23px;
      line-height: 28.8px;
      color: var(--eke-color-primary);
    }

    p {
      font-size: 16px;
      font-weight: 500;
      line-height: 21.5px;
      letter-spacing: 0.2px;
      color: #475467;
    }

    .news--letter--field {
      width: 100%;

      .newsletter__InputField {
        width: 100%;
        border-radius: 8px !important;
        height: 44px;
      }

      button {
        width: 100% !important;
        margin-top: 15px !important;
        font-weight: 600;
        color: #fff;
      }
    }
  }
`

export default Content
