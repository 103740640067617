import React from "react"
import Logo from "@ekenta/components/Logo";

import "./ErrorWrapper.scss";

const ErrorWrapper = ({ children }: { children: React.ReactNode }) => {
    return (
        <>
            <div className="ErrorUI___Wrapper">
                <div className="ErrorBound__container">
                    <div className="ErrorBound_Logo__wrapper">
                        <a href="/"><Logo width="30px" /></a>
                    </div>
                    <div className="ErrorBound_Content__wrapper">
                        {children}
                    </div>

                </div>

            </div>
        </>
    )
}

export default ErrorWrapper;