/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useCallback, useEffect, useState } from 'react'
import axios from 'axios';
import styled from 'styled-components';
import { toast } from "react-toastify";
import { Link, useNavigate } from 'react-router-dom';
import { Switch } from '@mui/material';
import { Filters } from './components/Filters';
//import Card from './components/Card';
import { useAppSelector } from '@ekenta/redux/store/hook';
import { Button, DataTable, Dropdown, HelmetHeader, PageTitle, Typography } from '@ekenta/components';
import { withDashboardLayout } from '@ekenta/hoc';
import { BASE_URL } from '@ekenta/constants';
import { ReactComponent as WelcomeIcon } from "@ekenta/assets/icons/hand-wave.svg";
import { ReactComponent as ProductIcon } from "@ekenta/assets/icons/order-value-icon.svg";
import { ReactComponent as OrderIcon } from "@ekenta/assets/icons/delivery-fee-icon.svg";
import { ReactComponent as DocIcon } from "@ekenta/assets/icons/doc-icon.svg";
import { ReactComponent as InfoIcon } from "@ekenta/assets/icons/info-curve-icon.svg";
import { ReactComponent as CancelIcon } from "@ekenta/assets/icons/cancel-curve-icon.svg";
import { ReactComponent as ReturnIcon } from "@ekenta/assets/icons/return-curve-icon.svg";
import { ReactComponent as UsersIcon } from "@ekenta/assets/icons/users-curve-icon.svg";
import { ReactComponent as ChartIcon } from "@ekenta/assets/icons/chart-curve-icon.svg";

import { DashboardAddProductPathUrl, DashboardOrdersPathUrl, DashboardProductsPathUrl, DashboardReturnRequestPathUrl, productBaseUrl } from '@ekenta/routes/constant';
import { ButtonGroupStyle, CardGroupStyle, DataTableWrapper, RenderCard, TypographyWrapper } from "../../common/common";
import { useGetAllProductsQuery } from '@ekenta/redux/store/services/vendorProductApi';
import { formatCurrency, formatNumber, slugifyText } from '@ekenta/utils';
import { allProductColumns } from '../common';
import { AppBoard } from '../../common/styles';
import "./Analytics.scss";
import { TextTruncate } from '../../common/Resuable';
import { FiChevronDown, FiEdit3, FiExternalLink, FiTrash2 } from 'react-icons/fi';

const Analytics = () => {
    const navigate = useNavigate();
    const { token, account } = useAppSelector(state => state.auth.authentication);
    const [isLoading, setIsLoading] = useState(false);
    const [getResult, setGetResult] = useState<any | null>(null);
    const [productData, setProductData] = useState([]);
    const pageno = 1;
    const result = useGetAllProductsQuery({ pageno, keyword: "", token });


    useEffect(() => {
        if (result?.data) {
            setProductData(result?.data?.product.data)
        }
    }, [result?.data]);


    const listData = [
        {
            name: "Total Products",
            value: `${getResult ? formatNumber(getResult?.totalProducts) : 0}`,
            icon: <ProductIcon />,
            link: DashboardProductsPathUrl,
        },
        {
            name: "Total Completed Orders",
            value: `${getResult ? formatNumber(getResult?.totalCompletedOrders) : 0}`,
            icon: <OrderIcon />,
            link: `${DashboardOrdersPathUrl}?_status=COMPLETED`,

        },
        {
            name: "Total Pending Orders",
            value: `${getResult ? formatNumber(getResult?.totalPendingOrders) : 0}`,
            icon: <InfoIcon />,
            link: `${DashboardOrdersPathUrl}?_status=PENDING`,
        },
        {
            name: "Total Cancelled Orders",
            value: `${getResult ? formatNumber(getResult?.totalCancelledOrders) : 0}`,
            icon: <CancelIcon />,
            link: `${DashboardOrdersPathUrl}?_status=CANCELLED`,
        },
        {
            name: "Total Return Request",
            value: `${getResult ? formatNumber(getResult?.totalProductReturnRequest) : 0}`,
            icon: <ReturnIcon />,
            link: DashboardReturnRequestPathUrl,
        },
        { name: "Total Followers", value: `${getResult ? formatNumber(getResult.followers) : 0}`, icon: <UsersIcon />, link: "#" },
        {
            name: "Average Orders",
            value: `${getResult ? formatNumber(getResult?.averageOrderValue) : 0}`,
            icon: <DocIcon />,
            link: "#"
        },
        {
            name: "Gross Merchandise Value",
            value: `${getResult ? formatNumber(getResult?.grossMerchandiseValue) : 0}`,
            icon: <ChartIcon />,
            link: "#"
        },
    ];

    const columns = [
        ...allProductColumns,
        {
            Header: 'Published',
            accessor: 'visiblity',
            disableSortBy: true,
        },
        {
            Header: 'Action',
            accessor: 'option',
            disableSortBy: true,
        },
    ];

    const mapData = (data: any) => {
        return data.length > 0 ? data.slice(0, 4).map((item: any) => ({
            ...item,
            image: (
                <div
                    className="picture__thumb"
                    style={{ width: '50px', height: 'auto', maxHeight: '60px', overflow: "hidden" }}
                >
                    <img
                        className="img-thumbnail rounded"
                        width="100%"
                        height="90%"
                        style={{ maxHeight: '60px', }}
                        src={item?.images.length > 0 ? item.images[0]?.image_url : ''}
                    />
                </div>
            ),
            name: (
                <TextTruncate>
                    {item?.visibility && !item?.is_flagged ? (
                        <Link to={`${productBaseUrl}/${slugifyText(item?.name.toLowerCase(), item?.id)}`} target="_blank">
                            {item?.name}
                            <FiExternalLink fontSize="0.7rem" />
                        </Link>
                    ) : item?.name}
                </TextTruncate>
            ),
            price: formatCurrency(item?.price),
            subcollection: item?.subCollection,
            stock: item?.stock,
            visiblity: (
                <Switch
                    readOnly={item?.is_flagged}
                    size="small"
                    color="warning"
                    defaultChecked={item?.stock > 0 ? item?.visibility : false}
                    disabled={item?.stock > 0 && !item?.is_flagged ? false : true}
                    onChange={(e) =>
                        item?.stock > 0 && !item?.is_flagged
                            ? togglePublish(item?.id, e.target.checked)
                            : console.log('')
                    }
                />
            ),
            option: (
                <Dropdown
                    name={'more'}
                    suffixIcon={<FiChevronDown />}
                    placement="bottom"
                    menus={[
                        {
                            name: 'Edit product',
                            icon: <FiEdit3 color="#22BB9C" />,
                            active: false,
                            key: 'live-code-key',
                            handler: () =>
                                navigate(
                                    DashboardAddProductPathUrl + `?_id=${item?.id}&type=edit`,
                                ),
                        },
                        {
                            name: 'Delete product',
                            icon: <FiTrash2 color="#FF4D67" />,
                            active: false,
                            key: 'view-detail',
                            handler: () => handleDelete(item?.id),
                        },
                    ]}
                    tooltipContent={'More Option'}
                />
            ),
        })) : []
    };

    const togglePublish = (productId: string, checked: boolean) => {
        if (productId) {
            const status = checked ? 'publish' : 'unpublish';
            toggleFunction(status, productId, checked);
        }
    };

    const toggleFunction = useCallback(
        async (status: string, prodId: string, checked: boolean) => {
            const url = 'seller/product-status';
            const config = {
                method: 'get',
                url: `${BASE_URL}/api/${url}/${status}/${prodId}`,
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
                data: '',
            };
            setIsLoading(true);
            await axios(config)
                .then(function (data) {
                    const product = data?.data?.product;
                    setProductData((prevState: any) =>
                        prevState.map((productItem: any) =>
                            productItem.id === product.id
                                ? { ...productItem, visibility: product.visibility }
                                : productItem
                        )
                    );
                    setIsLoading(false);
                    toast.success(`Product ${status} successfully`, {
                        toastId: '-product-toggle-success-id-toast',
                    });
                })
                .catch(function () {
                    setProductData((prevState: any) =>
                        prevState.map((productItem: any) =>
                            productItem.id === prodId
                                ? { ...productItem, visibility: !checked }
                                : productItem
                        )
                    );
                    setIsLoading(false);
                    toast.error(`Something went wrong`, {
                        toastId: '-toggle-error-400-id-toast-error',
                    });
                });
        },
        [],
    );

    const handleDelete = async (id: any) => {
        const config = {
            method: 'get',
            maxBodyLength: Infinity,
            url: `${BASE_URL}/api/seller/delete-product/${id}`,
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
        };

        setIsLoading(true);
        await axios(config)
            .then(function () {
                setProductData((prevState: any) =>
                    prevState.filter((item: any) => item.id !== id));
                setIsLoading(false);
                toast.success(`Product deleted successfully`, {
                    toastId: '-product-toggle-success-id-toast',
                });
            })
            .catch(function () {
                setIsLoading(false);
                toast.error(`Something went wrong`, {
                    toastId: '-delete-error-400-id-toast-error',
                });
            });
    };


    const filterFunction = useCallback((date: string) => {
        const config = {
            method: 'get',
            url: `${BASE_URL}/api/seller/dashboard/${date}`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            data: ""
        };
        setIsLoading(true);
        axios(config)
            .then(function (response: any) {
                setGetResult(response.data.data);
                setIsLoading(false);
                toast.success(`${response.data.message}`, {
                    toastId: "-managemet-toggle-success-id-toast",
                });
            })
            .catch(function () {
                setIsLoading(false);
                toast.error(`Something went wrong`, {
                    toastId: "-toggle-error-400-id-toast-error",
                });
            });
    }, []);


    return (
        <>
            <HelmetHeader
                title="Seller Dashboard"
                description="Qlason Analytics Dashboard Page"
            />
            <AppBoard className="_listing_product_ Analytics__Div">
                <div className="mx-1 mx-lg-0">
                    <PageTitle
                        title={<Flex>Hi, {account.user.merchant_business ? account.user.merchant_business.business_name : account.user ? account.user.firstName : 'Seller'}<WelcomeIcon /></Flex>}
                        reponsiveCol={true}
                        subTitle={
                            <SubTitle>
                                Dashboard Overview
                            </SubTitle>}>
                        <ButtonGroupStyle defaultStyle>
                            <Filters
                                className="outline_role outline_role_button"
                                onClick={filterFunction}
                            />
                            <Button
                                name="Create Product"
                                bgVariant="primary"
                                textVariant="light"
                                type="button"
                                onClick={() => navigate(`${DashboardAddProductPathUrl}?type=add&condition=new`)}
                            />
                        </ButtonGroupStyle>
                    </PageTitle>
                </div>

                <div>
                    {
                        isLoading ? null :
                            getResult?.offers.map((e: any, i: any) => (
                                <a key={i} href={e.link} target={'_blank'} rel="noreferrer">
                                    <img src={e.body} style={{ height: "100px", width: "100%", paddingRight: "20px" }} alt="" />
                                </a>
                                // <Alert key={i} variant={"info"} dismissible>
                                //     <Alert.Heading>{e?.title}</Alert.Heading>
                                //     <p>{e.body}</p>
                                // </Alert>
                            ))
                    }
                </div>
                <RowStyle>
                    <CardGroupStyle flexWrap={true}>
                        {listData.map((item: any, index: number) => (
                            <RenderCard key={"id" + index}
                                className="CardBox_Dash"
                                text={item.name}
                                amount={item.value}
                                icon={item.icon}
                                loading={isLoading}
                                isClickable={item.link !== "#" ? true : false}
                                onClick={() => navigate(item.link)}
                            />
                        ))}
                    </CardGroupStyle>
                </RowStyle>
                <DataTableWrapper isCenter={true} className="mt-4 position-relative">
                    <DataTable
                        theme="simple"
                        data={
                            productData
                                ? mapData(productData)
                                : []
                        }
                        loading={result?.isLoading || isLoading}
                        isSelectable={true}
                        columns={columns}
                        showPagination={false}
                        hasLink={true}
                        thClassName=""
                        trClassName=""
                        theadClassName="DataTable_THead"
                        tdClassName={`Row__col`}
                        showSearch={false}
                        renderTop={
                            <>
                                <TypographyWrapper>
                                    <Typography
                                        as="h3"
                                        color="default"
                                        size="xlarge"
                                        heading >
                                        Recently Added Products
                                    </Typography>
                                </TypographyWrapper>
                                <TextBtnWrapper>
                                    <Button
                                        name="See All"
                                        textVariant="primary"
                                        type="button"
                                        shadow={false}
                                        onClick={() => navigate(DashboardProductsPathUrl)}
                                    />
                                </TextBtnWrapper>
                            </>
                        }
                    />
                    <br />
                </DataTableWrapper>
                {/* <Col className="w-100 mt-4">
                    <div className="Analytics_rowDirection">

                        <div className="Analytics__Col">
                            <Link to={'/vendor/dashboard/product?type=add&condition=new'} style={{ textDecoration: "none" }}>
                                <CardBox className="card border-none">
                                    <div className="card-body" style={{ background: "#1e293b", textDecoration: "none" }}>
                                        <div>
                                            <div style={{ textAlign: "center", textDecoration: "none" }} className='mx-auto text-white'><FaPlus fontSize={38} /></div>
                                            <h3 style={{ textAlign: "center", textDecoration: "none" }} className={"text-white"}>Create Product</h3>
                                        </div>
                                        <span className="Analytics__icon">
                                        </span>
                                    </div>
                                </CardBox>
                            </Link>
                        </div>


                        {listOfData.map((item: any, index: number) => (
                            <Card key={"id" + index}
                                name={item.name}
                                value={item.value}
                                icon={item.icon}
                                url={item.link}
                            />
                        ))}
                    </div>
                </Col> */}
                <br />
            </AppBoard>
        </>
    )
}

const RowStyle = styled.div`
    width: 100%;
    .CardBox{
        cursor: pointer !important;
    }
`;


const Flex = styled.span`
    display: flex;
    align-items: center;
    gap: 12.761px;
`

const SubTitle = styled.span`
    color: var(--greyscale-700, #616161);
    font-family: var(--eke-body-font-family);
    font-size: 14px;
    font-weight: var(--eke-font-weight-semibold);
    line-height: 140%; /* 22.4px */
    letter-spacing: 0.2px;

`;

const TextBtnWrapper = styled.div`
    button{
        background-color: transparent !important;
        cursor: pointer;
       span{
        font-size: 15px !important;
        font-weight: var(--eke-font-weight-bold) !important;
        line-height: 120%; /* 21.6px */
       }
    }

`;

export default withDashboardLayout(Analytics);