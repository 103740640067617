import { Button } from '@ekenta/components'
import { device } from '@ekenta/utils/devices'
import styled from 'styled-components'

export const StoreContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding-top: 0.2em;
  padding-bottom: 8em;

  @media ${device.lg} {
    padding-top: 1em;
  }
`

export const Wrapper = styled.div`
  width: 100%;
  max-width: var(--eke-container-maxWidth);
  height: auto;
`

export const StoreTop = styled.div`
  position: relative;
  width: 100%;
  height: 360px;
  border-radius: 12px;
  background: var(--others-white, #fff);
  border: 1px solid #e4f4ff;

  @media ${device.lg} {
    height: 377px;
  }

  .store-banner-container {
    width: 100%;
    border-top-right-radius: 12px;
    border-top-left-radius: 12px;
    background-color: #eee;
    height: 130px;
    overflow: hidden;

    .banner-wrapper {
      width: 100%;
      height: 100%;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
      border-top-right-radius: 12px;
      border-top-left-radius: 12px;
    }

    @media ${device.lg} {
      height: 200px;
    }
  }

  .store-content-container {
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 0px 69px;
    justify-content: center;
    margin-top: -18%;

    @media ${device.md} {
      margin-top: 0%;
      flex-direction: row;
      align-items: flex-start;
      justify-content: space-between;
    }

    .store-logowrapper {
      flex: 1;
      width: auto;
      display: flex;
      align-items: flex-start;
      flex-direction: column;
      gap: 15px;

      @media ${device.md} {
        flex-direction: row;
      }

      .logo-container {
        width: 128.077px;
        height: 128.077px;
        flex-shrink: 0;

        border-radius: 184px;
        margin-top: -10%;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;

        background-color: var(--eke-color-primary);

        h1 {
          font-size: 5rem;
          color: #fff;
        }

        img {
          width: 100%;
          height: 100%;
          border-radius: 184px;
          object-fit: cover;
          object-position: center;
        }

        @media ${device.lg} {
          width: 184px;
          height: 184px;

          h1 {
            font-size: 4rem;
          }
        }
      }

      .store-profile-info {
        width: 100%;
        text-align: center;
        font-family: var(--eke-body-font-family);
        padding-top: 0px;
        h5 {
          color: var(--greyscale-900, #212121) !important;
          font-size: 22px !important;
          font-weight: var(--eke-font-weight-bold) !important;
          line-height: 120%; /* 28.8px */
          margin-bottom: 4px !important;
        }
        p {
          margin: 0 !important;
          color: var(--greyscale-700, #616161);
          font-size: 14px !important;
          font-weight: var(--eke-font-weight-normal) !important;
          line-height: 140%; /* 25.2px */
          letter-spacing: 0.2px;
          margin-bottom: 4px !important;
        }

        @media ${device.md} {
          width: auto;
          text-align: left;
          padding-top: 15px;
        }
      }
    }

    .store-cta-btns {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      padding-top: 15px;

      button {
        display: flex;
        width: 92px !important;
        height: 41px !important;
        padding: 7.011px 6.232px !important;
        justify-content: center;
        align-items: center;

        span {
          font-size: 14px !important;
          font-weight: var(--eke-font-weight-semibold) !important;
          line-height: 140%; /* 22.4px */
          letter-spacing: 0.2px;
        }
      }

      .store-follow-cta {
      }

      .store-share-cta {
        border: 1px solid var(--eke-color-primary) !important;
        background-color: var(--eke-color-white) !important;

        span {
          color: var(--eke-color-primary) !important;
          font-family: var(--eke-body-font-family);
        }
      }
    }
  }
`

export const StoreBottom = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  align-items: stretch;
  justify-content: stretch;
  gap: 10px;
  margin-top: 25px;
`

export const StoreAside = styled.div`
  //flex-shrink: 1;
  display: none;
  flex: 0 0 20%;
  flex-direction: column;
  .col-lg-2 {
    width: 230px !important;
  }

  @media ${device.lg} {
    display: flex;
  }
`

export const StoreMainBar = styled.div`
  flex: 1;
  padding-left: 5px !important;
  padding-right: 5px !important;
  padding-top: 0.955rem;
  background-color: var(--eke-color-white);
  border: 1px solid #e4f4ff;
  border-radius: 12px;
  display: flex;
  flex-direction: column;

  .top--section {
    width: 100%;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: flex-start;
    .Heading {
      flex: 1;
      color: #000;
      font-family: var(--eke-body-font-family) !important;
      font-size: 20px !important;
      font-weight: var(--eke-font-weight-bold) !important;
      line-height: 120%; /* 24px */
      margin-bottom: 10px;
    }
  }

  @media ${device.md} {
    padding-left: 13.5px !important;
    padding-right: 13.5px !important;

    .top--section {
      flex-direction: row;
      justify-content: space-between;

      .Heading {
        margin-bottom: 0;
      }
    }
  }

  .main-wrapper {
    padding-bottom: 30px;
    padding-left: 2px;
    padding-right: 2px;

    .prod_commponent_row_ {
      padding-top: 15px !important;
      .col-lg-3 {
        flex: 0 0 auto !important;
        width: 46% !important;
      }

      @media ${device.lg} {
        .col-lg-3 {
          flex: 0 0 auto !important;
          width: 23% !important;
        }
      }
    }

    @media ${device.lg} {
      padding-left: 0px;
      padding-right: 0px;
    }
  }
`

export const SearchSortStyle = styled.div`
  flex: 1;
  position: relative;
  width: 100%;
  height: auto;
  margin-top: 5px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 16px;

  @media ${device.md} {
    width: 40%;
  }

  .filter-product {
    display: flex;
    @media ${device.lg} {
      display: none
    }
  }

  .SearchWrapper__Box {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: stretch;

    .InputField__fieldContainer {
      span {
        left: 2px !important;
        padding: 7px 11px 10px 10px !important;
      }
    }
    .InputField {
      width: 100% !important;
      input {
        border-radius: 30px !important;
        border: 1px solid var(--alerts-status-disabled, #d8d8d8) !important;
        box-shadow: none !important;
        padding-top: 7px !important;
        padding-bottom: 7px !important;
        background: #f5fbff !important;
      }
    }

    .SearchWrapper__Field {
      z-index: 0;
      width: 100% !important;
    }

    @media ${device.md} {
      width: auto;
    }
  }

  .sortBy_div {
    width: auto;
    display: flex;
    align-items: center;
    gap: 2px;

    .sort_Heading {
      font-size: 14.5px;
    }
  }
`

export const SortStyle = styled.button`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-width: 120px;
  height: auto;
  padding: 9px 20px;
  align-items: center;
  outline: none;
  flex-shrink: 0;
  border-radius: 6px;
  border: 1px solid var(--secondary-100, #b9d1e2);
  background: #fbfeff !important;
  color: var(--greyscale-600, #757575);
  font-family: var(--eke-body-font-family);
  font-weight: var(--eke-font-weight-normal);
  font-size: 14px;
  line-height: 140%; /* 19.6px */
  letter-spacing: 0.2px;
  text-transform: capitalize;
  border: 1px solid var(--greyscale-300, #e0e0e0);
  background: var(--others-white, #fff);
`

export const OutlineButton = styled(Button)`
  display: flex;
  width: 92px !important;
  height: 41px !important;
  padding: 7.011px 6.232px !important;
  justify-content: center;
  align-items: center;
  border: 1px solid var(--eke-color-primary) !important;
  background-color: var(--eke-color-white) !important;

  span {
    font-size: 14px !important;
    font-weight: var(--eke-font-weight-semibold) !important;
    line-height: 140%; /* 22.4px */
    letter-spacing: 0.2px;
    color: var(--eke-color-primary) !important;
    font-family: var(--eke-body-font-family);
  }
`
