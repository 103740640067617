import React from 'react'
import styled from 'styled-components'
//import { MailIcon } from '@ekenta/components/icons'
import { device } from '@ekenta/utils/devices'

const Hero = () => {
  return (
    <VStack>
      <Box>
        <Flex>
          <h1>Our Blog & Articles</h1>
          <p>
            Welcome to our Blog, your dedicated space for delving deeper into
            access to a wide range of products, and a personalized shopping
            experience, all from the comfort of your own home.
          </p>
          {/* <InputGroup>
            <div className="BlogField__Wrap">
              <form>
                <Field
                  id="newsletter-field"
                  name="newsletter"
                  type="email"
                  disabled={false}
                  value={''}
                  renderPrefix={<MailIcon />}
                  fieldClassName="Blog__InputField"
                  placeholder="Enter your email"
                  autoComplete="off"
                />
                <div className="BlogField__Button">
                  <Button
                    name="Submit"
                    bgVariant="primary"
                    shadow={false}
                    type="submit"
                    isLoading={false}
                    isLoadingText=" "
                  />
                </div>
              </form>
            </div>
          </InputGroup> */}
        </Flex>
      </Box>
    </VStack>
  )
}

const VStack = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 382px;
  background-color: rgba(255, 250, 245, 1);
`
const Box = styled.div`
  width: 90%;
  margin: 0 auto;

  @media ${device.md} {
    width: 70%;
  }

  @media ${device.lg} {
    width: 60%;
  }
`
const Flex = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  gap: 0.5em;

  h1 {
    font-family: var(--eke-body-font-family);
    font-size: 35px;
    font-weight: 700;
    color: var(--eke-color-primary);

    @media ${device.lg} {
      font-size: 56.28px;
    }
  }

  p {
    font-family: var(--eke-body-font-family);
    font-size: 17px;
    color: #475467;
    font-weight: 500;
    letter-spacing: 0.2px;
  }
`

// const InputGroup = styled.div`
//   position: relative;
//   width: 100%;
//   .BlogField__Wrap {
//     position: relative;
//     width: 100%;
//     margin: 0 auto;

//     @media ${device.lg} {
//       width: 60%;
//     }

//     svg {
//       stroke: #424242 !important;
//     }

//     .Blog__InputField {
//       display: flex;
//       width: 100%;
//       height: 50px;
//       //padding: 0px 6px 0px 24px !important;
//       padding-left: 35px !important;
//       padding-right: 35px !important;
//       align-items: center;
//       flex-shrink: 0;
//       border-radius: 30px;
//       border: 1px solid #eee;
//       background: #fff !important;
//       font-size: 14px;
//       color: var(--eke-color-dark);
//       font-family: var(--eke-body-font-family);
//       font-weight: var(--eke-font-weight-normal);
//       line-height: 140%;
//       letter-spacing: 0.2px;
//       box-shadow: none !important;

//       &::placeholder {
//         color: #424242;
//       }
//     }

//     .BlogField__Button {
//       position: absolute;
//       top: 7px;
//       right: 0;

//       button {
//         padding: 9.172px 22.931px !important;
//         font-size: 14px;
//         color: var(--eke-color-white);
//         font-family: var(--eke-body-font-family);
//         line-height: 140%;
//         letter-spacing: 0.2px;

//         span {
//           font-weight: var(--eke-font-weight-bold) !important;
//         }
//       }
//     }
//   }

//   /* @media ${device.md} {
//     .NewsLetterField__Wrap {
//       width: 60%;
//     }
//   } */
// `

export default Hero
