import React, { useCallback, useEffect, useState } from "react"
import { Spinner } from "react-bootstrap";
import { FiMinus, FiPlus } from "react-icons/fi";
import { useFindUserCartItemByIdReturnQuantityQuery, useRemoveUserCartItemMutation, useUpdateCartItemQuantityMutation, useUpdateUserCartItemQuantityMutation }
  from "../../../../redux/store/services/cartApi";
import { useAppDispatch, useAppSelector } from "../../../../redux/store/hook";
import { useLocation } from "react-router";
import { useMatchPath } from "../../../../utils";
import { productDetailPathUrl, singleLivePathUrl } from "../../../../routes/constant";
import { VscTrash } from "react-icons/vsc";
import { toast } from "react-toastify";
import { removeFromCart } from "../../../../redux/store/states/reducerSlice/cartSlice";

import styles from "./QuantityCartButton.module.scss";
import classnames from "classnames";
import { Button } from "@ekenta/components";

interface QuantityCartButtonPropsType {
  cartItemId: string | null;
  cartItems: any;
  className: string;
}

export default function QuantityCartButton({ cartItemId, cartItems, className }: QuantityCartButtonPropsType) {
  const { pathname } = useLocation();
  const dispatch = useAppDispatch();

  const [isAdded, setIsAdded] = useState(true)
  const [inputValue, setInputValue] = useState<number>(1);

  const [quantity, setQuantity] = useState<string | number>("");

  const { ek_isAuth, token } = useAppSelector(state => state.auth.authentication);


  const getQuantity = useFindUserCartItemByIdReturnQuantityQuery({ token, cartItemId }, { skip: !cartItemId });

  const [updateCartItemQuantity, { isLoading, error, isSuccess, isError }] = useUpdateCartItemQuantityMutation();
  const [removeUserCartItem, { isError: remove_isError, isSuccess: remove_isSuccess }] = useRemoveUserCartItemMutation();

  const isAddedMsg = !isAdded ? "Quantity reduced in your cart list" : "Quantity added to your cart list";

  useEffect(() => {

    if (isSuccess) {
      toast.success(`${isAddedMsg}`, {
        toastId: "quantity-increase-or-dec-id--toast-success",
      });
    }

    if (isError && error.status == 401) {
      toast.error(`${error.data.message}`, {
        toastId: "quantity-400-quantity-id-toast-error",
      });
    }

    if (isError && error.status == "FETCH_ERROR") {
      toast.error(`Cart Error unable to add the quantity to your cart list`, {
        toastId: "quantity-added-or-remove-id-toast-error",
      });
    }

    if (remove_isSuccess) {
      toast.success(`Cart item was removed from your cart list`, {
        toastId: "cart-item-remove-id-toast-sucess",
      });

    }

    if (remove_isError) {
      toast.error(`Cart Error unable to remove the item to your cart list`, {
        toastId: "cart-item-remove-un-id-toast-error",
      });
    }

    if (getQuantity?.data) {
      setInputValue(parseInt(getQuantity?.data.quantity));
    }

    if (inputValue && getQuantity?.data) {
      setQuantity(inputValue)
    }



  }, [
    isSuccess,
    remove_isError,
    error,
    isError,
    remove_isSuccess,
    getQuantity?.data
  ]);




  const handleReduceQuantity = () => {
    if (cartItemId) {
      if (ek_isAuth && token) {
        updateCartItemQuantity({
          token: token,
          cartItemId: cartItemId,
          type: "decrease"
        });
        setIsAdded(false)
      } else {
        toast.error("Sign in to reduce quantity !", {
          toastId: "signin-to-reduce-qty-id-toast-error",
        });
      }
    } else {
      toast.error("Could not find product", {
        toastId: "-qty-add-cart-list-id-toast-error",
      });
    }

  }

  const handleIncreaseQuantity = useCallback(() => {

    const _value = inputValue + 1;
    setInputValue(_value);
    if (cartItemId) {

      if (ek_isAuth && token) {
        updateCartItemQuantity({
          token: token,
          cartItemId: cartItemId,
          type: "increase"
        });
        setIsAdded(true)
      } else {
        toast.error("Sign in to increase quantity !", {
          toastId: "signin-to-increase-qty-id-toast-error",
        });
      }

    } else {
      toast.error("Could not find product", {
        toastId: "qty-cart-added-list-id-toast-error-id",
      });
    }


  }, [dispatch, cartItems])

  const handleRemoveCartItem = (cartItemId: string | null) => {
    if (cartItemId) {
      if (ek_isAuth && token) {
        removeUserCartItem({
          token: token,
          cartItemId: cartItemId
        })
      } else {
        dispatch(removeFromCart({ id: cartItemId }));
      }
    } else {
      toast.error("Could not find product", {
        toastId: "qty-cart-remove-list--id-toast-error-id",
      });
    }
  }



  return (
    <>
      {pathname !== useMatchPath(productDetailPathUrl, pathname) ? (
        <Button
          name="Rounded"
          defaultClass={false}
          width=""
          className="action_round_btn p-0">
          <span className="mr2">
            {ek_isAuth && token ? (
              <>
                {isLoading && !getQuantity?.data ? (
                  <div className="d-flex align-items-center justify-content-center">
                    <Spinner size="sm" animation="border" variant="white" />
                  </div>) :
                  <>
                    {getQuantity?.isSuccess && getQuantity?.data ? (<>{getQuantity?.data.quantity}</>) : (
                      <div className="d-flex align-items-center justify-content-center">
                        <Spinner size="sm" animation="border" variant="white" />
                      </div>
                    )}
                  </>}
              </>
            ) : (
              <>
                {cartItems ? (<>{cartItems.quantity}</>) : (
                  <div className="d-flex align-items-center justify-content-center">
                    <Spinner size="sm" animation="border" variant="white" />
                  </div>
                )}
              </>
            )}
          </span>
        </Button>
      ) : null}


      <div className={classnames(styles.QuantityCart__Button, className)}>
        <div className={styles.QuantityCart__ButtonDiv}>

          {getQuantity?.data && parseInt(getQuantity?.data.quantity) <= 1 || cartItems && cartItems.quantity <= 1 ? (
            <span className={classnames(styles.QuantityCart__Minus, quantity && parseInt(quantity.toString()) > 1 ? styles.QuantityCart__Active : "")}
              onClick={() => handleRemoveCartItem(cartItemId)} >
              <VscTrash />
            </span>
          ) : (
            <span className={classnames(styles.QuantityCart__Minus, quantity && parseInt(quantity.toString()) > 1 ? styles.QuantityCart__Active : "")}
              onClick={handleReduceQuantity} >
              <FiMinus />
            </span>
          )}

          <div className={styles.QuantityCart__Button_Display}>
            {ek_isAuth && token ? (
              <>
                {isLoading ? (<div className="d-flex align-items-center justify-content-center">
                  <Spinner size="sm" animation="border" variant="white" />
                </div>) : `${getQuantity?.data ? getQuantity?.data.quantity + " " + "added" : "loading..."} `}
              </>
            ) : (
              <>
                {cartItems && (<>{cartItems.quantity + " added"}</>)}
              </>
            )}

            <input
              className="w-25"
              style={{ display: "none" }}
              value={inputValue}
              disabled
            />
          </div>
          <span className={classnames(styles.QuantityCart__Increase, styles.QuantityCart__Active)}
            onClick={handleIncreaseQuantity}
          >
            <FiPlus />
          </span>
        </div>
      </div>
    </>
  )
}
