import React from 'react'

const SvgFavorite = ({
	width = "1rem",
	height = "1rem",
	className
}: {
	className?: string,
	width?: string;
	height?: string
}) => (
	<svg
		width={width}
		height={height}
		className={className}
		viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
		<g id="Iconly/Light/Heart">
			<g id="Heart">
				<path id="Stroke 1" fillRule="evenodd" clipRule="evenodd" d="M2.3929 10.355C1.49873 7.5633 2.54373 4.37247 5.47456 3.4283C7.01623 2.9308 8.7179 3.22413 9.99956 4.1883C11.2121 3.2508 12.9762 2.93413 14.5162 3.4283C17.4471 4.37247 18.4987 7.5633 17.6054 10.355C16.2137 14.78 9.99956 18.1883 9.99956 18.1883C9.99956 18.1883 3.83123 14.8316 2.3929 10.355Z" stroke="#F8880A" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
				<path id="Stroke 3" d="M13.333 6.27295C14.2247 6.56128 14.8547 7.35712 14.9305 8.29128" stroke="#F8880A" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
			</g>
		</g>
	</svg>
);

export default SvgFavorite;