import React from 'react'
import styles from "./ButtonGroup.module.scss";
import classnames from 'classnames';

interface ButtonGroupPropsType {
    children: React.ReactNode;
    className?: string;
    pos?: "left" | "right";
}
const ButtonGroup = (props: ButtonGroupPropsType) => {
    const Class = classnames({
        [styles.pos_Left]: props.pos === "left",
        [styles.pos_Right]: props.pos === "right"
    })
    return (
        <div className={classnames(styles.ButtonGroup__Wrapper, props.className, Class)}>
            {props.children}
        </div>
    )
}

export default ButtonGroup;