import React from "react";
import { Navigate, useSearchParams } from 'react-router-dom';
import { homePathUrl } from "../constant";
import { useAppSelector } from '../../redux/store/hook';


const AuthRoute = ({ component: RouteComponent, ...rest }: { component: () => JSX.Element; }) => {
  const { token } = useAppSelector(state => state.auth.authentication);
  const [params] = useSearchParams()
  const redirectPath = params.get('redirect') || null
  return !token ? <RouteComponent {...rest} /> : <Navigate to={redirectPath || homePathUrl} />


};

export default AuthRoute;