/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from "react";
import { withDefaultLayout } from "@ekenta/hoc";
import { toast } from "react-toastify";
import { useAppSelector } from "@ekenta/redux/store/hook";
import { Button, CustomAlert, HelmetHeader, Loader, Overlay, PageTitle } from "@ekenta/components";
import { Col, Container, Row } from "react-bootstrap";
import BreadCrumb from "../component/BreadCrumb";
import ProfileSideBar from "../component/AccountSideBar/AccountSideBar";
import BackButton from "../component/BackButton";
import classnames from "classnames";

import styles from "./Addresses.module.scss";
import AddressForm from "./Form/AddressForm";
import { useDeleteUserAddressMutation, useFetchAllAddressesQuery } from "@ekenta/redux/store/services/accountApi";
import AddressListItem from "./List/AddressListItem";

const AccountAddresses = () => {
    const { token } = useAppSelector(
        (state) => state.auth.authentication,
    );

    const getAllAddress = useFetchAllAddressesQuery(token);
    const [deleteAddress, resultAddress] = useDeleteUserAddressMutation();

    const [showForm, setShowForm] = useState(false);
    const [isEdit, setEdit] = useState({
        show: false,
        data: {}
    });

    // const handleShowClick = (data: any) => {
    //     setEditAddress(data);
    // };

    function handleShow() {
        setShowForm(true);
    }
    function handleCancel() {
        setShowForm(false);
        setEdit({ show: false, data: {} });
    }

    function handleDeleteUserAddress(id: string) {
        deleteAddress({ id, token });
    }

    useEffect(() => {

        if (resultAddress?.isSuccess) {
            toast.success(`${resultAddress?.data.message}`, {
                toastId: "remove-status-id-toast-success",
            });
        }
        if (resultAddress?.isError) {
            toast.error(`Something went wrong`, {
                toastId: "error-status-id",
            });
        }
    }, [
        resultAddress?.isSuccess,
        resultAddress?.isError

    ]);



    return (
        <>
            <HelmetHeader
                title="Manage Addresses "
                description="Qlason Delivery addresses Page"
            />

            <Container className="account-user-container position-relative">
                <BreadCrumb activePage="Addresses" />
                <Row className="account-pro-row g-2">
                    <ProfileSideBar isLoading={null} />
                    <Col className="account-profile-col-right">
                        <PageTitle
                            title="Address Book"
                            margin={false}
                            subTitle={<BackButton />}
                        />

                        {!showForm && !isEdit.show ? (
                            <div className={classnames(styles.NewAddress__Button)}>
                                <Button
                                    aria-label="Add address"
                                    name="+ Add address"
                                    as="text"
                                    textVariant="dark"
                                    className={styles.Button}
                                    onClick={handleShow}
                                />
                            </div>
                        ) : null}


                        {getAllAddress?.isError && getAllAddress?.error.status === "FETCH_ERROR" ? (
                            <CustomAlert
                                variant="danger"
                                show={true}
                                message={"Failed to fetch, Try again"}
                            />
                        ) : null}

                        {showForm || isEdit.show ? (
                            <Col sm={12} lg={8}>
                                <AddressForm
                                    isEdit={isEdit}
                                    setEdit={setEdit}
                                    onCancel={handleCancel}
                                />
                            </Col>
                        ) : (
                            <Col sm={12} lg={12}>
                                {getAllAddress?.data ? getAllAddress?.data.addresses.map((item: any) => (
                                    <AddressListItem
                                        key={item.id}
                                        item={item}
                                        setEdit={setEdit}
                                        handleRemove={handleDeleteUserAddress}
                                    />
                                )) : null}
                            </Col>
                        )}
                        {getAllAddress?.isLoading || resultAddress?.isLoading ? (
                            <Overlay bgColor="dark">
                                <Loader
                                    isCenter
                                    size="large"
                                    color="white"
                                />
                            </Overlay>
                        ) : null}
                    </Col>
                </Row>
            </Container>


        </>
    )
}

export default withDefaultLayout(AccountAddresses);