import React from "react";
import HoverVideoPlayer from "react-hover-video-player";
import LoadingOverlay from "./component/LoadingOverlay";
import PausedOverlay from "./component/PausedOverlay";


import "./VideoPlayerWithoutControls.scss";

interface VideoPlayerWithoutControlsPropsType {
  videoUrl: string;
  thumbnailUrl: string;
  title?: string | undefined;
}


export default function VideoPlayerWithoutControls(props: VideoPlayerWithoutControlsPropsType) {


  return (
    <HoverVideoPlayer
      videoSrc={props.videoUrl ? props.videoUrl : ""}
      pausedOverlay={<PausedOverlay posterUrl={props.thumbnailUrl} title={props.title} videoUrl={props.videoUrl} />}
      loadingOverlay={<LoadingOverlay />}
      muted={true}
      preload="metadata"
      unloadVideoOnPaused
      disablePictureInPicture={false}
      shouldSuppressPlaybackInterruptedErrors={false}

    />
  )
}
