import React from "react";
import classnames from "classnames";

import { PageTitlePropsType } from "../../interfaces";
import styles from "./PageTitle.module.scss";
import { Link } from "react-router-dom";


const PageTitle = ({
  name,
  title,
  subTitle,
  children,
  centered,
  className,
  url,
  isLink,
  type,
  margin = true,
  full = true,
  reponsiveCol = false,
  render,
  ...rest
}: PageTitlePropsType) => {
  const titleClasses = classnames(styles.AppHeader_header, className, `${name ? name : ""}`, {
    [styles.AppHeader_marginBottom]: margin,
    [styles.AppHeader_spreadForApp]: full,
    [styles.AppHeader_isLink]: isLink
  });

  const titleHead = classnames({
    [styles.AppHeader_centered]: centered,
  });

  if (type === "border") {
    return (
      <div className={titleClasses} {...rest}>
        <div className="AppHeader has-spacing has-border">
          <h1 className={styles.AppHeader_title}>
            {isLink ? (
              <Link to={url ? url : ""}>
                <span className="AppHeader__titleContent">{title}</span>
              </Link>
            ) :
              <span className="AppHeader__titleContent">{title}</span>
            }
          </h1>
          <div className="AppHeader__actions">{children}</div>
        </div>
      </div>
    );
  }

  return (
    <div className={titleClasses} {...rest} data-testid="pagetitle-id">
      <div className={classnames(styles.AppHeader_inner, {
        [styles.AppHeader_Responsive]: reponsiveCol
      })} id="app-inner">
        <div className={styles.AppHeader_title}>
          <h1 className={classnames(styles.AppHeader_Title_Typography, titleHead, "mb-0")}>
            {isLink ? (
              <Link to={url ? url : ""}>
                {title}
              </Link>
            ) :
              <>
                {title}
              </>
            }
          </h1>
          <span className={styles.AppHeader_titleSub}>{subTitle}</span>
        </div>
        <div className={styles.AppHeader_actions}>{children}</div>
      </div>
      {render && render()}
    </div>
  );
};

export default PageTitle;


