import React from 'react'
import styled from 'styled-components';
import { CardBox, Loader, Typography } from '@ekenta/components';
import NoData from "@ekenta/assets/images/no-data.gif";


const EmptyCard = ({ title, subTitle, isLoading }: { title: string; subTitle: string; isLoading?: boolean }) => {
    return (
        <CardBoxStyle image={NoData}>
            <CardBox className="CardBox">
                {isLoading ? <Loader size='large' /> : (
                    <>
                        <div className="CardBox_Gif File_div">
                        </div>
                        <div>
                            <Typography
                                as="h3"
                                color="default"
                                size="medium">
                                {title}
                            </Typography>
                            <Typography
                                as="p"
                                color="default"
                                size="small">
                                {subTitle}
                            </Typography>
                        </div>
                    </>
                )}
            </CardBox >
        </CardBoxStyle>
    )
}

const CardBoxStyle = styled.div<{ image: string }>`
    .CardBox{
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
        width: 100% !important;
        height: 60vh !important;
        flex-shrink: 0;
        border-radius: 13px !important;
       // padding: 84.5px 499px 85.18px 420px !important;
        box-shadow: 0px 4px 60px 0px rgba(92, 107, 173, 0.03) !important;
        font-family: var(--eke-body-font-family);

        .CardBox_Gif{
            width: 224px;
            height: 152.32px;
            background-image: url(${(props) => props.image});
            background-size: cover;
            background-repeat: no-repeat;
        }

        h3{
            color: var(--greyscale-900, #212121) !important;
            font-size: 24px !important;
            font-weight: var(--eke-font-weight-bold) !important;
            line-height: 120%; /* 28.8px */
        }

        p{
            color: var(--greyscale-700, #616161);
            font-size: 16px !important;
            font-weight: var(--eke-font-weight-semibold) !important;
            line-height: 140%; /* 22.4px */
            letter-spacing: 0.2px;
            margin: 0;
        }
    }

`;

export default EmptyCard;