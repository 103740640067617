/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { getIn, useFormikContext } from 'formik';
import styled from 'styled-components';
import { VscArrowSmallRight } from 'react-icons/vsc';
import { Button, ButtonGroup, Checkbox, RefreshLink } from '@ekenta/components';
import { useAppSelector } from '@ekenta/redux/store/hook';
import { MyFormValues } from '../OnboardingForm/types';
import { onboardingPolicyUrl, privacyUrl, termsAndConditionsUrl } from '@ekenta/routes/constant';

export default function Summary({ isLoading, prevPage }: { isLoading?: boolean; prevPage: () => void; }) {

  const { values, errors, touched, setFieldValue, handleBlur } = useFormikContext<MyFormValues>();

  const covertAccountNumber = (acc_number: string) => {
    return acc_number ? acc_number.toString().replace(/\d(?=\d{4})/g, "*") : '......';
  }

  const { ek_isAuth, account, token } = useAppSelector(state => state.auth.authentication);



  return (
    <div className="seller_account_details_form_col">
      <div className="_top_header">
        <h5>Summary</h5>
      </div>
      <ContentStyle>
        <div className="mt-4">
          <div className="group__wrapp_ mb-2">
            <SummaryTitle>
              <h4>Personal Details</h4>
            </SummaryTitle>

            <div className="list__table_">
              <dl className="dl__tb">
                <dt>First Name :</dt>
                <dd>{values.personal_detail?.firstname != '' ? values.personal_detail.firstname : '......'}</dd>
              </dl>
              <dl className="dl__tb">
                <dt>Last Name :</dt>
                <dd>{values.personal_detail?.lastname != '' ? values.personal_detail.lastname : '......'}</dd>
              </dl>
              <dl className="dl__tb">
                <dt>User Name :</dt>
                <dd>{values.personal_detail?.username != '' ? values.personal_detail.username : '......'}</dd>
              </dl>

              {!ek_isAuth && !token && !account.user ? (
                <dl className="dl__tb">
                  <dt>Phone Number :</dt>
                  <dd>{values.personal_detail?.phonenumber != '' ? values.personal_detail.phonenumber : '......'}</dd>
                </dl>
              ) : null}

              <dl className="dl__tb">
                <dt>Email Address :</dt>
                <dd>{values.personal_detail?.email != '' ? values.personal_detail.email : '......'}</dd>
              </dl>
              {/* <dl className="dl__tb">
                <dt>Password :</dt>
                <dd>{values.personal_detail?.password != '' ? '************' : '......'}</dd>
              </dl> */}
              <dl className="dl__tb">
                <dt>Referred by :</dt>
                <dd>{values.personal_detail?.referedby != '' ? values.personal_detail.referedby : '......'}</dd>
              </dl>
            </div>
          </div>


          <div className="group__wrapp_">
            <SummaryTitle>
              <h4>Business Details</h4>
            </SummaryTitle>

            <div className="list__table_">
              <dl className="dl__tb">
                <dt>Seller Name :</dt>
                <dd>{values.business_detail?.businessname != '' ? values.business_detail?.businessname : '......'}</dd>
              </dl>
              <dl className="dl__tb">
                <dt>Address :</dt>
                <dd>{values.business_detail?.address != '' ? values.business_detail?.address : '......'}</dd>
              </dl>
              <dl className="dl__tb">
                <dt>State:</dt>
                <dd>{values.business_detail?.state.name != '' ? values.business_detail?.state.name : '......'}</dd>
              </dl>
              <dl className="dl__tb">
                <dt>City/Town :</dt>
                <dd>{values.business_detail?.city_or_town.name != '' ? values.business_detail?.city_or_town.name : '......'}</dd>
              </dl>
              <dl className="dl__tb">
                <dt>Country :</dt>
                <dd>{values.business_detail?.country != '' ? values.business_detail?.country : '......'}</dd>
              </dl>
              <dl className="dl__tb">
                <dt>Business Phone Number :</dt>
                <dd>{values.business_detail?.business_phone_number != '' ? values.business_detail?.business_phone_number : '.....'}</dd>
              </dl>
              <dl className="dl__tb">
                <dt>Business Registration Number:</dt>
                <dd>{values.business_detail?.business_registration_number != '' ? values.business_detail?.business_registration_number : '......'}</dd>

              </dl>

            </div>
          </div>
          <div className="group__wrapp_">
            <SummaryTitle>
              <h4>Bank Details</h4>
            </SummaryTitle>
            <div className="list__table_">
              <dl className="dl__tb">
                <dt>Bank :</dt>
                <dd>{values.bank_detail?.bank != '' ? values.bank_detail?.bank : '......'}</dd>
              </dl>
              <dl className="dl__tb">
                <dt>Account Name :</dt>
                <dd>{values.bank_detail?.account_name != '' ? values.bank_detail?.account_name : '......'}</dd>
              </dl>
              <dl className="dl__tb">
                <dt>Account Number:</dt>
                <dd>{covertAccountNumber(values.bank_detail?.account_number)}</dd>
              </dl>
            </div>
          </div>
        </div>
        <div className="Terms_PolicyStyle">
          <Checkbox
            id="acceptTermsAndConditions"
            required
            name="acceptTermsAndConditions"
            value={values.acceptTermsAndConditions}
            label={
              <>
                <span>I agree to the </span>
                <RefreshLink to={privacyUrl} target="_blank" className="me-1">Privacy policy & </RefreshLink>
                <RefreshLink to={termsAndConditionsUrl} target="_blank">Terms and Conditions</RefreshLink>
              </>
            }
            onBlur={handleBlur}
            onChange={(e: any) => {
              setFieldValue(
                "acceptTermsAndConditions",
                e.target.checked
              );
            }}
          />
          {touched.acceptTermsAndConditions && errors.acceptTermsAndConditions ? (
            <div className="invalid-fbk">
              {getIn(errors, "acceptTermsAndConditions")}
            </div>
          ) : null}
        </div>


        <div>
          <Checkbox
            id="acceptOnboardingPolicy"
            name="acceptOnboardingPolicy"
            required
            value={values.acceptOnboardingPolicy}
            label={
              <>
                <span>I agree to the </span>
                <RefreshLink to={onboardingPolicyUrl} target="_blank" className="me-1">Qlason onboarding policy</RefreshLink>
              </>
            }
            onBlur={handleBlur}
            onChange={(e: any) => {
              setFieldValue(
                "acceptOnboardingPolicy",
                e.target.checked
              );
            }}
          />
          {touched.acceptOnboardingPolicy && errors.acceptOnboardingPolicy ? (
            <div className="invalid-fbk">
              {getIn(errors, "acceptOnboardingPolicy")}
            </div>
          ) : null}
        </div>


        {/* <div className="_stepper_trigger_col">
          <Button className="_button_A_ me-1" onClick={prevPage}>
            <VscArrowSmallLeft />
            <span>Back</span>
          </Button>
          <Button className={`_button_A_`}
            type='submit' disabled={!values.acceptTermsAndConditions || isLoading}>
            {isLoading ? (<div className="d-flex align-items-center justify-content-center">
              <Spinner size="sm" animation="border" variant="white" />
              <span className="ms-1 text-sm"><small>Loading..</small></span>
            </div>) :
              (
                <>
                  <span>Start Selling</span>
                  <VscArrowSmallRight />
                </>
              )
            }
          </Button>
        </div> */}
        <ButtonGroup>
          {/* <Button
            name="Back"
            shadow={false}
            bgVariant="secondary"
            width="w-100"
            textVariant="light"
            prefixIcon={<VscArrowSmallLeft />}
            type="button"
            onClick={prevPage}
          /> */}

          <Button
            name="Start Selling"
            textVariant="light"
            width="w-100"
            className="PageStep__Btn"
            disabled={
              !values.acceptTermsAndConditions ||
              !values.acceptOnboardingPolicy ||
              isLoading
            }
            isLoadingText="Start Selling"
            isLoading={isLoading}
            shadow={false}
            suffixIcon={<VscArrowSmallRight />}
            type="submit"
          />
        </ButtonGroup>
      </ContentStyle>
    </div>
  )
}


const ContentStyle = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

  .Terms_PolicyStyle{
    margin-bottom: 0.7em
  }


  .list__table_{
        padding-top: 0.3rem;
        padding-bottom: 0.9rem;

        .dl__tb{ 
            display: flex;
            align-items: center;
            justify-content: space-between;   
            margin: 0 !important;
            
            dt{
                color: var(--greyscale-700, #616161);
                font-family: var(--eke-body-font-family);
                font-weight: var(--eke-font-weight-medium);
                font-size: 15.5px;
                line-height: 155%; /* 22.4px */
                letter-spacing: 0.2px;
            }
            
            dd{
              color: var(--greyscale-700, #616161);
              text-align: right;
              font-family: var(--eke-body-font-family);
              font-weight: var(--eke-font-weight-normal);
              font-size: 15.5px;
              line-height: 155%; /* 22.4px */
              letter-spacing: 0.2px;
              text-transform: capitalize;
                
            }
        }
    }
`;


const SummaryTitle = styled.div`
  display: block;
  width: 100%;
  padding-bottom: 10px;
  border-bottom: 1px solid #eee;
  margin-bottom: 5px;

  h4{
    color: var(--eke-color-primary);
    font-family: var(--eke-body-font-family);
    font-weight: var(--eke-font-weight-bold);
    font-size: 16px;
    line-height: 140%; /* 22.4px */
    letter-spacing: 0.2px;
    margin: 0;
  }

`;