import React, { Fragment, useEffect } from "react"
import { Col, Container, Form, Row } from "react-bootstrap";
import * as queryString from "query-string";
import ProfileSideBar from "../component/AccountSideBar/AccountSideBar"
import BreadCrumb from "../component/BreadCrumb";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { pendingReviewsPathUrl } from "@ekenta/routes/constant";
import { useFormik, FormikProvider } from "formik";
import RatingAndReviewFormValidation from "@ekenta/validations/schemas/rating-review.schema";
import { useRateProductItemMutation } from "@ekenta/redux/store/services/accountApi";
import { Button, ButtonGroup, CustomAlert, Field, HelmetHeader, Loader, PageTitle, Typography } from "@ekenta/components";
import { withDefaultLayout } from '@ekenta/hoc';
import { useAppSelector } from "@ekenta/redux/store/hook";
import { VscArrowSmallLeft } from "react-icons/vsc";
import RatingItem from "./RatingItem/RatingItem";

import "./Review.scss";
import { useFetchSingleProductByIdQuery } from "@ekenta/redux/store/services/productsApi";

const RatingAndReviews = () => {
    const params = useParams();
    const query = queryString.parse(window.location.search);

    const { token } = useAppSelector(state => state.auth.authentication);
    const navigate = useNavigate();

    const [rateProductItem, getRating] = useRateProductItemMutation();
    const productId = params.id;
    const getProduct = useFetchSingleProductByIdQuery(productId, { skip: !productId });


    const formik = useFormik({
        initialValues: {
            name: "",
            title: "",
            details: "",
            rating: 0
        },
        validationSchema: RatingAndReviewFormValidation(),
        onSubmit: values => {
            console.log("submit");

            if (getProduct?.data) {
                const data = {
                    product_id: getProduct?.data?.product.id,
                    review_title: values.title,
                    review_details: values.details,
                    stars: values.rating
                };
                rateProductItem({ data, token });
            } else {
                toast.error("Something went wrong, Try Refresh", {
                    toastId: "id-product-id-toast-",
                });
            }

        }
    });

    useEffect(() => {
        if (getRating?.isSuccess) {
            toast.success(`${getRating?.data.message}`, {
                toastId: "rating-success-id-toast",
            });
            navigate(-1);
            window.scrollTo(0, 0);
        }

        if (getRating?.isError
            && getRating?.error.status === "FETCH_ERROR") {
            toast.error("Something went wrong", {
                toastId: "error-ratings-id-toast-",
            });
        }

        if (getRating?.isError
            && getRating?.error.status === 401) {
            toast.error(`${getRating?.error.message}`, {
                toastId: "error-rating-id-toast-error",
            });
        }

    }, [
        getRating?.isSuccess,
        getRating?.isError,
        getRating?.error
    ]);

    return (
        <Fragment>
            <HelmetHeader
                title="Rating and Reviews"
                description="Qlason Pending Review Page"
            />
            <Container className="account-user-container">
                <><BreadCrumb activePage="Order History" /></>

                <Row className="account-pro-row g-2">
                    <ProfileSideBar isLoading={null} />
                    <Col className="account-profile-col-right">
                        <PageTitle
                            title="Rate &amp; Review"
                            margin={false}
                            subTitle={
                                <span
                                    className="cursor-pointer d-flex w-lg-50 align-items-center 
                                        text-muted"
                                    onClick={() => navigate(pendingReviewsPathUrl)}>
                                    <VscArrowSmallLeft />
                                    Back to Pending Review
                                </span>
                            }
                        />


                        <div className="p-2">
                            <Typography
                                as="h4"
                                size="base"
                                margin="bottom"
                                transform="uppercase"
                                uiText>
                                Select the stars to rate this product
                            </Typography>
                            <FormikProvider value={formik}>
                                <Form onSubmit={formik.handleSubmit}>
                                    <div className="col-lg-12 mt-3">
                                        <div className="">


                                            {getProduct?.isLoading &&
                                                <Loader
                                                    isCenter
                                                    color="black"
                                                    padding
                                                    size="large"
                                                />
                                            }
                                            {getProduct?.data ? (
                                                <RatingItem
                                                    images={
                                                        getProduct?.data?.product.images
                                                            ? getProduct?.data?.product.images
                                                            : []}
                                                    title={getProduct?.data?.product.name}
                                                    orderNo={query.order}
                                                    rating={formik.values.rating}
                                                    errors={formik.errors}
                                                    setFieldValue={formik.setFieldValue}
                                                />
                                            ) : null}

                                            {getProduct?.isError ? (
                                                <CustomAlert
                                                    variant="danger"
                                                    show={true}
                                                    message={"Something went wrong, Try reloading"}
                                                />
                                            ) : null}
                                        </div>
                                        <div className="RatingReview__Section">
                                            <Typography
                                                as="h4"
                                                size="base"
                                                margin="bottom"
                                                transform="uppercase"
                                                uiText>
                                                Leave a review
                                            </Typography>
                                        </div>
                                        <div className="row RatingReview__Fields">
                                            <Col sm={12}>
                                                <Field
                                                    id="title"
                                                    name="title"
                                                    label="Review Title"
                                                    disabled={
                                                        getRating?.isLoading ||
                                                        getProduct?.isLoading ||
                                                        getProduct?.isError
                                                    }
                                                    type="text"
                                                    required
                                                    size="large"
                                                    childOutsideLabel={false}
                                                    placeholder="eg. I love it!/ I dislike it!"
                                                    fieldClassName="FieldControl__Input"
                                                    autoComplete="off"
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    value={formik.values.title}
                                                    isError={formik.touched.title && formik.errors.title ? true : false}
                                                    error={formik.errors.title}
                                                />
                                            </Col>
                                            <Col sm={12}>
                                                <div className="MarginTop_Bar">
                                                    <Field
                                                        id="details"
                                                        name="details"
                                                        label="Review Details"
                                                        disabled={
                                                            getRating?.isLoading ||
                                                            getProduct?.isLoading ||
                                                            getProduct?.isError
                                                        }
                                                        type="textarea"
                                                        size="large"
                                                        required
                                                        childOutsideLabel={false}
                                                        placeholder="Tell us more about your rating"
                                                        fieldClassName={""}
                                                        autoComplete="off"
                                                        onChange={formik.handleChange}
                                                        onBlur={formik.handleBlur}
                                                        value={formik.values.details}
                                                        isError={formik.touched.details && formik.errors.details ? true : false}
                                                        error={formik.errors.details}
                                                    />
                                                </div>
                                            </Col>
                                            <ButtonGroup pos="right">
                                                <Button
                                                    as="text"
                                                    name="Cancel"
                                                    shadow={false}
                                                    type="button"
                                                    disabled={
                                                        getRating?.isLoading ||
                                                        getProduct?.isLoading
                                                    }
                                                    className="Cancel__Button"
                                                    onClick={() => {
                                                        navigate(-1);
                                                        window.scrollTo(0, 0);
                                                    }}
                                                />
                                                <Button
                                                    name="Save"
                                                    bgVariant="primary"
                                                    textVariant="light"
                                                    className="py-2"
                                                    isLoadingText="Save"
                                                    disabled={
                                                        getRating?.isLoading ||
                                                        getProduct?.isError ||
                                                        getProduct?.isError
                                                    }
                                                    isLoading={getRating?.isLoading}
                                                    shadow={false}
                                                    type="submit"

                                                />
                                            </ButtonGroup>
                                        </div>

                                    </div>
                                </Form>
                            </FormikProvider>

                        </div>

                    </Col>
                </Row>
            </Container>
        </Fragment>
    )
}

export default withDefaultLayout(RatingAndReviews);


