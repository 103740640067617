import * as Yup from 'yup';

const loginFormValidation = () => Yup.object({
    login: Yup.string().when("isEmailValue", {
                is: 'true',
                then: Yup.string()
                    .email("Invalid email address")
                    .required("Email field is required"),
                otherwise: Yup.string()
                    .required("Username field is required")
                    .min(2, 'Username must be at least 2 char')
    }),
    password: Yup.string()
                  .required('Password field is required')
})

export default loginFormValidation;