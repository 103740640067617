/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import {
  FiBarChart2,
  FiBell,
  FiCalendar,
  FiGift,
  FiGitMerge,
  FiLogOut,
  FiUser,
  FiVideo,
} from 'react-icons/fi'
import { AnimatePresence, motion } from 'framer-motion'
import styled from 'styled-components'
import Scrollbars from 'react-custom-scrollbars'
import { FaUser, FaUserCheck } from 'react-icons/fa'
import { VscClose } from 'react-icons/vsc'
import { toast } from 'react-toastify'

import { Menu } from '../inner/Menu'
import { MenuItem } from '../inner/MenuItem'

import {
  adminAccountingOrders,
  adminAccountingSellers,
  adminAllOrders,
  AdminDashboardCollectionPathUrl,
  AdminDashboardSubCollectionCategoriesPathUrl,
  AdminDashboardSubCollectionPathUrl,
  AdminDiscountPathUrl,
  adminGetAllUsersUrl,
  adminGetAllVerifiedUsersUrl,
  adminManageBanner,
  adminManageLogo,
  adminPayoutRequest,
  AdminProductsPathUrl,
  adminReturnRequest,
  // AdminSellerProfile,
  // AdminSellerProfileId,
  AdminUserMangement,
  adminVideos,
  DashboardCollectionPathUrl,
  DashboardEventsPathUrl,
  DashboardNotificationsPathUrl,
  DashboardOffersPathUrl,
  DashboardOrdersPathUrl,
  DashboardPathUrl,
  DashboardProductsPathUrl,
  DashboardReturnRequestPathUrl,
  //DashboardSellerAnalytics,
  DashboardSellerLiveCommerceBaseUrl,
  DashboardSellerLiveCommercePathUrl,
  DashboardSellerMerchantDetailsUrl,
  DashboardSellers,
  homePathUrl,
  loginPathUrl,
  adminReviewsReportUrl,
  awaitingSellers,
  activeSellersPathUrl,
  allSellersPathUrl,
  DashboardAddProductPathUrl,
  DashboardSellerChatPathUrl,
  AdminChatPathUrl,
  AdminVoucherPathUrl,
} from '@ekenta/routes/constant'
// import logo from "@ekenta/assets/images/ekentanew.webp";
//import logo from "@ekenta/assets/images/WebLogo.png";
import liveAD from '@ekenta/assets/images/live-streaming3.png'
import { useAppDispatch, useAppSelector } from '@ekenta/redux/store/hook'
import {
  checkIfAdmin,
  checkIfAdminOrSellerRoleExist,
  logoutHandler,
  useMatchPath,
} from '@ekenta/utils'
import { removeUser } from '@ekenta/redux/store/states/reducerSlice/authenticationSlice'
import { SubMenu } from '../inner/SubMenu'
import { googleLogout } from '@react-oauth/google'
import { removeLive } from '@ekenta/redux/store/states/reducerSlice/livecomSlice'
import { Logo } from '@ekenta/components'
import { ReactComponent as DashboardIcon } from '@ekenta/assets/icons/dashboard-icon.svg'
import { ReactComponent as ProductIcon } from '@ekenta/assets/icons/product-icon.svg'
import { ReactComponent as ActivityIcon } from '@ekenta/assets/icons/activity-icon.svg'
import { ReactComponent as UsersIcon } from '@ekenta/assets/icons/users-icon.svg'
import { ReactComponent as AccountingIcon } from '@ekenta/assets/icons/graph-icon.svg'
import { ReactComponent as SwapIcon } from '@ekenta/assets/icons/swap-icon.svg'
import { ReactComponent as BannerIcon } from '@ekenta/assets/icons/banner-icon.svg'
import { ChatIcon } from '@ekenta/components/icons'
import { emptyCart } from '@ekenta/redux/store/features/cart/cartSlice'

export const DashboardCustomSideBar = ({
  visibilty,
  setvisibilty,
}: {
  visibilty: boolean
  setvisibilty: any
}) => {
  const location = useLocation()
  const navigate = useNavigate()
  const dispatch = useAppDispatch()

  const { ek_isAuth, account, token } = useAppSelector(
    (state) => state.auth.authentication
  )
  const isAdmin = account.user ? checkIfAdmin(token, account.user.role) : null

  const logoutHandlerFunc = () => {
    logoutHandler(
      navigate,
      dispatch,
      googleLogout,
      loginPathUrl,
      removeUser,
      emptyCart,
      removeLive
    )
    toast.success(`You"ve successfully logout`, {
      toastId: 'logout-id-toast-success',
    })
  }

  const dashboardList = [
    `${awaitingSellers}`,
    `${DashboardSellers}`,
    `${activeSellersPathUrl}`,
  ]

  const accountingList = [
    `${AdminDiscountPathUrl}`,
    `${adminPayoutRequest}`,
    `${adminAccountingOrders}`,
    `${adminAccountingSellers}`,
    `${allSellersPathUrl}`,
  ]

  const salesList = [
    `${adminAllOrders}`,
    `${DashboardOrdersPathUrl}`,
    `${DashboardReturnRequestPathUrl}`,
    `${adminReturnRequest}`,
  ]

  return (
    <AnimatePresence>
      <Container
        as={motion.div}
        transition={{ type: 'spring', bounce: 0, duration: 0.1 }}
        className={`dashboard-custom-sidebar ${
          visibilty ? 'visble-sidebar' : 'not-visble-sidebar'
        }`}
      >
        <div>
          <BrandStyle>
            <Logo width="140px" className="mx-3 mt-1" type="default" />
            <button
              className="navbar-toggler d-lg-none p-0 me-2 border-0"
              type="button"
              aria-expanded="false"
              onClick={() => setvisibilty(false)}
            >
              <span className="navbar-mobile-icon">
                <VscClose className="text-white" fontSize={27} />
              </span>
            </button>
          </BrandStyle>
          <SwitchToBuyer className="switch-buyer">
            <MenuItem
              text="Switch to Buyer"
              icon={<SwapIcon />}
              active={false}
              className="buyer-mode"
              link={homePathUrl}
            />
          </SwitchToBuyer>
          <div className="dashboard-menu-links">
            <Menu className="topp-menu-list pb-0">
              <div className="menu-title">
                <h5>Menu</h5>
              </div>
              <Scrollbars
                className="t-menu-scroll"
                thumbMinSize={50}
                style={
                  ek_isAuth &&
                  checkIfAdminOrSellerRoleExist(token, account.user.role) &&
                  !isAdmin
                    ? { width: '100%', height: '151px' }
                    : { width: '100%', height: '400px', marginBottom: '40px' }
                }
              >
                <MenuItem
                  active={
                    isAdmin
                      ? dashboardList.includes(location.pathname)
                      : location.pathname == DashboardPathUrl
                  }
                  icon={<DashboardIcon className="fill-icon" />}
                  link={isAdmin ? DashboardSellers : DashboardPathUrl}
                  text="Dashboard"
                />

                <MenuItem
                  icon={<ProductIcon className="stroke-icon" />}
                  active={
                    isAdmin
                      ? location.pathname === AdminProductsPathUrl
                      : isAdmin
                      ? location.pathname === DashboardAddProductPathUrl
                      : location.pathname === DashboardProductsPathUrl
                  }
                  link={
                    isAdmin ? AdminProductsPathUrl : DashboardProductsPathUrl
                  }
                  text="Products"
                />

                <MenuItem
                  icon={<ChatIcon className="stroke-icon" />}
                  active={
                    isAdmin
                      ? location.pathname === AdminChatPathUrl
                      : location.pathname === DashboardSellerChatPathUrl
                  }
                  link={isAdmin ? AdminChatPathUrl : DashboardSellerChatPathUrl}
                  text="Chats"
                />

                {isAdmin ? (
                  <MenuItem
                    icon={<FiGitMerge fontSize={17} />}
                    active={location.pathname == adminReviewsReportUrl}
                    link={adminReviewsReportUrl}
                    className="recent"
                    text="Review Report"
                  />
                ) : null}

                {isAdmin ? (
                  <MenuItem
                    active={location.pathname == AdminVoucherPathUrl}
                    icon={<FiCalendar fontSize={17} />}
                    link={AdminVoucherPathUrl}
                    text="Voucher"
                  />
                ) : null}

                {isAdmin ? (
                  <SubMenu
                    text="Collections"
                    icon={<FiBarChart2 fontSize={16} />}
                    active={false}
                  >
                    <MenuItem
                      icon={<FiGift fontSize={17} />}
                      active={location.pathname == DashboardCollectionPathUrl}
                      link={
                        isAdmin
                          ? AdminDashboardCollectionPathUrl
                          : DashboardCollectionPathUrl
                      }
                      text="All Collections"
                    />
                    <MenuItem
                      icon={<FiGift fontSize={17} />}
                      active={
                        location.pathname == AdminDashboardSubCollectionPathUrl
                      }
                      link={AdminDashboardSubCollectionPathUrl}
                      text="All Sub-Collections"
                    />
                    <MenuItem
                      icon={<FiGift fontSize={17} />}
                      active={
                        location.pathname ==
                        AdminDashboardSubCollectionCategoriesPathUrl
                      }
                      link={AdminDashboardSubCollectionCategoriesPathUrl}
                      text="All Sub-Collection Categories"
                    />
                  </SubMenu>
                ) : (
                  ''
                )}
                {isAdmin ? (
                  <>
                    <MenuItem
                      active={location.pathname == DashboardEventsPathUrl}
                      icon={<FiCalendar fontSize={17} />}
                      link={DashboardEventsPathUrl}
                      text="Events"
                    />
                    <MenuItem
                      active={location.pathname == DashboardOffersPathUrl}
                      icon={<FiGift fontSize={17} />}
                      link={DashboardOffersPathUrl}
                      text="Offers"
                    />
                    <MenuItem
                      active={
                        location.pathname == DashboardNotificationsPathUrl
                      }
                      icon={<FiBell fontSize={17} />}
                      link={DashboardNotificationsPathUrl}
                      text="Notifications"
                    />
                  </>
                ) : null}

                <SubMenu
                  text="Sales"
                  icon={<ActivityIcon className="stroke-icon" />}
                  active={
                    isAdmin ? salesList.includes(location.pathname) : false
                  }
                >
                  <MenuItem
                    icon={<FiGift fontSize={17} />}
                    active={
                      location.pathname == adminAllOrders ||
                      location.pathname == DashboardOrdersPathUrl
                    }
                    link={isAdmin ? adminAllOrders : DashboardOrdersPathUrl}
                    text="Orders"
                  />
                  <MenuItem
                    icon={<FiGift fontSize={17} />}
                    active={
                      location.pathname == DashboardReturnRequestPathUrl ||
                      location.pathname == adminReturnRequest
                    }
                    link={
                      isAdmin
                        ? adminReturnRequest
                        : DashboardReturnRequestPathUrl
                    }
                    text="Returns Request"
                  />
                </SubMenu>
                {isAdmin ? (
                  <>
                    <SubMenu
                      text="Accounting"
                      icon={<AccountingIcon />}
                      active={
                        isAdmin
                          ? accountingList.includes(location.pathname)
                          : false
                      }
                    >
                      <MenuItem
                        icon={<FiGift fontSize={17} />}
                        active={location.pathname == AdminDiscountPathUrl}
                        link={AdminDiscountPathUrl}
                        text="Discount"
                      />
                      <MenuItem
                        icon={<UsersIcon />}
                        active={location.pathname == allSellersPathUrl}
                        link={allSellersPathUrl}
                        text="All Sellers"
                      />
                      <MenuItem
                        icon={<FiGift fontSize={17} />}
                        active={location.pathname == adminPayoutRequest}
                        link={adminPayoutRequest}
                        text="Payout Requests"
                      />
                    </SubMenu>

                    <SubMenu
                      text="Manage Users"
                      icon={<UsersIcon />}
                      active={false}
                    >
                      <MenuItem
                        icon={<FaUser fontSize={17} />}
                        active={location.pathname == adminGetAllUsersUrl}
                        link={AdminUserMangement}
                        text="User Management"
                      />
                      <MenuItem
                        icon={<FaUser fontSize={17} />}
                        active={location.pathname == adminGetAllUsersUrl}
                        link={adminGetAllUsersUrl}
                        text="All Users"
                      />
                      <MenuItem
                        icon={<FaUserCheck fontSize={17} />}
                        active={
                          location.pathname == adminGetAllVerifiedUsersUrl
                        }
                        link={adminGetAllVerifiedUsersUrl}
                        text="All Verified Users"
                      />
                    </SubMenu>

                    <MenuItem
                      text="Manage Logo"
                      icon={<FiGift fontSize={16} />}
                      active={location.pathname == adminManageLogo}
                      link={adminManageLogo}
                    />
                    <MenuItem
                      text="Manage Banner"
                      icon={<BannerIcon className="stroke-icon" />}
                      active={location.pathname == adminManageBanner}
                      link={adminManageBanner}
                    />

                    <MenuItem
                      text="Manage Video"
                      icon={<FiVideo fontSize={16} />}
                      active={location.pathname == adminVideos}
                      link={adminVideos}
                    />
                  </>
                ) : (
                  ''
                )}
              </Scrollbars>
            </Menu>

            <Menu className="to-menu-list mt-2">
              <div className="menu-title mb-2">
                <h5>General</h5>
              </div>
              {!isAdmin && (
                <MenuItem
                  text="Profile"
                  icon={<FiUser fontSize={16} />}
                  active={
                    location.pathname == DashboardSellerMerchantDetailsUrl
                  }
                  link={DashboardSellerMerchantDetailsUrl}
                />
              )}

              <MenuItem
                text="Logout"
                icon={<FiLogOut />}
                active={false}
                link=""
                onClick={logoutHandlerFunc}
              />
            </Menu>
          </div>
        </div>

        {location.pathname !==
        useMatchPath(DashboardSellerLiveCommercePathUrl, location.pathname) ? (
          <>
            {ek_isAuth &&
            checkIfAdminOrSellerRoleExist(token, account.user.role) &&
            !isAdmin ? (
              <div className="dashboard-live-ad">
                <div className="ek_dashboard_img">
                  <img src={liveAD} alt="QlasonSeller Livestream" />
                </div>
                <h3>Qlason Live</h3>
                <h5>Start selling in real-time with our Livestream feature</h5>
                <Link
                  to={`${DashboardSellerLiveCommerceBaseUrl}/view-${account.user.id}?type=isSellerLive`}
                >
                  <button>Go Live</button>
                </Link>
              </div>
            ) : null}
          </>
        ) : null}
      </Container>

      {visibilty && (
        <OutsideDiv
          initial={{ opacity: 0 }}
          animate={{
            opacity: 1,
          }}
          exit={{
            opacity: 0,
          }}
          transition={{ type: 'spring', bounce: 0, duration: 0.2 }}
          onClick={() => setvisibilty(false)}
        />
      )}
    </AnimatePresence>
  )
}

const Container = styled.div`
  position: absolute;
  //background-color: #1E293B !important;
  background: #fffefe !important;
  width: 250px;
  left: 0;
  top: 0;
  flex-direction: column;
  justify-content: space-between;
  transition: 0.3s all ease-in-out;
  z-index: 999;
  overflow-y: auto;
  overflow-x: hidden;
  height: 100vh;
  box-shadow: 0px 4px 60px 0px rgba(92, 107, 173, 0.03);
  border-right: 1px solid #eee;

  .menu-title {
    padding-left: 30.55px !important;
    margin-bottom: 0.2em;

    h5 {
      font-size: 13px;
      padding-bottom: 2px;
      color: var(--eke-color-primary);
      text-transform: uppercase;
      letter-spacing: 2px;
      font-weight: var(--eke-font-weight-extrabold);
      font-family: var(--eke-body-font-family);
      margin: 0;
    }
  }

  .sub-menu-ul {
    background-color: var(--background-blue, #f3f6f9) !important;
    padding-top: 2px !important;
    padding-bottom: 2px !important;
    margin-top: 2px !important;
    margin-bottom: 2px !important;
    transition: 0.3s all ease-in-out;
    overflow: hidden;
  }

  @media (min-width: 1200px) {
    position: fixed;
    display: flex !important;
    width: 15rem;
    height: 100vh;
  }
`

const BrandStyle = styled.div`
  padding-top: 0.7rem;
  padding-bottom: 0.85rem;
  border-bottom: 1px solid #eee;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.07);
`

const SwitchToBuyer = styled.div`
  width: 100%;
  background: rgba(255, 255, 250, 0.1);
  padding-top: 0.6em;
  padding-bottom: 0.2em;
  border-bottom: 1px solid #eee;

  li {
    //margin: 0 1rem 0.3rem;
    list-style-type: none;
    a {
      display: flex;
      align-items: center;
      text-decoration: none;
      font-size: 14px;
      padding: 0.7rem 0.999rem;
      color: var(--greyscale-700, #000);
      font-weight: var(--eke-font-weight-medium) !important;
      font-family: var(--eke-body-font-family) !important;
    }
  }
`

const OutsideDiv = styled(motion.div)`
  display: flex;
  position: absolute;
  top: 0%;
  right: 0%;
  padding-left: 1.25rem;
  padding-right: 1.25rem;
  background-color: rgba(255, 255, 255, 0.5);
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  z-index: 99;
`
