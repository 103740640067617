import { api } from '@ekenta/app/api/apiSlice'
import { AllVoucherResponseType, createVoucherPayloadType } from './voucherDataType'

export const voucherApiSlice = api.injectEndpoints({
  endpoints: (builder) => ({
    getAllVouchers: builder.query<AllVoucherResponseType, void>({
      query: () => ({
        url: `/api/get-all-vouchers`,
        method: 'GET',
      }),
    }),
     getAllAdminVouchers: builder.query<AllVoucherResponseType, void>({
      query: () => ({
        url: `/api/get-admin-vouchers`,
        method: 'GET',
       }),
    }),
    createVoucher: builder.mutation<void,createVoucherPayloadType>({
      query: (data) => ({
        url: `/api/create-voucher`,
        method: 'POST',
        body: data,
      }),
    }),
  }),
})
export const { useGetAllVouchersQuery,useGetAllAdminVouchersQuery,useCreateVoucherMutation } = voucherApiSlice
