/* eslint-disable @typescript-eslint/no-explicit-any */
import { Loader, Radio } from "@ekenta/components";
import React from "react";
import { ListGroup } from "react-bootstrap";
import NumberFormat from "react-number-format";

export function DeliveryChannelItem(props: {
  logistics: string;
  deliveryPrice: number | null;
  companyName: string;
  values: any;
  handleBlur: any;
  deliveryEnums: any
  setFieldValue: any;
  touched: any;
  errors: any;
  isLoadingPrice: boolean;
  id: string;
}) {
  return (
    <ListGroup.Item className="px-0 py-0 mb-2">
      <div className="delivery__selection">
        <div style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
        >
          <div className="d__checkbox_">
            <Radio
              id={props.id}
              name="logistics"
              className="RadioCheck"
              variant="success"
              label={props.companyName}
              disabled={props.isLoadingPrice}
              checked={props.values.logistics === props.logistics}
              onBlur={props.handleBlur}
              onChange={() => {
                props.setFieldValue("deliveryCharge", props.deliveryPrice);
                props.setFieldValue("logistics", props.logistics);
                props.setFieldValue("deliveryDate", props.deliveryEnums.DELIVERY.DATE);
              }}
            />
            {/* <Form.Check
              type="radio"
              name="logistics"
              id={props.id}
              disabled={props.isLoadingPrice}
              label={props.companyName}
              checked={props.values.logistics === props.logistics}
              onBlur={props.handleBlur}
              onChange={(e: any) => {
                props.setFieldValue("deliveryCharge", props.deliveryPrice);
                props.setFieldValue("logistics", props.logistics);
                props.setFieldValue("deliveryDate", props.deliveryEnums.DELIVERY.DATE);
              }}
            /> */}
          </div>
          <div
            className="listItem__price"
            style={{ minWidth: "13.55%" }}
          >
            {props.isLoadingPrice ? <Loader /> : (
              <>
                {!props.deliveryPrice ? "error" : (
                  <NumberFormat
                    thousandSeparator
                    displayType="text"
                    decimalSeparator="."
                    decimalScale={2}
                    prefix="₦ "
                    fixedDecimalScale
                    value={props.deliveryPrice}
                  />
                )}
              </>
            )}
          </div>
        </div>

      </div>
    </ListGroup.Item>
  );
}
