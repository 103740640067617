import React, { useContext } from 'react'
import SVG from "react-inlinesvg";
import WhatAppSvg from "@ekenta/assets/icons/whatsapp-icon.svg";
import MailSvg from "@ekenta/assets/icons/email-icon.svg";
import FeedbackSvg from "@ekenta/assets/icons/feedback-icon.svg";
import { ListPopItem } from '../style'
import { TriggerHelpCanvasContext } from '@ekenta/context/TriggerHelpCanvasContext';

const HelpList = () => {
    const { handleHelpShowCanvas } = useContext(TriggerHelpCanvasContext);

    return (
        <ListPopItem stroke={false}>
            <li>
                <a href="https://wa.link/2473bs" target="_blank" rel="noreferrer">
                    <SVG src={WhatAppSvg} />
                    Whatsapp
                </a>
            </li>
            <li>
                <a href="mailto:support@dabsoluterobotics.com" rel="noreferrer">
                    <SVG src={MailSvg} />
                    Email Us
                </a>
            </li>
            <li>
                <a href="#" rel="noreferrer" onClick={() => handleHelpShowCanvas()}>
                    <SVG src={FeedbackSvg} />
                    Feedback
                </a>
            </li>
        </ListPopItem>
    )
}

export default HelpList