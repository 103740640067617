/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState } from "react";
import styled from "styled-components";
import * as queryString from "query-string";
import { Tab, Tabs } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { withDashboardLayout } from "@ekenta/hoc";
import { HelmetHeader, PageTitle } from "@ekenta/components";
import { VscArrowSmallLeft } from "react-icons/vsc";
import { DashboardSellers } from "@ekenta/routes/constant";
import StatusOrder from "./common/StatusOrder";

const AdminOrders = () => {
  const navigate = useNavigate();
  const query = queryString.parse(window.location.search);
  const { _status } = query;
  const [activeTab, setActiveTab] = useState<string | any>(_status || "PENDING");


  return (
    <>
      <HelmetHeader
        title="Orders"
        description="QlasonOrders Dashboard Page"
      />
      <div>
        <div className="mt-0">
          <div className="mx-1 mx-lg-0">
            <PageTitle
              title="Orders"
              subTitle={
                <span
                  className="cursor-pointer d-flex w-lg-50 align-items-center 
                  text-muted"
                  onClick={() => navigate(DashboardSellers)}>
                  <VscArrowSmallLeft />
                  Back to Dashboard
                </span>
              }
            />
          </div>
          <TabContainer className="tab__">
            <Tabs defaultActiveKey={activeTab} id="u__tab" className="mb-3"
              onSelect={(value) => { setActiveTab(value); }}>
              <Tab eventKey="PENDING" title="Pending">
                <StatusOrder status="PENDING" activetab={activeTab} />
              </Tab>
              <Tab eventKey="PROCESSING" title="Processing">
                <StatusOrder status="PROCESSING" activetab={activeTab} />
              </Tab>
              <Tab eventKey="COMPLETED" title="Completed">
                <StatusOrder status="COMPLETED" activetab={activeTab} />
              </Tab>
              <Tab eventKey="CANCELLED" title="Cancelled">
                <StatusOrder status="CANCELLED" activetab={activeTab} />
              </Tab>
            </Tabs>
          </TabContainer>
        </div>
      </div >
      <br />
      <br />
    </>
  );
}

const TabContainer = styled.div`
  position: relative;
  .nav-tabs{
    margin-bottom: 25px !important;
  }
`;

export default withDashboardLayout(AdminOrders);