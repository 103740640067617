import styled from 'styled-components'
import { device } from '@ekenta/utils/devices'

export const WrapContainer = styled.div`
  width: 20%;
  position: relative;
  height: auto;

  a {
    text-decoration: none !important;
  }
  /* @media ${device.md} {
    width: 22%;
  } */

  @media ${device.xl} {
    width: 18.5%;
  }
`

export const VoucherCardItem = styled.div`
  display: block;
  position: relative;
  //margin: 20px 30px;
  transition: transform 0.5s ease-in-out;
  font-family: var(--font-family);
  border: 1.6px solid rgba(248, 248, 248, 1);
  border-radius: 8px;
  font-size: 12px;
  //width: 18%;
  width: 100%;
  /* @media screen and (max-width: 950px) {
    max-width: 150px;
    font-size: 15px;
  } */
`
export const VStack = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`
export const Image = styled.div`
  position: relative;
  width: 100%;
  height: 200px;
  transition: all 0.4s ease-in-out;
  border-radius: 10px;
  background-color: rgb(245, 251, 255);
`

export const Contents = styled.div`
  position: relative;
  width: 100%;
  height: auto;
  padding: 15px 10px 10px 10px;
`

export const Title = styled.h1`
  width: 100% !important;
  display: block;
  display: -webkit-box;
  text-decoration: none;
  text-transform: capitalize;
  padding-bottom: 0.2rem;
  max-width: 100%;
  height: 30px; /* Fallback for non-webkit */
  margin-bottom: 0rem;
  line-height: 1.5;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 15px !important;
  font-family: var(--eke-body-font-family);
  color: var(--eke-color-greyscale);
  font-weight: var(--eke-font-weight-bold);
  line-height: 140%; /* 27.404px */
  letter-spacing: 0.241px;

  &:hover {
    color: var(--eke-body-text-primary-color) !important;
    text-decoration: none !important;
  }
`

export const TemplateWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 100%;
  height: 100%;

  img.voucher-gift-temp {
    width: 100%;
    height: auto;
  }

  span.voucher-gift-price {
    font-size: 18px;
    color: rgba(255, 255, 255, 0.8);
    position: absolute;
    top: 95px;
  }
`

export const WrapRatings = styled.div`
  display: flex;
  align-items: center;
  gap: 9.634px;
  align-self: stretch;
  padding: 7px 0;
`

export const FlexRates = styled.div`
  display: flex;
  align-items: center;
  gap: 9.787px;

  span {
    color: var(--greyscale-700, #616161) !important;
    font-family: var(--eke-body-font-family);
    font-size: 15.06px;
    font-weight: var(--eke-font-weight-medium);
    line-height: 140%; /* 23.604px */
    letter-spacing: 0.241px;
  }
`
export const Divider = styled.div`
  width: 1px;
  height: 13px;
  border-right: 1.5px solid #616161;
`
export const ConditionBadge = styled.div`
  span {
    display: flex;
    width: 50px !important;
    padding: 7.226px 12.043px;
    justify-content: center;
    align-items: center;
    color: var(--eke-color-secondary);
    font-family: var(--eke-body-font-family);
    font-size: 10px;
    font-weight: var(--eke-font-weight-semibold);
    letter-spacing: 0.2px;
    border-radius: 7.226px;
  }
  .span__new {
    background: var(--transparent-blue, #335ef714) !important;
  }

  .span__used {
    background: var(--transparent-blue, #335ef714) !important;
  }
`
export const PricePerKg = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 4px;
  span.main-price:nth-child(1) {
    margin-bottom: 0rem !important;
    margin-right: 0.5rem;
    font-family: var(--eke-body-font-family);
    color: var(--greyscale-900, #212121);
    font-weight: var(--eke-font-weight-bold);
    font-size: 18.486px !important;
    line-height: 120%; /* 24.583px */
  }
  span:nth-child(2) {
    font-size: 13.5px !important;
    color: var(--eke-body-text-secondary-color);
    opacity: 0.6;
    font-weight: normal !important;
    text-decoration: line-through;
    font-family: var(--eke-body-font-family);
    font-weight: (--eke-font-weight-light);
  }
`

export const ModalContent = styled.div`
  padding: 5px 20px;
  width: 100%;
  height: auto;
  border-top: 1px solid #eeeeee;

  .modal--body {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;
    padding-bottom: 2px;

    label {
      color: var(--greyscale-700, #616161) !important;
      font-family: var(--eke-body-font-family);
      font-weight: 500;
      font-size: 18px;
      line-height: 140%;
      letter-spacing: 0.2px;

      span {
        font-size: 14px;
        font-weight: var(--eke-font-weight-medium) !important;
      }

      svg {
        margin-top: 6px;
      }
    }

    .InputField {
      width: 100%;
    }
    input {
      width: 100%;
      border-radius: 8px !important;
      border: 1px solid var(--gray-300, #d0d5dd) !important;
      background: var(--eke-color-white) !important;
      box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05) !important;
      color: var(--gray-500, #667085);
      font-size: 14.5px !important;
      font-family: var(--eke-body-font-family);
      font-weight: var(--eke-font-weight-medium) !important;
      line-height: 140%;
      letter-spacing: 0.2px;
    }
    ul {
      width: 100%;
      list-style: none;
      padding-left: 0px;
      display: flex;
      align-items: flex-start;
      flex-direction: column;
      gap: 24px;

      li {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .feature-value {
          flex: 1;
          display: flex;
          align-items: flex-start;
          flex-direction: column;
          gap: 2px;

          h5 {
            color: var(--Greyscale-700, #616161);
            font-family: var(--eke-body-font-family);
            font-size: 18px;
            font-weight: 500;
            letter-spacing: 0.2px;
            margin: 0 !important;
            text-transform: capitalize !important;
          }

          p {
            margin: 0 !important;
            color: var(--Greyscale-800, #424242);
            font-size: 18px;
            font-weight: 700;
            line-height: 140%;
            letter-spacing: 0.2px;
          }
        }

        .feature-add-quantity {
          flex-shrink: 1;
        }
      }
    }

    .quantity-container {
      width: 100%;
      display: flex;
      align-items: center;
      flex-direction: row;
      justify-content: space-between;
      padding: 12px 0 0 0;

      label {
        color: var(--greyscale-700, #616161) !important;
        font-family: var(--eke-body-font-family);
        line-height: 140%;
        letter-spacing: 0.2px;
      }
      p {
        padding: 0 !important;
        font-weight: 700;
        font-size: 18px;
        color: #424242;
      }
      .quantity-counter {
        .counter-qty {
          align-items: center;
          justify-content: center;
          width: 120px;
          border-radius: 100px;
          background: var(--Background-Orange, #fff8ed) !important;
          transition: all 0.3s ease-out;
          padding: 4px 20px !important;

          .count-value {
            font-size: 16px !important;
            color: var(--eke-color-primary) !important;
          }

          button {
            width: 32px !important;
            height: 32px !important;
            background-color: transparent !important;
            border: 1px solid transparent;

            svg {
              width: 100%;
              height: 100%;
              stroke-width: 2px;
              stroke: #f8880a !important;
            }

            &:disabled {
              pointer-events: none !important;
              opacity: 0.2 !important;
            }
          }
        }
      }
    }
  }

  .modal--footer {
    width:100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    padding-bottom: 17px;

    button {
      width: 100% !important;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 36.128px;
      height: 50px !important;
      padding: 18px 16px 18px 16px !important;
      border: none !important;
      transition: all 0.3s ease-out;
      gap: 4px !important;

      span {
        font-size: 14px !important;
        font-family: var(--eke-body-font-family) !important;
        font-weight: var(--eke-font-weight-bold) !important;
        margin-top: 0px;
      }
    }

    .shopping-btn {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 50px !important;
      border-radius: 100px !important;
      background: var(--Primary-100, #ffe5c8) !important;
      box-shadow: 0px 20px 100px 0px rgba(4, 6, 15, 0.08) !important;
      color: var(--Primary-500, #f8880a) !important;
      font-size: 15px !important;
      font-weight: 700 !important;
      line-height: 140%;
      letter-spacing: 0.2px;
    }
/* 
    .viewCart--btn {
      display: flex;
      height: 50px !important;
      justify-content: center;
      align-items: center;
      border-radius: 100px;
      background: var(--Primary-500, #f8880a) !important;
      box-shadow: 4px 8px 24px 0px #efdcc7 !important;
      color: var(--Others-White, #fff) !important;
      font-weight: 700 !important;
      font-size: 15px !important;
      line-height: 140%;
      letter-spacing: 0.2px;
    } */
  }
`
