/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { Fragment, useEffect, useState } from 'react'
import SVG from 'react-inlinesvg'
import * as queryString from 'query-string'
import * as Yup from 'yup'
import { useGoogleLogin } from '@react-oauth/google'
import { toast } from 'react-toastify'
import { useNavigate } from 'react-router-dom'
import {
  FormSteps,
  RegisterAsideStyle,
  RegisterContainerStyle,
  RegisterMainContentStyle,
} from './styles'
import { withAuthenticationLayout } from '@ekenta/hoc'
import { Button, Field, HelmetHeader, RefreshLink } from '@ekenta/components'
import Logo from '@ekenta/components/Logo'
import GoogleSVG from '@ekenta/assets/icons/goole-icon.svg'
import { getIn, useFormik } from 'formik'
import registerFormValidation from '@ekenta/validations/schemas/account-register.schema'
import {
  DashboardPathUrl,
  DashboardSellers,
  emailVerifyPathUrl,
  homePathUrl,
  loginPathUrl,
} from '@ekenta/routes/constant'
import { useRegisterUserMutation } from '@ekenta/redux/store/services/authenticationApi'
import { useAppDispatch } from '@ekenta/redux/store/hook'
import { checkIfAdmin, checkIfAdminOrSellerRoleExist } from '@ekenta/utils'
import { setUser } from '@ekenta/redux/store/states/reducerSlice/authenticationSlice'
import axios from 'axios'
import { AuthFormGroupStyle } from '../common/auth-style'
import AsideSlider from '../Login/common/AsideSlider'
import {
  initializeFacebookPixel,
  trackFacebookEvent,
} from '@ekenta/utils/initializeFbPixel'
import { FACEBOOK_PIXEL_ID } from '@ekenta/constants'
import { Form } from 'react-bootstrap'

const validationPhoneSchema = Yup.object().shape({
  phonenumber: Yup.string()
    .min(14, 'Phone number must be 11 digits')
    .max(15, 'Phone number must be 11 digits')
    .required('Phone number is required'),
})

const Register = () => {
  const navigate = useNavigate()
  const queryParam = queryString.parse(window.location.search)
  const referralCode = queryParam ? queryParam.code : ''
  const dispatch = useAppDispatch()

  const [email, setEmail] = useState<string>()
  const [googleData, setGoogleData] = useState<any>(null)
  const [formStep, setFormStep] = useState<number>(0)
  const [registerUser, { isLoading, isError, error, isSuccess }] =
    useRegisterUserMutation()

  const [googleLoading, setGoogleLoading] = useState(false)
  const [registerGoogle, getResult] = useRegisterUserMutation()

  useEffect(() => {
    if (isError && error.status === 'FETCH_ERROR') {
      toast.error(`Something went wrong,Try again`, {
        toastId: 'error-status-register-id-toast-error',
      })
    }

    if (isError && error.status === 401) {
      mapErrors(error.data.errors)
    }

    if (isError && error.status === 400) {
      toast.error(`Something went wrong`, {
        toastId: 'error-400-register-id-toast-error',
      })
    }

    if (getResult?.isError && getResult?.error.status === 401) {
      setFormStep(0)
      mapErrors(getResult?.error.data.errors)
    }

    if (getResult?.isError && getResult?.error.status === 400) {
      toast.error(`Something went wrong`, {
        toastId: 'google-400-id-toast-error',
      })
    }
  }, [isError, error, getResult?.isError])

  function mapErrors(errors: string[]) {
    for (const [key, value] of Object.entries(errors)) {
      if (key === 'email') {
        toast.error(`${value[0]}`, {
          toastId: 'google-signup-email-toast-error',
        })
      } else if (key === 'password') {
        toast.error(`${value[0]}`, {
          toastId: 'google-signup-id-password-error',
        })
      } else if (key === 'username') {
        toast.error(`${value[0]}`, {
          toastId: 'google-signup-id-username-error',
        })
      }
    }
  }

  useEffect(() => {
    initializeFacebookPixel(FACEBOOK_PIXEL_ID)
  }, [FACEBOOK_PIXEL_ID, isSuccess, getResult?.isSuccess])

  const formik = useFormik({
    initialValues: {
      userName: '',
      firstname: '',
      lastname: '',
      email: '',
      password: '',
      confirmpassword: '',
      phoneNumber: '',
      acceptTerms: false,
      acceptNotification: false,
    },
    validationSchema: registerFormValidation(),
    onSubmit: async (values) => {
      setEmail(values.email)
      const objData = {
        username: values.userName.toLowerCase(),
        first_name: values.firstname,
        last_name: values.lastname,
        email: values.email.toLowerCase(),
        password: values.password,
        password_confirmation: values.confirmpassword,
        phone_number: values.phoneNumber,
        origin: 'web',
        referred_by: referralCode,
      }
      try {
        const response = await registerUser(objData).unwrap()
        if (response?.success) {
          navigate(emailVerifyPathUrl, {
            state: { email: values.email },
            replace: true,
          })
        }
      } catch (error) {
        console.error('Registration error')
      }
    },
  })

  const googlePhoneFormik = useFormik({
    initialValues: {
      phonenumber: '',
    },
    validationSchema: validationPhoneSchema,
    onSubmit: (values) => {
      if (!googleData) {
        console.log('no google data')
        return
      }
      const data = {
        ...googleData,
        referred_by: referralCode,
        origin: 'web',
        phone_number: values.phonenumber,
      }
      registerGoogle(data)
    },
  })

  const handleGoogleSignup = useGoogleLogin({
    onSuccess: (credentialResponse) =>
      responseGoogleSuccess(credentialResponse),
    onError: (err) => {
      toast.error(`${err}`, {
        toastId: 'google-use-id-toast-error',
      })
    },
  })

  const responseGoogleSuccess = (google_response: any) => {
    if (google_response) {
      setGoogleLoading(true)
      axios
        .get(
          `https://www.googleapis.com/oauth2/v1/userinfo?access_token=${google_response.access_token}`,
          {
            headers: {
              Authorization: `Bearer ${google_response.access_token}`,
              Accept: 'application/json',
            },
          }
        )
        .then((res) => {
          if (res.status === 200) {
            const getNumber = new Date().getTime()
            const username =
              res.data.given_name.toLowerCase() + getNumber.toString()
            const objData = {
              username: username,
              first_name: res.data.given_name,
              last_name: res.data.family_name,
              email: res.data.email.toLowerCase(),
              google: true,
              password: res.data.email.toLowerCase(),
              password_confirmation: res.data.email.toLowerCase(),
            }
            trackFacebookEvent('CompleteRegistration')
            setGoogleData(objData)
            setFormStep(1)
            //registerGoogle(objData);
          }
        })
        .catch((err: any) => {
          toast.error(`${err.data.message}`, {
            toastId: 'google-oauth2-id-toast-error',
          })
        })
        .finally(() => {
          setGoogleLoading(false)
        })
    }
  }

  //GOOGLE AUTHENTICATION SUCCESS RESPONSE
  if (getResult?.isSuccess) {
    trackFacebookEvent('CompleteRegistration')
    dispatch(
      setUser({
        user: {
          id: getResult?.data.id,
          username: getResult?.data.user.username,
          firstName: getResult?.data.user.first_name,
          lastName: getResult?.data.user.last_name,
          email: getResult?.data.user.email,
          role: getResult?.data.user.role,
          verified: getResult?.data.user.verified,
          phoneNumber: getResult?.data.user.phone_number,
          gender: getResult?.data.user.gender,
        },
        token: getResult?.data.token,
        anonymousUser: false,
        ek_isAuth: true,
      })
    )
    setFormStep(0)
    if (
      checkIfAdminOrSellerRoleExist(
        getResult?.data.token,
        getResult?.data.user.role
      ) === true
    ) {
      if (checkIfAdmin(getResult?.data.token, getResult?.data.user.role)) {
        navigate(DashboardSellers)
      } else {
        navigate(DashboardPathUrl)
      }
    } else {
      navigate(homePathUrl)
    }
    toast.success(`You"ve successfully login`, {
      toastId: 'login-google-success-toast-success',
    })
  }

  // // { regular register success response check}
  // if (isSuccess) {
  //   //trackFacebookEvent('CompleteRegistration')
  //   setTimeout(() => {
  //     navigate(emailVerifyPathUrl, {
  //       state: {
  //         email: email,
  //       },
  //       replace: true,
  //     })
  //   }, 1000)
  // }

  return (
    <>
      <HelmetHeader title="Sign Up" description="Qlason Register Page" />
      <RegisterContainerStyle>
        <RegisterAsideStyle>
          <AsideSlider />
        </RegisterAsideStyle>
        <RegisterMainContentStyle>
          <div className="ContentChildGroup__Style">
            <Logo width="40px" showDesc={false} />
            <div className="SignText_Style">
              <h1>Sign Up on Qlason</h1>
              <p>Create an account on Qlason to get started</p>
            </div>

            <div className="ContentForm__Style">
              <form
                noValidate
                onSubmit={
                  googleData
                    ? googlePhoneFormik.handleSubmit
                    : formik.handleSubmit
                }
                autoComplete="off"
              >
                {formStep === 0 && (
                  <Fragment>
                    <FormSteps>
                      <div className="horizontalGroup_Style">
                        <AuthFormGroupStyle>
                          <Field
                            id="firstname"
                            name="firstname"
                            label="First Name"
                            disabled={isLoading}
                            type="text"
                            required
                            size="large"
                            childOutsideLabel={false}
                            placeholder="John"
                            fieldClassName=""
                            autoComplete="off"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.firstname}
                            isError={
                              formik.touched.firstname &&
                              formik.errors.firstname
                                ? true
                                : false
                            }
                            error={formik.errors.firstname}
                          />
                        </AuthFormGroupStyle>
                        <AuthFormGroupStyle>
                          <Field
                            id="lastname"
                            name="lastname"
                            label="Last Name"
                            disabled={isLoading}
                            type="text"
                            required
                            size="large"
                            childOutsideLabel={false}
                            placeholder="Doe"
                            fieldClassName=""
                            autoComplete="off"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.lastname}
                            isError={
                              formik.touched.lastname && formik.errors.lastname
                                ? true
                                : false
                            }
                            error={formik.errors.lastname}
                          />
                        </AuthFormGroupStyle>
                      </div>
                      <AuthFormGroupStyle>
                        <Field
                          id="userName"
                          name="userName"
                          label="UserName"
                          disabled={isLoading}
                          type="text"
                          required
                          size="large"
                          childOutsideLabel={false}
                          placeholder="Johndoe"
                          fieldClassName=""
                          autoComplete="off"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.userName}
                          isError={
                            formik.touched.userName && formik.errors.userName
                              ? true
                              : false
                          }
                          error={formik.errors.userName}
                        />
                      </AuthFormGroupStyle>
                      <AuthFormGroupStyle>
                        <Field
                          id="email"
                          name="email"
                          label="Email Address"
                          disabled={isLoading}
                          type="email"
                          required
                          size="large"
                          childOutsideLabel={false}
                          placeholder="Johndoe@example.com"
                          fieldClassName=""
                          autoComplete="off"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.email}
                          isError={
                            formik.touched.email && formik.errors.email
                              ? true
                              : false
                          }
                          error={formik.errors.email}
                        />
                      </AuthFormGroupStyle>
                      <AuthFormGroupStyle>
                        <Field
                          id="phoneNumber"
                          name="phoneNumber"
                          label="Phone Number"
                          disabled={isLoading}
                          type="number"
                          required
                          size="large"
                          numberFieldPrefix="+234"
                          childOutsideLabel={false}
                          placeholder="09033455555"
                          fieldClassName=""
                          autoComplete="off"
                          value={formik.values.phoneNumber}
                          isError={formik.errors.phoneNumber ? true : false}
                          error={formik.errors.phoneNumber}
                          onBlur={formik.handleBlur}
                          onChange={(value) => {
                            formik.setFieldValue('phoneNumber', value.value)
                          }}
                        />
                      </AuthFormGroupStyle>
                      <AuthFormGroupStyle>
                        <Field
                          id="password"
                          name="password"
                          label="Create Password"
                          disabled={isLoading}
                          type="password"
                          required
                          size="large"
                          childOutsideLabel={false}
                          placeholder="*********"
                          fieldClassName=""
                          autoComplete="off"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.password}
                          isError={
                            formik.touched.password && formik.errors.password
                              ? true
                              : false
                          }
                          error={formik.errors.password}
                        />
                      </AuthFormGroupStyle>
                      <AuthFormGroupStyle>
                        <Field
                          id="confirmpassword"
                          name="confirmpassword"
                          label="Confirm Password"
                          disabled={isLoading}
                          type="password"
                          required
                          size="large"
                          childOutsideLabel={false}
                          placeholder="**********"
                          fieldClassName=""
                          autoComplete="off"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.confirmpassword}
                          isError={
                            formik.touched.confirmpassword &&
                            formik.errors.confirmpassword
                              ? true
                              : false
                          }
                          error={formik.errors.confirmpassword}
                        />
                      </AuthFormGroupStyle>
                      <div
                        style={{
                          width: '100%',
                          marginTop: '5px',
                          fontSize: '13px',
                        }}
                      >
                        <label
                          className="Checkbox"
                          style={{ cursor: 'pointer' }}
                        >
                          <Form.Check
                            name="aacceptTerms"
                            className="Checkbox__input"
                            type="checkbox"
                            label="I hereby agree to the Privacy conditions by signing
                            up."
                            checked={formik.values.acceptTerms}
                            onChange={(e) =>
                              formik.setFieldValue(
                                'acceptTerms',
                                e.target.checked
                              )
                            }
                          />
                        </label>

                        <label
                          className="Checkbox"
                          style={{ cursor: 'pointer' }}
                        >
                          <Form.Check
                            name="acceptNotification"
                            className="Checkbox__input"
                            type="checkbox"
                            label="I agree to receive emails from Qlason 
                            about Products/Services,
                            news, updates, marketing, etc."
                            checked={formik.values.acceptNotification}
                            onChange={(e) =>
                              formik.setFieldValue(
                                'acceptNotification',
                                e.target.checked
                              )
                            }
                          />
                        </label>

                        {formik.errors.acceptNotification ? (
                          <div style={{ color: 'red', fontSize: '12px' }}>
                            {formik.errors.acceptNotification}
                          </div>
                        ) : null}
                      </div>
                      <Button
                        name="Sign Up"
                        bgVariant="primary"
                        className="Button-Call-to-action"
                        isLoading={isLoading || googleLoading}
                        disabled={
                          isLoading ||
                          getIn(formik.errors, 'username') ||
                          getIn(formik.errors, 'firstname') ||
                          getIn(formik.errors, 'lastname') ||
                          getIn(formik.errors, 'email') ||
                          getIn(formik.errors, 'password') ||
                          getIn(formik.errors, 'confirmpassword') ||
                          getIn(formik.errors, 'acceptTerms') ||
                          getIn(formik.errors, 'phoneNumber')
                        }
                        type="submit"
                        shadow={false}
                      />
                      <div className="RegisterDiv__Style">
                        <span>Already have an account?</span>
                        <RefreshLink to={loginPathUrl}>Sign In</RefreshLink>
                      </div>
                      <div className="OtherProcess_div__Style">
                        <div className="OtherDivider"></div>
                        <span>or</span>
                      </div>
                      <div className="SocialAuth_method_style">
                        <div className="Listauth_Style">
                          <Button
                            name="google"
                            type="button"
                            disabled={isLoading || googleLoading}
                            shadow={false}
                            defaultClass={false}
                            onClick={() => handleGoogleSignup()}
                            className="auth_Card"
                          >
                            <SVG src={GoogleSVG} />
                            Continue with Google
                          </Button>
                        </div>
                      </div>
                    </FormSteps>
                  </Fragment>
                )}
                {formStep === 1 && (
                  <FormSteps className="mt-5">
                    <div className="FormStep__Wrapper">
                      <AuthFormGroupStyle className="mb-3">
                        <Field
                          id="phonenumber"
                          name="phonenumber"
                          label="Phone Number"
                          disabled={getResult?.isLoading}
                          type="number"
                          required
                          size="large"
                          numberFieldPrefix="+234"
                          childOutsideLabel={false}
                          placeholder="09033455555"
                          fieldClassName=""
                          autoComplete="off"
                          value={googlePhoneFormik.values.phonenumber}
                          isError={
                            googlePhoneFormik.touched.phonenumber &&
                            googlePhoneFormik.errors.phonenumber
                              ? true
                              : false
                          }
                          error={googlePhoneFormik.errors.phonenumber}
                          onBlur={googlePhoneFormik.handleBlur}
                          onChange={(value) => {
                            googlePhoneFormik.setFieldValue(
                              'phonenumber',
                              value.formattedValue
                            )
                          }}
                        />
                      </AuthFormGroupStyle>
                      <Button
                        name="Add Phone Number"
                        bgVariant="primary"
                        className="Button-Call-to-action"
                        isLoading={getResult?.isLoading}
                        disabled={
                          getResult?.isLoading ||
                          !!getIn(googlePhoneFormik.errors, 'phonenumber')
                        }
                        type="submit"
                        shadow={false}
                      />
                    </div>
                  </FormSteps>
                )}
              </form>
              <div className="Terms_Conditions-Style">
                <div>
                  By continuing, you agree to{' '}
                  <RefreshLink to="/terms-and-conditions" target="_blank">
                    Conditions of Use
                  </RefreshLink>{' '}
                  and{' '}
                  <RefreshLink to="/privacy-policy" target="_blank">
                    Policy Notice
                  </RefreshLink>
                </div>
              </div>
            </div>
          </div>
        </RegisterMainContentStyle>
      </RegisterContainerStyle>
    </>
  )
}

export default withAuthenticationLayout(Register)
