import classnames from 'classnames';
import React from 'react';
import styles from "./Tag.module.scss";

const Tag = ({ numberOf, rounded = false, className }: { numberOf: string | number, rounded?: boolean, className?: string }) => {
  const allClass = classnames(styles.TagParent__Container, className, {
    [styles.TagRadius_Round]: rounded,
    [styles.TagRadius_Normal]: !rounded
  })
  return <div className={allClass}>{numberOf}%</div>;
};

export default Tag;
