import React from 'react'

const SvgSearch = ({
	width = "1rem",
	height = "1rem",
	className
}: {
	className?: string,
	width?: string;
	height?: string
}) => (
	<svg
		width={width}
		height={height}
		className={className}
		viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
		<g id="Light/Icon">
			<g id="vuesax/linear/search-normal">
				<g id="search-normal">
					<path id="Vector" d="M7.66634 13.9999C11.1641 13.9999 13.9997 11.1644 13.9997 7.66659C13.9997 4.16878 11.1641 1.33325 7.66634 1.33325C4.16854 1.33325 1.33301 4.16878 1.33301 7.66659C1.33301 11.1644 4.16854 13.9999 7.66634 13.9999Z" stroke="#9A9EA6" strokeLinecap="round" strokeLinejoin="round" />
					<path id="Vector_2" d="M14.6663 14.6666L13.333 13.3333" stroke="#9A9EA6" strokeLinecap="round" strokeLinejoin="round" />
				</g>
			</g>
		</g>
	</svg>


)

export default SvgSearch;