import React, { useState } from 'react'
import styled from 'styled-components'
import { FollowButton, RefreshLink } from '@ekenta/components'
import { SellerInfoType } from '@ekenta/redux/store/features/product/productDataType'
import { device } from '@ekenta/utils/devices'

interface PropsType {
  sellerInfo: SellerInfoType
  merchantFollowers: number
  followStatus: number
}

const SellerInfo: React.FC<PropsType> = (props) => {
  const { merchantFollowers, sellerInfo, followStatus } = props

  const [count, setCount] = useState<number>(0)
  const _count = count ? count : merchantFollowers

  return (
    <Container>
      <div className="top-header">
        <h5>Seller Information</h5>
      </div>
      <div className="content">
        <div className="flex item-enter">
          <SellerLogo>{sellerInfo?.business_name?.charAt(0)}</SellerLogo>
          <VStack>
            <h4>
              <RefreshLink to={`/vendor/${sellerInfo?.id}/store`}>
                {sellerInfo?.business_name}
              </RefreshLink>
            </h4>
            <p>{_count || 0} Followers</p>
            <p>{sellerInfo?.phone_number}</p>
          </VStack>
        </div>
        <FollowButton
          followStatus={followStatus}
          sellerId={sellerInfo?.id?.toString()}
          count={_count}
          className="store-follow-cta"
          inline={true}
          callback={(count) => setCount(count)}
        />
      </div>
    </Container>
  )
}

const Container = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 16px 22px 19.5px 21px;
  border-radius: 12px;
  background: #fff;
  font-family: var(--eke-body-font-family);
  gap: 20px;
  //box-shadow: 0 2px 2px 0px rgba(0, 0, 0, 0.12);

  .flex {
    flex: 1;
    display: flex;
    align-items: flex-start;
    gap: 10px;
  }

  .top-header {
    width: 100%;
    height: 57px;
    border-radius: 12px;
    padding: 15px 16px 12px 16px;
    align-items: center;
    background: #f3faff;

    h5 {
      color: var(--secondary-500, #1d4057);
      font-size: 22px;
      font-weight: 700;
      margin: 0;
      padding: 0;
    }
  }

  .content {
    width: 100%;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    gap: 19px;
    padding-bottom: 10px;

    .follow--btn-group {
      width: 100% !important;

      button.store-follow-cta {
        max-width: 100% !important;
      }
    }

    @media ${device.lg} {
      flex-direction: row;
      align-items: center;

      .follow--btn-group {
        width: auto !important;
      }
      button.store-follow-cta {
        max-width: 150px !important;
      }
    }
  }
`

const VStack = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font-family: var(--eke-body-font-family);
  gap: 4px;

  h4 {
    font-size: 17px;
    font-weight: 700;
    letter-spacing: 0.2px;
    margin: 0;

    a {
      text-decoration: none;
      color: var(--greyscale-900, #212121);
    }
  }

  p {
    color: var(--greyscale-700, #616161);
    font-size: 15px;
    font-weight: 600;
    letter-spacing: 0.2px;
    margin: 0;
    padding: 0;
  }
`

const SellerLogo = styled.div`
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 35px;
  color: #fff;
  font-weight: bold;
  border-radius: 104px;
  background-color: var(--eke-color-primary);
`
export default SellerInfo
