import React from 'react';
import styled from 'styled-components';
import emptyOrder from '../../assets/images/empty_order_icon.png';

interface EmptyOrderPropsType {
  orderType: string;
}
export default function EmptyOrder(props: EmptyOrderPropsType) {
  return (
    <Container>
      <div className="empty__div_centered">
        <div className="image__container_order">
          <img src={emptyOrder} alt="" />
        </div>
        <p className="empty_order_text">No {props.orderType} order made</p>
      </div>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  vertical-align: middle;
  height: 400px;

  .empty__div_centered {
    .image__container_order {
      text-align: center;
      margin-bottom: 20px;

      img {
        width: 85px;
        height: auto;
        filter: grayscale(0.8) !important;
        opacity: 0.3;
      }
    }

    .empty_order_text {
      font-size: 20px;
      font-family: $fontfamily;
      font-weight: bolder;
      color: #9c9b9b;
    }
  }
`;
