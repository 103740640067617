import React from 'react'
import { ProductLoader } from '../../../Product/component/SkeletonLoader/ProductLoader'

export default function ProductListLoader() {
  return (
    <>
      {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9].map((i) => (
        <ProductLoader layoutView='grid' key={'id_' + i} />
      ))}
      <br />
      <br />
      <br />
    </>
  )
}
