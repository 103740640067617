import React, { Fragment, useState } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import {
  Button,
  DataTable,
  HelmetHeader,
  PageTitle,
  Typography,
} from '@ekenta/components'
import styled from '@emotion/styled'
import { withDefaultLayout } from '@ekenta/hoc'
import { ReactComponent as BagLockIcon } from '@ekenta/assets/icons/bag-lock.svg'
import { ReactComponent as BagIcon } from '@ekenta/assets/icons/order-value-icon.svg'
import { useGetAllVouchersQuery } from '@ekenta/redux/store/features/voucher/voucherApiSlice'
import { TypographyWrapper } from '@ekenta/pages/Dashboard/common/common'
import { RCardGroup, RenderCard } from '@ekenta/components/reusable/RenderCard'

import BreadCrumb from '../component/BreadCrumb'
import ProfileSideBar from '../component/AccountSideBar/AccountSideBar'
import { DataTableWrapper } from '../../common/common'
import { voucherColumn } from './column'
import CreateVoucher from './CreateVoucher'

const Voucher = () => {
  const [isOpen, setIsOpen] = useState(false)
  const { data } = useGetAllVouchersQuery()
  const allVoucher = data?.data ?? []

  const onOpen = async () => {
    setIsOpen(true)
  }

  const onClose = async () => {
    setIsOpen(false)
  }
  return (
    <Fragment>
      <HelmetHeader title="Voucher" description="Qlason Profile Account Page" />
      <Container className="account-user-container">
        <>
          <BreadCrumb activePage="Voucher Purchase History" />
        </>

        <Row className="account-pro-row g-2">
          <ProfileSideBar isLoading={null} />
          <Col className="account-profile-col-right ">
            <HStack>
              <div className="mb-2">
                <PageTitle
                  title="Voucher Purchase History"
                  margin={false}
                  subTitle={
                    <div>
                      <span>See breakdown of all voucher purchases</span>
                    </div>
                  }
                />
              </div>
              <Button
                name="Create Voucher"
                type="button"
                bgVariant="primary"
                disabled={false}
                defaultClass={false}
                onClick={onOpen}
              />
            </HStack>

            <Content className="pt-2 pb-5">
              <RCardGroup>
                <RenderCard
                  icon={<BagLockIcon width="51px" height="51px" />}
                  loading={false}
                  text="Number of Vouchers"
                  amount={'0'}
                />
                <RenderCard
                  icon={<BagIcon />}
                  text="Total Voucher Value"
                  loading={false}
                  amount={`₦${new Intl.NumberFormat().format(0)}`}
                />
              </RCardGroup>

              <DataTableWrapper
                isCenter={true}
                className="mt-4 tab__ position-relative"
              >
                <DataTable
                  theme="simple"
                  data={allVoucher}
                  shadow={true}
                  loading={false}
                  isSelectable={true}
                  columns={voucherColumn}
                  showPagination={true}
                  hasLink={true}
                  thClassName=""
                  trClassName="w-25"
                  theadClassName="DataTable_THead"
                  tdClassName={`Row__col`}
                  cardClassName="DataTable_Card"
                  showSearch={false}
                  renderTop={
                    <TypographyWrapper>
                      <Typography as="h3" color="default" size="xlarge" heading>
                        Purchase History
                      </Typography>
                    </TypographyWrapper>
                  }
                />
                <br />
              </DataTableWrapper>
            </Content>
          </Col>
        </Row>
      </Container>

      {/* Create Voucher Modal */}
      <CreateVoucher
        {...{
          isOpen,
          onClose,
        }}
      />
    </Fragment>
  )
}

const Content = styled.div`
  .CardBox,
  .CardBox_Dash {
    width: 100% !important;
  }
`

const HStack = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  button {
    width: auto !important;
    max-width: 150px !important;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 36.128px;
    height: 36.367px !important;
    padding: 20.677px !important;
    color: var(--eke-body-text-light-color) !important;
    box-shadow: none !important;
    border: none !important;
    transition: all 0.3s ease-out;
    gap: 4px !important;

    span {
      font-size: 14px !important;
      font-family: var(--eke-body-font-family) !important;
      font-weight: var(--eke-font-weight-bold) !important;
      margin-top: 0px;
    }
  }
`

export default withDefaultLayout(Voucher)
