import React, { useEffect, useState } from 'react'
import { HelmetHeader } from '@ekenta/components';
import { animateScroll } from 'react-scroll';
import { OnboardingMainStyle, OnboardingContainerStyle, OnboardingRowStyle } from './style';
import { withDefaultLayout } from '@ekenta/hoc';
import AsideStepper from './common/AsideStepper';
import OnboardingForm from './common/OnboardingForm';


const SellerOnboarding = () => {
    const [page, setPage] = useState(0);


    useEffect(() => {
        if (page) {
            scrollToTop();
        }
    }, [page]);

    const scrollToTop = () => {
        animateScroll.scrollToTop();
    }


    const nextPage = () => setPage(page + 1);
    const prevPage = () => setPage(page - 1);



    return (
        <>
            <HelmetHeader
                title="Sell on Qlason"
                description="Sell on QlasonPage"
            />
            <OnboardingMainStyle>
                <OnboardingContainerStyle>
                    <div className="TopOnboarding__Style">
                        <h1>Create a seller account</h1>
                        <p>Register and start selling today!</p>
                    </div>
                    <OnboardingRowStyle>
                        <AsideStepper
                            page={page}
                            setPage={setPage}
                        />
                        <OnboardingForm
                            page={page}
                            nextPage={nextPage}
                            prevPage={prevPage}
                        />
                    </OnboardingRowStyle>
                </OnboardingContainerStyle>
            </OnboardingMainStyle>
            <br />
            <br />
        </>
    )
}

export default withDefaultLayout(SellerOnboarding);