import React from 'react';
import styled from 'styled-components';
import statusliveimg from '../../assets/images/TB2live.gif'


export default function LiveLoveComp() {
  return (
    <Div className="lhc-status-like pst lc-ele">
        <div className="lhc-pst lov">
            <img className="sta-live-icon Live pst" src={statusliveimg} title="Live Commerce ongoing" alt="live" />
        </div>
    </Div>
  )
}


 
const Div = styled.div`
    position: absolute;
    z-index: 0;
    right: 12px;
    bottom: 60px;
    display: block;
    transition: all .4s ease-in-out;

    .lhc-pst{
        width: 30px;
        height: 62px;

        .sta-live-icon{
            width: 100%;
            height: 100%;
            border-style: none;
        }
    }

`;