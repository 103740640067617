/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { Fragment, useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { toast } from "react-toastify";
import { withDashboardLayout } from "@ekenta/hoc";
import { useAppSelector } from "@ekenta/redux/store/hook";
import { useFetchOrderStatusDetailsQuery, useSetOrderToPaidMutation, useUpdateOrderStatusAdminMutation } from "@ekenta/redux/store/services/adminSellerApi";
import {
  useFetchSellerOrderStatusDetailsQuery, useUpdateOrderStatusSellerMutation,
} from "@ekenta/redux/store/services/vendorProductApi";
import { useNavigate, useParams } from "react-router";
// import OrderSkeletonLoader from "../../components/SkeletonLoader/Account/OrderSkeletonLoader"
import { checkIfAdmin } from "@ekenta/utils";
import OrderSkeletonLoader from "@ekenta/components/SkeletonLoader/Account/OrderSkeletonLoader";
import { DataTable, HelmetHeader, Loader, Overlay, PageTitle, Typography } from "@ekenta/components";
import { VscArrowSmallLeft } from "react-icons/vsc";
import { DashboardOrdersPathUrl, adminAllOrders } from "@ekenta/routes/constant";

const OrderListDetails = () => {

  const { account, token } = useAppSelector((state) => state.auth.authentication);
  const isAdmin = account.user ? checkIfAdmin(token, account.user.role) : null;
  const navigate = useNavigate();
  const params = useParams();

  const id = params.id;


  let getOrderResult;
  isAdmin ? getOrderResult = useFetchOrderStatusDetailsQuery({ token, id }) :
    getOrderResult = useFetchSellerOrderStatusDetailsQuery({ token, id });

  const [updateOrderStatusAdmin, getResult] = useUpdateOrderStatusAdminMutation();
  const [updateOrderStatusSeller, result] = useUpdateOrderStatusSellerMutation();


  useEffect(() => {
    if (!id) {
      navigate(
        isAdmin
          ? adminAllOrders
          : DashboardOrdersPathUrl
      );
    }

    if (getResult?.isSuccess) {
      toast.success(`${getResult?.data.message}`, {
        toastId: "set-paid-id-toast-success",
      });
      navigate(
        isAdmin
          ? adminAllOrders
          : DashboardOrdersPathUrl
      );
    }

    if (getResult?.isError && getResult?.error.status === "FETCH_ERROR") {
      toast.error("Something went wrong", {
        toastId: "set-paid-id-toast-error",
      });
    }

    if (getResult?.isError && getResult?.error.status === 500) {
      toast.error("Something went wrong", {
        toastId: "set-paid2-id-toast-error",
      });
    }

    if (getResult?.isError && getResult?.error.status === 400) {
      toast.error(`${getResult?.error.data.message}`, {
        toastId: "set-paid3-id-toast-error",
      });
    }
  }, [
    getResult?.isSuccess,
    getResult?.isError,
    getResult?.error,
    id
  ]);

  useEffect(() => {
    if (result?.isSuccess) {
      toast.success(`${result?.data.message}`, {
        toastId: "seller-order-id-toast-success",
      });
      navigate(-1);
    }

    if (result?.isError && result?.error.status === "FETCH_ERROR") {
      toast.error("Something went wrong", {
        toastId: "seller-order-1-id-toast-error",
      });
    }

    if (result?.isError && result?.error.status === 500) {
      toast.error("Something went wrong", {
        toastId: "seller-order2-id-toast-error",
      });
    }

    if (result?.isError && result?.error.status === 400) {
      toast.error(`${result?.error.data.message}`, {
        toastId: "seller-order3-id-toast-error",
      });
    }
  }, [
    result?.isSuccess,
    result?.isError,
    result?.error
  ]);


  const handleChange = (value: any) => {
    const data = {
      status: value,
      delivery_status: value
    }
    updateOrderStatusAdmin({ data, id, token });
  };

  const handleSellerChange = (value: any) => {
    const data = {
      status: value,
      delivery_status: value
    }
    updateOrderStatusSeller({ data, id, token });
  };

  const columns = [
    {
      Header: "Name",
      accessor: "name",
    },
    {
      Header: "Image",
      accessor: "image",
      disableSortBy: true,
    },
    {
      Header: "Seller",
      accessor: "seller",
      disableSortBy: true,
    },
    {
      Header: "Seller Phone no",
      accessor: "seller_phone_no",
      disableSortBy: true,
    },
    {
      Header: "Seller Email",
      accessor: "seller_email",
      disableSortBy: true,
    },
    {
      Header: "Quantity",
      accessor: "quantity",
      disableSortBy: true,
    },
    {
      Header: "Delivery Status",
      accessor: "delivery_status",
      disableSortBy: true,
    },
    {
      Header: "Price",
      accessor: "price",
    },
    {
      Header: "Total",
      accessor: "total",
      disableSortBy: true,
    },
  ];

  const mapData = (data: any) => {
    return data.map((item: any) => ({
      ...item,
      name: item.product?.name,
      image: (
        <div
          className="picture__thumb"
          style={{ width: "70px", height: "auto" }}
        >
          <img
            className="img-thumbnail rounded"
            width="100%"
            height="50px"
            src={item.product?.images ? item.product?.images[0].image_url : ""}
          />
        </div>
      ),
      seller: item?.product.sellerInfo?.name,
      quantity: item.quantity,
      seller_phone_no: item.product.sellerInfo.phone_number,
      seller_email: item?.product?.sellerInfo.email,
      delivery_status: item?.delivery_status,
      price: `NGN ${new Intl.NumberFormat().format(item?.price)}`,
      total: `NGN ${new Intl.NumberFormat().format(item?.price)}`,
    }));
  }
  return (
    <>
      <HelmetHeader
        title="Order Detail"
        description="QlasonOrder Detail Dashboard Page"
      />
      <Col className=" col-lg-12 _listing_product_ px-3 dash_top">
        <PageTitle
          title="Order Details"
          subTitle={
            <span
              className="cursor-pointer d-flex w-lg-50 align-items-center 
                    text-muted"
              onClick={() => navigate(-1)}>
              <VscArrowSmallLeft />
              Back to Orders
            </span>
          }
        />

        {!getOrderResult?.isSuccess ? (
          <OrderSkeletonLoader />
        ) : (
          <Row className="px-3">
            <Row className={"mt-2 p-2 ps-0"}>
              <Col lg={6}>
                <Typography
                  as="span"
                  size="medium"
                  margin="top"
                  heading>
                  Status
                </Typography>

                <select
                  className="form-control"
                  defaultValue={
                    isAdmin
                      ? getOrderResult?.data.order.order_status
                      : getOrderResult?.data.data.order_item_status
                  }
                  onChange={
                    (e: any) => isAdmin ? handleChange(e.target.value) : handleSellerChange(e.target.value)
                  }>
                  <option value="PENDING">PENDING</option>
                  <option value="PROCESSING">PROCESSING</option>
                  <option value="COMPLETED">COMPLETED</option>
                  <option value="CANCELLED">CANCELLED</option>
                </select>

              </Col>
              <Col lg={1}>&nbsp;</Col>
              <Col>
                <Typography
                  as="span"
                  size="medium"
                  margin="top"
                  heading>
                  Items from orders
                </Typography>

                <Typography
                  as="span"
                  size="medium"
                  margin="top"
                  className="ms-2"
                  uiText>
                  #{isAdmin
                    ? getOrderResult?.data?.order.order_number
                    : getOrderResult?.data.data.order_id
                  }
                </Typography>
              </Col>
            </Row>


            <Row className="table-responsive p-2" style={{ marginTop: "15px" }}>
              <DataTable
                theme="simple"
                data={getOrderResult?.data
                  ? mapData(
                    isAdmin
                      ? getOrderResult.data?.order.order_item
                      : [getOrderResult.data?.data]
                  ) : []}
                loading={getOrderResult?.isLoading}
                isSelectable={true}
                columns={columns}
                showPagination={false}
                hasLink={true}
                thClassName="w-auto"
                tdClassName={`Row__col`}
                renderTop={
                  <Fragment>
                    {isAdmin ? (
                      <Row>
                        <Col xs={12} lg={6} className="mb-2">
                          <Typography
                            id="buyer_details"
                            size="small"
                            className="mb-3"
                            as="h5"
                          >User details</Typography>
                          <p>
                            <strong>Name</strong>:{" "}
                            {getOrderResult.data?.order.user?.first_name +
                              " " +
                              getOrderResult.data?.order.user?.last_name}{" "}
                          </p>
                          <p>
                            <strong>Email: </strong> {getOrderResult.data?.order.user?.email}{" "}
                          </p>
                          <p>
                            <strong>Phone: </strong>{" "}
                            {getOrderResult.data?.order.address?.phone_number}{" "}
                          </p>
                        </Col>
                        <Col xs={12} className="mb-2">
                          <Typography
                            id="buyer_details"
                            size="small"
                            className="mb-3"
                            as="h5"
                          >Delivery details</Typography>
                          <p>
                            <strong>Name</strong>:{" "}
                            {getOrderResult.data?.order.address?.first_name +
                              " " +
                              getOrderResult.data?.order.address?.last_name}{" "}
                          </p>
                          <p>
                            <strong>Phone: </strong>{" "}
                            {getOrderResult.data?.order.address?.phone_number}{" "}
                          </p>
                          <p>
                            <strong>Address: </strong>{" "}
                            {getOrderResult.data?.order.address?.home_address}{" "}
                          </p>
                        </Col>
                      </Row>
                    ) : (
                      <Typography
                        id="buyer_details"
                        size="small"
                        className="mb-3"
                        as="p"
                      >Order Item</Typography>
                    )}
                  </Fragment>
                }
                renderBottom={
                  <div className="d-flex align-items-center justify-content-between pt-2 px-2 pe-4">
                    <Typography
                      id="totalId"
                      size="small"
                      as="h5"
                    >Total</Typography>
                    <Typography
                      id="totalNumber"
                      size="small"
                      as="span">
                      NGN {new Intl.NumberFormat().format(
                        isAdmin ? getOrderResult.data?.order.total_order_amount
                          : getOrderResult.data?.data.price,
                      )}
                    </Typography>
                  </div>
                }

              />
            </Row>
          </Row>
        )}
      </Col>

      {getResult.isLoading || result?.isLoading ? (
        <Overlay bgColor="light"><Loader isCenter={true} /></Overlay>
      ) : null}

    </>
  );
}

export default withDashboardLayout(OrderListDetails);
