/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useContext, useEffect } from 'react'
import SVG from "react-inlinesvg";
import styled, { css } from 'styled-components';
import * as queryString from "query-string";

import { Typography } from '@ekenta/components';
import { CollectionContext } from '@ekenta/context/CollectionContext';
import { Form } from 'react-bootstrap';
import closeSVG from "@ekenta/assets/icons/close-square.svg";
//import { useLocation } from 'react-router-dom';

const MobileSort = (props: any) => {
    const { setShowPopover } = props;
    const query = queryString.parse(window.location.search);
    const { configureFilterUrl } = useContext(CollectionContext);
    //const { pathname } = useLocation();
    const [selected, setSelected] = React.useState('popular');


    const handleSelect = (value: string) => {
        setSelected(value)
        let data = { key: "sort", value: value, set: true };
        if (value !== "popular") {
            data = { ...data, set: true }
        } else {
            data = { ...data, set: false }
        }
        configureFilterUrl(data);
        setShowPopover(() => ({ type: "", show: false }));
    }

    useEffect(() => {
        if (query.sort) {
            setSelected(query.sort);
        } else {
            setSelected("popular");
        }
    }, []);


    return (
        <Container>
            <Flex topPos>
                <Typography
                    as="h5"
                    size="medium"
                    className="Typography_Heading"
                    color="dark"
                    heading>
                    Sort by
                </Typography>
                <IconButton onClick={props.onClose}>
                    <SVG src={closeSVG} />
                </IconButton>
            </Flex>
            <ColumnGroup posType="col">
                <Form.Check
                    name="sort"
                    type="radio"
                    className={selected === "popular" ? "active" : ""}
                    label="Popular to Non Popular"
                    id="popular"
                    checked={selected === "popular" ? true : false}
                    onChange={() => handleSelect('popular')}
                />
                <Form.Check
                    name="sort"
                    type="radio"
                    className={selected === "highest" ? "active" : ""}
                    label="Highest Price to Lowest Price"
                    id="highest"
                    checked={selected === "highest" ? true : false}
                    onChange={() => handleSelect('highest')}

                />
                <Form.Check
                    name="sort"
                    type="radio"
                    className={selected === "lowest" ? "active" : ""}
                    label="Lowest Price to Highest Price"
                    id="lowest"
                    checked={selected === "lowest" ? true : false}
                    onChange={() => handleSelect('lowest')}
                />
                <Form.Check
                    name="sort"
                    type="radio"
                    className={selected === "newest" ? "active" : ""}
                    label="Newly Added Products to Old Products"
                    id="newest"
                    checked={selected === "newest" ? true : false}
                    onChange={() => handleSelect('newest')}
                />
            </ColumnGroup>

            <Flex topPos={false}>
                <Typography
                    as="h5"
                    size="medium"
                    className="Typography_Heading"
                    color="dark"
                    heading>
                    Condition
                </Typography>
            </Flex>
            <ColumnGroup posType="row">
                <Form.Check
                    name="sort"
                    type="radio"
                    className={selected === "used" ? "active" : ""}
                    label="Used"
                    id="used"
                    checked={selected === "used" ? true : false}
                    onChange={() => handleSelect('used')}
                />
                <Form.Check
                    name="sort"
                    type="radio"
                    className={selected === "newest" ? "active" : ""}
                    label="New"
                    id="new"
                    checked={selected === "newest" ? true : false}
                    onChange={() => handleSelect('newest')}
                />
            </ColumnGroup>
        </Container>
    )
}

const Container = styled.div`
    padding: 2em 1.5em 1em 1.5em;
    height: 100%;
`;

const Flex = styled.div<{ topPos: boolean; }>`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    ${props => props.topPos ?
        css`
        padding-bottom: 25px;
        border-bottom: 1px solid #EEE;
    `:
        css`
          padding-top: 25px;
          border: none;
    `}

     .Typography_Heading{
        margin: 0 !important;
        color: #000 !important;
        font-size: 20px;
        font-family: var(--eke-body-font-family);
        font-weight: var(--eke-font-weight-bold) !important;
        line-height: 120%; /* 24px */
    }

`;

const ColumnGroup = styled.div<{ posType: string; }>`
     display: flex;
     padding-top: 20px;
      align-items: flex-start;
        gap: 21px;

    ${props => props.posType === 'col' ?
        css`
         flex-direction: column;
        border-bottom: 1px solid #EEE;
        padding-bottom: 25px;

    `: props.posType === 'row' ?
            css`
        flex-direction: row;
        padding-bottom: 15px;

    `: null}

    .form-check {
        display: flex !important;
        align-items: center !important;
        gap: 12px !important;
        margin:0 !important;
    }

     label{
        color: var(--greyscale-900, #212121) !important;
        font-family: var(--eke-body-font-family);
        font-weight: var(--eke-font-weight-semibold) !important;
        font-size: 14px !important;
        line-height: 140% !important; /* 19.6px */
        letter-spacing: 0.2px;
     }
     input{
        margin-top: -1px !important;
     }

     .form-check.active label {
            color: var(--eke-body-text-primary-color) !important;
            font-weight: var(--eke-font-weight-semibold) !important;
        }    
`

const IconButton = styled.button`
    width: auto;
    outline: none;
    border: none;
    background-color: transparent !important;
`;
export default MobileSort;