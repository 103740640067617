/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import ErrorPage from '../ErrorsPage/ErrorPage';
import ErrorWrapper from '../ErrorsPage/ErrorWrapper/ErrorWrapper';


class ErrorBoundaries extends React.Component<{ children: React.ReactNode, externalError?: boolean, wrapContent?: boolean }, { error: string | null, errorInfo: any, }> {
  constructor(props: any) {
    super(props);
    this.state = {
      error: null,
      errorInfo: null
    };
  }

  componentDidCatch(error: any, errorInfo: any) {
    // Catch errors in any components below and re-render with error message
    this.setState({
      error: error,
      errorInfo: errorInfo
    })
    // You can also log error messages to an error reporting service here
  }

  renderComponent() {
    return <ErrorPage
      title="Something went wrong."
      subTitle=""
      wrap={this.props.wrapContent}
    >
      <div>
        If you think this is an error then please contact{" "}
        <a href="mailto:support@dabsoluterobotics.com?subject=Site+Error">
          support@dabsoluterobotics.com
        </a>
        .
      </div>
    </ErrorPage>
  }
  render() {
    if (this.state.errorInfo) {
      // Error path
      return this.props.externalError ?
        <ErrorWrapper>
          {this.renderComponent()}
        </ErrorWrapper>
        : (
          this.renderComponent()
        );
    }
    // Normally, just render children
    return this.props.children
  }
}

export default ErrorBoundaries;
