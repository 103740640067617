/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react'
import { closePaymentModal, useFlutterwave } from 'flutterwave-react-v3'
import { FLUTTERWAVE_KEY_PUBLIC } from '@ekenta/constants'
import { generateReference } from '@ekenta/utils'
import { Button } from '@ekenta/components'

interface FlutterwavePayType {
  customerEmail: string
  customerName: string
  customerPhoneNumber: string
  descriptionOfPayment: string
  amountToPay: number
  disabled: boolean
  loading: boolean
  setLoading: React.Dispatch<React.SetStateAction<boolean>>
  callback: (response: any) => void
}

const FlutterwavePay = (props: FlutterwavePayType) => {
  const { loading, setLoading } = props
  const paymentData = props
  const publicKey = FLUTTERWAVE_KEY_PUBLIC || ''

  const reference = generateReference()

  const config = {
    public_key: publicKey,
    tx_ref: reference,
    amount: paymentData.amountToPay,
    currency: 'NGN',
    payment_options: 'card,mobilemoney,ussd',
    customer: {
      email: paymentData.customerEmail,
      phone_number: paymentData.customerPhoneNumber,
      name: paymentData.customerName,
    },
    customizations: {
      title: 'Qlason Payment',
      description: paymentData.descriptionOfPayment,
      logo: 'https://st2.depositphotos.com/4403291/7418/v/450/depositphotos_74189661-stock-illustration-online-shop-log.jpg',
    },
  }

  const handlePay = useFlutterwave(config)

  const onClickHandler = () => {
    setLoading(true)
    handlePay({
      callback: (response) => {
        props.callback(response)
        closePaymentModal()
      },
      onClose: () => {
        setLoading(false)
        console.log('close')
      },
    })
  }

  return (
    <Button
      name={loading ? 'Processing...' : 'Pay Now'}
      isLoading={loading}
      isLoadingText=" "
      type="button"
      className="main cta-btn"
      bgVariant="primary"
      textColor="white"
      defaultClass={false}
      disabled={loading || paymentData.disabled}
      onClick={onClickHandler}
    />
  )
}

export default FlutterwavePay
