/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { Fragment, useState } from 'react';
import styled from 'styled-components';
import { CustomAlert, CustomModal, RefreshLink } from '@ekenta/components';
import { allLivePathUrl, singLiveBaseUrl } from '@ekenta/routes/constant';
import { useFetchAllLiveSellersQuery } from '@ekenta/redux/store/services/livecommerceApi';
import { AllLiveCardLoader } from './AllLiveLoader';
import SectionCard from '../Sectioncard';
import liveGIF from '@ekenta/assets/images/animate-livestream.gif';
import liveAvatar from '@ekenta/assets/images/live_avatar1.png';
import defaultImg from '@ekenta/assets/images/default_livestream.webp';
//import EmptyLive from './EmptyLive';
import { ReactComponent as VideoPlayIcon } from '@ekenta/assets/icons/video-play-icon.svg';
import VideoImage from './video-banner.jpg';

const LiveCommerce = () => {
	const [modal, setModal] = useState(false);
	const getResult = useFetchAllLiveSellersQuery();

	const handleOnClick = (open: boolean) => {
		setModal(open);
	};

	const mapAllLiveSellers = (data: any) => {
		return data.merchant_business.length > 0 && (
			data.merchant_business.slice(0, 6).map((result: any, index: number) => {
				return (
					<LiveCardStyle avatar={liveAvatar} liveGIF={liveGIF} key={index}>
						<RefreshLink to={`${singLiveBaseUrl}/spm-${result.id}?type=isLive`}>
							<div className="ImageGroup__Style" style={{ height: '200px' }}>
								<img
									className="_tim"
									data-creative={
										result.merchant_business?.thumbnail_url
											? result.merchant_business?.thumbnail_url
											: defaultImg
									}
									data-position="live_1_1"
									src={
										result.merchant_business?.thumbnail_url
											? result.merchant_business?.thumbnail_url
											: defaultImg
									}
									alt=""
								/>
							</div>
							<div className="Details__Style">
								<div className="Left_Detail__Style">
									<div className="Mini-Image__Style"></div>
									<span className="active__Indicator" />
								</div>
								<div className="Right_Detail__Style">
									<h5>{result.merchant_business?.business_name}</h5>
									<p>Tap to watch my live videos</p>
								</div>
							</div>
							<div className="animate__Like_Lott"></div>
						</RefreshLink>
					</LiveCardStyle>
				);
			})
		)
	}

	return (
		<Fragment>
			<SectionCard
				title="Qlason Live Commerce"
				size="small"
				url={allLivePathUrl}
			>
				<div>
					<VideoWrapper bg={VideoImage}>
						<button type="button" onClick={() => handleOnClick(true)}>
							<VideoPlayIcon />
						</button>
					</VideoWrapper>
					<ContentStyle>
						{getResult?.isError && <AllLiveCardLoader />}
						{getResult?.isLoading ? (
							<AllLiveCardLoader />
						) : (
							<>
								{getResult?.isSuccess && getResult?.data ? (
									<>{mapAllLiveSellers(getResult?.data)}</>
								) : null}
							</>
						)}
					</ContentStyle>
					{getResult?.isError && (
						<CustomAlert
							variant="danger"
							show={true}
							message="Unable to load livecommerce"
						/>
					)}
				</div>
			</SectionCard>
			<CustomModal
				show={modal}
				size="xl"
				title=""
				subtitle=""
				colorScheme="dark"
				modalPadding="0px"
				centered={true}
				showFooter={false}
				isLoading={false}
				isDisabled={false}
				headerClass="app-modalheader"
				onHide={() => handleOnClick(false)}
				render={
					<EmbedVideo>
						<iframe
							width="100%"
							height="100%"
							src="https://www.youtube.com/embed/8bT8EydE6NQ?rel=0?version=3&autoplay=1&controls=0&&showinfo=0&loop=1"
							title="Qlason E- commerce Platform"
							frameBorder="0"
							allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
						></iframe>
					</EmbedVideo>
				}
			/>
		</Fragment>
	);
};


const ContentStyle = styled.div`
  width: 100%;
  height: auto;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  gap: 0.8em;
  overflow: hidden;
  overflow-x: auto;
  white-space: nowrap;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */

  &::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
  }
`;

const LiveCardStyle = styled.div<{ liveGIF?: string; avatar: string }>`
  position: relative;
  width: 275px;
  height: auto;
  z-index: 1 !important;
  flex-shrink: 0;

  a {
    display: flex;
    width: 100%;
    height: auto;
    align-items: center;
    flex-direction: column;
    gap: 10.157px;
    padding: 15.157px 13.5px 13.157px;
    border-radius: 18.157px;
    background: var(--eke-color-white);
    box-shadow: 0px 4px 60px 0px rgba(92, 107, 173, 0.1);
    text-decoration: none !important;
  }

  .ImageGroup__Style {
    width: 100%;
    height: 180px;
    border-radius: 18.157px;
    background: var(--background-blue, #f5fbff);

    img {
      width: 100%;
      height: 100%;
      border-radius: 18.157px;
      object-fit: cover;
      object-position: 50% 50%;
    }
  }

  .Details__Style {
    width: 100%;
    height: 50px;
    display: flex;
    align-items: center;
    gap: 12px;
    z-index: 1;

    .Left_Detail__Style {
      position: relative;
      width: 48px;
      height: 48px;
      justify-content: center;
      align-items: center;
      border-radius: 200px;

      .Mini-Image__Style {
        width: 100%;
        height: 100%;
        display: block;
        border-radius: 200px;
        background: ${(props) =>
		props.avatar ? `url(${props.avatar}) no-repeat` : 'lightgray'};
        background-position: center;
        background-size: cover;
        background-color: #eee;
      }

      .active__Indicator {
        position: absolute;
        right: 0.467px;
        bottom: 0.867px;
        width: 12px;
        height: 12px;
        flex-shrink: 0;
        border-radius: 6px;
        border: 1.5px solid var(--eke-color-white);
        background: var(--eke-color-success-500);
        margin-top: -3px;
      }
    }

    .Right_Detail__Style {
      flex: 1;
      width: auto;

      h5 {
        color: var(--greyscale-900, #212121);
        font-size: 16px;
        font-family: var(--eke-body-font-family);
        font-weight: var(--eke-font-weight-bold);
        line-height: 140%;
        letter-spacing: 0.2px;
        margin: 0 !important;
      }
      p {
        margin: 0 !important;
        color: var(--greyscale-500, #9e9e9e) !important;
        font-size: 12px;
        font-family: var(--eke-body-font-family);
        font-weight: var(--eke-font-weight-medium);
        line-height: normal;
        letter-spacing: 0.2px;
      }
    }
  }

  .animate__Like_Lott {
    position: absolute;
    right: 0.467px;
    bottom: 17.867px;
    width: 84.533px;
    height: 125.446px;
    display: block;
    background: ${(props) =>
		props.liveGIF
			? `url(${props.liveGIF}) center/cover no-repeat`
			: 'lightgray'};
  }
`;

const VideoWrapper = styled.div<{ bg: string }>`
  position: relative;
  width: 100%;
  height: 290px;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  background: #f3faff;
  margin-bottom: 10px;
  background-image: ${(props) => `url(${props.bg})`};
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  overflow: hidden;

  button {
    width: 95px;
    height: 95px;
    border-radius: 50%;
    position: absolute;
    background-color: transparent;
    outline: none;
    border: none;
    svg {
      width: 100%;
      height: 100%;
    }
  }
`;

const EmbedVideo = styled.div`
  width: 100%;
  height: 523px;
  border-radius: 16px ;
 overflow: hidden;
`;

export default LiveCommerce;
