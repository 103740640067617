import React, { Fragment } from 'react';
import { Listbox, Transition } from '@headlessui/react';
import styled from 'styled-components';
import { FiChevronDown, FiChevronUp } from 'react-icons/fi';

const Select = ({ lists, selected, setSelected, className, width = "auto" }: any) => {
  return (
    <Container className={className} width={width}>
      <Listbox as={ListSelect} value={selected} onChange={setSelected}>
        {({ open }) => (
          <>
            <Listbox.Button as={ListSelectButton}>
              <span className="selected-button-name">{selected.name}</span>
              <span className="pointer-svg-name">
                {open ? <FiChevronUp /> : <FiChevronDown />}
              </span>
            </Listbox.Button>
            {open && (
              <Transition
                as={Fragment}
                leave="transition ease-in duration-100"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Listbox.Options as={ListSelectOptions}>
                  {lists.map(
                    (list: { name: string; key: string }, listIdx: number) => (
                      <Listbox.Option
                        as={ListSelectOption}
                        key={listIdx}
                        className={({ active }: { active: boolean }) =>
                          `relative cursor-default select-none py-2 pl-10 pr-4 ${active ? 'bg-amber-100 text-amber-900' : 'text-gray-900'
                          }`
                        }
                        value={list}
                      >
                        {({ selected }) => (
                          <>
                            <span
                              className={`block truncate ${selected ? 'font-medium' : 'font-normal'
                                }`}
                            >
                              {list.name}
                            </span>
                            {selected ? (
                              <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-amber-600">
                                {/* <CheckIcon className="h-5 w-5" aria-hidden="true" /> */}
                              </span>
                            ) : null}
                          </>
                        )}
                      </Listbox.Option>
                    ),
                  )}
                </Listbox.Options>
              </Transition>
            )}
          </>
        )}
      </Listbox>
    </Container>
  );
};

const Container = styled.div<{ width: string; }>`
  width: ${props => props.width};
  top: 0;
  position: relative;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  background-color: #fff; // rgba(0,0,0,0.03);
`;
const ListSelect = styled.div`
  position: relative;
  cursor: pointer;
`;

const ListSelectButton = styled.button`
  position: relative;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 0.85rem;
  padding-right: 2.5rem;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  background-color: rgba(0,0,0,0.03) !important;
  text-align: left;
  width: 100%;
  cursor: pointer;
  border: none;

  @media (min-width: 640px) {
    line-height: 1.25rem;
  }

  .selected-button-name {
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 13.5px;
    line-height: 16px;
    text-transform: capitalize;
    font-weight: 400;
    font-family: var(----eke-body-font-family);
  }

  .pointer-svg-name {
    position: absolute;
    top: 8px;
    right: 18.5px;

    svg {
      font-size: 12px;
    }
  }
`;

const ListSelectOptions = styled.div`
  overflow: auto;
  position: absolute;
  padding-top: 0.2rem;
  padding-bottom: 0.2rem;
  margin-top: 0.2rem;
  background-color: #ffffff;
  font-size: 1rem;
  line-height: 1.5rem;
  width: 100%;
  border-radius: 0.375rem;
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
    0 4px 6px -2px rgba(0, 0, 0, 0.05);
  z-index: 99999999999999999999;

  @media (min-width: 640px) {
    font-size: 0.875rem;
    line-height: 1.25rem;
  }
`;

const ListSelectOption = styled.div`
  position: relative;
  padding-top: 0.1rem;
  padding-bottom: 0.1rem;
  padding-right: 0.8rem;
  padding-left: 0.8rem;
  cursor: pointer !important;
  list-style-type: none;
  margin: 0;
  width: 100%;
  white-space: nowrap;
  text-transform: capitalize;
  font-weight: 400;
  font-size: 13.5px;
  font-family: var(----eke-body-font-family);

  &:hover {
    background-color: #eee;
    color: var(--eke-color-primary);
  }
`;

export default Select;
