import Loader from '@ekenta/components/Loader/Loader';
import React from 'react'
import styled from 'styled-components'

export default function LoadingOverlay() {
  return (
     <LoadingWrapper>
      <Loader />
     </LoadingWrapper>
  )
}


const LoadingWrapper = styled.div`
    width: 100%;
    height: 100%;
    /* Loading overlay has semi-transparent background so the player
        will be darkened while it's visible */
    background-color: rgba(0, 0, 0, 0.1);
    /* Center the spinner inside of the overlay */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 0 !important;
`;