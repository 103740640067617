import React, { FC, Fragment, useCallback, useState } from 'react'
import { useDispatch } from 'react-redux'
import styled from 'styled-components'
import { FiTrash2 } from 'react-icons/fi'
import { removeFromCartAsync } from '@ekenta/redux/store/features/cart/cartThunks'
import { Loader } from '@ekenta/components'

interface RemoveBtn {
  id: string
  refetch: () => void
}

const RemoveBtn: FC<RemoveBtn> = (props) => {
  const { id, refetch } = props
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false)

  const handleDelete = useCallback(async () => {
    try {
      setLoading(true)
      await dispatch(removeFromCartAsync(id))
      refetch()
      setLoading(false)
    } catch (error) {
      console.log(error)
    }
  }, [])
  return (
    <Button onClick={handleDelete} disabled={loading}>
      <Fragment>
        {loading ? <Loader /> : <FiTrash2 />}
        Remove
      </Fragment>
    </Button>
  )
}

const Button = styled.button`
  display: flex;
  align-items: center;
  gap: 3px;
  border: none;
  outline: none;
  background: transparent;
  color: var(--eke-color-primary);
  font-family: var(--eke-body-font-family);
  font-size: 16px;
  font-weight: 600;
  line-height: 120%; /* 21.6px */
  text-decoration: none !important;
`
export default RemoveBtn
