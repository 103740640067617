/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState } from 'react'
import * as queryString from "query-string";
import { VscArrowSmallLeft } from 'react-icons/vsc'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { ButtonGroupStyle, CardGroupStyle, DataTableWrapper, RenderCard, TopWrapper, TypographyWrapper } from '../../../common/common'
import { withDashboardLayout } from '@ekenta/hoc'
// import { ReactComponent as DownloadIcon } from "@ekenta/assets/icons/download-icon.svg";
import { Button, DataTable, HelmetHeader, PageTitle, Pagination, Typography } from '@ekenta/components'
import { useAppSelector } from '@ekenta/redux/store/hook'
import { useFetchSinglePayoutQuery } from '@ekenta/redux/store/services/adminSellerApi'
import { ReactComponent as OrderIcon } from "@ekenta/assets/icons/order-value-icon.svg";
import { ReactComponent as DeliveryIcon } from "@ekenta/assets/icons/delivery-fee-icon.svg";
import { ReactComponent as DocIcon } from "@ekenta/assets/icons/doc-icon.svg";
import { ReactComponent as Doc2Icon } from "@ekenta/assets/icons/doc2-icon.svg";
import { AppBoard } from '@ekenta/pages/Dashboard/common/styles'
import { StatusIndicator, TextTruncate } from '@ekenta/pages/Dashboard/common/Resuable'
import { formatCurrency } from '@ekenta/utils'
import { adminPayoutHistory } from '@ekenta/routes/constant';



const AdminSinglePayout = () => {
    const query = queryString.parse(window.location.search);
    const _page = query.page;
    const navigate = useNavigate();
    const { pathname } = useLocation();
    const params = useParams();

    const isParam = !params.id ? true : false
    const { token } = useAppSelector((state) => state.auth.authentication);
    const [pageno, setPageno] = useState(1);
    const result = useFetchSinglePayoutQuery({
        pageno,
        id: params.id,
        token
    }, {
        skip: isParam
    });


    const columns = [
        {
            Header: 'Image',
            accessor: 'image',
            disableSortBy: true,

        },
        {
            Header: "Name",
            accessor: "name",
            sortType: "basic",
        },
        {
            Header: 'Price',
            accessor: 'price',
            sortType: 'basic',
        },
        {
            Header: "SubCollection",
            accessor: "subcollection",
            sortType: 'basic',
        },
        {
            Header: 'Discount Price',
            accessor: 'discount',
            disableSortBy: true,
        },
        {
            Header: '% Discount',
            accessor: 'per_discount',
            disableSortBy: true,
        },
        {
            Header: 'OrderPrice',
            accessor: 'orderprice',
            sortType: 'basic',
        },
        {
            Header: 'status',
            accessor: 'status',
            sortType: 'basic',
        },
        {
            Header: 'OrderQty',
            accessor: 'qty',
            disableSortBy: true,
        },
    ];

    const mapData = (data: any) => {
        return data.length > 0 ? data.map((item: any) => ({
            ...item,
            image: (
                <div
                    className="picture__thumb"
                    style={{ width: '50px', height: 'auto', maxHeight: '60px', overflow: "hidden" }}
                >
                    <img
                        className="img-thumbnail rounded"
                        width="100%"
                        height="90%"
                        style={{ maxHeight: '60px', }}
                        src={item?.product.images.length > 0 ? item?.product.images[0]?.image_url : ''}
                    />
                </div>
            ),
            name: (
                <TextTruncate>{item?.product?.name}</TextTruncate>
            ),
            price: formatCurrency(item?.product?.price, '₦'),
            subcollection: item?.product?.subCollection,
            discount: item?.product?.discount,
            orderprice: formatCurrency(item?.price, '₦'),
            status: (
                item.seller_payment_status ? (
                    <StatusIndicator text={item?.seller_payment_status}
                        status={
                            item?.seller_payment_status === "NOT PAID" ? "PENDING" :
                                item?.seller_payment_status === "PAID" ? "COMPLETED" :
                                    item?.seller_payment_status
                        } />
                ) : ""
            ),
            per_discount: item?.admin_discount,
            qty: item?.quantity,
        })) : [];
    };

    return (
        <>
            <HelmetHeader
                title={result?.data ? `${result?.data.user?.merchant_business?.business_name} payout` : "Seller Payout"}
                description="Qlason Single Seller Payout Request Page"
            />
            <AppBoard className="_listing_product_">
                <div className="mt-0">
                    <div className="AppEvents_col mx-1 mx-lg-0">
                        <PageTitle
                            title={result?.data ? result?.data.user?.merchant_business?.business_name : "Loading..."}
                            margin={false}
                            subTitle={
                                <div>
                                    <span
                                        className="cursor-pointer d-flex w-lg-50 align-items-center text-muted"
                                        onClick={() => navigate(-1)}>
                                        <VscArrowSmallLeft />
                                        Back to Payout {query.history ? 'History' : ''}
                                    </span>
                                    <p className="mt-4">
                                        View analytics and seller data
                                    </p>
                                </div>
                            }
                        >
                            <ButtonGroupStyle defaultStyle>
                                <Button
                                    name="Payout History"
                                    bgVariant=""
                                    className="outline_role_button"
                                    type="button"
                                    disabled={false}
                                    shadow={false}
                                    onClick={() => navigate(`${adminPayoutHistory}/${params.id}`)}
                                />
                                {/* <Button
                                    name="Acknowledge Payout"
                                    bgVariant=""
                                    className="outline_role_button"
                                    type="button"
                                    disabled={true}
                                    shadow={false}
                                />
                                <Button
                                    name="Payout"
                                    bgVariant="primary"
                                    textVariant="light"
                                    type="button"
                                    shadow={false}
                                /> */}
                            </ButtonGroupStyle>
                        </PageTitle>
                    </div >
                    <CardGroupStyle flexWrap={false}>
                        <RenderCard
                            icon={<OrderIcon />}
                            loading={result?.isLoading}
                            text="Total Orders Value"
                            amount={`₦${new Intl.NumberFormat().format(result?.data ? result?.data?.totalOrderValue : 0)}`}
                        />
                        <RenderCard
                            icon={<DeliveryIcon />}
                            loading={result?.isLoading}
                            text="Total Payout"
                            amount={`₦${new Intl.NumberFormat().format(result?.data ? result?.data?.totalPayout : 0)}`}
                        />
                        <RenderCard
                            icon={<DocIcon />}
                            text="Total Unpaid Payout"
                            loading={result?.isLoading}
                            amount={`₦${new Intl.NumberFormat().format(result?.data ? result?.data?.totalUnpaidPayout : 0)}`}
                        />
                        <RenderCard
                            icon={<Doc2Icon />}
                            text="Total Admin Commission"
                            loading={result?.isLoading}
                            amount={`₦${new Intl.NumberFormat().format(result?.data ? result?.data?.totalAdminCommission : 0)}`}
                        />
                    </CardGroupStyle>

                    <DataTableWrapper isCenter={true} className="mt-4 tab__ position-relative">
                        <DataTable
                            theme="simple"
                            data={result?.data ? mapData(result?.data?.orders?.data) : []}
                            loading={result?.isLoading}
                            isSelectable={true}
                            columns={columns}
                            showPagination={true}
                            hasLink={true}
                            thClassName="w-auto"
                            trClassName="w-50"
                            theadClassName="DataTable_THead"
                            tdClassName={`Row__col payout_td`}
                            showSearch={false}
                            renderTop={
                                <TopWrapper>
                                    <TypographyWrapper>
                                        <Typography
                                            as="h3"
                                            color="default"
                                            size="xlarge"
                                            heading >
                                            All Order Sold
                                        </Typography>
                                    </TypographyWrapper>
                                </TopWrapper>
                            }
                            renderPagnation={
                                <div className="mt-4">
                                    {result?.data ? (
                                        <Pagination
                                            pathname={pathname}
                                            itemPerPage={result?.data?.orders?.per_page}
                                            itemsLength={result?.data?.orders?.total}
                                            currentPage={pageno}
                                            queryNo={_page}
                                            callback={(value: any) => setPageno(value)}
                                        />
                                    )
                                        : null}
                                </div>
                            }
                        />
                        <br />
                    </DataTableWrapper>
                </div>
            </AppBoard>
        </>
    )
}

export default withDashboardLayout(AdminSinglePayout);