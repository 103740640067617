/* eslint-disable @typescript-eslint/no-explicit-any */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AuthenticationState } from "../initialState";


export const authenticationSlice = createSlice({
    name: 'authentication',
    initialState:AuthenticationState,
    reducers: {
       setUser:(state, action: PayloadAction<{ user: any | null; token: string | null; ek_isAuth:boolean; anonymousUser:boolean}>) => {
           state.account.user = action.payload.user;
           state.anonymousUser = action.payload.anonymousUser;
           state.token = action.payload.token;
           state.ek_isAuth = action.payload.ek_isAuth;
       },
       setShippingAddress:(state, action:PayloadAction<{address:any}>) => {
        state.shippingAddress = action.payload.address;
       },
       removeUser:(state)=>{
        state.account.user = null;
        state.token = null;
        state.anonymousUser = false;
        state.ek_isAuth = false;
       },
       myjoyride:(state)=>{
           state.ek_joyride = false;
       }
    }
})

export const { setUser,setShippingAddress, removeUser, myjoyride} = authenticationSlice.actions;

export default authenticationSlice.reducer;
