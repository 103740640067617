import React, { Fragment, useState } from 'react'
import SVG from 'react-inlinesvg'
// import IkitchenSVG from "@ekenta/assets/icons/ikitchen-icon.svg";
// import SavePlanetSVG from "@ekenta/assets/icons/save-planet.svg";
import SpinWheelSVG from '@ekenta/assets/icons/spin-wheel.svg'
import MarketStreamSVG from '@ekenta/assets/icons/market-icon.svg'
import EyeshopperSVG from '@ekenta/assets/icons/eyeshopper-icon.svg'
import ShoppingImg from './common/shopping-stream.png'
import { RightCardStyle, RightSideContainerStyle } from './styles'
import { RefreshLink } from '@ekenta/components'
import { allLivePathUrl } from '@ekenta/routes/constant'
import { useGetCurrentMarketDayQuery } from '@ekenta/redux/store/services/livecommerceApi'
import { slugifyText } from '@ekenta/utils'
import SpinWheel from './spinwheel/SpinWheel'
import { truncate } from 'lodash'

const Rightside = () => {
  const result = useGetCurrentMarketDayQuery()
  const event = result?.isSuccess && result?.data ? result?.data.event : null
  const [isOpen, setIsOpen] = useState(false)

  const slugify = (text: string) => {
    return slugifyText(text.toLowerCase())
  }
  const handleToggle = (val: boolean) => {
    setIsOpen(val)
  }

  return (
    <Fragment>
      <RightSideContainerStyle>
        {/* <RightCardStyle color="rgba(255, 152, 0, 0.08)">
				<RefreshLink to={iKitchenCollectionUrl}>
					<div className="CardInner__Style">
						<SVG src={IkitchenSVG} />
						<h2>i-Kitchen</h2>
						<p>buyers can conveniently access delicious food options from their neighbors</p>
					</div>
				</RefreshLink>
			</RightCardStyle> */}
        {/* <RightCardStyle color="rgba(0, 188, 212, 0.08)">
				<RefreshLink to={savePlanetPathUrl}>
					<div className="CardInner__Style">
						<SVG src={SavePlanetSVG} />
						<h2>Save the planet</h2>
						<p>we recommend you use your products more or sell them, don&apos;t throw them away</p>
					</div>
				</RefreshLink>
			</RightCardStyle> */}
        <RightCardStyle color="rgba(114, 16, 255, 0.08)">
          <RefreshLink
            to={
              event
                ? `/market/${event.id}/${slugify(event.name)}/event`
                : `${allLivePathUrl}?_ss=market`
            }
          >
            <div className="CardInner__Style">
              <SVG src={MarketStreamSVG} />
              <h2>Market Day</h2>
              <p>Join our daily market day and enjoy amazing discount prices</p>
            </div>
          </RefreshLink>
        </RightCardStyle>
        <RightCardStyle color="rgba(76, 175, 80, 0.08)">
          <a className="HCard" role="button" onClick={() => handleToggle(true)}>
            <div className="CardInner__Style">
              <SVG src={SpinWheelSVG} />
              <h2>Spin Wheel</h2>
              <p>Win amazing prices when you spin the wheel</p>
            </div>
          </a>
        </RightCardStyle>
        <RightCardStyle color="rgba(255, 152, 0, 0.08)">
          <RefreshLink to="/live">
            <div className="HCard">
              <div className="CardInner__Style">
                <img className="live-img" src={ShoppingImg} alt="" />
                <h2>Qlason Live</h2>
                <p>
                  Watch sellers showcase their products and also buy on the live
                  video
                </p>
              </div>
            </div>
          </RefreshLink>
        </RightCardStyle>
        <RightCardStyle color="rgba(0, 188, 212, 0.08)">
          <a
            href="https://www.eyeshopper.net/"
            title="Eyeshopper"
            target="_blank"
            rel="noreferrer"
          >
            <div className="HCard">
              <div className="CardInner__Style">
                <img className="eye-img" src={EyeshopperSVG} alt="" />
                <h2>Eyeshopper</h2>
                <p>
                  Access vibrant markets without leaving your home. Eyeshopper
                  brings the market to your fingertips.
                </p>
              </div>
            </div>
          </a>
        </RightCardStyle>
      </RightSideContainerStyle>

      {/* Spinwheel */}
      <SpinWheel isOpen={isOpen} onClose={() => handleToggle(false)} />
    </Fragment>
  )
}

export default Rightside
