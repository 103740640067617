/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, memo } from 'react'
import {
  useTable,
  usePagination,
  useGlobalFilter,
  useFilters,
  useRowSelect,
  useSortBy,
} from 'react-table'
import classnames from 'classnames'
import { ReactComponent as FilterIcon } from '@ekenta/assets/icons/filter-icon.svg'
import styles from './DataTable.module.scss'
//import { DataTablePropType } from '../../interfaces'
import Table from '../Table/Table'
//import Loader from "../Loader/Loader";
import { Card, Form } from 'react-bootstrap'
import Field from '../InputField/Field/Field'
import { FiChevronDown, FiSearch, FiX } from 'react-icons/fi'
import styled from 'styled-components'
//import Checkbox from "../InputField/Checkbox/Checkbox";
import TableSkeleton from './TableSkeleton'
import Dropdown from '../Dropdown/Dropdown3'

// eslint-disable-next-line react/display-name
const IndeterminateCheckbox = React.forwardRef(
  ({ indeterminate, ...rest }: any, ref: any) => {
    const defaultRef = React.useRef()
    const resolvedRef = ref || defaultRef

    React.useEffect(() => {
      if (resolvedRef) {
        resolvedRef.current.indeterminate = indeterminate
      }
    }, [resolvedRef, indeterminate])

    return (
      <>
        <label className="Checkbox widthMinimize" style={{ cursor: 'pointer' }}>
          <Form.Check
            ref={resolvedRef}
            className="Checkbox__input"
            type="checkbox"
            {...rest}
          />
          <span className="Checkbox__label"></span>
        </label>
      </>
    )
  }
)

const DataTable = ({
  theme,
  data,
  columns,
  loading,
  showChecklist = true,
  isSelectable = true,
  className,
  cardClassName,
  thClassName,
  trClassName,
  tdClassName,
  theadClassName,
  renderEndTable,
  renderPagnation,
  renderBottom,
  renderTop,
  hasLink,
  align,
  showSearch,
  searchValue,
  onSearch,
  showFilter,
  filterMenu,
  activeFilter,
  clearFilter,
  onKeyUp,
  shadow,
  onSelectedRow,
  autoResetSelectedRows = false,
}: any) => {
  const prevSelectedRowsRef = React.useRef<any>([])

  const tableHeadStyle = classnames({
    [styles.TheadWith__Bg]: showSearch,
  })

  const _columns = React.useMemo(() => [...columns], [])

  const _data = React.useMemo(() => [...data], [data])

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    selectedFlatRows,
  } = useTable(
    {
      columns: _columns,
      data: _data,
      autoResetRowState: true,
      autoResetSelectedRows: autoResetSelectedRows,
      // autoResetPage: false,
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination,
    useRowSelect,

    (hooks) => {
      if (showChecklist) {
        hooks.allColumns.push((columns) => [
          {
            id: 'selection',
            columnId: 'column-Selection',
            disableSortBy: true,
            Header: ({ getToggleAllRowsSelectedProps }: any) => (
              <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
            ),
            Cell: ({ row }: any) => {
              return (
                <IndeterminateCheckbox
                  type="checkbox"
                  {...row.getToggleRowSelectedProps()}
                  indeterminate={row.original.selected === 'indeterminate'}
                />
              )
            },
          },
          ...columns,
        ])
      }
    }
  )

  useEffect(() => {
    const selectedRowsData = selectedFlatRows.map((row) => row.original)
    if (selectedRowsData) {
      prevSelectedRowsRef.current = selectedRowsData
    }
  }, [selectedFlatRows, prevSelectedRowsRef.current])

  useEffect(() => {
    if (prevSelectedRowsRef.current && onSelectedRow) {
      onSelectedRow(prevSelectedRowsRef.current)
    }
  }, [prevSelectedRowsRef.current])

  const filterOutput = (
    <div style={{ width: 'auto', position: 'relative' }}>
      {activeFilter ? (
        <CloseButton onClick={clearFilter}>
          <FiX fontSize="1rem" />
        </CloseButton>
      ) : (
        ''
      )}
      <Dropdown
        placement="bottom"
        suffixIcon={<FiChevronDown />}
        render={
          <FilterStyle>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                flex: 1,
                gap: '10px',
              }}
            >
              <FilterIcon />
              <span>{activeFilter ? activeFilter : 'Filter'}</span>
            </div>
            <FiChevronDown />
          </FilterStyle>
        }
        menus={filterMenu}
        tooltipContent={'Filter'}
      />
    </div>
  )

  const tableOutput = (
    <Card
      className={classnames(
        cardClassName,
        styles.cardTable,
        shadow ? 'boxShadow' : ''
      )}
    >
      <Card.Body>
        {showSearch ? (
          <>
            <SearchBar>
              <div className="renderSearchWrapper__Box">
                <label className="renderSearchWrapper__Label"></label>
                <Field
                  id="search-field"
                  name="search"
                  size="small"
                  value={searchValue}
                  type="search"
                  disabled={loading}
                  placeholder="Search"
                  fieldClassName="renderSearchWrapper__Field"
                  onChange={!loading ? onSearch : () => console.log('')}
                  onKeyUp={onKeyUp}
                  autoComplete="off"
                  renderPrefix={<FiSearch />}
                />
              </div>
              {showFilter ? <>{filterOutput}</> : null}
            </SearchBar>
          </>
        ) : null}
        {renderTop && (
          <div className={styles.renderTop__Horizontal}>
            {renderTop}
            {showFilter && !showSearch ? <>{filterOutput}</> : null}
          </div>
        )}
        {loading ? (
          <TableSkeleton colums={10} />
        ) : (
          <>
            <Table
              simple={theme === 'simple'}
              className={className}
              {...getTableProps()}
            >
              <Table.Head
                className={classnames(tableHeadStyle, theadClassName)}
              >
                {headerGroups.map((headerGroup, index) => (
                  <Table.Row
                    {...headerGroup.getHeaderGroupProps()}
                    key={'id_' + index}
                  >
                    {headerGroup.headers.map((column, index) => {
                      return (
                        // eslint-disable-next-line react/jsx-key
                        <Table.Cell
                          {...column.getHeaderProps(
                            column.getSortByToggleProps()
                          )}
                          component="th"
                          className={classnames(thClassName, {
                            [styles.selection]: index === 0,
                            [styles.sortable]: column.canSort,
                          })}
                        >
                          {column.render('Header')}
                          {column.canSort && (
                            <span
                              className={classnames(styles.sort, {
                                [styles.sortDesc]: column.isSortedDesc,
                                [styles.sortAsc]: !column.isSortedDesc,
                              })}
                            ></span>
                          )}
                        </Table.Cell>
                      )
                    })}
                  </Table.Row>
                ))}
              </Table.Head>
              <Table.Body {...getTableBodyProps()}>
                {/* loading ? (
                  <Table.Row>
                    <Table.Cell colSpan="100%">
                      <div className={styles.loader}>
                        <Loader />
                      </div>
                    </Table.Cell>
                  </Table.Row>
                ) :  */}
                {data.length === 0 ? (
                  <Table.Row>
                    <Table.Cell colSpan="100%">
                      <div className={styles.noResults}>
                        No results to display.
                      </div>
                    </Table.Cell>
                  </Table.Row>
                ) : (
                  <>
                    {rows.map((row) => {
                      prepareRow(row)
                      return (
                        // eslint-disable-next-line react/jsx-key
                        <Table.Row
                          {...row.getRowProps()}
                          className={trClassName}
                          hasLink={hasLink}
                          style={{
                            backgroundColor: !row.isSelected
                              ? 'transparent'
                              : '#faedde8f',
                            border: 'none !important',
                          }}
                        >
                          {row.cells.map((cell) => {
                            return (
                              // eslint-disable-next-line react/jsx-key
                              <Table.Cell
                                {...cell.getCellProps()}
                                className={tdClassName}
                                component={'td'}
                                align={align}
                              >
                                {cell.render('Cell')}
                              </Table.Cell>
                            )
                          })}
                        </Table.Row>
                      )
                    })}
                  </>
                )}
              </Table.Body>
            </Table>

            <>{renderBottom && renderBottom}</>
            {renderPagnation && renderPagnation}
          </>
        )}
      </Card.Body>
    </Card>
  )

  return (
    <>
      {tableOutput}
      {renderEndTable && renderEndTable()}
    </>
  )
}

const SearchBar = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  gap: 10px;

  .renderSearchWrapper__Box {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: stretch;
    margin-bottom: 0.5rem;

    .renderSearchWrapper__Label {
      margin-right: 0.7rem;
      font-size: 13.5px;
      font-weight: 500;
    }

    .InputField__fieldContainer {
      span {
        padding: 8px 10px 10px 10px !important;
      }
    }
    .InputField {
      width: 100% !important;
      input {
        border-radius: 12px !important;
        background: var(--others-white, #fff) !important;
      }
    }

    .renderSearchWrapper__Field {
      z-index: 0;
      width: 100% !important;
    }

    .renderSearchButton {
      position: absolute;
      right: 10px;
      background-color: var(--eke-bg-primary-color);
      width: 25px !important;
      height: 25px;
      text-align: center;
      display: flex;
      justify-content: center;
      padding-left: 0;
      padding-right: 0;
      top: 18px;
      border-radius: 50% !important;

      svg {
        color: var(--eke-body-text-light-color);
      }
    }
  }
`

const FilterStyle = styled.button`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-width: 136px;
  height: auto;
  padding: 9.2px 20px;
  align-items: center;
  outline: none;
  flex-shrink: 0;
  border-radius: 12px;
  color: var(--greyscale-600, #757575);
  font-family: var(--eke-body-font-family);
  font-weight: var(--eke-font-weight-normal);
  font-size: 14px;
  line-height: 140%; /* 19.6px */
  letter-spacing: 0.2px;
  text-transform: capitalize;
  border: 1px solid var(--greyscale-300, #e0e0e0);
  background: var(--others-white, #fff);
  margin-bottom: 0.5rem;
`

const CloseButton = styled.button`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  right: -5%;
  width: 20px !important;
  height: 20px !important;
  top: -15%;
  z-index: 999;
  outline: none;
  border: none;
  border-radius: 50%;
  padding: 0 !important;
  color: #fff;
  background-color: black !important;
`

export default memo(DataTable)
