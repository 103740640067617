import React from 'react'

const SvgChat = ({
    width = "1rem",
    height = "1rem",
    className
}: {
    className?: string,
    width?: string;
    height?: string
}) => (
    <svg
        width={width}
        height={height}
        className={className}
        viewBox="0 0 20 19" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g id="Chat">
            <path id="Stroke 4" fillRule="evenodd" clipRule="evenodd" d="M15.8926 15.3916C13.3458 17.9387 9.57465 18.489 6.48852 17.0618C6.03293 16.8783 5.65941 16.7301 5.30431 16.7301C4.31524 16.7359 3.08414 17.695 2.4443 17.0559C1.80447 16.416 2.76422 15.1839 2.76422 14.1889C2.76422 13.8337 2.62185 13.4669 2.43844 13.0104C1.01053 9.92477 1.56159 6.15232 4.10839 3.60609C7.3595 0.353775 12.6415 0.353775 15.8926 3.60525C19.1496 6.86259 19.1438 12.1402 15.8926 15.3916Z" stroke="#F8880A" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path id="Stroke 11" d="M13.2825 9.84416H13.29" stroke="#F8880A" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
            <path id="Stroke 13" d="M9.94173 9.84416H9.94923" stroke="#F8880A" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
            <path id="Stroke 15" d="M6.60091 9.84416H6.60841" stroke="#F8880A" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
        </g>
    </svg>

)

export default SvgChat;