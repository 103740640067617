/* eslint-disable @typescript-eslint/no-explicit-any */

import React from 'react'
import Select from 'react-select'
import styled from 'styled-components'
import { Info } from '../icons'

interface CustomSelectPropsType {
  options: { label: string; value: string }[]
  value: { label: string; value: string } | { label: string; value: string }[]
  className: string
  name?: string
  errorClassName?: string
  isError?: boolean
  error?: string
  placeholder: string
  isLoading: boolean
  isDisabled?: boolean
  isClearable?: boolean
  isSearchable?: boolean
  reference?: any
  isMulti?: boolean
  noOptionsMessage: (obj: { inputValue: string }) => React.ReactNode | undefined
  onChanged: (value: string) => any
  onBlur: (e: React.FocusEvent<any, Element>) => void
}

const CustomSelect = ({
  isDisabled,
  name,
  reference,
  onChanged,
  onBlur,
  isClearable,
  isSearchable,
  noOptionsMessage,
  isLoading,
  placeholder,
  options,
  value,
  errorClassName,
  error,
  isError,
  className,
  isMulti = false,
}: CustomSelectPropsType) => {
  // const defaultValue = (options: { label: string; value: string }[], value: string) => {
  //   return options ? options.find((option: any) => option.value === parseInt(value)) : "";
  // }

  return (
    <div className={className}>
      <ContainerStyle>
        <Select
          name={name}
          ref={reference ? reference : null}
          theme={(theme) => ({
            ...theme,
            borderRadius: 8,
            paddingTop: 20,
            cursor: 'pointer',
            boxShadow: '0px 1px 2px 0px rgba(16, 24, 40, 0.05)',
            colors: {
              ...theme.colors,
              primary25: '#F8880A',
              primary: 'neutral5',
            },
          })}
          isClearable={isClearable}
          isDisabled={isDisabled}
          placeholder={placeholder}
          options={options}
          value={value}
          isMulti={isMulti}
          onChange={(value: any) => onChanged(value)}
          isLoading={isLoading}
          noOptionsMessage={noOptionsMessage}
          onBlur={onBlur}
          isSearchable={isSearchable}
          styles={{
            ...customStyles,
          }}
          // components={{
          //   IndicatorSeparator: null,
          // }}
        />
        {isError && (
          <span className={`${errorClassName}`}>
            {error}
            <Info className="" />
          </span>
        )}
      </ContainerStyle>
    </div>
  )
}

const ContainerStyle = styled.div`
  position: relative;

  &__Close {
    position: absolute;
    top: 20%;
    right: 8%;
    cursor: pointer;

    svg {
      fill: hsl(0, 0%, 80%);
    }
  }

  span {
    color: var(--eke-body-text-danger-color);
    display: flex;
    font-size: 0.75rem;
    gap: 0.2rem;
    margin: 0rem 0.4rem;
    margin-top: 1rem;

    svg {
      fill: var(--eke-bg-danger-color) !important;
    }

    width: 100%;
    padding: 0;
  }
`

const customStyles = {
  control: (provided: any, { isDisabled }: any) => ({
    ...provided,
    backgroundColor: isDisabled ? '#F9FAFB' : 'unset',
    fontSize: '14px',
    fontWeight: 500,
    fontFamily: 'Urbanist',
    height: '48px',
    cursor: 'pointer',
    color: '#000',
    borderColor: '#D0D5DD',
    '&:hover': {
      borderColor: '',
      color: '#FFF',
    },
    '&:focus': {
      borderColor: '#D0D5DD',
      color: '',
    },
  }),
  valueContainer: (provided: any) => ({
    ...provided,
    fontSize: '14px',
    fontWeight: 500,
    fontFamily: 'Urbanist',
    color: '#000',
  }),
  input: (provided: any) => ({
    ...provided,
    fontFamily: 'Urbanist',
  }),
  placeholder: (provided: any) => ({
    ...provided,
    fontFamily: 'Urbanist',
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '15px',
    color: '#9E9E9E !important',
    paddingLeft: '0',
  }),

  container: (provided: any) => ({
    ...provided,
    fontFamily: 'Urbanist',
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '15px',
    borderRadius: '5px',
    height: '40px',
  }),
  menu: (provided: any) => ({
    ...provided,
    maxHeight: '300px',
    background: 'white',
    fontWeight: 500,
    zIndex: 20000000000,
  }),
  dropdownIndicator: (provided: any) => ({
    ...provided,
    paddingRight: '15px',
  }),

  option: (styles: any, { isDisabled, isFocused, isSelected }: any) => ({
    ...styles,
    paddingLeft: '20px',
    backgroundColor: isDisabled
      ? undefined
      : isSelected
      ? '#F8880A'
      : isFocused
      ? '#FFE5C8'
      : undefined,
    cursor: isDisabled ? 'not-allowed' : 'pointer',
  }),
}

export default CustomSelect
