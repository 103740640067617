/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { Fragment, useState } from 'react'
import { Col } from 'react-bootstrap'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { useAppSelector } from '@ekenta/redux/store/hook'
import { useFetchAdminAllReturnRequestQuery } from '@ekenta/redux/store/services/adminSellerApi'
import { useFetchSellerReturnRequestQuery } from '@ekenta/redux/store/services/vendorProductApi'
import { checkIfAdmin } from '@ekenta/utils'
import {
  DataTable,
  HelmetHeader,
  PageTitle,
  Button,
  Pagination,
} from '@ekenta/components'
import { withDashboardLayout } from '@ekenta/hoc'
import { VscArrowSmallLeft } from 'react-icons/vsc'
import { DashboardPathUrl, DashboardSellers } from '@ekenta/routes/constant'
import moment from 'moment'

import './ReturnRequest.css'
import { SpinLoader } from '@ekenta/components/Spinner/SpinLoader'

const ReturnsRequested = () => {
  const { account, token } = useAppSelector(
    (state) => state.auth.authentication
  )
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const [pageno, setPageno] = useState(0)

  const isAdmin = account.user ? checkIfAdmin(token, account.user.role) : null
  const returnRequest = isAdmin
    ? useFetchAdminAllReturnRequestQuery(token)
    : useFetchSellerReturnRequestQuery(token)

  const columns = [
    {
      Header: 'Product',
      accessor: 'product',
      disableSortBy: true,
    },
    {
      Header: 'Price',
      accessor: 'price',
      disableSortBy: true,
    },
    {
      Header: 'Request Action',
      accessor: 'action',
      disableSortBy: true,
    },
    {
      Header: 'Reason',
      accessor: 'reason',
      disableSortBy: true,
    },
    {
      Header: 'Comment',
      accessor: 'comment',
      disableSortBy: true,
    },
    {
      Header: 'QTY',
      accessor: 'returned',
      disableSortBy: true,
    },
    {
      Header: 'Status',
      accessor: 'status',
      disableSortBy: true,
    },
    {
      Header: 'Date',
      accessor: 'date',
      disableSortBy: true,
    },

    {
      Header: 'Action',
      accessor: 'view',
      disableSortBy: true,
    },
    // ...actionCol
  ]

  const navBack = isAdmin ? DashboardSellers : DashboardPathUrl

  const recalibrate = (data: any) => {
    return data.length > 0
      ? data.map((items: any) => ({
          ...items,
          product: items.order_item.product.name,
          action: items.action,
          returned: items.quantity,
          comment: <span className="Comment_Text">{items.comment}</span>,
          price:
            'NGN ' + new Intl.NumberFormat().format(items.order_item.price),
          status: items.status,
          date: moment(items.created_at).format('MMM D, YYYY'),
          view: (
            <Link to={`/vendor/dashboard/returns-requested/${items.id}`}>
              <Button
                name="View"
                bgVariant="light"
                textVariant="secondary"
                className="py-2"
                shadow={true}
                type="button"
              />
            </Link>
          ),
        }))
      : []
  }

  return (
    <Fragment>
      <HelmetHeader
        title="Returns Request"
        description="Qlason Returns Requested Page"
      />

      <Col className=" col-lg-12 _listing_product_">
        <div className="mt-0">
          <Col className="AppEvents_col mx-1 mx-lg-0">
            <PageTitle
              title="Return Request"
              subTitle={
                <span
                  className="cursor-pointer d-flex w-auto align-items-center 
                    text-muted"
                  onClick={() => navigate(navBack)}
                >
                  <VscArrowSmallLeft />
                  Back to Dashboard
                </span>
              }
            />
          </Col>

          <Col className="mt-4 position-relative">
            {returnRequest?.isLoading ? (
              <SpinLoader size={2} variant="dark" />
            ) : (
              <DataTable
                theme="simple"
                data={
                  returnRequest?.isSuccess
                    ? recalibrate(returnRequest?.data?.data?.data)
                    : []
                }
                loading={returnRequest?.isLoading}
                isSelectable={true}
                showChecklist={false}
                columns={columns}
                showPagination={false}
                hasLink={true}
                thClassName="w"
                trClassName="w_Tr"
                tdClassName={`Row__col`}
                renderPagnation={
                  <>
                    {returnRequest?.data
                      ? returnRequest?.data?.total > 10 && (
                          <Pagination
                            pathname={pathname}
                            itemPerPage={returnRequest?.data?.per_page}
                            itemsLength={returnRequest?.data?.total}
                            currentPage={pageno}
                            callback={(value) => setPageno(value)}
                          />
                        )
                      : null}
                  </>
                }
              />
            )}

            <br />
          </Col>
        </div>
      </Col>
    </Fragment>
  )
}

export default withDashboardLayout(ReturnsRequested)
