const SvgInfo = ({
    width = "1rem",
    height = "1rem",
    className
}: {
    className?: string,
    width?: string;
    height?: string
}) => (
    <svg fill=""
        viewBox="0 0 16 16"
        width={width}
        height={height}
        className={className}
        xmlns="http://www.w3.org/2000/svg"
        aria-hidden="true" role="presentation"
        style={{ fontSize: '1rem', verticalAlign: '-0.175em' }}>
        <path d="M8 1a7 7 0 1 1 0 14A7 7 0 0 1 8 1Zm0 9.898a.602.602 0 1 0 0 1.204.602.602 0 0 0 0-1.204ZM8 3.9a.6.6 0 0 0-.6.6v5.14l.008.098A.6.6 0 0 0 8.6 9.641V4.5l-.008-.097A.6.6 0 0 0 8 3.9Z" fillRule="evenodd" />
    </svg>
)

export default SvgInfo;