import { CardBox } from '@ekenta/components';
import { abbrevateNumber } from '@ekenta/utils';
import React from 'react';
import CountUp from 'react-countup';

const Card = ({ name, value, icon }: { name: string; value: number; icon?: JSX.Element }) => {
  const [val, suffix] = abbrevateNumber(parseInt(value.toString()));

  return (
    <div className="UserManagement__Col">
      <CardBox className="card border-none">
        <div className="card-body">
          <div>
            <h5 className="userManagement__heading-title">{name}</h5>
            <h2 className="userManagement__heading-count display_lg_header">
              <CountUp
                start={0.0}
                end={parseInt(val.toString())} />
              <span>{suffix}</span>
            </h2>
          </div>
          <span className="userManagement__icon">
            {icon}
          </span>
        </div>
      </CardBox>
    </div>
  );
}

export default Card;