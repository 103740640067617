/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react'
import MainOrder from './mainOrder';
import EmptyOrder from '@ekenta/components/Box/EmptyOrder';
import { CustomAlert } from '@ekenta/components';
import OrderSkeletonLoader from '@ekenta/components/SkeletonLoader/Account/OrderSkeletonLoader';

interface PendingOrderPropsType {
    isLoading: boolean;
    isError: boolean;
    isSuccess: boolean;
    data: any;
}

const PendingOrder = (props: PendingOrderPropsType) => {
    const { data, isLoading, isError, isSuccess } = props;

    return (
        <div className="w-100">
            {isLoading ? (
                <OrderSkeletonLoader />
            ) : (
                <>
                    {isError ? (
                        <CustomAlert
                            variant="danger"
                            show={true}
                            message={"Something went wrong, Try again"}
                        />
                    ) : null}

                    <div>
                        {isSuccess && data ? (
                            data.pendingOrders.length > 0 ? (
                                data.pendingOrders.map((item: any, index: any) => {
                                    return (
                                        <MainOrder key={index} itemData={item} />
                                    );
                                })
                            ) : (
                                <EmptyOrder orderType="Completed" />
                            )
                        ) : null}
                    </div>
                </>
            )}
        </div>

    )
}

export default PendingOrder;