/* eslint-disable @typescript-eslint/no-explicit-any */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { SavedItemInitialState } from "../initialState";

export const savedItemSlice = createSlice({
    name: 'savedItem',
    initialState:SavedItemInitialState,
    reducers: {
        addToSaveItem:(state,action: PayloadAction<{savedItems: any[]}>) => {
           state.savedItems = action.payload.savedItems;
        },
       
       removeSavedItem:(state) => {
            state.savedItems = [];
       },

    }
})

export const { addToSaveItem, removeSavedItem} = savedItemSlice.actions;

export  default savedItemSlice.reducer;
