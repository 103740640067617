import React, { memo, useEffect, useState } from 'react';
import { getIn, useFormik, useFormikContext } from 'formik';
import * as Yup from 'yup';
import { Form, Row } from 'react-bootstrap';
import { toast } from "react-toastify";

import { VscArrowSmallRight } from 'react-icons/vsc';
import { useRegisterUserMutation } from '@ekenta/redux/store/services/authenticationApi';
import SignInLink from '../SignInLink';
import { Button, Field } from '@ekenta/components';
import { AlertNote } from '../Reusable';
import { OnboardingContentWidth } from '../../style';
import { MyFormValues } from '../OnboardingForm/types';
// Shape of form values



const lowercaseRegex = /(?=.*[a-z])/;
const uppercaseRegex = /(?=.*[A-Z])/;
const specialRegex = /(?=.*[!@#$%^&*])/;
const numericRegex = /(?=.*[0-9])/;
const spaceRegex = /^(.*)?\S+(.*)?$/;


function AccountDetails({
    nextPage }: { nextPage: () => void }) {
    const {
        values,
        errors,
        setFieldValue,
        handleChange,
        handleBlur,
    } = useFormikContext<MyFormValues>();

    const [registerUser, { data, isLoading, isError, error, isSuccess }]
        = useRegisterUserMutation();


    const validation = () => Yup.object().shape({
        firstname: Yup.string()
            .required('Firstname is required'),
        lastname: Yup.string()
            .required('Lastname is required'),
        username: Yup.string()
            .required('Username is required'),
        email: Yup.string()
            .email('Invalid email address')
            .required('Email field is required'),
        password: Yup.string()
            .matches(lowercaseRegex, 'One lowercase required')
            .matches(uppercaseRegex, 'One upper required')
            .matches(specialRegex, 'One special character')
            .matches(numericRegex, 'One number required')
            .matches(spaceRegex, 'Space not required')
            .min(6, 'Minimum 6 characters required')
            .required('Password field is required'),
        confirmpassword: Yup.string()
            .required('Confirm Password field is required')
            .oneOf([Yup.ref('password'), null], 'Passwords must match'),
    });

    const [initialValues, setInitialValues] = useState({
        firstname: '',
        lastname: '',
        username: '',
        email: '',
        password: '',
        confirmpassword: '',
    })



    const formik = useFormik({
        initialValues: initialValues,
        enableReinitialize: true,
        validationSchema: validation(),
        onSubmit: values => {
            const objData = {
                username: values.username.toLowerCase(),
                first_name: values.firstname,
                last_name: values.lastname,
                email: values.email.toLowerCase(),
                password: values.password,
                password_confirmation: values.confirmpassword
            };
            registerUser(objData);
        }
    });

    useEffect(() => {
        if (values) {
            setInitialValues({
                firstname: values.personal_detail.firstname,
                lastname: values.personal_detail.lastname,
                username: values.personal_detail.username,
                email: values.personal_detail.email,
                password: values.personal_detail.password,
                confirmpassword: values.personal_detail.confirmpassword,

            })
        }
    }, []);

    useEffect(() => {
        if (formik.values) {
            setFieldValue('personal_detail.firstname', formik.values.firstname)
            setFieldValue('personal_detail.lastname', formik.values.lastname)
            setFieldValue('personal_detail.username', formik.values.username)
            setFieldValue('personal_detail.email', formik.values.email)
            setFieldValue('personal_detail.password', formik.values.password)
            setFieldValue('personal_detail.confirmpassword', formik.values.confirmpassword)
        }

    }, [formik.values])


    useEffect(() => {
        if (isSuccess) {
            setFieldValue('token', data.token);
            setFieldValue('user_id', data.user.id);
            nextPage();
        }

        if (isError && error.status === 'FETCH_ERROR') {
            toast.error(`Something went wrong`, {
                toastId: "error-onboarding-status-register-id-toast-error",
            });
        }

        if (isError && error.status === 401) {
            mapErrors(error.data.errors);
        }


        if (isError && error.status === 400) {
            toast.error(`Something went wrong`, {
                toastId: "error-onboarding-400-register-id-toast-error",
            });
        }

    }, [isSuccess, isError, error])

    function mapErrors(errors: string[]) {
        for (const [key, value] of Object.entries(errors)) {
            if (key === "email") {
                toast.error(`${value[0]}`, {
                    toastId: 'onboard-signup-email-toast-error',
                });
            }

            if (key === "username") {
                toast.error(`${value[0]}`, {
                    toastId: 'onboard-signup-id-username-error',
                });
            }
        }

    }


    return (

        <OnboardingContentWidth className="seller_account_details_form_col">
            <div className="_top_header">
                <h5>Personal Account Details</h5>
                <p>Complete the form below to become a seller</p>
            </div>
            {/* <Col lg={8} className=""> */}
            <div>
                <div className=" mt-4">
                    <Form.Group className="mb-3 position-relative">
                        <Field
                            id="firstname"
                            name="firstname"
                            label=" First Name "
                            disabled={isLoading}
                            type="text"
                            required
                            size="large"
                            childOutsideLabel={false}
                            placeholder="e.g. John"
                            fieldClassName=""
                            autoComplete="off"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.firstname}
                            isError={formik.touched.firstname && formik.errors.firstname ? true : false}
                            error={formik.errors.firstname}
                        />
                    </Form.Group>
                    <Form.Group className="mb-3 position-relative">
                        <Field
                            id="lastname"
                            name="lastname"
                            label="Last Name"
                            disabled={isLoading}
                            type="text"
                            required
                            size="large"
                            childOutsideLabel={false}
                            placeholder="e.g. Doe"
                            fieldClassName=""
                            autoComplete="off"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.lastname}
                            isError={formik.touched.lastname && formik.errors.lastname ? true : false}
                            error={formik.errors.lastname}
                        />
                    </Form.Group>
                    <Form.Group className="mb-3 position-relative">
                        <Field
                            id="username"
                            name="username"
                            label="User Name"
                            disabled={isLoading}
                            type="text"
                            required
                            size="large"
                            childOutsideLabel={false}
                            placeholder="e.g. Doejohn"
                            fieldClassName=""
                            autoComplete="off"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.username}
                            isError={formik.touched.username && formik.errors.username ? true : false}
                            error={formik.errors.username}
                        />
                    </Form.Group>

                    <Form.Group className="mb-3 position-relative">
                        <Field
                            id="email"
                            name="email"
                            label="Email Address"
                            disabled={isLoading}
                            type="email"
                            required
                            size="large"
                            childOutsideLabel={false}
                            placeholder="e.g. email@gmail.com"
                            fieldClassName=""
                            autoComplete="off"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.email}
                            isError={formik.touched.email && formik.errors.email ? true : false}
                            error={formik.errors.email}
                        />
                    </Form.Group>
                    <Form.Group className="mb-3 position-relative">
                        <Field
                            id="phone-number"
                            name="personal_detail.phonenumber"
                            label="Phone Number"
                            disabled={isLoading}
                            type="number"
                            required
                            childOutsideLabel={false}
                            placeholder="e.g. +234805282837924"
                            numberFieldPrefix={"+234"}
                            autoComplete="off"
                            onChange={(e) => {
                                setFieldValue("personal_detail.phonenumber", e.formattedValue)
                            }}
                            value={values.personal_detail.phonenumber}
                            isError={values.personal_detail.phonenumber && getIn(errors, 'personal_detail.phonenumber') ? true : false}
                            error={getIn(errors, 'personal_detail.phonenumber')}
                        />
                    </Form.Group>

                    <Form.Group className="mb-3 position-relative">
                        <Field
                            id="referredby"
                            name="personal_detail.referedby"
                            label="Referred by"
                            disabled={isLoading}
                            type="text"
                            size="large"
                            childOutsideLabel={false}
                            placeholder="e.g. Michael"
                            fieldClassName=""
                            autoComplete="off"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.personal_detail.referedby}
                            isError={getIn(errors, 'personal_detail.referedby') ? true : false}
                            error={getIn(errors, 'personal_detail.referedby')}
                        />
                    </Form.Group>
                    <Form.Group className="mb-3 position-relative">
                        <Field
                            id="password"
                            name="password"
                            label="Password"
                            disabled={isLoading}
                            type="password"
                            required
                            size="large"
                            childOutsideLabel={false}
                            placeholder="*********"
                            fieldClassName=""
                            autoComplete="off"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.password}
                            isError={formik.touched.password && formik.errors.password ? true : false}
                            error={formik.errors.password}
                        />
                        <AlertNote
                            text="At least 6 characters containing a capital & lower letter, special charatcer and a numeric character"
                        />
                    </Form.Group>
                    <Form.Group as={Row} className="mb-3 position-relative">
                        <Field
                            id="confirmpassword"
                            name="confirmpassword"
                            label="Confirm Password"
                            disabled={isLoading}
                            type="password"
                            required
                            size="large"
                            childOutsideLabel={false}
                            placeholder="*********"
                            fieldClassName=""
                            autoComplete="off"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.confirmpassword}
                            isError={formik.touched.confirmpassword && formik.errors.confirmpassword ? true : false}
                            error={formik.errors.confirmpassword}
                        />
                    </Form.Group>



                    <div className="mt-3">
                        {/* <Button disabled={
                            !formik.values.firstname ||
                            !formik.values.lastname ||
                            !formik.values.username ||
                            !formik.values.email ||
                            !formik.values.password ||
                            !formik.values.confirmpassword ||
                            !values.personal_detail.phonenumber ||
                            getIn(formik.errors, 'firstname') ||
                            getIn(formik.errors, 'lastname') ||
                            getIn(formik.errors, 'username') ||
                            getIn(formik.errors, 'email') ||
                            getIn(formik.errors, 'password') ||
                            getIn(formik.errors, 'confirmpassword') ||
                            getIn(errors, 'personal_detail.phonenumber')
                        }

                            className="_button_A_"
                            onClick={() => formik.handleSubmit()}>
                            {isLoading ? <>Loading...</> :
                                <>
                                    <span>Proceed</span>
                                    <VscArrowSmallRight />
                                </>
                            }
                        </Button> */}
                        <Button
                            name="Proceed"
                            textVariant="light"
                            width="w-100"
                            className="PageStep__Btn"
                            disabled={
                                !formik.values.firstname ||
                                !formik.values.lastname ||
                                !formik.values.username ||
                                !formik.values.email ||
                                !formik.values.password ||
                                !formik.values.confirmpassword ||
                                !values.personal_detail.phonenumber ||
                                getIn(formik.errors, 'firstname') ||
                                getIn(formik.errors, 'lastname') ||
                                getIn(formik.errors, 'username') ||
                                getIn(formik.errors, 'email') ||
                                getIn(formik.errors, 'password') ||
                                getIn(formik.errors, 'confirmpassword') ||
                                getIn(errors, 'personal_detail.phonenumber')
                            }
                            isLoadingText="Proceed"
                            isLoading={isLoading}
                            shadow={false}
                            suffixIcon={<VscArrowSmallRight />}
                            type="submit"
                            onClick={() => formik.handleSubmit()}
                        />
                    </div>
                    <SignInLink />
                </div>
            </div>
        </OnboardingContentWidth>
    )
}


export default memo(AccountDetails);