/* eslint-disable @typescript-eslint/no-explicit-any */
import { Label, Radio, Typography } from '@ekenta/components'
import React from 'react'
import "./ListOrderItem.scss";

const ListOrderItem = (props: any) => {
    const { item, onSelect, isLoading, orderNo } = props;
    return (
        <div className="ListItem_FrameWrapper">
            <Label className="ListItem__Flex"
                htmlFor={item.id}
                aria-label={`Select ${item.product.name} order`}
                data-title={item.product.name}
                data-price={item.product.price}
                data-condition={item.product.condition}>

                <Radio
                    id={item.id}
                    name="selectOrderItem"
                    className="ListItemRadio mt-2"
                    variant="primary"
                    disabled={isLoading}
                    onChange={() =>
                        onSelect({
                            orderItemId: item.id,
                            quantity: item.quantity
                        })}
                />
                <div className="ListIBox__OrderItem">
                    <img
                        src={item.product.images ? item.product.images[0].image_url : ""}
                        width="80"
                        height="80"
                        alt={item.product.name}
                    />
                    <div className="ListItem_TypographyDiv">
                        <Typography
                            as="h3"
                            size="medium"
                            color="dark"
                            className=""
                            uiText>
                            {item.product.name}
                        </Typography>
                        <div style={{ marginTop: "15px" }}>
                            <div className="ListItem_TypoDiv">
                                <Typography
                                    as="span"
                                    size="small"
                                    color="secondary"
                                    className="me-2"
                                    uiText>
                                    Order No:
                                </Typography>
                                <Typography
                                    as="span"
                                    size="small"
                                    color="secondary"
                                    className="orderNO_Typo"
                                    uiText>
                                    {orderNo}
                                </Typography>
                            </div>
                        </div>
                    </div>
                </div>
            </Label>
        </div>
    )
}

export default ListOrderItem;