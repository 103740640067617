import { device } from '@ekenta/utils/devices'
import styled from 'styled-components'

export const FooterContainerStyle = styled.div`
  padding-top: 8rem;
  width: 100%;
  height: auto;
`

export const FooterInnerContainerStyle = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  min-height: 500px;
  padding: 0 0.8em;
  background: var(--eke-color-secondary);

  @media ${device.md} {
    flex-direction: row;
    padding: 0 3em;
  }
`

export const FooterInnerWrapperStyle = styled.div`
  width: 100%;
  height: 100%;
  max-width: var(--eke-container-maxWidth);

  @media ${device.lg} {
    width: 90%;
    margin: 0 auto;
  }
`

export const FooterTopChildStyle = styled.div`
  position: relative;
  width: 100%;
  height: 301px;
  margin-top: -200px;
  border-radius: 12px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  background: var(--eke-color-white);
  overflow: hidden;
  box-shadow: 0px 20px 100px 0px rgba(4, 6, 15, 0.08) !important;

  h1 {
    color: #212121;
    font-size: 30px;
    font-family: var(--eke-body-font-family);
    font-weight: var(--eke-font-weight-bold);
    line-height: 120%;
  }

  p {
    display: block;
    width: 100%;
    color: #616161;
    text-align: center;
    font-size: 17px;
    font-family: var(--eke-body-font-family);
    font-weight: var(--eke-font-weight-normal);
    line-height: 140%;
    letter-spacing: 0.2px;

    @media ${device.md} {
      width: 485px;
    }
  }

  svg.shape1 {
    position: absolute;
    top: 8%;
    left: 15%;
    width: 25px;

    @media ${device.lg} {
      top: 55%;
      left: 20%;
      width: auto;
    }
  }

  svg.shape2 {
    position: absolute;
    top: 8%;
    left: 28%;
    width: 25px;

    @media ${device.lg} {
      top: 20%;
      left: 28%;
      width: auto;
    }
  }

  svg.shape3 {
    position: absolute;
    top: 15%;
    left: 2%;
    width: 25px;

    @media ${device.lg} {
      top: 25%;
      left: 12%;
      width: auto;
    }
  }

  svg.shape4 {
    display: none;
    position: absolute;
    bottom: -10%;
    left: -10%;

    @media ${device.lg} {
      display: block;
      left: 0%;
      bottom: 10%;
    }
  }

  svg.shape-right5 {
    position: absolute;
    top: 1%;
    right: 0%;
    bottom: 0;
    width: 25px;
  }

  svg.shape-right4 {
    position: absolute;
    top: 15%;
    right: 27%;
    width: 25px;

    @media ${device.lg} {
      top: 15%;
      right: 27%;
      width: 1.3em;
    }
  }

  svg.shape-right6 {
    display: none;
    position: absolute;
    top: 15%;
    right: -15%;

    @media ${device.lg} {
      display: block;
      top: 15%;
      right: 0%;
    }
  }

  svg.shape-right7 {
    display: none;
    position: absolute;
    bottom: 30%;
    right: 10%;

    @media ${device.lg} {
      display: block;
    }
  }

  .NewsLetterField__Wrap {
    position: relative;
    width: 80%;

    svg {
      stroke: #424242 !important;
    }

    .Newsletter__FieldWrap {
      display: flex;
      width: 100%;
      height: 48px;
      padding: 0px 6px 0px 24px !important;
      padding-left: 35px !important;
      padding-right: 35px !important;
      align-items: center;
      flex-shrink: 0;
      border-radius: 30px;
      border: 1px solid #eee;
      background: #fff8f0;
      font-size: 14px;
      color: var(--eke-color-dark);
      font-family: var(--eke-body-font-family);
      font-weight: var(--eke-font-weight-normal);
      line-height: 140%;
      letter-spacing: 0.2px;
      box-shadow: none !important;

      &::placeholder {
        color: #424242;
      }
    }

    .NewsletterField__Button {
      position: absolute;
      top: 10.8%;
      right: 0;

      button {
        padding: 9.172px 22.931px !important;
        font-size: 14px;
        color: var(--eke-color-white);
        font-family: var(--eke-body-font-family);
        line-height: 140%;
        letter-spacing: 0.2px;

        span {
          font-weight: var(--eke-font-weight-bold) !important;
        }
      }
    }
  }

  @media ${device.md} {
    .NewsLetterField__Wrap {
      width: 60%;
    }
  }

  @media ${device.lg} {
    width: 100%;
    margin-top: -300px;

    svg.shape-right5 {
      bottom: 18%;
      right: 18%;
      width: auto;
    }

    .NewsLetterField__Wrap {
      width: var(--eke-color-success-500);
    }
  }

  @media ${device.xl} {
    width: 100%;
    margin-top: -300px;

    .NewsLetterField__Wrap {
      width: 40%;
    }
  }
`
export const FooterBottomChildStyle = styled.div`
  margin-top: 65px;

  .FooterMenuDiv__Style {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    gap: 2.3em;

    .FooterSub__Style {
      min-width: 11%;
    }

    /* .FooterSubMenu__Style:nth-child(1){
			order: 4;
		} */

    .FooterSubMenu__Style:nth-child(2) {
      order: 1;
      @media ${device.lg} {
        order: 0;
      }
    }

    .FooterSubMenu__Style:nth-child(3) {
      order: 2;
      @media ${device.lg} {
        order: 0;
      }
    }

    .FooterSubMenu__Style:nth-child(4) {
      order: 3;

      @media ${device.lg} {
        order: 0;
      }
    }

    .FooterSubMenu__Style {
      width: 100%;
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;
      gap: 0.8em;

      p.description_Style {
        color: var(--color-text, rgba(255, 255, 255, 0.8));
        font-size: 14.5px;
        line-height: 140%;
        width: 309px;
        text-align: center;
        display: block;
        letter-spacing: 0.2px;
        font-family: var(--eke-body-font-family);
        font-weight: var(--eke-font-weight-normal) !important;

        @media ${device.lg} {
          text-align: left;
        }
      }

      ul {
        list-style: none;
        padding: 0;
        margin: 0;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        li {
          h5 {
            color: var(--eke-color-white);
            font-size: 17px;
            font-family: var(--eke-body-font-family);
            font-weight: var(--eke-font-weight-bold) !important;
            line-height: 120% !important;
          }

          a {
            display: flex;
            align-items: flex-start;
            text-decoration: none;
            color: var(--color-text, rgba(255, 255, 255, 0.8));
            font-size: 14px;
            font-family: var(--eke-body-font-family);
            font-weight: var(--eke-font-weight-normal) !important;
            line-height: 39.5px !important;
            letter-spacing: 0.2px;
            gap: 0.5em;

            &:hover {
              opacity: 0.8;
            }
          }
        }

        .withIcon_Style {
          .listMenu_Icon {
            display: flex;
            width: 37px;
            height: 37px;
            border-radius: 30px;
            justify-content: center;
            align-items: center;
            background: var(--eke-color-white);

            svg {
              stroke: var(--eke-color-primary) !important;
              width: 21px !important ;
              height: 21px !important;
            }
          }

          .listMenu_Style {
            display: flex;
            align-items: flex-start;
            flex-direction: column;
            font-size: 14px;
            font-family: var(--eke-body-font-family);
            letter-spacing: 0.2;
            line-height: 39.5px;

            span:first-child {
              color: var(--color-text, rgba(255, 255, 255, 0.8));
              font-weight: var(--eke-font-weight-normal) !important;
              line-height: 1;
            }

            span:nth-child(2) {
              color: var(--eke-color-white);
              font-weight: var(--eke-font-weight-bold) !important;
            }
          }
        }
      }

      @media ${device.lg} {
        width: auto;
        align-items: flex-start;
        ul {
          width: 150px;
          align-items: flex-start;
          justify-content: center;
        }
      }
    }

    .FooterSubApp_Down__Style {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      text-align: center;

      h5 {
        color: var(--eke-color-white);
        font-size: 17px;
        font-family: var(--eke-body-font-family);
        font-weight: var(--eke-font-weight-bold) !important;
        line-height: 100%;
        margin-bottom: 10px;
      }

      p {
        color: var(--color-text, rgba(255, 255, 255, 0.8));
        font-size: 14.5px;
        line-height: 140%;
        letter-spacing: 0.2px;
        font-family: var(--eke-body-font-family);
        font-weight: var(--eke-font-weight-normal) !important;
      }

      .FooterDownloadApp_Store {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 0.8em;
      }

      @media ${device.lg} {
        width: auto;
        margin-left: 25px;
        text-align: left;
        justify-content: flex-start;

        .FooterDownloadApp_Store {
        }
      }
    }

    @media ${device.lg} {
      flex-direction: row;
    }
  }

  .FooterYear_Reserved {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 20px 0;

    span {
      color: var(--color-text, rgba(255, 255, 255, 0.8));
      text-align: center;
      font-size: 15px;
      font-family: var(--eke-body-font-family);
      font-weight: var(--eke-font-weight-semibold) !important;
      line-height: 140%;
      letter-spacing: 0.2px;
    }

    @media ${device.lg} {
      position: absolute;
      bottom: 7%;
      left: 0;
      margin: 0;
    }
  }
`

export const FooterLogoStyle = styled.div`
  a {
    display: flex;
    align-items: center;
    gap: 10px;
    flex: 0.3;
    text-decoration: none !important;

    .LogoText {
      h3 {
        margin: 0;
        padding: 0;
        line-height: 35px;
        font-size: 2.204rem;
        color: var(--eke-color-white);
        font-family: var(--eke-body-font-family);
        font-weight: var(--eke-font-weight-extrabold) !important;
      }
      p {
        margin: 0;
        font-family: var(--eke-body-font-family);
        font-size: 0.56175rem;
        color: var(--eke-color-white);
        font-weight: var(--eke-font-weight-medium) !important;
      }
    }
  }
`

export const FooterSocialStyle = styled.div`
  display: flex;
  align-items: center;
  gap: 0.8em;

  span {
    display: flex;
    width: 48px !important;
    height: 48px !important;
    justify-content: center;
    align-items: center;
    border-radius: 30px;
    text-decoration: none;
    background: var(--eke-color-white) !important;
  }
`
