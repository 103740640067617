/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { Fragment, Suspense, useEffect, useState } from 'react';
import * as Yup from 'yup';
import styled from 'styled-components';
import { getIn, useFormik } from 'formik';
import { useNavigate } from 'react-router-dom';
import { Switch } from "@mui/material";
import { FiEdit3, FiEye, FiTrash2, FiX } from 'react-icons/fi';
import { VscArrowSmallLeft } from 'react-icons/vsc';
import { Button, CustomAlert, CustomModal, Field, FileUploader, HelmetHeader, Label, Loader, Overlay, PageTitle } from '@ekenta/components';
import { AppBoard } from '../../common/styles';
import { PageSubTitle } from '../../common/Resuable';
import { DashboardSellers } from '@ekenta/routes/constant';
import { ButtonGroupStyle } from '../../common/common';
//import { useAppSelector } from '@ekenta/redux/store/hook';
import { withDashboardLayout } from '@ekenta/hoc';
import { copyToClipboard, readFileAsDataURL, showToastify } from '@ekenta/utils';
import BannerCard from './common/BannerCard';
import EmptyCard from '../../common/EmptyCard';
import CustomSelect from '@ekenta/components/Select/CustomSelect';
import { useAppSelector } from '@ekenta/redux/store/hook';
import { useCreateBannerMutation, useDeleteBannerMutation, useFetchAllBannerQuery, useUpdateBannerMutation } from '@ekenta/redux/store/services/adminSellerApi';
import { device } from '@ekenta/utils/devices';


const ManageBanner = () => {
  const navigate = useNavigate();
  const { token } = useAppSelector((state) => state.auth.authentication);
  const [modal, setModal] = useState(false);
  const [preview, setPreview] = useState({
    show: false,
    url: ""
  });
  const [isEdit, setIsEdit] = useState(false);
  const [isCopied, setIsCopied] = useState('Copy');
  const [createBanner, result] = useCreateBannerMutation();
  const [updateBanner, updateResult] = useUpdateBannerMutation();
  const [deleteBanner, deleteResult] = useDeleteBannerMutation();
  const allBanner = useFetchAllBannerQuery(token);




  const validationSchema = Yup.object().shape({
    banner_id: Yup.string(),
    name: Yup.string().required('Name is required'),
    bannerUrl: Yup.string().required('Banner Url is required'),
    tag: Yup.object({
      label: Yup.string(),
      value: Yup.string().required('Tag is required'),
    }).required('Tag is required'),
    imageFile: Yup.array()
      .when('bannerSrc', {
        is: (bannerSrc: string) => !bannerSrc, // Validate only if bannerSrc is falsy (empty)
        then: Yup.array()
          .of(
            Yup.object().shape({
              file: Yup.array(),
            })
          )
          .compact((v: any) => !v.file)
          .required('Banner image is required')
          .min(1, '*Must upload maximum of (1) quality product images')
          .max(1, '*Must upload maximum of (1) quality product images'),
        otherwise: Yup.array(), // No validation when bannerSrc is not empty
      }),
    bannerSrc: Yup.string(),
    slideNumber: Yup.number()
      .min(1, 'Slider Number minimum is 1')
      .required('Slider Number is required'),
  });

  const formik = useFormik({
    initialValues: {
      banner_id: '',
      name: '',
      bannerUrl: '',
      tag: {
        label: '',
        value: ''
      },
      slideNumber: 1,
      imageFile: [],
      bannerSrc: '',
      is_active: false,
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      const formData = new FormData();
      formData.append("title", values.name);
      formData.append("url", values.bannerUrl);
      formData.append("slide_number", values.slideNumber.toString());
      formData.append("tag", values.tag.value);
      formData.append("is_active", `${values.is_active}`);
      if (values.imageFile.length > 0) {
        values.imageFile.forEach((file: any) => {
          if (file) {
            formData.append("image", file);
          }
        });
      }

      const data = {
        data: formData,
        token: token
      }
      const id = values.banner_id;

      onSubmit(id, data, isEdit);
    },
  });

  // BOTH CREATE AND UPDATE API RESPONSE
  const onSubmit = async (id: string, data: any, isEdit: boolean) => {
    try {
      const result = isEdit ? await updateBanner({ ...data, id }) : await createBanner(data);
      if (result?.data?.status === true) {
        const data = result?.data;
        handleOnClick(false);
        showToastify(`${data?.message}`, `sub-banner`);
      } else if (result?.error?.status === "FETCH_ERROR") {
        showToastify(`Something went wrong`, `FETCH_ERROR`, true);
      } else {
        showToastify(`${result?.error?.data?.message}`, `ERROR`, true);
      }
    } catch (error) {
      showToastify(`Something went wrong`, `TERROR`, true);
    }
  };

  useEffect(() => {
    if (formik.values.imageFile.length > 0) {
      const file = formik.values.imageFile[0];
      readFileAsDataURL(file)
        .then((dataURL) => {
          // Handle the dataURL here, for example, set it in your formik state.
          formik.setFieldValue('bannerSrc', dataURL);
        })
        .catch((error) => {
          console.error('Error reading file:', error);
        });
    }
  }, [formik.values.imageFile]);


  // TOGGLE BANNER MODAL HANDLE FUNCTION
  const handleOnClick = (open: boolean) => {
    if (open === false) {
      formik.resetForm();
    }
    setIsEdit(false);
    setModal(open);
  }

  // PREVIEW BANNER FUNCTION
  const handlePreview = (imageUrl: string, show: boolean) => {
    setPreview(() => ({
      url: imageUrl,
      show: show
    }))
  }

  // EDIT BANNER FUNCTION
  const handleEdit = (item: any, open: boolean) => {
    setIsEdit(true);
    formik.setValues({
      ...formik.values,
      banner_id: item.id,
      name: item.title,
      bannerUrl: item.url,
      bannerSrc: item.image_url,
      slideNumber: item.slide_number,
      imageFile: [],
      tag: defaultValue(options, item.tag),
      is_active: item.is_active === 1 ? true : false
    })
    setModal(open);
  }

  // DELETE BANNER API FUNCTION
  const handleDelete = async (id: string) => {
    try {
      if (!id) return null;

      const result = await deleteBanner({ id, token });
      if (result?.data?.status === true) {
        const data = result?.data;
        handleOnClick(false);
        showToastify(`${data?.message}`, `delete-banner`);
      } else if (result?.error?.status === "FETCH_ERROR") {
        showToastify(`Something went wrong`, `DFETCH_ERROR`, true);
      } else {
        showToastify(`${result?.error?.data?.message}`, `ERROR`, true);
      }
    } catch (error) {
      showToastify(`Something went wrong`, `DERROR`, true);
    }
  }



  const defaultValue = (options: any, val: string) => {
    return options.find((option: any) => option.value === val) || null;
  };

  const clearImgSrc = () => {
    formik.setFieldValue('bannerSrc', '');
    formik.setFieldValue('imageFile', []);
  }


  const handleCopyClick = (file: string) => {
    copyToClipboard(file)
      .then((res: string) => {
        setIsCopied(res);
        // Reset the message back to "Copy" after a delay
        setTimeout(() => {
          setIsCopied('Copy');
        }, 2000); // 2000 milliseconds (2 seconds) delay
      })
      .catch((error: any) => {
        console.error('Error copying to clipboard:', error);
      });
  };

  const options = [
    {
      label: 'General     (712x384)px',
      value: 'GENERAL',
    },
    {
      label: 'Event     (1308x413)px',
      value: 'EVENT'
    },
    {
      label: 'Made in Nigeria',
      value: 'NIGERIA'
    },
  ];





  return (
    <>
      <HelmetHeader title="Manage Banners" description="Qlason Manage Banner Page" />
      <AppBoard className="_listing_product_">
        <div>
          <PageTitle
            title="Manage Banners"
            margin={false}
            subTitle={
              <PageSubTitle>
                <span
                  onClick={() => navigate(DashboardSellers)}>
                  <VscArrowSmallLeft /> Back to Dashboard
                </span>
                <p>Upload all banners for landing page here</p>
              </PageSubTitle>
            }>
            <ButtonGroupStyle defaultStyle={false}>
              <Button
                name="Add Banner"
                bgVariant="primary"
                textVariant="light"
                type="button"
                onClick={() => handleOnClick(true)}
              />
            </ButtonGroupStyle>
          </PageTitle>
        </div>

        {allBanner?.isError ? (<CustomAlert
          variant='danger'
          show={true}
          message={`${allBanner?.error?.message}`}
        />) : null}

        {!allBanner?.isLoading && allBanner?.data ? allBanner.data?.banners.length > 0 ? (
          <ContainerStyle>
            {allBanner.data?.banners.map((banner: any) => {
              return banner && (
                <BannerCard
                  key={banner.id}
                  loading={allBanner?.isLoading}
                  name={banner.title ? banner.title : ""}
                  link={banner.url}
                  tag={banner.tag}
                  isActive={banner.is_active}
                  bannerUrl={banner.image_url}
                  onPreview={() => handlePreview(banner.image_url, true)}
                  menus={[
                    {
                      name: "Edit Banner",
                      icon: <FiEdit3 color="#4ADE80" />,
                      active: false,
                      key: "edit-banner",
                      handler: () => handleEdit(banner, true),
                    },
                    {
                      name: "Preview Banner",
                      icon: <FiEye color="#335EF7" />,
                      active: false,
                      key: "preview-banner",
                      handler: () => handlePreview(banner.image_url, true),

                    },
                    {
                      name: "Delete Banner",
                      icon: <FiTrash2 color="#FF4D67" />,
                      active: false,
                      loading: false,
                      key: "delete-banner",
                      handler: () => handleDelete(banner.id),
                    },
                  ]}
                />
              )
            }
            )}
          </ContainerStyle>
        ) : (
          <EmptyCard
            title='No Banner added yet!'
            isLoading={allBanner?.isLoading}
            subTitle=''
          />
        ) : (
          <EmptyCard
            title='No Banner added yet!'
            isLoading={allBanner?.isLoading}
            subTitle=''
          />
        )}


        <CustomModal
          show={modal}
          size="sm"
          title={`${isEdit ? 'Edit' : 'Add a New'} Banner`}
          subtitle={`Complete the fields to ${isEdit ? 'edit' : 'add a'} banner`}
          centered={false}
          showFooter={false}
          isLoading={false}
          isDisabled={false}
          headerClass="app-modalheader event-modalhead"
          modalClass="app-modalbody"
          onHide={() => handleOnClick(false)}
          render={
            <FormPopupStyle>
              <form
                noValidate
                onSubmit={formik.handleSubmit}
                autoComplete="off">
                <div className="FormField">
                  <Field
                    id="name"
                    name="name"
                    label="Banner Name"
                    disabled={result?.isLoading || updateResult?.isLoading}
                    type="text"
                    required
                    size="large"
                    childOutsideLabel={false}
                    placeholder="Enter Banner Name"
                    fieldClassName=""
                    autoComplete="off"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.name}
                    isError={formik.touched.name && formik.errors.name ? true : false}
                    error={formik.errors.name}
                  />
                  <Field
                    id="bannerUrl"
                    name="bannerUrl"
                    label="Banner Link"
                    disabled={result?.isLoading || updateResult?.isLoading}
                    type="text"
                    required
                    size="large"
                    childOutsideLabel={false}
                    placeholder="Enter Banner Link"
                    fieldClassName=""
                    autoComplete="off"
                    onBlur={formik.handleBlur}
                    value={formik.values.bannerUrl}
                    isError={formik.touched.bannerUrl && formik.errors.bannerUrl ? true : false}
                    error={formik.errors.bannerUrl}
                    onChange={formik.handleChange}
                  />
                  <Field
                    id="slideNumber"
                    name="slideNumber"
                    label="Banner Slide Number"
                    disabled={result?.isLoading || updateResult?.isLoading}
                    type="number"
                    required
                    size="large"
                    numberFieldPrefix="No: "
                    childOutsideLabel={false}
                    placeholder="Enter Banner Slide Number"
                    fieldClassName=""
                    autoComplete="off"
                    onBlur={formik.handleBlur}
                    value={formik.values.slideNumber.toString()}
                    isError={formik.touched.slideNumber && formik.errors.slideNumber ? true : false}
                    error={formik.errors.slideNumber}
                    onChange={(value) => {
                      formik.setFieldValue('slideNumber', value.value);
                    }}
                  />
                  <div>
                    <Label
                      type="text"
                      text="Banner Tag"
                      required={true}
                    />
                    <CustomSelect
                      className="custom-select border-danger disabled"
                      placeholder="Select Banner Tag"
                      isLoading={false}
                      isDisabled={result?.isLoading || updateResult?.isLoading}
                      noOptionsMessage={() => "No Tags Found"}
                      value={formik.values.tag}
                      onBlur={formik.handleBlur}
                      options={options}
                      isError={formik.errors.tag?.value ? true : false}
                      error={formik.errors.tag?.value}
                      onChanged={(option: any) => {
                        formik.setFieldValue("tag", {
                          label: option.label,
                          value: option.value
                        });
                      }}
                    />
                  </div>
                  <ImageStyle>
                    <Label
                      type="text"
                      text="Image"
                      required={true}
                    />

                    {formik.values.bannerSrc ?
                      (<ImageContainer>
                        <img src={formik.values.bannerSrc} width="100%" height="250px" />
                        <span onClick={clearImgSrc}><FiX /></span>
                      </ImageContainer>)
                      : (
                        <Suspense fallback={<Loader />}>
                          <FileUploader
                            maxSize={4000000}
                            placeholder=""
                            label="Click the button to select your Image"
                            requiredText="Jpeg., Png. with not more that 4mb."
                            acceptFileType="image/*"
                            acceptFileTypeList={["image"]}
                            fileType="image"
                            maxFiles={1}
                            multiple={false}
                            name="imageFile"
                            setFieldTouched={formik.setFieldTouched}
                            setFieldValue={formik.setFieldValue}
                          />
                          {
                            getIn(
                              formik.errors,
                              "imageFile",
                            ) ? (
                              <div className="invalid-fbk">
                                {formik.errors.imageFile}
                              </div>
                            ) : null}
                        </Suspense>
                      )}
                  </ImageStyle>

                  {isEdit ? (
                    <SwitchStyle>
                      <Label
                        type="text"
                        text="Set Active"
                      />
                      <Switch
                        defaultChecked={formik.values.is_active}
                        name="is_active"
                        size="medium"
                        color="warning"
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                      />
                    </SwitchStyle>
                  ) : null}
                </div>
                <Button
                  name="Save"
                  bgVariant="primary"
                  textVariant="light"
                  type="submit"
                  className="ButtonSubmit_Class"
                  isLoading={result?.isLoading || updateResult?.isLoading}
                  isLoadingText=" "
                  disabled={
                    result?.isLoading ||
                    updateResult?.isLoading

                  }
                  shadow={false}
                />
              </form>
            </FormPopupStyle>
          }
        />

        <CustomModal
          show={preview.show}
          size="sm"
          title="Preview Banner"
          subtitle=""
          centered={false}
          showFooter={false}
          isLoading={false}
          isDisabled={false}
          headerClass="app-modalheader event-modalhead"
          modalClass="app-modalbody"
          onHide={() => handlePreview("", false)}
          render={
            <PreviewPopupStyle>
              {preview.url ? (
                <Fragment>
                  <div className="Banner_File_Preview">
                    <img src={preview.url} alt="Preview" />
                  </div>
                  <ImageUrlDiv>
                    <div className='ImageURL'>{preview.url}</div>
                    <button onClick={() => handleCopyClick(preview.url)}> {isCopied}</button>
                  </ImageUrlDiv>
                </Fragment>
              ) : (
                <p>No Image to Preview</p>
              )}
            </PreviewPopupStyle>
          }
        />

        {deleteResult?.isLoading ? (
          <Overlay bgColor="light">
            <Loader
              isCenter
              size="large"
              color="black"
            />
          </Overlay>
        ) : null}
      </AppBoard>
    </>
  );
}


const ContainerStyle = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 8px;
`;

const SwitchStyle = styled.div`
  padding-top: 20px;
`;

const PreviewPopupStyle = styled.div`
padding-bottom: 20px;
  .Banner_File_Preview{
      display: flex;
      width: 100%;
      min-height: 150px;
      justify-content: center;
      align-items: center;
      flex-shrink: 0;
      border-radius: 12px;
      background: #fff;
      cursor: pointer;

      img{
          width: 100%;
          height: 100%;
          border-radius: 12px !important;
          object-fit: contain;

      }

  @media ${device.lg}{
    max-height: 400px;
    min-height: 300px;

  }
  }
`
const ImageUrlDiv = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 1px solid #ccc;
    padding: 12px 16px;
    margin-top: 25px;
    border-radius: 8px;
    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.1);
    width: 100%;


    .ImageURL{
      width: 80%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      font-size: 14px;
      font-weight: var(--eke-font-weight-normal);
      font-family: var(--eke-body-font-family) !important;
    }


    button{
      padding: 8px 16px;
      background-color: #000;
      color: #fff;
      border: none;
      font-size: 14px;
      font-family: var(--eke-body-font-family) !important;
      border-radius: 4px;
      cursor:pointer;
      transition: background - color 0.3s;

        &:hover {
        background-color: rgba(0, 0, 0, 0.7);
      }
    }

    
`;


const FormPopupStyle = styled.div`
    width: 95%;
    margin: -6% auto;
    margin-bottom: 20px;
    font-family: var(--eke-body-font-family);

    form{
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        justify-content: center;
      }

      label{
        color: var(--greyscale-700, #616161) !important;
        font-size: 14px;
        font-weight: var(--eke-font-weight-normal) !important;
        line-height: 140%; /* 19.6px */
        letter-spacing: 0.2px;
      }

      .FormField{
        width: 100%;
        margin-bottom: 20px;
        display: flex;
        flex-direction: column;
        gap: 5px;
      }

      .InputField{
          width: 100% !important;
      }

      .ButtonSubmit_Class{
        align-self: flex-end;
        justify-self: flex-end;
        padding: 15px 16px !important;
        min-width: 180px !important;
        margin-right: 0 !important;
        span{
          text-align: center;
          text-shadow: 4px 8px 24px #EFDCC7;
          font-size: 15px !important;
          font-weight: var(--eke-font-weight-bold) !important;
          line-height: 140%; /* 22.4px */
          letter-spacing: 0.2px;
        }
      }

`;

const ImageStyle = styled.div`
  padding-top: 5px;
`;

const ImageContainer = styled.div`
    position: relative;
    width: 100%;
    height: 250px;
    border-radius: 12px;


    img{
        width: 100%;
        height: 250px;
      border-radius: 12px;
    }

    span{
        position: absolute;
        top: -2%;
        right: -1%;
        width:20px;
        height:20px;
        border-radius: 50%;
        background-color: #000;
        display: flex;
        align-items: center;
        text-align: center;
        justify-content: center;
        cursor: pointer;

        svg{
            color: #fff;
            font-size:13px;
        }

    }

`;


export default withDashboardLayout(ManageBanner);
