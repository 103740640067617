/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState } from "react";
import { Col, Row, Stack } from "react-bootstrap";
import { BsPiggyBank, BsEye } from "react-icons/bs"
import { Link } from "react-router-dom"
import { useFetchPendingSellersQuery, useFetchAdminDashboardQuery }
    from "@ekenta/redux/store/services/adminSellerApi"
import { useAppSelector } from "@ekenta/redux/store/hook"
import { activeSellersPathUrl, adminGetAllUsersUrl, AdminProductsPathUrl, awaitingSellers, blockedSellersPathUrl, DashboardProductsPathUrl, allSellersPathUrl, adminAllOrders } from "@ekenta/routes/constant";
import { useLocation, useNavigate } from "react-router";
import CountUp from "react-countup"
import { withDashboardLayout } from "@ekenta/hoc"
import { CardBox, DataTable, HelmetHeader, Pagination, Typography } from "@ekenta/components"

import "../Sellers/Sellers.scss";
import moment from "moment";
import { StatusIndicator, TextTruncate } from "../../common/Resuable";
import { checkIfAdmin } from "@ekenta/utils";

const AdminHome = () => {
    const { pathname } = useLocation();
    const [pageno, setPageno] = useState(0);

    const { account, token } = useAppSelector(state => state.auth.authentication);
    const pendingSellers = useFetchPendingSellersQuery({ pageno, token });
    const dashboardResult = useFetchAdminDashboardQuery(token);
    const isAdmin = account.user ? checkIfAdmin(token, account.user.role) : null;


    const recalibrate = (data: any) => {
        return data.map((items: any) => ({
            ...items,
            name: (
                <TextTruncate>{items.user.first_name + " " + items.user.last_name}</TextTruncate>
            ),
            store: (
                <TextTruncate>{items.business_name}</TextTruncate>
            ),
            phone: items.business_phone_number,
            email: items.user.email,
            status: (
                items.seller_request_status ? (
                    <StatusIndicator status={items.seller_request_status} />
                ) : ""
            ),
            created_at: moment(items.user?.created_at).format('MMMM Do YYYY'),
            bank: <BsPiggyBank size={20} color={"#E04826"} />,
            view: <Link to={`/admin/seller-review/${items.id}?type=awaiting`}><BsEye size={20} color={"#E04826"} /></Link>
        }))

    }


    const columns = [
        {
            Header: "Name",
            accessor: "name",
            sortType: "basic",
        },
        {
            Header: "Phone No",
            accessor: "phone",
            sortType: "basic",
        },
        {
            Header: "Store Name",
            accessor: "store",
            sortType: "basic",
        },
        {
            Header: 'Email',
            accessor: 'email',
            sortType: 'basic',
        },
        {
            Header: "Status",
            accessor: "status",
            disableSortBy: true,
        },
        // {
        //     Header: "Service Status",
        //     accessor: "service_status",
        //     sortType: "basic",
        // },
        {
            Header: "Bank",
            accessor: "bank",
            disableSortBy: true,
        },
        {
            Header: "Date Joined",
            accessor: "created_at",
            disableSortBy: true,
        },
        {
            Header: "View",
            accessor: "view",
            disableSortBy: true,
        },


    ]

    const navigate = useNavigate()
    return (
        <>
            <HelmetHeader
                title="Admin Dashboard"
                description="QlasonAdmin Dashboard Page"
            />

            <Col className="col-lg-12 mx-auto _listing_product_ mt-2 px-2">
                <div className="mt-0">
                    {/* <h3 className="text-secondary heading_d">
                        <FiChevronLeft />
                        Sellers
                    </h3> */}
                    <Stack direction="horizontal" gap={3}>
                        <h3 className="display_lg_header">Admin Dashboard</h3>
                        {/* <Button variant="outline-danger">Danger <AiOutlinePlus /></Button> */}
                    </Stack>
                    <Row className="mt-4">
                        <Col xs={12} md={6} lg={4}
                            className="text-center mb-3" role={"button"}
                            onClick={() => navigate(allSellersPathUrl)}>
                            <CardBox>
                                <Typography
                                    as="h3"
                                    color="default"
                                    size="xlarge"
                                    heading
                                >
                                    {dashboardResult.isSuccess ?
                                        <CountUp
                                            start={0}
                                            end={dashboardResult?.data.data.totalSellers} /> : "---"}
                                    <br />All Sellers
                                </Typography>
                            </CardBox>
                        </Col>
                        <Col xs={12} md={6} lg={4}
                            className="text-center mb-3" role={"button"}
                            onClick={() => navigate(activeSellersPathUrl)}>
                            <CardBox>
                                <Typography
                                    as="h3"
                                    color="default"
                                    size="xlarge"
                                    heading
                                >
                                    {dashboardResult.isSuccess ?
                                        <CountUp
                                            start={0}
                                            end={dashboardResult.data.data.totalActiveSellers} /> : "---"}
                                    <br />Active Sellers
                                </Typography>
                            </CardBox>
                        </Col>
                        <Col xs={12} md={6} lg={4}
                            className="text-center mb-3"
                            role={"button"}
                            onClick={() => navigate(blockedSellersPathUrl)}>
                            <CardBox>
                                <Typography
                                    as="h3"
                                    color="default"
                                    size="xlarge"
                                    heading
                                >
                                    {dashboardResult.isSuccess ?
                                        <CountUp
                                            start={0}
                                            end={dashboardResult?.data.data?.totalBlockedSellers} />
                                        : "---"}
                                    <br /> Blocked
                                </Typography>
                            </CardBox>
                        </Col>
                        <Col xs={12} md={6} lg={4}
                            className="text-center mb-3"
                            role={"button"}
                            onClick={() => navigate(awaitingSellers)}>
                            <CardBox>
                                <Typography
                                    as="h3"
                                    color="default"
                                    size="xlarge"
                                    heading
                                >
                                    {dashboardResult.isSuccess ?
                                        <CountUp
                                            start={0}
                                            end={dashboardResult?.data.data.totalSellersAwaitingApproval}
                                        />
                                        : "--"}
                                    <br /> Awaiting Approval
                                </Typography>
                            </CardBox>
                        </Col>

                        <Col xs={12} md={6} lg={4}
                            className="text-center mb-3"
                            role={"button"}
                            onClick={() => navigate(adminGetAllUsersUrl)}
                        >
                            <CardBox>
                                <Typography
                                    as="h3"
                                    color="default"
                                    size="xlarge"
                                    heading
                                >
                                    {dashboardResult.isSuccess ?
                                        <CountUp
                                            start={0}
                                            end={dashboardResult?.data?.data.allUsers} />
                                        : "---"} <br /> All Users
                                </Typography>
                            </CardBox>
                        </Col>
                        <Col xs={12} md={6} lg={4}
                            className="text-center mb-3"
                            role={"button"}
                            onClick={() => navigate(isAdmin ? AdminProductsPathUrl : DashboardProductsPathUrl)}>
                            <CardBox>
                                <Typography
                                    as="h3"
                                    color="default"
                                    size="xlarge"
                                    heading
                                >
                                    {dashboardResult.isSuccess ?
                                        <CountUp
                                            start={0}
                                            end={dashboardResult.data?.data.totalProducts} /> : "---"}
                                    <br />Total Products
                                </Typography>
                            </CardBox>
                        </Col>

                        <Col xs={12} md={6} lg={4}
                            role={"button"}
                            onClick={() => navigate(adminAllOrders)}
                            className="text-center mb-3">
                            <CardBox>
                                <Typography
                                    as="h3"
                                    color="default"
                                    size="xlarge"
                                    heading
                                >
                                    {dashboardResult.isSuccess ?
                                        <CountUp
                                            start={0}
                                            end={dashboardResult?.data.data.totalPendingOrders} /> : "---"}
                                    <br />Pending Orders
                                </Typography>
                            </CardBox>
                        </Col>


                        <Col xs={12} md={6} lg={4}
                            className="text-center mb-3">
                            <CardBox>
                                <Typography
                                    as="h3"
                                    color="default"
                                    size="xlarge"
                                    heading
                                >
                                    {dashboardResult.isSuccess ?
                                        <CountUp
                                            start={0}
                                            end={dashboardResult?.data?.data.totalBrands} />
                                        : "---"}
                                    <br /> Total Brands
                                </Typography>
                            </CardBox>
                        </Col>
                        <Col xs={12} md={6} lg={4}
                            className="text-center mb-3">
                            <CardBox>
                                <Typography
                                    as="h3"
                                    color="default"
                                    size="xlarge"
                                    heading
                                >
                                    {dashboardResult.isSuccess ?
                                        <CountUp
                                            start={0}
                                            end={dashboardResult?.data?.data.totalCollection} />
                                        : "---"} <br /> Total Collection
                                </Typography>
                            </CardBox>
                        </Col>
                        <Col xs={12} md={6} lg={4}
                            className="text-center mb-3">
                            <CardBox role="button" onClick={() => navigate(`${adminAllOrders}?_status=CANCELLED`)}>
                                <Typography
                                    as="h3"
                                    color="default"
                                    size="xlarge"
                                    heading
                                >
                                    {dashboardResult.isSuccess ?
                                        <CountUp
                                            start={0}
                                            end={dashboardResult?.data?.data.totalCancelledOrders} />
                                        : "---"} <br /> Total Cancel Order
                                </Typography>
                            </CardBox>
                        </Col>
                        <Col xs={12} md={6} lg={4}
                            className="text-center mb-3">
                            <CardBox role="button" onClick={() => navigate(`${adminAllOrders}?_status=COMPLETED`)}>
                                <Typography
                                    as="h3"
                                    color="default"
                                    size="xlarge"
                                    heading
                                >
                                    {dashboardResult.isSuccess ?
                                        <CountUp
                                            start={0}
                                            end={dashboardResult?.data?.data.totalCompletedOrders} />
                                        : "---"}
                                    <br /> Total Complete Order
                                </Typography>
                            </CardBox>
                        </Col>
                        <Col xs={12} md={6} lg={4}
                            className="text-center mb-3">
                            <CardBox>
                                <Typography
                                    as="h3"
                                    color="default"
                                    size="xlarge"
                                    heading
                                >
                                    {dashboardResult.isSuccess ?
                                        <CountUp
                                            start={0}
                                            end={dashboardResult?.data?.data.totalDeliveredOrders} />
                                        : "---"}
                                    <br /> Total Delivered Order
                                </Typography>
                            </CardBox>
                        </Col>
                    </Row>

                    {/* <Row className="mt-5">
                    </Row> */}


                    <Col className="mt-5 tab__">
                        <DataTable
                            theme="simple"
                            data={pendingSellers?.data ? recalibrate(pendingSellers?.data?.sellers.data) : []}
                            loading={pendingSellers?.isLoading}
                            isSelectable={true}
                            columns={columns}
                            showPagination={false}
                            hasLink={true}
                            thClassName="W"
                            trClassName="w_Tr"
                            tdClassName={`Row__col`}
                            renderPagnation={
                                <>
                                    {pendingSellers?.data
                                        ? pendingSellers?.data.totalElements > 20 && (
                                            <Pagination
                                                pathname={pathname}
                                                itemPerPage={pendingSellers?.data.pageable.pageSize}
                                                itemsLength={pendingSellers?.data.totalElements}
                                                currentPage={pageno}
                                            />
                                        )
                                        : null}
                                </>
                            }
                        />
                        <br />
                        <br />
                    </Col>
                </div>
            </Col>
        </>
    )
}

export default withDashboardLayout(AdminHome);