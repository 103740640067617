import React from "react";
import styled from "@emotion/styled";
import { AnimatePresence, motion } from "framer-motion";
//import arrowDown from "../../assets/images/arrow_down_icon.png";

interface StyledProp {
  position: string;
  height: string;
  radius: string;
  width: string;
}

export default function Popover(props: {
  show: boolean,
  setOutside: () => void,
  position: string;
  height: string;
  width: string;
  round: string;
  children?: React.ReactElement,
  render?: ((onClose: () => void) => JSX.Element) | undefined;

}) {
  return (
    <AnimatePresence>
      {props.show && (
        <>
          <Outside
            initial={{ opacity: 0 }}
            animate={{
              opacity: 1,
            }}
            exit={{
              opacity: 0,
            }}
            transition={{ type: "spring", bounce: 0, duration: 0.2 }}
            onClick={props.setOutside}
          />
          <Container
            initial={{ y: "100%" }}
            animate={{
              y: 0,
            }}
            exit={{
              y: "100%",
            }}
            transition={{ type: "spring", bounce: 0, duration: 0.3 }}
            style={props.position === "top" ? { top: "0%" }
              : props.position === "left" ? { left: "0%" }
                : props.position === "right" ? { right: "0%" }
                  : props.position === "bottom" ? { bottom: "0%" } : { bottom: "0%" }}
            position={props.position}
            height={props.height}
            radius={props.round}
            width={props.width}
          >
            {/* 
          <button className="close__dialog_div" onClick={props.setOutside}>
            <img src={arrowDown} />
          </button> */}
            {props.render ? props.render(props.setOutside) : props.children}
          </Container>
        </>
      )}
    </AnimatePresence>
  );
}

const Outside = styled(motion.div)`
   position: fixed;
   width: 100%;
   background: rgba(0,0,0,0.3);
    height: 100%;
    bottom: 0%;
    z-index: 999; 
    transition: all 0.3s ease-in-out;
`;

const Container = styled(motion.div) <StyledProp>`
  background-color: white;
  position: fixed;
  overflow-y: auto;
  overflow-x: hidden;
  width: ${(props) => `${props.width ? props.width : "100"}%`};
  height: ${(props) => `${props.height ? props.height : "300"}px`};
  border-top-left-radius: ${(props) => `${props.radius ? props.radius : 0}px`};
  border-top-right-radius: ${(props) => `${props.radius ? props.radius : 0}px`};
  z-index: 99999; 

  .close__dialog_div{
        width:100%;
        height: 25px;
        border: none !important;
        margin: 0 !important;
        position: absolute;
        top: 0;
        -webkit-appearance: none;
        background-color: #eee;
        border-top-left-radius: ${(props) => `${props.radius ? props.radius : 0}px`};
        border-top-right-radius: ${(props) => `${props.radius ? props.radius : 0}px`};

      img{
        height: 15px;
        filter: grayscale(1);
      }
    }

  
    @media (min-width: 1024px) { }
`;
