import React from "react";
import { Navigate } from "react-router";
import { useAppSelector } from "../../redux/store/hook";
import { checkIsSellerOrAdminRoleOnboardingRoute } from "../../utils";
import { DashboardPathUrl } from "../constant";


const OnboardingRoute = ({ component: RouteComponent, ...rest }: { component: () => any; }) => {
  const { ek_isAuth, account, token } = useAppSelector((state) => state.auth.authentication);


  if (ek_isAuth && token) {
    const result = checkIsSellerOrAdminRoleOnboardingRoute(account.user.role);
    if (result) {
      return <Navigate to={DashboardPathUrl} />
    }
  }

  return <RouteComponent {...rest} />
};

export default OnboardingRoute;

