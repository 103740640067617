/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { Fragment, useCallback, useEffect, useState } from 'react'
import Echo from 'laravel-echo'
import Pusher from 'pusher-js'
import * as queryString from 'query-string'
import styled, { css } from 'styled-components'
import AllUserChat from './AllUsers'
import AllChats from './AllChats'
import { device } from '@ekenta/utils/devices'
import { BASE_URL } from '@ekenta/constants'
import { useAppSelector } from '@ekenta/redux/store/hook'
import { checkIfAdmin } from '@ekenta/utils'
import RefreshLink from '../RefreshLink/RefreshLink'
import Button from '../Button/Button'
import { collectionPathUrl } from '@ekenta/routes/constant'
import {
  useGetAllAdminRoomsQuery,
  useGetAllRoomsQuery,
} from '@ekenta/redux/store/features/chat/chatApiSlice'
import Loader from '../Loader/Loader'
import axios from 'axios'
import { useDispatch } from 'react-redux'
import {
  dispatchDebouncedSetChatCount,
  setChatCountAsync,
} from '@ekenta/redux/store/features/chat/chatThunk'
import { debounce } from 'lodash'

const ChatSystem = (props: {
  isPopup: boolean
  from: 'admin' | 'seller' | 'buyer'
  userId?: string
  isChatStarted?: boolean
  isLoading?: boolean
  sellerStoreName?: string
  convertsatId?: string | null
  closeIcon?: JSX.Element
  redirectUrl?: string
  product?: {
    id: string
    name: string
    price: number
    imageUrl: string
  } | null
}) => {
  const {
    isPopup,
    sellerStoreName,
    product,
    convertsatId,
    from,
    userId,
    closeIcon,
    redirectUrl,
    isChatStarted = true,
  } = props
  const dispatch = useDispatch()
  const query = queryString.parse(window.location.search)
  const { token, account } = useAppSelector(
    (state) => state.auth.authentication
  )
  const role = account.user ? account?.user.role : null
  const storeName = query?.store ? query.store.split('-').join(' ') : ''
  const conversationId = convertsatId ?? query?.conversationId

  const is_resized = query?.is_resized

  const [isLoading, setIsLoading] = useState(false)
  const [allConversations, setAllConversations] = useState<any>([])
  const [messages, setMessages] = useState<any>([])

  const isAdmin = checkIfAdmin(token, role)

  let chats: any
  isAdmin
    ? (chats = useGetAllAdminRoomsQuery(null, {
        skip: isPopup && allConversations.length === 0,
      }))
    : (chats = useGetAllRoomsQuery(null, {
        skip: isPopup && allConversations.length === 0,
      }))

  window.Pusher = Pusher

  useEffect(() => {
    if (conversationId) {
      setIsLoading(true)
      setTimeout(() => {
        setIsLoading(false)
      }, 1000)
    }
  }, [conversationId])

  useEffect(() => {
    if (chats?.data) {
      getConversations(chats?.data)
    }
  }, [chats?.data])

  // useEffect(() => {
  //   if (token && conversationId) {
  //     window.Echo = new Echo({
  //       broadcaster: 'pusher',
  //       Pusher,
  //       encrypted: true,
  //       enabledTransports: ['ws', 'wss'],
  //       key: 'bbcca772736c038bbd2a',
  //       authEndpoint: `${BASE_URL}/api/broadcasting/auth`,
  //       cluster: 'eu',
  //       transports: ['websocket'],
  //       auth: {
  //         headers: {
  //           Authorization: `Bearer ${token}`,
  //           Accept: 'application/json',
  //         },
  //       },
  //     })

  //     window.Echo.private(`private-chat.${conversationId}`)
  //       .subscribed(() => {
  //         console.log('Subscribed')
  //       })
  //       .listen('.chat-message', (event: any) => {
  //         const result = event.message
  //         console.log(result, "message")
  //         setMessages((prevMessages: any) => {
  //           // Check if there's a message with the same id
  //           const index = prevMessages.findIndex(
  //             (message: { id: any }) => message.id === result.id
  //           )
  //           if (index !== -1) {
  //             const updatedMessages = [...prevMessages]
  //             updatedMessages[index] = result
  //             return updatedMessages
  //           } else {
  //             // If no message with the same id exists, add the new message with a unique key
  //             return [...prevMessages, { ...result }]
  //           }
  //         })
  //       })
  //   }
  // }, [conversationId])

  useEffect(() => {
    let isMounted = true

    const setupWebSocket = async () => {
      if (token && conversationId) {
        window.Echo = new Echo({
          broadcaster: 'pusher',
          Pusher,
          encrypted: true,
          enabledTransports: ['ws', 'wss'],
          key: 'bbcca772736c038bbd2a',
          authEndpoint: `${BASE_URL}/api/broadcasting/auth`,
          cluster: 'eu',
          transports: ['websocket'],
          auth: {
            headers: {
              Authorization: `Bearer ${token}`,
              Accept: 'application/json',
            },
          },
        })

        window.Echo.private(`private-chat.${conversationId}`)
          .subscribed(() => null)
          .listen('.chat-message', (event: any) => {
            dispatch(dispatchDebouncedSetChatCount())
            const result = event.message
            console.log(result)
            setMessages((prevMessages: any[]) =>
              prevMessages.findIndex((m) => m.id === result.id) !== -1
                ? [
                    ...prevMessages.map((m) =>
                      m.id === result.id ? result : m
                    ),
                  ]
                : [...prevMessages, { ...result }]
            )
          })
      }
    }

    setupWebSocket()

    return () => {
      isMounted = false
      if (window.Echo) {
        window.Echo.disconnect()
      }
    }
  }, [token, conversationId, dispatch])

  useEffect(() => {
    if (!isPopup && userId) {
      window.NewEcho = new Echo({
        broadcaster: 'pusher',
        Pusher,
        encrypted: true,
        enabledTransports: ['ws', 'wss'],
        key: 'bbcca772736c038bbd2a',
        authEndpoint: `${BASE_URL}/api/broadcasting/auth`,
        cluster: 'eu',
        transports: ['websocket'],
        auth: {
          headers: {
            Authorization: `Bearer ${token}`,
            Accept: 'application/json',
          },
        },
      })

      window.NewEcho.channel(`chat-list.${userId}`)
        .subscribed(() => null)
        .listen('.chat-list', () => {
          chats?.refetch()
        })
    }
  }, [isPopup, userId, token, chats])

  // useEffect(() => {
  //   if (isPopup === false && userId) {
  //     window.NewEcho = new Echo({
  //       broadcaster: 'pusher',
  //       Pusher,
  //       encrypted: true,
  //       enabledTransports: ['ws', 'wss'],
  //       key: 'bbcca772736c038bbd2a',
  //       authEndpoint: `${BASE_URL}/api/broadcasting/auth`,
  //       cluster: 'eu',
  //       transports: ['websocket'],
  //       auth: {
  //         headers: {
  //           Authorization: `Bearer ${token}`,
  //           Accept: 'application/json',
  //         },
  //       },
  //     })

  //     window.NewEcho.channel(`chat-list.${userId}`)
  //       .subscribed(() => {
  //         console.log('Subscribed all conversation')
  //       })
  //       .listen('.chat-list', () => {
  //         chats?.refetch()
  //       })
  //   }
  // }, [])

  const getConversations = (response: any) => {
    const result = isAdmin ? response?.data.data : response.data
    const data =
      result.length > 0
        ? result.map(
            (c: {
              lastMessage: { is_read: number; sender_id: string | undefined }
            }) => ({
              ...c,
              hasNewMessages:
                c.lastMessage?.is_read === 0 &&
                c.lastMessage?.sender_id?.toString() !== userId?.toString(),
              newMessagesCount: 0,
            })
          )
        : []
    setAllConversations(data)
  }

  const handleIndicatorClick = useCallback(
    async (conversationId: string, is_read: boolean) => {
      if (conversationId && is_read) {
        const response = await axios.get(
          `${BASE_URL}/api/read-message/${conversationId}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        if (response.status === 200) {
          chats?.refetch()
        }
      }
    },
    []
  )

  return (
    <ContainerWrapper>
      {isPopup ? (
        <AllChats
          isPopup={isPopup}
          isLoading={isLoading}
          from={from}
          isChatStarted={isChatStarted}
          toName={sellerStoreName}
          conversationId={conversationId}
          closeIcon={closeIcon}
          product={product}
          messages={messages}
          setMessages={setMessages}
        />
      ) : (
        <Container ispopup={`${isPopup}`} resized={`${is_resized}`}>
          {allConversations.length > 0 ? (
            <Fragment>
              {isPopup === false ? (
                <div className="AllUsers_Style">
                  <AllUserChat
                    isLoading={chats?.isLoading}
                    from={from}
                    data={allConversations}
                    conversationId={conversationId}
                    currentUser={account.user.email}
                    isError={chats?.isError}
                    handleIndicatorClick={handleIndicatorClick}
                  />
                </div>
              ) : null}
              <div className="AllChat_Style">
                {conversationId ? (
                  <AllChats
                    isPopup={isPopup}
                    isLoading={isLoading}
                    from={from}
                    redirectUrl={redirectUrl}
                    isChatStarted={isChatStarted}
                    toName={storeName}
                    conversationId={conversationId}
                    product={product}
                    messages={messages}
                    setMessages={setMessages}
                  />
                ) : (
                  <div className="EmptyChat_Style">
                    <p>Select Chat to reply</p>
                  </div>
                )}
              </div>
            </Fragment>
          ) : (
            <VStack>
              <TopHeader>
                <h5>Chats</h5>
              </TopHeader>
              <div className="center-empty-data">
                {chats?.isLoading || chats?.isError ? (
                  <Loader withWrapper size="xlarge" />
                ) : (
                  <>
                    <h5>Start a conversation</h5>
                    <RefreshLink to={collectionPathUrl}>
                      <Button
                        name="Find Products"
                        textVariant="light"
                        bgVariant="primary"
                        className="default_btn"
                      />
                    </RefreshLink>
                  </>
                )}
              </div>
            </VStack>
          )}
        </Container>
      )}
    </ContainerWrapper>
  )
}

const ContainerWrapper = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
`

const TopHeader = styled.div`
  position: relative;
  width: 100%;
  height: 80px;
  z-index: 9;
  background: var(--eke-bg-light-color) !important;
  padding: 19.5px 25px 13px 25px;
  border-bottom: 1px solid #eee;

  h5 {
    color: #000;
    font-family: var(--eke-body-font-family);
    font-size: 30px;
    font-weight: 700;
    line-height: 28.35px;
  }
`

const Container = styled.div<{ ispopup: string; resized: string }>`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: stretch;
  justify-content: stretch;

  .AllUsers_Style {
    flex-shrink: 0;
    width: 100%;
    height: 100%;
    background-color: #fff;
    border-right: 1px solid #eee !important;
    border-left: 1px solid #eee;
    border-top: 1px solid #eee;

    @media ${device.lg} {
      width: 300px;
    }
  }

  .AllChat_Style {
    width: 100%;
    height: 100%;

    ${(props) =>
      props.ispopup !== 'true' &&
      css`
        display: none;
        @media ${device.lg} {
          display: inline-flex;
        }
      `}

    .EmptyChat_Style {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  ${(props) =>
    props.resized === 'true' &&
    css`
      .AllUsers_Style {
        display: none !important;
      }
      .AllChat_Style {
        display: inline-flex !important;
      }
    `}
`

const VStack = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;

  .center-empty-data {
    flex: 1;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: -20%;
    justify-content: center;

    h5 {
      display: block;
      font-family: var(--eke-body-font-family) !important;
      font-weight: 500;
      color: var(--eke-color-secondary);
    }
  }
`
export default ChatSystem
