/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react'
import styled from 'styled-components'
import { device } from '@ekenta/utils/devices'
import { useGetTopRatedProductQuery } from '@ekenta/redux/store/features/product/productApiSlice'
import InformationCard from './common/InformationCard'
import { HomeBreadCrumb } from './common/BreadCrumb'
import SectionCard from './common/Sectioncard'
import { CustomAlert, HelmetHeader, ProductCard } from '@ekenta/components'
import { withDefaultLayout } from '@ekenta/hoc'
import ProductListLoader from '../Collections/component/SkeletonLoader/ProductListLoader'
import {
  ExchangeDescription,
  LiveDescription,
  TermsDescription,
} from './common/common'
import { Container, HomeStyle } from './styles'

const AllTopRated = () => {
  const getResult = useGetTopRatedProductQuery(null)

  return (
    <>
      <HelmetHeader title="Top Rated Product" description="Top rated product" />
      <HomeStyle>
        <Container>
          <HomeBreadCrumb activePage="Top Rated Product" />
          <SectionCard title="Top Rated Product" size="">
            <ContentStyle className="product_vid_commponent">
              {getResult?.isLoading && getResult?.isFetching ? (
                <ProductListLoader />
              ) : (
                <>
                  {getResult?.isSuccess && getResult?.data ? (
                    <>
                      {getResult?.data.product?.data.length > 0
                        ? getResult?.data.product?.data.map((item: any) => {
                            return (
                              <ProductCard
                                key={item.id}
                                productId={item.id}
                                isVariant={item.is_variant}
                                productVariation={item?.productVariation}
                                stockCount={item.stock}
                                aggregateRating={item.rating}
                                isShadow={true}
                                productName={item.name}
                                price={item.price}
                                discount={item.discount}
                                adminDiscount={item.admin_discount}
                                maxPrice={item.max_price}
                                imageUrl={item?.images[0]?.image_url ?? ''}
                                videoUrl={item.video_url}
                                condition={item.condition}
                              />
                            )
                          })
                        : null}
                    </>
                  ) : (
                    <>
                      <CustomAlert
                        variant="danger"
                        show={true}
                        message="Unable to load Content"
                      />
                      <ProductListLoader />
                    </>
                  )}
                </>
              )}
            </ContentStyle>
          </SectionCard>
          <div>
            <InformationCard
              title="30 Days Free Exchange for Apparels and Shoes"
              description={<ExchangeDescription />}
            />
            <InformationCard
              title="24/7 Livestream Pharmacy Consulting Services on E-commerce"
              description="Please carefully read the following terms and conditions regarding our 24/7 Livestream Pharmacy Consulting Services on our e-commerce platform. These terms outline the guidelines and procedures for utilizing our livestream consulting services"
            />
            <InformationCard
              title="24/7 Livestream Shopping Experience"
              description={<LiveDescription />}
            />
            <InformationCard
              title="Trade-Ins on our E-commerce Website"
              description={<TermsDescription />}
            />
          </div>
        </Container>
        <br />
      </HomeStyle>
    </>
  )
}

const ContentStyle = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 1em !important;
  margin-top: 0 !important;

  @media ${device.lg} {
    justify-content: flex-start;
  }

  .product__card__col {
    display: block;
    padding: 0 !important;
    border-radius: 10px !important;
    width: 46% !important;

    @media ${device.md} {
      width: 30% !important;
    }

    @media ${device.lg} {
      width: 214px !important;
    }
  }
`

export default withDefaultLayout(AllTopRated)
