import styled from "styled-components";


export const PriceRangeContainer = styled.div`
    width: 100%;
    padding-left: 0 !important;
    .price_wrap{
        margin-top: 1rem;

        .price_range_div{
            width: 100%;
            display: flex;
            align-items: center;
            gap: 2px;

            input{
                padding: 0.4rem;
                padding-right: 0.3rem !important;
                // width: 60px;
                height: auto;
                box-shadow: none !important;
                border-radius: 2px !important;
                font-family: var(--eke-body-font-family) !important;
                font-weight: 400;
                background: #FBFEFF !important;
                font-size: 13px;
                border: 1px solid #ddd !important;
            }

            button{
                color: var(--eke-color-white);
                // padding: 0.15rem 0.3rem 0.15rem 0.3rem;
                padding: 7px 5px 7px 5px !important;
                font-size: 13px;
                box-shadow: none;
                margin-right: 0 !important;
                border-radius: 6px !important;
                width: 84px !important;
            }
        }

        .rating_filter_div{
            display: flex;
            align-items: center;

            .rating__heading{
                padding-left: 0;
                font-size: 12.8px !important;
                color: var(--eke-body-text-secondary-color);
                font-family: var(--eke-body-font-family);
                font-weight: var(--eke-font-weight-medium);
                margin-right: 0.55rem;
                margin-bottom: 0 !important;
            }

            .form-check{
                display: flex;
                align-items: center;
                
                .form-check-input {
                    cursor: pointer;
                    margin-left: 0 !important;
                    margin-right:0.3rem !important;
                }

                .rating_up_div{
                    display: flex;
                    align-items: flex-end;
                    cursor: pointer;


                    .star-ratings{
                        margin-top: 0!important;
                        padding-top: 0 !important;
                        margin-right: 0.1rem;
                        padding: 0;
                        padding-bottom: 10px !important;
                        
                    }

                    span{
                        display: inline-block;
                        vertical-align: middle;
                        font-size: 13.2px;
                    }
                }


            }

        }
    }
    //margin-bottom: 0.5rem;
`;