/* eslint-disable @typescript-eslint/no-explicit-any */
import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { LazyLoadImage } from "react-lazy-load-image-component";
import defaultImg from "@ekenta/assets/images/default_livestream.webp";
import banner_ekeday from "@ekenta/assets/images/banner_ekeday.gif";
import { useFetchAllLiveSellersOnEkeQuery } from "@ekenta/redux/store/services/livecommerceApi";
import { CustomAlert, HelmetHeader, EmptyState } from "@ekenta/components";
import { withDefaultLayout } from "@ekenta/hoc";
import LiveProductCard from "@ekenta/components/LiveCommerce/LiveProductCard";
import { AllLiveCardLoader } from "../component/SkeletonLoader/AllLiveLoader";

const Ekemarket = () => {
  const getResult = useFetchAllLiveSellersOnEkeQuery();

  const mapAllLiveSellers = (data: any) => {
    return data.merchant_business.length > 0 ? (
      data.merchant_business.map((result: any, index: number) => {
        return (
          <>
            {/* <LiveProductCard
              key={`id_` + index}
              type="isEkeLive"
              id={result.userId}
              className="item col__cardb-wrapper"
              imageUrl={result.imageUrl ? result.imageUrl : defaultImg}
              title={result.business_name}
            /> */}
            LiveEkemarket
          </>
        );
      })
    ) : (
      <>
        <EmptyState title="No active stream" />
        <br />
      </>
    );
  };

  return (
    <>
      <HelmetHeader
        title="Ekemarket Live"
        description="QlasonEkemarket Livestream Page"
      />

      <div className="dall_live_wrapper_al">
        <div className="overall__pg_top">
          <Col className="Dlarge__b_img">
            <LazyLoadImage
              src={banner_ekeday}
              alt=""
              width="100%"
              height="auto"
              effect="blur"
            />
          </Col>
          <div className="main__container_wrapper">
            <Container className="m-0">
              <Col className="col__h3 d-flex align-items-center justify-content-start pl-0">
                <h3>
                  {getResult?.data && getResult?.data.content > 0
                    ? "Happening Now"
                    : "Ekemarket Live"}
                </h3>
              </Col>
              <Row className="mt-1 row__col__div">
                {getResult?.isError && (
                  <>
                    <CustomAlert
                      variant="danger"
                      show={true}
                      message="Unable to load Ekemarket live sellers"
                    />
                    <Row>
                      <AllLiveCardLoader />
                    </Row>
                  </>
                )}
                {getResult?.isLoading ? (
                  <AllLiveCardLoader />
                ) : (
                  <>
                    {getResult?.isSuccess && getResult?.data ? (
                      <>{mapAllLiveSellers(getResult?.data)}</>
                    ) : null}
                  </>
                )}
              </Row>
            </Container>
          </div>
        </div>
      </div>
    </>
  );
};

export default withDefaultLayout(Ekemarket);
