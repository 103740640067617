import React from "react"
import ToggleButton from "./ToggleButton";

const Switch = (props: { name?: string; disabled?: boolean; value: boolean; onChange: any; outerClass?: string; innerClass?: string; activeColor?: string; innerPos?: string; }) => {
  return <ToggleButton
    name={props.name}
    disabled={props.disabled}
    enabled={props.value}
    outerClass={props.outerClass}
    innerClass={props.innerClass}
    activeColor={props.activeColor}
    innerPos={props.innerPos}
    setEnabled={props.onChange}
  />
}



export default Switch