import React,{useState} from 'react';
import { OffcanvasPlacement } from 'react-bootstrap/esm/Offcanvas';

interface TriggerSideOffCanvasContextPropsType {
    offCanvas: {type:string,show:boolean,placement:OffcanvasPlacement |undefined};
    handleShowCanvas?: any;
    handleOnHideCanvas?: () => void;
}

const defaultState = {
    offCanvas: {type:"all",show:false,placement:undefined},
};

export const TriggerSideOffCanvasContext = React.createContext<TriggerSideOffCanvasContextPropsType>(defaultState);

export const TriggerSideOffCanvasProvider = ({children}:any) => {
    const [offCanvas, setOffCanvas] = useState<{type:string;show:boolean;placement:OffcanvasPlacement| undefined}>({
                        type:"all",
                        show: false,
                        placement: undefined
                     });

    const handleShowCanvas = (type:string,placement:OffcanvasPlacement|undefined) => setOffCanvas((prevState) => (
        {type:type,show: !prevState.show,placement:placement}
    ));
    const handleOnHideCanvas = () => setOffCanvas({...offCanvas,show:false});

    return(
        <TriggerSideOffCanvasContext.Provider value={{offCanvas,handleShowCanvas,handleOnHideCanvas}}>
            {children}
        </TriggerSideOffCanvasContext.Provider>
    )
}