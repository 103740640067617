import React, { Fragment } from 'react';
import { ToastContainer } from 'react-toastify';
//import { useLocation } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import AllRoutes from './routes';
import ErrorBoundaries from './components/ErrorBoundary/ErrorBoundaries';
import styled from 'styled-components';

const App = () => {

  const ToastContainerStyle = styled(ToastContainer)`
      &&&.Toastify__toast-container {}
      .Toastify__toast {
        display: flex;
        border-radius: 10px !important;
        padding-bottom: 0px !important;
        padding-top: 0px !important;
        background: rgba(0, 0, 0, .8) !important;
        color: "#fff";
        opacity:1;
        font-size: 12px;
        height: 20px !important;
        //padding: 5px 10px !important;
      }
      .Toastify__toast-body {
      }
      .Toastify__progress-bar {
      }
`;


  return (
    <Fragment>
      <ErrorBoundaries externalError={true}>
        <AllRoutes />
      </ErrorBoundaries>

      <ToastContainerStyle
        theme="dark"
        position="bottom-center"
        autoClose={1500}
        hideProgressBar
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover

      />
    </Fragment>
  );
};

export default App;
