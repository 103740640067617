import React, { useEffect, useState } from 'react'
import { useFormik } from 'formik'
import { toast } from 'react-toastify'
import * as queryString from 'query-string'
import { Alert, Button, Col, Form, Row, Spinner } from 'react-bootstrap'
import { VscArrowSmallLeft } from 'react-icons/vsc'
import { useNavigate, useParams } from 'react-router'
import { withDashboardLayout } from '@ekenta/hoc'
import { useAppSelector } from '@ekenta/redux/store/hook'
import {
  useFetchSellerMerchantDetailsQuery,
  useUpdateSellerProfileMutation,
  useUpdateSellerRequestStatusMutation,
} from '@ekenta/redux/store/services/adminSellerApi'

import { HelmetHeader, PageTitle, CustomAlert } from '@ekenta/components'

const SellerMerchantDetails = () => {
  const params = useParams()
  const navigate = useNavigate()
  const query = queryString.parse(window.location.search)
  const type = query.type

  const { token } = useAppSelector((state) => state.auth.authentication)

  const [isLoading, setIsLoading] = useState(false)
  const [status, setStatus] = useState('PENDING')
  const merchantId = params.id
  const [sellerInfo, setSellerInfo] = useState<any>(null)
  const [liveLink, setLiveLink] = useState<any>(null)

  console.log(token)
  const result = useFetchSellerMerchantDetailsQuery(token)
  const [updateSellerProfile, response] = useUpdateSellerProfileMutation()
  const [updateSellerRequestStatus, getResult] =
    useUpdateSellerRequestStatusMutation()

  useEffect(() => {
    if (response?.isError) {
      console.log(response?.isError)
    }

    if (result?.isSuccess) {
      const data = result?.data
      setSellerInfo(data)
      setLiveLink(data.liveLink)
    }
    if (result?.Error) {
      console.log('error')
    }

    if (getResult?.isError) {
      toast.error(`Something went wrong`, {
        toastId: 'error-id-toast-error',
      })
    }

    if (getResult?.isSuccess) {
      toast.success(`Updated Seller status Successfully`, {
        toastId: 'success-id-toast',
      })
    }
  }, [
    result?.isSuccess,
    response?.isError,
    getResult?.isError,
    getResult?.isSuccess,
  ])

  if (response?.isSuccess) {
    setIsLoading(false)
  }

  const formik = useFormik({
    initialValues: {
      currentpassword: '',
      newpassword: '',
      confirmpassword: '',
    },

    onSubmit: (values) => {
      if (status) {
        updateSellerRequestStatus({
          data: {
            status: status,
          },
          merchantid: result.data?.seller.id,
          token: token,
        })
      }

      // if (liveLink && sellerInfo) {
      //   const obj = {
      //     ...sellerInfo,
      //     liveLink,
      //   };

      //   updateSellerProfile({
      //     data: obj,
      //     merchantOwnerId: merchantId,
      //     token: token,
      //   });
      // }

      window.scrollTo(0, 0)
      // pass in the data
    },
  })

  return (
    <>
      <HelmetHeader
        title="Seller Details"
        description="QlasonSeller Detail Page"
      />
      <Col className=" col-lg-12 _listing_product_ dash_top px-2">
        <div className="mt-0">
          <Col className="AppEvents_col mx-1 mx-lg-0">
            <PageTitle
              title="Seller Details"
              subTitle={
                <span
                  className="cursor-pointer d-flex w-auto align-items-center 
                      text-muted"
                  onClick={() => navigate(-1)}
                >
                  <VscArrowSmallLeft />
                  Back
                </span>
              }
            />
          </Col>

          <Col className="mt-1 tab__">
            <br />
            {getResult?.isSuccess ? (
              <CustomAlert
                variant="success"
                show={true}
                message="Updated Seller status Successfully"
              />
            ) : null}

            {getResult?.isError ? (
              <CustomAlert
                variant="danger"
                show={true}
                message="Something went wrong"
              />
            ) : null}

            {response?.isSuccess ? (
              <CustomAlert
                variant="success"
                show={true}
                message="Seller Detail Updated Successfully"
              />
            ) : null}

            {result.isSuccess ? (
              <Form noValidate onSubmit={formik.handleSubmit}>
                <Row className="mt-2">
                  <Col lg={12}>
                    <h5 style={{ fontSize: '17px' }}>Personal Details</h5>
                  </Col>
                  <Col lg={6}>
                    <Form.Group className="mb-3 mt-1">
                      <Form.Label>Name</Form.Label>
                      <Form.Control
                        type="text"
                        value={
                          result.data?.seller?.user.first_name +
                          ' ' +
                          result.data?.seller?.user.last_name
                        }
                        placeholder="Enter Name"
                        readOnly
                      />
                    </Form.Group>

                    <Form.Group className="mb-3">
                      <Form.Label>Username</Form.Label>
                      <Form.Control
                        type="text"
                        value={result.data?.seller?.user.username}
                        readOnly
                      />
                    </Form.Group>
                  </Col>
                  <Col lg={6}>
                    <Form.Group className="mb-3">
                      <Form.Label>Phone Number</Form.Label>
                      <Form.Control
                        type="text"
                        value={result.data?.seller?.business_phone_number}
                        readOnly
                      />
                    </Form.Group>

                    <Form.Group className="mb-3">
                      <Form.Label>Email</Form.Label>
                      <Form.Control
                        type="text"
                        value={result.data?.seller?.user.email}
                        readOnly
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <hr />
                <Row className="mt-5">
                  <Col lg={12}>
                    <h5 style={{ fontSize: '17px' }}>Business Details</h5>
                  </Col>
                  <Col lg={6}>
                    <Form.Group className="mb-3">
                      <Form.Label>Business Name</Form.Label>
                      <Form.Control
                        type="text"
                        value={result.data?.seller?.business_name}
                        readOnly
                      />
                    </Form.Group>
                    <Form.Group className="mb-3 mt-1">
                      <Form.Label>Address 1</Form.Label>
                      <Form.Control
                        type="text"
                        value={
                          result.data?.seller?.address
                            ? result.data?.seller?.address
                            : ''
                        }
                        readOnly
                      />
                    </Form.Group>

                    <Form.Group className="mb-3">
                      <Form.Label>District</Form.Label>
                      <Form.Control
                        type="text"
                        value={
                          result.data?.seller?.address
                            ? result.data?.seller?.address
                            : ''
                        }
                        readOnly
                      />
                    </Form.Group>

                    <Form.Group className="mb-3">
                      <Form.Label>Country</Form.Label>
                      <Form.Control
                        type="text"
                        value={result.data?.seller?.country}
                        readOnly
                      />
                    </Form.Group>
                  </Col>
                  <Col lg={6}>
                    <Form.Group className="mb-3" controlId="formBasictext">
                      <Form.Label>City</Form.Label>
                      <Form.Control
                        type="text"
                        value={result.data?.seller?.city}
                        readOnly
                      />
                    </Form.Group>

                    <Form.Group className="mb-3">
                      <Form.Label>State</Form.Label>
                      <Form.Control
                        type="text"
                        value={result.data?.seller?.state}
                        readOnly
                      />
                    </Form.Group>
                    <Form.Group className="mb-3">
                      <Form.Label>Business Phone Number</Form.Label>
                      <Form.Control
                        type="text"
                        value={result.data?.seller?.business_phone_number}
                        readOnly
                      />
                    </Form.Group>
                  </Col>
                  <Col lg={6}>
                    <Form.Group className="mb-3">
                      <Form.Label>Business Proof of work:</Form.Label>{' '}
                      &nbsp;&nbsp;&nbsp;
                      {result.data?.seller?.proof_of_work ? (
                        <a
                          href={result.data?.seller?.proof_of_work}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          View
                        </a>
                      ) : (
                        'not provided'
                      )}
                    </Form.Group>
                  </Col>
                </Row>

                <hr />
                <Row className="mt-5">
                  <Col lg={12} style={{ fontSize: '17px' }}>
                    <h5>Bank Account</h5>
                  </Col>
                  <Col>
                    <Form.Group className="mb-3">
                      <Form.Label>Bank </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter Bank"
                        value={result.data?.seller?.bank_name}
                        readOnly
                      />
                    </Form.Group>
                    <Form.Group className="mb-3">
                      <Form.Label>Account Name</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter Account Name"
                        value={result.data?.seller?.bank_account_name}
                        readOnly
                      />
                    </Form.Group>
                    <Form.Group className="mb-3">
                      <Form.Label>Account Number</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter Account Number"
                        value={result.data?.seller?.bank_account_number}
                        readOnly
                      />
                    </Form.Group>
                  </Col>
                </Row>

                <br />
              </Form>
            ) : (
              'Loading'
            )}
          </Col>
        </div>
      </Col>
    </>
  )
}

export default withDashboardLayout(SellerMerchantDetails)
