/* eslint-disable @typescript-eslint/no-explicit-any */
import EmptyBox from "@ekenta/components/Box/EmptyBox";
import React, { useContext } from "react";
import { Row } from "react-bootstrap";
import ProductListLoader from "../../component/SkeletonLoader/ProductListLoader";
import { Pagination, ProductCardComponent } from "@ekenta/components";
import { useLocation } from "react-router-dom";
import { mapCartDataReturnQuantity } from "@ekenta/utils";
import { CollectionContext } from "@ekenta/context/CollectionContext";
import ErrorBox from "@ekenta/components/Box/ErrorBox";
import styled from "styled-components";

const SearchMainBar = (props: any) => {
    const {
        data,
        isSuccess,
        isError,
        setPageno,
        pageno,
        queryNo,
        loading,
        allCartData,
        productViewIcon
    } = props;
    const { pathname } = useLocation();
    const {
        isLoading,
        filterData,
        pageNo,
        setPageNo,
    } = useContext(CollectionContext);

    const newData = isSuccess ?
        filterData ? filterData.data
            : data.data : [];

    const pagninationData = isSuccess ?
        filterData ? filterData
            : data : null;


    return (
        <Container className="container-mainbar">
            <div className="grid_list_container">
                <div className="__main_collection_product_card_">
                    <Row className="prod_commponent_row_ p-0 m-0">

                        {isError ? (
                            <Row className="prod_commponent_row_ p-0 m-0">
                                <ErrorBox />
                            </Row>
                        ) : null}

                        {isSuccess &&
                            newData.length < 1 ? (
                            <Row className="prod_commponent_row_ p-0 m-0">
                                <EmptyBox />
                            </Row>
                        ) : null}


                        {
                            // fetchProduct_isSuccess ? "sdsdssd": ""
                            loading || isLoading ? (
                                <ProductListLoader />
                            ) : (
                                isSuccess &&
                                newData.map((item: any) => (
                                    <ProductCardComponent
                                        key={item.id}
                                        productId={item.id}
                                        product={item}
                                        layoutView={productViewIcon}
                                        cartItem={mapCartDataReturnQuantity(
                                            allCartData,
                                            item.id,
                                        )}
                                        isShadow={true}
                                        maxPrice={item.max_price}
                                        minPrice={item.min_price}
                                        productname={item.name}
                                        defaultprice={item.price}
                                        discount={item.discount}
                                        adminDiscount={item.admin_discount}
                                        discountPercentage={item.discount_percentage}
                                        thumbnailUrl={item.images.length > 0 ? item.images[0]?.image_url : ""}
                                        imageUrl={""}
                                        videoUrl={item.video_url}
                                        condition={item.condition}
                                    />
                                ),
                                )
                            )
                        }

                        {isSuccess && pagninationData ? (
                            <Pagination
                                pathname={pathname}
                                itemPerPage={pagninationData.per_page}
                                itemsLength={pagninationData.total}
                                currentPage={filterData ? pageNo : pageno}
                                queryNo={queryNo}
                                callback={(value) => filterData ? setPageNo(value) : setPageno(value)}
                            />
                        ) : null}
                    </Row>
                </div>
            </div>
        </Container>
    );
}

const Container = styled.div`
  position: relative;
`;
export default SearchMainBar;