import React from 'react'
import LiveIconComp from '@ekenta/components/LiveCommerce/LiveIconComp'
import { LiveStreamBaseURL, LiveStream_BaseURL } from '@ekenta/constants'
import { SellerNotLive } from '../innercomponents/SellerNotLive'
import { useAppSelector } from '@ekenta/redux/store/hook'

export default function VideoStreamsContainer({
  liveCode,
  isStream,
  businessName,
  messageArn,
  username,
}: {
  liveCode: string | null
  isStream: boolean
  businessName: string
  messageArn: string
  username: string | null
}) {
  const { token, account } = useAppSelector(
    (state) => state.auth.authentication
  )

  return (
    <>
      <div className="video__streaming__section position-relative">
        <div className="video__player__s3 vj3 ">
          {liveCode ? (
            <>
              {isStream ? (
                <>
                  <LiveIconComp />
                  <div className="iframe-stream-div">
                    <div className=" text-center">
                      <iframe
                        className="live-stream-frame"
                        src={`${LiveStreamBaseURL}/channel/${username}${
                          account !== null &&
                          account !== undefined &&
                          account?.user?.username !== undefined
                            ? '?name=' + account?.user?.username
                            : ''
                        }`}
                        // src={`${LiveStreamBaseURL}/viewer2?playbackUrl=${liveCode}&messageArn=${messageArn}`}
                        name="upstream"
                        // scrolling="no"
                        allow=""
                      ></iframe>
                    </div>
                  </div>
                </>
              ) : (
                <SellerNotLive businessName={businessName} />
              )}
            </>
          ) : null}
        </div>
      </div>
    </>
  )
}
