import * as Yup from 'yup';

const lowercaseRegex = /(?=.*[a-z])/;
const uppercaseRegex = /(?=.*[A-Z])/;
const specialRegex = /(?=.*[!@#$%^&*])/;
const numericRegex = /(?=.*[0-9])/;
const alphabetRegex = /^[aA-zZ\s]+$/;
const spaceRegex = /^(.*)?\S+(.*)?$/;
//const notOnlyNumbersRegex = /(?!^\d+$)^.+$/;

const registerFormValidation = () => Yup.object().shape({

    firstname: Yup.string()
                .matches(alphabetRegex, 'Require only alphabet')
                .matches(spaceRegex, 'Space not required')
                .required('Firstname field is required'),
    lastname: Yup.string()
                .matches(alphabetRegex, 'Require only alphabet')
                .matches(spaceRegex, 'Space not required')
                .required('Lastname field is required'),
    userName: Yup.string()
                .min(2, 'Minimum 2 characters required')
                .required('Username field is required')
                .matches(spaceRegex, 'Username must not contain space'),
    email: Yup.string()
                .email('Email address not valid')
                .required('Email field is required'),
    phoneNumber: Yup.string()
                .min(10, 'Phone Number should be 11 digits')
                .max(11, 'Phone Number should be 11 digits')
                .required('Phone number is required'),
    password: Yup.string()
                .matches(lowercaseRegex, 'One lowercase required')
                .matches(uppercaseRegex, 'One upper required')
                .matches(specialRegex, 'One special character')
                .matches(numericRegex, 'One number required')
                .matches(spaceRegex, 'Space not required')
                .min(6, 'Minimum 6 characters required')
                .required('Password field is required'),
    confirmpassword: Yup.string()
                .required('Confirm Password field is required')
        .oneOf([Yup.ref('password'), null], 'Passwords must match'),
    acceptTerms: Yup.bool().oneOf([true], 'You must accept the terms and conditions.'),
});

export default registerFormValidation;