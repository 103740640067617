import { useFormik } from 'formik';
import React, { useCallback, useEffect, useState } from 'react';
import * as queryString from 'query-string';
import { Card, Col } from 'react-bootstrap';
import { toast } from 'react-toastify';
import {
  Overlay,
  HelmetHeader,
  PageTitle,
  Switch,
  Loader,
} from '@ekenta/components';
import { withDashboardLayout } from '@ekenta/hoc';
import { DashboardEventsPathUrl } from '@ekenta/routes/constant';
import {
  useFetchSingleEventQuery,
  useToggleEventMutation,
  useUpdateEkeMarketActivationMutation,
} from '@ekenta/redux/store/services/adminSellerApi';
import { useAppSelector } from '@ekenta/redux/store/hook';
import { useFetchAdminAllCollectionsQuery } from '@ekenta/redux/store/services/collectionApi';
import { VscArrowSmallLeft } from 'react-icons/vsc';
import { useNavigate } from 'react-router';

const findActiveCollection = (data: any) => {
  return data.content.length > 0
    ? data.content.find((item: any) => item.isActivated === true)
    : null;
};

const EkeMarket = () => {
  const navigate = useNavigate();
  const { token } = useAppSelector((state) => state.auth.authentication);
  const param = queryString.parse(window.location.search);
  const id = param._id;
  const [disabled, setDisabled] = useState(false);

  const [updateEkeMarketActivation, getResult] =
    useUpdateEkeMarketActivationMutation();
  const [toggleEvent, result] = useToggleEventMutation();
  const allCollection = useFetchAdminAllCollectionsQuery(token);
  const fetchEvent = useFetchSingleEventQuery({ id, token });

  const formik = useFormik({
    initialValues: {
      parent: false,
      value: false,
      selected: '',
    },
    onSubmit: () => {
      console.log(null);
    },
  });

  useEffect(() => {
    if (result?.isSuccess) {
      toast.success(`Switched Collection`, {
        toastId: 'toggle-event-id-toast-success',
      });
    }

    if (result?.isError && result?.error.status == 400) {
      toast.error(`${getResult?.error.data.title}`, {
        toastId: 'toggle-event-id-toast-error',
      });
    }

    if (result?.isError && result?.error.status == 'FETCH_ERROR') {
      toast.error(`Something went wrong`, {
        toastId: 'toggle-event-2-id-toast-error',
      });
    }
  }, [result?.isSuccess, result?.isError]);

  useEffect(() => {
    if (getResult?.isSuccess) {
      toast.success(`Switched Collection`, {
        toastId: 'switch-collection-id-toast-success',
      });
    }

    if (getResult?.isError && getResult?.error.status == 400) {
      toast.error(`${getResult?.error.data.title}`, {
        toastId: 'switch-col-id-toast-error',
      });
    }

    if (getResult?.isError && getResult?.error.status == 'FETCH_ERROR') {
      toast.error(`Something went wrong`, {
        toastId: 'switch-collection-id-toast-error',
      });
    }
  }, [getResult?.isSuccess, getResult?.isError]);

  useEffect(() => {
    if (fetchEvent?.data) {
      const _res = fetchEvent?.data.isActive;
      formik.setFieldValue('parent', _res);
    }

    if (allCollection?.data) {
      const result = findActiveCollection(allCollection?.data);
      if (result) {
        formik.setFieldValue('selected', result.id);
        formik.setFieldValue('value', true);
      }
    }
  }, [fetchEvent?.data, allCollection?.data]);

  useEffect(() => {
    if (formik.values.parent) {
      setDisabled(false);
    } else {
      formik.setFieldValue('value', false);
      formik.setFieldValue('selected', '');
      setDisabled(true);
    }
    if (!formik.values.value) {
      formik.setFieldValue('selected', '');
    }
  }, [formik.values.value, formik.values.parent]);

  const handleToggleParent = useCallback(() => {
    const id = param._id;
    if (id) {
      toggleEvent({ id, token });
    }
    if (formik.values.value == true && formik.values.selected !== '') {
      handleToggleColl(formik.values.selected);
    }
  }, [param, formik.values.value, formik.values.selected]);

  const handleToggleColl = useCallback((collectionId: string) => {
    if (collectionId) {
      updateEkeMarketActivation({
        collectionId,
        token,
      });
    }
  }, []);

  return (
    <>
      <HelmetHeader title="Ekemarket" description="QlasonEkemarket Page" />

      <Col className="EkeMarket__Div">
        <Col className="AppEvents_col mx-1 mx-lg-0">
          <PageTitle
            title="Eke Market"
            subTitle={
              <span
                className="cursor-pointer d-flex w-lg-50 align-items-center
                    text-muted"
                onClick={() => navigate(DashboardEventsPathUrl)}
              >
                <VscArrowSmallLeft />
                Back to Events
              </span>
            }
          />
        </Col>
        <div className="EkeMarket__Filters">
          <div
            className="EkeMarket__subHeader w-100 mb-2
                 d-flex align-items-center justify-content-between"
          >
            <h5 className="mb-2">Switch Eke Market Day</h5>
            <span>
              <Switch
                value={formik.values.parent}
                disabled={fetchEvent?.isLoading ? true : false}
                onChange={(e: React.ChangeEvent) => {
                  formik.setFieldValue('parent', !formik.values.parent);
                  handleToggleParent();
                }}
              />
            </span>
          </div>
          <br />
          <div className="EkeMarket__content w-100">
            <span className="mb-1">All Collections</span>
            <Col className="w-100 mt-4">
              <div className="EkeMarket__rowDirection">
                {allCollection?.isLoading && (
                  <div
                    className="d-flex justify-content-center 
                               w-100 align-items-center"
                  >
                    <Loader />
                  </div>
                )}
                {allCollection?.data &&
                  allCollection?.data.content.map(
                    (item: any, index: number) => {
                      return (
                        <CardComponent
                          key={'id__' + index}
                          name={item.name}
                          disabled={
                            formik.values.value == true &&
                              formik.values.selected == item.id
                              ? false
                              : formik.values.value == false &&
                                formik.values.selected == ''
                                ? disabled
                                : true
                          }
                          value={
                            formik.values.selected === item.id
                              ? formik.values.value
                              : false
                          }
                          setFieldValue={() => {
                            formik.setFieldValue('value', !formik.values.value);
                            formik.setFieldValue('selected', item.id);
                            handleToggleColl(item.id);
                          }}
                        />
                      );
                    },
                  )}
              </div>
            </Col>
          </div>
          <br />
          <br />
        </div>
      </Col>
      {result?.isLoading || fetchEvent?.isLoading || getResult?.isLoading ? (
        <Overlay>
          <div
            className="d-flex align-items-center 
                  justify-content-end mx-auto w-25 p-4"
          >
            <Loader withWrapper />
          </div>
        </Overlay>
      ) : null}
    </>
  );
};

const CardComponent = ({
  name,
  value,
  disabled,
  setFieldValue,
}: {
  name: string;
  value: boolean;
  disabled: boolean;
  setFieldValue: any;
}) => {
  return (
    <div className="EkeMarket__Col">
      <Card>
        <Card.Body>
          <span className="EkeMarket__icon mt-1">
            <Switch
              disabled={disabled}
              value={value}
              onChange={setFieldValue}
            />
          </span>
          <h2 className="EkeMarket__heading mt-2 display_lg_header">{name}</h2>
        </Card.Body>
      </Card>
    </div>
  );
};

export default withDashboardLayout(EkeMarket);
