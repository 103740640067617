import React from 'react'
import styled from 'styled-components'

const Description = (props: { description: string }) => {
  const { description } = props

  return (
    <Container>
      <div className="top-header">
        <h5>Product Description</h5>
      </div>
      <div className="descriptions">
        <p>{description}</p>
      </div>
    </Container>
  )
}

const Container = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 16px 22px 18px 21px;
  border-radius: 12px;
  background: #fff;
  font-family: var(--eke-body-font-family);
  gap: 20px;
  //box-shadow: 0 2px 2px 0px rgba(0, 0, 0, 0.12);

  .top-header {
    width: 100%;
    height: 57px;
    border-radius: 12px;
    padding: 15px 0px 12px 16px;
    align-items: center;
    background: #f3faff;

    h5 {
      color: var(--secondary-500, #1d4057);
      font-size: 22px;
      font-weight: 700;
      margin: 0;
      padding: 0;
    }
  }

  .descriptions {
    p {
      margin: 0;
      color: var(--greyscale-700, #616161);
      font-size: 14px;
      font-weight: 400;
      line-height: 140%; /* 22.4px */
      letter-spacing: 0.2px;
    }
  }
`
export default Description
