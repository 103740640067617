/* eslint-disable @typescript-eslint/no-explicit-any */
import { Button, Typography } from '@ekenta/components'
import React, { memo, useCallback, useState } from 'react'

import styles from './FollowButton.module.scss'
import { useAppSelector } from '@ekenta/redux/store/hook'
import { toast } from 'react-toastify'
import { FiPlus } from 'react-icons/fi'
import { BASE_URL } from '@ekenta/constants'
import axios from 'axios'
import classnames from 'classnames'

const FollowButton = (props: {
  sellerId: string | null
  followStatus?: number
  count: number
  suffixCount?: boolean
  inline?: boolean
  className?: string
  callback?: (value: number) => void
}) => {
  const {
    sellerId,
    followStatus = 0,
    count = 0,
    inline,
    suffixCount,
    className,
  } = props
  const { token } = useAppSelector((state) => state.auth.authentication)
  const [result, setResult] = useState<number | null>(null)
  const [isLoading, setLoading] = useState(false)
  const [title, setTitle] = useState(followStatus == 0 ? 'Follow' : 'Un-follow')

  const handleSubmit = (id: string | null) => {
    if (id) {
      console.log('clicked')
      toggleFunction(id)
    } else if (!id && !token) {
      toast.error(`Sign in to follow seller`, {
        toastId: 'error-status-follow-id',
      })
    }
  }

  const toggleFunction = useCallback((id: string) => {
    if (!token) {
      toast.warning(`Login to follow seller`, {
        toastId: '-toggle-follow-info-id-toast-error',
      })
      return
    }
    const config = {
      method: 'get',
      url: `${BASE_URL}/api/follow-seller/${id}`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      data: '',
    }
    setLoading(true)
    axios(config)
      .then(function (response: any) {
        setLoading(false)
        toast.success(`${response?.data.message}`, {
          toastId: 'status-follow-success-id-toast',
        })
        console.log(response?.data?.message)
        console.log(response?.data?.message == 'Followed seller successfully')
        if (response?.data?.message == 'Followed seller successfully') {
          setTitle('Un-follow')
        } else {
          setTitle('Follow')
        }
        setResult(response?.data.data)
        if (props.callback) {
          props.callback(response?.data.data)
        }
      })
      .catch(function () {
        setLoading(false)
        toast.error(`Something went wrong`, {
          toastId: '-toggle-follow-error-400-id-toast-error',
        })
      })
  }, [])

  const _count = result ? result : count

  return (
    <div className={`${styles.FollowingButton_Wrapper} follow--btn-group`}>
      <Button
        name={title}
        bgVariant="primary"
        textVariant="light"
        className={classnames(styles.Button, className)}
        prefixIcon={<FiPlus />}
        suffixIcon={suffixCount ? <span>{_count}</span> : <></>}
        disabled={isLoading}
        isLoadingText="Follow"
        isLoading={isLoading}
        shadow={false}
        type="button"
        onClick={() => handleSubmit(sellerId)}
      />
      {!inline ? (
        <div className={styles.FlexTypography}>
          <Typography as="span" size="small" color="dark" uiText>
            {_count}
          </Typography>
          <Typography
            as="span"
            size="smallest"
            className={styles.FollowerTypography}
            color="light"
            uiText
          >
            Follower{_count > 1 ? 's' : ''}
          </Typography>
        </div>
      ) : null}
    </div>
  )
}

export default memo(FollowButton)
