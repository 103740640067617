import React, { Fragment, useEffect, useRef, useState } from 'react'
import { withDashboardLayout } from '@ekenta/hoc';
import { animateScroll } from 'react-scroll';
import { useNavigate } from 'react-router-dom';
import { CustomAlert, HelmetHeader, PageTitle } from '@ekenta/components';

import { AddProductContainerStyle, AddProductMainStyle, AddProductRowStyle } from './style';
import AsideStepper from './common/AsideStepper';
import AddProductForm from './common/AddProductForm';
import { SpinLoader } from '@ekenta/components/Spinner/SpinLoader';
import { PageSubTitle } from '../../common/Resuable';
import { useAppSelector } from '@ekenta/redux/store/hook';
import { checkIfAdmin } from '@ekenta/utils';
import { AdminProductsPathUrl, DashboardProductsPathUrl } from '@ekenta/routes/constant';
import { VscArrowSmallLeft } from 'react-icons/vsc';
import { useGetSingleSellerDetailQuery } from '@ekenta/redux/store/services/adminSellerApi';
import { UnAuthorizedCard } from '../../component/UnAuthorizedCard/UnAuthorizedCard';


const AddProduct = () => {
    const navigate = useNavigate();
    const urlParams = new URLSearchParams(window.location.search);
    let type = urlParams.get("type");
    const { account, token } = useAppSelector((state) => state.auth.authentication);
    const [page, setPage] = useState(0);
    const isAdmin = account.user ? checkIfAdmin(token, account.user.role) : null;
    const getResult = useGetSingleSellerDetailQuery(token);

    const ref = useRef(null);


    useEffect(() => {
        if (page) {
            scrollToTop();
        }
        if (type == '') {
            type = "add";
        }
    }, [page, type]);

    const scrollToTop = () => {
        animateScroll.scrollToTop();
    }
    const nextPage = () => setPage(page + 1);

    let result;
    isAdmin ? result = true
        : result = getResult?.isSuccess
            ? getResult?.data.user.merchant_business
                && getResult?.data.user.merchant_business.seller_request_status === "APPROVED"
                ? true
                : null : null;

    return (
        <>
            <HelmetHeader
                title={`${type == "edit" ? "Edit" : "Add"
                    } Product - Dashboard - Qlason.com`}
                description="Qlason Create Product Page"
            />
            {getResult?.isLoading ? (
                <SpinLoader size={3} variant="dark" />
            ) : (
                <>
                    {result ? (
                        <Fragment>
                            <AddProductMainStyle>
                                <PageTitle
                                    className="AppHeader_inner"
                                    title={
                                        type === "edit" ? "Edit Product" : "Create Product"
                                    }
                                    margin={false}
                                    subTitle={
                                        <PageSubTitle>
                                            <span
                                                onClick={() => navigate(isAdmin
                                                    ? AdminProductsPathUrl
                                                    : DashboardProductsPathUrl
                                                )}>
                                                <VscArrowSmallLeft />
                                                Back to Dashboard
                                            </span>
                                            <p className="mt-3">
                                                The most important feature. When
                                                {type === "edit" ? " editing" : " adding"} product here,
                                                do not ignore to fill the required fields completely
                                                and follow the product adding rules.
                                            </p>
                                        </PageSubTitle>
                                    }
                                />
                                <AddProductContainerStyle>
                                    <AddProductRowStyle>
                                        <AsideStepper
                                            isLoading={false}
                                            page={page}
                                            setPage={setPage}
                                        />
                                        <AddProductForm
                                            ref={ref}
                                            page={page}
                                            type={type}
                                            isAdmin={isAdmin}
                                            nextPage={nextPage}
                                        />
                                    </AddProductRowStyle>
                                </AddProductContainerStyle>
                            </AddProductMainStyle >
                            <br />
                            <br />
                        </Fragment>
                    ) : getResult?.isError ? (
                        <div className="mt-3">
                            <CustomAlert
                                variant="danger"
                                show={true}
                                message={"Something went wrong, Try again"}
                            />
                        </div>
                    ) : <UnAuthorizedCard />}
                </>
            )}
        </>
    )
}

export default withDashboardLayout(AddProduct);