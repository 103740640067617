/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { Fragment } from 'react'
import { mapCartDataReturnQuantity } from '@ekenta/utils'
import { Pagination, ProductCardComponent } from '@ekenta/components'
import EmptyBox from '@ekenta/components/Box/EmptyBox'
import { device } from '@ekenta/utils/devices'
import styled from 'styled-components'
import ErrorBox from '@ekenta/components/Box/ErrorBox'
import { useLocation } from 'react-router'
import ProductListLoader from '@ekenta/pages/Collections/component/SkeletonLoader/ProductListLoader'
import { useFetchCartItemsQuery } from '@ekenta/redux/store/services/cartApi'
import { useAppSelector } from '@ekenta/redux/store/hook'

interface StoreProducts {
  loading: boolean
  isSuccess: boolean
  isError: boolean
  pageno: number
  data: any
  queryNo: number
  setPageno: React.Dispatch<React.SetStateAction<number>>
  paginationData: {
    per_page: number
    total: number
  }
}
const StoreProducts = (props: StoreProducts) => {
  const { pathname } = useLocation()
  const { token } = useAppSelector((state) => state.auth.authentication)
  const { loading, data, paginationData, isError, pageno, queryNo, setPageno } =
    props

  const { data: allCartData } = useFetchCartItemsQuery(token, { skip: !token })

  return (
    <Fragment>
      <ContentStyle className="prod_commponent_row_ p-0 m-0">
        {isError ? (
          <ErrorBox />
        ) : (
          <Fragment>
            {loading ? (
              <>
                <ProductListLoader />
              </>
            ) : data && data.length > 0 ? (
              data.map((item: any) => {
                return (
                  <ProductCardComponent
                    key={item.id}
                    productId={item.id}
                    product={item}
                    layoutView="grid"
                    cartItem={mapCartDataReturnQuantity(allCartData, item.id)}
                    isShadow={true}
                    maxPrice={item.max_price}
                    minPrice={item.min_price}
                    discount={item.discount}
                    adminDiscount={item.admin_discount}
                    discountPercentage={item.discount_percentage}
                    productname={item.name}
                    defaultprice={item.price}
                    imageUrl={
                      item.images.length > 0 ? item.images[1]?.image_url : ''
                    }
                    thumbnailUrl={
                      item.images.length > 0 ? item.images[0]?.image_url : ''
                    }
                    videoUrl={item.video_url}
                    condition={item.condition}
                  />
                )
              })
            ) : (
              <EmptyBox />
            )}
          </Fragment>
        )}
      </ContentStyle>
      <div>
        {paginationData ? (
          data ? (
            <Pagination
              pathname={pathname}
              itemPerPage={paginationData.per_page}
              itemsLength={paginationData.total}
              currentPage={pageno}
              queryNo={queryNo}
              callback={(value) => setPageno(value)}
            />
          ) : null
        ) : null}
      </div>
    </Fragment>
  )
}

const ContentStyle = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  align-items: center;
  gap: 1em !important;
  margin-top: 0 !important;

  .product__card__col {
    display: block;
    padding: 0 !important;
    border-radius: 10px !important;
  }
`
export default StoreProducts
