/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { Fragment, useState } from 'react'
import { useFormikContext } from 'formik'
import styled from 'styled-components'
import { toast } from 'react-toastify'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import axios from 'axios'
import {
  AddCard,
  CancelBtn,
  InnerContainerStyle,
  StepContentStyle,
  StepHeading,
} from '../../style'
import {
  FileUploader,
  Label,
  Loader,
  ProgressiveImg,
  Typography,
} from '@ekenta/components'
import { NewProductListingFormType } from '@ekenta/interfaces/ProductListing'
import { ReactComponent as CancelIcon } from '@ekenta/assets/icons/cancel-icon.svg'
import FileSVG from '@ekenta/assets/icons/file-icon.svg'
import { SquarePlus } from '@ekenta/components/icons'
import { BASE_URL } from '@ekenta/constants'
import { useAppSelector } from '@ekenta/redux/store/hook'
import { VscGripper, VscTrash } from 'react-icons/vsc'
import VideoPlayerWithoutControls from '@ekenta/components/VideoPlayer/VideoPlayerWithoutControls'

const ProductImages = () => {
  const { values, errors, setFieldValue, setFieldTouched, touched } =
    useFormikContext<NewProductListingFormType>()
  const { token } = useAppSelector((state) => state.auth.authentication)
  const [isLoading, setIsLoading] = useState(false)
  const [videoLoader, setVideoLoader] = useState(false)
  const [currentIndex, seCurrentIndex] = useState<number | null>(null)

  const handleAddImageField = () =>
    setFieldValue('productimages', [
      ...values.productimages,
      {
        image_url: null,
      },
    ])

  const handleRemoveImageField = (index: number) => {
    const list_ = [...values.productimages]
    list_.splice(index, 1)
    setFieldValue('productimages', list_)
  }

  const uploadImage = (acceptedFiles: File[], index: number) => {
    if (acceptedFiles.length <= 0) {
      return toast.error(`File must be selected`, {
        toastId: '-upload-error-400-id-toast-error',
      })
    }
    setIsLoading(true)
    seCurrentIndex(index)
    const imagesData = new FormData()
    acceptedFiles.forEach((file: File) => {
      if (file) {
        imagesData.append('media', file)
      }
    })

    try {
      const config = {
        method: 'POST',
        url: `${BASE_URL}/api/seller/upload-media`,
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${token}`,
        },
        data: imagesData,
      }
      axios(config)
        .then(function (response) {
          if (response.status === 200) {
            const imageUrl = response?.data?.url
            setFieldValue(`productimages.${index}.image_url`, imageUrl)
            setIsLoading(false)
          }
        })
        .catch(function () {
          setIsLoading(false)
          seCurrentIndex(null)

          toast.error(`Could not upload file`, {
            toastId: '-toggle-error-400-id-toast-error',
          })
        })
    } catch (e) {
      console.error(e)
      setIsLoading(false)
      seCurrentIndex(null)
      toast.error(`Something went wrong`, {
        toastId: '-wrong-error-400-error',
      })
    }
  }

  const uploadVideo = (acceptedFiles: File[]) => {
    setVideoLoader(true)
    const videoData = new FormData()
    acceptedFiles.forEach((file: File) => {
      if (file) {
        videoData.append('media', file)
      }
    })
    try {
      const config = {
        method: 'POST',
        url: `${BASE_URL}/api/seller/upload-media`,
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${token}`,
        },
        data: videoData,
      }
      axios(config)
        .then(function (response) {
          if (response.status === 200) {
            const videoUrl = response?.data?.url
            setFieldValue('productvideo', videoUrl)
            setVideoLoader(false)
          }
        })
        .catch(function () {
          setVideoLoader(false)
          toast.error(`Could not upload file`, {
            toastId: '-video-error-400-id-toast-error',
          })
        })
    } catch (e) {
      console.error(e)
      setVideoLoader(false)
      toast.error(`Something went wrong`, {
        toastId: '-wrong-error-400-error',
      })
    }
  }

  const onDragEnd = (result: any) => {
    if (!result.destination) return // Dragged outside the list
    const updatedImages = [...values.productimages]
    const [reorderedImage] = updatedImages.splice(result.source.index, 1)
    updatedImages.splice(result.destination.index, 0, reorderedImage)
    setFieldValue('productimages', updatedImages)
  }

  const removeImageVal = (index: number) => {
    const images = [...values.productimages]
    const updatedImages = images.map((image, i) => {
      if (i === index) return { image_url: null }
      return image
    })
    setFieldValue('productimages', updatedImages)
  }

  const removeVideoVal = () => {
    setFieldValue('productvideo', null)
  }

  const imgProduct = values.productimages

  return (
    <InnerContainerStyle>
      <Flex>
        <StepHeading>
          <Typography as="h3" color="default" size="xlarge" heading>
            Product Images & Video
          </Typography>
          <Typography as="p" color="default" size="small">
            Image must be maximum of 9.06 MB, Accepted format: (jpeg,jpg,png)
          </Typography>
        </StepHeading>
        {imgProduct?.length && imgProduct?.length < 5 ? (
          <AddCard type="button" onClick={handleAddImageField}>
            <SquarePlus />
            <span>Add Image</span>
          </AddCard>
        ) : null}
      </Flex>
      <StepContentStyle h="auto">
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="imageList" direction="horizontal">
            {(provided: any) => (
              <ImageGroup
                ref={provided?.innerRef}
                {...provided?.droppableProps}
              >
                {imgProduct &&
                  imgProduct?.map((item, index) => (
                    <Draggable
                      key={index}
                      draggableId={`${index}`}
                      index={index}
                    >
                      {(provided: any) => (
                        <ImageCard
                          className="boxShadow"
                          key={index}
                          ref={provided?.innerRef}
                          {...provided?.draggableProps}
                          {...provided?.dragHandleProps}
                        >
                          <div className="gripper">
                            <VscGripper />
                          </div>

                          {imgProduct?.length > 2 && (
                            <CancelBtn
                              type="button"
                              onClick={() => handleRemoveImageField(index)}
                            >
                              <CancelIcon />
                            </CancelBtn>
                          )}

                          {currentIndex === index && isLoading ? (
                            <ImagePreview isLoading={`${isLoading}`}>
                              <Loader />
                            </ImagePreview>
                          ) : (
                            <>
                              {item?.image_url !== null ? (
                                <ImagePreview>
                                  <ProgressiveImg
                                    src={`${item?.image_url}`}
                                    alt="image"
                                    placeholderSrc={`${item?.image_url}`}
                                  />
                                  <DeleteBtn
                                    type="button"
                                    onClick={() => removeImageVal(index)}
                                  >
                                    <VscTrash color="#000" />
                                  </DeleteBtn>
                                </ImagePreview>
                              ) : (
                                <FileUploader
                                  maxSize={10000000}
                                  placeholder={FileSVG}
                                  requiredText=""
                                  acceptFileType="image/*"
                                  acceptFileTypeList={['image']}
                                  fileType="image"
                                  maxFiles={1}
                                  multiple={false}
                                  errors={errors}
                                  name={`productimages.${index}.url`}
                                  callback={(acceptedFiles) =>
                                    uploadImage(acceptedFiles, index)
                                  }
                                />
                              )}
                            </>
                          )}
                        </ImageCard>
                      )}
                    </Draggable>
                  ))}
              </ImageGroup>
            )}
          </Droppable>
        </DragDropContext>
        <VideoWrapper>
          <Label type="text" text="Product Video" required={false} />
          {videoLoader || values.productvideo ? (
            <VideoPreview>
              {videoLoader ? (
                <Loader size="large" />
              ) : (
                values.productvideo && (
                  <Fragment>
                    <VideoPlayerWithoutControls
                      videoUrl={values.productvideo}
                      thumbnailUrl={''}
                      title="product video"
                    />
                    <DeleteBtn type="button" onClick={() => removeVideoVal()}>
                      <VscTrash color="#000" />
                    </DeleteBtn>
                  </Fragment>
                )
              )}
            </VideoPreview>
          ) : (
            <FileUploader
              maxSize={80000000}
              placeholder={FileSVG}
              requiredText="(Only a single 20 seconds video can be drop here)"
              acceptFileType="video/mp4,video/mov,video/x-m4v,video/*"
              acceptFileTypeList={['video']}
              fileType="video"
              maxFiles={1}
              multiple={false}
              setFieldTouched={setFieldTouched}
              touched={touched}
              errors={errors}
              name={'productvideo'}
              callback={(acceptedFiles) => uploadVideo(acceptedFiles)}
            />
          )}
        </VideoWrapper>
      </StepContentStyle>
    </InnerContainerStyle>
  )
}

const Flex = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const ImageGroup = styled.div`
  display: flex;
  align-items: flex-start;
  //width: 100%;
  max-width: 100%;
  height: auto;
  flex-wrap: nowrap;
  overflow-x: auto;
  gap: 10.155px;
  padding-top: 20px;
  padding-bottom: 19px;
`

const ImageCard = styled.div`
  position: relative;
  display: block;
  width: 180px !important;
  height: 218px;
  margin-bottom: 8px;
  padding: 0px 0px !important;
  border-radius: 10px;
  //box-shadow:  0px 2px 21px -8px rgba(19, 19, 20, 0.4) !important;
  //background: #F5FBFF;

  .gripper {
    position: absolute;
    left: 0px;
    top: 1px;
    background-color: #eee;
    width: fit-content;
    padding-left: 2px;
    padding-right: 2px;
    padding-bottom: 2px;
    height: auto;
    z-index: 99;
    border-top-left-radius: 10px;
    box-shadow: 0px 2px 21px -8px rgba(19, 19, 20, 0.4) !important;

    svg {
      font-size: 1.2rem !important;
      color: #222;
    }
  }

  .dropdiv {
    width: 180px !important;
    padding: 2rem 2rem !important;
    height: 100% !important;

    label {
      display: block !important;
      word-wrap: break-word;
      white-space: break-spaces;
      span {
        font-size: 11px !important;
        word-wrap: break-word !important; /* Add this line to allow text wrapping */
      }
    }
  }
`

const ImagePreview = styled.div<{ isLoading?: string }>`
  position: relative;
  width: 180px !important;
  min-height: 218px;
  height: 100%;
  display: flex;
  align-items: center;
  border-radius: 10px;
  justify-content: center;
  background-color: #f5fbff;
  border: 1px solid rgb(221, 221, 221);
  padding: 0.5px 0px;
  overflow: hidden;
  transition: all 0.5s ease-in-out;

  img {
    width: 100%;
    height: 100%;
    min-height: 219px !important;
    border-radius: 10px;
    object-fit: cover;
  }

  svg {
    width: 24px;
    height: 24px;
  }
`

const VideoWrapper = styled.div`
  padding-bottom: 15px;
`

const VideoPreview = styled.div`
  position: relative;
  width: 100%;
  height: 235px;
  //height: auto;
  display: flex;
  align-items: center;
  border-radius: 10px;
  justify-content: center;
  background-color: #f5fbff;
  border: 1px solid rgb(221, 221, 221);

  .image-wrapper-div {
    height: 180px !important;
  }
`

const DeleteBtn = styled.button`
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: 999;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: #fff;
  border: 1px solid #eee;
  box-shadow: 0px 10px 21px 8px rgba(19, 19, 20, 0.2) !important;
`

export default ProductImages
