/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react'
import { Carousel as BCarousel } from "react-bootstrap";
import { Loader } from '@ekenta/components';
// import { Swiper, SwiperSlide } from 'swiper/react';

// // Import Swiper styles
// import 'swiper/css';
// import 'swiper/css/pagination';
// import 'swiper/css/navigation';

// import './styles.css';

// // import required modules
// import { Autoplay, Pagination, Navigation } from 'swiper/modules';
import { CarouselContainerStyle } from './styles';
import { useFetchGeneralBannerQuery } from '@ekenta/redux/store/services/productsApi';
import QlasonPlaceholder from '@ekenta/assets/icons/qlason-placeholder.svg';
//import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css';


const Carousel = () => {
	const allBanner = useFetchGeneralBannerQuery();
	const data = allBanner?.isSuccess ? allBanner?.data?.banners : null

	return (
		<CarouselContainerStyle placeholder={QlasonPlaceholder}>
			{/* <Swiper
				spaceBetween={0}
				centeredSlides={true}
				autoplay={{
					delay: 2500,
					disableOnInteraction: false,
				}}
				pagination={{
					clickable: true,
				}}
				navigation={true}
				modules={[Autoplay, Pagination, Navigation]}
				className="mySwiper"
			>
				<SwiperSlide>Slide 1</SwiperSlide>
				<SwiperSlide>Slide 2</SwiperSlide>
				<SwiperSlide>Slide 3</SwiperSlide>
				<SwiperSlide>Slide 4</SwiperSlide>
				<SwiperSlide>Slide 5</SwiperSlide>
				<SwiperSlide>Slide 6</SwiperSlide>
				<SwiperSlide>Slide 7</SwiperSlide>
				<SwiperSlide>Slide 8</SwiperSlide>
				<SwiperSlide>Slide 9</SwiperSlide>
			</Swiper> */}
			{allBanner?.isLoading ? <Loader size="large" /> : (
				<div className="ImageGroup__Style">
					<BCarousel indicators={true}>
						{data && data.length > 0
							? data.map((item: any) => (
								<BCarousel.Item key={item.id}>
									<a href={item.url} aria-details={item.title}>
										<img
											src={item.image_url}
											alt={item.title}
											data-creative={item.image_url}
											data-position={`slider_${item.slide_number}`}
											data-srcset={item.image_url}
										/>
									</a>
								</BCarousel.Item>
							)) : null}
					</BCarousel>
				</div>
			)}
		</CarouselContainerStyle>
	)
}

export default Carousel;