import * as Yup from 'yup';
import "yup-phone-lite";

const addNewAddressFormValidation = () => Yup.object().shape({
    
    stateId:Yup.string()
            .required('State is required'),
    cityId: Yup.string()
            .required('City is required'),
    streetAddress: Yup.string()
            .required('Street address is required'),
    phoneNumber: Yup.string()
            .phone("NG", "Provide a valid phone number")
            .min(14, '*Phone number must be 14 characters')
            .max(14, '*Phone number must be 14 characters')
           .required('*Phone Number is required'),
//     defaultAddress: Yup.bool()
//             .required('Set default is required'),
    
});

export default addNewAddressFormValidation;