/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import DashboardComponent from '../../pages/Dashboard/component/DashboardComponent';
// import NavigationBar from '../../components/NavigationBar/NavigationBar';
// import DashboardNavigationBar from '../../components/NavigationBar/Dashboard_NavigationBar';

export default function withDashboardLayout(
  WrappedComponent: () => JSX.Element,
  className?: string,
) {
  const DashboardLayout = (props: any) => {
    return (
      <>
        <DashboardComponent noStyle={false} full={false} className={className}>
          <WrappedComponent {...props} />
        </DashboardComponent>
      </>
    );
  };

  DashboardLayout.displayName = 'DashboardLayout';
  return DashboardLayout;
}
