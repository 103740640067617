import { device } from "@ekenta/utils/devices";
import styled from "styled-components";


export const HomeStyle = styled.div`
    width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	height: 100%;
	padding-top: 0em;
	padding-bottom: 8em;

  @media ${device.lg}{
	padding-top: 0.8em;
  }

`;

export const Row = styled.div`
  flex: 1;
  display: flex;
  align-items: stretch;
  justify-content: stretch;
  flex-wrap: nowrap;
	white-space: wrap;
  gap: 1em!important;
  padding-left: 5px;

`;


export const Container = styled.div`
  width: 100%;
  height: auto;
  max-width: var(--eke-container-maxWidth);


 
@media ${device.lg}{
	width: 95%;
	margin: 0 auto;
}

  @media ${device.xl}{
	width: var(--eke-container-width);
	margin: 0 auto;
}
  
`;

export const InsideBannerAdsStyle = styled.div`
    width: 95%;
	display: flex;
	align-items: center;
	flex-direction: column;
	gap: 20px;
	margin: 10px auto;

	a{
		flex: 1;
		width: 100%;
		display: inline-flex;
		
		@media ${device.lg}{
			width: 500px;
		}
	}

	@media ${device.md}{
	width: 100%;
	flex-direction: row;
}

`;

export const BannerImage = styled.img`
	width: 100%;
	object-fit: contain;
	object-position: center;
	height: auto;


`;