import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { LoginState } from "../initialState";

export const loginSlice = createSlice({
    name: 'login',
    initialState:LoginState,
    reducers: {
       redirectPath:(state,action: PayloadAction<{redirectPath: string}>) =>{
           state.redirectPath = action.payload.redirectPath;
       },
       removeRedirectPath:(state) => {
            state.redirectPath = null;
       },
    }
})

export const { redirectPath,removeRedirectPath } = loginSlice.actions;

export  default loginSlice.reducer;
