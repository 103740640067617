/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect } from "react"
import { useNavigate } from "react-router-dom";
import { Col, Container, Form, Row } from "react-bootstrap";
//import { returnRequestPathUrl } from "../../../../routes/constant";
import ProfileSideBar from "../component/AccountSideBar/AccountSideBar"
import BreadCrumb from "../component/BreadCrumb";
import { useCreateProductReturnRequestMutation, useFetchProductReturnRequestQuery } from "@ekenta/redux/store/services/accountApi";
import { useAppSelector } from "../../../../redux/store/hook";
import { toast } from "react-toastify";
import { HelmetHeader, PageTitle, Typography, Button, Label, Field, ButtonGroup, Loader, CustomAlert } from "@ekenta/components";
import withDefaultLayout from "@ekenta/hoc/Layout/withDefaultLayout";
import { VscArrowSmallLeft } from "react-icons/vsc";

import ListOrderItem from "./component/ListOrderItem/ListOrderItem";
import Scrollbars from "react-custom-scrollbars";
import CustomSelect from "@ekenta/components/Select/CustomSelect";
import { useFormik } from "formik";

import styles from "./NewReturnRequest.module.scss";
import { returnRequestPathUrl } from "@ekenta/routes/constant";

const NewReturnRequest = () => {

    const navigate = useNavigate();
    const { token } = useAppSelector(state => state.auth.authentication);

    const getAllOrders = useFetchProductReturnRequestQuery(token);
    const [createProductReturnRequest, getResult] = useCreateProductReturnRequestMutation();

    const formik = useFormik({
        initialValues: {
            orderItemId: "",
            quantity: "",
            reason: "",
            action: "",
            comment: ""
        },
        validationSchema: "",
        onSubmit: values => {
            const data = {
                order_item_id: values.orderItemId,
                quantity: values.quantity,
                reason: values.reason,
                action: values.action,
                comment: values.comment,
            };

            createProductReturnRequest({ data, token });
        }
    });

    useEffect(() => {
        if (getAllOrders?.isError
            && getAllOrders?.error.status === "FETCH_ERROR") {
            toast.error("Something went wrong", {
                toastId: "error-order-id-toast-error",
            });
        }
        if (getAllOrders?.isError
            && getAllOrders?.error.status === 500) {
            toast.error(`Something went wrong`, {
                toastId: "error-order-500-id-toast-error",
            });
        }

        if (getAllOrders?.isError && getAllOrders?.error.status === 401) {
            toast.error(`${getAllOrders?.error.data.message}`, {
                toastId: "error-isErr-order-id-toast-error",
            });
        }

    }, [
        getAllOrders?.error,
        getAllOrders?.isError
    ])


    useEffect(() => {
        if (getResult?.isSuccess) {
            toast.success(`${getResult?.data.message}`, {
                toastId: "success-create-return-id-toast",
            });
            navigate(returnRequestPathUrl);
        }

        if (getResult?.isError
            && getResult?.error.status === "FETCH_ERROR") {
            toast.error("Something went wrong", {
                toastId: "error-create-return-id-toast-error",
            });
        }
        if (getResult?.isError
            && getResult?.error.status === 500) {
            toast.error(`Something went wrong`, {
                toastId: "error-create-500-id-toast-error",
            });
        }

        if (getResult?.isError && getResult?.error.status === 401) {
            toast.error(`${getResult?.error.data.message}`, {
                toastId: "error-isErr-id-toast-error",
            });
        }

    }, [
        getResult?.isSuccess,
        getResult?.error,
        getResult?.isError
    ]);




    const minH = getAllOrders?.isError
        || getAllOrders?.isLoading ||
        getAllOrders?.data &&
        getAllOrders?.data.products.length < 2
        ? "150px" : "280px"

    const reasonOptions: any = [
        {
            label: "Received Wrong Product",
            value: "Received Wrong Product"
        },
        {
            label: "Damaged products",
            value: "Damaged products"
        },
        {
            label: "Wrong style, size, or color",
            value: "Wrong style, size, or color"
        },
        {
            label: "Late delivery",
            value: "Late delivery"
        },
        {
            label: "Change of mind",
            value: "Change of mind"
        }
    ];

    const actionOptions: any = [
        {
            value: "repair",
            label: "Repair"
        },
        {
            value: "change",
            label: "Change"
        }
    ];

    const isDisabled = getAllOrders?.isLoading ||
        getResult?.isLoading ||
        getAllOrders?.isError ||
        getAllOrders?.data && getAllOrders?.data.products.length > 0 ? false : true;


    const defaultValue = (options: any, val: string) => {
        return options.find((option: any) => option.label === val);
    }

    console.log(defaultValue(actionOptions, formik.values.action));

    function handleSelectedOrderItem(data: any) {
        formik.setFieldValue("orderItemId", data.orderItemId);
        formik.setFieldValue("quantity", data.quantity);
    }

    function mapOrdersReturnItem(data: any, orderNo: string) {
        return data.length > 0 && data.map((item: any) => (
            <div key={item.id}>
                <ListOrderItem
                    item={item}
                    orderNo={orderNo}
                    onSelect={handleSelectedOrderItem}
                    isLoading={getResult?.isLoading}
                />
                <div aria-hidden="true" className={styles.Hr_horizontal} />
            </div>
        ))
    }



    return (
        <>
            <HelmetHeader
                title="New Return Request"
                description="QlasonNew Return Request Account Page"
            />
            <div className={styles.ReturnRequest__Wrapper}>
                <Container className="account-user-container ">
                    <><BreadCrumb activePage="New Return Request" /></>

                    <Row className="account-pro-row g-2">
                        <ProfileSideBar isLoading={null} />
                        <Col className="account-profile-col-right">
                            <PageTitle
                                title="New Return Request"
                                margin={false}
                                subTitle={
                                    <span
                                        className="cursor-pointer d-flex w-lg-50 align-items-center 
                                        text-muted"
                                        onClick={() => navigate(-1)}>
                                        <VscArrowSmallLeft />
                                        Back to ReturnRequest
                                    </span>
                                }
                            />
                            <Col lg={12} className={styles.ReturnRequest__Content}>
                                <div className="_heading_tit">
                                    <Typography
                                        as="h5"
                                        size="base"
                                        transform="uppercase"
                                        uiText>
                                        Select an item you want to return
                                    </Typography>
                                </div>
                                <div aria-hidden="true" className={styles.Hr_horizontal} />

                                <div className={styles.List__OrderItems}>
                                    {getAllOrders?.isLoading ? <Loader isCenter size="large" /> : null}
                                    {getAllOrders?.isError ? (
                                        <CustomAlert
                                            variant="danger"
                                            show={true}
                                            message={"Something went wrong, Try reloading"}
                                        />
                                    ) : null}
                                    {getAllOrders?.isSuccess && getAllOrders?.data ? (
                                        <Scrollbars className={styles.ListOrderItem_Wrapper}
                                            style={{ width: "100%", height: 'auto', maxHeight: "300px", minHeight: `${minH}` }}>
                                            {getAllOrders?.data.products.length > 0
                                                ? getAllOrders?.data.products.map((item: any) => (
                                                    mapOrdersReturnItem(item.order_item, item.order_number,)
                                                )) : (
                                                    <>No order</>
                                                )}
                                        </Scrollbars>
                                    ) : null}
                                </div>

                                <div className="_heading_tit mb-4 mt-3">
                                    <Typography
                                        as="h5"
                                        size="base"
                                        transform="uppercase"
                                        uiText>
                                        Why are you returning these items?
                                    </Typography>
                                </div>
                                <div className="mx-auto _col__product">
                                    <Form>
                                        <div className={styles.MarginTop}>
                                            <Label
                                                text="Return Reason"
                                                required
                                            />
                                            <CustomSelect
                                                isDisabled={isDisabled}
                                                className="custom-select"
                                                placeholder="Return Reason"
                                                isLoading={getAllOrders?.isLoading}
                                                isSearchable={false}
                                                noOptionsMessage={() => ""}
                                                value={defaultValue(reasonOptions, formik.values.reason)}
                                                options={reasonOptions}
                                                onBlur={formik.handleBlur}
                                                onChanged={(value: any) => {
                                                    formik.setFieldValue("reason", value.label);
                                                }}
                                            />
                                        </div>

                                        <div className={styles.MarginTop}>
                                            <Label
                                                text="Return Action"
                                                required
                                            />
                                            <CustomSelect
                                                isDisabled={isDisabled}
                                                className="custom-select"
                                                placeholder="Return Action"
                                                isLoading={getAllOrders?.isLoading}
                                                isSearchable={false}
                                                noOptionsMessage={() => ""}
                                                value={defaultValue(actionOptions, formik.values.action)}
                                                options={actionOptions}
                                                onBlur={formik.handleBlur}
                                                onChanged={(value: any) => {
                                                    formik.setFieldValue("action", value.label);
                                                }}
                                            />
                                        </div>

                                        <div className={styles.MarginTop}>
                                            <Label
                                                text="Comment"
                                                required
                                            />
                                            <Field
                                                id="comment"
                                                name="comment"
                                                label=""
                                                disabled={isDisabled}
                                                type="textarea"
                                                size="large"
                                                required
                                                childOutsideLabel={false}
                                                placeholder="Comment"
                                                fieldClassName={""}
                                                autoComplete="off"
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.comment}
                                                isError={formik.touched.comment && formik.errors.comment ? true : false}
                                                error={formik.errors.comment}
                                            />
                                        </div>

                                        <ButtonGroup pos="right">
                                            <Button
                                                as="text"
                                                name="Cancel"
                                                shadow={false}
                                                type="button"
                                                disabled={getAllOrders?.isLoading}
                                                className={styles.Cancel__Button}
                                                onClick={() => {
                                                    navigate(-1);
                                                    window.scrollTo(0, 0);
                                                }}
                                            />
                                            <Button
                                                name="Save"
                                                bgVariant="primary"
                                                textVariant="light"
                                                className="py-2"
                                                disabled={isDisabled}
                                                isLoadingText="Save"
                                                isLoading={
                                                    getResult?.isLoading}
                                                shadow={false}
                                                type="submit"
                                                onClick={formik.handleSubmit}
                                            />
                                        </ButtonGroup>
                                    </Form>
                                </div>
                            </Col>
                        </Col>
                    </Row>
                </Container>

            </div>
        </>
    )
}

export default withDefaultLayout(NewReturnRequest);