import React from 'react'
import { ListGroup } from 'react-bootstrap'
import { FiArrowLeft } from 'react-icons/fi'
import { Link } from 'react-router-dom'
import { CSSTransition } from 'react-transition-group'
import { categoryBaseUrl, subBaseUrl } from '../../../../routes/constant'
import { slugifyText } from '../../../../utils'

export default function InnerCollection(props: any) {
  const _slugCategory = slugifyText(
    props.data.subcollectioncategory_name.toLowerCase(),
    props.data.subcollectioncategory_id
  )
  return (
    <CSSTransition
      in={props.activeMenu == 'inner'}
      unmountOnExit
      timeout={500}
      classNames="menu-inner"
    >
      <div className="listgroup__container_inner">
        <ListGroup>
          <div className="top__bar_return" onClick={props.goToMain}>
            <FiArrowLeft fontSize={20} />
            <span className="main__menu_back">Main Menu</span>
          </div>
          <div className="_header__group">
            <div className="collection___title">
              <h4>
                <Link
                  to={`${categoryBaseUrl}/${_slugCategory}`}
                  onClick={props.closeHandler}
                >
                  {props.data.subcollectioncategory_name}
                </Link>
              </h4>
            </div>
          </div>
          {props.data.childcollections &&
          props.data.childcollections.length > 0 ? (
            props.data.childcollections.map((item: any) => {
              const _slug = slugifyText(item.name.toLowerCase(), item.id)
              return (
                <ListGroup.Item
                  key={item.id}
                  as={Link}
                  to={`${subBaseUrl}/${_slug}`}
                  onClick={props.closeHandler}
                  className="d-flex justify-content-between align-items-start"
                >
                  <div className="ms-2 me-auto sub_collection">
                    <span>{item.name}</span>
                  </div>
                </ListGroup.Item>
              )
            })
          ) : (
            <span>No Sub Collection</span>
          )}
        </ListGroup>
      </div>
    </CSSTransition>
  )
}
