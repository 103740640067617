import React from 'react'
import classnames from 'classnames';
import { AvatarPropsType } from '@ekenta/interfaces';

import styles from "./Avatar.module.scss";

const Avatar = ({
    url,
    isCircle = true,
    title,
    className,
    height,
    width,
    imageclassName,
  }:AvatarPropsType) => {
    const classes = classnames("avatar", className, styles.avatar, {
      [styles.isCircle]: isCircle,
    });
  
    return (
        <span className={classes}>
          <img
            className={`js-avatar${imageclassName ? ` ${imageclassName}` : ""}`}
            src={url}
            alt={title}
            width={width}
            height={height}
          />
        </span>
    );
  };
  
  export default Avatar;