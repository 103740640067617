import React from 'react'
import styled from 'styled-components';
import { Carousel } from 'react-bootstrap';
import { Link } from 'react-scroll';
import { device } from '@ekenta/utils/devices';


const BannerAds = () => {
	return (
		<Container>
			<Carousel indicators={false} interval={10000}>
				<Carousel.Item>
					<div className="Center_Ads Round_popuP">
						<h5>15-Days Price Warranty for Qlason.com</h5>
						<Link activeClass="active" className="#15price_warranty"
							to="#15price_warranty" spy={true} duration={0} offset={-120}>
							<span>Learn more</span>
						</Link>
						{/* <span>1</span>: */}
					</div>
				</Carousel.Item>
				<Carousel.Item>
					<div className="Center_Ads Round_popuP">
						<h5>90 Days Exchange Without Repairs for Electronic Products</h5>
						<Link activeClass="active" className="#90days_exchange"
							to="#90days_exchange" spy={true} duration={0} offset={-120}>
							<span>Learn more</span>
						</Link>
						{/* <span>1</span>: */}
					</div>
				</Carousel.Item>
				<Carousel.Item>
					<div className="Center_Ads Round_popuP">
						<h5>30 Days Free Exchange for Apparels and Shoes</h5>
						<Link activeClass="active" className="#130days_free_exchange"
							to="#130days_free_exchange" spy={true} duration={0} offset={-120}>
							<span>Learn more</span>
						</Link>
						{/* <span>1</span>: */}
					</div>
				</Carousel.Item>
				<Carousel.Item>
					<div className="Center_Ads Round_popuP">
						<h5>24/7 Livestream Pharmacy Consulting Services on E-commerce</h5>
						<Link activeClass="active" className="#24_7_Livestream_pharmacy"
							to="#24_7_Livestream_pharmacy" spy={true} duration={0} offset={-120}>
							<span>Learn more</span>
						</Link>
						{/* <span>1</span>: */}
					</div>
				</Carousel.Item>
				<Carousel.Item>
					<div className="Center_Ads Round_popuP">
						<h5>24/7 Livestream Shopping Experience</h5>
						<Link activeClass="active" className="#24_7_Livestream_shopping"
							to="#24_7_Livestream_shopping" spy={true} duration={0} offset={-120}>
							<span>Learn more</span>
						</Link>
						{/* <span>1</span>: */}
					</div>
				</Carousel.Item>
				<Carousel.Item>
					<div className="Center_Ads Round_popuP">
						<h5>Trade-Ins on our E-commerce Website</h5>
						<Link activeClass="active" className="#trade_ins"
							to="#trade_ins" spy={true} duration={0} offset={-120}>
							<span>Learn more</span>
						</Link>
						{/* <span>1</span>: */}
					</div>
				</Carousel.Item>

			</Carousel>

		</Container>
	)
}


const Container = styled.div`
	display: flex;
	width: 100%;
	height: 48px;
	padding: 0px 0px;
	justify-content: center;
	text-align: center;
	align-items: center;
	background: var(--eke-color-secondary);

	
	


	@media ${device.lg}{
		padding: 0px 8rem;
		/* padding: 0px 186px; */
	}

	.carousel{
		position: relative;
		display: flex !important;
		width: 100% !important;
		margin:  0 auto;
		justify-content: center !important;
	    align-items: center !important;

	a.carousel-control-prev, 
	a.carousel-control-next{
		position: static !important;
		display: none !important;
	}

		.carousel-inner {
			width: auto !important;
			margin: 0 auto !important;
		}

		@media ${device.md}{
			width: 80% !important;
	   }

		@media ${device.lg}{
			width: 60% !important;
	   }
	}

	

	.Center_Ads{
		display: flex;
		align-items: center;
		justify-content: center;
		margin: 0 auto;
		width: 100%;
		text-align: center;
		gap: 0.5em;

		h5{
			display: inline-block;
			width: 70%;
			margin: 0 auto;
			height: auto;
			color: var(--eke-color-white);
			font-size: 13px;
			font-family: var(--eke-body-font-family);
			font-weight: var(--eke-font-weight-bold);
			line-height: 140%;
			letter-spacing: 0.2px;
			margin: 0;
			white-space: nowrap;
			text-overflow: ellipsis;
			overflow: hidden;


			@media ${device.lg}{
				font-size: 16px;
				width: auto;
	        }
		}


			a{
				text-decoration: none;
				display: flex;
				align-items: center;
				gap: 0.6em;
				cursor: pointer;
				color: var(--eke-color-primary);

				span{
					outline: none;
					border: none;
					display: flex;
					width: auto;
					height: 30px;
					padding: 0 10px;
					flex-direction: column;
					justify-content: center;
					align-items: center;
					gap: 10px;
					border-radius: 6px;
					background: var(--eke-color-primary);
					color: #FFF !important;
					font-size: 12px;
					font-family: var(--eke-body-font-family);
					font-weight: var(--eke-font-weight-medium);
					line-height: normal;
				}
			} 


			/* span{
				display: flex;
				width: 30px;
				height: 30px;
				flex-direction: column;
				justify-content: center;
				align-items: center;
				gap: 10px;
				border-radius: 6px;
				background: var(--eke-color-primary);
				color: #FFF !important;
				font-size: 12px;
				font-family: var(--eke-body-font-family);
				font-weight: var(--eke-font-weight-medium);
				line-height: normal;
			} */
		

		@media ${device.md}{
			width: auto;
			gap: 1.5em;
				h5{
					font-size: 14px;
					width: auto;

				}
	        }

		@media ${device.lg}{
			   width: auto;
			   gap: 1.9em;
				h5{
					font-size: 15px;
					width: auto;
					text-overflow: unset;
				}
	        }
	}
`;

export default BannerAds;