/* eslint-disable @typescript-eslint/no-explicit-any */
import { Accordion, CustomAlert, Typography } from "@ekenta/components";
import React, { useContext, useState, useEffect } from "react"
import styled, { css } from "styled-components";
import SVG from "react-inlinesvg";
import PriceRange from "./PriceRange/PriceRange";
import { CollectionContext } from "@ekenta/context/CollectionContext";
import Features from "./Features/Features";
import Rating from "./Rating/Rating";
import { useFetchBrandsByCollectionIdQuery } from "@ekenta/redux/store/services/vendorProductApi";
import BrandsFilter from "./Features/Brands";
import closeSVG from "@ekenta/assets/icons/close-square.svg";



const MobileFilters = (props: any) => {
    const { configureFilterUrl } = useContext(CollectionContext);
    const [brandIds, setBrandIds] = useState<any>([]);


    const getBrands = useFetchBrandsByCollectionIdQuery(props.collectionId, {
        skip: !props.collectionId,
    });

    useEffect(() => {
        if (brandIds.length > 0) {
            const data = { key: "brands", value: brandIds.join(","), set: true };
            configureFilterUrl(data);
        } else if (brandIds.length <= 0 && props.queryParam.brands) {
            configureFilterUrl({ key: "brands", value: "", set: false });
        }
    }, [brandIds]);


    const handleMobileFilter = (value: { checked: boolean; id: string }) => {
        if (value.checked) {
            setBrandIds((prevState: any) => [...prevState, value.id]);
        } else {
            const brandId_ = brandIds.filter((_id: any) => _id !== value.id);
            setBrandIds(brandId_);
        }
    }

    const handleDiscounted = (checked: boolean) => {
        let data = { key: "discounted", value: checked, set: true };
        if (checked) {
            data = { ...data, set: true }
        } else {
            data = { ...data, set: false }
        }
        configureFilterUrl(data);
        props.setShowPopover(() => ({ type: "", show: false }));

    }

    const handleIsLive = (checked: boolean) => {
        let data = { key: "is_live", value: checked, set: true };
        if (checked) {
            data = { ...data, set: true }
        } else {
            data = { ...data, set: false }
        }
        configureFilterUrl(data);
        props.setShowPopover(() => ({ type: "", show: false }));

    }

    return (
        <Container>
            <Flex topPos>
                <Typography
                    as="h5"
                    size="medium"
                    className="Typography_Heading"
                    color="dark"
                    heading>
                    Filter By
                </Typography>
                <IconButton onClick={props.onClose}>
                    <SVG src={closeSVG} />
                </IconButton>
            </Flex>

            <ColumnGroup>
                <Accordion
                    title="Price"
                    eventKey="0"
                    defaultActiveKey={['0']}
                    alwaysOpen
                >
                    <PriceRange
                        configureFilterUrl={configureFilterUrl}
                        setShowPopover={props.setShowPopover}
                    />
                </Accordion>
                <Accordion
                    title="Brands"
                    eventKey="1"
                    defaultActiveKey={['1']}
                    alwaysOpen
                >
                    <div className="brand__div">
                        {getBrands?.isSuccess && getBrands?.data ? (
                            <BrandsFilter
                                name="brand"
                                title="Brands"
                                id="brandID"
                                onChange={(value) => handleMobileFilter(value)}
                                data={{
                                    list: getBrands?.data.collections.brand,
                                }}
                            />
                        ) : (
                            <CustomAlert
                                variant="danger"
                                show={true}
                                message="Something went wrong"
                            />
                        )
                        }
                    </div>
                </Accordion>
                <Accordion
                    title="Discount"
                    eventKey="2"
                    defaultActiveKey={['2']}
                    alwaysOpen
                >
                    <Features
                        id="discountID"
                        name="discount"
                        title="Discount"
                        label="Only discount"
                        onChange={(e) => handleDiscounted(e.target.checked)}
                    />
                </Accordion>
                <Accordion
                    title="Ratings"
                    eventKey="3"
                    defaultActiveKey={['3']}
                    alwaysOpen
                >
                    <Rating
                        configureFilterUrl={configureFilterUrl}
                        setShowPopover={props.setShowPopover}
                    />
                </Accordion>
                <Accordion
                    title="Live"
                    eventKey="4"
                    defaultActiveKey={['4']}
                    alwaysOpen
                >
                    <Features
                        id="liveID"
                        name="live_items"
                        title="Live"
                        label="Only live"
                        onChange={(e: any) => handleIsLive(e.target.checked)}
                    />
                </Accordion>
                <Accordion
                    title="Available in Stock"
                    eventKey="5"
                    defaultActiveKey={['5']}
                    alwaysOpen
                >
                    <Features
                        id="stockID"
                        name="in_stock"
                        title="In_stock"
                        label="In stock"
                        onChange={(e: any) => console.log("In stock")}
                    />
                </Accordion>
            </ColumnGroup>
        </Container>
    )
}

const Container = styled.div`
    padding: 2em 1.5em 1em 1.5em;
    min-height: auto;

`;

const Flex = styled.div<{ topPos: boolean; }>`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    ${props => props.topPos ?
        css`
        padding-bottom: 25px;
        border-bottom: 1px solid #EEE;
    `:
        css`
          padding-top: 25px;
          border: none;
    `}

     .Typography_Heading{
        margin: 0 !important;
        color: #000 !important;
        font-size: 20px;
        font-family: var(--eke-body-font-family);
        font-weight: var(--eke-font-weight-bold) !important;
        line-height: 120%; /* 24px */
    }

`;

const ColumnGroup = styled.div`
     width: 100%;
      display: flex;
     padding-top: 20px;
    gap: 21px;
    flex-direction: column;
    padding-bottom: 25px;

     .accordion-button{
        padding-top: 10px !important;
        padding-left: 2px !important;
        padding-right: 2px !important;
        background: transparent !important;
        border: none !important;
        outline: none !important;
        box-shadow: none !important;
        color: #000 !important;
        font-size: 18px;
        line-height: 120%; /* 21.6px */
        line-height: 20px;
        font-weight: var(--eke-font-weight-bold) !important;
        font-family: var(--eke-body-font-family) !important;
     }

     .accordion-item {
        border:0;
        border-radius: 0 !important;
        border-bottom: 0.0625rem solid #eee;
        width: 100%;
     }

     .accordion-button::after{
        fill: transparent !important;
    }
    .accordion-body{
        padding-top: 0 !important;
        padding-left: 2px !important;
        padding-right: 1px !important;
        margin-left: auto;
        margin-right: auto;
    }

    .price_container_, .features_group_wrap, .RatingDiv__Wrapper{
        h3{display: none};
    }

    .features_group_wrap, .RatingDiv__Wrapper{ 
        margin-top: 5px !important;
    }

     .price_container_{
        .price_range_div{
           width: 100%;
           input{ width: 50% !important; height: 40px;}
           //.Button{height: 40px; width: 50px !important;}
        }
     }

    .ClearFilter{
        padding-left: 16px;
        padding-right: 16px;
        display: flex;
        flex-direction: column;
        justify-content: center;;
        width: 100%;

        button{
            background-color: transparent !important;
            background: #0000;
            color: #2e2f32;
            text-decoration: underline;
            margin-left: auto;
            margin-right: auto;
        }
    }

    .brand__div{
        .list-group{
            gap: 8px;
        }
    }

`;


const IconButton = styled.button`
    width: auto;
    outline: none;
    border: none;
    background-color: transparent !important;
`;

export default MobileFilters