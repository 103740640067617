import React, { useState } from 'react';
import { Button, Card, Col } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import { FiCheckCircle } from 'react-icons/fi';
import TableComponent from '../../../components/Dashboard/components/TableComponent';
import DashboardComponent from '../component/DashboardComponent';
import DashboardLayout from '../../../hoc/Layout/Dashboard-Layout';


const LiveData: any[] = [
  // {
  //   name: 'Computer, Desktop',
  //   ordertotal: '3'
  // },
  // {
  //   name: 'Computer, Desktop',
  //   ordertotal: '3'
  // },
  // {
  //   name: 'Electronics,  Camera & photo',
  //   ordertotal: '2'
  // },
  // {
  //   name: 'Fashion, clothing',
  //   ordertotal: '5'
  // }
]

export default function DashboardCollection({ collapsed, toggled, handleToggleSidebar, handleCollapsedChange }: any) {

  const mapData = LiveData.map((items, index) => ({
    no: index + 1,
    ...items,
    published: <FiCheckCircle className="cursor-pointer text-primary" fontSize={15} />,
    ordertotal: items.ordertotal,
  }))
  const [data, setData] = useState(mapData);

  const [_pageSize, setPageSize] = useState(5);

  const columns = [
    {
      Header: 'No',
      accessor: 'no',
      sortType: 'basic',
    },
    {
      Header: 'Name',
      accessor: 'name',
      sortType: 'basic',
    },
    {
      Header: 'Published',
      accessor: 'published',
      sortType: 'basic',
    },
    {
      Header: 'Order Total',
      accessor: 'ordertotal',
      sortType: 'basic',
    }
  ]

  return (
    <DashboardLayout>
      <Helmet>
        <title>Seller Dashboard - Qlason</title>
        <meta charSet="utf-8" />
        <meta name="title" content="QlasonAll Product Page" />
        <meta name="description"
          content="QlasonAll Product  Page"
        />
        <meta property="og:locale" content="en_NG" />
        <meta name="robots" content="noindex,follow" />
      </Helmet>

      <DashboardComponent
        collapsed={collapsed}
        toggled={toggled}
        handleToggleSidebar={handleToggleSidebar}
        handleCollapsedChange={handleCollapsedChange}
      >
        <Col className=" mt-1 px-2">
          <div className="mt-0">
            <h3 className="display_lg_header">Collection</h3>
            <Col className="mt-4">
              <Card>
                <Card.Body>
                  <TableComponent columns={columns} data={data} pageSizeno={_pageSize} />
                </Card.Body>
              </Card>
            </Col>
          </div>
        </Col>
      </DashboardComponent>
    </DashboardLayout>
  )
}
