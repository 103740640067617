import React from 'react';
import { HelmetHeader } from '@ekenta/components';
import { withDashboardLayout } from '@ekenta/hoc';

const AddVideo = () => {


  return (
    <>
      <HelmetHeader
        title="Add Video Dashboard - Qlason.com"
        description="QlasonAdd Video Dashboard Page"
      />

      <div className="_listing_product_">
        <div className="mt-0">
          <hr />
          <h3 className="display_lg_header">
            {/* <FiChevronLeft /> */}
            Manage Videos
          </h3>
          <hr />
        </div>
      </div>
    </>
  );
};

export default withDashboardLayout(AddVideo);
