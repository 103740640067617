/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { Col, ListGroup } from 'react-bootstrap';
import { FiChevronLeft } from 'react-icons/fi';
import {
  categoryBaseUrl,
  collectionBaseUrl,
  collectionPathUrl,
  subBaseUrl,
} from '../../../../routes/constant';
import { slugifyText } from '../../../../utils';
import { Loader, RefreshLink, Typography } from '@ekenta/components';

interface BrowseCollectionPropsType {
  collectionData: any;
  isCollectionLoading: boolean;
  subsingleData: any;
  subIsLoading: boolean;
  subIsSuccess: boolean;
  subCategoryData: any;
  subCategoryIsLoading: boolean;
  subCategoryIsSuccess: boolean;
}

export default function BrowseCollection(props: BrowseCollectionPropsType) {
  const urlParams = new URLSearchParams(window.location.search);
  const myParam = urlParams.get("isIkitchen")

  const _slugCollection = props.subCategoryData
    ? slugifyText(
      props.subCategoryData?.sub_collection_category.collectionName.toLowerCase(),
      props.subCategoryData?.sub_collection_category.collection_id
    )
    : '/';

  const _slugCategory = props.subsingleData
    ? slugifyText(
      props.subsingleData?.sub_collection.sub_collection_category.name.toLowerCase(),
      props.subsingleData?.sub_collection.sub_collection_category.id
    )
    : '/';

  return (
    <Col className="all_list">
      <Typography
        as="h3"
        heading
        className="filtersHeading"
      >
        Browse Categories
      </Typography>
      <ListGroup>
        <ListGroup.Item className="text-left">
          <RefreshLink
            to={collectionPathUrl}
            replace
            className="d-flex align-items-center item--child_div">
            <FiChevronLeft />
            <span>All Categories</span>
          </RefreshLink>

          {props.isCollectionLoading ? (
            <div className="ms-2 mt-1"><Loader /></div>
          ) : props.collectionData && (
            <div className="ps-3 mt-1">
              <div className="dr_sub_bold__">
                <span>{props.collectionData.collection.name.toLowerCase()}</span>
              </div>
              <div className="ps-2 mt-1 collItem--child_div">
                <ListGroup>
                  {props.collectionData.collection.sub_collection_category.map((item: any) => {
                    const _slugSubcategory = item
                      ? slugifyText(item.name.toLowerCase(), item.id)
                      : '/';
                    return (
                      <RefreshLink
                        className="single__child_"
                        key={item.id}
                        to={`${categoryBaseUrl}/${_slugSubcategory}${myParam !== null ? '?isIkitchen=true' : ''}`}
                        state={{ newPath: true, url: `${categoryBaseUrl}/${_slugSubcategory}${myParam !== null ? '?isIkitchen=true' : ''}` }}
                        replace
                      >
                        <ListGroup.Item>{item.name}</ListGroup.Item>
                      </RefreshLink>
                    );
                  })}
                </ListGroup>
              </div>
            </div>
          )}


          {props.subCategoryIsLoading ? (
            <div className="ms-2 mt-1">  <Loader /></div>
          ) : (
            props.subCategoryIsSuccess && (
              <div className="ps-3 mt-1">
                <RefreshLink
                  to={`${collectionBaseUrl}/${_slugCollection}${myParam !== null ? '?isIkitchen=true' : ''}`}
                  state={{ "newPath": true, url: `${collectionBaseUrl}/${_slugCollection}${myParam !== null ? '?isIkitchen=true' : ''}` }}
                  replace
                  className="d-flex align-items-start item--child_div dr_sub_bold__ mb-2">
                  <FiChevronLeft className="ms-1" />
                  <span>{props.subCategoryData.sub_collection_category.collectionName}</span>
                </RefreshLink>

                <div className="ps-4 dr_sub_bold__ mb-1">
                  <span>{props.subCategoryData.sub_collection_category.name}</span>
                </div>
                <div className="mt-2 collItem--child_div m" style={{ paddingLeft: "2.5em" }}>
                  <ListGroup>
                    {props.subCategoryData.sub_collection_category.sub_collection.map((item: any) => {
                      const _slugSubcollection = item
                        ? slugifyText(item.name.toLowerCase(), item.id)
                        : '/';
                      return (
                        <RefreshLink
                          className="single__child_"
                          key={item.id}
                          to={`${subBaseUrl}/${_slugSubcollection}${myParam !== null ? '?isIkitchen=true' : ''}`}
                          state={{ newPath: true, url: `${subBaseUrl}/${_slugSubcollection}${myParam !== null ? '?isIkitchen=true' : ''}` }}
                          replace
                        >
                          <ListGroup.Item>{item.name}</ListGroup.Item>
                        </RefreshLink>
                      );
                    })}
                  </ListGroup>
                </div>
              </div>
            )
          )}

          {props.subIsLoading ? (
            <div className="ms-2 mt-1">  <Loader /></div>
          ) : (
            props.subIsSuccess && (
              <div className="ps-3 mt-1">
                <RefreshLink
                  to={`${categoryBaseUrl}/${_slugCategory}${myParam !== null ? '?isIkitchen=true' : ''}`}
                  state={{ "newPath": true, url: `${categoryBaseUrl}/${_slugCategory}${myParam !== null ? '?isIkitchen=true' : ''}` }}
                  replace
                  className="d-flex align-items-start item--child_div dr_sub_bold__"
                >
                  <FiChevronLeft className="ms-1" />
                  <span>{props.subsingleData.sub_collection.sub_collection_category.name}</span>
                </RefreshLink>
                <div className="ps-4 mt-1 drop--list_sub">
                  <ListGroup>
                    <ListGroup.Item>{props.subsingleData.sub_collection.name}</ListGroup.Item>
                  </ListGroup>
                </div>
              </div>
            )
          )}
        </ListGroup.Item>
      </ListGroup>
    </Col>
  );
}
