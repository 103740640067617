/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react'
import styled from 'styled-components'
import SearchBar from '../SearchBox'
import Select from '../Select/SelectDrop'
import Button from '../Button/Button'
import { SearchBtnStyle, SelectStyle } from './style'

const NavigationSearchBar = (props: any) => {
  const {
    formik,
    getSearchResult,
    showAutocomplete,
    handleKeyup,
    searchLists,
    setShowAutocomplete,
    autoCompleteData,
  } = props

  return (
    <Container>
      <Form noValidate onSubmit={formik.handleSubmit}>
        <SearchBar
          name="search"
          value={formik.values.search}
          isLoading={getSearchResult?.isLoading}
          isSuccess={getSearchResult?.isSuccess}
          isError={getSearchResult?.isError}
          autoCompleteData={getSearchResult?.data ? autoCompleteData() : null}
          onChange={formik.handleChange}
          category={formik.values.by.name}
          showAutocomplete={showAutocomplete}
          setShowAutocomplete={setShowAutocomplete}
          onKeyUp={handleKeyup}
          setValue={formik.setFieldValue}
          renderPrefix={
            <SelectStyle>
              <Select
                className="Select__Container"
                width=""
                lists={searchLists}
                selected={formik.values.by}
                setSelected={(value: any) => {
                  formik.setFieldValue('by', value)
                }}
              />
            </SelectStyle>
          }
          renderSuffix={
            <SearchBtnStyle>
              <Button
                name="Search"
                bgVariant="primary"
                shadow={false}
                type="submit"
                disabled={!formik.values.search}
              />
            </SearchBtnStyle>
          }
        />
      </Form>
      {showAutocomplete ? (
        <div
          className="SearchAutocomplete__Overlay__Style"
          onClick={() => setShowAutocomplete(false)}
        ></div>
      ) : null}
    </Container>
  )
}

const Container = styled('div')`
  width: auto;
  position: relative;

  .SelectDropdown_div_Style {
    display: none !important;

    /* @include mixin.laptop(){
         display: block !important;
     } */
  }

  .SearchAutocomplete__Overlay__Style {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 999;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.5);
  }
`

const Form = styled.form`
  display: flex;
  align-items: center;
  justify-content: center;
`
export default NavigationSearchBar
