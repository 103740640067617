import React, { useState, useEffect, useCallback } from 'react';
import { toast } from 'react-toastify';

const withNetworkDetector = (WrappedComponent:() => JSX.Element) => {
 
  const NetworkDetector = (props:any) => {
    const [isDisconnected, setIsDisconnected] = useState(false);

    const handleConnectionChange = useCallback(() => {
      const condition = navigator.onLine ? 'online' : 'offline';
      if (condition === 'online') {
        setIsDisconnected(false);
      } else {
        setIsDisconnected(true);
      }
    }, [navigator]);

    useEffect(() => {
      handleConnectionChange();
      window.addEventListener('online', handleConnectionChange);
      window.addEventListener('offline', handleConnectionChange);

      return () => {
        window.removeEventListener('online', handleConnectionChange);
        window.removeEventListener('offline', handleConnectionChange);
      };
    }, [handleConnectionChange]);

    useEffect(() => {
      if (isDisconnected) {
        toast.error(`No internet connection.`, {
          autoClose: isDisconnected ? false : true,
          position: toast.POSITION.BOTTOM_CENTER,
          // generate random id for each toast
          toastId: `${Math.random()}`,
        });
      } else {
        toast.dismiss();
      }
    }, [isDisconnected]);

    return <WrappedComponent {...props} />;
  };
  NetworkDetector.displayName = 'NetworkDetector'
  return NetworkDetector;
};

export default withNetworkDetector;

