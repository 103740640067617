import React from "react"
import { Avatar, DataTable, HelmetHeader, Loader, Overlay, PageTitle, Switch, Typography } from "@ekenta/components"
import { Col, Row } from "react-bootstrap"
import { VscArrowSmallLeft } from "react-icons/vsc"
import { useNavigate, useParams } from "react-router-dom"
import Card from "../UserManagement/components/Card"
import avatar from "@ekenta/assets/images/avatar.png";

import styles from "./SellerProfile.module.scss";
import classnames from "classnames"
import DashboardComponent from "../../component/DashboardComponent"
import { useGetSellerProfileQuery } from "@ekenta/redux/store/services/adminSellerApi"
import { useAppSelector } from "@ekenta/redux/store/hook"

const SellerProfile = () => {
  const navigate = useNavigate();
  const params = useParams();

  const { token } = useAppSelector((state) => state.auth.authentication);

  const userDetails = useGetSellerProfileQuery({ token, sellerId: params.id });


  const columns = [
    {
      Header: "ProductName",
      accessor: "name",
      sortType: "basic",
    },
    {
      Header: "Collection",
      accessor: "collection",
      sortType: "basic",
    },
    {
      Header: "Brand",
      accessor: "brand",
      sortType: "basic",
    },
    {
      Header: "Price",
      accessor: "price",
      disableSortBy: true,
    },
    {
      Header: "Quantity",
      accessor: "stock",
      disableSortBy: true,
    },
    {
      Header: "Discount",
      accessor: "discount",
      disableSortBy: true,
    },
    // {
    //   Header: "Status",
    //   accessor: "status",
    //   disableSortBy: true,
    // }
  ];

  return (
    <DashboardComponent full={true}>
      <div className={styles.SellerProfile_Container}>
        <HelmetHeader
          title="Seller Profile"
          description="QlasonAll Active Sellers Page"
        />
        <Col className=" col-lg-12 dash_top mt-0 px-2">
          <div className="mt-0">
            <div className="AppEvents_col mx-1 mt-0 d-block d-lg-none">
              <PageTitle
                title="Seller Profile"
                subTitle={
                  <span
                    className="cursor-pointer d-flex w-auto align-items-center 
                    text-muted"
                    onClick={() => navigate(-1)}>
                    <VscArrowSmallLeft />
                    Back
                  </span>
                }
              />
            </div>
            <Row className={styles.SellerProfile_Row}>
              {
                userDetails.isLoading ?
                  <Overlay bgColor="light">
                    <div
                      className="d-flex align-items-center 
                      justify-content-end mx-auto w-25 p-4"
                    >
                      <Loader withWrapper />
                    </div>
                  </Overlay> :
                  <>
                    <Col xs={12} lg={2} className={classnames(styles.SellerProfile_RowLeft, "px-0")}>
                      <div className={classnames(styles.AvatarImg_Icon, " mb-4")}>
                        <div className={styles.AvatarImg}>
                          <Avatar
                            url={avatar}
                            title="Seller"
                            imageclassName={styles.Img}
                          />
                        </div>
                      </div>

                      <div className={styles.CollectionConfig__Sec}>
                        {/* <div className={classnames(styles.CollectionSec, " mt-4")}>
                          <Typography
                            id="Typography_h5"
                            as="h5" heading>
                            Collections Configure
                          </Typography>
                          <div className={styles.CollectionConfig}>
                            <Typography
                              id="Typography_h6"
                              as="h6"
                              uiText
                              className={styles.Typography_h6}
                            >
                              Can Add Collection
                            </Typography>
                            <Switch
                              value={false}
                              outerClass={styles.outerClass}
                              innerClass={styles.innerClass}
                              activeColor={styles.activeColor}
                              innerPos={styles.innerPos}
                              onChange={() => console.log("noo")}
                            />
                          </div>
                        </div> */}

                        <div className={classnames(styles.SellerCollection, "mt-4")}>
                          <Typography
                            id="Typography_h5"
                            as="h5" heading>
                            Seller Collections
                          </Typography>
                          <div className={styles.SellerCollection__Child}>
                            {userDetails.data?.collections.map((item: any, i: any) => (
                              <div key={i} className={styles.SellerCollection__ChildItem}>
                                <Typography
                                  id="Typography_h6"
                                  as="h6"
                                  uiText
                                  className={styles.Typography_h6}
                                >
                                  {item.collection}
                                </Typography>
                                <Switch
                                  value={true}
                                  outerClass={styles.outerClass}
                                  innerClass={styles.innerClass}
                                  activeColor={styles.activeColor}
                                  innerPos={styles.innerPos}
                                  onChange={() => console.log("noo")}
                                />
                              </div>
                            ))}

                          </div>
                        </div>
                      </div>

                    </Col>
                    <Col xs={12} lg={10} className={styles.SellerProfile_RowRight}>
                      <div className="AppEvents_col mx-1 mx-lg-0 d-none d-lg-block">
                        <PageTitle
                          title={`Seller Profile: ${userDetails.data.user?.merchant_business?.business_name}`}
                          subTitle={
                            <span
                              className="cursor-pointer d-flex w-auto align-items-center 
                              text-muted"
                              onClick={() => navigate(-1)}>
                              <VscArrowSmallLeft />
                              Back
                            </span>
                          }
                        />
                      </div>
                      <div className="CardSection mb-4">
                        <Row>
                          <Col xs={12} lg={6}>
                            <Card
                              name={"Total Product"}
                              value={userDetails.data?.products.length}
                            />
                          </Col>
                          <Col xs={12} lg={6}>
                            <Card
                              name={"Published Product"}
                              value={userDetails.data?.publishedProduct}
                            />
                          </Col>
                        </Row>
                      </div>
                      <div className="Data-table">
                        <div className="d-flex align-items-center justify-content-end">
                        </div>
                        <div className="table mt-4">
                          <DataTable
                            theme="simple"
                            data={userDetails.data?.products}
                            loading={false}
                            isSelectable={true}
                            columns={columns}
                            showPagination={false}
                            hasLink={true}
                            thClassName="w-25"
                            trClassName="w_Tr"
                            tdClassName={`Row__col`}
                          />
                        </div>
                      </div>

                    </Col>
                  </>
              }

            </Row>
          </div>
        </Col>
      </div>
    </DashboardComponent>
  )
}

export default SellerProfile;
