/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react'

import { Card, ListGroup } from 'react-bootstrap';
import { CustomAlert, Typography } from '@ekenta/components';
import defaultImg from "@ekenta/assets/images/default_livestream.webp";
import styles from "./MoreLives.module.scss";
import { Link } from 'react-router-dom';
import LiveIconComp from '@ekenta/components/LiveCommerce/LiveIconComp';
import { singLiveBaseUrl } from '@ekenta/routes/constant';

const MoreLives = (props: {
    data: any;
    isLoading: boolean;
    isError?: boolean;
    isSuccess: boolean;
    isLiveType: string;
}) => {

    const { data, isLoading, isError, isLiveType, isSuccess } = props;

    const mapAllLiveSellers = (data: any, type: any) => {
        return (
            data.merchant_business.length > 0 &&
            data.merchant_business.map((result: any, index: number) => {
                return (
                    <ListGroupItem
                        key={`id_` + index}
                        id={result.id}
                        type={type === "isLive" ? "isLive" : "isEkeLive"}
                        className="item col__cardb-wrapper"
                        imageUrl={
                            type === "isLive" && result.thumbnailUrl
                                ? result.merchant_business.thumbnail_url
                                : type === "isEkeLive" && result.merchant_business.thumbnail_url
                                    ? result.merchant_business.thumbnail_url
                                    : defaultImg
                        }
                        name={
                            type === "isLive" ? result.merchant_business.business_name : result.merchant_business.business_name
                        }
                    />
                );
            })
        );
    };


    return (
        <ListGroup className={styles.UL_ListGroup}>
            {isError ? (
                <div className="p-2">
                    <CustomAlert
                        variant="danger"
                        show={true}
                        message={"Something went wrong, Try again"}
                    />
                </div>
            ) : null}
            {isLoading ? <>Loading..</> : null}
            {isSuccess ? <>{mapAllLiveSellers(data, isLiveType)}</> : null}
        </ListGroup>
    )
}

const ListGroupItem = (props:
    {
        type: string;
        id: string;
        name: string;
        className: string;
        imageUrl: string
    }) => {



    return (
        <ListGroup.Item className={styles.Item_LI} as={"li"}>
            <Card className={styles.CardBox}>
                <div className="position-relative">
                    <Card.Body className={styles.CardBody}>
                        <LiveIconComp />
                        <div className={styles.ImageDiv}>
                            <Link to={`${singLiveBaseUrl}/spm-${props.id}?type=${props.type}`} target="_blank">
                                <Card.Img variant="top" src={props.imageUrl} />
                            </Link>
                        </div>
                        <Link className={styles.ContentGroup} to={`${singLiveBaseUrl}/spm-${props.id}?type=${props.type}`} target="_blank">
                            <Typography
                                as="h5"
                                size="small"
                                color="secondary"
                                className={styles.TitleH5}
                                heading>
                                Live with {props.name}!
                            </Typography>
                            <div className={styles.GroupSection}>
                                <div>
                                    <Typography
                                        as="h5"
                                        size="small"
                                        margin="none"
                                        color="secondary"
                                        className={styles.BusinessTitle}
                                        uiText>
                                        {props.name}
                                    </Typography>
                                    <Typography
                                        as="span"
                                        size="small"
                                        margin="none"
                                        color="grey"
                                        className={styles.Small}
                                        uiText>
                                        Qlason Livestream
                                    </Typography>
                                </div>
                            </div>
                        </Link>
                    </Card.Body>
                </div>
            </Card>
        </ListGroup.Item>
    )
}

export default MoreLives 