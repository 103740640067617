/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect } from 'react'
import { getIn, useFormikContext } from 'formik'
import { NewProductListingFormType } from '@ekenta/interfaces/ProductListing'
import {
  HStack,
  StepContainer,
  StepContentStyle,
  StepFooter,
  StepFormGroup,
  StepHeading,
} from '../style'
import { Button, Field, Typography } from '@ekenta/components'
import { VscArrowSmallRight } from 'react-icons/vsc'

const ProductPrices = (props: { nextPage: () => void; isLoading: boolean }) => {
  const { nextPage, isLoading } = props
  const {
    values,
    errors,
    touched,
    setFieldValue,
    setFieldTouched,
    handleBlur,
  } = useFormikContext<NewProductListingFormType>()

  //const [inValidMax, setInValidMax] = useState(false)
  // const checkMax = () => {
  //     if (values.maximumprice < values.defaultprice) {
  //         setInValidMax(true)
  //     } else {
  //         setInValidMax(false)
  //     }
  // }

  useEffect(() => {
    if (!values.discountedPrice) {
      setFieldValue('discount', 0)
    }
  }, [values.discountedPrice])

  const handleDiscountCalculate = (price: number) => {
    const discountAmount = values.defaultprice - price
    const percentage = (discountAmount / values.defaultprice) * 100
    if (price > 0 && price <= values.defaultprice) {
      setFieldValue('discount', percentage.toFixed(2))
    } else {
      setFieldValue('discount', 0)
    }
  }

  const handlePriceEvaluation = (
    defaultprice: number,
    quantity: number,
    vat: number
  ) => {
    console.log(vat, 'vat')
    const totalwithoutvta: number = defaultprice * quantity
    setFieldValue('totalprice', totalwithoutvta)
    setFieldValue('bulkprice', totalwithoutvta)
    //setFieldValue('discountedPrice', defaultprice);
    setFieldValue('maximumprice', defaultprice)
  }

  return (
    <StepContainer>
      <StepHeading>
        <Typography as="h3" color="default" size="xlarge" heading>
          Product Prices
        </Typography>
        <Typography as="p" color="default" size="small">
          Add Prices for your product
        </Typography>
      </StepHeading>
      <StepContentStyle h="auto">
        <HStack pt="3px">
          <StepFormGroup>
            <Field
              id="defaultprice"
              name="defaultprice"
              label="Price"
              disabled={false}
              type="number"
              required
              size="large"
              numberFieldPrefix="₦ "
              thousandSeparator={true}
              childOutsideLabel={false}
              placeholder="Enter Default Price"
              fieldClassName=""
              autoComplete="off"
              onBlur={handleBlur}
              value={`${values.defaultprice}`}
              isError={
                touched.defaultprice && errors.defaultprice ? true : false
              }
              error={errors.defaultprice}
              onChange={(value: any) => {
                setFieldValue('defaultprice', value.value)
                handlePriceEvaluation(
                  parseInt(value.value),
                  values.quantity,
                  values.vatcharges
                )
              }}
            />
          </StepFormGroup>
          <StepFormGroup>
            <Field
              id="quantity"
              name="quantity"
              label="Quantity"
              disabled={false}
              type="number"
              required
              size="large"
              childOutsideLabel={false}
              placeholder="Enter Quantity"
              fieldClassName=""
              autoComplete="off"
              onBlur={handleBlur}
              value={`${values.quantity}`}
              isError={touched.quantity && errors.quantity ? true : false}
              error={errors.quantity}
              onChange={(value: any) => {
                if (value.floatValue !== undefined) {
                  setFieldValue('quantity', value.floatValue)
                  handlePriceEvaluation(
                    values.defaultprice,
                    parseInt(value.value),
                    values.vatcharges
                  )
                }
              }}
            />
          </StepFormGroup>
          <StepFormGroup>
            <Field
              id="vatcharges"
              name="vatcharges"
              label="VAT Charges"
              disabled={true}
              type="number"
              required
              size="large"
              numberFieldPrefix="% "
              thousandSeparator={true}
              childOutsideLabel={false}
              placeholder="Enter Vat Charges"
              fieldClassName=""
              autoComplete="off"
              onBlur={handleBlur}
              value={`${values.vatcharges}`}
              isError={touched.vatcharges && errors.vatcharges ? true : false}
              error={errors.vatcharges}
              onChange={() => console.log('')}
            />
          </StepFormGroup>
        </HStack>
      </StepContentStyle>
      <StepContentStyle h="auto">
        <StepHeading>
          <Typography as="h3" color="default" size="xlarge" heading>
            Make discount offer
          </Typography>
        </StepHeading>
        <HStack pt="3px">
          {/* <StepFormGroup>
                        <Field
                            id="maximumprice"
                            name="maximumprice"
                            label="Discounted Price"
                            disabled={false}
                            type="number"
                            required
                            size="large"
                            numberFieldPrefix="₦ "
                            minimumNum={values.defaultprice}
                            thousandSeparator={true}
                            childOutsideLabel={false}
                            placeholder="Enter Default Price"
                            fieldClassName=""
                            autoComplete="off"
                            onBlur={checkMax}
                            value={`${values.maximumprice}`}
                            isError={touched.maximumprice && errors.maximumprice ? true : false || !!inValidMax}
                            error={
                                errors.maximumprice ||
                                    inValidMax ? " Maximum price must be equal to or more than default price" : ""
                            }
                            onChange={(value: any) => {
                                setFieldValue('maximumprice', value.value);
                                handleMaximum(parseInt(value.value));
                            }}
                        />
                    </StepFormGroup> */}
          <StepFormGroup>
            {/* before Min Price to Discounted Price */}
            <Field
              id="discountedPrice"
              name="discountedPrice"
              label="Discounted Price"
              disabled={false}
              type="number"
              required={false}
              size="large"
              numberFieldPrefix="₦ "
              minimumNum={0}
              thousandSeparator={true}
              childOutsideLabel={false}
              placeholder="Enter Discounted Price"
              fieldClassName=""
              autoComplete="off"
              onBlur={handleBlur}
              value={`${values.discountedPrice}`}
              isError={
                getIn(touched, 'discountedPrice') &&
                getIn(errors, 'discountedPrice')
                  ? true
                  : false
              }
              error={errors.discountedPrice}
              onChange={(value: any) => {
                setFieldValue('discountedPrice', value.value)
                handleDiscountCalculate(parseInt(value.value))
              }}
            />
          </StepFormGroup>
          <StepFormGroup>
            <Field
              id="discount"
              name="discount"
              label="Discount % (Optional)"
              disabled={true}
              type="number"
              required={false}
              size="large"
              minimumNum={0}
              numberFieldPrefix="% "
              childOutsideLabel={false}
              placeholder="Enter Discount Price"
              fieldClassName=""
              autoComplete="off"
              onBlur={handleBlur}
              value={`${values.discount}`}
              isError={touched.discount && errors.discount ? true : false}
              error={errors.discount}
            />
          </StepFormGroup>
          <StepFormGroup>
            <Field
              id="totalprice"
              name="totalprice"
              label="Total Price (INC. VAT)"
              disabled={true}
              type="number"
              required
              size="large"
              numberFieldPrefix="₦ "
              thousandSeparator={true}
              childOutsideLabel={false}
              placeholder="Enter Total Price"
              fieldClassName=""
              autoComplete="off"
              onBlur={handleBlur}
              value={`${values.totalprice}`}
              isError={touched.totalprice && errors.totalprice ? true : false}
              error={errors.totalprice}
            />
          </StepFormGroup>
        </HStack>
      </StepContentStyle>
      <StepFooter>
        <Button
          name="Proceed"
          textVariant="light"
          width="w-100"
          bgVariant="primary"
          className="PageStep__Btn"
          disabled={
            isLoading ||
            !values.defaultprice ||
            !values.quantity ||
            getIn(errors, 'defaultprice') ||
            getIn(errors, 'discountedPrice') ||
            getIn(errors, 'quantity')
          }
          shadow={false}
          suffixIcon={<VscArrowSmallRight />}
          type="button"
          onClick={nextPage}
        />
      </StepFooter>
    </StepContainer>
  )
}

export default ProductPrices
