/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useContext, useState, useEffect } from 'react'
import { CloseButton, Col, Modal, Row } from 'react-bootstrap'
//import RecentlyViewed from "../Products/RecentlyViewed";
import Price_Sort_Component from './Filters/Sort/Sort'
import { useFetchCartItemsQuery } from '../../../redux/store/services/cartApi'
import { useAppDispatch, useAppSelector } from '../../../redux/store/hook'
import {
  useFetchProductBySubCategoryCollectionIdQuery,
  useFetchProductBySubCollectionIdQuery,
} from '../../../redux/store/services/productsApi'
import ProductListBySub from './subcomponent/ProductListBySub'
import ProductListByCategory from './subcomponent/ProductListByCategory'
import ErrorBox from '../../../components/Box/ErrorBox'
import ProductListLoader from './SkeletonLoader/ProductListLoader'
import BreadCrumbs from './inner/BreadCrumbs'
import { CollectionContext } from '@ekenta/context/CollectionContext'
import { Button, Field } from '@ekenta/components'
import styles from '../../Account/ManageAccount/Profile/Profile.module.scss'
import { usePlacesWidget } from 'react-google-autocomplete'
import { useFormik } from 'formik'
import updateAccountFormValidation from '@ekenta/validations/schemas/update-account.schema'
import { useUpdateProfileMutation } from '@ekenta/redux/store/services/accountApi'
import { setUser } from '@ekenta/redux/store/states/reducerSlice/authenticationSlice'
import ProductListByCollection from './subcomponent/ProductListByCollection'
import { useGetProductByCollectionIdQuery } from '@ekenta/redux/store/features/product/productApiSlice'
import styled from 'styled-components'

export default function CollectionMainBar({
  collectionName,
  collectionId,
  categoryId,
  subCollectionId,
  queryNo,
}: any) {
  const { isLoading, filterData, pageNo, setPageNo } =
    useContext(CollectionContext)
  const dispatch = useAppDispatch()
  const [_loading, setLoading] = useState(false)
  const [pageno, setPageno] = useState(1)
  const [productViewIcon, setProductViewIcon] = useState('grid')
  const [changeLocationLoading, setChangeLocationLoading] = useState(false)

  const { token, ek_isAuth, account } = useAppSelector(
    (state) => state.auth.authentication
  )
  const urlParams = new URLSearchParams(window.location.search)
  const myParam = urlParams.get('isIkitchen')
  const [showModal, setShowModal] = React.useState(false)

  //fetch products by collection Id
  const getCollectionProduct = useGetProductByCollectionIdQuery(
    {
      collectionId,
      pageno,
    },
    { skip: !collectionId }
  )

  //fetch products by subcollection Id
  const {
    data: subcollection_data,
    isLoading: subcollection_isLoading,
    isError: subcollection_isError,
    isSuccess: subcollection_isSuccess,
  } = useFetchProductBySubCollectionIdQuery(
    {
      subCollectionId,
      pageno,
      token,
      ikitchen: myParam !== null ? true : false,
    },
    { skip: !subCollectionId }
  )

  //fetch products by subcollectioncategory Id
  const {
    data: categoryData,
    isLoading: category_isLoading,
    isError: category_isError,
    isSuccess: category_isSuccess,
  } = useFetchProductBySubCategoryCollectionIdQuery(
    { categoryId, pageno, token, ikitchen: myParam !== null ? true : false },
    { skip: !categoryId }
  )

  const { data: allCartData } = useFetchCartItemsQuery(token, { skip: !token })

  const switchToGridHandler = () => {
    setLoading(true)
    setTimeout(() => {
      setLoading(false)
      setProductViewIcon('grid')
    }, 1000)
  }

  const switchToListHandListler = () => {
    setLoading(true)
    setTimeout(() => {
      setLoading(false)
      setProductViewIcon('list')
    }, 1000)
  }

  const handleClose = () => {
    setShowModal(false)
  }

  const { ref } = usePlacesWidget({
    apiKey: 'AIzaSyB3RiNYnPFNin56mwjwKUN_trGmulyykJY',
    onPlaceSelected: (place) => {
      console.log(place)
      formik.setFieldValue('location', place.formatted_address)
      formik.setFieldValue('lga', place.address_components?.[1].long_name)
    },
  })

  const [updateUser, getResult] = useUpdateProfileMutation()
  useEffect(() => {
    if (getResult?.isSuccess) {
      dispatch(
        setUser({
          user: {
            ...account.user,
            location: getResult?.data.user.location,
            lga: getResult?.data.user.lga,
          },
          token: token,
          anonymousUser: false,
          ek_isAuth: true,
        })
      )
      setChangeLocationLoading(false)
      setShowModal(false)
    }
  }, [ek_isAuth, token, getResult?.isSuccess])

  const formik = useFormik({
    initialValues: {
      firstName: account.user?.firstName,
      lastName: account.user?.lastName,
      phoneNumber: account.user?.phoneNumber,
      gender: account.user?.gender,
      location: account.user?.location,
      lga: account.user?.lga,
    },
    validationSchema: updateAccountFormValidation(),
    onSubmit: (values) => {
      const data = {
        phone_number: values.phoneNumber,
        first_name: values.firstName,
        last_name: values.lastName,
        gender: values.gender,
        location: values.location,
        lga: values.lga,
      }
      setChangeLocationLoading(true)
      updateUser({ data, token })
      // handleSubmit(data);
    },
  })

  return (
    <Container className="container-mainbar">
      <div
        className="pt-1 row px-0"
        style={{ borderBottom: '1px solid #e9ecef', alignItems: 'flex-end' }}
      >
        <div style={{ width: '100%', paddingBottom: '8px' }}>
          <BreadCrumbs
            collectionName={collectionName}
            subCollectionId={subCollectionId}
            categoryId={categoryId}
          />
        </div>
        {myParam !== null && token ? (
          <div className="col-lg-2 pb-2 ">
            <Button onClick={() => setShowModal(true)}>Change location</Button>
          </div>
        ) : null}
      </div>
      <Col className="d-none d-lg-block price_sort_wrapper mt-1">
        <Price_Sort_Component
          gridHandler={switchToGridHandler}
          listHandler={switchToListHandListler}
          productViewIcon={productViewIcon}
        />
      </Col>

      {subcollection_isLoading || category_isLoading || isLoading ? (
        <Row className="prod_commponent_row_ p-0 m-0">
          <ProductListLoader />
        </Row>
      ) : (
        <div
          className={`${
            productViewIcon == 'grid' ? 'grid_list_container' : ''
          } pt-2 px-1 px-lg-0`}
        >
          {subcollection_isError ||
          category_isError ||
          getCollectionProduct?.isError ? (
            <Row className="prod_commponent_row_ p-0 m-0">
              <ErrorBox />
            </Row>
          ) : null}

          {getCollectionProduct?.isSuccess && getCollectionProduct?.data ? (
            <ProductListByCollection
              isLoading={getCollectionProduct?.isLoading || isLoading}
              isSuccess={getCollectionProduct?.isSuccess}
              isError={getCollectionProduct?.isError}
              collectionData={
                filterData ? filterData : getCollectionProduct?.data?.product
              }
              productViewIcon={productViewIcon}
              allCartData={allCartData}
              pageno={filterData ? pageNo : pageno}
              setPageno={filterData ? setPageNo : setPageno}
              queryNo={queryNo}
            />
          ) : null}

          {subcollection_isSuccess && subcollection_data ? (
            <ProductListBySub
              isLoading={subcollection_isLoading || isLoading}
              isSuccess={subcollection_isSuccess}
              isError={subcollection_isError}
              subData={filterData ? filterData : subcollection_data?.product}
              productViewIcon={productViewIcon}
              allCartData={allCartData}
              pageno={filterData ? pageNo : pageno}
              setPageno={filterData ? setPageNo : setPageno}
              queryNo={queryNo}
            />
          ) : null}

          {category_isSuccess && categoryData ? (
            <ProductListByCategory
              categoryProductData={
                filterData ? filterData : categoryData.product
              }
              isSuccess={category_isSuccess}
              isError={category_isError}
              isLoading={category_isLoading || isLoading}
              productViewIcon={productViewIcon}
              allCartData={allCartData}
              pageno={filterData ? pageNo : pageno}
              setPageno={filterData ? setPageNo : setPageno}
              queryNo={queryNo}
            />
          ) : null}
        </div>
      )}

      <Modal size="sm" centered show={showModal} onHide={handleClose}>
        <Modal.Body>
          <CloseButton
            onClick={handleClose}
            style={{ position: 'absolute', right: '0', padding: '3px 20px' }}
          />
          <br />
          <div className="my-3">
            <Field
              ref={ref}
              id="location"
              name="location"
              label="Loaction"
              disabled={isLoading}
              type="text"
              size="large"
              required
              childOutsideLabel={false}
              placeholder="Location*"
              fieldClassName={styles.FieldControl__Input}
              autoComplete="off"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.location}
              isError={
                formik.touched.location && formik.errors.location ? true : false
              }
              // error={formik.errors.location}
            />
          </div>

          <Button
            name="Change"
            bgVariant="primary"
            textVariant="light"
            className="py-2"
            disabled={changeLocationLoading}
            isLoadingText="Change"
            isLoading={changeLocationLoading}
            shadow={false}
            type="submit"
            onClick={formik.handleSubmit}
          />
        </Modal.Body>
      </Modal>
    </Container>
  )
}

const Container = styled.div`
  position: relative;
`
