/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useCallback, useEffect, useState } from "react";
import { Switch } from "@mui/material";
import { Col, Spinner, Table } from "react-bootstrap";
import { toast } from "react-toastify";

import { FaPlus } from "react-icons/fa";
import { FiEdit, FiTrash2 } from "react-icons/fi";
import { useNavigate } from "react-router";
//import { useFetchAllCollectionsQuery } from "../../../redux/store/services/collectionApi";
import { AddCollectionUrl, DashboardSellers } from "@ekenta/routes/constant";
import { Button, CardBox, HelmetHeader, Loader, Overlay, PageTitle } from "@ekenta/components"
import { useDeleteCollectionMutation, useFetchAdminAllCollectionsQuery } from "@ekenta/redux/store/services/collectionApi";
import { useAppSelector } from "@ekenta/redux/store/hook";
import { withDashboardLayout } from '@ekenta/hoc';
import { VscArrowSmallLeft } from "react-icons/vsc";
import axios from "axios";
import { BASE_URL } from "@ekenta/constants";


const AdminCollection = () => {

    const navigate = useNavigate()
    //const [_pageSize, setPageSize] = useState(5);
    const { token } = useAppSelector(state => state.auth.authentication);
    const result = useFetchAdminAllCollectionsQuery(token);
    const [deleteCollectionCall, { isSuccess: deleteCollection_isSuccess }] = useDeleteCollectionMutation()
    const [loading, setLoading] = useState(false);
    const handleDeleteCollection = async (id: any) => {
        setLoading(true)
        deleteCollectionCall({ collectionId: id, token })
    }

    useEffect(() => {
        if (deleteCollection_isSuccess) {
            toast.success(`Collection Deleted Successfully!`, {
                toastId: "admincollection-id-toast-55-success",
            });
            window.location.reload();
        }
    }, [deleteCollection_isSuccess]);



    const toggleFunction = useCallback((id: string, data: any) => {
        const config = {
            method: 'post',
            url: `${BASE_URL}/api/admin/update-collection/${id}`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            data: data
        };
        setLoading(true);
        axios(config)
            .then(function (res) {
                setLoading(false);
                toast.success(`${res.data.message}`, {
                    toastId: "-coll-toggle-success-id-toast",
                });
                result.refetch()
            })
            .catch(function () {
                setLoading(false);
                toast.error(`Something went wrong`, {
                    toastId: "-coll-error-400-id-toast-error",
                });
                result.refetch()

            });
    }, [result]);

    return (
        <>
            <HelmetHeader
                title="Add Collection - Dashboard"
                description="Qlason Add Collection Page"
            />

            <Col className=" col-lg-12 mt-1 px-2">
                <div className="mt-0">
                    <PageTitle
                        title="Collection"
                        subTitle={
                            <span
                                className="cursor-pointer d-flex w-lg-50 align-items-center text-muted"
                                onClick={() => navigate(DashboardSellers)}>
                                <VscArrowSmallLeft />
                                Back to Dashboard
                            </span>
                        }>
                        <Button
                            name="Add New Collection"
                            prefixIcon={<FaPlus />}
                            bgVariant="light"
                            className="mb-3"
                            onClick={() => navigate(AddCollectionUrl)}
                        />
                    </PageTitle>

                    <Col className="mt-3">
                        <CardBox>
                            <Table striped hover className='custom_table_listing'>
                                <thead>
                                    <tr>
                                        <th>Picture</th>
                                        <th>Name</th>
                                        <th>Sub Categories</th>
                                        <th>Visibility</th>
                                        <th>Edit</th>
                                        <th>Delete</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        !result.isSuccess ? <div>Loading</div> :
                                            result?.data?.collections?.map((e: any, i: any) => {

                                                return <tr key={i}>
                                                    <td><div className="picture__thumb"><img className="img-thumbnail rounded" src={e?.image_url} /></div></td>
                                                    <td>{e?.name}</td>
                                                    <td>{e?.sub_collection_category?.length}</td>
                                                    <td>
                                                        <Switch
                                                            defaultChecked={e?.visibility}
                                                            onChange={() => {
                                                                const data = {
                                                                    name: e?.name,
                                                                    description: e?.description,
                                                                    collection_id: e?.id,
                                                                    visibility: !e?.visibility ? true : false,
                                                                }
                                                                toggleFunction(e?.id, data);
                                                            }}
                                                        />
                                                    </td>
                                                    <td><FiEdit onClick={() => navigate(`/admin/collection/${e?.id}`)} className="cursor-pointer text-primary" fontSize={15} /></td>
                                                    <td>
                                                        {
                                                            loading ? <Spinner animation="grow" /> :
                                                                <FiTrash2 onClick={() => handleDeleteCollection(e?.id)} className="cursor-pointer" fontSize={15} />
                                                        }

                                                    </td>
                                                </tr>


                                            })
                                    }

                                </tbody>
                            </Table>
                        </CardBox>
                    </Col>
                </div>
            </Col>
            {loading ? (
                <Overlay bgColor="light">
                    <div
                        className="d-flex align-items-center 
                 justify-content-end mx-auto w-25 p-4"
                    >
                        <Loader withWrapper />
                    </div>
                </Overlay>
            ) : null}
        </>
    )
}

export default withDashboardLayout(AdminCollection);
