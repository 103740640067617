/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { Suspense, useEffect, useState } from "react";
import { Card, Col, Spinner } from "react-bootstrap";
import StarRatings from "react-star-ratings";
import NumberFormat from "react-number-format";
import { productBaseUrl } from "../../routes/constant";
import { setDiscountPercent, slugifyText } from "../../utils";

import VideoPlayerWithoutControls from "../VideoPlayer/VideoPlayerWithoutControls";
import AddCartButton from "../Cart/CartButtons/AddCartButton/AddCartButton";
import QuantityCartButton from "../Cart/CartButtons/QuantityCartButton/QuantityCartButton";
import { useAppSelector } from "../../redux/store/hook";
import LiveIconComp from "../LiveCommerce/LiveIconComp";

import { toast } from "react-toastify";
import StockEmpty from "../StockEmpty/StockEmpty";
import classnames from "classnames";
import Tag from "../Tag/Tag";

import "./ProductCardComponent.scss";
import SavedButton from "./SavedItemButtons/SavedButton";
import RemoveSaved from "./SavedItemButtons/RemoveSaved";
import Typography from "../Typography/Typography";
import RefreshLink from "../RefreshLink/RefreshLink";

// interface IKitchenProductCardComponent {
//   productname: string;
// }

export const mapCartDataReturnLocalQuantity = (
    cartData: any,
    productid: number,
) => {
    return cartData
        ? cartData.find((item: any) => {
            if (item.product.id === productid) {
                return item;
            }
        })
        : null;
};

export default function IKitchenProductCardComponent({
    cartItem,
    condition,
    videoUrl,
    thumbnailUrl,
    product,
    productId,
    productname,
    defaultprice,
    layoutView,
    maxPrice,
    isShadow,
    discount,
    isFavorite,
    features,
    description,
}: any) {
    const { ek_isAuth, token } = useAppSelector(
        (state) => state.auth.authentication,
    );
    const { cartItems: _cartItems } = useAppSelector((state) => state.cart.cart);

    // const [mysaved, setMySaved] = useState(savedItems);
    // const allSaved = (y: any) => {
    //   const x = [];
    //   for (let i = 0; i < y.length; i++) {
    //     x.push(y[i].id);
    //   }
    //   setMySaved(x);
    // };
    const [_cartItem, setCartItem] = useState<any>(null);
    const [localCartItem, setLocalCartItem] = useState<any>(null);

    const _slugProduct = productId
        ? slugifyText(productname.toLowerCase(), productId)
        : "/";

    const showToast = () => {
        if (!token) {
            toast.error("Sign in to save item !", {
                toastId: "signin-to-save-id-toast-error",
            });
        }
    };


    // useEffect(() => {
    //   if (toFavorite_isSuccess) {
    //     toast.success("Favourite list updated!", {
    //       toastId: "favourite-id-toast-error",
    //     });

    //   }
    // }, [toFavorite_isSuccess]);

    useEffect(() => {
        if (ek_isAuth) {
            if (cartItem) {
                setCartItem(cartItem);
            } else {
                setCartItem(null);
            }
        } else {
            const items = mapCartDataReturnLocalQuantity(_cartItems, productId);
            setLocalCartItem(items);
        }
    }, [ek_isAuth, cartItem, _cartItems]);



    return (
        <>
            <Col
                xs={6}
                sm={6}
                md={4}
                lg={`${layoutView === "grid" ? "4" : "12"}`}
                className="product__card__col position-relative"

            >
                <Card
                    className={classnames(isShadow ? "boxShadow" : "card-noBorder")}
                >
                    {
                        discount > 0 ? <Tag
                            numberOf={setDiscountPercent(discount)}
                            className="priceOff"
                        /> : null
                    }

                    {product.user ? product.user.isSellerLive && <LiveIconComp /> : null}

                    {/* {SavedItem Buttom} */}
                    {!isFavorite ? (<SavedButton productId={productId} />
                    ) : <RemoveSaved productId={productId} />}

                    {product && product.stock >= 1 ? (
                        <div className="_add_cart_button">
                            {ek_isAuth && token ? (
                                <>
                                    {cartItem && cartItem.quantity >= 1 ? (
                                        <div className="group--components">
                                            <QuantityCartButton
                                                cartItems={cartItem}
                                                cartItemId={cartItem.id}
                                                className="quantity--picker"
                                            />
                                        </div>
                                    ) : (
                                        <>
                                            <AddCartButton
                                                productId={productId}
                                                productname={productname}
                                                product={product}
                                                name="Add"
                                            />
                                        </>
                                    )}
                                </>
                            ) : (
                                <>
                                    {localCartItem && localCartItem.quantity >= 1 ? (
                                        <div className="group--components">
                                            <QuantityCartButton
                                                cartItems={localCartItem}
                                                cartItemId={
                                                    localCartItem ? localCartItem.product.id : ""
                                                }
                                                className="quantity--picker"
                                            />
                                        </div>
                                    ) : (
                                        <>
                                            <AddCartButton
                                                productId={productId}
                                                productname={productname}
                                                product={product}
                                                name="Add"
                                            />
                                        </>
                                    )}
                                </>
                            )}
                        </div>
                    ) : (
                        <StockEmpty className="StockEmptyWrapper_float" />
                    )}

                    <RefreshLink
                        className={`card--inner ${layoutView === "grid" ? "" : "row"}`}
                        to={`${productBaseUrl}/${_slugProduct}`}
                        data-name={productname} data-price={defaultprice} data-brand="" data-category=""
                        data-track-onclick="false"
                    >

                        <Col
                            className={`_card_image-wrapper position-relative 
                            ${layoutView === "grid" ? "col-lg-12" : "col-4 col-md-2"
                                }`}
                        >
                            {/* <Link to={`${productBaseUrl}/${_slugProduct}`}> */}
                            <div className="video__wrapper">
                                <Suspense
                                    fallback={<Spinner animation="border" size="sm" />}
                                >
                                    <VideoPlayerWithoutControls
                                        videoUrl={videoUrl}
                                        thumbnailUrl={thumbnailUrl}
                                        title={productname}
                                    />
                                </Suspense>
                            </div>
                            {/* </Link> */}
                        </Col>
                        <Col
                            className={`${layoutView === "grid" ? "col-lg-12" : "col-md-10"
                                }`}
                        >
                            <Card.Body>

                                <Typography
                                    as="h5"
                                    size="medium"
                                    color="secondary"
                                    className="card-h5-title"
                                    heading
                                >
                                    {productname.toLowerCase()}
                                </Typography>
                                <div className="card-text" style={{ color: "black" }}>
                                    {description.toLowerCase()}
                                </div>
                                {/* <Typography
                                        as="p"
                                        size="medium"
                                        color="secondary"
                                        className="card-text"
                                    >
                                        {description.toLowerCase()}
                                    </Typography> */}
                                <div className="row" style={{ minHeight: "10px" }}>
                                    {
                                        features?.map((e: any, i: any) => (
                                            <div key={i} className={"p-0 col-lg-4"} style={{ color: "black" }}>
                                                <div className="_no_p_">
                                                    <span className="_text_">
                                                        {e?.feature_value} {e?.unit_id} <br /> {e?.feature?.name}
                                                    </span>
                                                </div>
                                            </div>
                                        ))
                                    }

                                </div>
                                <div className="card-text">
                                    <div className="no_item_sold_">
                                        <div className="_no_p_">
                                            <span className="_text_">
                                                {product.stock} item{product.stock > 1 ? "s" : ""} in
                                                stock
                                            </span>
                                        </div>
                                        <div className="_n_rating_row">
                                            <div className="star-ratings">
                                                <StarRatings
                                                    rating={0}
                                                    starDimension="18px"
                                                    starSpacing="3px"
                                                    starRatedColor={"#fc624d"}
                                                    numberOfStars={1}
                                                    name="rating"
                                                />
                                            </div>
                                            <div className="rate-score">
                                                <NumberFormat
                                                    value={0}
                                                    displayType="text"
                                                    decimalSeparator="."
                                                    decimalScale={1}
                                                    fixedDecimalScale
                                                    allowNegative={false}
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="wrap__t__group d-flex align-items-center justify-content-between">
                                        <div className="card-image-wrapper me-2"></div>
                                        <div className="d-flex align-items-center justify-content-between w-100">
                                            <div>
                                                <div className="_main_price_">
                                                    <h3>
                                                        <NumberFormat
                                                            value={defaultprice}
                                                            displayType="text"
                                                            thousandSeparator={true}
                                                            prefix="₦"
                                                            className="some"
                                                        />
                                                    </h3>

                                                    {discount && discount > 0
                                                        ? (
                                                            <span className="span__body">
                                                                <NumberFormat
                                                                    value={`${maxPrice}`}
                                                                    displayType="text"
                                                                    thousandSeparator={true}
                                                                    prefix="₦"
                                                                    className="some"
                                                                />
                                                            </span>
                                                        )
                                                        : null}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="pr_condition_sec">
                                            {condition == "new" ? (
                                                <span className="span__new">New</span>
                                            ) : (
                                                <span
                                                    className="span__used"
                                                    style={{ backgroundColor: "#7C420C" }}
                                                >
                                                    Used
                                                </span>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </Card.Body>
                        </Col>
                    </RefreshLink>
                </Card>
            </Col>
        </>
    );
}
