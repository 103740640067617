import React, { useContext } from 'react';
import { Col } from 'react-bootstrap';

//import "../../../Collection.scss";
import "./Sort.scss";
import { CollectionContext } from '@ekenta/context/CollectionContext';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

interface PriceSortProps {
  gridHandler: (grid: string) => void;
  listHandler: (grid: string) => void;
  productViewIcon: string;
}

export default function PriceSort({ productViewIcon, listHandler, gridHandler }: PriceSortProps) {
  const { configureFilterUrl } = useContext(CollectionContext);
  const { pathname } = useLocation();
  const [selected, setSelected] = React.useState('popular');

  const handleSelect = (value: string) => {
    setSelected(value)
    let data = { key: "sort", value: value, set: true };
    if (value !== "popular") {
      data = { ...data, set: true }
    } else {
      data = { ...data, set: false }
    }
    configureFilterUrl(data);
  }

  useEffect(() => {
    setSelected("popular");
  }, [pathname]);

  return (
    <>


      <Col className="mb-2 sort_container_">
        <div className="_row_">
          <div className="sort_first_wrapper">
            <h5 className="sort_heading">Sort by:</h5>
            <div className="_list_items_">
              <ul className="list_ul_">
                <li>
                  <a style={{ cursor: "pointer", color: selected == 'popular' ? '#F8880A' : '' }} onClick={() => handleSelect('popular')}>Popular</a>
                </li>
                <li>
                  <a style={{ cursor: "pointer", color: selected == 'newest' ? '#F8880A' : '' }} onClick={() => handleSelect('newest')}>Newest</a>
                </li>
                <li>
                  <a style={{ cursor: "pointer", color: selected == 'lowest' ? '#F8880A' : '' }} onClick={() => handleSelect('lowest')}>Lowest</a>
                </li>
                <li>
                  <a style={{ cursor: "pointer", color: selected == 'highest' ? '#F8880A' : '' }} onClick={() => handleSelect('highest')}>Highest</a>
                </li>
                <li>
                  <a style={{ cursor: "pointer", color: selected == 'used' ? '#F8880A' : '' }} onClick={() => handleSelect('used')}>Used</a>
                </li>
              </ul>
            </div>
          </div>
          <div className="sort_second_wrapper d-none d-lg-flex">
            <span>View:</span>
            <div className="_layout_changes_">
              <svg onClick={() => gridHandler('grid')} className={`${productViewIcon === 'grid' ? 'active_svg_' : ''} `} viewBox="0 0 24 24" fill="" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd" d="M8.92918 4.47033C7.65796 4.32825 6.34205 4.32825 5.07084 4.47033C4.76195 4.50485 4.51835 4.74944 4.48361 5.0465C4.3318 6.34444 4.3318 7.65565 4.48361 8.95359C4.51835 9.25065 4.76195 9.49524 5.07084 9.52976C6.34206 9.67184 7.65796 9.67184 8.92918 9.52976C9.23807 9.49524 9.48167 9.25065 9.51641 8.95359C9.66822 7.65565 9.66822 6.34444 9.51641 5.0465C9.48167 4.74944 9.23807 4.50485 8.92918 4.47033ZM4.90423 2.97961C6.28617 2.82516 7.71384 2.82516 9.09579 2.97961C10.0866 3.09035 10.8891 3.87034 11.0063 4.87225C11.1716 6.28596 11.1716 7.71413 11.0063 9.12784C10.8891 10.1298 10.0866 10.9097 9.09579 11.0205C7.71384 11.1749 6.28617 11.1749 4.90423 11.0205C3.91339 10.9097 3.11094 10.1298 2.99376 9.12784C2.82841 7.71413 2.82841 6.28596 2.99376 4.87225C3.11094 3.87034 3.91339 3.09035 4.90423 2.97961Z" fill="" />
                <path fillRule="evenodd" clipRule="evenodd" d="M8.92918 14.4703C7.65796 14.3282 6.34205 14.3282 5.07084 14.4703C4.76195 14.5049 4.51835 14.7494 4.48361 15.0465C4.3318 16.3444 4.3318 17.6557 4.48361 18.9536C4.51835 19.2506 4.76195 19.4952 5.07084 19.5298C6.34206 19.6718 7.65796 19.6718 8.92918 19.5298C9.23807 19.4952 9.48167 19.2506 9.51641 18.9536C9.66822 17.6556 9.66822 16.3444 9.51641 15.0465C9.48167 14.7494 9.23807 14.5049 8.92918 14.4703ZM4.90423 12.9796C6.28617 12.8252 7.71384 12.8252 9.09579 12.9796C10.0866 13.0904 10.8891 13.8703 11.0063 14.8722C11.1716 16.286 11.1716 17.7141 11.0063 19.1278C10.8891 20.1298 10.0866 20.9097 9.09579 21.0205C7.71384 21.1749 6.28617 21.1749 4.90423 21.0205C3.91339 20.9097 3.11094 20.1298 2.99376 19.1278C2.82841 17.7141 2.82841 16.286 2.99376 14.8722C3.11094 13.8703 3.91339 13.0904 4.90423 12.9796Z" fill="" />
                <path fillRule="evenodd" clipRule="evenodd" d="M18.9292 4.47033C17.658 4.32825 16.3421 4.32825 15.0708 4.47033C14.7619 4.50485 14.5183 4.74944 14.4836 5.0465C14.3318 6.34444 14.3318 7.65565 14.4836 8.95359C14.5183 9.25065 14.7619 9.49524 15.0708 9.52976C16.3421 9.67184 17.658 9.67184 18.9292 9.52976C19.2381 9.49524 19.4817 9.25065 19.5164 8.95359C19.6682 7.65565 19.6682 6.34444 19.5164 5.0465C19.4817 4.74944 19.2381 4.50485 18.9292 4.47033ZM14.9042 2.97961C16.2862 2.82516 17.7138 2.82516 19.0958 2.97961C20.0866 3.09035 20.8891 3.87034 21.0063 4.87225C21.1716 6.28596 21.1716 7.71413 21.0063 9.12784C20.8891 10.1298 20.0866 10.9097 19.0958 11.0205C17.7138 11.1749 16.2862 11.1749 14.9042 11.0205C13.9134 10.9097 13.1109 10.1298 12.9938 9.12784C12.8284 7.71413 12.8284 6.28596 12.9938 4.87225C13.1109 3.87034 13.9134 3.09035 14.9042 2.97961Z" fill="" />
                <path fillRule="evenodd" clipRule="evenodd" d="M18.9292 14.4703C17.658 14.3282 16.3421 14.3282 15.0708 14.4703C14.7619 14.5049 14.5183 14.7494 14.4836 15.0465C14.3318 16.3444 14.3318 17.6557 14.4836 18.9536C14.5183 19.2506 14.7619 19.4952 15.0708 19.5298C16.3421 19.6718 17.658 19.6718 18.9292 19.5298C19.2381 19.4952 19.4817 19.2506 19.5164 18.9536C19.6682 17.6556 19.6682 16.3444 19.5164 15.0465C19.4817 14.7494 19.2381 14.5049 18.9292 14.4703ZM14.9042 12.9796C16.2862 12.8252 17.7138 12.8252 19.0958 12.9796C20.0866 13.0904 20.8891 13.8703 21.0063 14.8722C21.1716 16.286 21.1716 17.7141 21.0063 19.1278C20.8891 20.1298 20.0866 20.9097 19.0958 21.0205C17.7138 21.1749 16.2862 21.1749 14.9042 21.0205C13.9134 20.9097 13.1109 20.1298 12.9938 19.1278C12.8284 17.7141 12.8284 16.286 12.9938 14.8722C13.1109 13.8703 13.9134 13.0904 14.9042 12.9796Z" fill="" />
              </svg>
              <svg onClick={() => listHandler('list')} className={`${productViewIcon === 'list' ? 'active_svg_' : ''} `} viewBox="0 0 24 24" fill="" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd" d="M4.95726 5.19236C4.54566 6.52645 4.54566 7.97349 4.95726 9.30758C9.64158 9.75904 14.3585 9.75904 19.0428 9.30758C19.4544 7.97349 19.4544 6.52645 19.0428 5.19236C14.3585 4.74089 9.64158 4.74089 4.95726 5.19236ZM4.70877 3.70943C9.55804 3.2352 14.442 3.2352 19.2913 3.70943C19.8258 3.76171 20.2751 4.128 20.4393 4.63377C20.9889 6.32686 20.9889 8.17307 20.4393 9.86617C20.2751 10.3719 19.8258 10.7382 19.2913 10.7905C14.442 11.2647 9.55804 11.2647 4.70877 10.7905C4.17422 10.7382 3.72495 10.3719 3.56077 9.86617C3.01116 8.17307 3.01116 6.32686 3.56077 4.63377C3.72495 4.128 4.17422 3.76171 4.70877 3.70943Z" fill="" />
                <path fillRule="evenodd" clipRule="evenodd" d="M4.95726 14.6924C4.54566 16.0265 4.54566 17.4735 4.95726 18.8076C9.64158 19.259 14.3585 19.259 19.0428 18.8076C19.4544 17.4735 19.4544 16.0265 19.0428 14.6924C14.3585 14.2409 9.64158 14.2409 4.95726 14.6924ZM4.70877 13.2094C9.55804 12.7352 14.442 12.7352 19.2913 13.2094C19.8258 13.2617 20.2751 13.628 20.4393 14.1338C20.9889 15.8269 20.9889 17.6731 20.4393 19.3662C20.2751 19.8719 19.8258 20.2382 19.2913 20.2905C14.442 20.7647 9.55804 20.7647 4.70877 20.2905C4.17422 20.2382 3.72495 19.8719 3.56077 19.3662C3.01116 17.6731 3.01116 15.8269 3.56077 14.1338C3.72495 13.628 4.17422 13.2617 4.70877 13.2094Z" fill="" />
              </svg>
            </div>
          </div>
        </div>

      </Col>
    </>
  );
}
