/* eslint-disable @typescript-eslint/no-explicit-any */
import React from "react";
import { Row } from "react-bootstrap";
import { useLocation } from "react-router";
import { mapCartDataReturnQuantity, slugifyText } from "../../../../utils";
import EmptyBox from "../../../../components/Box/EmptyBox";
import ProductCardComponent from "../../../../components/Product/ProductCardComponent";
import { Loader, Pagination } from "../../../../components";
import { useFetchAllLiveIKitchenSellersQuery } from "@ekenta/redux/store/services/livecommerceApi";
import LiveProductCard from "@ekenta/components/LiveCommerce/LiveProductCard";
import defaultImg from "@ekenta/assets/images/default_livestream.webp";
import { usePlacesWidget } from "react-google-autocomplete";
import IKitchenProductCardComponent from "@ekenta/components/Product/iKitchenProductCardComponent";

const ProductListByCollection = (props: any) => {

    const { pathname } = useLocation();
    const urlParams = new URLSearchParams(window.location.search);
    const myParam = urlParams.get("isIkitchen")
    const getResult = useFetchAllLiveIKitchenSellersQuery();

    const { ref } = usePlacesWidget({
        apiKey: "AIzaSyB3RiNYnPFNin56mwjwKUN_trGmulyykJY",
        onPlaceSelected: (place) => {
            console.log(place)

        }
    })

    const mapAllLiveSellers = (data: any) => {
        return data.merchant_business.length > 0 ? (
            data.merchant_business.slice(0, 6).map((result: any, index: number) => {
                return (
                    <LiveProductCard
                        key={`id_` + index}
                        id={result.id}
                        type="isLive"
                        className="item col__cardb-wrapper"
                        imageUrl={result.merchant_business?.thumbnail_url ? result.merchant_business?.thumbnail_url : defaultImg}
                        title={result.merchant_business?.business_name}
                    />
                    // <>LiveSeller</>
                );
            })
        ) : (
            null
        );
    };
    return (
        <div className="__main_collection_product_card_">
            {
                myParam !== null && getResult?.isSuccess && getResult?.data.length > 0 ?
                    <Row className={"row pb-5"} style={{ minHeight: "200px" }}>
                        {mapAllLiveSellers(getResult?.data)}
                    </Row> : null

            }

            <Row className="prod_commponent_row_ p-0 m-0">
                {props.isLoading ? (
                    <div className="ms-2 mt-1">
                        <Loader />
                    </div>
                ) : props.isSuccess && props.collectionData ? (
                    props.collectionData?.data.length > 0 ? (
                        props.collectionData?.data.map((item: any) => {
                            const _slugProduct = item?.id
                                ? slugifyText(item?.name.toLowerCase(), item?.id)
                                : "/";
                            return (

                                myParam !== null ?
                                    <IKitchenProductCardComponent
                                        key={item.id}
                                        productId={item.id}
                                        product={item}
                                        layoutView={props.productViewIcon}
                                        cartItem={mapCartDataReturnQuantity(
                                            props.allCartData,
                                            item.id,
                                        )}
                                        isShadow={true}
                                        maxPrice={item.max_price}
                                        minPrice={item.min_price}
                                        discount={item.discount}
                                        discountPercentage={item.discount_percentage}
                                        productname={item.name}
                                        defaultprice={item.price}
                                        imageUrl={
                                            item.images.length > 0 ? item.images[1]?.image_url : ""
                                        }
                                        thumbnailUrl={item.images.length > 0 ? item.images[0]?.image_url : ""}
                                        videoUrl={item.video_url}
                                        condition={item.condition}
                                        features={item.features}
                                        description={item.description}
                                    />

                                    :

                                    <ProductCardComponent
                                        key={item.id}
                                        productId={item.id}
                                        product={item}
                                        layoutView={props.productViewIcon}
                                        cartItem={mapCartDataReturnQuantity(
                                            props.allCartData,
                                            item.id,
                                        )}
                                        isShadow={true}
                                        maxPrice={item.max_price}
                                        minPrice={item.min_price}
                                        discount={item.discount}
                                        adminDiscount={item.admin_discount}
                                        discountPercentage={item.discount_percentage}
                                        productname={item.name}
                                        defaultprice={item.price}
                                        imageUrl={
                                            item.images.length > 0 ? item.images[1]?.image_url : ""
                                        }
                                        thumbnailUrl={item.images.length > 0 ? item.images[0]?.image_url : ""}
                                        videoUrl={item.video_url}
                                        condition={item.condition}
                                    />




                            );
                        })
                    ) : (
                        <EmptyBox />
                    )
                ) : null}
            </Row>
            {props.collectionData
                ? props.collectionData.total > 0 && (
                    <Pagination
                        pathname={pathname}
                        itemPerPage={props.collectionData.per_page}
                        itemsLength={props.collectionData.total}
                        currentPage={props.pageno}
                        queryNo={props.queryNo}
                        callback={(value) => props.setPageno(value)}
                    />
                )
                : null}
        </div>
    );
}


export default ProductListByCollection;