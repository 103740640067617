import React from 'react'
import styled from 'styled-components';
import EmptyLiveGif from "@ekenta/assets/illustrators/live-empty.gif"

const EmptyLive = (props: { title?: string }) => {
    const { title } = props;
    return (
        <Container>
            <div className="ImageContainer">
                <img src={EmptyLiveGif} alt="no livestramming" />
            </div>
            <h5>{title ? title : "No live videos at the moment"}</h5>
            {!title ? <p>Continue shopping till a seller comes live</p> : null}
        </Container>
    )
}

const Container = styled.div`
    width: 100%;
    height: 100%;
    display:flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;

    h5{
        color: var(--greyscale-900, #212121);
        text-align: center;
        font-family: var(--eke-body-font-family);
        font-weight:  var(--eke-font-weight-bold);
        font-size: 18px;
        line-height: 120%;
    }

    p{
        color: var(--greyscale-700, #616161);
        text-align: center;
        font-family: var(--eke-body-font-family);
        font-weight:  var(--eke-font-weight-normal);
        font-size: 16px;
        line-height: 140%;
        letter-spacing: 0.2px;
    }

    .ImageContainer{
        width: 255px;
        height: 179px;                
        
        img{
            width: 100%;
            height: 100%;       
            object-fit: cover;                                                          
        }
    }
`;

export default EmptyLive