/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';
import withDefaultLayout from '../../hoc/Layout/withDefaultLayout';
import {
  Button,
  CardBox,
  CustomAlert,
  HelmetHeader,
  Loader,
  Overlay,
  PageTitle,
  Typography,
} from '@ekenta/components';
import { useNavigate } from 'react-router-dom';
import { Pencil } from '@ekenta/components/icons';
import { setUser } from '@ekenta/redux/store/states/reducerSlice/authenticationSlice';
import classNames from 'classnames';
import BackButton from '../Account/ManageAccount/component/BackButton';
import { useAppDispatch, useAppSelector } from '@ekenta/redux/store/hook';
import styles from './reels.module.scss';
// import "../../components/Product/ProductCardComponent.scss";

import Video from './Video';
import { CardMedia } from '@mui/material';
import StarRatings from 'react-star-ratings';
import NumberFormat from 'react-number-format';

const Reels = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [isEdit, setIsEdit] = useState(false);
  const { ek_isAuth, token, account } = useAppSelector(
    (state) => state.auth.authentication,
  );

  const videoUrls = [
    'https://ekenta-image-resources.s3.amazonaws.com/7egNaK4zC0JdncHlHSsVGNJhrEC9TiRJzipVVnWM.mp4',
    'https://ekenta-image-resources.s3.amazonaws.com/8eAan5a4OioH0ihaIqlXtwrfV3a7PioCZKxVoOLg.mp4',
    'https://ekenta-image-resources.s3.amazonaws.com/4pHIkYLXMfThy9q3biTZAUhz1SDdzOUr6YkA1Vwf.mp4',
    'https://ekenta-image-resources.s3.amazonaws.com/XeYrK8ERydfJxNJWms9HIEuyGVFpgQFc4G6gMxj5.mp4',
  ];

  const callback = (entries: any) => {
    entries.forEach((element: any) => {
      // console.log(element);
      const child = element.target.childNodes[0];
      console.log(child);
      child.play().then(() => {
        //if this video is not in viewport then pause it
        if (!child.paused && !element.isIntersecting) {
          child.pause();
        }
      });
    });
  };

  const observer = new IntersectionObserver(callback, {
    threshold: 1,
  });

  useEffect(() => {
    const videos = document.querySelectorAll('.videos');
    videos.forEach((video) => {
      observer.observe(video);
    });
    return () => {
      observer.disconnect();
    };
  }, [videoUrls]);

  return (
    <>
      <HelmetHeader title="Reels" description="Qlason Reels" />
      <div className="">
        <Container className="account-user-container">
          <>{/* <BreadCrumb activePage="Manage Profile" /> */}</>

          <Row className={''}>
            {/* <ProfileSideBar isLoading={isLoading} /> */}
            <Col lg={9} className="mx-auto">
              <Col className="mx-1 mx-lg-0">
                <PageTitle
                  title="Reels"
                  margin={false}
                  subTitle={<BackButton />}
                />
              </Col>
              <Card>
                <Col lg={6} className={'mx-auto'}>
                  {videoUrls.map((e, i) => (
                    <CardMedia key={i} className={'card mb-3'}>
                      <div className="videos">
                        <Video src={e} />
                      </div>
                      <Card.Body>
                        <Typography
                          as="h5"
                          size="medium"
                          color="secondary"
                          className="card-h5-title"
                          heading
                        >
                          Product Name {i}
                        </Typography>
                        <div className="card-text">
                          <div className="no_item_sold_">
                            <div className="_no_p_">
                              <span className="_text_">5 in stock</span>
                            </div>
                          </div>

                          <div className="wrap__t__group d-flex align-items-center justify-content-between">
                            <div className="d-flex align-items-center justify-content-between w-100">
                              <div>
                                <div className="_main_price_">
                                  <h3>
                                    <NumberFormat
                                      value={100}
                                      displayType="text"
                                      thousandSeparator={true}
                                      prefix="₦"
                                      className="some"
                                    />
                                  </h3>
                                </div>
                              </div>
                            </div>
                            <div className="pr_condition_sec">
                              <div className="_add_cart_button">
                                <button
                                  type="button"
                                  className="Button btn-icon Button_Shadow__rKOcs w-auto AddCartButton_AddCart_Button__UDTR1"
                                >
                                  <svg
                                    stroke="currentColor"
                                    fill="none"
                                    strokeWidth="2"
                                    viewBox="0 0 24 24"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    fontSize="18"
                                    height="1em"
                                    width="1em"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <line x1="12" y1="5" x2="12" y2="19"></line>
                                    <line x1="5" y1="12" x2="19" y2="12"></line>
                                  </svg>
                                  <span className="mr2">Add</span>
                                </button>
                              </div>
                              {/* <span className="span__new">New</span> */}
                            </div>
                          </div>
                        </div>
                      </Card.Body>
                    </CardMedia>
                  ))}

                  <video
                    className={classNames(styles.ReelsVideo)}
                    // onEnded={handleAutoScroll}
                    // onClick={handleMute}
                    controls
                    // muted='muted'

                    autoPlay
                  >
                    <source
                      src={
                        'https://ekenta-image-resources.s3.amazonaws.com/7egNaK4zC0JdncHlHSsVGNJhrEC9TiRJzipVVnWM.mp4'
                      }
                      type="video/webm"
                    />
                  </video>
                </Col>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default withDefaultLayout(Reels);
