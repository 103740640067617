import React from "react";
import classNames from "classnames";
import classnames from "classnames";
import { TypographyPropsType } from "@ekenta/interfaces";
import styles from "./Typography.module.scss";

function Typography({ children, ...props }: TypographyPropsType) {
  const {
    as: Component = "h1",
    size = "medium",
    uiText,
    color,
    disabled,
    id,
    margin,
    heading,
    transform,
    className,
  } = props;

  const classes = classNames(styles.TypographyText, {
    [styles[size]]: size,
    [styles.uiText]: uiText,
    [styles.heading]: heading,
    [styles.displayText]: !uiText,
    [styles.primaryColor]: color === "primary",
    [styles.secondaryColor]: color === "secondary",
    [styles.defaultColor]: color === "default",
    [styles.lightColor]: color === "light",
    [styles.darkColor]: color === "dark",
    [styles.greyColor]: color === "grey",
    [styles.errorColor]: color === "error",
    [styles.successColor]: color === "success",
    [styles.linkColor]: color === "link",
    [styles.disabled]: disabled,
    [styles.marginTop]: margin === "top",
    [styles.marginBottom]: margin === "bottom",
    [styles.upperCaseText]: transform === "uppercase",
    [styles.lowerCaseText]: transform === "lowercase",
    [styles.capitalizeText]: transform === "capitalize",

    // [styles.heading]: ["h1", "h2", "h3", "h4", "h5", "h6"].includes(Component),
  });

  return (
    <Component className={classnames(classes, className)} id={id}>
      {children}
    </Component>
  );
}

export default Typography;
