import React from 'react'

const SvgCart = ({
	width = "1rem",
	height = "1rem",
	className
}: {
	className?: string,
	width?: string;
	height?: string
}) => (
	<svg
		width={width}
		height={height}
		className={className}
		viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
		<g id="Iconly/Light/Buy">
			<g id="Buy">
				<path id="Stroke 1" d="M1.83301 2.1665L3.21967 2.4065L3.86167 10.0552C3.91301 10.6798 4.43501 11.1592 5.06167 11.1572H12.3343C12.9323 11.1585 13.4397 10.7185 13.5243 10.1265L14.157 5.7545C14.2277 5.26584 13.8883 4.8125 13.4003 4.74184C13.3577 4.73584 3.44234 4.7325 3.44234 4.7325" stroke="#F8880A" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
				<path id="Stroke 3" d="M9.41602 7.19637H11.2647" stroke="#F8880A" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
				<path id="Stroke 5" fillRule="evenodd" clipRule="evenodd" d="M4.76892 13.4683C4.96958 13.4683 5.13158 13.6309 5.13158 13.8309C5.13158 14.0316 4.96958 14.1943 4.76892 14.1943C4.56825 14.1943 4.40625 14.0316 4.40625 13.8309C4.40625 13.6309 4.56825 13.4683 4.76892 13.4683Z" fill="#F8880A" stroke="#F8880A" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
				<path id="Stroke 7" fillRule="evenodd" clipRule="evenodd" d="M12.2894 13.4683C12.4901 13.4683 12.6528 13.6309 12.6528 13.8309C12.6528 14.0316 12.4901 14.1943 12.2894 14.1943C12.0888 14.1943 11.9268 14.0316 11.9268 13.8309C11.9268 13.6309 12.0888 13.4683 12.2894 13.4683Z" fill="#F8880A" stroke="#F8880A" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
			</g>
		</g>
	</svg>
)

export default SvgCart;