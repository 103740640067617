/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { Fragment, useState } from 'react'
import { FieldPropsType } from '@ekenta/interfaces'
//import { Field as FormikField } from "formik";
//import { useField } from 'formik';
import InputField from '../InputField'
import NumberFormat from 'react-number-format'
import classnames from 'classnames'

import styles from './Field.module.scss'
import { Button } from '@ekenta/components'
import Checkbox from '../Checkbox/Checkbox'

const Field = React.forwardRef(
  (
    {
      id,
      name,
      label,
      help,
      fieldClassName,
      placeholder,
      onChange,
      onKeyUp,
      onBlur,
      onFocus,
      required,
      value,
      isChecked,
      errorClassName,
      isError,
      error,
      disabled,
      inline = false,
      autoComplete,
      type = 'text',
      size = 'large',
      withMargin = false,
      childOutsideLabel,
      renderDescription,
      numberFieldPrefix,
      renderPrefix,
      thousandSeparator,
      minimumNum,
      format,
      ...rest
    }: FieldPropsType,
    ref: any
  ) => {
    const [show, setShow] = useState(false)
    //const [field, meta] = useField(name);
    //const classes = classnames("InputField__input", className);

    const togglePassword = () => setShow((prev) => !prev)

    const _type = type === 'password' ? (show ? 'text' : 'password') : type

    const isBorder = classnames({
      InputFieldBorder: !isError,
      ['InputFieldBorder_error']: isError === true,
    })

    return (
      <InputField
        id={id}
        label={label}
        size={size}
        type={type}
        withMargin={withMargin}
        description={help}
        renderDescription={renderDescription}
        error={error}
        isError={isError}
        errorClassName={errorClassName}
        required={required}
        inline={inline}
        childOutsideLabel={childOutsideLabel}
      >
        <Fragment>
          {type === 'number' ? (
            <NumberFormat
              value={value ? value : ''}
              name={name}
              allowNegative={false}
              format={format}
              placeholder={placeholder}
              autoComplete={autoComplete}
              className={classnames(
                fieldClassName,
                isBorder,
                'InputField__input'
              )}
              min={minimumNum}
              thousandSeparator={thousandSeparator}
              prefix={numberFieldPrefix}
              disabled={disabled}
              onValueChange={(e) => {
                onChange && onChange(e)
              }}
              onKeyUp={onKeyUp}
              onFocus={onFocus}
            />
          ) : type === 'checkbox' ? (
            <Checkbox
              {...rest}
              id={id}
              name={name}
              value={isChecked}
              disabled={disabled}
              type={type}
              className={fieldClassName}
              onChange={onChange}
              onBlur={onBlur}
              onFocus={onFocus}
            />
          ) : type === 'textarea' ? (
            <textarea
              {...rest}
              ref={ref}
              name={name}
              disabled={disabled}
              placeholder={placeholder}
              defaultValue={value}
              className={classnames(
                styles.TextAreaField,
                `InputField--large`,
                'InputField__input',
                fieldClassName,
                isBorder
              )}
              style={{ whiteSpace: 'normal' }}
              autoComplete={autoComplete}
              onChange={onChange}
              onBlur={onBlur}
              onKeyUp={onKeyUp}
              onFocus={onFocus}
            />
          ) : (
            <>
              {renderPrefix ? (
                <span className={styles.FieldInput__Prefix}>
                  {renderPrefix}
                </span>
              ) : null}

              <input
                {...rest}
                ref={ref}
                className={classnames(
                  fieldClassName,
                  `InputField--${size}`,
                  'InputField__input',
                  isBorder,
                  {
                    [styles.FieldInput__PrefixPadding]: renderPrefix,
                  }
                )}
                name={name}
                type={_type}
                disabled={disabled}
                placeholder={placeholder}
                value={value ? value : ''}
                autoComplete={autoComplete}
                onChange={onChange}
                onBlur={onBlur}
                onKeyUp={onKeyUp}
                onFocus={onFocus}
              />
              {type === 'password' ? (
                <span className="InputField__Password_Visibility">
                  <Button
                    aria-label="Show password"
                    name={`${show ? 'Hide' : 'Show'}`}
                    as="text"
                    textVariant="dark"
                    onClick={togglePassword}
                  />
                </span>
              ) : null}
            </>
          )}

          {/* )}
     </FormikField> */}
        </Fragment>
      </InputField>
    )
  }
)

Field.displayName = 'Field'
export default Field
