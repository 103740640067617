/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import ProfileSideBar from '../component/AccountSideBar/AccountSideBar';
import ProfileLoader from '../../../../components/SkeletonLoader/Account/ProfileLoader';
import { useAppDispatch, useAppSelector } from '../../../../redux/store/hook';
import {
  useFetchUserProfileDetailsQuery,
  useUpdateProfileMutation,
} from '@ekenta/redux/store/services/accountApi';
import { loginPathUrl } from '@ekenta/routes/constant';
import BreadCrumb from '../component/BreadCrumb';
import withDefaultLayout from '../../../../hoc/Layout/withDefaultLayout';
import BackButton from '../component/BackButton';
import {
  Button,
  CardBox,
  CustomAlert,
  Loader,
  Overlay,
  PageTitle,
  HelmetHeader,
} from '@ekenta/components';

import styles from './Profile.module.scss';
import classnames from 'classnames';
import { useNavigate } from 'react-router-dom';
import { ListDetails, SectionPass } from './component';
import { Pencil } from '@ekenta/components/icons';
import { setUser } from '@ekenta/redux/store/states/reducerSlice/authenticationSlice';

const Profile = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [isEdit, setIsEdit] = useState(false);
  const { ek_isAuth, token, account } = useAppSelector(
    (state) => state.auth.authentication,
  );
  const { data, isLoading, isFetching, isError, error, isSuccess } =
    useFetchUserProfileDetailsQuery(token, {
      skip: !token,
    });

  const [updateUser, getResult] = useUpdateProfileMutation();

  useEffect(() => {
    if (!ek_isAuth && !token) {
      return navigate(loginPathUrl);
    }
    if (getResult?.isSuccess) {
      dispatch(
        setUser({
          user: {
            ...account.user,
            firstName: getResult?.data.user.first_name,
            lastName: getResult?.data.user.last_name,
            phoneNumber: getResult?.data.user.phone_number,
            gender: getResult?.data.user.gender,
            location: getResult?.data.user.location,
            lga: getResult?.data.user.lga,
          },
          token: token,
          anonymousUser: false,
          ek_isAuth: true,
        }),
      );
      setIsEdit(false);
    }
  }, [ek_isAuth, token, getResult?.isSuccess]);

  const handleShowForm = () => setIsEdit(true);
  const handleHideForm = () => setIsEdit(false);

  const handleUpdateProfile = (data: any) => {
    updateUser({ data, token });
  };

  return (
    <>
      <HelmetHeader
        title="Manage Profile "
        description="Qlason Profile Account Page"
      />
      <div className="">
        <Container className="account-user-container">
          <>
            <BreadCrumb activePage="Manage Profile" />
          </>

          <Row
            className={classnames(
              styles.ProfileRow__Parent,
              'account-pro-row g-2',
            )}
          >
            <ProfileSideBar isLoading={isLoading} />
            <Col lg={9} className="account-profile-col-right">
              <Col className="mx-1 mx-lg-0">
                <PageTitle
                  title="Profile"
                  margin={false}
                  subTitle={<BackButton />}
                />
              </Col>

              {getResult?.isSuccess ? (
                <CustomAlert
                  variant="success"
                  show={true}
                  message={`${getResult?.data.message}`}
                />
              ) : null}

              {getResult?.isError && getResult?.error.status === 401 ? (
                <CustomAlert
                  variant="danger"
                  show={true}
                  message={`${getResult?.error.message}`}
                />
              ) : null}

              {isLoading || isFetching ? (
                <ProfileLoader />
              ) : isSuccess ? (
                <>
                  <Row className="account-row">
                    <Col xs={12} lg={6}>
                      <CardBox
                        title="Your profile information"
                        margin="none"
                        render={
                          <>
                            {!isEdit ? (
                              <Button
                                aria-label="Edit profile information"
                                prefixIcon={<Pencil />}
                                name="Edit"
                                as="text"
                                textVariant="dark"
                                className={styles.Edit__Button}
                                onClick={handleShowForm}
                              />
                            ) : null}
                          </>
                        }
                      >
                        <ListDetails
                          data={data.user}
                          isEdit={isEdit}
                          isLoading={getResult?.isLoading}
                          handleHideForm={handleHideForm}
                          handleSubmit={handleUpdateProfile}
                        />
                      </CardBox>
                    </Col>
                    <Col xs={12} lg={6}>
                      <CardBox title="Sign in method" margin="none">
                        <SectionPass />
                      </CardBox>
                    </Col>
                  </Row>
                </>
              ) : null}

              {isError && error.status === 'FETCH_ERROR' ? (
                <CustomAlert
                  variant="danger"
                  show={true}
                  message={'Something went wrong, Try again'}
                />
              ) : null}

              {isError && error.status === 401 ? (
                <CustomAlert
                  variant="danger"
                  show={true}
                  message={error.data.detail}
                />
              ) : (
                ''
              )}

              {isLoading || isFetching ? (
                <Overlay bgColor="dark">
                  <Loader isCenter size="large" color="white" />
                </Overlay>
              ) : null}
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default withDefaultLayout(Profile);
