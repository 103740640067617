/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { Fragment, useEffect, useState } from 'react'
import { getIn, useFormikContext } from 'formik';
import { AddCard, CancelBtn, InnerContainerStyle, StepContentStyle, StepFormGroup, StepHeading } from '../../style'
import { Field, Label, Typography } from '@ekenta/components';
import { NewProductListingFormType } from '@ekenta/interfaces/ProductListing';
import { useFetchFeaturesByColIdQuery } from '@ekenta/redux/store/services/collectionApi';
import { useAppSelector } from '@ekenta/redux/store/hook';
import CustomSelect from '@ekenta/components/Select/CustomSelect';
import { SquarePlus } from '@ekenta/components/icons';
import styled from 'styled-components';
import { VscTrash } from 'react-icons/vsc';



const mapCollectionData = (data: any) => {
    return data && data.map((item: any) => (
        {
            value: item.id,
            label: item.name
        }
    ));

}

const ProductFeatures = () => {
    const { values, errors, touched, setFieldValue, setFieldTouched, handleBlur, handleChange } = useFormikContext<NewProductListingFormType>();

    const { token } = useAppSelector(state => state.auth.authentication);

    const [featuresOptions, setFeaturesOptions] = useState<any>([]);

    const { data, isLoading, isError: feature_isError, isSuccess }
        = useFetchFeaturesByColIdQuery({
            token,
            id: values.collectionId
        }, {
            skip: !values.collectionId
        });


    const defaultValue = (options: any, val: string) => {
        return options ? options.find((option: any) => option.value === parseInt(val)) : "";
    }


    useEffect(() => {
        if (isSuccess) {
            const obj = mapCollectionData(data.features);
            setFeaturesOptions(obj);
        }
    }, [isSuccess])


    const handleAddFeatureField = () => setFieldValue('featureValues',
        [
            ...values.featureValues,
            {
                feature_id: '',
                feature_value: '',
                unit_id: ''
            }
        ]
    );

    const handleRemoveFeatureField = (index: number) => {
        const list_ = [...values.featureValues];
        list_.splice(index, 1);
        setFieldValue('featureValues', list_);
    }

    return (
        <InnerContainerStyle>
            <StepHeading>
                <Typography
                    as="h3"
                    color="default"
                    size="xlarge"
                    heading >
                    Product Features
                </Typography>
                <Typography
                    as="p"
                    color="default"
                    size="small">
                    Add features of your product
                </Typography>
            </StepHeading>
            <StepContentStyle>
                {values.featureValues && values.featureValues.map((singleitem, index) => (
                    <Fragment key={index}>
                        <Flex>
                            <StepFormGroup >
                                <Label
                                    type="text"
                                    text="Features"
                                    required={true}
                                />
                                <CustomSelect
                                    name={`featureValues.${index}.feature_id`}
                                    className="custom-select"
                                    placeholder="Select Features"
                                    isDisabled={!values.collectionId ? true : false}
                                    noOptionsMessage={() => feature_isError ? 'Could not load, refresh' : 'No Features Found'}
                                    isLoading={isLoading}
                                    value={data ? defaultValue(featuresOptions, singleitem.feature_id) : ""}
                                    options={featuresOptions}
                                    onBlur={handleBlur}
                                    isError={getIn(touched, `featureValues.${index}.feature_id`) && getIn(errors, `featureValues.${index}.feature_id`) ? true : false}
                                    error={getIn(errors, `featureValues.${index}.feature_id`)}
                                    onChanged={(value: any) => {
                                        setFieldTouched(`featureValues.${index}.feature_id`, true)
                                        value ? setFieldValue(`featureValues.${index}.feature_id`, value.value) :
                                            setFieldValue(`featureValues.${index}.feature_id`, '')
                                    }}
                                />
                            </StepFormGroup>
                            <StepFormGroup>
                                <Field
                                    id="feature_value"
                                    name={`featureValues.${index}.feature_value`}
                                    disabled={!values.collectionId ? true : false}
                                    label="Feature Value"
                                    type="text"
                                    required
                                    size="large"
                                    childOutsideLabel={false}
                                    placeholder="Enter Value"
                                    fieldClassName=""
                                    autoComplete="off"
                                    onChange={(e) => {
                                        setFieldValue(`featureValues.${index}.feature_value`, e.target.value.toLowerCase());
                                    }}
                                    onBlur={handleBlur}
                                    value={singleitem.feature_value}
                                    isError={
                                        getIn(touched, `featureValues.${index}.feature_value`) &&
                                            getIn(errors, `featureValues.${index}.feature_value`) ? true : false
                                    }
                                    error={getIn(errors, `featureValues.${index}.feature_value`)}
                                />
                            </StepFormGroup>
                            {values.featureValues.length > 1 && (
                                <CancelBtn pos="relative" type="button" onClick={() => handleRemoveFeatureField(index)}>
                                    <VscTrash color="#F75555" fontSize="1.2rem" />
                                </CancelBtn>
                            )}
                        </Flex>
                        {values.featureValues.length - 1 === index && values.featureValues.length < 5 && (
                            <AddCard disabled={!values.collectionId ? true : false} type="button" mt="28px"
                                onClick={handleAddFeatureField}>
                                <SquarePlus />
                                <span>Add More</span>
                            </AddCard>
                        )}
                    </Fragment>
                ))}

                <WeightWrap>
                    <Field
                        id="productWeight"
                        name="productWeight"
                        label="Product Weight (kg)"
                        disabled={false}
                        type="number"
                        required
                        size="large"
                        childOutsideLabel={false}
                        placeholder="Enter the weight"
                        fieldClassName=""
                        autoComplete="off"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.productWeight}
                        isError={
                            getIn(touched, "productWeight") &&
                                getIn(errors, "productWeight") ? true : false
                        }
                        error={getIn(errors, "productWeight")}
                    />
                    <p>1Litre = 1kg, 1cl = 0.01kg,</p>
                </WeightWrap>
            </StepContentStyle>
        </InnerContainerStyle>
    )
}

const Flex = styled.div`
    display: flex;
    width: 100%;
    height: auto;
    //padding-top: 2px;
    align-items: flex-start;
    gap:5px;

    button{
        align-self: center !important;
        height: 100%;
        //margin-bottom: 5px !important;
        flex: 0 !important;

        svg{
            width: fit-content !important;
            height: fit-content !important;
        }

    }
`;

const WeightWrap = styled.div`
    padding-top: 20px;
    p{
        color: var(--gray-500, #667085)!important;
        font-family: var(--eke-body-font-family);
        font-size: 14px;
        font-weight: var(--eke-font-weight-normal);
        line-height: 140%;
        padding-top: 8px;
        letter-spacing: 0.2px;
    }
`;
export default ProductFeatures

