import { device } from "@ekenta/utils/devices";
import styled from "styled-components";


export const SectionCardStyle =  styled.div`
	width: 100%;
	display: flex;
	//padding: 0px 5px 16px 10px;
	min-height: 300px;
	flex-direction: column;
	align-items: flex-start;
	gap: 24px;
	border-radius: 12px;
	background: var(--eke-color-white);
	margin-bottom: 1em;
	margin-top: 1em;
	overflow: hidden;
	border: 1px solid #e4f4ff;

	/* @media ${device.lg}{
		padding: 16px 23px 16px 23px;
	} */


	.TopHeader__Style{
		display: flex;
		height: 67px;
		padding: 13px 5px 12px 10px;
		align-items: center;
		justify-content: space-between;
		border-top-right-radius: 12px;
		border-top-left-radius: 12px;
		background-color: #DEF1FD;  //#FEE2CC;  //#e4f4ff;//#F3FAFF;
		width: 100%;
		font-family: var(--eke-body-font-family);

		@media ${device.lg}{
			padding: 13px 27px 12px 16px;
		}

		h5{
			color: var(--eke-color-secondary);
			font-weight: var(--eke-font-weight-bold);
			font-size: 20px;
			line-height: 120%;
			margin: 0 !important;

			@media ${device.lg}{
			font-size: 22px;
			}
		}

		a{
			display: flex;
			align-items: center;
			color: var(--eke-color-primary);
			text-align: center;
			font-size: 13.5px;
			font-weight: var(--eke-font-weight-bold);
			line-height: 120%;
			text-decoration: none;
			cursor: pointer;

			@media ${device.lg}{
				font-size: 16px;
			}
			

			svg{
				margin-top: 0.2em;
				font-size: 1rem;
			}

			&:hover{
				text-decoration: underline;
            }
		}
	}

	.BottomContent__Style{
		position: relative;
		width: 100%;
		height: auto;
		padding: 0px 7px 5px 7px;
		margin: 0.1em auto;
		overflow: hidden;
		@media ${device.lg}{
			padding: 0px 10px 5px 10px;
	    }
	}

`;