/* eslint-disable @typescript-eslint/no-explicit-any */
import { useState, useEffect } from 'react'

interface DeviceInfo {
  isMobile: boolean
  isAndroid: boolean
  isIOS: boolean
}

const useDeviceDetection = (): DeviceInfo => {
  const [device, setDevice] = useState<DeviceInfo>({
    isMobile: false,
    isAndroid: false,
    isIOS: false,
  })

  useEffect(() => {
    const userAgent =
      navigator.userAgent || navigator.vendor || (window as any).opera

    const isMobile = /Mobi|Android|iPhone|iPad|iPod/i.test(userAgent)
    const isAndroid = /Android/i.test(userAgent)
    const isIOS = /iPhone|iPad|iPod/i.test(userAgent)

    setDevice({
      isMobile,
      isAndroid,
      isIOS,
    })
  }, [])

  return device
}

export default useDeviceDetection
