/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useCallback, useEffect, useState } from "react"
import { FiChevronDown, FiSliders } from "react-icons/fi"
import Dropdown from "@ekenta/components/Dropdown/Dropdown3"



export const Filters = (props: {
  onClick: (value: string) => void;
}) => {

  //const [content, setContent] = useState("");
  const [current, setCurrent] = useState({
    key: "today",
    name: "Today"
  });

  useEffect(() => {
    props.onClick(current.key);
  }, [current.key])

  const handler = (value: { key: string, name: string }) => {
    setCurrent(value);
  }


  const menuArray = [
    {
      name: "Today",
      icon: "",
      active: false,
      key: "today",
      handler: () => handler({ key: "today", name: "Today" })
    },
    {
      name: "This Week",
      icon: "", active: false,
      key: "this_week",
      handler: () => handler({ key: "this_week", name: "This Week" })
    },
    {
      name: "This Month",
      icon: "",
      active: false,
      key: "this_month",
      handler: () => handler({ key: "this_month", name: "This Month" })
    },
    {
      name: "This Year",
      icon: "", active: false,
      key: "this_year",
      handler: () => handler({ key: "this_year", name: "This Year" })
    },
    {
      name: "Yesterday",
      icon: "",
      active: false,
      key: "yesterday",
      handler: () => handler({ key: "yesterday", name: "Yesterday" })
    },
    {
      name: "Last Week",
      icon: "",
      active: false,
      key: "last_week",
      handler: () => handler({ key: "last_week", name: "Last Week" })
    },
    {
      name: "Last Month",
      icon: "", active: false,
      key: "last_month",
      handler: () => handler({ key: "last_month", name: "Last Month" })
    },
    {
      name: "Last Year",
      icon: "",
      active: false,
      key: "last_year",
      handler: () => handler({ key: "last_year", name: "Last Year" })
    },
    {
      name: "All Time",
      icon: "",
      active: false,
      key: "all",
      handler: () => handler({ key: "all", name: "All Time" })
    },
  ];

  const filterActive = useCallback((data: any, current: { key: string, name: string }) => {
    return data.filter((item: any, index: number) => {
      if (item.key === current.key) {
        data[index].active = true;
      }
      return data;
    });

  }, [menuArray, current])




  return (
    <>
      <Dropdown
        name={current ? current.name : "Filters"}
        prefixIcon={<FiSliders />}
        suffixIcon={<FiChevronDown />}
        placement="bottom"
        menus={filterActive(menuArray, current)}
        tooltipContent=""
      />
    </>
  )
}
