/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react'
import styled, { css } from 'styled-components'
import { useLocation, useNavigate } from 'react-router-dom'
import slugify from 'slugify'
import moment from 'moment'
import { Avatar } from '../style'
import { FiImage } from 'react-icons/fi'
import PingCircle from './Pingcircle'
import { isMobileView } from '@ekenta/utils'

const UserCard = (props: {
  forwardedRef?: React.Ref<HTMLButtonElement>
  active: boolean
  id: string
  product: {
    name: string
    price: number
    images: {
      url: string
    }[]
  },
  sellerName: string
  dateTime: string
  lastMsg: {
    message: string
    is_read: number
    type: string
  }
  hasNewMessages: boolean
  newMessagesCount: number
  participantUser: any
  handleIndicatorClick: (conversationId: string, is_read: boolean) => void
}) => {
  const {
    forwardedRef,
    active,
    id,
    product,
    dateTime,
    sellerName,
    lastMsg,
    hasNewMessages,
    participantUser,
    handleIndicatorClick,
  } = props

  const navigate = useNavigate()
  const { pathname } = useLocation()
  const is_resized = isMobileView();

  const handleClick = () => {
    console.log(lastMsg,lastMsg)
    const is_read = lastMsg?.is_read === 0
    handleIndicatorClick(id, is_read)
    const queryParams = {
      conversationId: id,
      store: slugify(
        participantUser ? participantUser : sellerName ? sellerName : 'Seller'
      ),
      is_resized: `${is_resized}`
    }
    const queryString = new URLSearchParams(queryParams).toString()
    navigate({
      pathname: pathname,
      search: `?${queryString}`,
    })
  }

  return (
    <CardButton ref={forwardedRef} active={active} onClick={handleClick}>
      <div className="ChatUser_Image">
        <Avatar>
          {participantUser
            ? participantUser.charAt(0)
            : sellerName
            ? sellerName.charAt(0)
            : 'Seller'.charAt(0)}
        </Avatar>
        {/* <img src={shopLogo} alt="" /> */}
      </div>
      <div className="ChatUser_Content">
        <div className="UserInfo">
          <HStack>
            <Box>
              <span className="ChatUser__Name">
                {participantUser
                  ? participantUser
                  : sellerName
                  ? sellerName
                  : 'Seller'}
              </span>
              <h5>{product.name}</h5>
            </Box>
            <span
              className={`ChatTime__Sent ${
                active === false && hasNewMessages ? 'date_active' : ''
              } `}
            >
              {dateTime ? moment(dateTime).format('hh:mm a') : ''}
            </span>
          </HStack>
          <HStack className="message_Conversation">
            <div
              className={`message_last_history ${
                hasNewMessages && active === false ? 'new-message' : ''
              }`}
            >
              {lastMsg.type === 'TEXT' ? (
                <p>{lastMsg.message}</p>
              ) : lastMsg.type === 'FILE' ? (
                <p>
                  <FiImage fontSize="1.02rem" />
                  Photo
                </p>
              ) : null}
            </div>
            {active === false && hasNewMessages ? (
              <div className="unread_indicator">
                <span>
                  <div
                    className="unread_indicator_child"
                    data-testid="icon-unread-count"
                    aria-label="Unread"
                  >
                    <PingCircle />
                  </div>
                </span>
              </div>
            ) : null}
          </HStack>
        </div>
      </div>
    </CardButton>
  )
}

const CardButton = styled.button<{ active: boolean }>`
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0px 10.25px 0px 15.25px;
  gap: 10px;
  outline: none;
  border: none;
  background: transparent;
  box-shadow: 0px 4px 60px 0px rgba(92, 107, 173, 0.03);
  transition: background-color transform 0.3s ease-in-out;

  ${(props) =>
    props.active &&
    css`
      border-bottom: 1px solid var(--eke-color-primary);
      background: var(--eke-color-primary-100);
    `}

  &:hover {
    background: var(--eke-color-primary-100);
  }

  .ChatUser_Image {
    width: 50px;
    height: 50px;
    max-width: 63px;
    border-radius: 50%;
    flex-shrink: 0;

    img {
      width: 48.8px !important;
      height: 48.8px !important;
      border-radius: 50%;
    }
  }

  .ChatUser_Content {
    flex: 1;
    position: relative;
    justify-content: space-between;
    text-align: left;
    border-bottom: 1px solid rgba(134, 150, 160, 0.15);
    padding: 13.25px 0px 13.25px 0px;

    min-height: 40px;

    .UserInfo {
      font-family: var(--eke-body-font-family);
      .ChatUser__Name {
        color: #000;
        font-size: 14px;
        font-weight: 500;
        line-height: 22.35px;
        text-transform: capitalize;
      }

      h5 {
        color: #000;
        font-size: 15px;
        font-weight: 600;
        line-height: 18.35px;
        margin: 0 !important;
        width: 180px;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }

      .message_Conversation {
        .message_last_history {
          flex: 1;
          max-width: 80%;

          p {
            white-space: nowrap; /* Prevent text from wrapping to a new line */
            overflow: hidden; /* Hide overflowing text */
            text-overflow: ellipsis;
            margin: 0 !important;
            color: #8a8a8a;
            opacity: 0.8;
            font-family: var(--eke-body-font-family);
            font-size: 13.5px;
            font-weight: 400;
            line-height: 28.35px;
            display: flex;
            align-items: center;
            gap: 0.5em;
          }
        }

        .unread_indicator {
          flex-shrink: 1;
          font-weight: var(--eke-font-weight-semibold);
          /* color: var(--unread-timestamp); */

          .unread_indicator_child {
            display: inline-block;
            vertical-align: top;
            padding-right: 30px

            span{

            }
          }
        }
      }
    }

    .date_active {
      color: #7e7d7a !important;
    }

    .new-message {
      p {
        color: var(--eke-bg-primary-color) !important;
        font-weight: bold !important;
      }
    }

    .ChatTime__Sent {
      position: absolute;
      right: 7px;
      top: 10%;
      color: #000;
      font-family: var(--eke-body-font-family);
      font-size: 12px;
      font-weight: 400;
      line-height: 28.35px; /* 218.077% */
    }
  }
`

const HStack = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 0;
`

const Box = styled.div`
  max-width: 80%;
`

export default UserCard
