import styled from "styled-components";
import React from "react";

interface ContainerType {
  bgColor?: string;
  position?: string;
}

interface OverlayType {
  children?: React.ReactNode;
  bgColor?: "light" | "dark";
  onClick?: () => void;
  className?: string;
  position?: "absolute" | "fixed";
}

function Overlay(props: OverlayType) {
  const {
    className,
    position = "fixed",
    onClick,
    bgColor,
    children } = props;
  return <Container className={className} position={position} onClick={onClick} bgColor={bgColor}>{children}</Container>;
}

const Container = styled.div<ContainerType>`
  position: ${(props) => props.position};
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: ${(props) => `${props.bgColor === "dark"
    ? "rgba(0, 0, 0, 0.5)"
    : props.bgColor === "light"
      ? "rgba(248, 248, 255, 0.5)"
      : props.bgColor
    }`};
  z-index: 2;
`;

export default Overlay;
