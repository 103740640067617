/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {
  ChangeEvent,
  Fragment,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import * as queryString from 'query-string'
import styled, { keyframes } from 'styled-components'
import ChatProduct from '../common/ChatProduct'
import ChatBubbles from '../common/ChatBubbles'
import Loader from '@ekenta/components/Loader/Loader'
import ChatForm from '../common/ChatForm'
import { useAppSelector } from '@ekenta/redux/store/hook'
import { Avatar } from '../style'
import { useSendMessageMutation } from '@ekenta/redux/store/features/chat/chatApiSlice'
import { BASE_URL } from '@ekenta/constants'
import axios from 'axios'
import { Button } from '@ekenta/components'
import { FiArrowLeft, FiUploadCloud, FiX } from 'react-icons/fi'
import { device } from '@ekenta/utils/devices'
import { ChatPathUrl } from '@ekenta/routes/constant'

interface Message {
  message: string
  user: {
    email: string
    first_name: string
    last_name: string
  }
}

const AllChats = (props: {
  closeIcon?: JSX.Element
  isPopup: boolean
  isChatStarted: boolean
  isLoading?: boolean
  from: 'admin' | 'seller' | 'buyer'
  conversationId: any
  toName?: string
  messages: Message[]
  setMessages: any
  redirectUrl?: string
  product?: {
    id: string
    name: string
    price: number
    imageUrl: string
  } | null
}) => {
  const { toName, from, conversationId, setMessages, messages, product, redirectUrl=ChatPathUrl } = props
  const navigate = useNavigate()
  const { token, account } = useAppSelector(
    (state) => state.auth.authentication
  )
  const chatContainerRef = useRef<HTMLDivElement>(null)
  const [file, setFile] = useState<File | null>(null)
  const [preview, setPreview] = useState('')
  const [loading, setLoading] = useState(false)

  const [sendMessage, result] = useSendMessageMutation()
  const [loader, setLoader] = useState(false)

  useEffect(() => {
    const query = queryString.parse(window.location.search)
    const room = query.conversationId || conversationId
    if (room) {
      fetchChats(room as string)
    }
    scrollToBottom()
  }, [window.location.search])

  const fetchChats = useCallback(
    async (conversationId: string): Promise<void> => {
      try {
        setLoader(true)
        const response = await axios.get(
          `${BASE_URL}/api/get-messages/${conversationId}`,
          {
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${token}`,
            },
          }
        )
        const result = response.data.data
        setMessages(result)
        setLoader(false)
      } catch (error) {
        toast.error('Something went wrong, Try again')
        setLoader(true)
      }
    },
    []
  )

  // Function to handle form submission and send messages
  const handleSubmit = async (data: { message: string; type: string }) => {
    sendMessage({ conversationId, data })
      .unwrap()
      .then(() => {
        scrollToBottom()
       // dispatch(setChatCountAsync())
        return 'success'
      })
      .catch((error) => {
        const data = error?.data
        const message = data ? data.statusMessage : 'Error sending message'
        toast.error(message, { position: 'bottom-center' })
      })
  }

  const scrollToBottom = () => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight
    }
  }

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files
    if (!files) {
      return toast.error('No files selected', { position: 'bottom-center' })
    }
    const file = files[0]
    if (
      !file.type.startsWith('image/') ||
      file.type === 'image/gif' ||
      file.type === 'image/svg+xml'
    ) {
      return toast.error('File Type not supported')
    }
    const reader = new FileReader()
    reader.onload = (event: ProgressEvent<FileReader>) => {
      const result = event.target?.result as string
      setPreview(result)
    }
    reader.readAsDataURL(file)
    setFile(file)
  }

  const uploadFile = async (acceptedFile: File | null) => {
    if (!acceptedFile) {
      return toast.error('No files selected')
    }
    setLoading(true)
    const formData = new FormData()
    formData.append('media', acceptedFile)

    try {
      const config = {
        method: 'POST',
        url: `${BASE_URL}/api/upload-media`,
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${token}`,
        },
        data: formData,
      }
      await axios(config)
        .then(async (response) => {
          if (response.status === 200) {
            const imageUrl = response?.data?.url
            const data = {
              message: imageUrl,
              type: 'FILE',
            }
            handleSubmit(data)
            setLoading(false)
            setPreview('')
            setFile(null)
          }
        })
        .catch(() => {
          setLoading(false)
          setPreview('')
          setFile(null)
          toast.error(`Could not upload file`, {
            toastId: '-toggle-error-400-id-toast-error',
          })
        })
    } catch (e) {
      setLoading(false)
      setPreview('')
      setFile(null)
      toast.error(`Something went wrong`, {
        toastId: '-wrong-error-400-error',
      })
    }
  }

  const handleClose = () => {
    setFile(null)
    setPreview('')
  }

  const handleBack = () => navigate(redirectUrl)

  return (
    <Container>
      {preview && file ? (
        <FilePopper isopen={'true'}>
          <button
            disabled={loading}
            className="close-btn"
            onClick={handleClose}
          >
            <FiX fontSize="1.1rem" />
          </button>
          <div className="popper-wrapper">
            <div className="image-container">
              <img src={preview ?? ''} alt="preview" />
            </div>
            <div className="button-group">
              <Button
                name="Upload Image"
                bgVariant="primary"
                textVariant="light"
                disabled={loading}
                prefixIcon={<FiUploadCloud />}
                className="Uloadfile"
                shadow={true}
                isLoading={loading}
                isLoadingText="Uploading..."
                onClick={() => uploadFile(file)}
              />
            </div>
          </div>
        </FilePopper>
      ) : (
        <Fragment>
          <TopHeader>
            <div className="FlexStyle">
              <div className="UserChat_Info">
                <div className="User_group">
                  <span
                    role="button"
                    className="back_to_allusers"
                    onClick={handleBack}
                  >
                    <FiArrowLeft fontSize="1.2rem" />
                  </span>
                  <div className="UserChat_Image">
                    {toName && <Avatar>{toName.charAt(0)}</Avatar>}
                  </div>
                </div>
                <div className="UserInfo__Name_">
                  <h5>{toName}</h5>
                </div>
              </div>
              {props.closeIcon ? props.closeIcon : null}
            </div>
          </TopHeader>
          <ChatMessages ref={chatContainerRef}>
            {loader ? (
              <HStack>
                <Loader size="xlarge" />
              </HStack>
            ) : (
              <>
                {product && (
                  <ChatProduct
                    id={product.id}
                    name={product.name}
                    price={product.price}
                    imageUrl={product.imageUrl}
                  />
                )}
                <div className="ChatBubbleList">
                  <ChatBubbles
                    isDateCurrent={false}
                    messages={messages}
                    loading={result?.isLoading}
                    from={from}
                    currentUser={account.user ? account.user.id : 0}
                  />
                </div>
              </>
            )}
            <br />
            <br />
            <br />
          </ChatMessages>
          <ChatForm
            isPopup={props.isPopup}
            isLoading={loader || result?.isLoading}
            handleChange={handleChange}
            handleSubmit={handleSubmit}
          />
        </Fragment>
      )}
    </Container>
  )
}

const fadeInAnimation = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`

const Container = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
  background-color: var(--eke-bg-light-color);
  padding-right: 4px;
  border-right: 1px solid #eee;
`

const TopHeader = styled.div`
  display: flex;
  width: 100%;
  padding: 9.5px 25px 9.5px 10px;
  align-items: center;
  background: var(--eke-bg-light-color);
  box-shadow: 0px 4px 60px 0px rgba(92, 107, 173, 0.1) !important;
  border-bottom: 1px solid #eee;
  z-index: 9;

  @media ${device.lg} {
    padding: 9.5px 25px 9.5px 25px;
  }

  .FlexStyle {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .UserChat_Info {
      width: auto;
      display: flex;
      align-items: center;
      gap: 0.8em;
      flex: 1;

      .User_group {
        display: flex;
        align-items: center;
        gap: 12px;

        .back_to_allusers {
          width: auto;
          display: inline-flex;
          @media ${device.lg} {
            display: none;
          }
        }
      }

      .UserChat_Image {
        display: flex;
        padding: 0px;
        align-items: center;
        justify-content: center;
        border-radius: 84px;
        width: 50.8px;
        height: 50.8px;
        border-radius: 29.4px !important;
        border: 0.42px solid #e5e5e5;
      }

      .UserInfo__Name_ {
        padding-top: 5px;

        h5 {
          color: #000;
          font-size: 18.9px;
          font-family: var(--eke-body-font-family);
          font-weight: var(--eke-font-weight-bold);
          margin: 0 !important;
          font-weight: 700;
        }

        span {
          font-size: 12.5px;
        }
      }
    }
  }
`

const ChatMessages = styled.div`
  position: relative;
  width: 100%;
  height: 200px;
  overflow-x: hidden;
  overflow-y: auto;
  background-color: #f5fbff;
  flex: 1;
  transition: all 0.2s ease-in-out;

  .ChatBubbleList {
    width: 100%;
    animation: ${fadeInAnimation} 0.3s ease-in-out;
  }
`

const FilePopper = styled.div<{ isopen: string }>`
  width: 100%;
  bottom: 0;
  left: 0px;
  right: 0px;
  height: 100%;
  margin: 0 auto;
  background-color: #f2f8fc;
  border: 1px solid #eee;
  z-index: 99;
  display: flex;
  align-items: center;
  transition: all 0.5s ease-in-out;
  /* border-top-right-radius: 10px;
    border-top-left-radius: 10px; */
  transition: transform 0.3s ease-in-out;
  /* transform: translateY(${(props) =>
    props.isopen === 'true' ? '80%' : '100%'}); */

  .dropdiv {
    margin: 0 !important;
  }

  .close-btn {
    position: absolute;
    right: 18px;
    top: 10px;
    outline: none !important;
    border: 1px solid #eee !important;
    width: 35px;
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    border-radius: 50%;
  }

  .popper-wrapper {
    width: 100%;
    max-width: 60%;
    margin: 0 auto !important;
    transition: all 0.5s ease-in-out;
    position: sticky;
    bottom: 2%;

    .image-container {
      width: 100%;
      background: #fff;
      border-radius: 10px;
      height: 300px;
      border: 1px solid #fff !important;

      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
        transition: all 0.5s ease-in-out;
      }
    }

    .button-group {
      padding-top: 35px;
      width: 100%;
      text-align: center;
    }
  }
`

const HStack = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`

export default AllChats
