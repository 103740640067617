/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react'
import { FiSearch } from 'react-icons/fi'
import { LazyLoadComponent } from 'react-lazy-load-image-component'
import styled from 'styled-components'
import {
  Button,
  CustomAlert,
  Field,
  ProductCard,
  Typography,
} from '@ekenta/components'
import ProductListLoader from '@ekenta/pages/Collections/component/SkeletonLoader/ProductListLoader'
import { device } from '@ekenta/utils/devices'
import EmptyBox from '@ekenta/components/Box/EmptyBox'
import EmptyLive from '@ekenta/pages/Home/common/Livecommerce/EmptyLive'
import { ReactComponent as NigeriaTag } from '@ekenta/assets/icons/nigeria-tag.svg'

const ContentSection = (props: {
  title: string
  isLoading: boolean
  isFetching: boolean
  data: any
  isSuccess: boolean
}) => {
  const { title, isLoading, isFetching, data, isSuccess } = props
  //const allCart = token ? useFetchCartItemsQuery(token) : undefined
  const [searchVal, setSearchVal] = useState('')
  const [filteredProducts, setFilteredProducts] = useState([])

  useEffect(() => {
    if (searchVal === '' && filteredProducts.length > 0) {
      setFilteredProducts([])
    }
  }, [filteredProducts, searchVal])

  const onSearch = (e: { target: { value: React.SetStateAction<string> } }) => {
    setSearchVal(e.target.value)
  }

  const handleSearch = (data: any) => {
    if (!data) return null
    const products = data.eventProduct
    const filtered = products.filter((item: any) =>
      item.product.name.toLowerCase().includes(searchVal.toLowerCase())
    )
    setFilteredProducts(filtered)
  }

  const allData = data
    ? data.eventProduct.length > 0
      ? filteredProducts.length > 0
        ? filteredProducts
        : data.eventProduct
      : []
    : []

  return (
    <Container>
      <div className="TopHeader">
        <Typography as="h4" size="large" color="dark" heading>
          {title ? `${title} Products` : 'Market Day Products'}
        </Typography>
        <SearchStyle>
          <div className="SearchWrapper__Box">
            <Field
              id="search-field"
              name="search"
              size="small"
              value={searchVal}
              type="search"
              disabled={false}
              placeholder="Search"
              fieldClassName="SearchWrapper__Field"
              onChange={onSearch}
              autoComplete="off"
              renderPrefix={<FiSearch />}
            />
          </div>
          <Button
            name="Search"
            bgVariant="primary"
            textVariant="light"
            type="button"
            onClick={() => handleSearch(data)}
          />
        </SearchStyle>
      </div>
      <ProductsWrapper>
        <div className="ContentStyle product_vid_commponent">
          {isLoading || isFetching ? (
            <Row>
              <ProductListLoader />
            </Row>
          ) : null}
          {isSuccess && data ? (
            <Row>
              {data.is_active ? (
                allData.length > 0 ? (
                  allData.map((item: any) => {
                    return (
                      <>
                        <ProductCard
                          key={item.product.id}
                          productId={item.product.id}
                          isVariant={item.product.is_variant}
                          productVariation={item?.product.productVariation}
                          stockCount={item.product?.stock}
                          aggregateRating={item.product.rating}
                          isShadow={true}
                          productName={item.product.name}
                          price={item.product.price}
                          discount={item.product.discount}
                          adminDiscount={item.product.admin_discount}
                          maxPrice={item.product.max_price}
                          imageUrl={item?.product?.images[0]?.image_url ?? ''}
                          videoUrl={item.product.video_url}
                          condition={item.product.condition}
                          render={
                            <>
                              {data.type === 'NIGERIAN' ? (
                                <EventType>
                                  <NigeriaTag />
                                </EventType>
                              ) : null}
                            </>
                          }
                        />
                        {/* <LazyLoadComponent key={item.product.id}>
                          <ProductCardComponent
                            productId={item.product.id}
                            product={item.product}
                            layoutView={'grid'}
                            cartItem={
                              allCart
                                ? mapCartDataReturnQuantity(
                                    allCart?.data,
                                    item.product.id
                                  )
                                : null
                            }
                            isShadow={true}
                            productname={item.product.name}
                            defaultprice={item.product.price}
                            discount={item.product.discount}
                            adminDiscount={
                              item.discount
                                ? item.discount
                                : item.product.admin_discount
                            }
                            discountPercentage={item.product.discountPercentage}
                            maxPrice={item.product.max_price}
                            minPrice={item.product.min_price}
                            thumbnailUrl={
                              item.product.images.length > 0
                                ? item.product.images[0]?.image_url
                                : ''
                            }
                            imageUrl={
                              item.product.images.length > 0
                                ? item.product.images[1]?.image_url
                                : ''
                            }
                            videoUrl={item.product.video_url}
                            condition={item.product.condition}
                            render={
                              <>
                                {data.type === 'NIGERIAN' ? (
                                  <EventType>
                                    <NigeriaTag />
                                  </EventType>
                                ) : null}
                              </>
                            }
                          />
                        </LazyLoadComponent> */}
                      </>
                    )
                  })
                ) : (
                  <EmptyBox />
                )
              ) : (
                <NotActive>
                  <EmptyLive title="Event is not active" />
                </NotActive>
              )}
            </Row>
          ) : (
            <>
              <CustomAlert
                variant="danger"
                show={true}
                message="Something went wrong"
              />
              <Row>
                <ProductListLoader />
              </Row>
            </>
          )}
        </div>
      </ProductsWrapper>
    </Container>
  )
}

const Container = styled.div`
  background-color: var(--eke-color-white);
  width: 100%;
  height: auto;
  min-height: 500px;
  border-radius: 12px;
  flex-shrink: 0;
  padding: 10px 12px;
  margin-top: 20px;

  @media ${device.lg} {
    padding: 10px 21px;
    margin-top: 22px;
  }

  .TopHeader {
    width: 100%;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: space-between;
    display: inline-flex;
    gap: 8px;
    padding: 12px 0px 0px 0px;

    @media ${device.md} {
      align-items: center;
      flex-direction: row;
    }

    h4 {
      color: var(--secondary-500, #1d4057);
      font-family: var(--eke-body-font-family);
      font-weight: var(--eke-font-weight-bold);
      font-size: 20px;
      line-height: 120%; /* 38.4px */

      @media ${device.md} {
        font-size: 22px;
      }

      @media ${device.lg} {
        font-size: 24px;
      }
    }
  }
`

const NotActive = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
`

const EventType = styled.div`
  position: absolute;
  top: 5px;
  left: 7.5px;

  svg {
    width: 23px !important;
    height: 23px !important;
  }
`

const SearchStyle = styled.div`
  position: relative;
  width: 100%;
  margin-top: 5px;

  @media ${device.md} {
    width: 40%;
  }

  .SearchWrapper__Box {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: stretch;

    .InputField__fieldContainer {
      span {
        left: 2px !important;
        padding: 10px 11px 10px 10px !important;
      }
    }
    .InputField {
      width: 100% !important;
      input {
        border-radius: 30px !important;
        border: 1px solid var(--alerts-status-disabled, #d8d8d8) !important;
        background: var(--eke-color-white);
        box-shadow: none !important;
        padding-top: 10px !important;
        padding-bottom: 10px !important;
        padding-right: 82px !important;
      }
    }

    .SearchWrapper__Field {
      z-index: 0;
      width: 100% !important;
    }
  }

  button {
    position: absolute;
    right: -8px;
    top: 1px;
    width: 90px !important;
    border-radius: 62.069px !important;
    padding: 11.172px 9.931px !important;
    box-shadow: 2.48276px 4.96552px 14.89655px 0px #efdcc7 !important;
  }
`

const ProductsWrapper = styled.div`
  width: 100%;
  max-height: auto;
  position: relative;

  .ContentStyle {
    height: auto;
    display: flex;
    flex-direction: column;
    border-top: 1px solid #f2f2f2;
    width: 100% !important;
    justify-self: center;
    padding: 15px 0px 18px 0px;

    .cardbox {
      box-shadow: none !important;
      margin: 30px auto 50px auto !important;
    }

    .product__card__col {
      display: block;
      padding: 0 !important;
      border-radius: 10px !important;
      width: 47% !important;
      flex-basis: 47%;

      @media ${device.md} {
        width: 30% !important;
        flex-basis: 31%;
      }

      @media ${device.lg} {
        width: 214px !important;
      }
    }

    &::-webkit-scrollbar {
      display: none; /* Safari and Chrome */
    }
  }
`

const Row = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  white-space: wrap;
  gap: 1em !important;
`
export default ContentSection
