/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import {
    // Button,
    // CardBox,
    // CustomAlert,
    // Loader,
    // Overlay,
    // PageTitle,
    HelmetHeader,
    EmptyRequest,
} from '@ekenta/components';

import classnames from 'classnames';
// import { useNavigate } from 'react-router-dom';
// import { Pencil } from '@ekenta/components/icons';
// import { setUser } from '@ekenta/redux/store/states/reducerSlice/authenticationSlice';
import withDefaultLayout from '@ekenta/hoc/Layout/withDefaultLayout';

const Careers = () => {
    return (
        <>
            <HelmetHeader
                title="Manage Profile "
                description="Qlason Profile Account Page"
            />
            <div className="">
                <Container className="account-user-container">
                    <Row
                        className={classnames(
                            'account-pro-row g-2',
                        )}
                    >
                        <Col lg={3} className='mx-auto my-auto mt-5'>
                            <EmptyRequest
                                title="No Jobs available"
                            />
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    );
};

export default withDefaultLayout(Careers);
