/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { Col, ListGroup } from 'react-bootstrap';
import { Checkbox, Typography } from '@ekenta/components';
import "./Features.scss";

interface FeatureListProps {
  id: string;
  data: any;
  title: string;
  name: string;
  onChange: (value: { checked: boolean; id: string }) => void;
}

export default function BrandsFilter(props: FeatureListProps) {
  return (
    <>
      <Col className="features_group_wrap">
        <div className="FlexGroup_B">
          <Typography
            as="h3"
            heading
            className="filtersHeading"
          >
            {props.title}
          </Typography>
        </div>
        <div className="features_overflow">
          <ListGroup>
            {props.data?.list?.map(
              (item: { id: number; name: string; query_string: string }) => {
                return (
                  <ListGroup.Item
                    key={`${item.id}_${props.id}`}
                    as={'li'}
                    className="d-flex 
                    justify-content-between
                  align-items-center Margin_B"
                  >
                    <Checkbox
                      id={`${item.id}_${props.id}`}
                      className="checkBox__Filter"
                      title={props.title}
                      label={item.name}
                      name={props.name}
                      onChange={(e: any) => {
                        props.onChange({
                          checked: e.target.checked,
                          id: item.id.toString()
                        })
                      }}
                    />
                  </ListGroup.Item>
                );
              },
            )}
          </ListGroup>
        </div>
      </Col>
    </>
  );
}
