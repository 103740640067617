import React, { FC } from 'react'
import { Card, Col, ListGroup, Row } from 'react-bootstrap'
import { Link } from 'react-router-dom'
//import RemoveCartButton from '../../../components/Cart/CartButtons/RemoveCartButton'
//import QuantityCartPageButton from '../../../components/Cart/CartButtons/QuantityCartPageButton'
import Tag from '@ekenta/components/Tag/Tag'
import { formatCurrency } from '../../../utils/index'
import { QuantityBtn } from '@ekenta/components'
import styled from 'styled-components'
import RemoveBtn from './RemoveBtn'
import { useAppSelector } from '@ekenta/redux/store/hook'
import { selectProductQuantityAndId } from '@ekenta/redux/store/features/cart/cartSlice'

interface CartItemPropsType {
  id: string
  productUrl: string
  productId: string
  imageUrl: string
  title: string
  stock: number
  cartItemPrice: number
  productPrice: number
  quantity: number
  condition: string
  refetch: () => void
}
const CartItem: FC<CartItemPropsType> = (props) => {
  const {
    id,
    title,
    cartItemPrice,
    productUrl,
    productId,
    imageUrl,
    quantity,
    productPrice,
    stock,
    refetch,
  } = props

  const { quantity: qty } = useAppSelector(
    selectProductQuantityAndId(productId)
  )

  return (
    <>
      <ListGroup className="list_cart_ul" as="ul">
        <ListGroup.Item as={'li'}>
          <Card style={{ width: '100%' }}>
            <Card.Body>
              <Card.Text>
                {/* <span className="saved__item-">
                  <VscHeart />
                </span> */}
                <Tag numberOf={2} className="saved__item" />
                <Row>
                  <Col className="_group_img_title">
                    <Col className="_card_img_col" xs={4} md={3}>
                      <Link to={productUrl}>
                        <Card.Img variant="top" src={imageUrl} />
                      </Link>
                    </Col>
                    <Col className="_title_desc_ ms-3">
                      <Row className="d_wrapper">
                        <Col xs={12} lg={9} className="p-0">
                          {' '}
                          <Link to={productUrl}>
                            <h5 className="card-title">{title}</h5>
                          </Link>
                        </Col>
                        <Col
                          lg={3}
                          className="cart_actual_price d-flex align-items-center justify-content-end"
                        >
                          <span>{formatCurrency(cartItemPrice || 0)}</span>
                        </Col>
                      </Row>
                      <div className="d_wrapper_rig">
                        <Col>
                          <Col className="cart_listing_price">
                            <p className="actual_price_quantity">
                              <span className="some">
                                {formatCurrency(productPrice || 0)}
                              </span>
                              <span className="ms-1">
                                x {quantity} {quantity > 1 ? 'Items' : 'Item'}
                              </span>
                            </p>
                          </Col>
                          <div className="pr_condition_sec">
                            {props.condition === 'new' ? (
                              <span className="span__new">New</span>
                            ) : (
                              <span
                                className="span__used"
                                style={{ backgroundColor: '#7C420C' }}
                              >
                                Used
                              </span>
                            )}
                          </div>
                        </Col>

                        <Col
                          xs={12}
                          lg={12}
                          className="cart_quantity_remove_col d-flex align-items-center"
                        >
                          <RemoveBtn id={id} refetch={refetch} />
                          <QuantityStyle>
                            {/* <QuantityCartPageButton
                              cartItem={props.cartItem}
                              cartItemId={
                                props.cartItemId ? props.cartItemId : ''
                              }
                            /> */}
                            <QuantityBtn
                              cartItemId={id}
                              stock={stock}
                              quantity={qty || 1}
                              className="counter"
                              disabled={false}
                              allowHover={false}
                              showDelete={false}
                              initialValue={1}
                            />
                          </QuantityStyle>
                        </Col>
                      </div>
                    </Col>
                  </Col>
                </Row>
              </Card.Text>
            </Card.Body>
          </Card>
        </ListGroup.Item>
      </ListGroup>
    </>
  )
}

const QuantityStyle = styled.div`
  margin-left: 15px;
  .counter {
    background-color: #fff8ed !important;

    .count-value {
      font-size: 16px !important;
      color: var(--eke-color-primary) !important;
    }

    button {
      svg {
        width: 27px !important;
        height: 27px !important;
        stroke: var(--eke-color-primary) !important;
        stroke-width: 4px !important;
      }

      &:hover {
        svg {
          stroke: var(--eke-color-white) !important;
        }
      }

      &:disabled {
        opacity: 0.2 !important;
        pointer-events: none;
      }
    }
  }
`

export default CartItem
