/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react';
import { Col } from 'react-bootstrap';
import * as queryString from 'query-string';
import { FiEye } from 'react-icons/fi';
import moment from 'moment';
import { useLocation, useNavigate } from 'react-router';
import { useAppSelector } from '../../../../redux/store/hook';
import { useFetchPendingSellersQuery } from '../../../../redux/store/services/adminSellerApi';
import { DashboardSellers } from '../../../../routes/constant';
import { HelmetHeader } from '@ekenta/components';
import withDashboardLayout from '../../../../hoc/Layout/withDashboardLayout';
import { VscArrowSmallLeft } from 'react-icons/vsc';
import { Pagination, DataTable, PageTitle } from '../../../../components';
import { StatusIndicator, TextTruncate } from '../../common/Resuable';

const SellersAwaitingApproval = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const query = queryString.parse(window.location.search);
  const _page = query.page;
  const { token } = useAppSelector((state) => state.auth.authentication);

  const [pageno, setPageno] = useState(1);
  const [search, setSearch] = useState('');
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState('');

  const result = useFetchPendingSellersQuery({
    pageno,
    keyword: debouncedSearchTerm,
    token
  },
    {
      refetchOnMountOrArgChange: true,
      refetchOnReconnect: true,
      keepPreviousData: false,
    }
  );

  const columns = [
    {
      Header: 'Name',
      accessor: 'name',
      sortType: 'basic',
    },

    {
      Header: 'Phone No',
      accessor: 'phone',
      sortType: 'basic',
    },
    {
      Header: 'Email',
      accessor: 'email',
      sortType: 'basic',
    },
    {
      Header: 'Store Name',
      accessor: 'store',
      sortType: 'basic',
    },
    {
      Header: "Date Joined",
      accessor: "created_at",
      disableSortBy: true,
    },
    {
      Header: 'Status',
      accessor: 'status',
      disableSortBy: true,
    },
    {
      Header: 'Option',
      accessor: 'option',
      disableSortBy: true,
    },
  ];

  useEffect(() => {
    const delay = setTimeout(() => {
      setDebouncedSearchTerm(search);
    }, 1000);

    return () => {
      clearTimeout(delay);
    };
  }, [search]);


  const mapData = (data: any) => {
    return data.map((item: any) => ({
      ...item,
      name: (
        <TextTruncate>{item.user.first_name + " " + item.user.last_name}</TextTruncate>
      ),
      store: (
        <TextTruncate>{item.business_name}</TextTruncate>
      ),
      phone: item.business_phone_number,
      email: item.user.email,
      status: (
        item.seller_request_status ? (
          <StatusIndicator status={item.seller_request_status} />
        ) : ""
      ),
      created_at: moment(item.user?.created_at).format('MMMM Do YYYY'),
      option: (
        <FiEye
          onClick={() =>
            navigate(`/admin/seller-review/${item.id}?type=awaiting`)
          }
          className="cursor-pointer text-primary"
          fontSize={15}
        />
      ),
    }));
  };

  const onSearch = (e: any) => {
    setSearch(e.target.value);
  };


  return (
    <>
      <HelmetHeader
        title="Awaiting Approval"
        description="QlasonAwaiting Approval Page"
      />

      <Col className="mx-auto _listing_product_ dash_top px-2">
        <div className="mt-0">
          <Col className="AppEvents_col mx-1 mx-lg-0">
            <PageTitle
              title="Awaiting Approval"
              subTitle={
                <span
                  className="cursor-pointer d-flex w-auto align-items-center
                    text-muted"
                  onClick={() => navigate(DashboardSellers)}
                >
                  <VscArrowSmallLeft />
                  Back to Dashboard
                </span>
              }
            />
          </Col>
          <Col className="mt-4 tab__">
            <DataTable
              theme="simple"
              data={result?.data ? mapData(result?.data?.sellers.data) : []}
              loading={result?.isLoading || result?.isFetching}
              isSelectable={true}
              columns={columns}
              showPagination={false}
              hasLink={true}
              thClassName="w-auto"
              trClassName="w_Tr"
              tdClassName={`Row__col`}
              showSearch={true}
              searchValue={search}
              onSearch={onSearch}
              renderPagnation={
                <>
                  {result?.data
                    ? result?.data.totalElements > 20 && (
                      <Pagination
                        pathname={pathname}
                        itemPerPage={result?.data?.pageable?.per_page}
                        itemsLength={result?.data?.totalElements}
                        currentPage={pageno}
                        queryNo={_page}
                        callback={(value) => setPageno(value)}
                      />
                    )
                    : null}
                </>
              }
            />
          </Col>
        </div>
      </Col>
    </>
  );
};

export default withDashboardLayout(SellersAwaitingApproval);
