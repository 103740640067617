/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react'
import classnames from 'classnames';

import "./Checkbox.scss";
import { Form } from 'react-bootstrap';
const Checkbox = ({
    id,
    fieldClassName,
    onChange,
    onBlur,
    onFocus,
    value,
    disabled,
    required,
    name,
    className,
    label,
    title,
    readOnly,
    width = "full",
    ...rest
}: any) => {


    return (
        <div className={classnames(className, "Checkbox__Container", {
            ["Container_Width"]: width === "full"
        })}>
            <Form.Check
                {...rest}
                id={id}
                className={classnames(fieldClassName, "InputField__input", "Checkbox__Input")}
                name={name}
                type="checkbox"
                disabled={disabled}
                value={value}
                checked={value}
                onBlur={onBlur}
                onFocus={onFocus}
                onChange={onChange}
                required={required}
                readOnly={readOnly}
            />
            {label ?
                (
                    <label title={title} htmlFor={id}
                        className={classnames("Checkbox__Label", {
                            ["CheckboxG_Label"]: disabled
                        })}>
                        {label} {required && <span className="requiredStyle">*</span>}
                    </label>
                ) : null}
        </div>
    )
}

export default Checkbox; 