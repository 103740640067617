/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { Fragment, useEffect, useState } from 'react'
import { Card, Col, Container, ListGroup, Row } from 'react-bootstrap'
import { VscArrowSmallRight } from 'react-icons/vsc'
import {
  Button,
  CardBox,
  RefreshLink,
  EmptyRequest,
  HelmetHeader,
  Typography,
} from '@ekenta/components'
import CartItem from './common/CartPageItem'
import { checkoutOnePagePathUrl, productBaseUrl } from '../../routes/constant'
import withDefaultLayout from '../../hoc/Layout/withDefaultLayout'
import { useGetCartItemsQuery } from '@ekenta/redux/store/features/cart/cartApiSlice'
//import BestSeller from './BestSeller'

import './Cart.scss'
import { formatCurrency, slugifyText } from '@ekenta/utils'

const mapCartReturnTotal = (cartData: any) => {
  const quantities: number[] = []
  cartData
    ? cartData.cart.map((item: any) => {
        if (item) {
          quantities.push(parseInt(item.quantity))
        }
      })
    : null
  if (quantities.length > 0) {
    const total = quantities.reduce((a: number, b: number) => {
      return a + b
    })
    return total
  }
  return null
}

const Cart = () => {
  const [noOfCart, setNoOfCart] = useState<number | null>(null)
  const { data, isLoading, isSuccess, refetch } = useGetCartItemsQuery(null)

  useEffect(() => {
    if (data || isSuccess) {
      const noCart = mapCartReturnTotal(data)
      setNoOfCart(noCart)
    }
  }, [data, isSuccess])

  let numberCart = '0 Item'

  if (noOfCart) {
    numberCart = `${noOfCart} ${noOfCart > 1 ? 'Items' : 'Item'}`
  }

  return (
    <>
      <HelmetHeader title="Your Cart " description="Qlason Cart Page" />
      <div className="d_cart_wrapper">
        <div className="cart_column_v">
          <Container>
            <Col className="cart_mainBar">
              <>
                <div className="CartTitle_Header">
                  <Typography
                    as="h2"
                    size="xlarge"
                    color="dark"
                    className=""
                    heading
                  >
                    Shopping Cart
                    <Typography
                      as="span"
                      size="large"
                      color="default"
                      className=""
                      uiText
                    >
                      {' '}
                      ({numberCart})
                    </Typography>
                  </Typography>
                </div>

                <Row>
                  <Col
                    xs={12}
                    lg={data ? (data?.cart?.length > 0 ? 8 : 12) : 12}
                  >
                    <div>
                      {isSuccess && data ? (
                        <Fragment>
                          {data.cart.length > 0 ? (
                            <CardBox className="">
                              {data.cart.map((item: any, index: number) => {
                                const _slugProduct = item
                                  ? slugifyText(
                                      item?.product?.name.toLowerCase(),
                                      item?.product?.id
                                    )
                                  : '/'
                                return (
                                  <Fragment key={item.id}>
                                    <CartItem
                                      id={item.id}
                                      imageUrl={
                                        item?.product?.images[0]?.image_url ??
                                        ''
                                      }
                                      cartItemPrice={item?.product.price}
                                      stock={item?.stock}
                                      productId={item?.product?.id}
                                      productPrice={item?.product?.price}
                                      title={item?.product?.name}
                                      quantity={item?.quantity}
                                      condition={item?.product?.condition}
                                      productUrl={`${productBaseUrl}/${_slugProduct}`}
                                      refetch={refetch}
                                    />
                                    {data.cart.length - 1 !== index ? (
                                      <div
                                        aria-hidden="true"
                                        className="Hr_horizontal"
                                      />
                                    ) : null}
                                  </Fragment>
                                )
                              })}
                            </CardBox>
                          ) : (
                            <EmptyRequest
                              title="Your cart is empty"
                              subtitle="Time to fill up cart basket"
                            />
                          )}
                        </Fragment>
                      ) : null}
                    </div>

                    {/* <Col xs={12} className="d-sm-block d-lg-none d-md-none">
                      {isLoading ? <div>Loading...</div> : null}
                      {isSuccess && !isLoading && data ? (
                        <>
                          {data.cart.length > 0 ? (
                            <>
                              <Col className="cart__total_price" xs={12} lg={4}>
                                <CardBox className="card" padding={false}>
                                  <div className="card-body">
                                    <div>
                                      <div className="summary__shipping__price">
                                        <Col>
                                          <ListGroup>
                                            <ListGroup.Item className="_top_cart_total">
                                              <span style={{ fontSize: '13px' }}>
                                                Subtotal
                                              </span>
                                              <span>
                                                <NumberFormat
                                                  value={data.total_price}
                                                  displayType="text"
                                                  decimalSeparator="."
                                                  decimalScale={2}
                                                  fixedDecimalScale
                                                  allowNegative={false}
                                                  thousandSeparator={true}
                                                  prefix="₦"
                                                  className=""
                                                />
                                              </span>
                                            </ListGroup.Item>
                                            <ListGroup.Item className="_shipping__charges">
                                              <span>
                                                <span style={{ fontSize: '13px' }}>
                                                  Delivery Charges:
                                                </span>
                                              </span>
                                              <span className="_shipping_">
                                                Select a Delivery agency of your
                                                choice at checkout to see delivery
                                                charges
                                              </span>
                                            </ListGroup.Item>
                                          </ListGroup>
                                        </Col>
                                      </div>

                                      <Col className="px-2 summary__total__price">
                                        <ListGroup>
                                          <ListGroup.Item className="_subtotal__digit">
                                            <span className="subtotal_label">
                                              Estimated total
                                            </span>
                                            <span>
                                              <NumberFormat
                                                value={
                                                  data.cart.length > 0
                                                    ? data.total_price
                                                    : 0.0
                                                }
                                                displayType="text"
                                                thousandSeparator={true}
                                                allowNegative={false}
                                                prefix="₦"
                                                className="subtotal_price"
                                              />
                                            </span>
                                          </ListGroup.Item>
                                        </ListGroup>
                                      </Col>
                                      <Col className="">
                                        <RefreshLink to={checkoutOnePagePathUrl}>
                                          <Button
                                            name=" Continue to checkout"
                                            className=""
                                            bgVariant="primary"
                                            textVariant="light"
                                            shadow={false}
                                            width="w-100"
                                            suffixIcon={<VscArrowSmallRight />}
                                          />

                                        </RefreshLink>
                                      </Col>
                                    </div>
                                  </div>
                                </CardBox>
                                <Card className="mt-3">
                                  <Card.Body></Card.Body>
                                </Card>
                              </Col>
                            </>
                          ) : null}
                        </>
                      ) : null}
                    </Col> */}

                    <div></div>
                  </Col>

                  {isLoading ? <div>Loading...</div> : null}
                  {isSuccess && !isLoading && data ? (
                    <>
                      {data?.cart?.length > 0 ? (
                        <>
                          <Col className="cart__total_price" xs={12} lg={4}>
                            <CardBox className="card" padding={false}>
                              <div className="card-body">
                                <div>
                                  <div className="summary__shipping__price">
                                    <Col>
                                      <ListGroup>
                                        <ListGroup.Item className="_top_cart_total">
                                          <span style={{ fontSize: '13px' }}>
                                            Subtotal
                                          </span>
                                          <span>
                                            {formatCurrency(
                                              data?.total_price || 0
                                            )}
                                          </span>
                                        </ListGroup.Item>
                                        <ListGroup.Item className="_shipping__charges">
                                          <span>
                                            <span style={{ fontSize: '13px' }}>
                                              Delivery Charges:
                                            </span>
                                          </span>
                                          <span className="_shipping_">
                                            Select a Delivery agency of your
                                            choice at checkout to see delivery
                                            charges
                                          </span>
                                        </ListGroup.Item>
                                      </ListGroup>
                                    </Col>
                                  </div>

                                  <Col className="px-2 summary__total__price">
                                    <ListGroup>
                                      <ListGroup.Item className="_subtotal__digit">
                                        <span className="subtotal_label">
                                          Estimated total
                                        </span>
                                        <span>
                                          {formatCurrency(
                                            data?.total_price || 0
                                          )}
                                        </span>
                                      </ListGroup.Item>
                                    </ListGroup>
                                  </Col>
                                  <Col className="w-full col-12">
                                    <RefreshLink to={checkoutOnePagePathUrl}>
                                      <Button
                                        name="Continue to checkout"
                                        className=""
                                        bgVariant="primary"
                                        textVariant="light"
                                        shadow={false}
                                        width="w-100"
                                        suffixIcon={<VscArrowSmallRight />}
                                      />
                                    </RefreshLink>
                                  </Col>
                                </div>
                              </div>
                            </CardBox>
                            <Card className="mt-3">
                              <Card.Body></Card.Body>
                            </Card>
                          </Col>
                        </>
                      ) : null}
                    </>
                  ) : null}
                </Row>
                {/* <BestSeller /> */}
              </>
            </Col>
          </Container>
        </div>
      </div>
    </>
  )
}
export default withDefaultLayout(Cart)
