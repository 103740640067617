/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { Fragment, MutableRefObject, useEffect } from 'react';
import { Form, FormikProvider, useFormik } from 'formik';
import { toast } from "react-toastify";
import { AddProductFormStyle } from '../../style';
import ProductCollection from '../Steps/Collection';
import addProductListingFormValidation from '@ekenta/validations/schemas/add-product-listing';
import ProductDetails from '../Steps/Details';
import ProductPrices from '../Steps/ProductPrices';
import ProductVariant from '../Steps/ProductVariant';
import OtherInfo from '../Steps/OtherInfo';
import { useFetchSingleProductByIdQuery, useUpdateProductMutation } from '@ekenta/redux/store/services/productsApi';
import { useCreateProductMutation } from '@ekenta/redux/store/services/collectionApi';
import { useAppSelector } from '@ekenta/redux/store/hook';
import { Loader, Overlay } from '@ekenta/components';
import { useNavigate } from 'react-router-dom';
import { AdminProductsPathUrl, DashboardProductsPathUrl } from '@ekenta/routes/constant';


interface AddProductType {
    page: number;
    type: string | null;
    isAdmin: boolean | null;
    ref: MutableRefObject<null>;
    nextPage: () => void;
}




const AddProductForm = ({ page, nextPage, type, ref, isAdmin }: AddProductType) => {
    const urlParams = new URLSearchParams(window.location.search);
    const myParam = urlParams.get("condition");
    const productId = urlParams.get("_id");
    const { token } = useAppSelector((state) => state.auth.authentication);
    const getProductResult = useFetchSingleProductByIdQuery(productId, { skip: !productId });
    const [createProduct, { isLoading }] = useCreateProductMutation();
    const [updateProduct, result] = useUpdateProductMutation();
    const navigate = useNavigate();



    const formik = useFormik({
        initialValues: {
            skus: [],
            collectionId: "",
            collection: "",
            subCollectionCategory: "",
            subcategorycollectionId: "",
            subCollectionCategoryId: "",
            subcollectionId: "",
            productname: "",
            productshortDescription: "",
            productWeight: "1",
            brandId: "",
            defaultprice: 0,
            discountedPrice: 0,
            maximumprice: 0,
            discount: 0,
            quantity: 1,
            vatcharges: 7.5,
            totalprice: 0,
            bulkprice: 0,
            acceptwarehouse: "",
            condition: `${myParam}`,
            waranty: false,
            madeInNig: false,
            returnpolicy: "7",
            featureValues: [
                {
                    feature_id: "",
                    unit_id: "",
                    feature_value: "",
                },
            ],
            variants: [
                {
                    price: 0,
                    features: [
                        {
                            feature_id: "",
                            feature_value: ""
                        }
                    ]
                },
            ],
            productimages: [
                { image_url: null },
                { image_url: null },
            ],
            productthumbnail: [],
            productvideo: null,
        },
        validationSchema: addProductListingFormValidation(),
        onSubmit: (values) => {
            const imageUrls = values.productimages.map((item) => item.image_url);

            const data = {
                name: values.productname,
                short_description: values.productshortDescription,
                collection_id: values.collectionId,
                sub_collection_category_id: values.subcategorycollectionId,
                sub_collection_id: values.subcollectionId,
                description: values.productshortDescription,
                price: parseInt(values.defaultprice.toString()),
                min_price: parseInt(values.discountedPrice.toString()),
                max_price: parseInt(values.maximumprice.toString()),
                discount: values.discount,
                discount_percentage: 0.0,
                return_policy: values.returnpolicy,
                condition: values.condition,
                weight: values.productWeight,
                stock: values.quantity,
                brand_id: values.brandId,
                features: values.featureValues,
                variants: values.variants,
                images: imageUrls,
                video: values.productvideo,
                stock_in_warehouse: false,
                has_warranty: values.waranty,
                //madeInNigeria: values.madeInNig
            };

            if (productId && type === "edit") {
                const obj = { ...data, skus: values.skus };
                onUpdateProduct(productId, obj, token)
            } else {
                onCreateProduct(data, token);
            }
        },
    });

    useEffect(() => {
        if (type === "edit") {
            renderDataToState();
        }
    }, [type, getProductResult?.isSuccess]);


    const calculateTotal = (price: number, quantity: number) => {
        return price * quantity;
    }

    const onCreateProduct = async (data: any, token: string) => {
        try {
            const res = await createProduct({ data, token });
            if (res?.data?.status === true) {
                toast.success(`${res?.data?.message}`, { position: "bottom-center" });
                navigate(isAdmin
                    ? AdminProductsPathUrl
                    : DashboardProductsPathUrl
                );
            }
        } catch (error) {
            toast.error(`Something went wrong`);
        }
    }

    const onUpdateProduct = async (productId: string, obj: any, token: string) => {
        try {
            const res = await updateProduct({ productId, obj, token });
            if (res?.data?.status === true) {
                toast.success(`${res?.data?.message}`, { position: "bottom-center" });
                navigate(isAdmin
                    ? AdminProductsPathUrl
                    : DashboardProductsPathUrl);
            }
        } catch (error) {
            console.log(error);
            toast.error(`Something went wrong`);
        }
    }



    const renderDataToState = () => {
        const result = getProductResult?.data;
        if (result) {
            const features: any[] = [];
            result.product.features.map((feature: any) => {
                return features.push({
                    feature_id: feature.feature_id,
                    //unit_id: feature.unit_id,
                    feature_value: feature.feature_value,
                })
            });
            formik.setValues({
                ...formik.values,
                maximumprice: result.product.max_price,
                discountedPrice: result.product.min_price,
                defaultprice: result.product.actual_price,
                quantity: result.product.stock,
                totalprice: calculateTotal(result.product.actual_price, result.product.stock),
                productname: result.product.name,
                productshortDescription: result.product.description,
                collectionId: result.product.collection_id ? result.product.collection_id : "",
                subcollectionId: result.product.sub_collection_id ? result.product.sub_collection_id : "",
                subCollectionCategoryId: result.product.sub_collection_category_id ? result.product.sub_collection_category_id : "",
                subcategorycollectionId: result.product.sub_collection_category_id ? result.product.sub_collection_category_id : "",
                productWeight: result.product.weight,
                brandId: result.product.brand_id,
                condition: result.product.condition,
                discount: result.product.discount,
                acceptwarehouse: result.product.stock_in_warehouse,
                skus: result.product.skus,
                productimages: result.product.images,
                variants: result.product.productVariation,
                productvideo: result.product.video_url,
                featureValues: features
            });

        }
    };

    return (
        <Fragment>
            <AddProductFormStyle ref={ref}>
                <FormikProvider value={formik}>
                    <Form noValidate onSubmit={formik.handleSubmit}>
                        {page === 0 && (
                            <ProductCollection
                                isLoading={isLoading}
                                nextPage={nextPage}
                            />
                        )}
                        {page === 1 && (
                            <ProductDetails
                                isLoading={isLoading}
                                nextPage={nextPage}
                            />
                        )}
                        {page === 2 && (
                            <ProductPrices
                                isLoading={isLoading}
                                nextPage={nextPage}
                            />
                        )}
                        {page === 3 && (
                            <ProductVariant
                                isLoading={isLoading}
                                nextPage={nextPage}
                            />
                        )}
                        {page === 4 && (
                            <OtherInfo
                                type={type}
                                isLoading={
                                    isLoading
                                    || result?.isLoading
                                }

                            />
                        )}
                    </Form>
                </FormikProvider>
            </AddProductFormStyle>
            {isLoading ||
                result?.isLoading ||
                getProductResult?.isLoading || getProductResult?.isFetching ? (
                <Overlay position="absolute" bgColor="light">
                    <Loader size="large" />
                </Overlay>
            ) : null}
        </Fragment>
    )
}

export default AddProductForm