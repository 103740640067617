import React from "react";
import PropTypes from "prop-types";

import styles from "./ErrorPage.module.scss";
import classnames from "classnames";

const ErrorPage = ({
  title,
  subTitle,
  children,
  onClick,
  buttonText,
  wrap,
}:{
    title:string,
    subTitle?:string,
    children?:React.ReactNode,
    onClick?: () => void,
    buttonText?:string
    wrap?:boolean
}) => {
  return (
    <div className={classnames("error-page", wrap ? styles.wrapContainer : "")}>
        <div
        className={`${styles.container}`}
        >
        <div className={styles.icon}>
            <svg
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            aria-hidden="true"
            focusable="false"
            width="80"
            height="80"
            style={{
                msTransform: "rotate(360deg)",
                WebkitTransform: "rotate(360deg)",
                transform: "rotate(360deg)",
            }}
            preserveAspectRatio="xMidYMid meet"
            viewBox="0 0 24 24"
            >
            <path
                d="M20 12a8 8 0 0 0-8-8a8 8 0 0 0-8 8a8 8 0 0 0 8 8a8 8 0 0 0 8-8m2 0a10 10 0 0 1-10 10A10 10 0 0 1 2 12A10 10 0 0 1 12 2a10 10 0 0 1 10 10m-6.5-4c.8 0 1.5.7 1.5 1.5s-.7 1.5-1.5 1.5s-1.5-.7-1.5-1.5s.7-1.5 1.5-1.5M10 9.5c0 .8-.7 1.5-1.5 1.5S7 10.3 7 9.5S7.7 8 8.5 8s1.5.7 1.5 1.5m2 4.5c1.75 0 3.29.72 4.19 1.81l-1.42 1.42C14.32 16.5 13.25 16 12 16s-2.32.5-2.77 1.23l-1.42-1.42C8.71 14.72 10.25 14 12 14z"
                fill="#626262"
            />
            <rect x={0} y={0} width={24} height={24} fill="rgba(0, 0, 0, 0)" />
            </svg>
        </div>
        <h1 className={styles.title}>{title}</h1>
        {subTitle && <div className={styles.subtitle}>{subTitle}</div>}
        <div className={styles.content}>{children}</div>
        {buttonText && (
            <div className={styles.children}>
            <button
                type="button"
                className="Button btn"
                onClick={onClick}
                data-testid="btn"
            >
                ← {buttonText}
            </button>
            </div>
        )}
        </div>
    </div>
  );
};

ErrorPage.propTypes = {
  /**
   * Heading title to show on the error message
   */
  title: PropTypes.string,
  /**
   * Subtitle of the error message
   */
  subTitle: PropTypes.string,
  /**
   * The custom text button display on error message
   */
  buttonText: PropTypes.string,

  
  wrap: PropTypes.bool,
  /**
   * The content to display in the error message
   */
  children: PropTypes.node,
  /**
   * Callback when user clicked the button
   */
  onClick: PropTypes.func,
};

export default ErrorPage;
