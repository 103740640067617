const loginPathUrl = '/auth/login'
const registerPathUrl = '/auth/register'
const emailVerifyPathUrl = '/auth/emailverify'
const forgotPassPathUrl = '/auth/forgotpassword'

// reset password link from email -> redirect to verify and new password
const resetPassPathUrl = '/auth/resetpassword'
// restore or new password  -> redirect to reset passord success message

// Account
const mobileAccountIndexPath = '/user/qlason'
const accountBaseUrl = '/user/account/'
const accountProfilePathUrl = '/user/account/profile'
const accountUpdateProfilePathUrl = '/user/account/profile/edit/'
const accountUpdateAddressPathUrl = '/user/account/profile/address/edit/'
const accountChangePasswordPathUrl = '/user/account/profile/changepassword/edit'

const voucherPathUrl = '/user/account/voucher'
// Chat Message
const ChatPathUrl = '/user/account/chats'
//
const referralsPathUrl = '/user/account/referrals'
const returnRequestPathUrl = '/user/account/return-request'
const newreturnRequestPathUrl = '/user/account/return-request/new'
const savedItemPathUrl = '/user/account/saved-item'
const ordersPathUrl = '/user/account/orders'
const addressesPathUrl = '/user/account/addresses'
const inboxPathUrl = '/user/account/inbox'
const WalletSystemPathUrl = '/user/account/wallet'
const pendingReviewsPathUrl = '/user/account/pending-reviews'
const ratingAndReviewsPathUrl = '/user/account/ratings-and-reviews/:id'

//reels
const reelsUrl = '/reels'
const broadcastUrl = '/broadcast'

//MarketDay
const marketDayEventUrl = '/market/:id/:slug/event'


const blogPathUrl = '/blog'
const blogDetailPathUrl = '/blog/:slug'


// Collection
const collectionPathUrl = '/all-collections'
const collectionBaseUrl = '/collection'
const subBaseUrl = '/subcollection'
const categoryBaseUrl = '/category'
const productBaseUrl = '/product'
const collectionPagePathUrl = '/collection/:collectionslug'
const subCollectionPathUrl = '/subcollection/:subslug'
const SubCollectionCartegoryPathUrl = '/category/:categoryslug'
const productDetailPathUrl = '/product/:productslug'
const detailsPathUrl = '/product/:productslug'
//Cart
const cartPathUrl = '/cart'

//Checkout
const checkoutOnePagePathUrl = '/checkoutonepage/delivery/'
const checkoutCallbackPagePathUrl = '/checkoutonepage/delivery/response'
const checkoutVerifyPagePathUrl = '/checkoutonepage/verify'

// Seller/Vendor Onboarding
const SellerOnboardingPathUrl = '/switch/vendor/onboarding'

// SellerStore
const storePathUrl = '/vendor/:id/store'

//LiveStream
const allLivePathUrl = '/live'
const ekeMarketLivePathUrl = '/eke-market'
export const singLiveBaseUrl = '/live/p/streams'
export const singleUserLiveBaseUrl = '/live/p/streams/spm'
const singleLivePathUrl = '/live/p/streams/spm-:sellerId'
const DashboardLivePathUrl = '/live/p/streams/view-:sellerId'

//Save The Planet
const savePlanetPathUrl = '/save-the-planet'
const iKitchenComingSoonPathUrl = '/ikitchen-coming-soon'
const rentItPathUrl = '/rent-it-coming-soon'

// Dashboard ()
const DashboardPathUrl = '/vendor/dashboard'
const DashboardAddProductPathUrl = '/vendor/dashboard/product'
const DashboardProductsPathUrl = '/vendor/dashboard/products'
const DashboardOrdersPathUrl = '/vendor/dashboard/orders'
const DashboardLowStockPathUrl = '/vendor/dashboard/low-stock'
const DashboardCollectionPathUrl = '/vendor/dashboard/collections'
const AdminDashboardCollectionPathUrl = '/admin/dashboard/collections'
const DashboardReturnRequestPathUrl = '/vendor/dashboard/returns-requested'
const DashboardWareHousePathUrl = '/vendor/dashboard/warehouse'
const DashboardSellerAnalytics = '/vendor/dashboard/analytics'
const DashboardSellerMerchantDetailsUrl = '/vendor/dashboard/merchant-details'
const DashboardSellerChatPathUrl = '/vendor/dashboard/seller/chats'

const AdminDashoardEkeMarketPathUrl = '/admin/dashboard/eke-market'
const AdminDashboardSubCollectionPathUrl = '/admin/dashboard/sub-collections'
const AdminDashboardSubCollectionCategoriesPathUrl =
  '/admin/dashboard/sub-collection-categories'
const EditCollectionUrl = '/admin/collection/:id'
const EditSubCollectionUrl = '/admin/sub-collection/:id'
const EditSubCollectionCategoryUrl = '/admin/sub-collection-category/:id'
const DashboardEventsPathUrl = '/admin/events'
const DashboardSingleEventsPathUrl = '/admin/events/:id'
const DashboardAddProductToEventPathUrl = '/admin/events/:id/add-product'

const DashboardOffersPathUrl = '/admin/offers'
const DashboardNotificationsPathUrl = '/admin/notifications'
//
export const DashboardSellerLiveCommerceBaseUrl =
  '/vendor/dashboard/live/s/streams'
const DashboardSellerLiveCommercePathUrl =
  '/vendor/dashboard/live/s/streams/view-:sellerId'
//
const AdminUserMangement = '/admin/user-management'

const DashboardSellers = '/admin/dashboard'
const SingleSeller = '/admin/seller/:id'
const SingleUserUrl = '/admin/user/:id'
const awaitingSellers = '/admin/sellers/awaiting-approval'
const activeSellersPathUrl = '/admin/sellers/active'
const blockedSellersPathUrl = '/admin/sellers/blocked'
const allSellersPathUrl = '/admin/sellers/all'

const AdminChatPathUrl = '/admin/dashboard/chat'

const sellerReview = '/admin/seller-review/:id'
const adminAllOrders = '/admin/all-orders'
const adminAccountingOrders = '/admin/accounting-orders'
const adminManageLogo = '/admin/manage-logo'
const adminManageBanner = '/admin/manage-banner'
const adminAddBanner = '/admin/add-banner'
const viewReturnRequest = '/vendor/dashboard/returns-requested/:id'
const DashboardOrdersPathUrlSingle = '/vendor/dashboard/order-details/:id'
const AdminOrdersPathUrlSingle = '/admin/order-details/:id'
const AdminProductsPathUrl = '/admin/all-products'
const adminAddLogo = '/admin/add-banner'
const adminReturnRequest = '/admin/all-product-return-request'
const adminPayoutRequest = '/admin/payout-request'
const adminPayoutHistory = '/admin/payout-request/history'
const adminSinglePayoutRequest = '/admin/payout-request/:id'
const adminSinglePayoutHistory = '/admin/payout-request/history/:id'
const adminAccountingSellers = '/admin/accounting-sellers'
const adminVideos = '/admin/manage-videos'
const adminAddVideos = '/admin/add-videos'
const adminGetOrderDetails = '/admin/get-order-details/:id'
const adminGetAllUsersUrl = '/admin/get-all-users'
const adminGetAllVerifiedUsersUrl = '/admin/get-all-verified-users'
const AdminSellerProfile = '/admin/seller-profile/:id'
const AdminSellerDetailsUrl = '/admin/seller-details/:id'
export const AdminSellerProfileId = '/admin/seller-profile'

const AdminVoucherPathUrl = '/admin/voucher'
const AdminAllVouchersPathUrl = '/admin/all-vouchers'
const AdminCreatedVouchersPathUrl = '/admin/created-vouchers'

const AdminDiscountPathUrl = '/admin/discount'
const AddCollectionUrl = '/admin/collection/new'
const AddSubCollectionUrl = '/admin/sub-collection/new'
const AddSubCollectionCategoryUrl = '/admin/sub-collection-category/new'

const termsAndConditionsUrl = '/terms-and-conditions'
const privacyUrl = '/privacy-policy'
const onboardingPolicyUrl = '/onboarding-policy'

const privacyEyeshopperUrl = '/eyeshopper/privacy-policy'
const deleteEyeshopperUrl = '/eyeshopper/account/delete/process'

const homePathUrl = '/'
const allArrivalPathUrl = '/all-arrival'
const allJustForYouPathUrl = '/all-just-for-you'
const allStorePathUrl = '/all-stores'
const allTopRatedPathUrl = '/all-top-rated'
const allVoucherPathUrl = '/all-vouchers'
const searchPathUrl = '/search-result'
const iKitchenCollectionUrl = '/iKitchen'
const adminReviewsReportUrl = '/admin/reviews-report'

const careersPathUrl = '/careers'

export {
  homePathUrl,
  allArrivalPathUrl,
  allJustForYouPathUrl,
  allTopRatedPathUrl,
  allVoucherPathUrl,
  allStorePathUrl,
  marketDayEventUrl,
  loginPathUrl,
  registerPathUrl,
  emailVerifyPathUrl,
  forgotPassPathUrl,
  resetPassPathUrl,
  blogPathUrl,
  voucherPathUrl,
  blogDetailPathUrl,
  mobileAccountIndexPath,
  accountBaseUrl,
  accountProfilePathUrl,
  referralsPathUrl,
  addressesPathUrl,
  inboxPathUrl,
  WalletSystemPathUrl,
  accountUpdateProfilePathUrl,
  accountUpdateAddressPathUrl,
  accountChangePasswordPathUrl,
  returnRequestPathUrl,
  newreturnRequestPathUrl,
  savedItemPathUrl,
  ordersPathUrl,
  subBaseUrl,
  categoryBaseUrl,
  collectionBaseUrl,
  collectionPathUrl,
  collectionPagePathUrl,
  subCollectionPathUrl,
  SubCollectionCartegoryPathUrl,
  productDetailPathUrl,
  detailsPathUrl,
  cartPathUrl,
  ChatPathUrl,
  checkoutOnePagePathUrl,
  checkoutCallbackPagePathUrl,
  checkoutVerifyPagePathUrl,
  productBaseUrl,
  allLivePathUrl,
  ekeMarketLivePathUrl,
  singleLivePathUrl,
  DashboardLivePathUrl,
  DashboardSellerChatPathUrl,
  savePlanetPathUrl,
  SellerOnboardingPathUrl,
  DashboardPathUrl,
  DashboardProductsPathUrl,
  DashboardOrdersPathUrl,
  DashboardLowStockPathUrl,
  DashboardCollectionPathUrl,
  DashboardAddProductPathUrl,
  DashboardWareHousePathUrl,
  DashboardReturnRequestPathUrl,
  DashboardSellerLiveCommercePathUrl,
  DashboardSellerAnalytics,
  DashboardSellers,
  storePathUrl,
  SingleSeller,
  awaitingSellers,
  activeSellersPathUrl,
  allSellersPathUrl,
  sellerReview,
  adminAllOrders,
  adminAccountingOrders,
  adminManageBanner,
  adminManageLogo,
  adminAddBanner,
  viewReturnRequest,
  DashboardOrdersPathUrlSingle,
  AdminOrdersPathUrlSingle,
  AdminProductsPathUrl,
  AdminUserMangement,
  adminAddLogo,
  adminReturnRequest,
  adminPayoutRequest,
  adminPayoutHistory,
  adminSinglePayoutRequest,
  adminSinglePayoutHistory,
  adminAccountingSellers,
  AdminVoucherPathUrl,
  AdminAllVouchersPathUrl,
  AdminCreatedVouchersPathUrl,
  searchPathUrl,
  adminVideos,
  adminAddVideos,
  AdminChatPathUrl,
  adminGetOrderDetails,
  termsAndConditionsUrl,
  iKitchenCollectionUrl,
  privacyUrl,
  privacyEyeshopperUrl,
  deleteEyeshopperUrl,
  pendingReviewsPathUrl,
  ratingAndReviewsPathUrl,
  adminGetAllUsersUrl,
  adminGetAllVerifiedUsersUrl,
  AdminDashboardCollectionPathUrl,
  AddCollectionUrl,
  AdminDashboardSubCollectionPathUrl,
  AdminDashboardSubCollectionCategoriesPathUrl,
  AdminDashoardEkeMarketPathUrl,
  AdminSellerProfile,
  AdminDiscountPathUrl,
  DashboardEventsPathUrl,
  DashboardSingleEventsPathUrl,
  DashboardAddProductToEventPathUrl,
  AddSubCollectionUrl,
  AddSubCollectionCategoryUrl,
  EditCollectionUrl,
  EditSubCollectionUrl,
  EditSubCollectionCategoryUrl,
  iKitchenComingSoonPathUrl,
  rentItPathUrl,
  blockedSellersPathUrl,
  DashboardSellerMerchantDetailsUrl,
  AdminSellerDetailsUrl,
  SingleUserUrl,
  DashboardOffersPathUrl,
  DashboardNotificationsPathUrl,
  reelsUrl,
  broadcastUrl,
  onboardingPolicyUrl,
  adminReviewsReportUrl,
  careersPathUrl,
}
