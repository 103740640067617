/* eslint-disable @typescript-eslint/no-explicit-any */
import { Button, Label, Typography } from "@ekenta/components";
import React from "react"
import SVG from "react-inlinesvg";
import outlineCheck from "@ekenta/assets/icons/outline-check.svg";
import Radio from "@ekenta/components/InputField/Radio/Radio";

import "./ListItem.scss";


const ListItem = (props: any) => {
    const { item, setEdit, onSelect } = props;
    return (
        <div className="ListItem_FrameWrapper">
            <Label className="ListItemFlex"
                htmlFor={`${item.first_name}-${item.id}`}
                aria-label={`${item.first_name} ${item.last_name}, ${item.home_address}, delivery from store available`}
            >
                <Radio
                    id={`${item.first_name}-${item.id}`}
                    name="selectAddress"
                    className="ListItemRadio"
                    //value={item.is_default}
                    variant="success"
                    onChange={() =>
                        onSelect(item)}
                />

                <div className="ListItem_Typography_Div">
                    <Typography
                        as="h5"
                        size="small"
                        color="dark"
                        className="ListItem_Heading"
                        heading>
                        {item.first_name} {item.last_name}
                    </Typography>
                    <Typography
                        as="p"
                        size="small"
                        color="dark"
                        className="Address_Para"
                        uiText>
                        {item.home_address}
                    </Typography>
                    {item.is_default ? (
                        <div className="AddressDefault">
                            <SVG src={outlineCheck} />
                            <Typography
                                as="span"
                                size="smallest"
                                color="grey"
                                uiText>
                                Preferred delivery address
                            </Typography>
                        </div>
                    ) : null}
                </div>
            </Label>
            <Button
                as="text"
                name="Edit"
                shadow={false}
                type="button"
                className="ListItem_Button"
                onClick={() => {
                    setEdit({ show: true, data: item });
                }}
            />
        </div>
    )
}

export default ListItem;