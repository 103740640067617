/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { memo } from 'react'
import SectionCard from './Sectioncard'
import styled from 'styled-components'
import { CustomAlert, ProductCard } from '@ekenta/components'
import ProductListLoader from '@ekenta/pages/Collections/component/SkeletonLoader/ProductListLoader'
import { useGetProductByCollectionIdQuery } from '@ekenta/redux/store/features/product/productApiSlice'
import { Row } from '../styles'

const CollectionProduct = (props: {
  collectionId: any
  title: any
  url: any
}) => {
  const { collectionId, title, url } = props
  const getResult = useGetProductByCollectionIdQuery({
    collectionId: collectionId,
    pageno: 1,
  })

  const _data = getResult?.isSuccess ? getResult?.data.product?.data : null
  const halfLength = _data ? Math.ceil(_data.length / 2) : null
  const _dataRow1 = _data ? _data.slice(0, halfLength) : null
  const _dataRow2 = _data ? _data.slice(halfLength) : null

  return (
    <SectionCard title={title} size="small" url={url}>
      <ContainerWrapper>
        <ContentStyle className="product_vid_commponent">
          {getResult?.isLoading && getResult?.isFetching ? (
            <Row>
              <ProductListLoader />
            </Row>
          ) : null}
          {!getResult?.isLoading && getResult?.isSuccess && getResult?.data ? (
            <>
              <Row>
                {_dataRow1
                  ? _dataRow1.map((item: any) => {
                      return (
                        <ProductCard
                          key={item.id}
                          productId={item.id}
                          isVariant={item.is_variant}
                          productVariation={item?.productVariation}
                          stockCount={item.stock}
                          aggregateRating={item.rating}
                          isShadow={true}
                          productName={item.name}
                          price={item.price}
                          discount={item.discount}
                          adminDiscount={item.admin_discount}
                          maxPrice={item.max_price}
                          imageUrl={item?.images[0]?.image_url ?? ''}
                          videoUrl={item.video_url}
                          condition={item.condition}
                        />
                      )
                    })
                  : null}
              </Row>
              <Row>
                {_dataRow2
                  ? _dataRow2.map((item: any) => {
                      return (
                        <ProductCard
                          key={item.id}
                          productId={item.id}
                          isVariant={item.is_variant}
                          productVariation={item?.productVariation}
                          stockCount={item.stock}
                          aggregateRating={item.rating}
                          isShadow={true}
                          productName={item.name}
                          price={item.price}
                          discount={item.discount}
                          adminDiscount={item.admin_discount}
                          maxPrice={item.max_price}
                          imageUrl={item?.images[0]?.image_url ?? ''}
                          videoUrl={item.video_url}
                          condition={item.condition}
                        />
                      )
                    })
                  : null}
              </Row>
            </>
          ) : (
            <>
              <Row>
                <ProductListLoader />
              </Row>
              <CustomAlert
                variant="danger"
                show={true}
                message="Unable to load Content"
              />
            </>
          )}
        </ContentStyle>
      </ContainerWrapper>
    </SectionCard>
  )
}
const ContainerWrapper = styled.div`
  width: 100%;
  max-height: auto;
  position: relative;
`

const ContentStyle = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  white-space: wrap;
  overflow: hidden;
  overflow-x: auto;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none;
  margin-top: 0 !important;
  animation: slide 20s linear infinite;

  .product__card__col {
    display: block;
    padding: 0 !important;
    border-radius: 10px !important;
    width: 214px !important;
  }
  &::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
  }
`

export default memo(CollectionProduct)
