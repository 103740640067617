/* eslint-disable @typescript-eslint/no-explicit-any */
import { Checkbox, Typography } from '@ekenta/components'
import React from 'react'
import { Col, Form, ListGroup } from 'react-bootstrap'

import "./Features.scss";

interface FeatureListProps {
    title: string;
    name: string;
    label: string;
    id: string;
    onChange: (e: any) => void;
}

const Features = (props: FeatureListProps) => {
    const { id, title, label, name, onChange } = props;
    return (
        <>
            <Col className="features_group_wrap">
                <Typography
                    as="h3"
                    heading
                    className="filtersHeading">
                    {title}
                </Typography>
                <ListGroup>
                    <ListGroup.Item
                        as="li"
                        className="d-flex justify-content-between align-items-center">
                        <Checkbox
                            id={id}
                            className="checkBox__Filter"
                            name={name}
                            label={label}
                            title={label}
                            onChange={onChange}
                        />
                    </ListGroup.Item>
                </ListGroup>
            </Col>
        </>
    )
}
export default Features;