import React, { useState } from 'react';
import {
  Badge,
  Button,
  Card,
  Col,
  Modal,
  Row,
  Tab,
  Tabs,
  Stack,
} from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import TableComponent from '../../../components/Dashboard/components/TableComponent';
import DashboardComponent from '../component/DashboardComponent';
import DashboardLayout from '../../../hoc/Layout/Dashboard-Layout';
import { useAppSelector } from '../../../redux/store/hook';
import {
  useFetchAllOrdersQuery,
  useFetchAdmniAllOrdersQuery,
} from '../../../redux/store/services/vendorProductApi';
import EmptyOrder from '../../../components/Box/EmptyOrder';
import {
  useFetchAccountingSalesAdminQuery,
  useFetchDailyTotalOrderQuery,
} from '../../../redux/store/services/adminSellerApi';
import {
  FiBarChart,
  FiBarChart2,
  FiChevronLeft,
  FiGift,
  FiToggleRight,
  FiUser,
  FiUserCheck,
} from 'react-icons/fi';
import { FaEdit, FaTrash, FaPlus } from 'react-icons/fa';
import { MDBDataTable } from 'mdbreact';
import { adminAddBanner, adminAddLogo } from '../../../routes/constant';
import { useNavigate } from 'react-router';

const Tdata = {
  columns: [
    {
      label: 'Id',
      field: 'id',
      sort: 'asc',
    },
    {
      label: 'Logo Title',
      field: 'title',
      sort: 'asc',
    },
    {
      label: 'Logo Image',
      field: 'image',
      sort: 'asc',
    },
    {
      label: 'Disabled',
      field: 'status',
      sort: 'asc',
    },
    {
      label: 'Options',
      field: 'option',
      sort: 'asc',
    },
  ],
  rows: [
    {
      id: '61',
      title: 'Tiger Nixon',
      image: 'System Architect',
      status: 'ahah',
      option: 'nanan',
    },
  ],
};
export default function ManageLogo({
  collapsed,
  toggled,
  handleToggleSidebar,
  handleCollapsedChange,
}: any) {
  const [lgShow, setLgShow] = useState(false);
  const { ek_isAuth, token } = useAppSelector(
    (state) => state.auth.authentication,
  );
  const navigate = useNavigate();

  // const allOrders = useFetchDailyTotalOrderQuery(token)
  // const ord = useFetchAccountingSalesAdminQuery(token)

  const recalibrate = (data: any) => {
    Tdata.rows = data;
    return Tdata;
  };

  const [_pageSize, setPageSize] = useState(7);

  const ddd = [
    {
      title: 'Tiger Nixon',
      image: 'System Architect',
      id: '61',
      status: 'ahah',
      option: 'nanan',
    },
    {
      title: 'Tiger Nixon',
      image: 'System Architect',
      id: '61',
      status: 'ahah',
      option: 'nanan',
    },
  ];

  const mapData = ddd.map((items) => ({
    ...items,
    image: (
      <img
        src="https://static.vecteezy.com/system/resources/previews/005/225/426/large_2x/sunset-ocean-with-dolphins-jumping-free-photo.jpg"
        height="100"
        width="100"
        alt=""
      />
    ),
    option: (
      <Col>
        <FaEdit fontSize={16} color={'#E04826'} />{' '}
        <FaTrash fontSize={16} color={'#E04826'} />
      </Col>
    ),
  }));

  const handleViewOrder = (id: string) => {
    setLgShow(true);
    alert(id);
  };

  return (
    <DashboardLayout>
      <Helmet>
        <title>Admin Dashboard - Qlason</title>
        <meta charSet="utf-8" />
        <meta name="title" content="QlasonOrders Dashboard Page" />
        <meta name="description" content="QlasonOrders Dashboard  Page" />
        <meta property="og:locale" content="en_NG" />
        <meta name="robots" content="noindex,follow" />
      </Helmet>

      <DashboardComponent
        collapsed={collapsed}
        toggled={toggled}
        handleToggleSidebar={handleToggleSidebar}
        handleCollapsedChange={handleCollapsedChange}
      >
        <Col className=" col-lg-11 mx-auto _listing_product_">
          <div className="mt-0">
            <h3 className="display_lg_header">
              {/* <FiChevronLeft /> */}
              Manage Slider Logo
            </h3>

            <Col className="mt-5">
              <Card>
                <Card.Body>
                  <Row>
                    <Col md={4}>&nbsp;</Col>
                    <Col md={6}></Col>
                    <Col md={2}>
                      <Button
                        onClick={() => navigate(adminAddLogo)}
                        className="text-right float-right text-white pull-right"
                      >
                        <FaPlus /> Add Logo
                      </Button>
                    </Col>
                  </Row>

                  <MDBDataTable
                    striped
                    bordered
                    hover
                    responsive={true}
                    searchLabel="Search"
                    exportToCSV
                    data={recalibrate(mapData)}
                  />
                  {/* <TableComponent columns={columns} data={allOrders.data.orders.content} pageSizeno={_pageSize} />  */}
                  {
                    // !allOrders.isSuccess ? 'Loading' :
                    //     allOrders.data.orders.content.length > 0 ? <MDBDataTable striped bordered hover responsive={true} searchLabel="Search" exportToCSV data={recalibrate(allOrders.data.orders.content)}/>:
                    //         <EmptyOrder orderType='active' />
                  }
                </Card.Body>
              </Card>
            </Col>
          </div>
        </Col>
      </DashboardComponent>
    </DashboardLayout>
  );
}
