import React, { Fragment, useState } from 'react'
import { useDispatch } from 'react-redux'
import { FiPlus } from 'react-icons/fi'
import { toast } from 'react-toastify'
import styled from 'styled-components'
import { Button, Loader } from '@ekenta/components'
import { addToCartAsync } from '@ekenta/redux/store/features/cart/cartThunks'
import classnames from 'classnames'
import { selectProductQuantityAndId } from '@ekenta/redux/store/features/cart/cartSlice'
import { ProductVariationType } from '@ekenta/redux/store/features/product/productDataType'
import { useAppSelector } from '@ekenta/redux/store/hook'
import QuantityBtn from './QuantityBtn'
import VariantModal from './VariantModal'
interface AddCartButtonPropsType {
  productName: string
  price: number
  productId: string
  stock: number
  is_variant: number
  className?: string
  buttonName?: string
  allowHover?: boolean
  productVariation: ProductVariationType[]
}

const AddToCart: React.FC<AddCartButtonPropsType> = (props) => {
  const {
    productName,
    productId,
    price,
    stock,
    is_variant,
    productVariation,
    className,
    allowHover = true,
    buttonName,
  } = props
  const dispatch = useDispatch()
  const { quantity, cartItemId } = useAppSelector(
    selectProductQuantityAndId(props.productId?.toString())
  )
  const [loading, setLoading] = useState(false)
  const [isOpen, setIsOpen] = useState(false)

  const handleAddToCart = async () => {
    if (productVariation?.length > 0) {
      setIsOpen(true)
      return
    }

    if (stock === quantity) {
      toast.info('Item is out of stock')
      return
    }

    setLoading(true)
    try {
      const data = {
        id: productId,
        name: productName,
        price,
        stock,
        is_variant,
      }
      await dispatch(addToCartAsync(data))
    } catch (error) {
      console.error('Error adding to cart:', error)
    } finally {
      setLoading(false)
    }
  }

  const onClose = () => {
    setIsOpen(false)
  }

  return (
    <Fragment>
      {allowHover && quantity > 0 ? (
        <QuantityBtn
          stock={stock}
          quantity={quantity}
          cartItemId={cartItemId}
        />
      ) : (
        <ButtonStyle>
          <Button
            name="addCart__name"
            type="button"
            bgVariant="primary"
            disabled={loading}
            defaultClass={false}
            className={classnames(className)}
            onClick={handleAddToCart}
          >
            {loading ? (
              <Loader color="white" size="small" />
            ) : (
              <Fragment>
                <FiPlus fontSize={15} />
                <span className="mr2">{buttonName ? buttonName : 'Add'}</span>
              </Fragment>
            )}
          </Button>
        </ButtonStyle>
      )}

      <VariantModal
        {...{
          isOpen,
          onClose,
          productName,
          stock,
          productVariation,
          is_variant,
        }}
      />
    </Fragment>
  )
}
const ButtonStyle = styled.div`
  button {
    width: 85px !important;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 36.128px;
    height: 36.367px !important;
    padding: 20.677px !important;
    color: var(--eke-body-text-light-color) !important;
    font-size: 14px !important;
    font-family: var(--eke-body-font-family) !important;
    font-weight: var(--eke-font-weight-medium) !important;
    border: none !important;
    box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease-out;
    gap: 4px !important;

    span {
      margin-top: 0px;
    }
  }
`

export default AddToCart
