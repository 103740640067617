import React from 'react'

const SvgPlus = ({
    width = "1rem",
    height = "1rem",
    className
}: {
    className?: string,
    width?: string;
    height?: string
}) => (
    <svg xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        strokeWidth="1.5"
        stroke="currentColor"
        width={width}
        height={height}
        className={className}
        role="img"
    >
        <path strokeLinecap="round" strokeLinejoin="round"
            d="M12 4.5v15m7.5-7.5h-15" />
    </svg>
)

export default SvgPlus;