/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useCallback, useState } from "react"
import { Col } from "react-bootstrap";
import { toast } from "react-toastify";
import {
  FiBarChart2, FiClipboard, FiGift,
  FiLayers,
  FiRefreshCcw,
  FiSlash,
  FiTruck,
  FiUsers
} from "react-icons/fi";
import { HelmetHeader, Loader, Overlay, PageTitle } from "@ekenta/components";
import { withDashboardLayout } from "@ekenta/hoc";
import { useAppSelector } from "@ekenta/redux/store/hook";
// import { abbrevateNumber } from "../../../../utils";
import Card from "./components/Card";
import { Filters } from "./components/Filters";

import "./UserManagment.scss";
import { VscArrowSmallLeft } from "react-icons/vsc";
import { useNavigate } from "react-router-dom";
import { DashboardSellers } from "@ekenta/routes/constant";
import { BASE_URL } from "@ekenta/constants";
import axios from "axios";

const UserManagement = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [getResult, setGetResult] = useState<any | null>(null);

  const { token } = useAppSelector(state => state.auth.authentication);


  // const getResult = useFetchAdminFilterQuery({ date, token }, { skip: !date });


  const listOfData = [
    { name: "Total Users", value: `${getResult ? getResult.allUsers : 0}`, icon: <FiUsers /> },
    {
      name: "Total Sellers",
      value: `${getResult ? getResult.totalActiveSellers : 0}`,
      icon: <FiUsers />
    },
    {
      name: "Total Brands",
      value: `${getResult ? getResult?.totalBrands : 0}`,
      icon: <FiGift />
    },
    {
      name: "Total Completed Orders",
      value: `${getResult ? getResult?.totalCompletedOrders : 0}`,
      icon: <FiBarChart2 />
    },
    {
      name: "Total Pending Orders",
      value: `${getResult ? getResult?.totalPendingOrders : 0}`,
      icon: <FiClipboard />
    },
    {
      name: "Total Delivered Orders",
      value: `${getResult ? getResult?.totalDeliveredOrders : 0}`,
      icon: <FiTruck />
    },
    {
      name: "Total Cancelled Orders",
      value: `${getResult ? getResult?.totalCancelledOrders : 0}`,
      icon: <FiSlash />
    },
    {
      name: "Total Collections",
      value: `${getResult ? getResult?.totalCollection : 0}`,
      icon: <FiLayers />
    },
    {
      name: "Total Products",
      value: `${getResult ? getResult?.totalProducts : 0}`,
      icon: <FiGift />
    },
    {
      name: "Total Return Request",
      value: `${getResult ? getResult?.totalProductReturnRequest : 0}`,
      icon: <FiRefreshCcw />
    },
  ];

  const toggleFunction = useCallback((date: string) => {
    const config = {
      method: 'get',
      url: `${BASE_URL}/api/admin/dashboard/${date}`,
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
      data: ""
    };
    setIsLoading(true);
    axios(config)
      .then(function (response: any) {
        setIsLoading(false);
        setGetResult(response.data.data);
        toast.success(`${response.data.message}`, {
          toastId: "-managemet-toggle-success-id-toast",
        });
      })
      .catch(function () {
        setIsLoading(false);
        toast.error(`Something went wrong`, {
          toastId: "-toggle-error-400-id-toast-error",
        });
      });
  }, []);
  return (
    <>
      <HelmetHeader
        title="User Management Collection"
        description="QlasonAdd Collection Page"
      />

      <Col className=" col-lg-12 Usermanagemenet__Div" >
        <div className="mt-1">
          <Col className={"d-flex align-items-center justify-content-between"}>
            <PageTitle
              title="User Management"
              subTitle={
                <span
                  className="cursor-pointer d-flex align-items-center 
                    text-muted"
                  onClick={() => navigate(DashboardSellers)}>
                  <VscArrowSmallLeft />
                  Back to Dashboard
                </span>
              }
            />
            <Filters
              onClick={toggleFunction}
            />
          </Col>
          <Col className="w-100 mt-4">
            <div className="UserManagement__rowDirection">
              {listOfData.map((item: any, index: number) => (
                <Card key={"id" + index}
                  name={item.name}
                  value={item.value}
                  icon={item.icon}
                />
              ))}
            </div>
            {isLoading ? (
              <Overlay position="absolute" bgColor="light">
                <Loader
                  isCenter
                  size="large"
                  color="black"
                />
              </Overlay>
            ) : null}

          </Col>
        </div>
      </Col>

    </>
  )
}



export default withDashboardLayout(UserManagement);