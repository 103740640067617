/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react'
import { Col, Form } from 'react-bootstrap'
import { FiChevronDown, FiDelete, FiPlus } from 'react-icons/fi'
import styled from 'styled-components'
import {
  useCreateNotificationMutation,
  useDeleteNotificationMutation,
  useFetchAllNotificationsQuery,
} from '@ekenta/redux/store/services/adminSellerApi'
import CustomModal from '@ekenta/components/Modal/CustomModal'
import { withDashboardLayout } from '@ekenta/hoc'
import {
  Pagination,
  Button,
  Dropdown,
  DataTable,
  PageTitle,
  HelmetHeader,
} from '@ekenta/components'
import { useAppSelector } from '@ekenta/redux/store/hook'
import {
  AdminDashoardEkeMarketPathUrl,
  DashboardSellers,
} from '@ekenta/routes/constant'
import { useLocation, useNavigate } from 'react-router'
import { VscArrowSmallLeft } from 'react-icons/vsc'

import './Events.scss'

const Notification = () => {
  const { pathname } = useLocation()
  const navigate = useNavigate()
  const { token } = useAppSelector((state) => state.auth.authentication)

  const [modal, setModal] = useState(false)
  const [event, setEvent] = useState({
    title: '',
    message: '',
    user_id: '',
    status: '',
  })
  const [iserror, setisError] = useState('')

  const [createOffer, result] = useCreateNotificationMutation()
  const allEvent = useFetchAllNotificationsQuery(token)

  const [
    deleteNotifcationCall,
    { data: deleteNotifcation_data, isSuccess: deleteNotifcation_isSuccess },
  ] = useDeleteNotificationMutation()

  useEffect(() => {
    if (result?.isSuccess) {
      setModal(false)
      setEvent({
        title: '',
        message: '',
        user_id: '',
        status: '',
      })
    }
  }, [result?.isSuccess])

  const handleModal = () => setModal(true)

  const handleSubmit = () => {
    setisError('')

    if (event.title && event.message && event.user_id && event.status) {
      const data = {
        title: event.title,
        message: event.message,
        user_id: event.user_id,
        status: event.status,
      }

      createOffer({
        data,
        token,
      })
    } else {
      setisError('*Field is required')
    }
  }

  const navigateTo = (url: string) => {
    if (url) {
      navigate(url)
    }
  }

  const urls = [
    {
      title: 'ekemarket',
      url: AdminDashoardEkeMarketPathUrl,
    },
  ]
  const columns = React.useMemo(
    () => [
      {
        Header: 'Title',
        accessor: 'title',
        sortType: 'basic',
      },
      {
        Header: 'Message',
        accessor: 'message',
        sortType: 'basic',
      },
      // {
      //     Header: "Status",
      //     accessor: "status",
      //     disableSortBy: true,
      // },
      {
        Header: 'Type',
        accessor: 'user_id',
        disableSortBy: true,
      },
      {
        Header: 'Action',
        accessor: 'id',
        disableSortBy: true,
        Cell: ({ value }: any) => {
          return (
            <Dropdown
              name="more"
              suffixIcon={<FiChevronDown />}
              placement="bottom"
              tooltipContent=""
              menus={[
                {
                  name: 'Delete Notification',
                  icon: <FiDelete />,
                  active: false,
                  key: 'live-code-key',
                  handler: () =>
                    deleteNotifcationCall({ notificationId: value, token }),
                },
              ]}
            />
          )
        },
      },
    ],
    []
  )

  return (
    <div className="EventDiv_Wrapper">
      <HelmetHeader
        title="Notification "
        description="Qlason Notification Page"
      />
      <Col className="AppEvents_col">
        <PageTitle
          title="Notification"
          subTitle={
            <span
              className="cursor-pointer d-flex align-items-center
              text-muted"
              onClick={() => navigate(DashboardSellers)}
            >
              <VscArrowSmallLeft />
              Back to Dashboard
            </span>
          }
        >
          <Button
            bgColor="white"
            prefixIcon={<FiPlus />}
            name="Add Notification"
            onClick={handleModal}
          />
        </PageTitle>
      </Col>
      <Col>
        <DataTable
          theme="simple"
          data={allEvent?.data?.notifications ?? []}
          loading={allEvent?.isLoading}
          isSelectable={true}
          columns={columns}
          showPagination={false}
          thClassName="w"
          trClassName="w"
          tdClassName={`Row__col`}
          renderPagnation={() => (
            <>
              {allEvent?.data.notifications &&
              allEvent?.data.notifications.length > 0 ? (
                <div className="mt-3">
                  <Pagination
                    pathname={pathname}
                    itemPerPage={1}
                    itemsLength={10}
                    currentPage={1}
                  />
                </div>
              ) : null}
            </>
          )}
        />
      </Col>
      <CustomModal
        title="Add Notification"
        show={modal}
        centered={false}
        showFooter={true}
        onHide={() => {
          setModal(false)
          setEvent({ title: '', message: '', status: '', user_id: '' })
        }}
        isLoading={result?.isLoading}
        onSubmit={handleSubmit}
        render={
          <RenderComponent event={event} error={iserror} setEvent={setEvent} />
        }
      />
    </div>
  )
}

const RenderComponent = (props: {
  event: {
    title: string
    message: string
    status: string
    user_id: string
  }
  error: string
  setEvent: React.Dispatch<
    React.SetStateAction<{
      title: string
      message: string
      status: string
      user_id: string
    }>
  >
}) => {
  return (
    <DIV>
      <div className="mb-2">
        <label>Title</label>
        <Input
          type="text"
          height={'400px'}
          name="addevent"
          placeholder="Title"
          value={props.event.title}
          onChange={(e) =>
            props.setEvent({ ...props.event, title: e.target.value })
          }
        />
        {props.error && !props.event.title ? (
          <small>{props.error}</small>
        ) : null}
      </div>
      <div className="mb-2">
        <label>message</label>
        <Input
          type="text"
          height={'400px'}
          name="addevent"
          placeholder="message"
          value={props.event.message}
          onChange={(e) =>
            props.setEvent({ ...props.event, message: e.target.value })
          }
        />
        {props.error && !props.event.message ? (
          <small>{props.error}</small>
        ) : null}
      </div>
      <div className="mb-2">
        <label>Type</label>
        <Form.Select
          onChange={(e) =>
            props.setEvent({ ...props.event, user_id: e.target.value })
          }
        >
          <option>Select one</option>
          <option value="ALL">ALL</option>
          <option value="BUYER">BUYER</option>
          <option value="SELLER">SELLER</option>
        </Form.Select>
        {props.error && !props.event.user_id ? (
          <small>{props.error}</small>
        ) : null}
      </div>
      {/* <div className="mb-2">
                <label>Status</label>
                <Form.Select
                    onChange={(e) =>
                        props.setEvent({ ...props.event, status: e.target.value })
                    }
                >
                    <option>Select one</option>
                    <option value="1">Active</option>
                    <option value="0">In-active</option>
                </Form.Select>
                {props.error && !props.event.status ? <small>{props.error}</small> : null}
            </div> */}
    </DIV>
  )
}

const DIV = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  label {
    font-size: 13px;
    margin-bottom: 5px;
    font-family: 'Noto Sans', sans-serif;
    font-weight: 400;
  }

  small {
    font-size: 12px;
    color: red;
  }
`

const Input = styled.input`
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  appearance: none;
  -webkit-appearance: none;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  outline: none;
`

export default withDashboardLayout(Notification)
