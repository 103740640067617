import React from 'react'

const SvgBag = ({
	width = "1rem",
	height = "1rem",
	className
}: {
	className?: string,
	width?: string;
	height?: string
}) => (
	<svg
		width={width}
		height={height}
		className={className}
		viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
		<g id="Iconly/Light/Bag">
			<g id="Bag">
				<path id="Path_33955" fillRule="evenodd" clipRule="evenodd" d="M13.7615 18.6065H6.80495C4.24965 18.6065 2.28931 17.6836 2.84614 13.9688L3.4945 8.93451C3.83775 7.08096 5.02005 6.37158 6.05743 6.37158H14.5395C15.5921 6.37158 16.7058 7.13436 17.1024 8.93451L17.7508 13.9688C18.2237 17.264 16.3168 18.6065 13.7615 18.6065Z" stroke="#F8880A" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
				<path id="Path_33956" d="M13.876 6.18847C13.876 4.20008 12.2641 2.58817 10.2757 2.58817V2.58817C9.31822 2.58411 8.39854 2.96163 7.72005 3.63726C7.04156 4.31289 6.66015 5.23096 6.66016 6.18847H6.66016" stroke="#F8880A" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
				<path id="Line_192" d="M12.7471 9.94145H12.709" stroke="#F8880A" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
				<path id="Line_193" d="M7.88776 9.94145H7.84962" stroke="#F8880A" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
			</g>
		</g>
	</svg>

)

export default SvgBag;