/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react'
import * as Yup from 'yup'
import { toast } from 'react-toastify'
import { useFormik } from 'formik'
import { useNavigate } from 'react-router'
import styled from 'styled-components'
import { VscArrowSmallLeft } from 'react-icons/vsc'
import {
  FiChevronDown,
  FiEdit3,
  FiEye,
  FiLink2,
  FiTrash2,
} from 'react-icons/fi'
import moment from 'moment'
import {
  useCreateEventMutation,
  useFetchAllEventQuery,
  useUpdateEventMutation,
} from '@ekenta/redux/store/services/adminSellerApi'
import CustomModal from '@ekenta/components/Modal/CustomModal'
import { withDashboardLayout } from '@ekenta/hoc'
import {
  Button,
  DataTable,
  PageTitle,
  HelmetHeader,
  Field,
  Label,
} from '@ekenta/components'
import { useAppSelector } from '@ekenta/redux/store/hook'
import {
  AdminDashoardEkeMarketPathUrl,
  DashboardEventsPathUrl,
  DashboardSellers,
} from '@ekenta/routes/constant'

import './Events.scss'
import { ButtonGroupStyle, DataTableWrapper } from '../../common/common'
import { AppBoard } from '../../common/styles'
import Dropdown from '@ekenta/components/Dropdown/Dropdown3'
import CustomSelect from '@ekenta/components/Select/CustomSelect'
import { useFetchAllCollectionsQuery } from '@ekenta/redux/store/services/collectionApi'
import {
  PageSubTitle,
  StatusIndicator,
  TextTruncate,
} from '../../common/Resuable'
import { getAppBaseUrl, showToastify, slugifyText } from '@ekenta/utils'
import { Switch } from '@mui/material'
import { Link } from 'react-router-dom'
import { BASE_URL } from '@ekenta/constants'
import axios from 'axios'

const mapDataAddUrl = (data: any, urls: { name: string; url: string }[]) => {
  const newData = []
  if (data && data.length > 0) {
    for (let i = 0; i < data.length; i++) {
      for (let j = 0; j < urls.length; j++) {
        if (data[i].name.toLowerCase() == urls[j].name.toLowerCase()) {
          newData.push({
            ...data[i],
            url: `${urls[j].url}?_id=${data[i].id}`,
          })
        } else {
          newData.push({
            ...data[i],
            url: '',
          })
        }
      }
    }
  }
  return newData
}
type OptionType = {
  label: string
  value: string
}
interface FormValue {
  event_id: string
  name: string
  category: {
    label: string
    value: string
  }[]
  eventDay: {
    label: string
    value: string
  }
  type: {
    label: string
    value: string
  }
  image_url: string
  discount: number
  is_active: boolean
}

const Events = () => {
  const navigate = useNavigate()
  const { token } = useAppSelector((state) => state.auth.authentication)
  const [modal, setModal] = useState(false)
  const [loading, setLoading] = useState(false)
  const [isEdit, setIsEdit] = useState(false)
  const [createEvent, result] = useCreateEventMutation()
  const [updateEvent, udateResult] = useUpdateEventMutation()
  const allEvent = useFetchAllEventQuery(token)
  const category = useFetchAllCollectionsQuery()
  const onlyHost = true
  const baseUrl = getAppBaseUrl(onlyHost)

  const validationSchema = Yup.object().shape({
    name: Yup.string().required('Name is required'),
    category: Yup.array()
      .of(
        Yup.object({
          label: Yup.string(),
          value: Yup.string().required('Category value is required'),
        }).required('Category object is required')
      )
      .min(1, 'At least one category is required'),
    eventDay: Yup.object({
      label: Yup.string(),
      value: Yup.string().required('Event Day is required'),
    }).required('Event Day is required'),
    type: Yup.object({
      label: Yup.string(),
      value: Yup.string().required('Event Type is required'),
    }).required('Event Type is required'),
    discount: Yup.number()
      .min(0, 'Discount minimum is 0')
      .max(100, 'Discount maximum is 100')
      .required('Discount is required'),
    image_url: Yup.string().required('Event Banner Url is required'),
    is_active: Yup.boolean(),
  })

  const formik = useFormik<FormValue>({
    initialValues: {
      event_id: '',
      name: '',
      category: [],
      eventDay: {
        label: '',
        value: '',
      },
      type: {
        label: '',
        value: '',
      },
      image_url: '',
      discount: 0,
      is_active: false,
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      const data = {
        name: values.name,
        event_day: values.eventDay?.value,
        collection_id: values.category?.map((item) => item.value),
        discount: values.discount,
        image_url: values.image_url,
        type: values.type.value,
        is_active: values.is_active,
      }
      if (isEdit) {
        updateEvent({
          data,
          id: values.event_id,
          token,
        })
      } else {
        createEvent({
          data,
          token,
        })
      }
    },
  })

  useEffect(() => {
    responseApiAction(result, 'create')
    responseApiAction(udateResult, 'update')
  }, [result, udateResult])

  const responseApiAction = (result: any, actionType: string) => {
    const isSuccess = result?.isSuccess
    const isError = result?.isError

    if (actionType === 'create' && isSuccess) {
      allEvent?.refetch()
      handleOnClick(false)
      showToastify(`Event ${actionType} successfully`, `${actionType}`)
    } else if (actionType === 'update' && isSuccess) {
      allEvent?.refetch()
      handleOnClick(false)
      showToastify(`Event ${actionType} successfully`, `${actionType}`)
      setIsEdit(false)
    } else if (isError) {
      showToastify(`Event failed to ${actionType}`, `${actionType}`, true)
    }
  }

  const slugify = (text: string) => {
    return slugifyText(text.toLowerCase())
  }
  const urls = [
    {
      name: 'ekemarket',
      url: AdminDashoardEkeMarketPathUrl,
    },
  ]
  const columns = [
    {
      Header: 'Date',
      accessor: 'date',
      sortType: 'basic',
    },
    {
      Header: 'Event Name',
      accessor: 'name',
      sortType: 'basic',
    },
    {
      Header: 'Discount Value',
      accessor: 'discount',
      sortType: 'basic',
    },
    {
      Header: 'Event Url',
      accessor: 'url',
      disableSortBy: true,
    },
    {
      Header: 'Event Day',
      accessor: 'event_day',
      disableSortBy: true,
    },
    {
      Header: 'Status',
      accessor: 'status',
      disableSortBy: true,
    },
    {
      Header: 'Action',
      accessor: 'action',
      disableSortBy: true,
    },
  ]

  const mapData = (data: any) => {
    return data.length > 0
      ? data.map((item: any) => ({
          ...item,
          date: moment(item.created_at).format('DD-MM-YYYY'),
          name: <TextTruncate>{item.name}</TextTruncate>,
          discount: item?.discount,
          event_day: <EventDayText>{item?.event_day}</EventDayText>,
          status: (
            <StatusIndicator
              text={item.is_active ? 'Active' : 'Not active'}
              status={item.is_active ? 'COMPLETED' : 'BLOCKED'}
            />
          ),
          url: (
            <Link
              className="ViewExternal__link"
              target="_blank"
              rel="noreferrer"
              to={`/market/${item.id}/${slugify(item.name)}/event`}
            >
              <FiLink2 />
              <TruncateUrl>{`${baseUrl}/market/${item.id}/${slugify(
                item.name
              )}/event`}</TruncateUrl>
            </Link>
          ),
          action: (
            <Dropdown
              name="more"
              suffixIcon={<FiChevronDown />}
              placement="bottom"
              tooltipContent=""
              menus={[
                {
                  name: 'Edit Event',
                  icon: <FiEdit3 color="#4ADE80" />,
                  active: false,
                  key: 'edit-event',
                  handler: () => handleEdit(item, true),
                },
                {
                  name: 'View Products',
                  icon: <FiEye color="#335EF7" />,
                  active: false,
                  key: 'view-product',
                  handler: () =>
                    navigate(
                      `${DashboardEventsPathUrl}/${item.id}?_ev=true&colId=${item.collection_id}&discount=${item.discount}`
                    ),
                },
                {
                  name: 'Delete Event',
                  icon: <FiTrash2 color="#FF4D67" />,
                  active: false,
                  key: 'delete-event',
                  handler: () => handleDelete(item.id),
                },
              ]}
            />
          ),
        }))
      : []
  }

  const handleDelete = (id: string) => {
    const config = {
      method: 'DELETE',
      url: `${BASE_URL}/api/admin/delete-event/${id}`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    }
    setLoading(true)
    axios(config)
      .then(function () {
        setLoading(false)
        toast.success(`Event deleted successfully`)
        allEvent?.refetch()
      })
      .catch(function () {
        setLoading(false)
        toast.error(`Something went wrong`)
        allEvent?.refetch()
      })
  }

  const categoryOption: OptionType[] = category?.data?.collections?.map(
    (item: any) => ({
      value: item.id.toString(),
      label: item.name,
    })
  )

  const handleOnClick = (open: boolean) => {
    if (open === false) {
      formik.resetForm()
    }
    setIsEdit(false)
    setModal(open)
  }

  const handleEdit = (item: any, open: boolean) => {
    const categoryIds = categoryOption?.filter(
      (option) => item?.collection_id?.includes(option.value) ?? []
    )
    setIsEdit(true)
    formik.setValues({
      ...formik.values,
      event_id: item.id,
      name: item.name,
      discount: item.discount,
      image_url: item.image_url,
      category: categoryIds,
      eventDay: defaultValue(options, item.event_day, 'event'),
      type: defaultValue(optionType, item.type, 'event'),
      is_active: item.is_active,
    })
    setModal(open)
  }

  const defaultValue = (options: any, val: any, type: string) => {
    if (type === 'event') {
      return options.find((option: any) => option.value === val) || null
    } else {
      const foundOption = options.find((option: any) => option.id === val)
      return foundOption
        ? {
            value: foundOption.id,
            label: foundOption.name,
          }
        : {
            value: '',
            label: '',
          }
    }
  }

  const optionType = [
    {
      label: 'Public',
      value: 'PUBLIC',
    },
    {
      label: 'Made in Nigeria',
      value: 'NIGERIAN',
    },
  ]
  const options = [
    {
      label: 'All',
      value: 'ALL',
    },
    {
      label: 'Sunday',
      value: 'SUNDAY',
    },
    {
      label: 'Monday',
      value: 'MONDAY',
    },
    {
      label: 'Tuesday',
      value: 'TUESDAY',
    },
    {
      label: 'Wednesday',
      value: 'WEDNESDAY',
    },
    {
      label: 'Thursday',
      value: 'THURSDAY',
    },
    {
      label: 'Friday',
      value: 'FRIDAY',
    },
    {
      label: 'Saturday',
      value: 'SATURDAY',
    },
  ]

  const eventData = allEvent?.data
    ? mapDataAddUrl(allEvent?.data.events, urls)
    : []

  return (
    <div className="EventDiv_Wrapper">
      <HelmetHeader title="Events " description="QlasonEvents Page" />
      <AppBoard className="_listing_product_">
        <div>
          <PageTitle
            title="Events"
            subTitle={
              <PageSubTitle>
                <span onClick={() => navigate(DashboardSellers)}>
                  <VscArrowSmallLeft /> Back to Dashboard
                </span>
                <p>Create events & add promo products</p>
              </PageSubTitle>
            }
          >
            <ButtonGroupStyle>
              <Button
                name="Create Event"
                bgVariant="primary"
                textVariant="light"
                type="button"
                onClick={() => handleOnClick(true)}
              />
            </ButtonGroupStyle>
          </PageTitle>
        </div>
        <DataTableWrapper isCenter={true} className="mt-3 position-relative">
          <div className="event_tableWrapper">
            <DataTable
              theme="simple"
              data={allEvent?.data ? mapData(eventData) : []}
              loading={allEvent?.isLoading || allEvent?.isFetching || loading}
              isSelectable={true}
              showChecklist={false}
              columns={columns}
              showPagination={true}
              thClassName=""
              trClassName=""
              tdClassName={`Row__col event_col`}
              theadClassName="DataTable_THead"
              renderPagnation={() => (
                <>
                  <div className="mt-4">
                    {/* {allEvent?.data && allEvent?.data.events.length > 0 ? ( */}
                    {/* <Pagination
                        pathname={pathname}
                        itemPerPage={result?.data?.sellers?.per_page}
                        itemsLength={result?.data?.sellers?.total}
                        currentPage={pageno}
                        queryNo={_page}
                        callback={(value) => setPageno(value)}
                      /> */}
                    {/* ) : null} */}
                  </div>
                </>
              )}
            />
          </div>
        </DataTableWrapper>

        <CustomModal
          show={modal}
          size="sm"
          title={`${isEdit ? 'Edit' : 'Create'} Event`}
          subtitle={`Complete the fields to ${
            isEdit ? 'edit' : 'create'
          } an event`}
          centered={false}
          showFooter={false}
          isLoading={false}
          isDisabled={false}
          headerClass="app-modalheader event-modalhead"
          modalClass="app-modalbody"
          onHide={() => handleOnClick(false)}
          render={
            <FormPopupStyle>
              <form
                noValidate
                onSubmit={formik.handleSubmit}
                autoComplete="off"
              >
                <div className="FormField">
                  <Field
                    id="name"
                    name="name"
                    label="Name"
                    disabled={result?.isLoading}
                    type="text"
                    required
                    size="large"
                    childOutsideLabel={false}
                    placeholder="Enter Event Name"
                    fieldClassName=""
                    autoComplete="off"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.name}
                    isError={
                      formik.touched.name && formik.errors.name ? true : false
                    }
                    error={formik.errors.name}
                  />
                  <Field
                    id="discount"
                    name="discount"
                    label="Global Discount Value"
                    disabled={result?.isLoading || udateResult?.isLoading}
                    type="number"
                    required
                    size="large"
                    numberFieldPrefix="% "
                    childOutsideLabel={false}
                    placeholder="Enter Discount Value"
                    fieldClassName=""
                    autoComplete="off"
                    onBlur={formik.handleBlur}
                    value={formik.values.discount.toString()}
                    isError={
                      formik.touched.discount && formik.errors.discount
                        ? true
                        : false
                    }
                    error={formik.errors.discount}
                    onChange={(value) => {
                      formik.setFieldValue('discount', value.value)
                    }}
                  />
                  <Field
                    id="image_url"
                    name="image_url"
                    label="Event Banner Url"
                    disabled={result?.isLoading}
                    type="text"
                    required
                    size="large"
                    childOutsideLabel={false}
                    placeholder="Enter Event Banner Url"
                    fieldClassName=""
                    autoComplete="off"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.image_url}
                    isError={
                      formik.touched.image_url && formik.errors.image_url
                        ? true
                        : false
                    }
                    error={formik.errors.image_url}
                  />

                  <SelectStyle>
                    <Label type="text" text="Category" required={true} />
                    <CustomSelect
                      className="custom-select border-danger disabled"
                      placeholder="Select Category"
                      isLoading={category?.isLoading}
                      isMulti={true}
                      isDisabled={
                        result?.isLoading ||
                        category?.isLoading ||
                        udateResult?.isLoading
                      }
                      noOptionsMessage={() =>
                        category?.isError
                          ? 'Could not load, refresh'
                          : 'No Category Found'
                      }
                      value={formik.values.category}
                      onBlur={formik.handleBlur}
                      options={categoryOption || []}
                      isError={formik.errors.category ? true : false}
                      //error={formik.errors.category}
                      onChanged={(option: any) => {
                        formik.setFieldValue('category', option)
                      }}
                    />
                  </SelectStyle>

                  <SelectStyle className="mt-2">
                    <Label type="text" text="Event Type" required={true} />
                    <CustomSelect
                      className="custom-select border-danger disabled"
                      placeholder="Select an Event Type"
                      isLoading={false}
                      noOptionsMessage={() => 'No Event Type Found'}
                      value={formik.values.type}
                      onBlur={formik.handleBlur}
                      options={optionType}
                      isDisabled={result?.isLoading || udateResult?.isLoading}
                      isError={formik.errors.type?.value ? true : false}
                      error={formik.errors.type?.value}
                      onChanged={(option: any) => {
                        formik.setFieldValue('type', {
                          label: option.label,
                          value: option.value,
                        })
                      }}
                    />
                  </SelectStyle>

                  <SelectStyle className="mt-2">
                    <Label type="text" text="Event Day" required={true} />
                    <CustomSelect
                      className="custom-select border-danger disabled"
                      placeholder="Select a Day"
                      isLoading={false}
                      noOptionsMessage={() => 'No Event Day Found'}
                      value={formik.values.eventDay}
                      onBlur={formik.handleBlur}
                      options={options}
                      isDisabled={result?.isLoading || udateResult?.isLoading}
                      isError={formik.errors.eventDay?.value ? true : false}
                      error={formik.errors.eventDay?.value}
                      onChanged={(option: any) => {
                        formik.setFieldValue('eventDay', {
                          label: option.label,
                          value: option.value,
                        })
                      }}
                    />
                  </SelectStyle>

                  {isEdit ? (
                    <SwitchStyle>
                      <Label type="text" text="Set Active" />
                      <Switch
                        defaultChecked={formik.values.is_active}
                        name="is_active"
                        size="medium"
                        color="warning"
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                      />
                    </SwitchStyle>
                  ) : null}
                </div>
                <Button
                  name="Save"
                  bgVariant="primary"
                  textVariant="light"
                  type="submit"
                  className="ButtonSubmit_Class"
                  isLoading={result?.isLoading || udateResult?.isLoading}
                  isLoadingText="Loading"
                  disabled={result?.isLoading || udateResult?.isLoading}
                  shadow={false}
                />
              </form>
            </FormPopupStyle>
          }
        />
      </AppBoard>
    </div>
  )
}

export const TruncateUrl = styled.div`
  width: 180px;
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

const EventDayText = styled.span`
  font-size: 13.5px;
  font-weight: var(--eke-font-weight-normal) !important;
  line-height: 140%;
  letter-spacing: 0.2px;
  color: var(--greyscale-900, #212121);
`

const FormPopupStyle = styled.div`
  width: 95%;
  margin: 3% auto;
  padding-bottom: 20px;
  font-family: var(--eke-body-font-family);

  form {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    justify-content: center;
  }

  label {
    color: var(--greyscale-700, #616161) !important;
    font-size: 14px;
    font-weight: var(--eke-font-weight-normal) !important;
    line-height: 140%; /* 19.6px */
    letter-spacing: 0.2px;
  }

  .FormField {
    width: 100%;
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    gap: 5px;
  }

  .InputField {
    width: 100% !important;
  }

  .ButtonSubmit_Class {
    align-self: flex-end;
    justify-self: flex-end;
    padding: 15px 16px !important;
    min-width: 180px !important;
    margin-right: 0 !important;
    span {
      text-align: center;
      text-shadow: 4px 8px 24px #efdcc7;
      font-size: 15px !important;
      font-weight: var(--eke-font-weight-bold) !important;
      line-height: 140%; /* 22.4px */
      letter-spacing: 0.2px;
    }
  }
`

const SwitchStyle = styled.div`
  padding-top: 20px;
`

const SelectStyle = styled.div``
export default withDashboardLayout(Events)
