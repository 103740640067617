import React, { Link, useLocation, useNavigate } from 'react-router-dom'
import { Col, ListGroup } from 'react-bootstrap'
import { googleLogout } from '@react-oauth/google'
import { toast } from 'react-toastify'
import {
  ChatPathUrl,
  accountProfilePathUrl,
  addressesPathUrl,
  loginPathUrl,
  ordersPathUrl,
  pendingReviewsPathUrl,
  referralsPathUrl,
  returnRequestPathUrl,
  savedItemPathUrl,
  voucherPathUrl,
} from '@ekenta/routes/constant'
import { useAppDispatch, useAppSelector } from '@ekenta/redux/store/hook'
import { removeUser } from '@ekenta/redux/store/states/reducerSlice/authenticationSlice'
import { ReactComponent as SendIcon } from '@ekenta/assets/icons/send-icon.svg'
import { ReactComponent as ShoppingIcon } from '@ekenta/assets/icons/shopping-cart.svg'
import { ReactComponent as BookmarkIcon } from '@ekenta/assets/icons/bookmark-icon.svg'
import { ReactComponent as UserIcon } from '@ekenta/assets/icons/user-icon.svg'
import { ReactComponent as TimeIcon } from '@ekenta/assets/icons/time-icon.svg'
import { ReactComponent as Filter2Icon } from '@ekenta/assets/icons/filter2-icon.svg'
import { ReactComponent as InfoIcon } from '@ekenta/assets/icons/info-square.svg'
import { ReactComponent as LogoutIcon } from '@ekenta/assets/icons/logout-icon.svg'
import { ReactComponent as LocationIcon } from '@ekenta/assets/icons/location-icon.svg'
import { PageTitle } from '@ekenta/components'

import './AccountSideBar.scss'
import { logoutHandler } from '@ekenta/utils'
import { removeLive } from '@ekenta/redux/store/states/reducerSlice/livecomSlice'
import { ChatIcon } from '@ekenta/components/icons'
import { emptyCart } from '@ekenta/redux/store/features/cart/cartSlice'

export default function ProfileSideBar(props: { isLoading: boolean | null }) {
  console.log(props.isLoading, 'isLoading')
  const { pathname } = useLocation()
  const { account, ek_isAuth } = useAppSelector(
    (state) => state.auth.authentication
  )
  const navigate = useNavigate()
  const dispatch = useAppDispatch()

  const side_nav_links = [
    {
      icon: <UserIcon />,
      title: 'Manage Profile',
      active: pathname === accountProfilePathUrl,
      link: accountProfilePathUrl,
    },
    {
      icon: <LocationIcon />,
      title: 'Address Book',
      active: pathname === addressesPathUrl,
      link: addressesPathUrl,
    },
    {
      icon: <ShoppingIcon />,
      title: 'Orders',
      active: pathname === ordersPathUrl,
      link: ordersPathUrl,
    },
    {
      icon: <SendIcon />,
      title: 'Referrals',
      active: pathname === referralsPathUrl,
      link: referralsPathUrl,
    },
    {
      icon: <SendIcon />,
      title: 'Voucher Purchase History',
      active: pathname === voucherPathUrl,
      link: voucherPathUrl,
    },
    {
      icon: <BookmarkIcon />,
      title: 'Saved Items',
      active: pathname === savedItemPathUrl,
      link: savedItemPathUrl,
    },
    {
      icon: <ChatIcon />,
      title: 'Chats',
      active: pathname === ChatPathUrl,
      link: ChatPathUrl,
    },
    {
      icon: <TimeIcon />,
      title: 'Pending Reviews',
      active: pathname === pendingReviewsPathUrl,
      link: pendingReviewsPathUrl,
    },
    {
      icon: <Filter2Icon />,
      title: 'Return Request',
      active:
        pathname === returnRequestPathUrl ||
        pathname === '/user/account/return-request/new',
      link: returnRequestPathUrl,
    },
  ]

  const logoutHandlerFunc = () => {
    logoutHandler(
      navigate,
      dispatch,
      googleLogout,
      loginPathUrl,
      removeUser,
      emptyCart,
      removeLive
    )

    toast.success(`You've successfully logout`, {
      toastId: 'logout-success-toast-success',
    })
  }

  return (
    <Col xs lg={3} className="d-none d-lg-block AccountSide__Bar">
      <ListGroup>
        <div className=" me-auto w-100 BorderBottom Display_Title">
          <PageTitle
            title={`Hi, ${
              ek_isAuth && account.user ? account.user.firstName : 'Account'
            }`}
            margin={false}
            isLink
            url={accountProfilePathUrl}
            className="_wrapper_h1"
            subTitle="Thanks for being Qlason customer"
          />
        </div>
        <div className="_list_child_group">
          {side_nav_links.map((item, index) => (
            <ListGroup.Item
              key={index}
              as={Link}
              to={item.link}
              className={`${item.active ? 'active' : ''}`}
            >
              <span className="sD_m_ ms-2 me-auto">
                {item.icon}
                <span>{item.title}</span>
              </span>
            </ListGroup.Item>
          ))}
        </div>

        <div className="mt-3">
          <ListGroup.Item as={'a'} href="mailto:support@dabsoluterobotics.com">
            <div className="sD_m_ ms-2 me-auto">
              <InfoIcon />
              <span>Help Center</span>
            </div>
          </ListGroup.Item>
          {ek_isAuth && (
            <ListGroup.Item as={Link} to={'#'} onClick={logoutHandlerFunc}>
              <div className="sD_m_ ms-2 me-auto">
                <LogoutIcon />
                <span>Log Out</span>
              </div>
            </ListGroup.Item>
          )}
        </div>
      </ListGroup>
    </Col>
  )
}
