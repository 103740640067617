import React, { Fragment, useState } from 'react'
import { FiPlus } from 'react-icons/fi'
import styled from '@emotion/styled'
import Button from '../Button/Button'
import Loader from '../Loader/Loader'
import BuyVoucherModal from './BuyVoucherModal'

const BuyVoucher = (props: { voucher_id: number; cost: number }) => {
  const { cost, voucher_id } = props
  const [isOpen, setIsOpen] = useState(false)

  const onOpen = () => {
    setIsOpen(true)
  }
  const onClose = () => {
    setIsOpen(false)
  }

  const loading = false
  return (
    <Fragment>
      <ButtonStyle>
        <Button
          name="buyvoucher__name"
          type="button"
          bgVariant="primary"
          disabled={loading}
          defaultClass={false}
          onClick={onOpen}
        >
          {loading ? (
            <Loader color="white" size="small" />
          ) : (
            <Fragment>
              <FiPlus fontSize={15} />
              <span className="mr2">Buy Voucher</span>
            </Fragment>
          )}
        </Button>
      </ButtonStyle>
      <BuyVoucherModal
        {...{
          isOpen,
          onClose,
          cost,
          voucher_id,
        }}
      />
    </Fragment>
  )
}

const ButtonStyle = styled.div`
  position: absolute;
  right: 8px;
  top: 170px;
  z-index: 2 !important;

  button {
    width: auto !important;
    max-width: 150px !important;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 36.128px;
    height: 36.367px !important;
    padding: 20.677px !important;
    color: var(--eke-body-text-light-color) !important;
    font-size: 14px !important;
    font-family: var(--eke-body-font-family) !important;
    font-weight: var(--eke-font-weight-medium) !important;
    border: none !important;
    box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease-out;
    gap: 4px !important;

    span {
      margin-top: 0px;
    }
  }
`
export default BuyVoucher
