/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react'
import styled from 'styled-components'
import UserCard from '../common/UserCard'
import CustomAlert from '@ekenta/components/Alert/CustomAlert'
import { Loader } from '@ekenta/components'

const AllUserChat = (props: {
  data: any
  conversationId: string | null
  from: 'admin' | 'seller' | 'buyer'
  isError: boolean
  currentUser: string
  isLoading: boolean
  handleIndicatorClick: (conversationId: string, is_read: boolean) => void
}) => {
  const {
    data,
    isLoading,
    conversationId,
    isError,
    currentUser,
    from,
    handleIndicatorClick,
  } = props

  return (
    <Container>
      <TopHeader>
        <h5>Chats</h5>
      </TopHeader>
      <ChatsBody>
        {isError ? (
          <CustomAlert
            show={true}
            variant="danger"
            message="Something went wrong"
          />
        ) : null}

        {isLoading ? (
          <VStack>
            <Loader size="large" />
          </VStack>
        ) : (
          <>
            {data && data.length > 0 ? (
              data.map((item: any) => {
                const participantUser =
                  from !== 'buyer'
                    ? item.allMembers.find(
                        (participant: any) => participant.email !== currentUser
                      )
                    : null
                return (
                  <UserCard
                    key={item.id}
                    id={item.room_name}
                    forwardedRef={null}
                    active={item.room_name.toString() === conversationId}
                    product={item.product ? item.product : ''}
                    sellerName={item.allMembers[1].first_name}
                    participantUser={
                      participantUser ? participantUser.first_name : ''
                    }
                    lastMsg={item.lastMessage ? item.lastMessage : ''}
                    dateTime={
                      item.lastMessage ? item.lastMessage.updated_at : ''
                    }
                    hasNewMessages={item.hasNewMessages}
                    newMessagesCount={item.newMessagesCount}
                    handleIndicatorClick={handleIndicatorClick}
                  />
                )
              })
            ) : (
              <>No Conversion</>
            )}
          </>
        )}
      </ChatsBody>
    </Container>
  )
}

const Container = styled.div`
  position: relative;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  /* width: 100%;
    height: 100%;
    border-right: 1px solid #eee; */
`

const TopHeader = styled.div`
  position: static;
  width: 100%;
  height: auto;
  z-index: 9;
  background: var(--eke-bg-light-color) !important;
  padding: 19.5px 25px 13px 25px;
  border-bottom: 1px solid #eee;

  h5 {
    color: #000;
    font-family: var(--eke-body-font-family);
    font-size: 30px;
    font-weight: 700;
    line-height: 28.35px;
  }
`

const ChatsBody = styled.div`
  width: 100%;
  height: 600px;
  flex: 1;
  z-index: 1;
  overflow-y: auto;
  overflow-x: hidden;
`

const VStack = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 100%;
`

export default AllUserChat
