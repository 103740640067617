/* eslint-disable @typescript-eslint/no-explicit-any */
import React from "react"
import { Col, Container, Row } from "react-bootstrap";
import ProfileSideBar from "../component/AccountSideBar/AccountSideBar"
import { useAppSelector } from "@ekenta/redux/store/hook";
import BreadCrumb from "../component/BreadCrumb";
//import { Link } from "react-router-dom";
import { useFetchPendingReviewQuery } from "@ekenta/redux/store/services/accountApi";
import { CustomAlert, EmptyRequest, HelmetHeader, Loader, Overlay, PageTitle } from "@ekenta/components";
import BackButton from "../component/BackButton";
import ListItem from "./ListItem/ListItem";
import { Fragment } from 'react';
import { withDefaultLayout } from "@ekenta/hoc";


const PendingReviews = () => {

    const { token } = useAppSelector(state => state.auth.authentication);
    const getReview = useFetchPendingReviewQuery(token);


    function mapOrdersReturnItem(data: any, orderNo: string, deliveryDate: string) {
        return data.length > 0 && data.map((item: any) => (
            <div key={item.id}>
                <ListItem
                    item={item}
                    orderNo={orderNo}
                    deliveryDate={deliveryDate}
                />
            </div>
        ))
    }



    return (
        <Fragment>
            <HelmetHeader
                title="Pending Reviews"
                description="Qlason Pending Review Page"
            />
            <Container className="account-user-container">
                <><BreadCrumb activePage="Order History" /></>
                <Row className="account-pro-row g-2">
                    <ProfileSideBar isLoading={null} />
                    <Col className="account-profile-col-right">
                        <div className="mb-0 ">
                            <PageTitle
                                title="Pending Reviews"
                                margin={false}
                                subTitle={<BackButton />}
                            />
                        </div>
                        <Col sm={12} lg={12}>
                            <div className="main--content_div">

                                {getReview?.isError ? (
                                    <CustomAlert
                                        variant="danger"
                                        show={true}
                                        message={"Something went wrong, Try again"}
                                    />
                                ) : null}

                                {getReview?.data ? getReview?.data.products.length > 0
                                    ? getReview?.data.products.map((item: any) => (
                                        mapOrdersReturnItem(item.order_item, item.order_number, item.delivery_date)
                                    )) : (
                                        <EmptyRequest
                                            title="No pending reviews"
                                        />
                                    ) : null}
                            </div>

                        </Col>
                        {getReview?.isLoading ? (
                            <Overlay bgColor="dark">
                                <Loader
                                    isCenter
                                    size="large"
                                    color="white"
                                />
                            </Overlay>
                        ) : null}
                    </Col>
                </Row>
            </Container>
        </Fragment>
    )
}

export default withDefaultLayout(PendingReviews);


