/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react'
import styled from 'styled-components';
import { DataTable, Typography } from '@ekenta/components';
import { DataTableWrapper, RCardGroup, RenderCard } from '@ekenta/pages/Account/common/common';
import { ReactComponent as OrderIcon } from "@ekenta/assets/icons/order-value-icon.svg";
import { ReactComponent as DeliveryIcon } from "@ekenta/assets/icons/delivery-fee-icon.svg";
import { ReactComponent as DocIcon } from "@ekenta/assets/icons/doc-icon.svg";
import { TypographyWrapper } from '@ekenta/pages/Dashboard/common/common';
import { useGetReferralAnalyticsQuery } from '@ekenta/redux/store/services/accountApi';
import { useAppSelector } from '@ekenta/redux/store/hook';
import { StatusIndicator, TextTruncates } from './Reusable';
import { formatCurrency } from '@ekenta/utils';
import moment from 'moment';

const ReferralAnalytics = () => {
    const { token } = useAppSelector(
        (state) => state.auth.authentication,
    );
    const result = useGetReferralAnalyticsQuery(token);


    const columns = [
        {
            Header: 'Date',
            accessor: 'date',
            sortType: 'basic',
        },
        {
            Header: "Referral Name",
            accessor: "referral_name",
            sortType: "basic",
        },
        {
            Header: "Referral Amount",
            accessor: "amount",
            sortType: "basic",
        },
        {
            Header: "Status",
            accessor: "status",
            sortType: "basic",
        },
    ];

    const mapData = (data: any) => {
        return data.length > 0 ? data.map((item: any) => ({
            ...item,
            date: moment(item.created_at).format('DD-MM-YYYY'),
            referral_name: (
                item.user ? (
                    <TextTruncates>{item.user.first_name + " " + item.user.last_name}</TextTruncates>
                ) : ''
            ),
            amount: formatCurrency(item.amount, '₦'),
            status: (
                item.status ? (
                    <StatusIndicator status={item.status} />
                ) : ""
            ),
        })) : [];
    };

    const creditRewards = result?.data ? result.data.user.credit_rewards : 0;
    const pendingReferral = result?.data ? result.data.referrals.filter((item: any) => item.status === 'PENDING').length : 0;
    const rewardedRef = result?.data ? result.data.referrals.filter((item: any) => item.status === 'PAID').length : 0;

    return (
        <Container>
            <div className="TopHeading">
                <Typography
                    as="h3"
                    size="base"
                    color="dark"
                    heading>
                    Your Referral Summary
                </Typography>
                <Typography
                    as="p"
                    size="medium"
                    color="dark"
                    uiText>
                    See breakdown of all your referrals
                </Typography>
            </div>
            <div className="pt-2 pb-5">
                <RCardGroup>
                    <RenderCard
                        icon={<OrderIcon />}
                        loading={false}
                        text="Pending Referrals"
                        amount={`${pendingReferral}`}
                    />
                    <RenderCard
                        icon={<DeliveryIcon />}
                        loading={false}
                        text="Rewarded Referrals"
                        amount={`${rewardedRef}`}
                    />
                    <RenderCard
                        icon={<DocIcon />}
                        text="Credit Rewards"
                        loading={false}
                        amount={`₦${new Intl.NumberFormat().format(creditRewards)}`}
                    />
                </RCardGroup>
                <DataTableWrapper isCenter={true} className="mt-4 tab__ position-relative">
                    <DataTable
                        theme="simple"
                        data={result?.data ? mapData(result?.data?.referrals) : []}
                        shadow={true}
                        loading={false}
                        isSelectable={true}
                        columns={columns}
                        showPagination={true}
                        hasLink={true}
                        thClassName=""
                        trClassName="w-25"
                        theadClassName="DataTable_THead"
                        tdClassName={`Row__col`}
                        cardClassName="DataTable_Card"
                        showSearch={false}
                        renderTop={
                            <TypographyWrapper>
                                <Typography
                                    as="h3"
                                    color="default"
                                    size="xlarge"
                                    heading >
                                    Referral History
                                </Typography>
                            </TypographyWrapper>
                        }
                    />
                    <br />
                </DataTableWrapper>
            </div>
            <div>

            </div>
        </Container>
    )
}


const Container = styled.div`
    width: 100%;
    min-height: 269px;
    flex-shrink: 0;
    padding: 26px 23px 0px 23px;
    border-radius: 14px;

    
    .TopHeading{
        h3{
            color: var(--greyscale-900, #212121);
            font-size: 24px !important;
            font-weight: 700 !important;
            line-height: 120%; /* 28.8px */
            margin-bottom: 10px !important;
        }
        
        p{
            color: #000 !important;
            font-size: 15px !important;
            font-weight: var(--eke-font-weight-normal) !important;
            line-height: 140%; /* 22.4px */
            letter-spacing: 0.2px;
        }
    }

    .DataTable_Card{
    }
`;

export default ReferralAnalytics