import * as Yup from 'yup';

const RatingAndReviewFormValidation = () => Yup.object({

    title: Yup.string()
               .required('Title is required!'),
    details: Yup.string()
               .required('Details is required!'),
    rating: Yup.number()
                .min(1,'Rating is required!')
               .required('Rating is required'),
   
});

export default RatingAndReviewFormValidation;