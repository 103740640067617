/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import {
  Col,
} from "react-bootstrap";
import { CardBox, Loader } from "@ekenta/components";
import Header from "../Header/Header";
import ListAddress from './List/ListAddress';
import { useFetchAllAddressesQuery } from '@ekenta/redux/store/services/accountApi';
import { useAppSelector } from '@ekenta/redux/store/hook';

interface DeliveryAddressPropsType {
  values: any;
  getGIGResult: any;
  isLoading: boolean;
}

function DeliveryAddress({
  values,
  isLoading,

}: DeliveryAddressPropsType) {
  const { token } = useAppSelector(
    (state) => state.auth.authentication,
  );
  const getAllAddress = useFetchAllAddressesQuery(token);

  return (
    <div
      className="checout__address__deliv">
      <CardBox
        className="card"
        padding={false}>
        <div className="card-body">
          {isLoading ? <Loader isCenter /> : (
            <>
              <Header
                title="Delivery Address"
                active={values.address}
                isBorder
              />
              <Col lg={12} className="delivery__address_option">
                <ListAddress
                  getAllAddress={getAllAddress}
                />
              </Col>
            </>
          )
          }

        </div>
      </CardBox>
    </div >

  );
}

export default DeliveryAddress;
