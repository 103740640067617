import React, { useState } from 'react';
import { Badge, Card, Col, Modal, Row, Tab, Tabs } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import { FiChevronLeft, FiInbox } from 'react-icons/fi';
import TableComponent from '../../../../components/Dashboard/components/TableComponent';
import DashboardComponent from '../../component/DashboardComponent';
import DashboardLayout from '../../../../hoc/Layout/Dashboard-Layout';
import { useAppSelector } from '../../../../redux/store/hook';
import {
  useFetchAllOrdersQuery,
  useFetchAdmniAllOrdersQuery,
} from '../../../../redux/store/services/vendorProductApi';
import EmptyOrder from '../../../../components/Box/EmptyOrder';
import {
  useFetchAccountingSalesAdminQuery,
  useFetchDailyTotalOrderQuery,
} from '../../../../redux/store/services/adminSellerApi';
import { MDBDataTable } from 'mdbreact';

const LiveData = [
  {
    order: 'Apple MacBook Pro 13 inch',
    quantity: '5',
    deliverystatus: 'Completed',
    paymentstatus: 'Pending',
    datecreated: '10/02/2022',
    totalamount: 'NGN 1,000',
  },
  {
    order: 'Phone Pro 13 inch',
    quantity: '5',
    deliverystatus: 'Completed',
    paymentstatus: 'Paid',
    datecreated: '1/02/2022',
    totalamount: 'NGN 1,000',
  },
  {
    order: 'Phone Pro 13 inch',
    quantity: '5',
    deliverystatus: 'Completed',
    paymentstatus: 'Paid',
    datecreated: '1/02/2022',
    totalamount: 'NGN 1,000',
  },
  {
    order: 'Phone Pro 13 inch',
    quantity: '5',
    deliverystatus: 'Completed',
    paymentstatus: 'Paid',
    datecreated: '1/02/2022',
    totalamount: 'NGN 1,000',
  },
];

const LData = [
  {
    orderId: '10028',
    customer: 'Customer',
    seller: 'seller',
    deliverystatus: 'Completed',
    promoCodeDiscount: 'promoCodeDiscount',
    paymentstatus: 'Pending',
    date: '10/02/2022',
    subTotalAmount: 'NGN 1,000',
  },
  {
    order: 'Phone Pro 13 inch',
    quantity: '5',
    deliverystatus: 'Completed',
    paymentstatus: 'Paid',
    datecreated: '1/02/2022',
    totalamount: 'NGN 1,000',
  },
];

const Tdata = {
  columns: [
    {
      label: 'Order',
      field: 'orderId',
      sort: 'asc',
      Header: 'Order',
      accessor: 'orderId',
      sortType: 'basic',
    },

    {
      label: 'Date',
      field: 'date',
      sortType: 'basic',
    },
    {
      label: 'Customer',
      field: 'customer',
      sortType: 'basic',
    },
    {
      label: 'Seller',
      field: 'seller',
      sortType: 'basic',
    },
    {
      label: 'Subtotal Amount',
      field: 'subTotalAmount',
      sortType: 'basic',
    },
    {
      label: 'Promo Code Discount',
      field: 'promoCodeDiscount',
      sortType: 'basic',
    },
    {
      label: 'Admin Comm',
      field: 'adminCommission',
      sortType: 'basic',
    },
    {
      label: 'Final Total',
      field: 'finalTotal',
      sortType: 'basic',
    },
  ],
  rows: [
    {
      customer: 'Tiger Nixon',
      seller: 'System Architect',
      adminCommission: 'Edinburgh',
      orderId: '61',
      date: '2011/04/25',
      subTotalAmount: '$320',
      promoCodeDiscount: '10202',
      finalTotal: 'ahah',
    },
  ],
};
export default function AdminAccountingOrders({
  collapsed,
  toggled,
  handleToggleSidebar,
  handleCollapsedChange,
}: any) {
  const [lgShow, setLgShow] = useState(false);
  const { ek_isAuth, token } = useAppSelector(
    (state) => state.auth.authentication,
  );
  const allOrders = useFetchDailyTotalOrderQuery(token);
  const ord = useFetchAccountingSalesAdminQuery(token);
  // console.log(allOrders)
  // const result = useFetchAllOrdersQuery(token)
  // const completed = useFetchCompletedOrdersQuery(token)
  // const pending = useFetchPendingOrdersQuery(token)
  // const cancelled = useFetchCancelledOrdersQuery(token)
  // const processing = useFetchProcessingOrdersQuery(token)

  const mapData = LiveData.map((items) => ({
    ...items,
  }));

  const recalibrate = (data: any) => {
    Tdata.rows = data;
    return Tdata;
  };

  const [data, setData] = useState(mapData);

  const [_pageSize, setPageSize] = useState(7);

  const columns = [
    {
      Header: 'Order',
      accessor: 'orderId',
      sortType: 'basic',
    },

    {
      Header: 'Date',
      accessor: 'date',
      sortType: 'basic',
    },
    {
      Header: 'Customer',
      accessor: 'customer',
      sortType: 'basic',
    },
    {
      Header: 'Seller',
      accessor: 'seller',
      sortType: 'basic',
    },
    {
      Header: 'Subtotal Amount',
      accessor: 'subTotalAmount',
      sortType: 'basic',
    },
    {
      Header: 'Promo Code Discount',
      accessor: 'promoCodeDiscount',
      sortType: 'basic',
    },
    {
      Header: 'Admin Comm',
      accessor: 'adminCommission',
      sortType: 'basic',
    },
    {
      Header: 'Final Total',
      accessor: 'finalTotal',
      sortType: 'basic',
    },
  ];

  const handleViewOrder = (id: string) => {
    setLgShow(true);
    alert(id);
  };

  return (
    <DashboardLayout>
      <Helmet>
        <title>Orders Dashboard - Qlason</title>
        <meta charSet="utf-8" />
        <meta name="title" content="QlasonOrders Dashboard Page" />
        <meta name="description" content="QlasonOrders Dashboard  Page" />
        <meta property="og:locale" content="en_NG" />
        <meta name="robots" content="noindex,follow" />
      </Helmet>

      <DashboardComponent
        collapsed={collapsed}
        toggled={toggled}
        handleToggleSidebar={handleToggleSidebar}
        handleCollapsedChange={handleCollapsedChange}
      >
        <Col className=" col-lg-11 mx-auto _listing_product_">
          <div className="mt-0">
            <h3 className="display_lg_header">
              {/* <FiChevronLeft /> */}
              Orders
            </h3>
            <hr className="mt-1" />
            <Row className="">
              <Col md={3}>
                ₦
                {new Intl.NumberFormat().format(
                  !allOrders.isSuccess
                    ? '---'
                    : allOrders.data.totalValueOrders,
                )}{' '}
                <br /> Total Orders Value
              </Col>
              <Col md={3}>
                {new Intl.NumberFormat().format(
                  !allOrders.isSuccess ? '---' : allOrders.data.totalOrders,
                )}{' '}
                <br /> Total Orders
              </Col>
              <Col md={3}>
                ₦
                {new Intl.NumberFormat().format(
                  !allOrders.isSuccess
                    ? '---'
                    : allOrders.data.totalCashReceived,
                )}{' '}
                <br /> Total Cash to be Collected
              </Col>
              <Col md={3}>
                ₦
                {new Intl.NumberFormat().format(
                  !allOrders.isSuccess
                    ? '---'
                    : allOrders.data.totalDeliveryFees,
                )}{' '}
                <br /> Total Delivery Fees
              </Col>
            </Row>
            <hr />
            <Col className="mt-5">
              <Card>
                <Card.Body>
                  {/* <TableComponent columns={columns} data={allOrders.data.orders.content} pageSizeno={_pageSize} />  */}
                  {!allOrders.isSuccess ? (
                    'Loading'
                  ) : allOrders.data.orders.content.length > 0 ? (
                    <MDBDataTable
                      striped
                      bordered
                      hover
                      responsive={true}
                      searchLabel="Search"
                      exportToCSV
                      data={recalibrate(allOrders.data.orders.content)}
                    />
                  ) : (
                    <EmptyOrder orderType="active" />
                  )}
                </Card.Body>
              </Card>
            </Col>
          </div>
        </Col>
      </DashboardComponent>
    </DashboardLayout>
  );
}
