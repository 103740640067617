/* eslint-disable @typescript-eslint/no-explicit-any */
import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit'

import { RootState } from '../../hook'
import {
  addToCartAsync,
  removeFromCartAsync,
  updateQuantityAsync,
} from './cartThunks'

export interface Product {
  id: string
  name: string
  price: number
  stock: number
  is_variant: number
  // Include other product properties
}

export interface CartItem {
  id?: string | number
  product: Product
  quantity: number
  user_id: string
}

interface CartInitialStateType {
  cartItems: CartItem[]
  noOfItems: number
  cartTotalAmount: number
}
export const CartInitialState: CartInitialStateType = {
  cartItems: [],
  cartTotalAmount: 0,
  noOfItems: 0,
}

export const cartSlice = createSlice({
  name: 'newCart',
  initialState: CartInitialState,
  reducers: {
    setCartItems: (state, action) => {
      const data = action.payload
      state.cartItems = data.map((item: CartItem) => ({
        id: item.id,
        product: {
          id: item.product.id,
          name: item.product.name,
          stock: item.product.stock,
          is_variant: item.product.is_variant,
        },
        quantity: parseInt(item.quantity.toString()),
        user_id: item.user_id,
      }))
      state.noOfItems = state.cartItems.reduce(
        (total, cartItem) => total + cartItem.quantity,
        0
      )
    },

    addToCart: (
      state,
      action: PayloadAction<{
        id: string
        product: Product
        quantity: number
        user_id: string
      }>
    ) => {
      const { id, product, quantity, user_id } = action.payload
      const existingCartItem = state.cartItems.find(
        (item) => item.product?.id.toString() === product?.id.toString()
      )

      if (existingCartItem) {
        // Update existing item
        existingCartItem.quantity += quantity
      } else {
        // Add new item
        state.cartItems.push({
          id,
          product: { ...product },
          quantity,
          user_id,
        })
      }

      state.noOfItems += quantity
    },

    removeFromCart: (state, action: PayloadAction<{ id: string }>) => {
      const { id } = action.payload
      const removedItem = state.cartItems.find(
        (cartItem) => cartItem.id?.toString() === id.toString()
      )
      if (removedItem) {
        state.noOfItems -= removedItem.quantity
        state.cartItems = state.cartItems.filter(
          (cartItem) => cartItem.id?.toString() !== id.toString()
        )
      }
    },

    updateQuantity: (
      state,
      action: PayloadAction<{ cartItemId: string; quantity: number }>
    ) => {
      const { cartItemId, quantity } = action.payload
      const existingItemIndex = state.cartItems.findIndex(
        (item) => item.id?.toString() === cartItemId.toString()
      )
      if (existingItemIndex !== -1) {
        state.cartItems[existingItemIndex].quantity = quantity
        state.noOfItems = state.cartItems.reduce(
          (total, item) => total + item.quantity,
          0
        )
      }
    },

    emptyCart: (state) => {
      state.cartItems = []
      state.noOfItems = 0
      state.cartTotalAmount = 0
    },
  },
  extraReducers: (builder) => {
    builder.addCase(addToCartAsync.fulfilled, () => {
      console.log('Item added to cart')
    })
    builder.addCase(updateQuantityAsync.fulfilled, () => {
      console.log('upated cart')
    })
    builder.addCase(removeFromCartAsync.fulfilled, () => {
      console.log('remove item from cart')
    })
  },
})

export const {
  emptyCart,
  updateQuantity,
  removeFromCart,
  addToCart,
  setCartItems,
} = cartSlice.actions

export const selectCartItems = (state: RootState) =>
  state.cart.newCart.cartItems
// export const getCartItemCount = (state: RootState) =>
//   state.cart.newCart.cartItems
//     ? state.cart.newCart.cartItems.reduce(
//         (total: number, item: CartItem) => total + item.quantity,
//         0,
//       )
//     : 0;

export const getCartItemCount = createSelector(
  (state: RootState) => state.cart.newCart.cartItems,
  (items) =>
    items.reduce((total: number, item: CartItem) => total + item.quantity, 0)
)

export const selectProductQuantityAndId =
  (productId: string) => (state: RootState) => {
    const cartItem = state.cart.newCart.cartItems.find(
      (item: CartItem) =>
        item?.product?.id?.toString() === productId?.toString()
    )
    return {
      quantity: cartItem ? cartItem?.quantity : 0,
      cartItemId: cartItem ? cartItem?.id : null,
    }
  }

export default cartSlice.reducer
