import React, { useEffect, useState } from 'react';
import { Button, Card, Col, Modal, Row, Form } from 'react-bootstrap';
import { useAppSelector } from '../../../../redux/store/hook';

import { FaArrowLeft } from 'react-icons/fa';
import { useNavigate } from 'react-router';
import { AdminDashboardCollectionPathUrl } from '../../../../routes/constant';
import sent from '@ekenta/assets/images/sent.gif';
import { useAddNewCollectionMutation } from '../../../../redux/store/services/collectionApi';
import withDashboardLayout from '../../../../hoc/Layout/withDashboardLayout';
import { BASE_URL } from '@ekenta/constants';
import axios from 'axios';
import { HelmetHeader } from '@ekenta/components';

const AddCollection = () => {
  const [lgShow, setLgShow] = useState(false);
  const { ek_isAuth, token } = useAppSelector(
    (state) => state.auth.authentication,
  );
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [
    addCollectionData,
    { isError: addCollection_isError, isSuccess: addCollection_isSuccess },
  ] = useAddNewCollectionMutation();

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [details, setDetails] = useState({
    name: '',
    short_name: '',
    description: '',
    visibility: 'false',
    thumbnailUrl: '',
    file: '',
  });
  // const formData = new FormData()
  const handleImage = (e: any) => {
    // console.log(e.target.files[0])
    setDetails({ ...details, file: e.target.files[0] });

    // console.log(formData)
  };

  const handleSubmit = () => {
    setLoading(true);
    console.log(details);
    // const data = {
    //   name: details?.name,
    //   description: details?.description,
    //   visibility: details?.visibility == 'true' ? true : false,
    // };
    const data = new FormData();
    data.append('name', details?.name);
    data.append('short_name', details?.short_name);
    data.append('description', details?.description);
    data.append('visibility', details?.visibility);
    data.append('image', details?.file);

    console.log(data);

    const config = {
      method: 'POST',
      url: `${BASE_URL}/api/admin/create-collection`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        console.log(JSON.stringify(response.data));
        handleShow();
        setLoading(false);
      })
      .catch(function (error) {
        console.log(error);
        setLoading(false);
      });

    // addCollectionData({ data, token });
  };

  useEffect(() => {
    if (addCollection_isSuccess) {
      handleShow();
    }

    if (addCollection_isError) {
      setLoading(false);
      //   toast.error(<div className="cus_t">An error occured !</div>);
      //   toastDisplay(true);
    }

    // navigate(adminManageBanner)
  }, [addCollection_isSuccess, addCollection_isError]);

  return (
    <>
      <HelmetHeader
        title="Add Collection"
        description="Qlason Add Collection Page"
      />

      <Col className=" col-lg-11 mx-auto _listing_product_">
        <div className="mt-0">
          <h3 className="display_lg_header">
            {/* <FiChevronLeft /> */}
            Add Collection
          </h3>
          <Col className="mt-3">
            <Button
              className="mb-3 text-white"
              onClick={() => navigate(AdminDashboardCollectionPathUrl)}
            >
              <FaArrowLeft /> Back
            </Button>
            <Card>
              <Card.Body>
                <Form.Label htmlFor="inputPassword5">
                  Collection Name *{' '}
                </Form.Label>
                <Form.Control
                  type="text"
                  onChange={(e) =>
                    setDetails({ ...details, name: e.target.value })
                  }
                />
                <br />
                <Form.Label htmlFor="inputPassword5">
                  Short Name *{' '}
                </Form.Label>
                <Form.Control
                  type="text"
                  onChange={(e) =>
                    setDetails({ ...details, short_name: e.target.value })
                  }
                />
                <br />
                <Form.Label htmlFor="inputPassword5">Description * </Form.Label>
                <Form.Control
                  type="text"
                  onChange={(e) =>
                    setDetails({ ...details, description: e.target.value })
                  }
                />
                <br />
                <Form.Label htmlFor="inputPassword5">
                  Thumbnail Image *
                </Form.Label>
                <Form.Control type="file" onChange={(e) => handleImage(e)} />
                <br />
                <Form.Label htmlFor="inputPassword5">Visibility</Form.Label>
                <Form.Select
                  onChange={(e) =>
                    setDetails({ ...details, visibility: e.target.value })
                  }
                >
                  <option>Select Visibility</option>
                  <option value={'true'}>True</option>
                  <option value={'false'}>False</option>
                </Form.Select>
                <br />
                <Button
                  variant="danger"
                  disabled={loading ? true : false}
                  onClick={handleSubmit}
                >
                  {loading ? 'Loading...' : 'Submit'}
                </Button>{' '}
                <Button variant="outline-danger">Cancel</Button>
              </Card.Body>
            </Card>
          </Col>
        </div>
      </Col>

      <Modal
        show={show}
        onHide={handleClose}
        size="sm"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body>
          <Row>
            <img src={sent} alt="" height={300} width={'100%'} />
            <Button
              variant="success"
              className="text-white text-center mx-auto"
              onClick={() => navigate(AdminDashboardCollectionPathUrl)}
            >
              Done
            </Button>
          </Row>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default withDashboardLayout(AddCollection);
