/* eslint-disable @typescript-eslint/no-explicit-any */
import { CardBox } from '@ekenta/components';
import React from 'react'
import styled from 'styled-components';

const InformationCard = (props: { title: string; isShow?: boolean; description: any; onShow?: any }) => {
	const { title, description } = props;



	return (
		<Container>
			<CardBox className="CardBox__Style" shadow={false}>
				<Title>{title}</Title>
				<Description>
					{description}
				</Description>
			</CardBox>
		</Container>
	)
}


const Container = styled.div`
	.CardBox__Style{
		display: flex;
		padding: 31px 28px 43px 36px !important;
		justify-content: center;
		flex-direction: column;
		align-items: flex-start;
	}
`;

const Title = styled.h1`
	color: var(--greyscale-900, #212121);
	font-size: 28px;
	font-family: var(--eke-body-font-family);
    font-weight: var(--eke-font-weight-bold) !important;
	line-height: 120%;
`;
const Description = styled.p`
	color: #616161;
	font-size: 17px;
	font-family: var(--eke-body-font-family);
    font-weight: var(--eke-font-weight-normal) !important;
	line-height: 140%;
	letter-spacing: 0.2px;
`;


export default InformationCard