import React from "react"
import { ListGroup, Card, Button } from "react-bootstrap"
import NumberFormat from "react-number-format"
import { Link } from "react-router-dom"
import AddCartButton from "../../../../../components/Cart/CartButtons/AddCartButton/AddCartButton";
import QuantityCartButton from "../../../../../components/Cart/CartButtons/QuantityCartButton/QuantityCartButton";
import { productBaseUrl } from "../../../../../routes/constant";

import "./SingleProductItem.scss";
import { Typography } from "@ekenta/components";
import Tag from "@ekenta/components/Tag/Tag";
import { calculatePercentage, calculateTotalPrice, setDiscountPercent } from "@ekenta/utils";

export default function SingleProductItem(props: any) {
  return (
    <ListGroup.Item className="SingleProd_group_item" as={"li"}>

      <Card style={{ width: "100%" }}>
        <div className="group-item">
          {props.discount && props.discountPercentage > 0 ?
            <Tag
              numberOf={setDiscountPercent(props.discountPercentage)}
              className="priceOff"
            /> : (
              <>
                {parseInt(props.maxPrice) > parseInt(props.price) ? (
                  <Tag
                    numberOf={calculatePercentage(props.maxPrice, props.minPrice)}
                    className="priceOff"
                  />
                ) : null}
              </>
            )}
          <Card.Body className="ps-0">
            <div className="cart_sys_div">
              {props.cartItem && props.cartItem.quantity >= 1 ? (
                <div className="group--components">
                  <QuantityCartButton
                    cartItems={props.cartItem}
                    cartItemId={props.cartItem.id}
                    className="quantity--picker"
                  />
                </div>
              ) : (
                <>
                  <AddCartButton
                    productId={props.productId}
                    productname={props.title}
                    product={props.product}
                    name="Add"
                  />

                </>
              )}
            </div>

            <div className="image_u">
              <Link to={`${productBaseUrl}/${props.slug}`} target="_blank">
                <Card.Img variant="top" src={props.imageUrl} />
              </Link>
            </div>
            <Link className="content-section" to={`${productBaseUrl}/${props.slug}`} target="_blank">
              <Typography
                as="h5"
                size="medium"
                color="secondary"
                className="title-h5"
                heading
              >
                {props.title}
              </Typography>
              <div className="card-bottom-group">
                <div className="cart_listing_price">
                  <NumberFormat
                    value={calculateTotalPrice(props.price, props.discount)}
                    displayType="text"
                    thousandSeparator={true}
                    prefix="₦"
                    className="actual_price_Span"
                  />
                  {props.discount && props.discount > 0
                    ? (
                      <NumberFormat
                        value={props.price}
                        displayType="text"
                        thousandSeparator={true}
                        prefix="₦"
                        className="span__body"
                      />
                    ) : parseInt(props.maxPrice) > parseInt(props.price) ? (
                      <NumberFormat
                        value={`${props.maxPrice}`}
                        displayType="text"
                        thousandSeparator={true}
                        prefix="₦"
                        className="span__body"
                      />
                    ) : null}
                </div>

              </div>
            </Link>


          </Card.Body>

        </div>
      </Card>
    </ListGroup.Item>

  )
}
