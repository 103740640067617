/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { useLocation } from 'react-router';
import { mapCartDataReturnQuantity, slugifyText } from '../../../../utils';
import EmptyBox from '../../../../components/Box/EmptyBox';
import ProductCardComponent from '../../../../components/Product/ProductCardComponent';
import { Pagination } from '../../../../components';
import ProductListLoader from '../SkeletonLoader/ProductListLoader';
import { Link } from 'react-router-dom';
import { productBaseUrl } from '@ekenta/routes/constant';

export default function ProductListBySub(props: any) {

  const { pathname } = useLocation();
  const urlParams = new URLSearchParams(window.location.search);
  const myParam = urlParams.get("isIkitchen")
  return (
    <>
      <div className="__main_collection_product_card_">
        <Row className="prod_commponent_row_ p-0 m-0">
          {props.isError ? <EmptyBox /> : null}

          {props.isLoading ? (
            <ProductListLoader />
          ) : props.isSuccess && props.subData ? (
            props.subData?.data.length > 0 ? (
              props.subData.data.map((item: any) => {
                const _slugProduct = item?.id
                  ? slugifyText(item?.name.toLowerCase(), item?.id)
                  : "/";
                return (
                  myParam !== null ?
                    <Link
                      className={`card--inner ${props.productViewIcon === "grid" ? "" : "row"}`}
                      to={`${productBaseUrl}/${_slugProduct}`}
                      data-name={item?.name} data-price={item?.price} data-brand="" data-category=""
                      data-track-onclick="false"
                      style={{ textDecoration: "none", color: "black" }}
                    >
                      <Col md={4} lg={4} sm={4} key={item.id}>
                        <img className={"img-fluid"} style={{ borderRadius: "1.6rem" }} src={item.images.length > 0 ? item.images[0]?.image_url : ""} alt="HCS" loading="lazy"></img>
                        <h4 style={{ letterSpacing: ".03em" }}>{item?.name}</h4>
                        <p>{item?.description}</p>
                        <Row style={{ minHeight: "100px" }}>
                          {
                            item.features?.map((e: any, i: any) => (
                              <Col sm={3} lg={4} md={4} key={i} className={"p-0"}>
                                <p style={{ textTransform: "uppercase", marginBottom: "0px" }}>{e?.feature_value}</p>
                                <p style={{ textTransform: "uppercase", marginBottom: "0px" }}>{e?.feature?.name}</p>
                              </Col>
                            ))
                          }
                        </Row>
                      </Col>
                    </Link> :
                    <ProductCardComponent
                      key={item.id}
                      productId={item.id}
                      product={item}
                      layoutView={props.productViewIcon}
                      cartItem={mapCartDataReturnQuantity(
                        props.allCartData,
                        item.id,
                      )}
                      isShadow={true}
                      maxPrice={item.max_price}
                      minPrice={item.min_price}
                      discount={item.discount}
                      adminDiscount={item.admin_discount}
                      discountPercentage={item.discount_percentage}
                      productname={item.name}
                      defaultprice={item.price}
                      thumbnailUrl={item.images.length > 0 ? item.images[0]?.image_url : ''}
                      imageUrl={item.images.length > 0 ? item.images[1]?.image_url : ''}
                      videoUrl={item.video_url}
                      condition={item.condition}
                    />
                );
              })
            ) : (
              <EmptyBox />
            )
          ) : null}
        </Row>
        {/* {Pagination} */}
        {props.subData
          ? props.subData.total > 0 && (
            <Pagination
              pathname={pathname}
              itemPerPage={props.subData.per_page}
              itemsLength={props.subData.total}
              currentPage={props.pageno}
              queryNo={props.queryNo}
              callback={(value) => props.setPageno(value)}
            />
          )
          : null}
      </div>
    </>
  );
}

