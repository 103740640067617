import {
  DashboardCollection,
  AllProducts,
  Orders,
  LowStock,
  AddProduct,
  WareHouse,
  ReturnsRequested,
  AdminHome,
  DashboardSingleSeller,
  SellerLiveScreen,
  AllActiveSellers,
  UserManagement,
  EkeMarket,
  AllUsers,
  AllVerifiedUsers,
  AddCollection,
  EditCollection,
  AddSubCollection,
  EditSubCollection,
  EditSubCollectionCategory,
  AddSubCollectionCategory,
  AdminCollection,
  AdminSubCollection,
  AdminSubCollectionCategories,
  ManageVideo,
  SellersAwaitingApproval,
  AdminOrders,
  AdminAccountingOrders,
  ManageBanner,
  ViewReturnRequest,
  OrderListDetails,
  Events,
  SellerProfile,
  Discount,
  Analytics,
  AdminReviews,
  AllSellers,
  AdminPayoutRequest,
  AdminPayoutHistory,
  AdminSinglePayout,
  AdminSinglePayoutHistory,
  SingleEvent,
  AddProductToEvent,
  Notification,
  SellerChat,
  AdminChat,
} from '@ekenta/pages/Dashboard'

import {
  DashboardPathUrl,
  DashboardAddProductPathUrl,
  DashboardCollectionPathUrl,
  DashboardProductsPathUrl,
  DashboardOrdersPathUrl,
  DashboardLowStockPathUrl,
  DashboardWareHousePathUrl,
  DashboardReturnRequestPathUrl,
  DashboardSellerLiveCommercePathUrl,
  DashboardSellerAnalytics,
  DashboardSellers,
  SingleSeller,
  awaitingSellers,
  activeSellersPathUrl,
  sellerReview,
  adminAllOrders,
  adminAccountingOrders,
  adminManageBanner,
  adminManageLogo,
  viewReturnRequest,
  DashboardOrdersPathUrlSingle,
  AdminUserMangement,
  AdminProductsPathUrl,
  adminAddLogo,
  adminReturnRequest,
  adminPayoutRequest,
  adminPayoutHistory,
  adminVideos,
  adminAddVideos,
  adminGetOrderDetails,
  adminGetAllUsersUrl,
  adminGetAllVerifiedUsersUrl,
  AdminDashboardCollectionPathUrl,
  AddCollectionUrl,
  AdminDashboardSubCollectionPathUrl,
  AdminDashboardSubCollectionCategoriesPathUrl,
  AddSubCollectionUrl,
  AddSubCollectionCategoryUrl,
  AdminSellerProfile,
  AdminDiscountPathUrl,
  EditCollectionUrl,
  EditSubCollectionUrl,
  EditSubCollectionCategoryUrl,
  // rentItPathUrl,
  AdminDashoardEkeMarketPathUrl,
  DashboardEventsPathUrl,
  blockedSellersPathUrl,
  DashboardSellerMerchantDetailsUrl,
  AdminSellerDetailsUrl,
  SingleUserUrl,
  DashboardOffersPathUrl,
  DashboardNotificationsPathUrl,
  reelsUrl,
  adminReviewsReportUrl,
  allSellersPathUrl,
  adminSinglePayoutRequest,
  adminSinglePayoutHistory,
  DashboardSingleEventsPathUrl,
  DashboardAddProductToEventPathUrl,
  DashboardSellerChatPathUrl,
  AdminChatPathUrl,
  AdminVoucherPathUrl,
  AdminAllVouchersPathUrl,
  AdminCreatedVouchersPathUrl,
} from '../constant'
import { Roles } from '../roleConfig'
import AdminSellerDetails from '@ekenta/pages/Dashboard/Admin/Sellers/AdminSellerDetails'
import AddLogo from '@ekenta/pages/Dashboard/Admin/AddLogo'
import ManageLogo from '@ekenta/pages/Dashboard/Admin/ManageLogo'
import Offers from '@ekenta/pages/Dashboard/Admin/Events/Offers'
import AddVideo from '@ekenta/pages/Dashboard/Admin/AddVideo'
import AllBlockedSellers from '@ekenta/pages/Dashboard/Admin/Home/AllBlockedSellers'
import SellerMerchantDetails from '@ekenta/pages/Dashboard/Sellers/SellerMerchantDetails'
import UserDetails from '@ekenta/pages/Dashboard/Admin/UserManagement/UserDetails'
import Reels from '@ekenta/pages/Reels/reels'
import Voucher from '@ekenta/pages/Dashboard/Admin/Voucher'
import AllVouchers from '@ekenta/pages/Dashboard/Admin/Voucher/AllVouchers'
import CreatedVouchers from '@ekenta/pages/Dashboard/Admin/Voucher/CreatedVouchers'

export const AuthorizeDashboardRoutes: {
  path: string
  component: any
  roles: string[]
}[] = [
  {
    path: DashboardPathUrl,
    // component: DashboardHome, Analytics
    component: Analytics,
    roles: [Roles.ROLE_ADMIN, Roles.ROLE_SELLER],
  },
  {
    path: DashboardCollectionPathUrl,
    component: DashboardCollection,
    roles: [Roles.ROLE_ADMIN, Roles.ROLE_SELLER],
  },
  {
    path: DashboardReturnRequestPathUrl,
    component: ReturnsRequested,
    roles: [Roles.ROLE_ADMIN, Roles.ROLE_SELLER],
  },
  {
    path: DashboardWareHousePathUrl,
    component: WareHouse,
    roles: [Roles.ROLE_ADMIN, Roles.ROLE_SELLER],
  },
  {
    path: DashboardAddProductPathUrl,
    component: AddProduct,
    roles: [Roles.ROLE_ADMIN, Roles.ROLE_SELLER],
  },
  {
    path: DashboardProductsPathUrl,
    component: AllProducts,
    roles: [Roles.ROLE_ADMIN, Roles.ROLE_SELLER],
  },
  {
    path: DashboardSellerChatPathUrl,
    component: SellerChat,
    roles: [Roles.ROLE_SELLER],
  },
  {
    path: DashboardOrdersPathUrl,
    component: Orders,
    roles: [Roles.ROLE_ADMIN, Roles.ROLE_SELLER],
  },
  {
    path: DashboardLowStockPathUrl,
    component: LowStock,
    roles: [Roles.ROLE_ADMIN, Roles.ROLE_SELLER],
  },
  {
    path: DashboardSellerLiveCommercePathUrl,
    component: SellerLiveScreen, //livecommerce
    roles: [Roles.ROLE_ADMIN, Roles.ROLE_SELLER],
  },
  {
    path: DashboardSellerAnalytics,
    component: Analytics,
    roles: [Roles.ROLE_ADMIN, Roles.ROLE_SELLER],
  },
  {
    path: DashboardSellers,
    component: AdminHome,
    roles: [Roles.ROLE_ADMIN],
  },
  {
    path: AdminChatPathUrl,
    component: AdminChat,
    roles: [Roles.ROLE_ADMIN],
  },
  {
    path: SingleSeller,
    component: DashboardSingleSeller,
    roles: [Roles.ROLE_ADMIN],
  },
  {
    path: awaitingSellers,
    component: SellersAwaitingApproval,
    roles: [Roles.ROLE_ADMIN],
  },
  {
    path: activeSellersPathUrl,
    component: AllActiveSellers,
    roles: [Roles.ROLE_ADMIN],
  },
  {
    path: allSellersPathUrl,
    component: AllSellers,
    roles: [Roles.ROLE_ADMIN],
  },
  {
    path: sellerReview,
    // component: SellerDetails,
    component: AdminSellerDetails,
    roles: [Roles.ROLE_ADMIN],
  },
  {
    path: adminAllOrders,
    component: AdminOrders,
    roles: [Roles.ROLE_ADMIN],
  },
  {
    path: adminAccountingOrders,
    component: AdminAccountingOrders,
    roles: [Roles.ROLE_ADMIN],
  },
  {
    path: AdminUserMangement,
    component: UserManagement,
    roles: [Roles.ROLE_ADMIN],
  },
  {
    path: AdminVoucherPathUrl,
    component: Voucher,
    roles: [Roles.ROLE_ADMIN],
  },
  {
    path: AdminAllVouchersPathUrl,
    component: AllVouchers,
    roles: [Roles.ROLE_ADMIN],
  },
  {
    path: AdminCreatedVouchersPathUrl,
    component: CreatedVouchers,
    roles: [Roles.ROLE_ADMIN],
  },
  {
    path: AdminSellerProfile,
    component: SellerProfile,
    roles: [Roles.ROLE_ADMIN],
  },
  {
    path: adminManageBanner,
    component: ManageBanner,
    roles: [Roles.ROLE_ADMIN],
  },

  {
    path: adminManageLogo,
    component: ManageLogo,
    roles: [Roles.ROLE_ADMIN],
  },

  {
    path: viewReturnRequest,
    component: ViewReturnRequest,
    roles: [Roles.ROLE_ADMIN, Roles.ROLE_SELLER],
  },

  {
    path: DashboardOrdersPathUrlSingle,
    component: OrderListDetails,
    roles: [Roles.ROLE_ADMIN, Roles.ROLE_SELLER],
  },

  {
    path: DashboardNotificationsPathUrl,
    component: Notification,
    roles: [Roles.ROLE_ADMIN],
  },

  // {
  //   path: AdminOrdersPathUrlSingle,
  //   component: OrderDetails,
  //   roles: [Roles.ROLE_ADMIN],
  // },

  {
    path: AdminProductsPathUrl,
    component: AllProducts,
    roles: [Roles.ROLE_ADMIN],
  },

  {
    path: adminAddLogo,
    component: AddLogo,
    roles: [Roles.ROLE_ADMIN],
  },

  {
    path: adminReturnRequest,
    component: ReturnsRequested,
    roles: [Roles.ROLE_ADMIN],
  },

  {
    path: adminPayoutRequest,
    component: AdminPayoutRequest,
    roles: [Roles.ROLE_ADMIN],
  },
  {
    path: adminPayoutHistory,
    component: AdminPayoutHistory,
    roles: [Roles.ROLE_ADMIN],
  },
  {
    path: adminSinglePayoutRequest,
    component: AdminSinglePayout,
    roles: [Roles.ROLE_ADMIN],
  },
  {
    path: adminSinglePayoutHistory,
    component: AdminSinglePayoutHistory,
    roles: [Roles.ROLE_ADMIN],
  },

  {
    path: AdminDashoardEkeMarketPathUrl,
    component: EkeMarket,
    roles: [Roles.ROLE_ADMIN],
  },
  {
    path: DashboardEventsPathUrl,
    component: Events,
    roles: [Roles.ROLE_ADMIN],
  },
  {
    path: DashboardSingleEventsPathUrl,
    component: SingleEvent,
    roles: [Roles.ROLE_ADMIN],
  },
  {
    path: DashboardAddProductToEventPathUrl,
    component: AddProductToEvent,
    roles: [Roles.ROLE_ADMIN],
  },
  {
    path: DashboardOffersPathUrl,
    component: Offers,
    roles: [Roles.ROLE_ADMIN],
  },
  {
    path: AdminDiscountPathUrl,
    component: Discount,
    roles: [Roles.ROLE_ADMIN],
  },
  {
    path: adminVideos,
    component: ManageVideo,
    roles: [Roles.ROLE_ADMIN],
  },

  {
    path: adminAddVideos,
    component: AddVideo,
    roles: [Roles.ROLE_ADMIN],
  },

  {
    path: adminGetOrderDetails,
    component: OrderListDetails,
    roles: [Roles.ROLE_ADMIN],
  },

  {
    path: adminGetAllUsersUrl,
    component: AllUsers,
    roles: [Roles.ROLE_ADMIN],
  },

  {
    path: adminGetAllVerifiedUsersUrl,
    component: AllVerifiedUsers,
    roles: [Roles.ROLE_ADMIN],
  },

  {
    path: AdminDashboardCollectionPathUrl,
    component: AdminCollection,
    roles: [Roles.ROLE_ADMIN],
  },

  {
    path: AddCollectionUrl,
    component: AddCollection,
    roles: [Roles.ROLE_ADMIN],
  },

  {
    path: adminReviewsReportUrl,
    component: AdminReviews,
    roles: [Roles.ROLE_ADMIN],
  },

  {
    path: AdminDashboardSubCollectionPathUrl,
    component: AdminSubCollection,
    roles: [Roles.ROLE_ADMIN],
  },

  {
    path: AdminDashboardSubCollectionCategoriesPathUrl,
    component: AdminSubCollectionCategories,
    roles: [Roles.ROLE_ADMIN],
  },

  {
    path: AddSubCollectionUrl,
    component: AddSubCollection,
    roles: [Roles.ROLE_ADMIN],
  },

  {
    path: AddSubCollectionCategoryUrl,
    component: AddSubCollectionCategory,
    roles: [Roles.ROLE_ADMIN],
  },

  {
    path: AddSubCollectionCategoryUrl,
    component: AdminSubCollectionCategories,
    roles: [Roles.ROLE_ADMIN],
  },
  {
    path: EditCollectionUrl,
    component: EditCollection,
    roles: [Roles.ROLE_ADMIN],
  },

  {
    path: EditSubCollectionUrl,
    component: EditSubCollection,
    roles: [Roles.ROLE_ADMIN],
  },

  {
    path: EditSubCollectionCategoryUrl,
    component: EditSubCollectionCategory,
    roles: [Roles.ROLE_ADMIN],
  },

  {
    path: blockedSellersPathUrl,
    component: AllBlockedSellers,
    roles: [Roles.ROLE_ADMIN],
  },

  {
    path: DashboardSellerMerchantDetailsUrl,
    component: SellerMerchantDetails,
    roles: [Roles.ROLE_ADMIN, Roles.ROLE_SELLER],
  },

  {
    path: AdminSellerDetailsUrl,
    component: AdminSellerDetails,
    roles: [Roles.ROLE_ADMIN, Roles.ROLE_SELLER],
  },

  {
    path: SingleUserUrl,
    component: UserDetails,
    roles: [Roles.ROLE_ADMIN, Roles.ROLE_SELLER],
  },

  {
    path: reelsUrl,
    component: Reels,
    roles: [Roles.ROLE_ADMIN, Roles.ROLE_SELLER, Roles.ROLE_USER],
  },

  // {
  //   path: broadcastUrl,
  //   component: Broadcast,
  //   roles: [Roles.ROLE_ADMIN, Roles.ROLE_SELLER, Roles.ROLE_USER],
  // },
]

export default AuthorizeDashboardRoutes
