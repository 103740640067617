import React from 'react';
import classnames from 'classnames';
//import { Icon } from "@iconify/react";
import PropTypes from 'prop-types';

import TableHead from './TableHead';
import TableBody from './TableBody';
import TableRow from './TableRow';
import TableCell from './TableCell';

import styles from './Table.module.scss';
import {
  TableMorePropType,
  TablePropType,
} from '../../interfaces/Table';
import { FiMoreHorizontal } from 'react-icons/fi';
import styled from 'styled-components';

export { default as TableRow } from './TableRow';
export { default as TableCell } from './TableCell';
export const TableMoreIcon = ({ onClick, text }: TableMorePropType) => (
  <button
    className={`btn btn-small btn-icon btn-more ${styles.more}`}
    title={text ? text : "More options"}
    onClick={onClick}
  >
    <FiMoreHorizontal width="1rem" />
  </button>
);

const Table = ({
  simple,
  withMargin,
  children,
  className,
  ...rest
}: TablePropType) => {
  const classes = classnames(className, 'table-responsive', styles.table, {
    [styles.simple]: simple,
    [styles.withMargin]: withMargin,
  });

  return (
    <TableWrapper>
      <TableStyle {...rest} className={classes}>
        {children}
      </TableStyle>
    </TableWrapper>
  );
};

Table.Head = TableHead;
Table.Body = TableBody;
Table.Row = TableRow;
Table.Cell = TableCell;
Table.More = TableMoreIcon;

Table.propTypes = {
  /**
   * Apply simple and basic design
   */
  simple: PropTypes.bool,
};


const TableWrapper = styled.div`
    display: block;
  	width: 100%;
    height: auto;
    overflow-y: hidden;
    overflow-x: auto;
`;

const TableStyle = styled.table`
  	width: 100%;
    //box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.05);
    border-radius: 5px;
    border-collapse: collapse;
    //border: 1px solid #e3e8ee;
    transition: opacity 200ms cubic-bezier(0.4, 0, 0.2, 1);

    thead{
      border-collapse: separate;
    }

    /* tr{
      &:hover{
        background-color: red;
      }
    } */
 
`;
export default Table;
