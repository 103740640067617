import React, { Fragment } from 'react'
import classnames from 'classnames'
import { ButtonPropsType } from '@ekenta/interfaces'
import styles from './Button.module.scss'
import Loader from '../Loader/Loader'

const Button = ({
  as = 'default',
  prefixIcon,
  suffixIcon,
  onClick,
  name,
  className,
  bgColor,
  textColor,
  bgVariant,
  isLoadingText,
  isLoading,
  textVariant,
  uppercase,
  width = 'w-auto',
  maxWidth = '150px',
  style,
  type = 'button',
  disabled = false,
  defaultClass = true,
  shadow = true,
  children,
  ...rest
}: ButtonPropsType) => {
  const mainClass = classnames({
    [styles.BtnPrimary]: bgVariant === 'primary' ? true : false,
    [styles.BtnLight]: bgVariant === 'light' ? true : false,
    [styles.BtnSecondary]: bgVariant === 'secondary' ? true : false,
    [styles.BtnSuccess]: bgVariant === 'success' ? true : false,
    [styles.BtnDanger]: bgVariant === 'danger' ? true : false,
    [styles.TextLight]: textVariant === 'light' ? true : false,
    [styles.TextPrimary]: textVariant === 'primary' ? true : false,
    [styles.TextSecondary]: textVariant === 'secondary' ? true : false,
    [styles.TextDark]: textVariant === 'dark' ? true : false,
    [styles.TextSuccess]: textVariant === 'success' ? true : false,
    [styles.TextDanger]: textVariant === 'danger' ? true : false,
    [styles.BtnUppercase]: uppercase,
    [styles.btn]: defaultClass,
    [styles.AppButton]: defaultClass && as === 'default',
    [styles.Shadow]: shadow,
    [styles.Disabled]: disabled,
    [width]: width,
    [styles.TextButton__Style]: defaultClass,
    [styles.TextButton__Style]: as === 'text',
    [styles.OutlineButton__Style]: as === 'outline',
    [styles.LinkButton__Style]: as === 'link',
    [styles.RoundedButton__Style]: as === 'rounded',
    [styles.IconButton__Style]: as === 'icon',
  })

  return (
    <button
      {...rest}
      onClick={onClick}
      type={type}
      disabled={disabled}
      className={classnames('Button btn-icon', mainClass, className)}
      style={{
        backgroundColor: bgColor,
        color: textColor,
        maxWidth: `${maxWidth} !important`,
        ...style,
      }}
    >
      {children ? (
        <>{children}</>
      ) : (
        <span
          className={classnames(
            styles.Button__Flex_Center,
            'Button__icon gap-1'
          )}
        >
          {isLoading ? (
            <Fragment>
              <Loader color={`${as === 'text' ? 'gray' : 'white'}`} />
              <span
                className={classnames(styles.Button__Name, 'BtnSpan', {
                  [styles.ButtonName_Font]: as !== 'text',
                  [styles.ButtonName_FontLight]: as === 'text',
                })}
              >
                {isLoadingText ? isLoadingText : 'Please wait...'}
              </span>
            </Fragment>
          ) : (
            <Fragment>
              {prefixIcon}
              <span
                className={classnames(styles.Button__Name, 'BtnSpan', {
                  [styles.ButtonName_Font]: as !== 'text',
                  [styles.ButtonName_FontLight]: as === 'text',
                })}
              >
                {name}
              </span>
            </Fragment>
          )}
          {suffixIcon}
        </span>
      )}
    </button>
  )
}

export default Button
