/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react'
import { Button, CardBox, Typography } from '@ekenta/components';
import SVG from "react-inlinesvg";
import outlineCheck from "@ekenta/assets/icons/outline-check.svg";

import "./AddressListItem.scss";

const AddressListItem = (props: any) => {
    const { item, setEdit, handleRemove } = props;
    return (
        <CardBox
            className="CardBox_AddressList">
            <div className="AddressItem_Typography">
                <Typography
                    as="span"
                    size="smallest"
                    className="mb-3"
                    color="grey"
                    uiText>
                    {item.first_name} {item.last_name}
                </Typography>
                <Typography
                    as="p"
                    size="base"
                    color="dark"
                    className="Address_Para"
                    uiText>
                    {item.home_address}
                </Typography>
                {item.is_default ? (
                    <div className="AddressDefault">
                        <SVG src={outlineCheck} />
                        <Typography
                            as="span"
                            size="smallest"
                            color="success"
                            uiText>
                            Preferred delivery address
                        </Typography>
                    </div>
                ) : null}
            </div>
            <div className="AddressItem_Button_Group">
                <Button
                    as="text"
                    name="Edit"
                    shadow={false}
                    type="button"
                    className="AddressItem_Button"
                    onClick={() => {
                        setEdit({ show: true, data: item });
                    }}
                />
                <span aria-hidden="true" className="Address_BorderHoz">|</span>
                <Button
                    as="text"
                    name="Remove"
                    shadow={false}
                    type="button"
                    className="AddressItem_Button"
                    onClick={() => handleRemove(item.id)}
                />
            </div>
        </CardBox>
    )
}

export default AddressListItem;