/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState } from 'react'
import * as queryString from 'query-string'
import { VscArrowSmallLeft } from 'react-icons/vsc'
import { useLocation, useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import {
  DataTable,
  HelmetHeader,
  PageTitle,
  Pagination,
  StarRating,
} from '@ekenta/components'
import { DashboardSellers } from '@ekenta/routes/constant'
import { withDashboardLayout } from '@ekenta/hoc'
import { useGetReviewReportQuery } from '@ekenta/redux/store/services/adminSellerApi'
import { useAppSelector } from '@ekenta/redux/store/hook'
import moment from 'moment'

const AdminReviews = () => {
  const query = queryString.parse(window.location.search)
  const _page = query.page
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const { token } = useAppSelector((state: any) => state.auth.authentication)
  const [currentPage, setCurrentPage] = useState(1)
  const { data, isLoading } = useGetReviewReportQuery({ token, currentPage })

  const columns = [
    {
      Header: 'Rating',
      accessor: 'rating',
    },
    {
      Header: 'Review',
      accessor: 'review',
    },
    {
      Header: 'Date',
      accessor: 'created_at',
      disableSortBy: false,
    },
  ]

  const mapData = (data: any) => {
    return data.map((item: any) => ({
      ...item,
      rating: (
        <StarWrapper>
          <StarRating
            name="rating"
            value={parseInt(item.rating)}
            starDimension="25px"
            starSpacing="1px"
            color="#F8880A"
            starHoverColor="#F8880A"
            numberOfStars={5}
          />
        </StarWrapper>
      ),
      review: item.review,
      created_at: moment(item.created_at).format('DD-MM-YYYY'),
    }))
  }

  return (
    <>
      <HelmetHeader
        title="All Reviews Report"
        description="Qlason All Reviews Report Page"
      />
      <Content>
        <PageTitle
          title=" All Review Report"
          subTitle={
            <span
              className="cursor-pointer d-flex w-lg-50 align-items-center 
						text-muted"
              onClick={() => navigate(DashboardSellers)}
            >
              <VscArrowSmallLeft />
              Back to Dashboard
            </span>
          }
        />

        <div>
          <DataTable
            theme="simple"
            data={data ? mapData(data.reviews?.data) : []}
            loading={isLoading}
            isSelectable={true}
            showChecklist={false}
            columns={columns}
            showPagination={true}
            hasLink={true}
            thClassName="w-25"
            trClassName="w_Tr"
            tdClassName={`Row__col`}
            showSearch={false}
            renderPagnation={
              <div className="mt-4">
                {data ? (
                  <Pagination
                    pathname={pathname}
                    itemPerPage={data?.reviews?.per_page}
                    itemsLength={data?.reviews?.total}
                    currentPage={currentPage}
                    queryNo={_page}
                    callback={(value) => setCurrentPage(value)}
                  />
                ) : null}
              </div>
            }
          />
        </div>
      </Content>
    </>
  )
}

const Content = styled.div`
  width: 100%;
  margin: 0 auto;
`

const StarWrapper = styled.div`
  .star-ratings {
    opacity: 1;

    .star-container {
      pointer-events: none !important;
    }
  }
`

export default withDashboardLayout(AdminReviews)
