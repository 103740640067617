/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react'
import SVG from 'react-inlinesvg'
import {
  AutoCompleteStyle,
  SearchContainerStyle,
  SearchInputWrapperStyle,
} from './style'
import Field from '../InputField/Field/Field'
import { SearchIcon, SvgArrowUpLeft } from '../icons'
import SearchSvg from '@ekenta/assets/icons/search.svg'
import { productBaseUrl } from '@ekenta/routes/constant'
import Scrollbars from 'react-custom-scrollbars'
import { useNavigate } from 'react-router-dom'
import classnames from 'classnames'
import Loader from '../Loader/Loader'
import { slugifyText } from '@ekenta/utils'

const SearchBar = ({
  renderPrefix,
  renderSuffix,
  value,
  autoCompleteData,
  name,
  isError,
  isLoading,
  isSuccess,
  onChange,
  showAutocomplete,
  setShowAutocomplete,
  onKeyUp,
  category,
  setValue,
}: any) => {
  const navigate = useNavigate()
  const ref: React.RefObject<HTMLInputElement> = React.createRef()

  const enterHandler = (e: { keyCode: number }) => {
    if (e.keyCode === 13) {
      //setValue("");
      setShowAutocomplete(false)
    }
  }

  const handleNavigation = (
    category: any,
    name: string,
    slug: string,
    id: string
  ) => {
    if (category !== 'products') {
      const urlSlug =
        '/search-result?q=' + name + '&category=' + category + '&uid=' + id
      window.location.href = urlSlug
    } else {
      // navigate({
      //   pathname: `${productBaseUrl}/${slug}`
      // });
      window.location.href = `${productBaseUrl}/${slug}`
    }
  }

  const autoCompleteContainer = (data: any) => {
    return (
      <div className="SearchAutocomplete_Style">
        <Scrollbars style={{ width: '100%', height: '480px' }}>
          <div className="SearchAutocomplete__fieldContainer__Style">
            <ul className="SearchAutocomplete__List_Style">
              {category === 'products' ? (
                <li className="SearchAutocomplete__List__val_Style">
                  <li className={'SearchAutocomplete_Group__Style'}>
                    <h5>Suggestions</h5>
                  </li>
                  <a
                    className="SearchAutocomplete__List__href__Style"
                    onClick={() => {
                      setShowAutocomplete(false)
                      navigate(
                        '/search-result?q=' + value + '&category=' + category
                      )
                    }}
                  >
                    <span className="SearchAutocomplete__List__icon_Style">
                      <SVG src={SearchSvg} />
                    </span>
                    <span className="SearchAutocomplete__List__value">
                      {value}
                    </span>
                  </a>
                </li>
              ) : null}
              <li
                className={classnames('SearchAutocomplete_Group__Style', {
                  SearchAutoComplete_Margin_Style: category === 'products',
                })}
              >
                <h5>{category}</h5>
              </li>
              {category !== 'all' ? (
                <li className="SearchAutocomplete_Content_li_Style">
                  <ul className="SearchAutocomplete_Content_Sublist__Style">
                    {isError || isLoading ? (
                      <li className="SearchAutocomplete__Loading__Style">
                        <Loader />
                      </li>
                    ) : null}

                    {isSuccess && data ? (
                      data.length > 0 ? (
                        data.map((item: any) => {
                          const name = item.name.toLowerCase()
                          const _slugProduct = item?.id
                            ? slugifyText(item?.name.toLowerCase(), item?.id)
                            : '/'
                          return (
                            <li
                              key={item.id}
                              className="SearchAutocomplete__List__item_Style"
                            >
                              <a
                                aria-label="Select Search item"
                                data-title={name}
                                className={classnames(
                                  'SearchAutocomplete__item_href',
                                  {
                                    ['SearchAutocomplete_Padding']:
                                      category !== 'products',
                                  }
                                )}
                                onClick={() => {
                                  setValue('search', '')
                                  setShowAutocomplete(false)
                                  handleNavigation(
                                    category,
                                    name,
                                    _slugProduct,
                                    item?.id
                                  )
                                }}
                              >
                                {category === 'products' ? (
                                  item.images.length > 0 ? (
                                    <img
                                      className="SearchAutocomplete__Img"
                                      src={item.images[0]?.image_url}
                                    />
                                  ) : null
                                ) : null}
                                <span className="SearchAutocomplete__item_href__Span">
                                  {name}
                                </span>
                                <SvgArrowUpLeft className="SearchAutocomplete__item_href__Svg" />
                              </a>
                            </li>
                          )
                        })
                      ) : (
                        <li className="SearchAutocomplete_noResult__Style">
                          no results
                        </li>
                      )
                    ) : null}
                  </ul>
                </li>
              ) : null}

              {category === 'all' ? (
                <li className="SearchAutocomplete_Content_li_Style">
                  <ul className="SearchAutocomplete_Content_Sublist__Style">
                    {isError || isLoading ? (
                      <li className="SearchAutocomplete__Loading__Style">
                        <Loader />
                      </li>
                    ) : null}
                    <li
                      className={classnames('SearchAutocomplete_Group__Style', {
                        ['SearchAutoComplete_Margin_Style']: category === 'all',
                      })}
                    >
                      <h5>Products</h5>
                    </li>
                    {isSuccess && data ? (
                      data.products.length > 0 ? (
                        data.products.map((item: any) => {
                          const name = item.name.toLowerCase()
                          const _slugProduct = item?.id
                            ? slugifyText(item?.name.toLowerCase(), item?.id)
                            : '/'
                          return (
                            <li
                              key={item.id}
                              className="SearchAutocomplete__List__item_Style"
                            >
                              <a
                                aria-label="Select Search item"
                                data-title={name}
                                className={classnames(
                                  'SearchAutocomplete__item_href',
                                  {
                                    ['SearchAutocomplete_Padding']:
                                      category !== 'products',
                                  }
                                )}
                                onClick={() => {
                                  setValue('search', '')
                                  setShowAutocomplete(false)
                                  handleNavigation(
                                    'products',
                                    name,
                                    _slugProduct,
                                    item?.id
                                  )
                                }}
                              >
                                <img
                                  className="SearchAutocomplete__Img"
                                  src={item.images[0]?.image_url}
                                />
                                <span className="SearchAutocomplete__item_href__Span">
                                  {name}
                                </span>
                                <SvgArrowUpLeft className="SearchAutocomplete__item_href__Svg" />
                              </a>
                            </li>
                          )
                        })
                      ) : (
                        <li className="SearchAutocomplete_noResult__Style">
                          no results
                        </li>
                      )
                    ) : null}
                    <li
                      className={classnames('SearchAutocomplete_Group__Style', {
                        ['SearchAutoComplete_Margin_Style']: category === 'all',
                      })}
                    >
                      <h5>Sellers</h5>
                    </li>
                    {isSuccess && data ? (
                      data.sellers.length > 0 ? (
                        data.sellers.map((item: any) => {
                          const name = item.name.toLowerCase()
                          const _slugProduct = item?.id
                            ? slugifyText(item?.name.toLowerCase(), item?.id)
                            : '/'
                          return (
                            <li
                              key={item.id}
                              className="SearchAutocomplete__List__item_Style"
                            >
                              <a
                                aria-label="Select Search item"
                                data-title={name}
                                className={classnames(
                                  'SearchAutocomplete__item_href',
                                  {
                                    ['SearchAutocomplete_Padding']:
                                      category !== 'products',
                                  }
                                )}
                                onClick={() => {
                                  setValue('search', '')
                                  setShowAutocomplete(false)
                                  handleNavigation(
                                    'seller',
                                    name,
                                    _slugProduct,
                                    item?.id
                                  )
                                }}
                              >
                                <span className="SearchAutocomplete__item_href__Span">
                                  {name}
                                </span>
                                <SvgArrowUpLeft className="SearchAutocomplete__item_href__Svg" />
                              </a>
                            </li>
                          )
                        })
                      ) : (
                        <li className="SearchAutocomplete_noResult__Style">
                          no results
                        </li>
                      )
                    ) : null}
                    <li
                      className={classnames('SearchAutocomplete_Group__Style', {
                        ['SearchAutoComplete_Margin_Style']: category === 'all',
                      })}
                    >
                      <h5>Brands</h5>
                    </li>
                    {isSuccess && data ? (
                      data.brands.length > 0 ? (
                        data.brands.map((item: any) => {
                          const name = item.name.toLowerCase()
                          const _slugProduct = item?.id
                            ? slugifyText(item?.name.toLowerCase(), item?.id)
                            : '/'
                          return (
                            <li
                              key={item.id}
                              className="SearchAutocomplete__List__item_Style"
                            >
                              <a
                                aria-label="Select Search item"
                                data-title={name}
                                className={classnames(
                                  'SearchAutocomplete__item_href',
                                  {
                                    ['SearchAutocomplete_Padding']:
                                      category !== 'products',
                                  }
                                )}
                                onClick={() => {
                                  setValue('search', '')
                                  setShowAutocomplete(false)
                                  handleNavigation(
                                    'brands',
                                    name,
                                    _slugProduct,
                                    item?.id
                                  )
                                }}
                              >
                                <span className="SearchAutocomplete__item_href__Span">
                                  {name}
                                </span>
                                <SvgArrowUpLeft className="SearchAutocomplete__item_href__Svg" />
                              </a>
                            </li>
                          )
                        })
                      ) : (
                        <li className="SearchAutocomplete_noResult__Style">
                          no results
                        </li>
                      )
                    ) : null}
                  </ul>
                </li>
              ) : null}
            </ul>
          </div>
        </Scrollbars>
      </div>
    )
  }

  return (
    <SearchContainerStyle isOpen={showAutocomplete}>
      <div className="SearchAutocomplete___Wrapper">
        {renderPrefix ? (
          <div className="SearchBox__Prefix">{renderPrefix}</div>
        ) : null}
      </div>
      <SearchInputWrapperStyle>
        <div ref={ref} className="SearchBar__FieldDiv">
          <Field
            id="search-field"
            name={name}
            type="text"
            value={value}
            required
            renderPrefix={<SearchIcon />}
            fieldClassName="SearchBar__FieldWrap"
            placeholder={`Search for ${category}..`}
            autoComplete="off"
            onChange={onChange}
            onKeyUp={(e) => {
              onKeyUp && onKeyUp(e)
              enterHandler(e)
            }}
          />
          {showAutocomplete ? (
            <AutoCompleteStyle>
              {autoCompleteContainer(autoCompleteData)}
            </AutoCompleteStyle>
          ) : null}
        </div>
        {renderSuffix ? (
          <div className="SearchBar___Right">{renderSuffix}</div>
        ) : null}
      </SearchInputWrapperStyle>
    </SearchContainerStyle>
  )
}

export default SearchBar
