import React from 'react'
import { Col, Container, Row, Tab, Tabs } from 'react-bootstrap';
import { useAppSelector } from '@ekenta/redux/store/hook';
import ProfileSideBar from '../component/AccountSideBar/AccountSideBar'
import ActiveOrder from './component/ActiveOrder';
import CompleteOrder from './component/CompleteOrder';
import ClosedOrder from './component/ClosedOrder';
import BreadCrumb from '../component/BreadCrumb';
import { HelmetHeader, PageTitle } from '@ekenta/components';
import { withDefaultLayout } from '@ekenta/hoc';
import { useFetchUserAllOrderQuery } from '@ekenta/redux/store/services/accountApi';
import PendingOrder from './component/PendingOrder';
import BackButton from '../component/BackButton';



const BuyerOrders = () => {
    const { token } = useAppSelector(state => state.auth.authentication);
    const { data, isLoading, isError, isSuccess } =
        useFetchUserAllOrderQuery(token, { skip: !token });
    return (
        <>
            <HelmetHeader
                title="Orders"
                description="Qlason Orders Account Page"
            />
            <Container className="account-user-container">
                <><BreadCrumb activePage="Order History" /></>

                <Row className="account-pro-row g-2">
                    <ProfileSideBar isLoading={null} />
                    <Col className="account-profile-col-right">
                        <Col className="mx-1 mx-lg-0">
                            <PageTitle
                                title="Orders"
                                margin={false}
                                subTitle={<BackButton />}
                            />
                        </Col>
                        <div className="buyer__pg_tabs">
                            <Tabs
                                defaultActiveKey="pendingOrder"
                                transition={false}
                                id="noanim-tab"
                                className="mb-3">

                                <Tab eventKey="pendingOrder" title="Pending Orders">
                                    <PendingOrder
                                        data={data}
                                        isLoading={isLoading}
                                        isError={isError}
                                        isSuccess={isSuccess}
                                    />
                                </Tab>
                                <Tab eventKey="activeOrder" title="Active Orders">
                                    <ActiveOrder
                                        data={data}
                                        isLoading={isLoading}
                                        isError={isError}
                                        isSuccess={isSuccess}
                                    />
                                </Tab>
                                <Tab eventKey="completeOrder" title="Complete Orders">
                                    <CompleteOrder
                                        data={data}
                                        isLoading={isLoading}
                                        isError={isError}
                                        isSuccess={isSuccess}
                                    />
                                </Tab>
                                <Tab eventKey="closedOrder" title="Closed Orders">
                                    <ClosedOrder
                                        data={data}
                                        isLoading={isLoading}
                                        isError={isError}
                                        isSuccess={isSuccess}
                                    />
                                </Tab>
                            </Tabs>
                        </div>

                    </Col>
                </Row>
            </Container>
        </>
    )
}

export default withDefaultLayout(BuyerOrders);
4

