/* eslint-disable @typescript-eslint/no-explicit-any */
import { device } from '@ekenta/utils/devices'
import React, { Component } from 'react'
import ImageGallery from 'react-image-gallery'
import styled from 'styled-components'

interface productTypes {
  imageList: any
  images: { original: string; thumbnail: string }[] | null
}

class Gallery extends Component<productTypes> {
  _renderVideo(item: any) {
    return (
      <VideoWrapper className="video-wrapper lite-vid-wrapper">
        <video className="video-component" muted controls autoPlay={true}>
          <source src={item.embedUrl} />
        </video>
      </VideoWrapper>
    )
  }

  render() {
    const { images } = this.props

    const mapMediaDataToSlider = (images: any[]) => {
      if (!images) return []
      if (images.length == 0) return []
      const listImgVid: any[] = []
      images.length > 0 &&
        images.map((item) => {
          if (item.id == 'video') {
            if (item.embedUrl) {
              const videoEmbed = {
                ...item,
                renderItem: this._renderVideo.bind(this),
              }

              listImgVid.push(videoEmbed)
            }
          } else {
            listImgVid.push(item)
          }
        })

      return listImgVid
    }

    const properties = {
      useBrowserFullscreen: false,
      lazyLoad: true,
      showPlayButton: false,
      showBullets: false,
      //renderItem: this.myRenderItem.bind(this),
      items: images ? mapMediaDataToSlider([...images]) : [],
    }

    return (
      <GalleryContainer>
        <ImageGallery thumbnailPosition="bottom" {...properties} />
      </GalleryContainer>
    )
  }
}

const GalleryContainer = styled.div`
  width: 300px;
  position: relative;
  flex: 1;
  border-radius: 12.186px;
  background: #f6f6f6;

  .image-gallery {
    .image-gallery-slide-wrapper {
      display: inline-block;
      width: 100% !important;
      height: 100% !important;
    }

    .image-gallery-swipe {
      width: 100% !important;
      height: 350px !important;
      padding: 0px;

      @media ${device.lg} {
        width: 500px !important;
        height: 450px !important;
      }
    }
  }

  .image-gallery-content .image-gallery-slide .image-gallery-image {
    max-height: 500px;
  }

  .image-gallery-left-nav,
  .image-gallery-right-nav {
    .image-gallery-svg {
      width: 80px !important;
      height: 80px !important;

      @include laptop() {
        width: 100px !important;
        height: 100px !important;
      }

      @include desktop() {
        width: 100px !important;
        height: 100px !important;
      }
    }
  }

  .image-gallery-thumbnails-wrapper.left {
    width: 0px;

    @media ${device.lg} {
      width: 40px !important;
    }
  }

  .image-gallery-thumbnails {
    width: auto !important;
    display: none;
    justify-content: center;
    padding-top: 30px;

    @media ${device.lg} {
      display: flex;
    }
  }

  .image-gallery-thumbnails-container {
    //text-align: left;

    .image-gallery-thumbnail {
      width: 50px !important;
      border: 1px solid rgba(variable.$secondary, $alpha: 0.5) !important;
      margin-left: 0px !important;

      .image-gallery-thumbnail-inner {
        width: 100%;
        height: 50px !important;
        cursor: move;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      &:hover {
        border: 1px solid var(--eke-border-primary-light-color) !important;
      }

      &:focus {
        border: 1px solid var(--eke-border-primary-light-color) !important;
      }
    }

    .image-gallery-thumbnail.active {
      outline: none;
      border: 1px solid var(--eke-border-primary-color) !important;
    }
  }

  /* .image-gallery {
    .image-gallery-slide-wrapper {
      display: inline-block;
      width: 100% !important;
      height: 100% !important;

      .image-gallery-swipe {
        width: 100% !important;
        height: 350px !important;
        padding: 0px;

        @include laptop() {
          width: 500px !important;
          height: 450px !important;
        }

        .image-gallery-slides {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;

          .image-gallery-slide {
            img {
              width: 100%;
              height: 350px;
              object-fit: contain;
              margin: 0 auto;
              transition: all 0.4s ease-in-out;

              @include laptop() {
                width: 450px;
                height: 450px;
                object-fit: cover;
              }

              @include desktop() {
                width: 450px;
                height: 450px;
                object-fit: cover;
              }
            }
          }
        }
      }

      .image-gallery-icon {
        filter: drop-shadow(0 0.4px 0.4px #1a1a1a) !important;

        &:hover {
          .image-gallery-svg {
            stroke: var(--eke-bg-primary-light-color) !important;
          }
        }
      }
      .image-gallery-left-nav,
      .image-gallery-right-nav {
        .image-gallery-svg {
          width: 80px !important;
          height: 80px !important;

          @include laptop() {
            width: 100px !important;
            height: 100px !important;
          }

          @include desktop() {
            width: 100px !important;
            height: 100px !important;
          }
        }
      }
    }

    .image-gallery-thumbnails-wrapper.left {
      width: 0px;

      @include laptop() {
        width: 40px !important;
      }

      @include desktop() {
        width: 40px !important;
      }
    }

    .image-gallery-thumbnails {
      width: auto !important;
      display: none;

      @include laptop() {
        display: flex;
      }

      @include desktop() {
        display: flex;
      }
    }

    .image-gallery-thumbnails-container {
      //text-align: left;

      .image-gallery-thumbnail {
        width: 50px !important;
        border: 1px solid rgba(variable.$secondary, $alpha: 0.5) !important;
        margin-left: 0px !important;

        .image-gallery-thumbnail-inner {
          width: 100%;
          height: 50px !important;
          cursor: move;

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }

        &:hover {
          border: 1px solid var(--eke-border-primary-light-color) !important;
        }

        &:focus {
          border: 1px solid var(--eke-border-primary-light-color) !important;
        }
      }

      .image-gallery-thumbnail.active {
        outline: none;
        border: 1px solid var(--eke-border-primary-color) !important;
      }
    }
  } */
`
const VideoWrapper = styled.div`
  width: 100%;
  height: 100%;
  border-radius: 12.186px;
  background-color: #eee;

  video {
    width: auto;
    height: 350px;
    cursor: pointer;

    @media ${device.lg} {
      width: 500px;
      height: 450px;
    }
  }
`

export default Gallery
