import React, { Fragment } from 'react';
import styles from "./CardBox.module.scss";
import Typography from '../Typography/Typography';
import classnames from 'classnames';

interface CardBoxPropsType {
    title?: string;
    children: React.ReactNode;
    shadow?: boolean;
    className?: string;
    render?: React.ReactNode;
    margin?: "top" | "bottom" | "none";
    role?: string;
    border?: boolean;
    padding?: boolean;
    onClick?: () => void;

}

const CardBox: React.FC<CardBoxPropsType> = (props) => {
    const { title, children, render, padding = true, role, shadow = true, border = false, margin = "bottom", onClick } = props;


    const classes = classnames({
        [styles.Shadow]: shadow && !border,
        [styles.CardBox_Border]: border,
        [styles.CardBox_Pad]: padding
    })

    return (
        <div role={role} className={classnames(styles.CardBox_Container, classes, props.className)} onClick={onClick}>
            {title ? (
                <div className={styles.CardBox_FlexAlign}>
                    <Typography
                        as="h5"
                        size="small"
                        color="light"
                        margin={margin}
                        className={styles.CardBox_Typography}
                        heading>
                        {title}
                    </Typography>
                    {render && render}
                </div>

            ) : null}
            <Fragment>
                {children}
            </Fragment>
        </div>
    )
}

export default CardBox;