import React, { useEffect, useState } from 'react';
import {
  Button,
  Card,
  Col,
  Modal,
  Row,
  Form,
} from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import DashboardComponent from '../../component/DashboardComponent';
import DashboardLayout from '../../../../hoc/Layout/Dashboard-Layout';
import { useAppSelector } from '../../../../redux/store/hook';
import { FaArrowLeft } from 'react-icons/fa';
import { useNavigate, useParams } from 'react-router';
import { AdminDashboardSubCollectionCategoriesPathUrl } from '../../../../routes/constant';
import sent from '@ekenta/assets/images/sent.gif';
import {
  useEditSubCollectionCategoryMutation,
  useFetchAllActiveCollectionsQuery,
  useFetchSingleSubCollectionCategoryQuery,
} from '../../../../redux/store/services/collectionApi';
import { BASE_URL } from '@ekenta/constants';
import axios from 'axios';

export default function EditSubCollectionCategory({
  collapsed,
  toggled,
  handleToggleSidebar,
  handleCollapsedChange,
}: any) {
  const [lgShow, setLgShow] = useState(false);
  const { ek_isAuth, token } = useAppSelector(
    (state) => state.auth.authentication,
  );
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  // const result = useFetchAdminAllCollectionsQuery();
  const result = useFetchAllActiveCollectionsQuery();

  const [
    editCollectionData,
    {
      data: editCollection_data,
      isLoading: editCollection_isLoading,
      isError: editCollection_isError,
      error: editCollection_error,
      isSuccess: editCollection_isSuccess,
    },
  ] = useEditSubCollectionCategoryMutation();

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const params = useParams();
  const fetchSubCollection = useFetchSingleSubCollectionCategoryQuery(
    params?.id,
  );

  const [details, setDetails] = useState({
    name: '',
    description: '',
    visibility: 'false',
    thumbnailUrl: '',
    file: '',
    collectionId: '',
  });
  const handleImage = (e: any) => {
    // console.log(e.target.files[0])
    setDetails({ ...details, file: e.target.files[0] });
  };

  const handleSubmit = async () => {
    setLoading(true);
    console.log(details);
    // const data = {
    //   name: details?.name,
    //   description: details?.description,
    //   visibility: details?.visibility == 'true' ? true : false,
    //   collection_id: parseInt(details?.collectionId),
    // };
    // editCollectionData({ subCollectionCategoryId: params.id, data, token });

    const data = new FormData();
    data.append('name', details?.name);
    data.append('description', details?.description);
    data.append('visibility', details?.visibility);
    data.append('image', details?.file);
    data.append('collection_id', details?.collectionId);

    const config = {
      method: 'POST',
      url: `${BASE_URL}/api/admin/update-sub-collection-category/${params.id}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        console.log(JSON.stringify(response.data));
        handleShow();
        setLoading(false);
      })
      .catch(function (error) {
        console.log(error);
        setLoading(false);
      });
  };

  const uploadThumbnail = async () => {
    // await axios.get(`${BASE_URL}/api/sub-collection-category/thumbnail/${editCollection_data?.id}`).then((resp:any)=>{
    //     setLoading(false)
    // }).catch(function (error:any) {
    //     console.log(error)
    // });
  };

  useEffect(() => {
    if (editCollection_isSuccess) {
      uploadThumbnail();
      handleShow();
    }

    if (editCollection_isError) {
      setLoading(false);
      //toast.error(<div className="cus_t">An error occured !</div>);
      //toastDisplay(true);
    }

    if (fetchSubCollection?.isSuccess) {
      setDetails({
        ...details,
        name: fetchSubCollection?.data?.sub_collection_category?.name,
        description: fetchSubCollection?.data?.sub_collection_category?.description,
        visibility: fetchSubCollection?.data?.sub_collection_category?.visibility,
        thumbnailUrl: fetchSubCollection?.data?.sub_collection_category?.thumbnailUrl,
        file: '',
        collectionId: fetchSubCollection?.data?.sub_collection_category?.collection_id,
      });
    }

    // navigate(adminManageBanner)
  }, [
    editCollection_isSuccess,
    editCollection_isError,
    fetchSubCollection?.isSuccess,
  ]);

  return (
    <DashboardLayout>
      <Helmet>
        <title>Admin Dashboard - Qlason</title>
        <meta charSet="utf-8" />
        <meta name="title" content="QlasonOrders Dashboard Page" />
        <meta name="description" content="QlasonOrders Dashboard  Page" />
        <meta property="og:locale" content="en_NG" />
        <meta name="robots" content="noindex,follow" />
      </Helmet>

      <DashboardComponent
        collapsed={collapsed}
        toggled={toggled}
        handleToggleSidebar={handleToggleSidebar}
        handleCollapsedChange={handleCollapsedChange}
      >
        <Col className=" col-lg-11 mx-auto _listing_product_">
          <div className="mt-0">
            <h3 className="display_lg_header">
              {/* <FiChevronLeft /> */}
              Edit Sub-Collection Category
            </h3>
            <Col className="mt-3">
              <Button
                className="mb-3 text-white"
                onClick={() =>
                  navigate(AdminDashboardSubCollectionCategoriesPathUrl)
                }
              >
                <FaArrowLeft /> Back
              </Button>
              <Card>
                <Card.Body>
                  <Form.Label htmlFor="inputPassword5">
                    Sub-Collection Category Name *{' '}
                  </Form.Label>
                  <Form.Control
                    type="text"
                    value={details?.name}
                    onChange={(e) =>
                      setDetails({ ...details, name: e.target.value })
                    }
                  />
                  <br />
                  <Form.Label htmlFor="inputPassword5">
                    Description *{' '}
                  </Form.Label>
                  <Form.Control
                    type="text"
                    value={details?.description}
                    onChange={(e) =>
                      setDetails({ ...details, description: e.target.value })
                    }
                  />
                  <br />
                  <Form.Label htmlFor="inputPassword5">
                    Thumbnail Image *
                  </Form.Label>
                  <Form.Control
                    type="file"
                    // value={details?.thumbnailUrl}
                    onChange={(e) => handleImage(e)}
                  />
                  <br />
                  <Form.Label htmlFor="inputPassword5">Visibility</Form.Label>
                  <Form.Select
                    value={details?.visibility?.toString()}
                    onChange={(e) =>
                      setDetails({ ...details, visibility: e.target.value })
                    }
                  >
                    <option>Select Visibility</option>
                    <option value={'true'}>True</option>
                    <option value={'false'}>False</option>
                  </Form.Select>
                  <br />
                  <br />
                  <Form.Label htmlFor="inputPassword5">Collection *</Form.Label>
                  <Form.Select
                    value={details?.collectionId}
                    onChange={(e) =>
                      setDetails({ ...details, collectionId: e.target.value })
                    }
                  >
                    <option>Select Collection</option>
                    {!fetchSubCollection.isSuccess ? (
                      <option>Loading...</option>
                    ) : (
                      fetchSubCollection?.data?.collections.map((e: any, i: any) => (
                        <option key={i} value={e?.id}>
                          {e?.name}
                        </option>
                      ))
                    )}
                  </Form.Select>
                  <br />
                  <Button
                    variant="danger"
                    disabled={
                      loading ||
                      details?.collectionId == '' ||
                      details?.name == '' ||
                      details?.description == ''
                    }
                    onClick={handleSubmit}
                  >
                    {loading ? 'Loading...' : 'Submit'}
                  </Button>{' '}
                  <Button
                    variant="outline-danger"
                    onClick={() =>
                      navigate(AdminDashboardSubCollectionCategoriesPathUrl)
                    }
                  >
                    Cancel
                  </Button>
                </Card.Body>
              </Card>
            </Col>
          </div>
        </Col>

        <Modal
          show={show}
          onHide={handleClose}
          size="sm"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Body>
            <Row>
              <img src={sent} alt="" height={300} width={'100%'} />
              <Button
                variant="success"
                className="text-white text-center mx-auto"
                onClick={() =>
                  window.location.href = AdminDashboardSubCollectionCategoriesPathUrl
                }
              >
                Done
              </Button>
            </Row>
          </Modal.Body>
        </Modal>
      </DashboardComponent>
    </DashboardLayout>
  );
}
