/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react'
import { withDefaultLayout } from '@ekenta/hoc';
import { CustomAlert, HelmetHeader, RefreshLink } from '@ekenta/components';
import liveGIF from '@ekenta/assets/images/animate-livestream.gif';
import liveAvatar from '@ekenta/assets/images/live_avatar1.png';
import defaultImg from "@ekenta/assets/images/default_livestream.webp";
import SectionCard from './common/Sectioncard';
import { HomeBreadCrumb } from './common/BreadCrumb';
import { Container, HomeStyle } from './styles';
import styled from 'styled-components';
import InformationCard from './common/InformationCard';
import { ExchangeDescription, LiveDescription, TermsDescription } from './common/common';
import { useFetchAllLiveSellersQuery } from '@ekenta/redux/store/services/livecommerceApi';
import { singLiveBaseUrl } from '@ekenta/routes/constant';
import EmptyLive from './common/Livecommerce/EmptyLive';
import { AllLiveCardLoader } from './common/Livecommerce/AllLiveLoader';
import { device } from '@ekenta/utils/devices';

const AllLiveCommerce = () => {
	const getResult = useFetchAllLiveSellersQuery();

	const mapAllLiveSellers = (data: any) => {
		return data.merchant_business.length > 0 ? (
			data.merchant_business.slice(0, 6).map((result: any, index: number) => {
				return (
					<LiveCardStyle avatar={liveAvatar} liveGIF={liveGIF} key={index}>
						<RefreshLink to={`${singLiveBaseUrl}/spm-${result.id}?type=isLive`}>
							<div className="ImageGroup__Style" style={{ height: '200px' }}>
								<img className="_tim"
									data-creative={result.merchant_business?.thumbnail_url ? result.merchant_business?.thumbnail_url : defaultImg} data-position="live_1_1"
									src={result.merchant_business?.thumbnail_url ? result.merchant_business?.thumbnail_url : defaultImg}
									alt="" />
							</div>
							<div className="Details__Style">
								<div className="Left_Detail__Style">
									<div className="Mini-Image__Style"></div>
									<span className="active__Indicator" />
								</div>
								<div className="Right_Detail__Style">
									<h5>{result.merchant_business?.business_name}</h5>
									<p>Tap to watch my live videos</p>
								</div>
							</div>
							<div className="animate__Like_Lott">
							</div>
						</RefreshLink>
					</LiveCardStyle>
				);
			})
		) : (
			<EmptyLive />
		);
	};

	return (
		<>
			<HelmetHeader
				title="Live Commerce Deals at Best Price"
				description="Qlason Live Commerce Deals"
			/>
			<HomeStyle>
				<Container>
					<HomeBreadCrumb
						activePage="Qlason Live Commerce"
					/>
					<SectionCard
						title="Qlason Live Commerce"
						size="">
						{getResult?.isError && (
							<>
								<CustomAlert
									variant="danger"
									show={true}
									message="Unable to load livecommerce"
								/>
								<AllLiveCardLoader />
							</>
						)}
						<ContentStyle>
							{getResult?.isLoading ? (
								<AllLiveCardLoader />
							) : (
								<>
									{getResult?.isSuccess && getResult?.data ? (
										<>{mapAllLiveSellers(getResult?.data)}</>
									) : null}
								</>
							)}
						</ContentStyle>
						<br />
						<br />
					</SectionCard>
					<div>
						<InformationCard
							title="30 Days Free Exchange for Apparels and Shoes"
							description={<ExchangeDescription />}
						/>
						<InformationCard
							title="24/7 Livestream Pharmacy Consulting Services on E-commerce"
							description="Please carefully read the following terms and conditions regarding our 24/7 Livestream Pharmacy Consulting Services on our e-commerce platform. These terms outline the guidelines and procedures for utilizing our livestream consulting services"
						/>
						<InformationCard
							title="24/7 Livestream Shopping Experience"
							description={<LiveDescription />}
						/>
						<InformationCard
							title="Trade-Ins on our E-commerce Website"
							description={<TermsDescription />}
						/>
					</div>
				</Container>
			</HomeStyle>
		</>

	)
}


const ContentStyle = styled.div`
	width: 100%;
	height: auto;
    display: flex;
	flex-wrap: wrap;
	align-items: center;
	gap: 0.6em!important;
  margin-top: 0 !important;

  @media ${device.lg}{
	justify-content: flex-start;

	}

.product__card__col {
	display: block;
    padding: 0 !important;
    border-radius: 10px !important;
	width: 46% !important;
		
	@media ${device.md}{
		width: 30% !important;
	}
	
	@media ${device.lg}{
		width: 214px !important;
	}
}
`;

const LiveCardStyle = styled.div<{ liveGIF?: string; avatar: string; }>`
	position: relative;
	width: 275px;
	height: auto;
	z-index: 1 !important;
	flex-shrink: 0;


	a{
		display: flex;
		align-items: center;
		flex-direction: column;
		gap: 10.157px;
		padding: 15.157px 13.5px 13.157px;
		border-radius: 18.157px;
		background: var(--eke-color-white);
		box-shadow: 0px 4px 60px 0px rgba(92, 107, 173, 0.10);
		text-decoration: none !important;
	}

	.ImageGroup__Style{
		width: 100%;
        min-height: 150px;
		border-radius: 18.157px;
		background: var(--background-blue, #F5FBFF);

		img{
			width: 100%;
            height: 100%;
            border-radius: 18.157px;
            object-fit: cover;
            object-position: 50% 50%;
		}
	}

	.Details__Style{
		width: 100%;
		height: 50px;
		display: flex;
		align-items: center;
		gap: 12px;
		z-index: 1;

		.Left_Detail__Style{
			position: relative;
			width: 48px;
			height: 48px;
			justify-content: center;
			align-items: center;
			border-radius: 200px;

			.Mini-Image__Style{
				width: 100%;
				height: 100%;
				display: block;
			    border-radius: 200px;
		       background: ${props => props.avatar ? `url(${props.avatar}) no-repeat` : 'lightgray'};
			   background-position: center;
			   background-size: cover;
			   background-color: #eee;
			}

			.active__Indicator{
				position: absolute;
				right: 0.467px;
				bottom: 0.867px;
				width: 12px;
				height: 12px;
				flex-shrink: 0;
				border-radius: 6px;
				border: 1.5px solid var(--eke-color-white);
				background: var(--eke-color-success-500);
				margin-top: -3px;
			}
		}

		.Right_Detail__Style{
			flex: 1;
			width: auto;

			h5{
				color: var(--greyscale-900, #212121);
				font-size: 16px;
				font-family: var(--eke-body-font-family);
				font-weight: var(--eke-font-weight-bold);
				line-height: 140%;
				letter-spacing: 0.2px;
				margin: 0 !important;
			}
			p{
				margin: 0 !important;
				color: var(--greyscale-500, #9E9E9E) !important;
				font-size: 12px;
				font-family: var(--eke-body-font-family);
				font-weight: var(--eke-font-weight-medium);
				line-height: normal;
				letter-spacing: 0.2px;
			}
		}

		
	}

	.animate__Like_Lott{
		position: absolute;
		right: 0.467px;
		bottom: 17.867px;
		width: 84.533px;
		height: 125.446px;
		display: block;
		background: ${props => props.liveGIF ? `url(${props.liveGIF}) center/cover no-repeat` : 'lightgray'};
	}


`;


export default withDefaultLayout(AllLiveCommerce);