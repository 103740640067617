import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { createFormDataMutationRequest, createMutationRequestWithoutBody, createQueryRequest, createQueryWithTokenRequest } from '.';
import { BASE_URL } from '../../../constants/index';


export const liveCommerceApi: any = createApi({
    reducerPath: 'liveCommerceApi',
    baseQuery: fetchBaseQuery({ baseUrl: BASE_URL }),
    tagTypes: ['IsLive', 'isEkeLive'],
    refetchOnMountOrArgChange: true,
    endpoints: (builder) => ({
        fetchAllSellerProduct: builder.query({
            query: (sellerId) => createQueryRequest({ url: `/api/seller-product/${sellerId}`, method: 'GET' })
        }),

        // Fetch all Seller filtered by collection for ekemarket
        fetchProductFilteredByCollection: builder.query<void, string>({
            query: () => createQueryRequest({ url: `/api/product/s`, method: 'GET' }),
        }),
        setSellerGoLiveStatus: builder.mutation<void, { isLive: boolean; token: string; }>({
            query: ({ isLive, token }) => createMutationRequestWithoutBody({ url: `/api/seller/update-seller-live-status?is_live=${isLive}`, method: 'PUT', token: token }),
            invalidatesTags: ['IsLive'],
        }),
        addThumbnailToSellerLive: builder.mutation<void, { merchantOwnerId: string; data: any, token: string; }>({
            query: ({ data, token }) => createFormDataMutationRequest({ url: `/api/seller/update-thumbnail-url`, method: 'POST', body: data, token: token }),
            invalidatesTags: ['IsLive'],
        }),
        fetchSellerLiveCode: builder.query<void, { merchantId: string; token: string; }>({
            query: ({ merchantId, token }) => createQueryWithTokenRequest({ url: `/api/get-seller-live-details/${merchantId}`, method: 'GET', token: token })
        }),
        fetchAllLiveSellers: builder.query({
            query: () => createQueryRequest({ url: `/api/all-sellers-live`, method: 'GET' }),
            providesTags: ['IsLive'],
        }),
        fetchAllLiveIKitchenSellers: builder.query({
            query: () => createQueryRequest({ url: `/api/all-ikitchen-sellers-live`, method: 'GET' }),
            providesTags: ['IsLive'],
        }),
        fetchAllLiveSellersOnEke: builder.query({
            query: () => createQueryRequest({ url: `/api/all-sellers-eke-live`, method: 'GET' }),
            providesTags: ['isEkeLive'],
        }),
        getCurrentMarketDay: builder.query({
            query: () => createQueryRequest({ url: `/api/get-active-market-day`, method: 'GET' }),
        }),
        setSellerToEkeLive: builder.mutation<void, { isEkeLive: boolean, token: string; }>({
            query: ({ isEkeLive, token }) => createMutationRequestWithoutBody({ url: `/api/set-seller-live-ekemart-status?is_live=${isEkeLive}`, method: 'PUT', token: token }),
            invalidatesTags: ['isEkeLive'],
        }),

    })
});

export const {
    useFetchAllSellerProductQuery,
    useFetchSellerLiveCodeQuery,
    useFetchAllLiveSellersQuery,
    useFetchAllLiveSellersOnEkeQuery,
    useFetchProductFilteredByCollectionQuery,
    useSetSellerGoLiveStatusMutation,
    useAddThumbnailToSellerLiveMutation,
    useSetSellerToEkeLiveMutation,
    useFetchAllLiveIKitchenSellersQuery,
    useGetCurrentMarketDayQuery
} = liveCommerceApi;

