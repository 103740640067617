import React from 'react'
import styled from 'styled-components'
import { ReactComponent as Stars } from '../icons/stars.svg'
import { formatCurrency } from '@ekenta/utils'
import { AddToCart, QuantityBtn } from '@ekenta/components'
//import { selectProductQuantityAndId } from '@ekenta/redux/store/features/cart/cartSlice'
import { ProductType } from '@ekenta/redux/store/features/product/productDataType'
import { useAppSelector } from '@ekenta/redux/store/hook'
import {
  CartItem,
  selectCartItems,
} from '@ekenta/redux/store/features/cart/cartSlice'
interface PropType {
  product: ProductType
}

const ProductInfo: React.FC<PropType> = (props) => {
  const { product } = props
  const cartItems = useAppSelector(selectCartItems)
  const cartItem = cartItems?.find((item: CartItem) => {
    return item.product?.id?.toString(), product?.id?.toString()
  })
  const cartItemId = cartItem?.id
  const quantity = cartItem?.quantity ?? 0
  const currentQty = cartItem?.quantity === 0 ? 1 : quantity
  const stock = product?.stock ? product?.stock + 1 : 0
  return (
    <Container>
      <Box>
        <h1>{product?.name?.toLowerCase()}</h1>
        <HStack>
          <Stars />
          <div>
            <p>
              {product?.rating || 0} ({product?.ratings?.length || 0} reviews)
            </p>
          </div>
        </HStack>
      </Box>
      <Box>
        <h5>Quantity</h5>
        <HStack>
          <QuantityBtn
            className="counter"
            stock={stock}
            quantity={currentQty}
            disabled={quantity < 1}
            allowHover={false}
            showDelete={true}
            initialValue={0}
            cartItemId={cartItemId}
          />
        </HStack>
      </Box>

      <Box>
        <h5>Total price</h5>
        <div className="price-hstack xhorizontal">
          <span className="xlarge-price">
            {formatCurrency(product?.price || 0)}
          </span>
          {Number(product?.discount) > 0 ? (
            <span className="small-price">
              {formatCurrency(product?.max_price || 0)}
            </span>
          ) : null}
        </div>
      </Box>
      <Box className="button-group  mt-2">
        <AddToCart
          buttonName="Add to Cart"
          productName={product?.name}
          price={product?.price}
          productId={product?.id}
          stock={product?.stock}
          productVariation={product?.productVariation}
          is_variant={product?.is_variant}
          allowHover={false}
          className="add-cart cart-btn"
        />
      </Box>
      <Box>
        <div className="delivery--note">
          <h5>Delivery Charges</h5>
          <p>
            Select a Delivery agency of your choice at checkout to see delivery
            charges. Deliver to all state in Nigeria
          </p>
        </div>
      </Box>
    </Container>
  )
}

const Container = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 18px;
`
const Box = styled.div`
  width: 100%;
  font-family: var(--eke-body-font-family);

  h1 {
    color: var(--greyscale-900, #212121);
    font-size: 27px;
    font-style: normal;
    text-transform: capitalize;
    font-weight: 700;
    line-height: 120%; /* 38.4px */
  }

  h5 {
    color: var(--greyscale-900, #212121);
    font-family: var(--eke-body-font-family);
    font-size: 16px;
    font-weight: 600;
    line-height: 120%; /* 21.6px */
    margin-bottom: 12px;
  }

  .button-group {
    display: flex;
    align-items: center;
    gap: 24px;
  }

  .price-hstack {
    display: flex;
    align-items: center;
    gap: 20px;
    font-family: var(--eke-body-font-family);
    span.xlarge-price {
      color: var(--greyscale-900, #212121);
      font-size: 27px;
      font-weight: 700;
      line-height: 120%; /* 45.197px */
    }
    span.small-price {
      color: var(--greyscale-800, #424242);
      font-size: 22px;
      font-weight: 600;
      line-height: 120%; /* 38.4px */
      text-decoration: line-through;
    }
  }

  .add-cart {
    width: 60% !important;
    padding: 24px 16px !important;
    box-shadow: 4px 8px 24px 0px #efdcc7 !important;
  }

  .delivery--note {
    width: 90%;
    display: flex;
    padding: 18px 15px;
    flex-direction: column;
    align-items: flex-start;
    border-radius: 12px;
    gap: 4px;
    background: var(--background-blue, #f5fbff);

    h5 {
      color: var(--greyscale-900, #212121);
      font-size: 16px;
      font-weight: 700;
      margin: 0 !important;
    }

    p {
      color: var(--greyscale-700, #616161);
      font-size: 14px;
      font-weight: 400;
      letter-spacing: 0.2px;
      margin: 0 !important;
    }
  }
`
const HStack = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  font-family: var(--eke-body-font-family);
  flex-shrink: 0;

  div p {
    color: var(--greyscale-800, #424242);
    font-size: 16px;
    font-weight: 600;
    line-height: 140%; /* 22.4px */
    letter-spacing: 0.2px;
    margin: 0 !important;
  }

  .counter {
    background-color: #fff8ed !important;

    .count-value {
      font-size: 18px !important;
      color: var(--eke-color-primary) !important;
    }

    button {
      svg {
        width: 27px !important;
        height: 27px !important;
        stroke: var(--eke-color-primary) !important;
        stroke-width: 4px !important;
      }

      &:hover {
        svg {
          stroke: var(--eke-color-white) !important;
        }
      }
    }
  }
`

export default ProductInfo
