/* eslint-disable @typescript-eslint/no-explicit-any */
import React from "react";
import { ListGroup } from "react-bootstrap";
import { useAppSelector } from "../../../../../redux/store/hook";
import { useFetchCartItemsQuery } from "../../../../../redux/store/services/cartApi";
import { slugifyText } from "../../../../../utils";
import SingleProductItem from "../innercomponents/SingleProductItem";
import { CustomAlert } from "@ekenta/components";

interface AllOtherProductsPropsType {
  data: any;
  isLoading: boolean;
  isError?: boolean;
  isSuccess?: boolean;
}

const mapCartDataReturnQuantity = (cartData: any, productid: number) => {
  return cartData ? cartData.cart.find((item: any) => {
    if (item.product.id === productid) {
      return item;
    }
  }) : null
}

export default function AllOtherProducts(props: AllOtherProductsPropsType) {
  const { token } = useAppSelector(state => state.auth.authentication);
  const { data: allCartData } = useFetchCartItemsQuery(token);


  return (
    <ListGroup className="list_p__item_uls">

      {props.isError ? (
        <div className="p-1">
          <CustomAlert
            variant="danger"
            show={true}
            message={"Something went wrong, Try again"}
          />
        </div>
      ) : null}
      {props.isLoading ? <>Loading..</> : null}
      {props.isSuccess ? props.data.products?.data.map((item: any) => {
        const _slugColl = slugifyText(item.name.toLowerCase(), item.id);
        return (
          <SingleProductItem
            key={item.id}
            productId={item.id}
            product={item}
            cartItem={mapCartDataReturnQuantity(allCartData, item.id)}
            imageUrl={
              item.images.length > 0 ? item.images[0]?.image_url : ''}
            title={item.name}
            price={item.price}
            discount={item.discount}
            discountPercentage={item.discountPercentage}
            maxPrice={item.max_price}
            minPrice={item.min_price}
            slug={_slugColl}
          />
        )
      }) : null}
    </ListGroup>
  )
}
