import { device } from "@ekenta/utils/devices";
import styled from "styled-components";


export const AddProductMainStyle = styled.div`
     width: 100%;
    height: auto;
    padding-top: 1rem;

    #app-inner{
        padding-top: 0 !important;
    }

`;

export const AddProductContainerStyle = styled.div`
    width: 100%;
    display: flex;
	align-items: center;
    flex-direction: column;
	justify-content: center;
    height: auto;

    .TopOnboarding__Style{
        display: flex;
        align-items: center;
        flex-direction: column;
        width: 100%;
        margin-bottom: 1rem;

        @media ${device.lg}{
            display: block;
            text-align: left;
        }

        h1{
            color: var(--greyscale-900, #212121);
            font-family: var(--eke-body-font-family);
            font-weight: var(--eke-font-weight-bold);
            font-size: 32px;
            line-height: 120%; /* 38.4px */
        }

        p{
            color: var(--greyscale-700, #616161);
            font-family: var(--eke-body-font-family);
            font-weight: var(--eke-font-weight-normal);
            font-size: 18px;
            line-height: 140%; /* 25.2px */
            letter-spacing: 0.2px;
        }
    }
`;

export const AddProductRowStyle = styled.div`
    width: 100%;
    height: auto;
    min-height: 500px;
    display: flex;
    align-items: flex-start;
    justify-content: stretch;
    flex-direction: column-reverse;
    gap: 1rem;

    @media ${device.md}{
        flex-direction: row;
    }
`;

export const AddProductAsideStyle = styled.div`
        display: none;
        position: relative;
        width: 90%;
        margin: 0 auto;
        padding: 10px 0px 10px 0px !important;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        border-radius: 12px;
        background: var(--eke-color-white);
        min-height: 200px;

    @media ${device.md}{
        display: inline-flex;
        width: 40%;
    }

     @media ${device.lg}{
        display: inline-flex;
        position: sticky;
        min-width: 300px;
        width: 24%;
        top: 2%;
        flex-shrink: 1;
    }

`;

export const OnboardingContentWidth = styled.div`
    padding: 0px;

    @media (min-width: 1200px) and (max-width: 1300px){
        width: 100% !important;
    }

    @media ${device.xl}{
        padding: 0 80px;
    }
`;

export const AddProductFormStyle = styled.div`
    width: 95%;
    margin: 0 auto;
    display: flex;
    padding: 20px 28px 10px 28px;
    border-radius: 12px;
    flex-direction:column;
    align-items: flex-start;
    min-height: 500px;
    height: auto;
    //overflow-x: hidden;
    background-color: var(--eke-color-white);

    /* @media ${device.md}{
        width: 55%;
    } */

    @media ${device.lg}{
        width: 80%;
        min-width: 400px;
        height: auto;
        display: inline-block;
        //overflow: hidden;
        flex: 1 2 auto;
    }

    form{
        flex: 1;
        width: 100% !important;
        height: 100%;
    }

    label{
		color: var(--greyscale-700, #616161) !important;
		font-family: var(--eke-body-font-family) ;
		line-height: 140%;
		font-weight: var(--eke-font-weight-medium) !important;
		letter-spacing: 0.2px;
		
		span{
			font-size: 14px !important;
			font-weight: var(--eke-font-weight-medium) !important;
		}
	}
    

    input.InputField__input, select{
        width: 100%;
        padding: 12px;
        font-size: 14px !important;
		border-radius: 8px !important;
		border: 1px solid var(--gray-300, #D0D5DD) !important;
		background: var(--eke-color-white) !important;
		box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05) !important;

		&::placeholder{
			color: var(--greyscale-500, #9E9E9E) !important;
		}
	}

    input.InputField__input{
        padding: 10px 14px !important;
    }



    ._top_header{

        h5{
            text-align: center;
            color: var(--greyscale-900, #212121) !important;
            text-align: center;
            font-family: var(--eke-body-font-family) !important;
            font-weight: var(--eke-font-weight-bold) !important;
            font-size: 24px;
        }
    
        p{
            text-align: center;
            color: var(--greyscale-700, #616161);
            text-align: center;
            font-family: var(--eke-body-font-family);
            font-size: 16px;
            font-weight: var(--eke-font-weight-normal);
            line-height: 140%; /* 22.4px */
            letter-spacing: 0.2px;
        }
    }

    .dropdiv{
        margin-top: 2px !important;

        img{
            filter: grayscale(0) !important;
            opacity: 1 !important;
        }
    }

    button.PageStep__Btn{
        width: 100%;
        margin-top: 0.5em;
        margin-bottom: 1em;
        background-color: var(--eke-color-primary) !important;
        padding: 13px 16px !important;

        &:disabled{
            background-color: var(--eke-color-primary-300) !important;
        }

        span{
            text-shadow: 4px 8px 24px 0px #EFDCC7;
            font-family: var(--eke-body-font-family) !important;
            font-size: 16px !important;
            font-weight: var(--eke-font-weight-bold) !important;
            line-height: 140%; /* 22.4px */
            letter-spacing: 0.2px;
        }
    }

    
`;

export const AlertNoteStyle = styled.div`
    width: 100%;
    display: flex;
    padding: 14px 25px 13px 18px;
    margin-top: 0.5em;
    align-items: flex-start;
    border-radius: 16px;
    gap: 0.6em;
    background: var(--background-blue, #F5FBFF);

    .IconStyle{
       
    }

    span{
        color: var(--greyscale-700, #616161);
        font-family: var(--eke-body-font-family);
        font-weight: var(--eke-font-weight-normal);
        font-size: 11px;
        line-height: 139%; /* 13.9px */
        letter-spacing: 0.2px;
    }

    
`;

export const SpinnerStyle = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;

	svg{
		width: 55px;
		height: auto;
	}

	.spin {
  		animation: spin 2s linear infinite;
	}

	@keyframes spin {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(360deg);
	}
}
`;

export const SuccessPopStyle = styled.div`
	display: flex;
	padding: 40px 22px 32px 22px;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	text-align: center;
	gap: 0.8em;
	font-family: var(--eke-body-font-family);

	.SuccessIcon__Style{
		svg{
			width: 110px !important;
			height: auto !important;
		}
	}

	h5{
		color: var(--greyscale-900, #212121);
		text-align: center;
		font-size: 22px;
		font-weight: var(--eke-font-weight-bold) !important;
		line-height: 120%;
		margin: 0;
	}

	p{
		color: var(--greyscale-900, #212121);
		font-size: 14px;
		font-weight: var(--eke-font-weight-normal) !important;
		line-height: 140%;
		letter-spacing: 0.2px;
		margin-bottom: 5px;
	}

	.Button-redirect-action{
		width: 100% !important;
		color: var(--eke-color-white);
		text-align: center;
		text-shadow: 4px 8px 24px 0px #EFDCC7 !important;
		font-family: var(--eke-body-font-family);
		padding: 18px 16px !important;
		line-height: 140%;
		letter-spacing: 0.2px;
		
		span{
			font-weight: var(--eke-font-weight-bold) !important;
			font-size: 16px !important;
		}
	}
    `;