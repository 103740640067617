import React from 'react'
import styled from 'styled-components';

export const ErrorCard = (props:{image?:string; title:string; description?:string}) => {
  return (
    <Container>
        <div className="container-rs Q">
        <div className="image-container">
            <img src={props.image} alt="" />
        </div>
        <div className="container-content">
            <h5>{props.title}</h5>
            <p>
            {props.description}
            </p>
        </div>
        </div>
  </Container>
  )
}



const Container = styled.div`
width: 100%;
height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  
  .container-rs {
    padding: 2rem 1rem;
    width: 100%;
    
    text-align: center;
    color: whitesmoke;

    @media (min-width: 1200px) { 
        width: 45%;
        padding: 2rem 2rem;
    }

     .image-container{
        img{
          margin-bottom: 8px;
          margin-top: 36px;
          max-width: 256px;
        }
     }

     .container-content{
       h5{
        text-align: center;
        font-size: 20px;
        font-weight: 700;
        line-height: 22px;
        margin-bottom: 10px;
        font-family: 'Noto Sans', sans-serif;
       }
       p{
        font-size: 16px;
        font-weight: 300;
        max-width: 400px;
        margin: 0 auto;
        margin-bottom: 36px;
        text-align: center;
        font-family: 'Noto Sans', sans-serif;
       }
     }

  }
  
`