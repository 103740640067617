/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState } from 'react'

import styles from './SellerInfo.module.scss'
import { Button, FollowButton, Typography } from '@ekenta/components'
import { FiShare } from 'react-icons/fi'

const SellerInfo = (props: any) => {
  const { merchantFollowers, sellerId, businessName, handleShowModal } = props
  const [count, setCount] = useState<number>(0)

  return (
    <div className={styles.MainInfo_Wrapper}>
      <div className={styles.MainInfo_Wrapper__Left}>
        <Typography
          as="h2"
          size="medium"
          color="light"
          className={styles.Heading}
          heading
        >
          Today Live with {businessName}!
        </Typography>
        <div className={styles.GroupSection}>
          <div>
            <Typography
              as="h5"
              size="medium"
              margin="none"
              color="light"
              className={styles.BusinessTitle}
              uiText
            >
              {businessName}
            </Typography>
            <Typography
              as="span"
              size="small"
              margin="none"
              color="grey"
              className={styles.Small}
              uiText
            >
              Qlason Livestream
            </Typography>
          </div>
          <FollowButton
            followStatus={0}
            sellerId={sellerId}
            count={merchantFollowers}
            suffixCount={true}
            inline={true}
            callback={(count) => setCount(count)}
          />
        </div>
      </div>
      <div className={styles.MainInfo_Wrapper__Right}>
        <Button
          as="text"
          name="Share"
          textVariant="light"
          prefixIcon={<FiShare color="white" size="15" />}
          shadow={false}
          type="button"
          className={styles.Cancel__Button}
          onClick={handleShowModal}
        />
      </div>
    </div>
  )
}

export default SellerInfo
