import React from 'react';
import { Row, Table } from 'react-bootstrap';
import { VscChevronLeft, VscChevronRight } from 'react-icons/vsc';
import { useTable, useSortBy, usePagination } from 'react-table';


const TableComponent: React.FC<any> = ({ data, columns, pageSizeno }) => {
    const _data = React.useMemo(() =>
        [...data], [data]
    )

    const _columns = React.useMemo(() =>
        [...columns],
        [columns]
    )

    return (
        <CustomTable columns={_columns} data={_data} useSortBy={useSortBy} pageSizeno={pageSizeno} />
    )
}

export default TableComponent;



function CustomTable({ columns, data, useSortBy, pageSizeno }: any) {
    // Use the state and functions returned from useTable to build your UI
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page,
        pageOptions,
        state: { pageIndex, pageSize },
        previousPage,
        nextPage,
        canPreviousPage,
        canNextPage,
    } = useTable({
        columns,
        data,
        initialState: { pageSize: pageSizeno },
    },
        useSortBy, usePagination
    )

    // Render the UI for your table
    return (
        <div className="custom_table_listing_order">
            <Table {...getTableProps()} responsive striped hover>
                <thead>
                    {headerGroups.map((headerGroup, i) => (
                        <tr {...headerGroup.getHeaderGroupProps()} key={i}>
                            {headerGroup.headers.map((column, i) => (
                                <th {...column.getHeaderProps(column.getSortByToggleProps())} key={i}>
                                    {column.render('Header')}
                                    <span>
                                        {column.isSorted ? (column.isSortedDesc ? '▼' : '▲') : ''}
                                    </span>
                                </th>
                            ))}
                        </tr>
                    ))}

                </thead>
                <tbody {...getTableBodyProps()}>

                    {page.map((row, i) => {
                        prepareRow(row)
                        return (
                            <tr {...row.getRowProps()} key={i}>
                                {row.cells.map((cell, i) => {
                                    return <td {...cell.getCellProps()} key={i}>{cell.render('Cell')}</td>
                                })}
                            </tr>
                        )
                    })}
                </tbody>
            </Table>
            <div className="dtable_pagination_pg">
                <div className="dtable_pagination_pg_no">
                    Page{' '}
                    <em>
                        {pageIndex + 1} of {pageOptions.length}
                    </em>
                </div>
                <div className="dtable_pagination_pg_btngroup">
                    <button className="btn" onClick={() => previousPage()} disabled={!canPreviousPage}>
                        <VscChevronLeft fontSize={18} />
                        <span className="ms-1">Previous</span>
                    </button>
                    <button className="btn" onClick={() => nextPage()} disabled={!canNextPage}>
                        <span className="ms-1">Next</span>
                        <VscChevronRight fontSize={18} />
                    </button>
                </div>

            </div>

        </div>
    )
}

