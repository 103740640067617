import React, { useState } from 'react'
import Tippy from "@tippyjs/react";
import classnames from 'classnames';

import "tippy.js/dist/tippy.css";
import "tippy.js/themes/light.css";
import "tippy.js/animations/shift-toward.css";

import exportStyles from "./Export.module.scss";
import { FiUpload } from 'react-icons/fi';

interface ExportPropType {
    downloadData: (type: string) => void;
    name: string;
    type?: string;
}

const Export = ({
    downloadData,
    name,
    type
}: ExportPropType) => {

    const [isVisible, setIsVisible] = useState(false);

    const dropdownMenu = (
        <ul className={exportStyles.dropdown_menu}>

            <li
                className="DropdownMenu__item"
                onClick={() => {
                    downloadData(type ? type : "csv");
                    setIsVisible(false);
                }}
            >
                <span className="DropdownMenu__icon">
                    <svg
                        xmlns="https://www.w3.org/2000/svg"
                        xmlnsXlink="https://www.w3.org/1999/xlink"
                        viewBox="0 0 16 16"
                        fill="currentColor"
                        width="15" height="15"
                    >
                        <defs>
                            <path
                                id="export-a"
                                d="M12.4545455,14.125 L13.7272727,14.125 L13.7272727,13.5 L12.4545455,13.5 L12.4545455,14.125 Z M15,16 L1,16 L1,12.875 C1,12.53125 1.28636364,12.25 1.63636364,12.25 L14.3636364,12.25 C14.7136364,12.25 15,12.53125 15,12.875 L15,16 Z M6.09090909,1.625 C6.09090909,1.28125 6.37727273,1 6.72727273,1 L9.27272727,1 C9.62272727,1 9.90909091,1.28125 9.90909091,1.625 L9.90909091,6 L13.0209091,6 C13.3709091,6 13.4663636,6.20875 13.2321818,6.465 L8.36018182,11.785 C8.126,12.04125 7.74418182,12.04 7.51127273,11.7825 L2.69527273,6.4675 C2.46363636,6.21 2.55909091,6 2.90909091,6 L6.09090909,6 L6.09090909,1.625 Z"
                            ></path>
                        </defs>
                        <use fillRule="evenodd" xlinkHref="#export-a"></use>
                    </svg>
                </span>
                <span className="DropdownMenu__text">Export as {type ? type : 'csv'}</span>
            </li>
        </ul>
    );

    return (
        <Tippy
            arrow={true}
            visible={isVisible}
            interactive={true}
            onClickOutside={() => setIsVisible(false)}
            content={dropdownMenu}
            placement="bottom-end"
            theme="light"
            animation="shift-toward"
            className={exportStyles.tippy_custom_styles}
        >
            <button
                onClick={() => setIsVisible((prev) => !prev)}
                type="button"
                className={classnames("Button Button--icon w-auto btn-icon ExportButton Button--iconOnly", exportStyles.btn, exportStyles.exportButton)}
            >
                <span className="Button__icon d-flex align-items-center">
                    <FiUpload fontSize="14" className='me-1' />
                    {name}
                </span>
            </button>
        </Tippy>
    )
}

export default Export