import React from 'react'
import { Container } from 'react-bootstrap';
import { HelmetHeader, PageTitle, Typography } from '@ekenta/components';
import { withDefaultLayout } from '@ekenta/hoc';
import { Link as Linked } from "react-router-dom";

import { Link, Element } from 'react-scroll'

import styles from "./index.module.scss";
import { privacyUrl } from '@ekenta/routes/constant';

const TermsAndCondition = () => {

    return (
        <>
            <HelmetHeader
                title="Terms and Condition "
                description="Qlason Terms and Condition Page"
            />
            <Container className="landingpage__container top__container">
                <div className={styles.Documentation__main}>
                    <div className={styles.Documentation__Container}>
                        <div className={styles.Documentation__Content}>
                            <PageTitle
                                title="Terms and Conditions of Use"
                                className={styles.PageTitle}
                                margin={true}
                            />

                            <Typography
                                as="p"
                                size="medium"
                                color="secondary"
                                margin="bottom"
                                className={styles.Documentation_Para}
                                heading>
                                Please read carefully before using this service.
                            </Typography>
                            <br />
                            <section className={styles.Documentation__Intro}>
                                <Typography
                                    as="h4"
                                    size="medium"
                                    color="secondary"
                                    margin="bottom"
                                    className={styles.Documentation_Heading}
                                    heading>
                                    Welcome to the Qlason
                                </Typography>
                                <Typography
                                    as="p"
                                    size="base"
                                    color="dark"
                                    className={styles.Documentation_Para}
                                    uiText>
                                    Qlason(the “Service”) is an e-commerce cloud service for businesses and online sellers to
                                    create and build their own online stores. The Service is a subscription-based software – a
                                    business to business or a business to customer platform. The Service is owned and operated by Absolute Robotics Limited, subject to this Terms and Conditions Agreement (“Terms”).
                                </Typography>
                                <Typography
                                    as="p"
                                    size="base"
                                    color="dark"
                                    className={styles.Documentation_Para}
                                    uiText>
                                    The Terms are inclusive of Qlason’s Privacy Policy (“Privacy Policy”).
                                    Please read here <Linked to={privacyUrl}>(Qlason Privacy Policy)</Linked>.
                                    Qlason takes the matters of protection and security of its users’ information very seriously.
                                    The Privacy Policy is incorporated into these Terms by this reference.
                                </Typography>
                                <Typography
                                    as="p"
                                    size="base"
                                    color="dark"
                                    className={styles.Documentation_Para}
                                    uiText>
                                    This Terms and Conditions are applicable to Merchants,
                                    their customers and any visitor to this platform.
                                    The Merchants and customers may be referred to as “User” in this document.
                                    By using the service, you agree to the use of your data in accordance with Qlason’s Privacy Policy.
                                    The Privacy Policy addresses only the information collected by Qlasonin providing this Service.
                                </Typography>
                            </section>

                            <section className={styles.MarginTop}>

                                <div className={styles.Highlight_Section}>
                                    <Typography
                                        as="p"
                                        size="base"
                                        color="dark"
                                        margin="bottom"
                                        className={styles.Documentation_Para}
                                        uiText>
                                        The terms contained herein govern your usage of the Qlason service.
                                        These Terms constitute a binding contract between the user and Qlason.
                                        This Service can only be used upon acceptance of these Terms. By using any part of the Service,
                                        you accept these Terms. This service is not available to users under eighteen (18) years of age.
                                    </Typography>
                                </div>

                                {/* Table of Contents */}
                                <div className={styles.TableContent}>
                                    <Typography
                                        as="h2"
                                        size="large"
                                        color="secondary"
                                        margin="bottom"
                                        className={styles.TableContent_Heading}
                                        heading>
                                        Table of Contents
                                    </Typography>
                                    <ul className={styles.TableContent__List}>
                                        <li>
                                            <Link activeClass="active" className="#merchant-account"
                                                to="#merchant-account" spy={true} duration={0} offset={-120}>
                                                <Typography
                                                    as="span"
                                                    size="base"
                                                    color="primary"
                                                    className={styles.TableContent__Item}
                                                    uiText>
                                                    1. Merchant Account Registration with valid information
                                                </Typography>
                                            </Link>
                                        </li>
                                        <li>
                                            <Link activeClass="active" className="#customer-account" to="#customer-account"
                                                spy={true} duration={0} offset={-120}>
                                                <Typography
                                                    as="span"
                                                    size="base"
                                                    color="primary"
                                                    className={styles.TableContent__Item}
                                                    uiText>
                                                    2. Customer Account Registration
                                                </Typography>
                                            </Link>
                                        </li>
                                        <li>
                                            <Link activeClass="active" className="#service-platform" to="#service-platform"
                                                spy={true} duration={0} offset={-120}>
                                                <Typography
                                                    as="span"
                                                    size="base"
                                                    color="primary"
                                                    className={styles.TableContent__Item}
                                                    uiText>
                                                    3. Service and Platform inquiries
                                                </Typography>
                                            </Link>
                                        </li>
                                        <li>
                                            <Link activeClass="active" className="#domain-name" to="#domain-name"
                                                spy={true} duration={0} offset={-120}>
                                                <Typography
                                                    as="span"
                                                    size="base"
                                                    color="primary"
                                                    className={styles.TableContent__Item}
                                                    uiText>
                                                    4. Domain Names
                                                </Typography>
                                            </Link>
                                        </li>
                                        <li>
                                            <Link activeClass="active" className="#subscription" to="#subscription"
                                                spy={true} duration={0} offset={-120}>
                                                <Typography
                                                    as="span"
                                                    size="base"
                                                    color="primary"
                                                    className={styles.TableContent__Item}
                                                    uiText>
                                                    5. Subscription
                                                </Typography>
                                            </Link>
                                        </li>
                                        <li>
                                            <Link activeClass="active" className="#prohibit" to="#prohibit"
                                                spy={true} duration={0} offset={-120}>
                                                <Typography
                                                    as="span"
                                                    size="base"
                                                    color="primary"
                                                    className={styles.TableContent__Item}
                                                    uiText>
                                                    6. Prohibition of violation of any Laws using the Service
                                                </Typography>
                                            </Link>
                                        </li>
                                        <li>
                                            <Link activeClass="active" className="#termination" to="#termination"
                                                spy={true} duration={0} offset={-120}>
                                                <Typography
                                                    as="span"
                                                    size="base"
                                                    color="primary"
                                                    className={styles.TableContent__Item}
                                                    uiText>
                                                    7. Termination and Suspension of Account
                                                </Typography>
                                            </Link>
                                        </li>
                                        <li>
                                            <Link activeClass="active" className="#nature-relate" to="#nature-relate"
                                                spy={true} duration={0} offset={-120}>
                                                <Typography
                                                    as="span"
                                                    size="base"
                                                    color="primary"
                                                    className={styles.TableContent__Item}
                                                    uiText>
                                                    8. The nature of the Relationship between Qlason and the Merchants
                                                </Typography>
                                            </Link>
                                        </li>
                                        <li>
                                            <Link activeClass="active" className="#qlason-ob" to="#qlason-ob"
                                                spy={true} duration={0} offset={-120}>
                                                <Typography
                                                    as="span"
                                                    size="base"
                                                    color="primary"
                                                    className={styles.TableContent__Item}
                                                    uiText>
                                                    9. Qlason’s Obligations
                                                </Typography>
                                            </Link>
                                        </li>
                                        <li>
                                            <Link activeClass="active" className="#merchant-ob" to="#merchant-ob"
                                                spy={true} duration={0} offset={-120}>
                                                <Typography
                                                    as="span"
                                                    size="base"
                                                    color="primary"
                                                    className={styles.TableContent__Item}
                                                    uiText>
                                                    10. Merchant’s Obligations
                                                </Typography>
                                            </Link>
                                        </li>
                                        <li>
                                            <Link activeClass="active" className="#payment-ship" to="#payment-ship"
                                                spy={true} duration={0} offset={-120}>
                                                <Typography
                                                    as="span"
                                                    size="base"
                                                    color="primary"
                                                    className={styles.TableContent__Item}
                                                    uiText>
                                                    11. Payment and Shipping of Products
                                                </Typography>
                                            </Link>
                                        </li>
                                        <li>
                                            <Link activeClass="active" className="#product-listing" to="#product-listing"
                                                spy={true} duration={0} offset={-120}>
                                                <Typography
                                                    as="span"
                                                    size="base"
                                                    color="primary"
                                                    className={styles.TableContent__Item}
                                                    uiText>
                                                    12. Product Listing
                                                </Typography>
                                            </Link>
                                        </li>
                                        <li>
                                            <Link activeClass="active" className="#service" to="#service"
                                                spy={true} duration={0} offset={-120}>
                                                <Typography
                                                    as="span"
                                                    size="base"
                                                    color="primary"
                                                    className={styles.TableContent__Item}
                                                    uiText>
                                                    13. This Service is provided without any warranties or guarantees
                                                </Typography>
                                            </Link>
                                        </li>
                                        <li>
                                            <Link activeClass="active" className="#qlason-liable" to="#qlason-liable"
                                                spy={true} duration={0} offset={-120}>
                                                <Typography
                                                    as="span"
                                                    size="base"
                                                    color="primary"
                                                    className={styles.TableContent__Item}
                                                    uiText>
                                                    14. Qlason is not liable for any damages you may incur as a result of using the Services on the Platform
                                                </Typography>
                                            </Link>
                                        </li>

                                        <li>
                                            <Link activeClass="active" className="#qlason-terms" to="#qlason-terms"
                                                spy={true} duration={0} offset={-120}>
                                                <Typography
                                                    as="span"
                                                    size="base"
                                                    color="primary"
                                                    className={styles.TableContent__Item}
                                                    uiText>
                                                    15. Qlason may modify these Terms
                                                </Typography>
                                            </Link>
                                        </li>
                                        <li>
                                            <Link activeClass="active" className="#application-law" to="#application-law"
                                                spy={true} duration={0} offset={-120}>
                                                <Typography
                                                    as="span"
                                                    size="base"
                                                    color="primary"
                                                    className={styles.TableContent__Item}
                                                    uiText>
                                                    16. Applicable Law and Dispute Resolution
                                                </Typography>
                                            </Link>
                                        </li>
                                        <li>
                                            <Link activeClass="active" className="#general-terms" to="#general-terms"
                                                spy={true} duration={0} offset={-120}>
                                                <Typography
                                                    as="span"
                                                    size="base"
                                                    color="primary"
                                                    className={styles.TableContent__Item}
                                                    uiText>
                                                    17. General Terms
                                                </Typography>
                                            </Link>
                                        </li>
                                    </ul>
                                </div>

                                <div>
                                    <ul className={styles.DocList}>
                                        <Element as="li" name="#merchant-account">
                                            <Typography
                                                as="h4"
                                                size="medium"
                                                color="secondary"
                                                margin="top"
                                                className={styles.DocList__Heading}
                                                heading>
                                                1. Merchant Account Registration with valid information
                                            </Typography>
                                            <Typography
                                                as="p"
                                                size="base"
                                                color="dark"
                                                margin="bottom"
                                                className={styles.Documentation_Para}
                                                uiText>
                                                To use the Service as a Merchant, you must: (a) provide a valid mobile phone number,
                                                email address and any further information requested by Qlason on the Platform; (b) agree
                                                to the creation of an account associated with such number; (c) accept the Terms contained herein; and (d) submit such other and additional information as Qlason may request. You agree to provide true, accurate and complete information about yourself and your business/Company as prompted by Qlason during the account registration process and business setup. Qlason shall not be liable for damages or loss arising out of the wrong information or data provided by you. Qlason shall also not be responsible for the accuracy or otherwise of information to be retrieved from it concerning your Company or business. You will be given the option of receiving a unique Password for purposes of reusing your account.
                                                You are responsible for keeping your Password secure or changing the Password to a more suitable one as you prefer.
                                            </Typography>
                                        </Element>
                                        <Element as="li" name="#customer-account">
                                            <Typography
                                                as="h4"
                                                size="medium"
                                                color="secondary"
                                                margin="top"
                                                className={styles.DocList__Heading}
                                                heading>
                                                2. Customer Account Registration
                                            </Typography>
                                            <Typography
                                                as="p"
                                                size="base"
                                                color="dark"
                                                margin="bottom"
                                                className={styles.Documentation_Para}
                                                uiText>
                                                Upon your acceptance of the Platform’s Terms of Use and Privacy Policy,
                                                you may be allowed to freely access a Merchant’s store without any further registration where the Merchant has chosen to make his/her store accessible to unregistered customers.
                                                However, for stores which do not take this option, in order to access their stores, you must: (a) provide a valid mobile phone number, email address and any further information requested on the Platform;
                                                (b) agree to the creation of an account associated with such number; (c) accept the Terms contained herein; and (d) submit such other and additional information as Qlason may request.
                                            </Typography>
                                        </Element>
                                        <Element as="li" name="#service-platform">
                                            <Typography
                                                as="h4"
                                                size="medium"
                                                color="secondary"
                                                margin="top"
                                                className={styles.DocList__Heading}
                                                heading>
                                                3. Service and Platform inquiries
                                            </Typography>
                                            <Typography
                                                as="p"
                                                size="base"
                                                color="dark"
                                                margin="bottom"
                                                className={styles.Documentation_Para}
                                                uiText>
                                                If you have any questions, concerns or requests related to the Products and Services offered on this Platform,
                                                you can contact us on admin@dabsoluterobotics.com
                                            </Typography>
                                        </Element>
                                        <Element as="li" name="#domain-name">
                                            <Typography
                                                as="h4"
                                                size="medium"
                                                color="secondary"
                                                margin="top"
                                                className={styles.DocList__Heading}
                                                heading>
                                                4. Domain Names – applicable to Merchants
                                            </Typography>
                                            <Typography
                                                as="p"
                                                size="base"
                                                color="dark"
                                                margin="bottom"
                                                className={styles.Documentation_Para}
                                                uiText>
                                                Upon completion of your registration on Qlason, a domain name is automatically assigned to you and the ownership of the domain name will be yours as long as your account remains active. The Domain issued to you is unique to your store and is not editable as other features on the platform.
                                                However, as a merchant, you may request for your personal domain name to be linked to your Qlason domain name.
                                            </Typography>
                                        </Element>
                                        <Element as="li" name="#subscription">
                                            <Typography
                                                as="h4"
                                                size="medium"
                                                color="secondary"
                                                margin="top"
                                                className={styles.DocList__Heading}
                                                heading>
                                                5. Subscription – applicable to merchants
                                            </Typography>
                                            <Typography
                                                as="p"
                                                size="base"
                                                color="dark"
                                                margin="bottom"
                                                className={styles.Documentation_Para}
                                                uiText>
                                                The usage of the platform shall be on subscription basis. Qlason has both paid and free plans which is available for your use immediately after completion of your registration. Where you opt for the paid plan, you shall be charged monthly subscription fees for the use of the platform and shall immediately be denied access to the platform where your subscription is not automatically renewed. Your subscription to these plans will automatically renew upon expiration until you deactivate your registration on Qlason.
                                                You acknowledge that it is your sole responsibility to deactivate the auto-renewal function should you choose to do so.
                                            </Typography>
                                        </Element>
                                        <Element as="li" name="#prohibit">
                                            <Typography
                                                as="h4"
                                                size="medium"
                                                color="secondary"
                                                margin="top"
                                                className={styles.DocList__Heading}
                                                heading>
                                                6. Prohibition of violation of any Laws using the Service – applicable to merchants, customers and visitors
                                            </Typography>
                                            <Typography
                                                as="p"
                                                size="base"
                                                color="dark"
                                                margin="bottom"
                                                className={styles.Documentation_Para}
                                                uiText>
                                                You must not use this Service to violate or infringe the rights of any other person, including the rights of other Companies/businesses off or on the platform. You must not breach any laws or regulations or carry out any fraudulent activities when using the Service or attempt to disturb or interfere with the security or functionality of the Platform.
                                                In the situation that Qlason has the suspicion you are using the services for illegal activities such as fraud, Qlason is hereby authorized to instantly block your account.
                                            </Typography>
                                        </Element>
                                        <Element as="li" name="#termination">
                                            <Typography
                                                as="h4"
                                                size="medium"
                                                color="secondary"
                                                margin="top"
                                                className={styles.DocList__Heading}
                                                heading>
                                                7. Termination and Suspension of Account – applicable to merchants
                                            </Typography>
                                            <Typography
                                                as="p"
                                                size="base"
                                                color="dark"
                                                margin="bottom"
                                                className={styles.Documentation_Para}
                                                uiText>
                                                You agree that your access to the service is on a subscription basis and may be cut off immediately upon expiration of your subscription. Where a Merchant’s products and services and/or actions are reported by a customer, the Company carryout investigations on the report to determine the veracity of the claim, where the claim/report is found to be true, the Merchant is issued with a warning. However, where a Merchant is reported up to three (3) times by the same or different customers and the Company finds the reports to be true all the three (3) times, the Merchant’s account may be suspended notwithstanding the Merchant’s subscription. Where an account has been suspended for up to three (3) times, the Company may permanently delete the Merchant’s account from the platform and deny the Merchant access to creation of any other account.
                                                Qlason may in its sole discretion and at any time terminate the Merchant’s access to the Platform or discontinue providing the Service or any part of the Service, with or without notice. The Merchant hereby agrees that Qlason will not be responsible or liable to him/her or any third party for modifying or discontinuing the Service, or for terminating or suspending your access to the Service. The Merchant may terminate his/her account and/or access to the Platform at any time, for any reason, by following the prompts and pointers provided on the platform.
                                            </Typography>
                                        </Element>
                                        <Element as="li" name="#nature-relate">
                                            <Typography
                                                as="h4"
                                                size="medium"
                                                color="secondary"
                                                margin="top"
                                                className={styles.DocList__Heading}
                                                heading>
                                                8. The nature of the Relationship between Qlason and the Merchants
                                            </Typography>
                                            <Typography
                                                as="p"
                                                size="base"
                                                color="dark"
                                                margin="bottom"
                                                className={styles.Documentation_Para}
                                                uiText>
                                                Both Parties are not by this Agreement deemed to be agents of each other nor shall anything herein contained be construed as creating a partnership.
                                                It is agreed that each Party will be responsible only for its obligations under this Agreement and neither Party shall be authorized to represent or bind the other to third parties.
                                                The Company shall be deemed as an Independent Contractor and will be fully responsible for payment of its own income taxes.
                                                The Merchant shall also be responsible for payment of its own taxes.
                                            </Typography>
                                        </Element>
                                        <Element as="li" name="#qlason-ob" style={{ marginTop: "1.5rem" }}>
                                            <Typography
                                                as="h4"
                                                size="medium"
                                                color="secondary"
                                                margin="top"
                                                className={styles.DocList__Heading}
                                                heading>
                                                9. Qlason’s Obligations
                                            </Typography>
                                            <ul>
                                                <li>
                                                    <Typography
                                                        as="p"
                                                        size="base"
                                                        color="dark"
                                                        margin="bottom"
                                                        className={styles.Documentation_Para}
                                                        uiText>
                                                        Qlason shall provide the Merchant the platform and the required assistance to create an online store where it can sell its products.
                                                    </Typography>
                                                </li>
                                                <li>
                                                    <Typography
                                                        as="p"
                                                        size="base"
                                                        color="dark"
                                                        margin="bottom"
                                                        className={styles.Documentation_Para}
                                                        uiText>
                                                        Qlason will provide access to delivery services and payment service providers on its platform to allow
                                                        the Merchant’s customers easily make payments for products purchased and receive delivery of the products purchased.
                                                    </Typography>
                                                </li>
                                            </ul>
                                        </Element>
                                        <Element as="li" name="#merchant-ob" style={{ marginTop: "1.5rem" }}>
                                            <Typography
                                                as="h4"
                                                size="medium"
                                                color="secondary"
                                                margin="top"
                                                className={styles.DocList__Heading}
                                                heading>
                                                10. Merchant’s Obligations
                                            </Typography>
                                            <ul>
                                                <li>
                                                    <Typography
                                                        as="p"
                                                        size="base"
                                                        color="dark"
                                                        margin="bottom"
                                                        className={styles.Documentation_Para}
                                                        uiText>
                                                        The Merchant shall comply with the provisions of the Terms and Conditions
                                                        which can be found on the Platform.
                                                    </Typography>
                                                </li>
                                                <li>
                                                    <Typography
                                                        as="p"
                                                        size="base"
                                                        color="dark"
                                                        margin="bottom"
                                                        className={styles.Documentation_Para}
                                                        uiText>
                                                        The Merchant shall self-fulfill all customer requirements from its store.
                                                    </Typography>
                                                </li>
                                                <li>
                                                    <Typography
                                                        as="p"
                                                        size="base"
                                                        color="dark"
                                                        margin="bottom"
                                                        className={styles.Documentation_Para}
                                                        uiText>
                                                        The Merchant shall always fulfill any orders made on the Qlason platform and shall always update its profile according to the stock
                                                        it has in order to curtail any inability to fulfil the customers’ orders.
                                                    </Typography>
                                                </li>
                                                <li>
                                                    <Typography
                                                        as="p"
                                                        size="base"
                                                        color="dark"
                                                        margin="bottom"
                                                        className={styles.Documentation_Para}
                                                        uiText>
                                                        The Merchant shall be responsible for the safe and
                                                        clean transportation of all goods purchased from its store till it is delivered to the customer.
                                                    </Typography>
                                                </li>
                                                <li>
                                                    <Typography
                                                        as="p"
                                                        size="base"
                                                        color="dark"
                                                        margin="bottom"
                                                        className={styles.Documentation_Para}
                                                        uiText>
                                                        The Merchant shall be responsible for customer experience.
                                                    </Typography>
                                                </li>
                                            </ul>
                                        </Element>
                                        <Element as="li" name="#payment-ship" style={{ marginTop: "1.5rem" }}>
                                            <Typography
                                                as="h4"
                                                size="medium"
                                                color="secondary"
                                                margin="top"
                                                className={styles.DocList__Heading}
                                                heading>
                                                11. Payment and Shipping of Products
                                            </Typography>
                                            <ul>
                                                <li>
                                                    <Typography
                                                        as="p"
                                                        size="base"
                                                        color="dark"
                                                        margin="bottom"
                                                        className={styles.Documentation_Para}
                                                        uiText>
                                                        The Subscription fee shall be paid monthly using the means of payment
                                                        provided by the Company on the platform.
                                                    </Typography>
                                                </li>
                                                <li>
                                                    <Typography
                                                        as="p"
                                                        size="base"
                                                        color="dark"
                                                        margin="bottom"
                                                        className={styles.Documentation_Para}
                                                        uiText>
                                                        Payments for products purchased on the Merchant’s store shall be remitted directly to the Merchant
                                                        using the payment gateway provided by Qlason on the platform.
                                                    </Typography>
                                                </li>
                                                <li>
                                                    <Typography
                                                        as="p"
                                                        size="base"
                                                        color="dark"
                                                        margin="bottom"
                                                        className={styles.Documentation_Para}
                                                        uiText>
                                                        Payments may be made to the Merchant via online transfer or the payment gateways provided Qlason.
                                                    </Typography>
                                                </li>
                                                <li>
                                                    <Typography
                                                        as="p"
                                                        size="base"
                                                        color="dark"
                                                        margin="bottom"
                                                        className={styles.Documentation_Para}
                                                        uiText>
                                                        The Merchant shall be solely responsible for the shipping and delivery of products purchased by its customer.
                                                        Qlason may provide reliable and easily accessible delivery/shipping Companies on the Platform to be used by
                                                        each Merchant for the delivery and shipping of products purchased to his/her customers.
                                                    </Typography>
                                                </li>
                                                <li style={{ listStyleType: "none", marginTop: "1.5rem" }}>
                                                    <Typography
                                                        as="p"
                                                        size="base"
                                                        color="dark"
                                                        margin="bottom"
                                                        className={styles.Documentation_Para}
                                                        uiText>
                                                        To learn more about our third-party payment gateways,
                                                        please visit our third-party websites through these links: (insert)
                                                    </Typography>
                                                </li>
                                            </ul>
                                        </Element>
                                        <Element as="li" name="#product-listing" style={{ marginTop: "1.5rem" }}>
                                            <Typography
                                                as="h4"
                                                size="medium"
                                                color="secondary"
                                                margin="top"
                                                className={styles.DocList__Heading}
                                                heading>
                                                12. Product Listing
                                            </Typography>
                                            <Typography
                                                as="p"
                                                size="base"
                                                color="dark"
                                                margin="bottom"
                                                className={styles.Documentation_Para}
                                                uiText>
                                                When listing an item for sale on its website hosted on Qlason,
                                                the Merchant agrees to the following:
                                            </Typography>
                                            <ul>
                                                <li>
                                                    <Typography
                                                        as="p"
                                                        size="base"
                                                        color="dark"
                                                        margin="bottom"
                                                        className={styles.Documentation_Para}
                                                        uiText>
                                                        To comply with the provisions of the Federal Competition and
                                                        Consumer Protection Act and the Qlason Terms and Conditions of Use;
                                                    </Typography>
                                                </li>
                                                <li>
                                                    <Typography
                                                        as="p"
                                                        size="base"
                                                        color="dark"
                                                        margin="bottom"
                                                        className={styles.Documentation_Para}
                                                        uiText>
                                                        To be fully responsible for the accuracy and
                                                        content of the listing and item offered;
                                                    </Typography>
                                                </li>
                                                <li>
                                                    <Typography
                                                        as="p"
                                                        size="base"
                                                        color="dark"
                                                        margin="bottom"
                                                        className={styles.Documentation_Para}
                                                        uiText>
                                                        The fixed price listings shall renew automatically every 30 days, based on the listing terms at the relevant time,
                                                        until the quantities sell out or until the listing is cancelled.
                                                    </Typography>
                                                </li>
                                                <li>
                                                    <Typography
                                                        as="p"
                                                        size="base"
                                                        color="dark"
                                                        margin="bottom"
                                                        className={styles.Documentation_Para}
                                                        uiText>
                                                        Content that violates any of Qlason’s policies may be modified, obfuscated
                                                        or deleted at Qlason’s discretion;
                                                    </Typography>
                                                </li>
                                                <li>
                                                    <Typography
                                                        as="p"
                                                        size="base"
                                                        color="dark"
                                                        margin="bottom"
                                                        className={styles.Documentation_Para}
                                                        uiText>
                                                        Qlason may revise product data associated with listings to supplement, remove, or correct information;
                                                    </Typography>
                                                </li>
                                                <li>
                                                    <Typography
                                                        as="p"
                                                        size="base"
                                                        color="dark"
                                                        margin="bottom"
                                                        className={styles.Documentation_Para}
                                                        uiText>
                                                        Metatags and URL links that are included in a listing may be removed or altered so as to not affect the listing on other Qlason stores; and
                                                    </Typography>
                                                </li>
                                            </ul>
                                        </Element>
                                        <Element as="li" name="#service" style={{ marginTop: "1.5rem" }}>
                                            <Typography
                                                as="h4"
                                                size="medium"
                                                color="secondary"
                                                margin="top"
                                                className={styles.DocList__Heading}
                                                heading>
                                                13. This Service is provided without any warranties or guarantees – applicable to customers
                                            </Typography>
                                            <Typography
                                                as="p"
                                                size="base"
                                                color="dark"
                                                margin="bottom"
                                                className={styles.Documentation_Para}
                                                uiText>
                                                The service is provided without warranty of any kind. Qlason and its affiliates or subsidiaries disclaim all warranties with regard to the service, including all implied warranties of merchantability, fitness for a particular purpose, title, and non-infringement. In the event that you are dissatisfied with any portion of the service, or with any of these terms,
                                                your sole and exclusive remedy is to discontinue using the service.
                                            </Typography>
                                        </Element>

                                        <Element as="li" name="#qlason-liable" style={{ marginTop: "1.5rem" }}>
                                            <Typography
                                                as="h4"
                                                size="medium"
                                                color="secondary"
                                                margin="top"
                                                className={styles.DocList__Heading}
                                                heading>
                                                14. Qlason is not liable for any damages you may incur as a result of using the Services on the Platform
                                            </Typography>
                                            <Typography
                                                as="p"
                                                size="base"
                                                color="dark"
                                                margin="bottom"
                                                className={styles.Documentation_Para}
                                                uiText>
                                                Qlason is set up for the management and smooth running of companies and businesses, and in no event shall Qlason or its suppliers be liable for any direct, indirect, punitive, incidental, special, consequential damages, or any damages whatsoever arising out of or in any way connected with the use or performance of the service on the Platform. Qlason shall in
                                                no way be held liable for any loss of business or information occasioned by the use of the Platform.
                                            </Typography>
                                        </Element>

                                        <Element as="li" name="#qlason-terms" style={{ marginTop: "1.5rem" }}>
                                            <Typography
                                                as="h4"
                                                size="medium"
                                                color="secondary"
                                                margin="top"
                                                className={styles.DocList__Heading}
                                                heading>
                                                15. Qlason may modify these Terms.
                                            </Typography>
                                            <Typography
                                                as="p"
                                                size="base"
                                                color="dark"
                                                margin="bottom"
                                                className={styles.Documentation_Para}
                                                uiText>
                                                These Terms and related policies (including but not limited to the Privacy Policy) may be modified by Qlason without notice at any time in the future. Changes will be posted. By using the Service, you agree to be bound by the latest version of these Terms.
                                                It is your responsibility to remain informed of any changes.
                                            </Typography>
                                        </Element>


                                        <Element as="li" name="#application-law" style={{ marginTop: "1.5rem" }}>
                                            <Typography
                                                as="h4"
                                                size="medium"
                                                color="secondary"
                                                margin="top"
                                                className={styles.DocList__Heading}
                                                heading>
                                                16. Applicable Law and Dispute Resolution
                                            </Typography>
                                            <Typography
                                                as="p"
                                                size="base"
                                                color="dark"
                                                margin="bottom"
                                                className={styles.Documentation_Para}
                                                uiText>
                                                This Agreement shall be governed by and construed in all aspects in accordance with
                                                the laws of the Federal Republic of Nigeria. The Parties hereto each undertake to comply with all laws and regulations as may be applicable to the performance of its obligations under this Agreement. Parties agree to settle all disputes or differences of opinion between them, arising from or in connection with this Agreement, amicably through mutual discussion and/or Arbitration where amicable settlement breaks down irretrievably; such arbitration shall be in accordance with the Arbitration and Conciliation Act, Cap.
                                                A18 Laws of the Federation of Nigeria 2004.
                                            </Typography>
                                        </Element>



                                        <Element as="li" name="#general-terms" style={{ marginTop: "1.5rem" }}>
                                            <Typography
                                                as="h4"
                                                size="medium"
                                                color="secondary"
                                                margin="top"
                                                className={styles.DocList__Heading}
                                                heading>
                                                17. General Terms
                                            </Typography>
                                            <ol>
                                                <li>
                                                    <Typography
                                                        as="p"
                                                        size="base"
                                                        color="dark"
                                                        margin="bottom"
                                                        className={styles.Documentation_Para}
                                                        uiText>
                                                        No alteration, variation or agreed cancellation of this Agreement, and this paragraph,
                                                        shall be of any effect unless directed so by us.
                                                    </Typography>
                                                </li>
                                                <li>
                                                    <Typography
                                                        as="p"
                                                        size="base"
                                                        color="dark"
                                                        margin="bottom"
                                                        className={styles.Documentation_Para}
                                                        uiText>
                                                        This Agreement constitutes the whole agreement between the parties in regard to the subject matter hereof and no warranties or representations of any nature whatsoever other than set out in this Agreement have been given by any of the parties.

                                                    </Typography>
                                                </li>
                                                <li>
                                                    <Typography
                                                        as="p"
                                                        size="base"
                                                        color="dark"
                                                        margin="bottom"
                                                        className={styles.Documentation_Para}
                                                        uiText>
                                                        No relaxation or indulgence which Qlason may show to you
                                                        shall in any way prejudice or be deemed to be a waiver of its rights hereunder.
                                                    </Typography>
                                                </li>
                                                <li>
                                                    <Typography
                                                        as="p"
                                                        size="base"
                                                        color="dark"
                                                        margin="bottom"
                                                        className={styles.Documentation_Para}
                                                        uiText>
                                                        Each and every provision of this Agreement (excluding only those provisions which are essential at law for a valid and binding Agreement to be constituted) shall be deemed to be separate and severable from the remaining provisions of this Agreement. If any of the provisions of this Agreement (excluding only those provisions which are essential at law for a valid and binding Agreement to be constituted) is found by any court of competent jurisdiction to be invalid and/or unenforceable then, notwithstanding such invalidity and/or unenforceability, the remaining provisions of this
                                                        Agreement shall be and remain of full force and effect.
                                                    </Typography>
                                                </li>
                                                <li>
                                                    <Typography
                                                        as="p"
                                                        size="base"
                                                        color="dark"
                                                        margin="bottom"
                                                        className={styles.Documentation_Para}
                                                        uiText>
                                                        This Agreement is subject to, and shall be governed by, and construed in accordance with the laws of Nigeria, without reference to the principles of conflict of laws thereof. Any matters arising concerning the interpretation, validity or implementation of this Agreement not solved by mutual agreement between the Parties shall be submitted to arbitration in the English language before a sole arbitrator to take place in Lagos, Nigeria as the seat of the arbitration. The arbitration shall be conducted pursuant to the provisions of the Arbitration and Conciliation Act 1998. The arbitral decision shall be final and binding on the Parties and may be made an order of court. The Parties unconditionally consent and submit to the jurisdiction of the High Court of Lagos, Nigeria for such purpose. Nothing in this Agreement will be deemed as preventing Qlason from seeking injunctive relief (or any other provisional remedy) from any court having jurisdiction over the Parties and the subject matter of the dispute as is necessary to protect Qlason’s name, proprietary information, trade secrets, know-how, or any other intellectual property rights.

                                                    </Typography>
                                                </li>
                                                <li>
                                                    <Typography
                                                        as="p"
                                                        size="base"
                                                        color="dark"
                                                        margin="bottom"
                                                        className={styles.Documentation_Para}
                                                        uiText>
                                                        The Merchant agrees to indemnify, defend and hold harmless the Company against and from any third-party claims (including reasonable legal or arbitration costs) arising from:
                                                    </Typography>
                                                    <ul>
                                                        <li>
                                                            <Typography
                                                                as="p"
                                                                size="base"
                                                                color="dark"
                                                                margin="bottom"
                                                                className={styles.Documentation_Para}
                                                                uiText>
                                                                Any breach or default on the part of the Merchant of any obligations;
                                                            </Typography>
                                                        </li>

                                                        <li>
                                                            <Typography
                                                                as="p"
                                                                size="base"
                                                                color="dark"
                                                                margin="bottom"
                                                                className={styles.Documentation_Para}
                                                                uiText>
                                                                Negligent act or omission of the Merchant;
                                                            </Typography>
                                                        </li>

                                                        <li>
                                                            <Typography
                                                                as="p"
                                                                size="base"
                                                                color="dark"
                                                                margin="bottom"
                                                                className={styles.Documentation_Para}
                                                                uiText>
                                                                Willful violation of the law, wrong description and price of products advertised; An offence committed by Merchant;
                                                            </Typography>
                                                        </li>

                                                        <li>
                                                            <Typography
                                                                as="p"
                                                                size="base"
                                                                color="dark"
                                                                margin="bottom"
                                                                className={styles.Documentation_Para}
                                                                uiText>
                                                                Provision of fake, inferior or substandard products that have been sold as genuine;
                                                            </Typography>
                                                        </li>

                                                        <li>
                                                            <Typography
                                                                as="p"
                                                                size="base"
                                                                color="dark"
                                                                margin="bottom"
                                                                className={styles.Documentation_Para}
                                                                uiText>
                                                                Listing and selling products that have not been authorized for sale by the appropriate regulatory body (including but not limited to the NCC, NAFDAC, SON etc.);

                                                            </Typography>
                                                        </li>
                                                        <li>
                                                            <Typography
                                                                as="p"
                                                                size="base"
                                                                color="dark"
                                                                margin="bottom"
                                                                className={styles.Documentation_Para}
                                                                uiText>
                                                                Engages in any activity which would be considered illegal under Nigerian law or in any other jurisdiction, or engages in any activity that could be considered as fraudulent or misleading.
                                                            </Typography>
                                                        </li>
                                                        <li>
                                                            <Typography
                                                                as="p"
                                                                size="base"
                                                                color="dark"
                                                                margin="bottom"
                                                                className={styles.Documentation_Para}
                                                                uiText>
                                                                The Company is only liable for its own acts or omissions and not for acts or omissions of third parties.
                                                                This expressly excludes the liability for acts or omissions of the Payment Service Providers, or for events or activities originating outside its system (such as infrastructure failure, internet disturbances or malfunctioning in third party systems),
                                                                except in case such events were caused by its willful misconduct.
                                                            </Typography>
                                                        </li>
                                                    </ul>
                                                </li>

                                            </ol>
                                        </Element>
                                    </ul>
                                </div>
                            </section>
                        </div>
                    </div>
                    <br />
                    <br />
                </div>
            </Container >
        </>
    )
}

export default withDefaultLayout(TermsAndCondition);