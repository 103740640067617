/* eslint-disable @typescript-eslint/no-explicit-any */
import { Button, ButtonGroup, Field, Typography } from '@ekenta/components';
import React, { useEffect, useState, useRef } from 'react'
import { useFormik } from 'formik';
import updateAccountFormValidation from '@ekenta/validations/schemas/update-account.schema';
import Autocomplete, { usePlacesWidget } from "react-google-autocomplete";
import styles from "../../Profile.module.scss";
import { Form } from 'react-bootstrap';
import usePlacesService from "react-google-autocomplete/lib/usePlacesAutocompleteService";
// import { GoogleMap, LoadScript, Autocomplete } from '@react-google-maps/api';

const EditProfile = (props: { isLoading: boolean; data: any; handleSubmit: (data: any) => void; onCancel: () => void }) => {
    const { isLoading, onCancel } = props;
    const { ref } = usePlacesWidget({
        apiKey: "AIzaSyB3RiNYnPFNin56mwjwKUN_trGmulyykJY",
        onPlaceSelected: (place) => {
            console.log(place)
            formik.setFieldValue("location", place.formatted_address)
            formik.setFieldValue("lga", place.address_components?.[1].long_name)
        }
    })

    const formik = useFormik({
        initialValues: {
            firstName: "",
            lastName: "",
            phoneNumber: "",
            gender: "Male",
            location: "",
            lga: "",
        },
        validationSchema: updateAccountFormValidation(),
        onSubmit: values => {
            const data = {
                phone_number: values.phoneNumber,
                first_name: values.firstName,
                last_name: values.lastName,
                gender: values.gender,
                location: values.location,
                lga: values.lga,
            };

            props.handleSubmit(data);
        }
    });

    useEffect(() => {
        if (props.data) {
            formik.setFieldValue("firstName", props.data.first_name);
            formik.setFieldValue("lastName", props.data.last_name);
            formik.setFieldValue("location", props.data.location);
            formik.setFieldValue("lga", props.data.lga);
            formik.setFieldValue("phoneNumber", props.data.phone_number ? props.data.phone_number : "");
            formik.setFieldValue("gender", props.data.gender ? props.data.gender : "Male");
        }
    }, [props.data])


    return (
        <div>
            <Typography
                as="h5"
                size="medium"
                color="secondary"
                margin="top"
                className={styles.EditTypograph_Heading}
                heading>
                Edit profile
            </Typography>

            <div className="mt-3">
                <Typography
                    as="span"
                    size="smallest"
                    className="mb-2"
                    color="light"
                    uiText>*Required fields</Typography>
                <Field
                    id="first-name"
                    name="firstName"
                    label=""
                    disabled={props.isLoading}
                    type="text"
                    required
                    size="large"
                    childOutsideLabel={false}
                    placeholder="First name*"
                    fieldClassName={styles.FieldControl__Input}
                    autoComplete="off"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.firstName}
                    isError={formik.touched.firstName && formik.errors.firstName ? true : false}
                    error={formik.errors.firstName}
                />
            </div>
            <div className="mt-3">
                <Field
                    id="last-name"
                    name="lastName"
                    label=""
                    disabled={props.isLoading}
                    type="text"
                    size="large"
                    required
                    childOutsideLabel={false}
                    placeholder="Last name*"
                    fieldClassName={styles.FieldControl__Input}
                    autoComplete="off"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.lastName}
                    isError={formik.touched.lastName && formik.errors.lastName ? true : false}
                    error={formik.errors.lastName}
                />
            </div>
            <div className="mt-3">
                <Field
                    id="phone-number"
                    name="phoneNumber"
                    label=""
                    disabled={isLoading}
                    type="number"
                    required
                    childOutsideLabel={false}
                    placeholder="Phone number*"
                    numberFieldPrefix={"+234"}
                    fieldClassName={styles.FieldControl__Input}
                    autoComplete="off"
                    onChange={(e) =>
                        formik.setFieldValue("phoneNumber", e.formattedValue)
                    }
                    value={formik.values.phoneNumber}
                    isError={!!formik.errors.phoneNumber}
                    error={formik.errors.phoneNumber}
                />
            </div>

            <div className="mt-3">
                <Form.Select
                    required
                    name="gender"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.gender}
                >
                    <option value="Male">Male</option>
                    <option value="Female">Female</option>
                </Form.Select>
            </div>

            <div className="mt-3">
                {/* <Autocomplete
                    apiKey={"AIzaSyB3RiNYnPFNin56mwjwKUN_trGmulyykJY"}
                    onPlaceSelected={(place) => console.log(place)}
                    libraries={["places"]}
                    options={{
                        fields: ["address_components", "geometry", "icon", "name"],
                    }}
                /> */}
                <Field
                    ref={ref}
                    id="location"
                    name="location"
                    label=""
                    disabled={props.isLoading}
                    type="text"
                    size="large"
                    required
                    childOutsideLabel={false}
                    placeholder="Location*"
                    fieldClassName={styles.FieldControl__Input}
                    autoComplete="off"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.location}
                    isError={formik.touched.location && formik.errors.location ? true : false}
                    error={formik.errors.location}
                />
            </div>

            <ButtonGroup pos="right">
                <Button
                    as="text"
                    name="Cancel"
                    shadow={false}
                    type="button"
                    disabled={isLoading}
                    className={styles.Row__Button}
                    onClick={onCancel}
                />
                <Button
                    name="Save"
                    bgVariant="primary"
                    textVariant="light"
                    className="py-2"
                    disabled={isLoading}
                    isLoadingText="Save"
                    isLoading={isLoading}
                    shadow={false}
                    type="submit"
                    onClick={formik.handleSubmit}
                />
            </ButtonGroup>
        </div>
    )
}

export default EditProfile;