/* eslint-disable @typescript-eslint/no-explicit-any */
import { Switch } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { Col } from 'react-bootstrap';
import { FiChevronDown, FiEdit3, FiTrash2 } from 'react-icons/fi';
import * as queryString from 'query-string';
import { VscAdd, VscArrowSmallLeft } from 'react-icons/vsc';
import { useNavigate, useLocation } from 'react-router';
import { SpinLoader } from '@ekenta/components/Spinner/SpinLoader';
import { useAppSelector } from '@ekenta/redux/store/hook';
import { useGetSingleSellerDetailQuery } from '@ekenta/redux/store/services/adminSellerApi';
import {
  useGetAllProductsQuery,
  useGetLowStockProductsQuery,
} from '@ekenta/redux/store/services/vendorProductApi';
import {
  DashboardAddProductPathUrl,
  DashboardPathUrl,
  DashboardSellers,
} from '@ekenta/routes/constant';
import { checkIfAdmin } from '@ekenta/utils';
import { withDashboardLayout } from '@ekenta/hoc';
import { UnAuthorizedCard } from '../component/UnAuthorizedCard/UnAuthorizedCard';
import {
  Overlay,
  Pagination,
  HelmetHeader,
  Loader,
  PageTitle,
  DataTable,
  CustomAlert,
  Dropdown,
} from '@ekenta/components';
import { useAuthSearchProductMutation } from '@ekenta/redux/store/services/productsApi';
import { BASE_URL } from '@ekenta/constants';
import axios from 'axios';

const LowStock = () => {
  const query = queryString.parse(window.location.search);
  const _page = query.page;
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const [pageno, setPageno] = useState(1);
  const [isLoading, setIsLoading] = useState(false);

  const [searchValue, setSearchValue] = useState('');
  const { token, account } = useAppSelector(
    (state) => state.auth.authentication,
  );
  const isAdmin = account.user ? checkIfAdmin(token, account.user.role) : null;

  let getResult;
  !isAdmin ? (getResult = useGetSingleSellerDetailQuery(token)) : null;

  const result = useGetLowStockProductsQuery({ pageno, token });

  const [searchProduct, getSearchResult] = useAuthSearchProductMutation();

  useEffect(() => {
    handleSearch(searchValue);
  }, [searchValue]);

  const handleSearch = (keyword: string) => {
    if (keyword) {
      const body = { keyword: keyword };
      searchProduct({ body, token });
    }
  };

  const columns = [
    {
      Header: 'Picture',
      accessor: 'image',
      sortType: 'basic',
      disableSortBy: true,
    },

    {
      Header: 'Name',
      accessor: 'name',
      sortType: 'basic',
    },
    {
      Header: 'Price',
      accessor: 'price',
      sortType: 'basic',
    },
    {
      Header: 'Subcollection',
      accessor: 'subcollection',
      sortType: 'basic',
    },

    {
      Header: 'Quantity',
      accessor: 'stock',
      disableSortBy: true,
    },
    {
      Header: `${isAdmin ? 'Flagged' : 'Published'}`,
      accessor: 'visiblity',
      disableSortBy: true,
    },
    {
      Header: 'Action',
      accessor: 'option',
      disableSortBy: true,
    },
  ];

  const togglePublish = (productId: string, visibility: boolean) => {
    if (productId) {
      const status = visibility ? 'unpublish' : 'publish';
      toggleFunction(status, productId);
    }
  };

  const toggleFlagged = (productId: string, isFlagged: boolean) => {
    if (productId) {
      const status = isFlagged ? 'unflag' : 'flag';
      toggleFunction(status, productId);
    }
  };

  const handleDelete = (id: any) => {
    const config = {
      method: 'get',
      maxBodyLength: Infinity,
      url: `${BASE_URL}/api/seller/delete-product/${id}`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    };

    axios(config)
      .then(function (response) {
        toast.success(`Product deleted successfully`, {
          toastId: '-product-toggle-success-id-toast',
        });
        window.location.reload();
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const toggleFunction = useCallback(
    (status: string, prodId: string) => {
      const url = isAdmin ? 'admin/flag-product' : 'seller/product-status';
      const config = {
        method: 'get',
        url: `${BASE_URL}/api/${url}/${status}/${prodId}`,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        data: '',
      };
      setIsLoading(true);
      axios(config)
        .then(function () {
          setIsLoading(false);
          toast.success(`Product ${status} successfully`, {
            toastId: '-product-toggle-success-id-toast',
          });
          result?.refetch();
        })
        .catch(function () {
          setIsLoading(false);
          toast.error(`Something went wrong`, {
            toastId: '-toggle-error-400-id-toast-error',
          });
          result?.refetch();
        });
    },
    [result],
  );

  const mapData = (data: any) => {
    return data?.map((item: any) => ({
      ...item,
      image: (
        <div
          className="picture__thumb"
          style={{ width: '70px', height: 'auto' }}
        >
          <img
            className="img-thumbnail rounded"
            width="100%"
            height="70px"
            src={item?.images.length > 0 ? item.images[0]?.image_url : ''}
          />
        </div>
      ),
      name: item?.name,
      price: item?.price,
      subcollection: item?.subCollection,
      stock: item?.stock,
      visiblity: (
        <>
          {isAdmin ? (
            <Switch
              defaultChecked={item?.is_flagged}
              size="small"
              color="warning"
              onChange={() => toggleFlagged(item?.id, item?.is_flagged)}
            />
          ) : (
            <Switch
              readOnly={item?.is_flagged}
              size="small"
              color="warning"
              defaultChecked={item?.stock > 0 ? item?.visibility : false}
              disabled={item?.stock > 0 && !item?.is_flagged ? false : true}
              onChange={() =>
                item?.stock > 0 && !item?.is_flagged
                  ? togglePublish(item?.id, item?.visibility)
                  : console.log('')
              }
            />
          )}
        </>
      ),
      option: (
        <Dropdown
          name={'more'}
          suffixIcon={<FiChevronDown />}
          placement="bottom"
          menus={[
            {
              name: 'Edit product',
              icon: <FiEdit3 />,
              active: false,
              key: 'live-code-key',
              handler: () =>
                navigate(
                  DashboardAddProductPathUrl + `?_id=${item?.id}&type=edit`,
                ),
            },
            {
              name: 'Delete product',
              icon: <FiTrash2 />,
              active: false,
              key: 'view-detail',
              handler: () => handleDelete(item?.id),
            },
          ]}
          tooltipContent={'More Option'}
        />
      ),
    }));
  };

  const navBack = isAdmin ? DashboardSellers : DashboardPathUrl;
  return (
    <>
      <HelmetHeader
        title="All Products"
        description="QlasonAll Products Page"
      />

      {getResult?.isLoading ? (
        <SpinLoader size={2} variant="dark" />
      ) : (
        <>
          {(getResult?.isSuccess &&
            getResult?.data.user.merchant_business.seller_request_status ==
            'APPROVED') ||
            isAdmin ? (
            <Col className="_listing_product_ mt-0 px-2">
              <div className="mt-0">
                <Col className="AppEvents_col mx-1 mx-lg-0">
                  <PageTitle
                    title="Low Stock Products"
                    subTitle={
                      <span
                        className="cursor-pointer d-flex w-lg-50 align-items-center
                            text-muted"
                        onClick={() => navigate(navBack)}
                      >
                        <VscArrowSmallLeft />
                        Back to Dashboard
                      </span>
                    }
                  />
                </Col>

                <Col className="mt-2 position-relative">
                  {result?.isError && (
                    <CustomAlert
                      variant="danger"
                      show={true}
                      message="Unable to load Content"
                    />
                  )}

                  <DataTable
                    theme="simple"
                    data={
                      result?.isSuccess
                        ? mapData(result?.data?.products?.data)
                        : []
                    }
                    loading={result?.isLoading || result?.isFetching}
                    isSelectable={true}
                    columns={columns}
                    showPagination={false}
                    hasLink={true}
                    thClassName="w"
                    trClassName="w"
                    tdClassName={`Row__col`}
                    align="left"
                    searchValue={searchValue}
                    // showSearch={true}
                    onChange={(e: any) => setSearchValue(e.target.value)}
                    renderPagnation={
                      <div className="mt-4">
                        {!searchValue
                          ? result?.data
                            ? result?.data.products.total > 19 && (
                              <Pagination
                                pathname={pathname}
                                itemPerPage={result?.data.products.per_page}
                                itemsLength={result?.data.products.total}
                                currentPage={pageno}
                                queryNo={_page}
                                callback={(value) => setPageno(value)}
                              />
                            )
                            : null
                          : null}
                      </div>
                    }
                  />
                  <br />
                </Col>
              </div>
            </Col>
          ) : getResult?.isError ? (
            <div className="mt-3">
              <CustomAlert
                variant="danger"
                show={true}
                message={'Something went wrong, Try again'}
              />
            </div>
          ) : (
            <UnAuthorizedCard />
          )}
        </>
      )}
      {isLoading || getSearchResult?.isLoading ? (
        <Overlay bgColor="light">
          <div
            className="d-flex align-items-center 
                 justify-content-end mx-auto w-25 p-4"
          >
            <Loader withWrapper />
          </div>
        </Overlay>
      ) : null}
    </>
  );
};
export default withDashboardLayout(LowStock);
