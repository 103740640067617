import React from 'react'
import classnames from 'classnames';
import styles from "./Field/Field.module.scss"
import "./InputField.scss";
import { Info } from '../icons';
import Label from '../Label/Label';

const InputField = ({
  id,
  label,
  children,
  description,
  error,
  isError,
  type,
  size = "small",
  childOutsideLabel = true,
  withMargin = true,
  className,
  errorClassName,
  required,
  inline,
  renderDescription
}: any) => {

  const inputWrapperClasses = classnames(
    "InputField",
    `InputField--${size}`,
    className,
    [styles.Field],
    {
      [styles.withMargin]: withMargin,
      "InputField--error": isError,
    },
  );

  const contentClasses = classnames(styles.content, {
    [styles.errorBottom]: isError,
  });

  const fieldContainer = () => (
    <div className="InputField__fieldContainer">
      {children}
      {inline && (renderDescription || description) && (
        <div className="description">
          {renderDescription ? renderDescription() : description}
        </div>
      )}
    </div>
  );

  return (
    <div className={inputWrapperClasses}>
      <div className={contentClasses}
        style={{ "display": childOutsideLabel ? "block" : undefined }}>
        {!childOutsideLabel ?
          <Label
            htmlFor={id}
            type={type}
            text={label}
            required={required}
            render={fieldContainer}
          />
          : null}
        {childOutsideLabel && fieldContainer()}
        {isError && (
          <span className={`${errorClassName} ${styles.error} ${styles.bottom}`}>
            {error}
            <Info className="" />
          </span>
        )}
      </div>
    </div>
  )
}

export default InputField