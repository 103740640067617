import styled from 'styled-components'

export const VoucherStyle = styled.div`
  width: 100%;

  .h-stacked {
    display: flex;
    align-items: center;
    justify-content: space-between;
    button {
      width: auto !important;
      max-width: 150px !important;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 36.128px;
      height: 36.367px !important;
      padding: 20.677px !important;
      color: var(--eke-body-text-light-color) !important;
      box-shadow: none !important;
      border: none !important;
      transition: all 0.3s ease-out;
      gap: 4px !important;

      span {
        font-size: 14px !important;
        font-family: var(--eke-body-font-family) !important;
        font-weight: var(--eke-font-weight-bold) !important;
        margin-top: 0px;
      }
    }
  }

  .chart-container {
    width: 100%;
    height: 450px;
    background-color: #fff;
    border-radius: 18px;
    padding: 25px 25px;
    //box-shadow: #adcfffb3 0px 1px 2px 0px, #3435364b 0px 1px 2px 0px !important;
    margin-top: 15px;

    .chart-header {
      display: flex;
      align-items: center;
      justify-content: space-between;

      h5 {
        font-size: 20px;
        color: #212121;
        line-height: 24px;
      }
    }
  }

  .datatable-wrapper {
    margin-top: 25px;

    .table-title-wrapper {
      padding-left: 10px;
      padding-right: 10px;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }
`

export const ModalContent = styled.div`
  width: 100%;
  height: auto;
  padding: 0px 20px 8px 20px;

  .modal--body {
    padding-top: 20px;
    .form-field {
      display: flex;
      flex-direction: column;
      gap: 5px;
      width: 100%;
      height: auto;

      .cta-group {
        width: 100%;
        display: flex;
        justify-content: flex-end;
        gap: 15px;

        button {
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 36.128px;
          height: 48px !important;
          padding: 18px 16px 18px 16px !important;
          border: none !important;
          transition: all 0.3s ease-out;
          gap: 4px !important;
          width: 180px !important;
          

          span {
            font-size: 14px !important;
            color: #fff;
            font-family: var(--eke-body-font-family) !important;
            font-weight: var(--eke-font-weight-bold) !important;
            margin-top: 0px;
          }
        }

        .cta-btn {
          background-color: var(--eke-color-primary) !important;
        }
      }
    }
  }
`
