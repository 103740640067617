/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react';
import * as queryString from 'query-string';
import moment from 'moment';
import { Link, useLocation } from 'react-router-dom';
import { useAppSelector } from '@ekenta/redux/store/hook';
import { Button, DataTable, Pagination } from '@ekenta/components';
import { useFetchAllSellerOrdersQuery } from "@ekenta/redux/store/services/vendorProductApi";
import { StatusIndicator } from '@ekenta/pages/Dashboard/common/Resuable';

const StatusOrder = (props: { activetab: string | null; status: string }) => {
    const { status, activetab } = props;
    const { pathname } = useLocation();
    const query = queryString.parse(window.location.search);
    const _page = query.page;

    const { token } = useAppSelector((state) => state.auth.authentication);

    const [pageNo, setPageNo] = useState(1);
    const [search, setSearch] = useState('');
    const [debouncedSearchTerm, setDebouncedSearchTerm] = useState('');

    const result = useFetchAllSellerOrdersQuery({
        token: token,
        status: status,
        keyword: debouncedSearchTerm,
        pageNo: status === activetab ? pageNo : 1,
    }, {
        skip: status !== activetab ? true : false,
    });

    useEffect(() => {
        const delay = setTimeout(() => {
            setDebouncedSearchTerm(search);
        }, 1000);

        return () => {
            clearTimeout(delay);
        };
    }, [search]);


    const columns = [
        {
            Header: "Date",
            accessor: "createdDate",
            disableSortBy: true,
        },
        {
            Header: "Product",
            accessor: "product_name",
        },
        {
            Header: "Price",
            accessor: "price",
            sortType: "basic",
        },
        {
            Header: "Collection",
            accessor: "collection",
        },
        {
            Header: "Order Status",
            accessor: "orderStatus",
            disableSortBy: true,
        },

        {
            Header: "Action",
            accessor: "view",
            disableSortBy: true,
        },
    ];

    const recalibrate = (data: any) => {
        return data.map((items: any) => ({
            ...items,
            createdDate: moment(items.created_at).format('MMM D,YYYY'),
            product_name: items.product.name,
            price: "NGN " + new Intl.NumberFormat().format(items.price),
            collection: items.product.collection,
            orderStatus: <StatusIndicator status={items.order_status} />,
            view: (
                <Link to={`/vendor/dashboard/order-details/${items.id}`}>
                    <Button
                        name="View"
                        bgVariant="light"
                        textVariant="secondary"
                        className="py-2"
                        shadow={true}
                        type="button"
                    />
                </Link>
            )
        }))
    }

    const onSearch = (e: any) => {
        setSearch(e.target.value);
    };

    return (
        <DataTable
            theme="simple"
            data={result?.data ? recalibrate(result?.data.data.data) : []}
            loading={result?.isLoading || result?.isFetching}
            isSelectable={true}
            columns={columns}
            showPagination={false}
            hasLink={true}
            showSearch
            shadow={true}
            searchValue={search}
            onSearch={onSearch}
            thClassName="w"
            trClassName="w_Tr"
            tdClassName={`Row__col`}
            renderPagnation={
                <>
                    {result?.data ? (
                        <Pagination
                            pathname={pathname}
                            itemPerPage={result?.data.data.per_page}
                            itemsLength={result?.data.data.total}
                            currentPage={pageNo}
                            queryNo={_page}
                            callback={(value) => setPageNo(value)}
                        />
                    ) : null}
                </>
            }
        />
    );
};

export default StatusOrder;
