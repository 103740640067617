import React, { Fragment } from "react";
import { useSend, useDirectCharge, useCheckout } from 'thepeer-react'
import styles from "./PaymentHookButton.module.scss";
import { Spinner } from "react-bootstrap";
import { Button, Loader } from "@ekenta/components";
import NumberFormat from "react-number-format";
import classnames from "classnames";
import { THEPEER_KEY_PUBLIC } from "@ekenta/constants";

interface ThePeerButtonPropsType {
    email: string;
    name: string;
    phoneNumber: string;
    amount: number;
    text: string;
    className: string;
    textPrice: number;
    currency: string;
    reference: string;
    isLoading: boolean;
    orderId: number;
    callback: (response: any) => void;

}

export default function ThePeerHookButton(props: ThePeerButtonPropsType) {
    const config = {
        publicKey: THEPEER_KEY_PUBLIC,
        amount: props.amount * 100,
        currency: 'NGN',
        email: props.email,
        meta: {
            email: props.email,
            orderId: props.orderId,
            amount: props.amount,
        },
        onClose: (event: any) => {
            console.log(event)
        },
        onSuccess: (event: any) => {
            console.log(event)
            props.callback(event);
        },
        onError: (event: any) => {
            console.log(event)

        }
    }

    // const handleSendPayment = useSend({
    //   ...config,
    //   userReference: 'USER_REFERENCE'
    // })
    // const handleDirectChargePayment = useDirectCharge({
    //   ...config,
    //   userReference: 'USER_REFERENCE'
    // })
    const handleCheckoutPayment = useCheckout(config)

    return (
        <div className={styles.ButtonGroup}>
            <Button
                className={
                    classnames(
                        styles.PaymentHook__Button,
                        "d-flex algin-items-center justify-content-center"
                        , props.className)
                }
                name="paymentBtn__hook"
                width="w-100"
                disabled={props.isLoading}
                onClick={() => {
                    handleCheckoutPayment()
                }}>
                {props.isLoading ? <Fragment><Loader color="white" /> Loading..</Fragment> : (
                    <>
                        <span>{props.text}</span>
                        <NumberFormat
                            className="tot ms-2"
                            thousandSeparator={true}
                            displayType={"text"}
                            decimalSeparator="."
                            decimalScale={2}
                            prefix={props.currency}
                            fixedDecimalScale
                            value={props.textPrice}
                        />
                    </>
                )}
            </Button>
        </div>
    )
}