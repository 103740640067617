import axios from "axios"
import React, { useState } from "react";
import { Col, Table, ButtonGroup, Button } from "react-bootstrap";
import { Helmet } from "react-helmet"
import { FiChevronLeft } from "react-icons/fi";
import { useLocation, useNavigate } from "react-router"
import DashboardComponent from "../../component/DashboardComponent"
import { Pagination } from "../../../../components";
import { BASE_URL } from "../../../../constants"
import DashboardLayout from "../../../../hoc/Layout/Dashboard-Layout"
import { useAppSelector } from "../../../../redux/store/hook"
import { useFetchAllVerifiedUsersQuery } from "../../../../redux/store/services/adminSellerApi";
import { DashboardSellers } from "../../../../routes/constant"

export default function AllVerifiedUsers({ collapsed, toggled, handleToggleSidebar, handleCollapsedChange }: any) {
    const { pathname } = useLocation();
    const [lgShow, setLgShow] = useState(false);
    const { ek_isAuth, token } = useAppSelector(state => state.auth.authentication);
    const [data, setData] = useState<any>({});
    const [paginated, setPaginated] = useState(false);
    const navigate = useNavigate()
    const [pageno, setPageNo] = useState(0)
    const [pagedData, setPagedData] = useState(null)
    const [value, setValue] = useState("");
    const [_pageSize, setPageSize] = useState(7);
    const result = useFetchAllVerifiedUsersQuery({ token, pageno })

    const handleViewOrder = (id: string) => {
        setLgShow(true);
        alert(id)
    }

    const paginateUsers = async (x: any) => {
        setPaginated(true)
        await axios.get(`${BASE_URL}/api/users/managed-verified-users?page=${x.selected}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        }).then((resp: any) => {
            console.log(resp.data.content)
            setData(resp.data)
        }).catch(function (error: any) {
            console.log(error)
        });
    }

    return (
        <DashboardLayout>
            <Helmet>
                <title>All Verified Users - Qlason</title>
                <meta charSet="utf-8" />
                <meta name="title" content="QlasonOrders Dashboard Page" />
                <meta name="description"
                    content="QlasonOrders Dashboard  Page"
                />
                <meta property="og:locale" content="en_NG" />
                <meta name="robots" content="noindex,follow" />
            </Helmet>

            <DashboardComponent
                collapsed={collapsed}
                toggled={toggled}
                handleToggleSidebar={handleToggleSidebar}
                handleCollapsedChange={handleCollapsedChange}
            >
                <Col className=" col-lg-11 mx-auto _listing_product_ dash_top px-2">
                    <div className="mt-0 pt-3">
                        <Col md={3} onClick={() => navigate(DashboardSellers)} className={"cursor-pointer"}>
                            <h3 className="text-secondary heading_d">
                                <FiChevronLeft />
                                All Verified Users
                            </h3>
                        </Col>
                        <Col className="mt-5 tab__">
                            <Table striped bordered hover>
                                <thead>
                                    <tr>
                                        <th>SN</th>
                                        <th>Image</th>
                                        <th>Name</th>
                                        <th>Email</th>
                                        <th>Phone</th>
                                        <th>Gender</th>
                                        <th>Role</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        paginated ?
                                            data?.content?.map((e: any, i: any) => (
                                                <tr key={i}>
                                                    <td>{i + 1}</td>
                                                    <td>
                                                        <img className="img-thumbnail rounded-circle" style={{ height: "50px" }} src={e.imageUrl !== null ? e?.imageUrl : "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png"} alt="" />
                                                    </td>
                                                    <td>{e?.firstName + " " + e?.lastName}</td>
                                                    <td>{e?.email}</td>
                                                    <td>{e?.phoneNumber}</td>
                                                    <td>{e?.gender}</td>
                                                    <td>{e?.authorities.map((x: any, y: any) => (
                                                        <span key={y}>{x}, </span>
                                                    ))}</td>
                                                    <td>
                                                        <ButtonGroup className="me-2" aria-label="Basic example">
                                                            <Button variant="primary text-white">View</Button>
                                                            <Button variant="secondary">Edit</Button>
                                                            <Button variant="danger">Delete</Button>
                                                        </ButtonGroup>
                                                    </td>
                                                </tr>
                                            ))
                                            :

                                            result.isSuccess ?
                                                result.data.content.map((e: any, i: any) => (
                                                    <tr key={i}>
                                                        <td>{i + 1}</td>
                                                        <td>
                                                            <img className="img-thumbnail rounded-circle" style={{ height: "50px" }} src={e.imageUrl !== null ? e?.imageUrl : "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png"} alt="" />
                                                        </td>
                                                        <td>{e?.firstName + " " + e?.lastName}</td>
                                                        <td>{e?.email}</td>
                                                        <td>{e?.phoneNumber}</td>
                                                        <td>{e?.gender}</td>
                                                        <td>{e?.authorities.map((x: any, y: any) => (
                                                            <span key={y}>{x}, </span>
                                                        ))}</td>
                                                        <td>
                                                            <ButtonGroup className="me-2" aria-label="Basic example">
                                                                <Button variant="primary" className="text-white">View</Button>
                                                                <Button variant="secondary">Edit</Button>
                                                                <Button variant="danger">Delete</Button>
                                                            </ButtonGroup>
                                                        </td>
                                                    </tr>
                                                ))
                                                : ""

                                    }

                                </tbody>


                            </Table>
                            {
                                result.isSuccess ?
                                    <Pagination
                                        itemPerPage={paginated ? data.size : result.data.size}
                                        itemsLength={paginated ? data.totalElements : result.data.totalElements}
                                        handleChange={paginateUsers}
                                        pathname={pathname}
                                        currentPage={paginated ? data.number : result.data.number}
                                    />
                                    : ""
                            }

                        </Col>
                    </div>
                </Col>
            </DashboardComponent>

        </DashboardLayout>
    )
}

